import React, { Component } from 'react';
import ScriptTag from 'react-script-tag';

export default class FuelCalculatorDisplayContent extends Component {
  render() {
    return (
      <ScriptTag
        async
        id="hcuk-ev-calc"
        src="https://evcalculator.hyundaicapital.co.uk/assets/inject.min.js"
        data-brand="hyundai"
        data-ref="9bfcc507-c6e0-4cb3-88b1-bccdb2d29abd"
        //data-ref="fc0410c1-5683-466e-bdce-db1509c8efed"
      />
    );
  }
}
