import React, { Component } from 'react';
import FooterNavbar from '../components/footerNavbar';
import TopNavbar from '../components/topnavbar';
import BottomNavbar from '../components/bottomnavbar';
import Footer from '../components/footer';
import $ from 'jquery';
import CarOfferItem from '../components/CarOffers/CarOfferItem';
import CarOffersNavBar from '../components/CarOffers/CarOffersNavBar';
import { Swipe } from '../utils/TouchDirection.js';
import Store from 'store';
import { Redirect } from 'react-router-dom';

export default class CarOffers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      carOffers: Store.get('carOffers'),
    };
  }
  componentDidMount() {
    function moveToSelected(element) {
      var selected;
      var selectedIndicator;
      if (element === 'next') {
        selected = $('.selected').next();
        selectedIndicator = $('.selectedIndicator').next();
      } else if (element === 'prev') {
        selected = $('.selected').prev();
        selectedIndicator = $('.selectedIndicator').prev();
      } else {
        var index = JSON.parse(element[0].attributes[1].value);
        selected = element;
        selectedIndicator = $('.dots').find('li')[index];
      }

      var next = $(selected).next();
      var nextIndicator = $(selectedIndicator).next();

      var prev = $(selected).prev();
      var prevIndicator = $(selectedIndicator).prev();

      var prevSecond = $(prev).prev();
      var prevSecondIndicator = $(prevIndicator).prev();

      var nextSecond = $(next).next();
      var nextSecondIndicator = $(nextIndicator).next();

      $(selected).removeClass().addClass('selected');

      $(selectedIndicator)
        .removeClass()
        .addClass('selectedIndicator  d-inline-block')
        .find('.fa-circle')
        .addClass(' fas ');

      $(prev).removeClass().addClass('prev');
      $(prevIndicator)
        .removeClass()
        .addClass('prevIndicator  d-inline-block')
        .find('.fa-circle')
        .removeClass('fas')
        .addClass('far');
      $(next).removeClass().addClass('next');
      $(nextIndicator)
        .removeClass()
        .addClass('nextIndicator  d-inline-block')
        .find('.fa-circle')
        .removeClass('fas')
        .addClass('far');

      $(nextSecond).removeClass().addClass('nextRightSecond');
      $(nextSecondIndicator)
        .removeClass()
        .addClass('nextRightSecondIndicator  d-inline-block')
        .find(' .fa-circle')
        .removeClass(' fas ')
        .addClass(' far ');

      $(prevSecond).removeClass().addClass('prevLeftSecond');
      $(prevSecondIndicator)
        .removeClass()
        .addClass('prevLeftSecondIndicator  d-inline-block')
        .find(' .fa-circle')
        .removeClass(' fas ')
        .addClass(' far ');

      $(nextSecond).nextAll().removeClass().addClass('hideRight');
      $(prevSecond).prevAll().removeClass().addClass('hideLeft');
    }

    $(document).keydown(function (e) {
      switch (e.which) {
        case 37: // left
          moveToSelected('prev');
          break;

        case 39: // right
          moveToSelected('next');
          break;

        default:
          return;
      }
      e.preventDefault();
    });

    $('#carousel > div').click(function () {
      moveToSelected($(this));
    });

    $('#prev').click(function () {
      moveToSelected('prev');
    });

    $('#next').click(function () {
      moveToSelected('next');
    });

    $('.buttonIndicators').click(function (e) {
      var index = e.currentTarget.attributes[1].value;

      $('#carousel').find(`[data='${index}']`).click();
    });

    new Swipe('#carousel')
      .onLeft(function () {
        $('#next').click();
      })
      .onRight(function () {
        $('#prev').click();
      })
      .run();
  }

  render() {
    const { carOffers } = this.state;

    const isCarOffersVisible =
      this.state.carOffers && this.state.carOffers.showSalesOffer;

    return (
      <div>
        {!isCarOffersVisible && <Redirect to="/" />}

        <TopNavbar />
        <BottomNavbar />
        <CarOffersNavBar />
        <div className="container">
          <section
            className="theme-main-grey-background wow fadeIn"
            data-wow-duration="2s"
            data-wow-delay="0.2s"
          >
            <div className="carOffers p-3 p-md-5 col-12">
              <div className="row">
                <div className="col-12 d-flex justify-content-center">
                  <div className="theme-car-offer-header-text  text-md-center bg-white border-right border-bottom mb-0 mb-md-3 p-3 ">
                    <h4
                      className="font-weight-bold d-md-none"
                      data-search-tag="title"
                    >
                      Car Offers
                    </h4>
                    <p className="mb-0 small">{carOffers.introText}</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div
                    id="carousel"
                    className="theme-car-offer-carousel-container"
                  >
                    {carOffers.models.map((carOffer, index) => {
                      var carouselType = '';
                      /* Order of CSS classes for carousel
                         hideLeft       -> 
                         prevLeftSecond -> 
                         prev           -> 
                              selected 
                         <- next
                         <- nextRightSecond
                         <- hideRight */

                      if (carOffers.models.length === 3) {
                        switch (index) {
                          case 0:
                            carouselType = 'prev';
                            break;
                          case 1:
                            carouselType = 'selected';
                            break;
                          case 2:
                            carouselType = 'next';
                            break;
                          default:
                            carouselType = 'next';
                        }
                      } else if (carOffers.models.length === 2) {
                        switch (index) {
                          case 0:
                            carouselType = 'selected';
                            break;
                          case 1:
                            carouselType = 'next';
                            break;

                          default:
                            carouselType = 'next';
                        }
                      } else if (carOffers.models.length === 1) {
                        carouselType = 'selected';
                      }

                      return (
                        <div className={carouselType} key={index} data={index}>
                          <CarOfferItem
                            model={carOffer.model}
                            image={carOffer.imageUrl}
                            content={carOffer.content}
                            link={carOffer.link}
                            linkText={carOffer.linkText}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col d-flex justify-content-center">
                  <div className="theme-car-offer-buttons  d-flex align-items-center">
                    {carOffers.models.length !== 1 && (
                      <div className="d-inline-block">
                        <button
                          id="prev"
                          className="btn btn-link  d-flex align-items-center"
                        >
                          <i className="fa fa-chevron-left fa-fw" />
                        </button>
                      </div>
                    )}

                    {carOffers.models.length === 3 && (
                      <ul className="list-inline d-inline-block dots mb-0">
                        <li className="prevIndicator d-inline-block">
                          {/* 1 */}
                          <button
                            className="btn btn-link  d-flex align-items-center buttonIndicators"
                            data-indicator="0"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              style={{ isolation: 'isolate' }}
                              viewBox="624.5 326.5 21 21"
                              width="21"
                              height="21"
                            >
                              <defs>
                                <clipPath id="_clipPath_VAMz5J1a7GD4hOZJucCnj5LKlqQzn1v1">
                                  <rect
                                    x="624.5"
                                    y="326.5"
                                    width="21"
                                    height="21"
                                  />
                                </clipPath>
                              </defs>
                              <switch>
                                <g clipPath="url(#_clipPath_VAMz5J1a7GD4hOZJucCnj5LKlqQzn1v1)">
                                  <g id="Group">
                                    <circle
                                      vectorEffect="non-scaling-stroke"
                                      cx="635"
                                      cy="337"
                                      r="10"
                                      id="Ellipse"
                                      fill="rgb(245,245,245)"
                                      strokeWidth="1"
                                      stroke="rgb(0,44,95)"
                                      strokeLinejoin="miter"
                                      strokeLinecap="square"
                                      strokeMiterlimit="3"
                                    />
                                    <path
                                      d=" M 635.741 332.55 L 635.741 341.45 L 634.754 341.45 L 634.754 335.107 L 634.754 335.107 Q 634.754 334.315 634.803 333.609 L 634.803 333.609 L 634.803 333.609 Q 634.675 333.737 634.517 333.877 L 634.517 333.877 L 634.517 333.877 Q 634.359 334.017 633.068 335.064 L 633.068 335.064 L 632.532 334.37 L 634.888 332.55 L 635.741 332.55 Z "
                                      id="Path"
                                      fill="rgb(0,44,95)"
                                    />
                                  </g>
                                </g>
                              </switch>
                            </svg>
                          </button>
                        </li>
                        <li className="selectedIndicator d-inline-block">
                          {/* 2 */}
                          <button
                            className="btn btn-link  d-flex align-items-center buttonIndicators"
                            data-indicator="1"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              style={{ isolation: 'isolate' }}
                              viewBox="624.5 325.5 21 21"
                              width="21"
                              height="21"
                            >
                              <defs>
                                <clipPath id="_clipPath_9H6bzwVFW8VMf2CnWchnZqVWeimiKEbh">
                                  <rect
                                    x="624.5"
                                    y="325.5"
                                    width="21"
                                    height="21"
                                  />
                                </clipPath>
                              </defs>
                              <switch>
                                <g clipPath="url(#_clipPath_9H6bzwVFW8VMf2CnWchnZqVWeimiKEbh)">
                                  <g id="Group">
                                    <circle
                                      vectorEffect="non-scaling-stroke"
                                      cx="634.9999999999999"
                                      cy="336"
                                      r="10"
                                      id="Ellipse"
                                      fill="rgb(245,245,245)"
                                      strokeWidth="1"
                                      stroke="rgb(0,44,95)"
                                      strokeLinejoin="miter"
                                      strokeLinecap="square"
                                      strokeMiterlimit="3"
                                    />
                                    <path
                                      d=" M 637.925 339.577 L 637.925 340.514 L 632.075 340.514 L 632.075 339.643 L 634.419 337.288 L 634.419 337.288 Q 635.49 336.204 635.831 335.741 L 635.831 335.741 L 635.831 335.741 Q 636.172 335.279 636.342 334.84 L 636.342 334.84 L 636.342 334.84 Q 636.513 334.402 636.513 333.897 L 636.513 333.897 L 636.513 333.897 Q 636.513 333.184 636.081 332.767 L 636.081 332.767 L 636.081 332.767 Q 635.648 332.35 634.881 332.35 L 634.881 332.35 L 634.881 332.35 Q 634.327 332.35 633.831 332.533 L 633.831 332.533 L 633.831 332.533 Q 633.335 332.716 632.726 333.197 L 632.726 333.197 L 632.191 332.509 L 632.191 332.509 Q 633.42 331.486 634.869 331.486 L 634.869 331.486 L 634.869 331.486 Q 636.123 331.486 636.835 332.128 L 636.835 332.128 L 636.835 332.128 Q 637.548 332.77 637.548 333.854 L 637.548 333.854 L 637.548 333.854 Q 637.548 334.7 637.073 335.528 L 637.073 335.528 L 637.073 335.528 Q 636.598 336.356 635.295 337.622 L 635.295 337.622 L 633.347 339.528 L 633.347 339.577 L 637.925 339.577 Z "
                                      id="Path"
                                      fill="rgb(0,44,95)"
                                    />
                                  </g>
                                </g>
                              </switch>
                            </svg>
                          </button>
                        </li>
                        <li className="nextIndicator d-inline-block">
                          {/* 3 */}
                          <button
                            className="btn btn-link  d-flex align-items-center buttonIndicators"
                            data-indicator="2"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              style={{ isolation: 'isolate' }}
                              viewBox="624.5 326.5 21 21"
                              width="21"
                              height="21"
                            >
                              <defs>
                                <clipPath id="_clipPath_TJC9PN6dADtt8QoDXNOFD9Lt8yLQ0naa">
                                  <rect
                                    x="624.5"
                                    y="326.5"
                                    width="21"
                                    height="21"
                                  />
                                </clipPath>
                              </defs>
                              <switch>
                                <g clipPath="url(#_clipPath_TJC9PN6dADtt8QoDXNOFD9Lt8yLQ0naa)">
                                  <g id="Group">
                                    <circle
                                      vectorEffect="non-scaling-stroke"
                                      cx="635"
                                      cy="337"
                                      r="10"
                                      id="Ellipse"
                                      fill="rgb(245,245,245)"
                                      strokeWidth="1"
                                      stroke="rgb(0,44,95)"
                                      strokeLinejoin="miter"
                                      strokeLinecap="square"
                                      strokeMiterlimit="3"
                                    />
                                    <path
                                      d=" M 637.639 334.647 L 637.639 334.647 L 637.639 334.647 Q 637.639 335.499 637.161 336.041 L 637.161 336.041 L 637.161 336.041 Q 636.683 336.583 635.807 336.766 L 635.807 336.766 L 635.807 336.814 L 635.807 336.814 Q 636.878 336.948 637.395 337.496 L 637.395 337.496 L 637.395 337.496 Q 637.913 338.044 637.913 338.933 L 637.913 338.933 L 637.913 338.933 Q 637.913 340.205 637.03 340.89 L 637.03 340.89 L 637.03 340.89 Q 636.148 341.575 634.522 341.575 L 634.522 341.575 L 634.522 341.575 Q 633.816 341.575 633.228 341.468 L 633.228 341.468 L 633.228 341.468 Q 632.641 341.362 632.087 341.094 L 632.087 341.094 L 632.087 340.132 L 632.087 340.132 Q 632.665 340.418 633.32 340.567 L 633.32 340.567 L 633.32 340.567 Q 633.974 340.717 634.559 340.717 L 634.559 340.717 L 634.559 340.717 Q 636.866 340.717 636.866 338.908 L 636.866 338.908 L 636.866 338.908 Q 636.866 337.289 634.321 337.289 L 634.321 337.289 L 633.445 337.289 L 633.445 336.419 L 634.333 336.419 L 634.333 336.419 Q 635.374 336.419 635.983 335.959 L 635.983 335.959 L 635.983 335.959 Q 636.592 335.499 636.592 334.684 L 636.592 334.684 L 636.592 334.684 Q 636.592 334.032 636.144 333.661 L 636.144 333.661 L 636.144 333.661 Q 635.697 333.29 634.93 333.29 L 634.93 333.29 L 634.93 333.29 Q 634.346 333.29 633.828 333.448 L 633.828 333.448 L 633.828 333.448 Q 633.311 333.606 632.647 334.032 L 632.647 334.032 L 632.136 333.35 L 632.136 333.35 Q 632.684 332.918 633.399 332.672 L 633.399 332.672 L 633.399 332.672 Q 634.114 332.425 634.906 332.425 L 634.906 332.425 L 634.906 332.425 Q 636.202 332.425 636.921 333.019 L 636.921 333.019 L 636.921 333.019 Q 637.639 333.612 637.639 334.647 Z "
                                      id="Path"
                                      fill="rgb(0,44,95)"
                                    />
                                  </g>
                                </g>
                              </switch>
                            </svg>
                          </button>
                        </li>
                      </ul>
                    )}

                    {carOffers.models.length === 2 && (
                      <ul className="list-inline d-inline-block dots mb-0">
                        <li className="selectedIndicator d-inline-block">
                          {/* 1 */}
                          <button
                            className="btn btn-link  d-flex align-items-center buttonIndicators"
                            data-indicator="0"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              style={{ isolation: 'isolate' }}
                              viewBox="624.5 326.5 21 21"
                              width="21"
                              height="21"
                            >
                              <defs>
                                <clipPath id="_clipPath_VAMz5J1a7GD4hOZJucCnj5LKlqQzn1v1">
                                  <rect
                                    x="624.5"
                                    y="326.5"
                                    width="21"
                                    height="21"
                                  />
                                </clipPath>
                              </defs>
                              <switch>
                                <g clipPath="url(#_clipPath_VAMz5J1a7GD4hOZJucCnj5LKlqQzn1v1)">
                                  <g id="Group">
                                    <circle
                                      vectorEffect="non-scaling-stroke"
                                      cx="635"
                                      cy="337"
                                      r="10"
                                      id="Ellipse"
                                      fill="rgb(245,245,245)"
                                      strokeWidth="1"
                                      stroke="rgb(0,44,95)"
                                      strokeLinejoin="miter"
                                      strokeLinecap="square"
                                      strokeMiterlimit="3"
                                    />
                                    <path
                                      d=" M 635.741 332.55 L 635.741 341.45 L 634.754 341.45 L 634.754 335.107 L 634.754 335.107 Q 634.754 334.315 634.803 333.609 L 634.803 333.609 L 634.803 333.609 Q 634.675 333.737 634.517 333.877 L 634.517 333.877 L 634.517 333.877 Q 634.359 334.017 633.068 335.064 L 633.068 335.064 L 632.532 334.37 L 634.888 332.55 L 635.741 332.55 Z "
                                      id="Path"
                                      fill="rgb(0,44,95)"
                                    />
                                  </g>
                                </g>
                              </switch>
                            </svg>
                          </button>
                        </li>
                        <li className="nextIndicator d-inline-block">
                          {/* 2 */}
                          <button
                            className="btn btn-link  d-flex align-items-center buttonIndicators"
                            data-indicator="1"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              style={{ isolation: 'isolate' }}
                              viewBox="624.5 325.5 21 21"
                              width="21"
                              height="21"
                            >
                              <defs>
                                <clipPath id="_clipPath_9H6bzwVFW8VMf2CnWchnZqVWeimiKEbh">
                                  <rect
                                    x="624.5"
                                    y="325.5"
                                    width="21"
                                    height="21"
                                  />
                                </clipPath>
                              </defs>
                              <switch>
                                <g clipPath="url(#_clipPath_9H6bzwVFW8VMf2CnWchnZqVWeimiKEbh)">
                                  <g id="Group">
                                    <circle
                                      vectorEffect="non-scaling-stroke"
                                      cx="634.9999999999999"
                                      cy="336"
                                      r="10"
                                      id="Ellipse"
                                      fill="rgb(245,245,245)"
                                      strokeWidth="1"
                                      stroke="rgb(0,44,95)"
                                      strokeLinejoin="miter"
                                      strokeLinecap="square"
                                      strokeMiterlimit="3"
                                    />
                                    <path
                                      d=" M 637.925 339.577 L 637.925 340.514 L 632.075 340.514 L 632.075 339.643 L 634.419 337.288 L 634.419 337.288 Q 635.49 336.204 635.831 335.741 L 635.831 335.741 L 635.831 335.741 Q 636.172 335.279 636.342 334.84 L 636.342 334.84 L 636.342 334.84 Q 636.513 334.402 636.513 333.897 L 636.513 333.897 L 636.513 333.897 Q 636.513 333.184 636.081 332.767 L 636.081 332.767 L 636.081 332.767 Q 635.648 332.35 634.881 332.35 L 634.881 332.35 L 634.881 332.35 Q 634.327 332.35 633.831 332.533 L 633.831 332.533 L 633.831 332.533 Q 633.335 332.716 632.726 333.197 L 632.726 333.197 L 632.191 332.509 L 632.191 332.509 Q 633.42 331.486 634.869 331.486 L 634.869 331.486 L 634.869 331.486 Q 636.123 331.486 636.835 332.128 L 636.835 332.128 L 636.835 332.128 Q 637.548 332.77 637.548 333.854 L 637.548 333.854 L 637.548 333.854 Q 637.548 334.7 637.073 335.528 L 637.073 335.528 L 637.073 335.528 Q 636.598 336.356 635.295 337.622 L 635.295 337.622 L 633.347 339.528 L 633.347 339.577 L 637.925 339.577 Z "
                                      id="Path"
                                      fill="rgb(0,44,95)"
                                    />
                                  </g>
                                </g>
                              </switch>
                            </svg>
                          </button>
                        </li>
                      </ul>
                    )}

                    {carOffers.models.length !== 1 && (
                      <div className="d-inline-block">
                        <button
                          id="next"
                          className="btn btn-link  d-flex align-items-center"
                        >
                          <i className="fa fa-chevron-right fa-fw" />
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <FooterNavbar />
        <Footer />
      </div>
    );
  }
}
