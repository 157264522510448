import React, { Component } from 'react';
import { Link } from 'react-router-dom';
//-- CSS
import './postItemSingular.css';

export default class PostItemSingular extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageSrc: props.imageSrc,
      imgAlt: props.imageAlt,
      title: props.title,
      closesText: props.closesText || '',
      link: props.link,
      buttonText: props.buttonText || 'Enter',
    };
  }

  render() {
    const {imageSrc, imgAlt ,title ,closesText,link,buttonText} = this.state;

    return (
      <div className="col-md-12 pb-5">
        <div className="theme-postItemSingular-item-border  p-3">
          <div className="row">
            <div className="col-md-5">
              <img className="img-fluid" src={imageSrc} alt={imgAlt} />
            </div>
            <div className="col-md-7">
              <div className="h-100">
                <div className=" d-flex h-75">
                  <div className="align-self-center">
                    <h5 className="card-title ">{title}</h5>
                    {closesText.length > 0 && (
                      <p className="small">{closesText}</p>
                    )}
                  </div>
                </div>
                <div className="align-self-end  h-25">
                  <Link
                    to={link}
                    title={title}
                    className="btn btn-primary removeRadius btn-block"
                  >
                    {buttonText}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
