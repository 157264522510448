import React, { Component } from 'react';
import Dates from '../../utils/DateFormat';
import ReminderField from './reminderField';
import Validation from '../../utils/Validation';
import Store from 'store';
export default class ReminderList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      updatedService: false,
      vehicleIndex: props.vehicleIndex,
      reminders: ReminderList.BuildReminders(props.vehicle),
      fromFacebook:
        (Store.get('campaign_name') &&
          Store.get('campaign_name') === 'facebook') ||
        false,
    };

    this.handleReminderChange = this.handleReminderChange.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      prevState.reminders == null ||
      prevState.vehicleIndex != nextProps.vehicleIndex
    ) {
      return {
        ...prevState,
        vehicleIndex: nextProps.vehicleIndex,
        reminders: ReminderList.BuildReminders(nextProps.vehicle),
      };
    }
    return null;
  }
  static CheckDateOption = (date, reminder) => {
    //If the date is null or mindate and a reminder time is selected then mark as invalid
    if (date == null && reminder != 0) {
      return false;
    }

    var newDate = new Date(date);
    if (newDate.getFullYear() == 1 && reminder != 0) {
      return false;
    }

    return true;
  };

  static BuildReminders = (vehicle) => {
    if (vehicle == null) {
      return null;
    }

    return {
      service: {
        date: Dates.parseDateFromApi(vehicle.nextServiceDate),
        remindAnnually: vehicle.serviceReminderEveryYear,
        reminderOption: vehicle.nextServiceDateReminderOption,
        isValid:
          Validation.validateReminder(
            vehicle.nextServiceDate,
            vehicle.nextServiceDateReminderOption,
          ) == null
            ? true
            : false,
        errorMessage: Validation.validateReminder(
          vehicle.nextServiceDate,
          vehicle.nextServiceDateReminderOption,
        ),
      },
      mot: {
        date: Dates.parseDateFromApi(vehicle.nextMotDate),
        remindAnnually: vehicle.motRenewalReminderEveryYear,
        reminderOption: vehicle.nextMotReminderOption,
        isValid:
          Validation.validateReminder(
            vehicle.nextMotDate,
            vehicle.nextMotReminderOption,
          ) == null
            ? true
            : false,
        errorMessage: Validation.validateReminder(
          vehicle.nextMotDate,
          vehicle.nextMotReminderOption,
        ),
      },
      insurance: {
        date: Dates.parseDateFromApi(vehicle.insuranceRenewal),
        reminderOption: vehicle.insuranceRenewalReminderOption,
        remindAnnually: vehicle.insuranceReminderEveryYear,
        isValid:
          Validation.validateReminder(
            vehicle.insuranceRenewal,
            vehicle.insuranceRenewalReminderOption,
          ) == null
            ? true
            : false,
        errorMessage: Validation.validateReminder(
          vehicle.insuranceRenewal,
          vehicle.insuranceRenewalReminderOption,
        ),
      },
      roadTax: {
        date: Dates.parseDateFromApi(vehicle.roadTaxDue),
        remindAnnually: vehicle.roadTaxReminderEveryYear,
        reminderOption: vehicle.roadTaxDueReminderOption,
        isValid:
          Validation.validateReminder(
            vehicle.roadTaxDue,
            vehicle.roadTaxDueReminderOption,
          ) == null
            ? true
            : false,
        errorMessage: Validation.validateReminder(
          vehicle.roadTaxDue,
          vehicle.roadTaxDueReminderOption,
        ),
      },
      breakdown: {
        date: Dates.parseDateFromApi(vehicle.breakdownCoverRenewal),
        reminderOption: vehicle.breakdownCoverRenewalReminderOption,
        remindAnnually: vehicle.breakdownCoverReminderEveryYear,
        isValid:
          Validation.validateReminder(
            vehicle.breakdownCoverRenewal,
            vehicle.breakdownCoverRenewalReminderOption,
          ) == null
            ? true
            : false,
        errorMessage: Validation.validateReminder(
          vehicle.breakdownCoverRenewal,
          vehicle.breakdownCoverRenewalReminderOption,
        ),
      },
    };
  };

  handleReminderChange(name, reminder) {
    if (name === 'service') {
      this.setState((prevState) => {
        return {
          updatedService: true,
          reminders: {
            ...prevState.reminders,
            [name]: reminder,
          },
        };
      });
    } else {
      this.setState((prevState) => {
        return {
          updatedService: false,
          reminders: {
            ...prevState.reminders,
            [name]: reminder,
          },
        };
      });
    }
  }

  handleSaveClick = () => {
    if (this.isValid()) {
      this.setState({
        isLoading: true,
      });

      var editingVehicle = this.props.vehicle;

      editingVehicle.nextServiceDate = Dates.formateDateForApi(
        this.state.reminders.service.date,
      );
      editingVehicle.nextServiceDateReminderOption = this.state.reminders.service.reminderOption;

      editingVehicle.insuranceRenewal = Dates.formateDateForApi(
        this.state.reminders.insurance.date,
      );
      editingVehicle.insuranceRenewalReminderOption = this.state.reminders.insurance.reminderOption;

      editingVehicle.roadTaxDue = Dates.formateDateForApi(
        this.state.reminders.roadTax.date,
      );
      editingVehicle.roadTaxDueReminderOption = this.state.reminders.roadTax.reminderOption;

      editingVehicle.nextMotDate = Dates.formateDateForApi(
        this.state.reminders.mot.date,
      );
      editingVehicle.nextMotReminderOption = this.state.reminders.mot.reminderOption;

      editingVehicle.breakdownCoverRenewal = Dates.formateDateForApi(
        this.state.reminders.breakdown.date,
      );
      editingVehicle.breakdownCoverRenewalReminderOption = this.state.reminders.breakdown.reminderOption;

      editingVehicle.serviceReminderEveryYear = this.state.reminders.service.remindAnnually;
      editingVehicle.motRenewalReminderEveryYear = this.state.reminders.mot.remindAnnually;
      editingVehicle.roadTaxReminderEveryYear = this.state.reminders.roadTax.remindAnnually;
      editingVehicle.insuranceReminderEveryYear = this.state.reminders.insurance.remindAnnually;
      editingVehicle.breakdownCoverReminderEveryYear = this.state.reminders.breakdown.remindAnnually;

      this.props.orFranDidIt(
        editingVehicle,
        Store.get('campaign_name') || '',
        this.state.updatedService,
      );
    }
  };

  isValid() {
    return (
      this.state.reminders.service.isValid &&
      this.state.reminders.insurance.isValid &&
      this.state.reminders.roadTax.isValid &&
      this.state.reminders.mot.isValid &&
      this.state.reminders.breakdown.isValid
    );
  }

  render() {
    const isValid = this.isValid();

    return (
      <div>
        <div className="row">
          <div className="col-sm-6 mb-4 position-relative">
            {this.state.fromFacebook && (
              <div className="theme-reminders-essentials-img-container">
                <img
                  className="img-fluid bg-white"
                  src="images/essentials/esensetials_logo.png"
                />
              </div>
            )}

            <ReminderField
              title="Service"
              name="service"
              reminder={this.state.reminders.service}
              vehicleIndex={this.state.vehicleIndex}
              onChange={this.handleReminderChange}
            />
          </div>

          <div className="col-sm-6 mb-4">
            <ReminderField
              title="MOT Renewal"
              name="mot"
              reminder={this.state.reminders.mot}
              vehicleIndex={this.state.vehicleIndex}
              onChange={this.handleReminderChange}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6 mb-4">
            <ReminderField
              title="Road Tax"
              name="roadTax"
              reminder={this.state.reminders.roadTax}
              vehicleIndex={this.state.vehicleIndex}
              onChange={this.handleReminderChange}
            />
          </div>
          <div className="col-sm-6 mb-4">
            <ReminderField
              title="Insurance"
              name="insurance"
              reminder={this.state.reminders.insurance}
              vehicleIndex={this.state.vehicleIndex}
              onChange={this.handleReminderChange}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6 mb-4">
            <ReminderField
              title="Breakdown Cover"
              name="breakdown"
              reminder={this.state.reminders.breakdown}
              vehicleIndex={this.state.vehicleIndex}
              onChange={this.handleReminderChange}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 offset-md-6">
            {!isValid && (
              <div className="text-left text-danger py-3">
                <small>
                  <i className="fas fa-exclamation-triangle" /> Please correct
                  the errors above before continuing.
                </small>
              </div>
            )}
            <button
              className="btn btn-primary btn-sm removeRadius w-100"
              onClick={this.handleSaveClick}
            >
              {this.props.isLoading ? (
                <div>
                  <i className="fas fa-spinner fa-spin fa-lg text-white" />
                </div>
              ) : (
                <span>Save details</span>
              )}
            </button>

            {this.props.saveSuccess && (
              <div className="text-center text-success">
                <p />
                <small>
                  <i className="fas fa-check-circle" /> Changes have been saved
                </small>
              </div>
            )}
            {this.props.saveFailed && (
              <div className="text-center text-danger">
                <p />
                <small>
                  <i className="fas fa-exclamation-triangle" /> Error in saving
                  changes
                </small>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
