import React, { Component } from 'react';
import Dates from '../../utils/DateFormat';
import ReminderOption from './reminderOption';
import Validation from '../../utils/Validation';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import './reminders.css';

//plugin https://reactdatepicker.com/

export default class ReminderField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: props.reminder.errorMessage,
      vehicleIndex: props.vehicleIndex,
      reminder: props.reminder,
      rawDate:
        props.reminder.date === null
          ? null
          : Dates.formatDate(props.reminder.date),
      date: props.reminder.date == null ? null : moment(props.reminder.date),
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.vehicleIndex === nextProps.vehicleIndex) {
      return null;
    }

    return {
      errorMessage: nextProps.reminder.errorMessage,
      vehicleIndex: nextProps.vehicleIndex,
      reminder: nextProps.reminder,
      date:
        nextProps.reminder.date == null
          ? null
          : moment(nextProps.reminder.date),
      isValid: nextProps.isValid,
      rawDate:
        nextProps.reminder.date == null
          ? null
          : Dates.formatDate(nextProps.reminder.date),
    };
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.reminder.date !== this.props.reminder.date) {
      this.setState({
        date:
          this.props.reminder.date == null
            ? null
            : moment(this.props.reminder.date),
      });
    }
  };

  handleDateChange = (date) => {
    var errorMessage;

    if (date && this.state.reminder.reminderOption === 0) {
      //Set default option
      this.setState({ reminder: { reminderOption: 1 } });
    }
    if (date == null) {
      this.setState({ reminder: { reminderOption: 0 } });
      errorMessage = null;
    }

    this.setState((prevState) => {
      return {
        date: date,
        reminder: {
          ...prevState.reminder,
          date: date == null ? null : date.toDate(),
          isValid: errorMessage == null,
        },
        errorMessage: errorMessage,
      };
    }, this.raiseOnChange);
  };

  handleDateChangeRaw = (event) => {
    const target = event.target;
    const value = target.value;

    var errorMessage = Validation.validateDate(value);

    this.setState((prevState) => {
      return {
        ...prevState,
        rawDate: value,
        reminder: {
          ...prevState.reminder,
          isValid: errorMessage == null,
        },
        errorMessage: errorMessage,
      };
    }, this.raiseOnChange);
  };

  handleDateSelected = (date) => {
    this.setState({
      rawDate: date == null ? '' : Dates.formatDate(date.toDate()),
    });
  };

  handleReminderOptionChange = (option) => {
    if (option === 0) {
      // clear the field
      return this.setState(
        {
          date: null,
          rawDate: null,
          reminder: { reminderOption: 0, isValid: true, remindAnnually: false },
          errorMessage: null,
        },
        this.validate,
      );
    }

    if (option !== 0 && this.state.date == null) {
      console.log("i'm here!!");
      //show calendar
      return this._calendar.setOpen(true);
    }

    this.setState((prevState) => {
      return {
        ...prevState,
        reminder: {
          ...prevState.reminder,
          reminderOption: option,
        },
      };
    }, this.validate);
  };

  onClickOutside = (e) => {
    if (this.state.date == null) {
      this.setState({ reminder: { reminderOption: 0, isValid: true } });
    }
  };

  handleRemindAnnuallyChanged = (event) => {
    const value = event.target.checked;
    this.setState((prevState) => {
      return {
        ...prevState,
        reminder: {
          ...prevState.reminder,
          remindAnnually: value,
        },
      };
    }, this.raiseOnChange);
  };

  validate() {
    this.setState((prevState) => {
      if (
        prevState.rawDate != null &&
        prevState.rawDate.length === 0 &&
        prevState.reminder.reminderOption === 0
      ) {
        return {
          ...prevState,
          reminder: {
            ...prevState.reminder,
            isValid: true,
          },
          errorMessage: null,
        };
      }

      var errorMessage = Validation.validateReminder(
        this.state.rawDate || null,
        this.state.reminder.reminderOption,
      );

      return {
        ...prevState,
        reminder: {
          ...prevState.reminder,
          isValid: errorMessage == null,
        },
        errorMessage: errorMessage,
      };
    }, this.raiseOnChange);
  }

  raiseOnChange() {
    if (this.props.onChange != null) {
      this.props.onChange(this.props.name, this.state.reminder);
    }
  }

  render() {
    const { date } = this.state;

    const remindAnnually = this.state.reminder.remindAnnually || false;
    const { reminderOption, isValid } = this.state.reminder;

    return (
      <div className="card rem theme-my-reminders-card h-100">
        <div className="card-header remh">{this.props.title}</div>
        <div className="card-body pt-3 px-3 pb-0">
          <small>Due Date</small>

          <div
            className={
              'theme-style-date-form-control ' +
              (this.state.reminder.isValid ? '' : 'is-invalid')
            }
          >
            <DatePicker
              ref={(c) => (this._calendar = c)}
              className={
                'form-control form-control-sm flatInput ' +
                (this.state.reminder.isValid ? '' : 'is-invalid')
              }
              placeholderText="dd/mm/yyyy"
              dateFormat="DD/MM/YYYY"
              fixedHeight
              minDate={moment().add(1, 'weeks')}
              maxDate={moment().add(10, 'years')}
              openToDate={moment().add(1, 'weeks')}
              selected={date}
              onSelect={this.handleDateSelected}
              onChange={this.handleDateChange}
              onChangeRaw={this.handleDateChangeRaw}
              showYearDropdown={true}
              scrollableYearDropdown
              isClearable={true}
              readOnly={true}
              onClickOutside={this.onClickOutside}
              yearDropdownItemNumber={10}
              showMonthDropdown
            />
          </div>

          <p />
          <div className="d-flex flex-wrap justify-content-around">
            <ReminderOption
              lineOne="Not"
              lineTwo="Set"
              value={0}
              selected={reminderOption === 0}
              onChange={this.handleReminderOptionChange}
            />
            <ReminderOption
              lineOne="Two"
              lineTwo="Weeks"
              value={2}
              selected={reminderOption === 2}
              disabled={date < moment().add(14, 'days')}
              onChange={this.handleReminderOptionChange}
            />
            <ReminderOption
              lineOne="Four"
              lineTwo="Weeks"
              value={7}
              selected={reminderOption === 7}
              disabled={date < moment().add(28, 'days')}
              onChange={this.handleReminderOptionChange}
            />
            <ReminderOption
              lineOne="Six"
              lineTwo="Weeks"
              value={8}
              selected={reminderOption === 8}
              disabled={date < moment().add(42, 'days')}
              onChange={this.handleReminderOptionChange}
            />
          </div>

          {[2, 7, 8].includes(reminderOption) && isValid && (
            <div className="custom-control custom-checkbox mb-1">
              <input
                className="custom-control-input"
                type="checkbox"
                id={this.props.name + 'checkBox'}
                checked={remindAnnually}
                onChange={this.handleRemindAnnuallyChanged}
              />
              <label
                className="custom-control-label pl-0"
                htmlFor={this.props.name + 'checkBox'}
              >
                <small>Remind me every year</small>
              </label>
            </div>
          )}

          {this.state.errorMessage != null && (
            <div className="text-left text-danger mb-2">
              <small>
                <i className="fas fa-exclamation-triangle" />{' '}
                {this.state.errorMessage}
              </small>
            </div>
          )}
        </div>
      </div>
    );
  }
}
