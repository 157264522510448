import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';
import Store from 'store';
import ReactGA from 'react-ga';
export default class Newi203 extends Component {
  componentDidMount = () => {};

  clickHandler = () => {};

  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="newi20" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="oneMillion-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/hyundai-new-i20-04.jpg") ',
              }}
            />
            {/* <div
              id="Bayon-gallery-2"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/hyundaielectric2.jpg")',
              }}
            /> */}
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                {/* <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#Bayon-gallery-2"
                    style={{
                      backgroundImage: 'url("images/hyundaielectric2.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#Bayon-gallery-1"
                    style={{
                      backgroundImage: 'url("images/hyundaielectric1.jpg")',
                    }}
                  />
                </div> */}
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “New i20 attracts with elegant and sporty design”
                </p>
              </div>
              <hr />

              <NewsMedia articleId="awards" articleText="awards" />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    NEW i20 ATTRACTS WITH ELEGANT AND SPORTY DESIGN
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    {' '}
                    <ul>
                      <li>
                        Hyundai i20 to draw in customers with refreshed design
                        and bold colour combinations
                      </li>
                      <li>
                        Model equipped with latest and class leading smart
                        technologies
                      </li>
                      <li>
                        New i20 continues to offer practicality and safety with
                        comfort and convenience features
                      </li>
                      <li>
                        UK pricing, specification and technical information
                        still to be confirmed
                      </li>
                      {/* <li>
                        Hyundai is investing £1 million in a programme of school
                        trips in order to prevent children missing out on such
                        experiences
                      </li>
                      <li>
                        Bookings are now open for the initiative, which has
                        launched with Hyundai’s Educator in Residence, Tim
                        Campbell MBE
                      </li> */}
                    </ul>
                  </p>
                  {displayShowMore && (
                    <p>
                      <a
                        data-toggle="collapse"
                        href="#arcticEnvironment"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </div>

              <div className={moreSectionClassName} id="arcticEnvironment">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      <strong>
                        Hyundai Motor has revealed the new i20, which will hit
                        roads with a refreshed, stylish design. The car’s safety
                        package and best-in-class connectivity features belong
                        to the most comprehensive packages in its class.
                      </strong>
                      <br />
                      <br />
                      <b>Elegant and sporty design</b>
                      <br /> The new i20 boasts a sleek and modern exterior that
                      makes an eye-catching statement on the road. Shape and
                      pattern of the front bumper, as well as the radiator
                      grille, have been enhanced to create an elegant look with
                      sporty elements.
                    </p>
                    <img
                      src="images/hyundai-new-i20-02.jpg"
                      className="img-fluid"
                    />
                    <br />
                    <br />
                    The rear features a redesigned bumper and a unique taillight
                    design. Newly designed 16- and 17-inch wheels have been
                    added.
                    <br />
                    <br />
                    <img
                      src="images/hyundai-new-i20-03.jpg"
                      className="img-fluid"
                    />
                    <br />
                    <br />
                    New i20’s design has been praised for its emotional and
                    dynamic styling that harmonises proportion, architecture,
                    styling and technology on both the exterior and interior.
                    The model retains its sporty stance thanks to its low roof
                    profile and long wheelbase. These features enhance the
                    vehicle’s aerodynamics by lowering air resistance, which
                    also improves handling and heightens fuel efficiency. A
                    dynamic light signature emphasises its overall bold look,
                    punctuated by Z-shaped LED rear lamps. Even with compact
                    B‑segment dimensions, the new i20 offers customers plenty of
                    interior room, as well as 352 litres of boot space with the
                    rear seats up, and 1,165 litres with the rear seats folded
                    down.
                    <br />
                    <br />
                    This new addition to Hyundai’s line-up is available in eight
                    exterior colours and an optional black two-tone roof. Three
                    exterior colours are brand-new: Lucid Lime Metallic, Lumen
                    Grey Pearl, and Meta Blue Pearl. Returning colours include
                    Atlas White, Phantom Black Pearl, Aurora Grey Pearl, Dragon
                    Red Pearl, and Mangrove Green Pearl.
                    <br />
                    <br />
                    <img
                      src="images/hyundai-new-i20-04.jpg"
                      className="img-fluid"
                    />
                    <br />
                    <br />
                    The vibrant new Lucid Lime interior colour package will
                    reinvigorate and brighten passengers’ moods. Outfitted with
                    this package, the seats will also feature a new sporty
                    pattern with a bold vertical line and embossed horizontal
                    dotted lines. The lime-coloured stitching decorating various
                    touchpoints in the interior underlines the sporty character
                    of the model.
                    <br />
                    <br />
                    <img
                      src="images/hyundai-new-i20-05.jpg"
                      className="img-fluid"
                    />
                    <br />
                    <br />
                    Seamless technology The new i20 comes packed with the latest
                    connectivity features that make the in-car experience even
                    more convenient for occupants. The model has been upgraded
                    with a standard 4.2-inch LCD cluster, USB type-C,
                    second-generation eCall based on the 4G network, and
                    Over-the-Air (OTA) map updates.
                    <br />
                    <br /> It continues to offer features that were already
                    popular in the previous version, such as the optional
                    10.25-inch cluster and 10.25-inch display screen, Apple
                    CarPlay and Android Auto, wireless device charger, and the
                    latest update to Bluelink® telematics.
                    <br />
                    <br /> More Hyundai Smart Sense safety features now come as
                    standard. Forward Collision-Avoidance Assist (FCA) for
                    ‘City’, ‘Interurban’, and ‘Pedestrian’ now includes
                    ‘Cyclist’. FCA helps detect and avoid potential accidents
                    with obstacles ahead. Lane Following Assist (LFA) ensures
                    that the vehicle stays in the current lane of travel.
                    <br />
                    <br /> For additional peace of mind, optional safety systems
                    are also available. Rear Cross-traffic Collision Assist
                    (RCCA) applies the brakes while backing out of a parking
                    space if a risk of collision with vehicles to the rear and
                    side is detected. Blind-spot Collision-avoidance Assist
                    (BCA) uses visual alerts that appear on the exterior mirrors
                    if another vehicle is detected. When necessary, BCA
                    activates to avoid a collision or reduce the impact of a
                    collision. Navigation-based Smart Cruise Control (NSCC) uses
                    the vehicle’s navigation system to anticipate curves or
                    straights on motorways and adjusts the speed for safer
                    driving.
                    <br />
                    <br />
                    <img
                      src="images/hyundai-new-i20-06.jpg"
                      className="img-fluid"
                    />
                    <br />
                    <br />
                    <b>Enhanced convenience for more comfort</b>
                    <br />
                    <br /> A smart choice for European drivers, the new i20 will
                    attract customers looking for an elegant and sporty design.
                    The refreshed model has replaced lightbulbs with LED
                    technology for better in-car illumination and now features
                    multicoloured ambient lights, so passengers can adjust the
                    colour of the interior lighting to fit their mood. The car
                    comes available with a BOSE® Premium Sound System.
                    <br />
                    <br /> <b>
                      Engine options for optimised fuel consumption
                    </b>{' '}
                    <br />
                    <br />
                    <img
                      src="images/hyundai-new-i20-08.jpg"
                      className="img-fluid"
                    />
                    <br />
                    <br />
                    <img
                      src="images/hyundai-new-i20-09.jpg"
                      className="img-fluid"
                    />
                    <br />
                    <br />
                    The new i20’s 1.0 T-GDi engine – available with 100 PS or
                    120 PS is designed for maximum efficiency and performance
                    and is available with a seven-speed dual-clutch transmission
                    (7DCT) or a six-speed Intelligent Manual Transmission (iMT),
                    providing a smooth and responsive driving experience. Upon
                    releasing the accelerator, iMT can decouple the engine from
                    the transmission, effectively saving fuel by allowing the
                    vehicle to enter a coasting mode.
                    <br />
                    <br />
                    <img
                      src="images/hyundai-new-i20-10.jpg"
                      className="img-fluid"
                    />
                    <br />
                    <br />
                    Production of the new i20 is scheduled to begin in the third
                    quarter of 2023 at Hyundai’s manufacturing plant in Izmit,
                    Turkey. Further variants will follow.
                    <br />
                    <br />
                    <b>Hyundai’s i-range</b>
                    <br />
                    <br /> Hyundai’s i-range – comprising of i10, i20, and i30 –
                    has been a success story for the company across Europe. As
                    three of the brand’s most popular models, the i-range
                    champions quality, reliability, and practicality, supporting
                    its diverse customer base in their everyday lives. Over
                    time, the i-range was further developed to offer even more
                    dynamic styling, advanced connectivity features, and
                    uncompromised accessibility and comfort.
                    <br />
                    <br />
                    <img
                      src="images/hyundai-new-i20-07.jpg"
                      className="img-fluid"
                    />
                    <br />
                    <br />
                    <b>Disclaimer: CO2 and emissions data</b>
                    <br />
                    <br /> The new i20 is not currently available for sale. CO2
                    values for this model have not yet been homologated. UK
                    specification and pricing is still to be confirmed.
                    <br />
                    <br />
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
