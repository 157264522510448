import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class FeedBackCheckBox extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
  };

  render = () => {
    const { name, id, onChange } = this.props;
    const nameSplit = name
      .split(/(?=[A-Z])+|[ ]/)
      .join('_')
      .toLowerCase();

    return (
      <div className="custom-control custom-checkbox mb-1">
        <input
          type="checkbox"
          className="custom-control-input"
          id={id + '_' + nameSplit}
          name={nameSplit}
          onChange={onChange}
        />
        <label className="custom-control-label" htmlFor={id + '_' + nameSplit}>
          {name}
        </label>
      </div>
    );
  };
}
