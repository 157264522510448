import React, { Component } from "react";

export default class AllNewKona extends Component {
  render() {
    return (
      <div className="container">
        <div className="fadeInDownBigFull">
          <div className="card-body newsPadding">
            <div className="card-deck " id="AllNewKona">
              <div className="card">
                <div className="row">
                  <div className="col-md">
                    <img
                      id="allnewkona-gallery-1"
                      className="card-img-top img-fluid news-gallary-image active"
                      src="images/newKona.jpg"
                      alt="Card cap"
                    />
                  </div>
                </div>

                <div className="card-block">
                  <div className="card-content">
                    <div className="col">
                      <div className="row whiteBG">
                        <div className="col-lg-4">
                          <div className="salesText mt-3">
                            <p className="theme-news-snippet">
                              “Taking pride of place in a cool courtyard in the
                              heart of Italy’s fashion capital”
                            </p>
                          </div>
                          <hr />
                          <div
                            className="d-flex justify-content-around mb-2 largerSI social-share-buttons"
                            data-article-id="AllNewKona"
                            data-article-text="All New Kona"
                          />
                        </div>

                        <div className="col-lg-8 border-lg-left">
                          <div className="row pt-3">
                            <div className="col-md-10 offset-md-1">
                              <p className="salesText" data-search-tag="title">
                                IT’S HERE! ALL-NEW KONA IS UNVEILED IN MILAN
                              </p>
                            </div>
                          </div>

                          <div className="row pt-3">
                            <div className="col-md-10 offset-md-1">
                              <div
                                className="theme-news-body-text"
                              >
                                <p data-search-tag="summary">
                                  <strong>June 2017 –</strong> The All-New KONA
                                  must have set a world record for ‘selfies with
                                  an SUV’ when it was revealed in Milan last
                                  night Hundreds of lifestyle bloggers, fashion
                                  vloggers, influencers and plain old car
                                  journalists crowded around the stage at the
                                  European launch event in an attempt to be the
                                  first to post pictures of the much-anticipated
                                  SUV on social media.
                                </p>
                                <p>
                                  Taking pride of place in a cool courtyard in
                                  the heart of Italy’s fashion capital, the
                                  All-New KONA is Hyundai’s first car to be
                                  released in the fast-growing sub-compact SUV
                                  category.{" "}
                                </p>
                                <p>
                                  “The All-New KONA is an important milestone.
                                  With its true SUV genes, progressive design
                                  and premium features, KONA reflects the
                                  lifestyle of modern customers,” Thomas A.
                                  Schmid, Chief Operating Officer at Hyundai
                                  Motor Europe told the excited guests.{" "}
                                </p>
                                <p>
                                  Compact it may be, but the All-New KONA is
                                  every bit a real SUV thanks to its bold
                                  design, premium features, great connectivity
                                  and some of the latest safety technologies
                                  around.
                                </p>
                                <p>
                                  The front’s bold look is thanks, in part, to
                                  the LED day time running lights being
                                  positioned above the headlights, alongside the
                                  ever more familiar Cascading Grille. From the
                                  side, the car appears compact and agile, its
                                  sleekness emphasised by the short rear
                                  overhangs and wraparound cladding on the front
                                  and rear bumpers. A wide and low stance adds
                                  to the car’s distinctive appearance and
                                  improves handling.
                                </p>
                                <p>
                                  The All-New KONA doeesn’t simply look the
                                  part, it behaves exactly as an SUV should
                                  thanks to the choice of two turbocharged
                                  petrol engines available in two- and
                                  four-wheel drive.{" "}
                                </p>
                                <p>
                                  Next Summer, it will also be available with a
                                  newly developed 1.6-litre four-cylinder
                                  turbocharged diesel engine.
                                </p>
                                <p>
                                  Inside, the car drips with technology. From
                                  the head-up display (a first for Hyundai) that
                                  projects driving information directly into the
                                  driver’s line of sight, to smartphone
                                  connectivity using Apple CarPlay or Android
                                  Auto on the seven-inch screen, to a high-end
                                  sound system by Krell that features eight
                                  speakers and an amplifier, drivers can stay
                                  fully connected throughout their journey.{" "}
                                </p>
                                <p>
                                  And because the All-New KONA is designed for
                                  people with active lifestyles it goes without
                                  saying that it offers some of the latest
                                  active safety features, including Autonomous
                                  Emergency Braking with Pedestrian Detection,
                                  Blind Spot Detection, Rear Cross Traffic{" "}
                                </p>
                                <p>
                                  Alert, Lane Keeping Assist, Driver Attention
                                  Alert, Static Bending Light and Smart High
                                  Beam.
                                </p>
                                <p>
                                  Oh, and with 10 exterior colour options and
                                  two roof designs to choose from, every KONA
                                  owner will be able to play with 20 colour
                                  combinations to find their perfect look. Log
                                  on to{" "}
                                  <a
                                    href="http://www.hyundai.co.uk/"
                                    target="_blank"
                                  >
                                    www.hyundai.co.uk
                                  </a>{" "}
                                  to discover more
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
