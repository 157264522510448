import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class NewsNavWells extends Component {
  render() {
    return (
      <div className="container">
        <div className="fadeInDownBigFull">
          <div className="section1 mt-5">
            <div className="  " id="NewsNavWells">
              <div className="row">
                <div className="col-10 offset-1 ">
                  <div className="row">
                    <div className="col-md-4 mb-3 mb-md-0">
                      <div className="bg-white border-right border-bottom">
                        <Link
                          to={{
                            pathname: '/newsPast',
                            query: { category: 'awards' },
                          }}
                        >
                          <h5 className="font-weight-bold p-3 mb-0">
                            Archived Awards
                          </h5>
                          <img
                            src="images/pastAwardsWell.png"
                            className="img-fluid"
                          />
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-4 mb-3 mb-md-0">
                      {/* <div className="bg-white border-right border-bottom">
                        <Link
                          to={{
                            pathname: '/newsPast',
                            query: { category: 'product' },
                          }}
                        >
                          <h5 className="font-weight-bold p-3 mb-0">
                            Archived Product News
                          </h5>
                          <img
                            src="images/pastProductNewsWell.png"
                            className="img-fluid"
                          />
                        </Link>
                      </div> */}
                    </div>
                    <div className="col-md-4 mb-3 mb-md-0">
                      <div className="bg-white border-right border-bottom">
                        <Link
                          to={{
                            pathname: '/newsPast',
                            query: { category: 'hyundai' },
                          }}
                        >
                          <h5 className="font-weight-bold p-3 mb-0">
                            Archived Hyundai News
                          </h5>
                          <img
                            src="images/pastNewsWell.png"
                            className="img-fluid"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
