import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';
import Store from 'store';
import ReactGA from 'react-ga';
export default class Ioniq6Saloon extends Component {
  componentDidMount = () => {};

  clickHandler = () => {};

  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="ioniq6Saloons" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="oneMillion-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/ioniq6SaloonMain.jpeg") ',
              }}
            />
            {/* <div
              id="Bayon-gallery-2"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/hyundaielectric2.jpg")',
              }}
            /> */}
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                {/* <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#Bayon-gallery-2"
                    style={{
                      backgroundImage: 'url("images/hyundaielectric2.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#Bayon-gallery-1"
                    style={{
                      backgroundImage: 'url("images/hyundaielectric1.jpg")',
                    }}
                  />
                </div> */}
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “New Hyundai IONIQ 6 Named ‘Saloon of the Year’ at 2023 GQ Car
                  Awards”
                </p>
              </div>
              <hr />

              <NewsMedia articleId="awards" articleText="awards" />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    NEW HYUNDAI IONIQ 6 NAMED "SALOON OF THE YEAR" AT 2023 GQ
                    CAR AWARDS
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    {' '}
                    <ul>
                      <li>
                        Hyundai’s high-tech all-electric IONIQ 6 collects top
                        accolade at GQ Car Awards in association with Michelin
                      </li>
                      <li>
                        Judges praise sleek Streamliner styling and futuristic,
                        high-tech feel
                      </li>
                      <li>
                        IONIQ 6 is available to order now, with prices from
                        £46,745
                      </li>
                      {/* <li>
                        Hyundai is investing £1 million in a programme of school
                        trips in order to prevent children missing out on such
                        experiences
                      </li>
                      <li>
                        Bookings are now open for the initiative, which has
                        launched with Hyundai’s Educator in Residence, Tim
                        Campbell MBE
                      </li> */}
                    </ul>
                  </p>
                  {displayShowMore && (
                    <p>
                      <a
                        data-toggle="collapse"
                        href="#ioniq6Saloon"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </div>

              <div className={moreSectionClassName} id="ioniq6Saloon">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      <strong>
                        Hyundai has picked up another prestigious honour for its
                        ever-growing trophy cabinet as the new, all-electric
                        IONIQ 6 was crowned ‘Saloon of the Year’ at the 2023 GQ
                        Car Awards in association with Michelin.
                      </strong>
                      <br />
                      <br />
                      The annual awards seek to identify the world’s finest
                      vehicles, and this year the changing landscape of the car
                      industry was reflected as British GQ decided that all the
                      winners should feature some form of electrification. The
                      expert team of judges said their aim was to recognise
                      models that are “inspiring, have integrity and get pulses
                      racing.”
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      The Hyundai IONIQ 6 emerged as the number one choice in
                      the hotly contested ‘Saloon of the Year’ category, with
                      the judges particularly impressed by the model’s unique
                      Streamliner design.
                      <br />
                      <br />
                      <strong>
                        "From hotter-than-the-sun hatches and sensational SUVs
                        to elegant EVs and killer concepts, everyone knows
                        Hyundai's ambitious design team are having a moment, and
                        the IONIQ 6 continues the brand’s remarkable run. Their
                        latest offering is a four-door ‘Streamliner’ saloon,
                        inspired by the late Art Deco exploration into
                        functional efficiency that showcases the South Koreans’
                        sleek statement styling, offering distinctive and daring
                        architecture with a futuristic, high-tech, pure-electric
                        twist."
                      </strong>{' '}
                      - British GQ
                      {/* <img src="images/expo20302.jpeg" className="img-fluid" /> */}
                    </p>
                    <img
                      src="images/ioniq6Saloon2.jpeg"
                      className="img-fluid"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <br />
                    <br />

                    <p className="theme-news-body-text">
                      {' '}
                      <strong>
                        "On behalf of the Global Design team at Hyundai, I would
                        like to thank British GQ for honouring the IONIQ 6 with
                        the award for Saloon of the Year. We always want to
                        connect with customers on an emotional level and with
                        the design of the IONIQ 6 we have created truly one of a
                        kind. The daring streamliner styling combines strong
                        emotional appeal with aerodynamic efficiency helping to
                        give exceptional range. Inside, we set-out to create a
                        personal studio which offers mindful space and calm
                        functionality which, we believe, elevates EV design."
                      </strong>{' '}
                      - SangYup Lee, Executive Vice President and Head of
                      Hyundai Design,<br></br> <br></br>The IONIQ 6 is based on
                      the same Electric-Global Modular Platform (E-GMP) as the
                      2022 World Car of the Year, the IONIQ 5, and has attracted
                      a hugely positive response since it was unveiled last
                      year. Orders for the IONIQ 6 opened in November, with
                      prices starting at £46,475 and first deliveries due to
                      begin shortly.
                      <br />
                      <br />
                      Customers have a choice of either a rear-wheel drive
                      powertrain offering 228PS and 350Nm or an all-wheel drive
                      powertrain with 325PS and 605Nm. All models feature the
                      long range 77.4 kWh battery, with the E-GMP platform’s
                      standard 800v charging system providing 350kW ultra-fast
                      charger compatibility, and generously equipped Premium and
                      Ultimate trim lines are available. The IONIQ 6 also has
                      impeccable safety credentials, with the European New Car
                      Assessment Programme awarding it ‘Best in Class’ in the
                      ‘Large Family Car’ category.
                      {/* <strong>
                        {' '}
                        "Having updated our brand’s image and pronunciation
                        locally, we wanted to explore pronunciation as a topic
                        more broadly and get people talking about those words
                        that we can potentially struggle with in everyday life,
                        such as quinoa and espresso. We have teamed up with
                        Seong-Jae, a.k.a Korean Billy, who is well known for his
                        light-hearted YouTube videos into different dialects
                        within the UK, to explore some British words from Korean
                        standpoint."
                      </strong>{' '}
                      - Ashley Andrew Managing Director, Hyundai Motor UK */}
                      <br />
                      <br />
                      The recognition from British GQ continues an impressive
                      start to 2023 by Hyundai. In January, the IONIQ 5 was
                      named Large Electric Car of the Year by SME fleet
                      specialist Company Car & Van, while the i20 N was named
                      ‘Hot Hatch of the Year’ and the SANTA FE SUV ‘Seven-seater
                      of the Year’ and ‘Best Plug-in Hybrid for Big Families’ at
                      the What Car? Awards.
                      <br />
                      <br />
                      <strong>
                        "Everyone at Hyundai UK is delighted with this award for
                        the IONIQ 6 from one of the world’s leading luxury and
                        style publications. The car offers a unique combination
                        of sophisticated Streamliner styling and long-distance
                        EV performance and has been very well received by both
                        the media and customers alike. We are extremely
                        confident that it will build on the fantastic success of
                        the ground-breaking IONIQ 5 and contribute to another
                        outstanding year for Hyundai in the UK."
                      </strong>{' '}
                      - Ashley Andrew, Managing Director of Hyundai Motor UK
                      <br />
                      <br />A full feature on all the winners of the 2023 GQ Car
                      Awards in association with Michelin will appear in the
                      March issue of British GQ.
                      {/* <br />
                      <br /> Commenting on the depth and reach of Chung’s
                      vision, MotorTrend added: “In that sense, he does his
                      father and grandfather proud, pushing the entire Hyundai
                      Motor Group to new levels. The man and his vision, and the
                      juggernaut that his company is becoming, loom loud and
                      large. For his vision and leadership, then, Euisun Chung
                      is our 2023 MotorTrend Person of the Year.”
                      <img src="images/execchair2.jpeg" className="img-fluid" /> */}
                      {/* <a
                        href="https://motorstudio.hyundai.com/busan/ln/main.do?strgC=04&lang=en"
                        target="_blank"
                      >
                        Hyundai Motorstudio Busan
                      </a>{' '}
                      , the ‘Reflections in Motion’ exhibition invites visitors
                      to explore and reflect on the interactive relationships
                      between time and design as well as technology and styling,
                      with various featured installations underscoring Hyundai
                      Motor’s vision centered on enabling ‘Progress for
                      Humanity’. */}
                      {/* <br />
                      <br />
                      Also ranked on MotorTrend’s Power List are Luc
                      Donckerwolke, President and Chief Creative Officer of
                      Hyundai Motor Group (3); Jose Muñoz, President and Global
                      Chief Operating Officer of Hyundai Motor Company (10);
                      SangYup Lee, Executive Vice President and Head of Hyundai
                      Global Design Center (28); and Karim Habib (44), Executive
                      Vice President and Head of Kia Global Design Center.
                      <br />
                      <br />
                      Previous recognition for Chung’s pioneering thinking
                      includes the Executive Chair being named Newsweek’s
                      ‘Visionary of the Year’ at its inaugural World’s Greatest
                      Auto Disrupters event in 2022 and being honored with the
                      coveted Issigonis Trophy at the 2021 Autocar Awards. */}
                      {/* <strong>
                        "School trips are an integral part of a child’s
                        educations, and I remember my own outings with such fond
                        memories, even all these years later! What’s more, being
                        able to turn these trips into further learning within
                        the classroom is such an important skill and something
                        that should be available for everyone. This programme is
                        extremely timely and will be crucial in putting school
                        trips firmly back on the agenda"
                      </strong>{' '}
                      - Tim Campbell Hyundai's Educator in Residence;
                      <br /> */}
                      {/* <br />
                      <br />
                      Speaking earlier in January, at a New Year town hall
                      meeting at the Group’s Namyang R&D Center, Chung
                      underlined his continued commitment to the success of the
                      Group. He urged everyone within the organisation to spare
                      no effort to ensure the delivery of the best possible
                      products and services for its customers.
                      <br />
                      <br />
                      <strong>
                        "We must take on challenges based on trust, and the
                        positive results must lead to even stronger trust. No
                        great product or technology is complete without the
                        trust of our customers. Quality is a priority for all of
                        us. From product planning to design, production, sales,
                        and after-sales maintenance, all teams and employees
                        must place quality and the customer as the top priority.
                        We must all go above and beyond to earn the respect and
                        loyalty of our customers."
                      </strong>{' '}
                      - Euisun Chung, The Groups Executive Chair
                      <br />
                      <br /> */}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
