import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Store from 'store';
//-- CSS
import './competitionSideNav.css';
export default class CompetitionSideNav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      competitions: Store.get('competitionInfo'),
    };
  }

  render() {
    return (
      <div data-search-tag="menu" data-search-exclude>
        <Link to="/compsOverview" className="theme-competitionSideNav-title">
          <h4>
            <strong>
              Competition
              <br />
              Overview
            </strong>
          </h4>
        </Link>

        <br />

        {this.state.competitions.map((competition, index) => {
          if (!competition.directLinkOnly) {
            return (
              <Link
                to={{
                  pathname: '/competition',
                  search: '?id=' + competition.id,
                }}
                key={index}
                title={competition.longTitle}
              >
                <small>
                  <p className="asUnderline">{competition.shortTitle}</p>
                </small>
              </Link>
            );
          }
        })}
      </div>
    );
  }
}
