import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';

export default class MostPositiveColour extends Component {
  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="MostPositiveColour" className="col">
        <div className="row">
          <div className="col-md">
            <div className="embed-responsive embed-responsive-16by9 theme-news-header-video">
              <iframe
                width="100%"
                height="450"
                src="https://www.youtube.com/embed/REfd57VrPXg"
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen
              />
            </div>
          </div>
        </div>

        <div className="col bg-white border-right border-bottom">
          <div className="row py-3">
            <div className="col-lg-4">
              <hr />
              <NewsMedia
                articleId="MostPositiveColour"
                articleText="Most Positive Colour"
              />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-8 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    In search of the world’s most positive colour
                  </p>
                  <p className="theme-news-body-text" data-search-tag="summary">
                    “Grey is the colour of neutrality, it puts a dampener on
                    things,” says Angela Wright, a colour psychologist who has
                    studied how different colours influence our mindset. “Nine
                    times out of 10, it has a negative effect on our wellbeing.
                    It’s the colour of austerity.”
                  </p>
                  {displayShowMore && (
                    <p>
                      <a  
                        data-toggle="collapse"
                        href="#collapsePositiveColour"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </div>

              <div className={moreSectionClassName} id="collapsePositiveColour">
                <div className="row pt-3">
                  <div className="col-md-8 offset-md-1">
                    <p className="theme-news-body-text">
                      Well, we thought it was time to do something about that!
                      Determined to inject some positivity and colour into our
                      monochrome motoring world, we set out to find the UK’s
                      most positive colour...{' '}
                    </p>
                    <p className="theme-news-body-text">
                      First, we needed to track positivity and optimism, and so
                      decided to focus on six emotions – stress, boredom,
                      excitement, interest, relaxation and focus.
                    </p>
                    <p className="theme-news-body-text">
                      A number of people – different genders, ages and
                      backgrounds – were hooked up to state-of-the-art
                      technology and left alone in a large room that was flooded
                      with an ever-changing rainbow of colours – greens, blues,
                      yellows, reds – and all the hues in between.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 offset-md-1">
                    <p className="theme-news-body-text">
                      Whilst in the room, they wore a biometric glove to monitor
                      changes in their reactions,a heart rate monitor, and an
                      electroencephalogram to monitor their brain activity. Five
                      volts were run through their skin to measure how much they
                      were sweating.
                    </p>
                    <p className="theme-news-body-text">
                      By measuring how people reacted to the changing colours,
                      we could deduce which colours were leading to higher
                      stress or engagement levels, and could even tell which
                      colours were raising heartbeat and sweat levels.
                    </p>
                  </div>
                  <div className="col-md-4 salesText">
                    <div className="col">
                      <img
                        src="images/colours1.jpg"
                        className="img-fluid pt-2"
                      />
                    </div>
                    <div className="col">
                      <img
                        src="images/colours2.jpg"
                        className="img-fluid pt-2"
                      />
                    </div>
                    <div className="col">
                      <img
                        src="images/colours3.jpg"
                        className="img-fluid pt-2 d-none d-md-block"
                      />
                    </div>
                  </div>
                </div>

                <div className="row pt-3">
                  <div className="col-md-8 offset-md-1">
                    <p className="theme-news-body-text">
                      Everyone reacted strongly to reds, greens and blues. Red
                      was unpleasant for a lot of people. It was nauseating for
                      some, and was giving others a feeling close to vertigo. At
                      the other end of the scale, people found green very
                      relaxing.
                    </p>
                    <p className="theme-news-body-text">
                      But which colour came out on top when it came to inducing
                      positive vibes? Well, it was a mix of indigo and blue.
                      When people encountered it, readings showed they were
                      relaxed, but also strongly focused and engaged. Their
                      heart rate dropped, anxiety levels dipped, and sweat
                      levels went down.
                    </p>
                    <p className="theme-news-body-text">
                      This came as no surprise to colour psychologist Angela.
                      “Green is the colour of reassurance, yellow is for
                      optimism, and red is for stimulation, but time and time
                      again, blue comes out on top when people are asked their
                      favourite colour. While it might not technically be
                      considered an optimistic colour, it is calming and
                      reassuring. Blue is also the colour of intellectual
                      stimulus. It focuses the mind and aids concentration– it’s
                      good to have around if you’re studying for exams,” says
                      Angela. “Blue also helps you to chill out. A lot of people
                      instinctively reach for it because it calms the mind.”
                    </p>
                    <img
                      src="images/colours3.jpg"
                      className="img-fluid pt-2 d-block d-md-none"
                    />
                    <p className="small">03/04/2018</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
