import React, { Component } from 'react';
import './personal.css';
import FooterNavbar from '../components/footerNavbar';
import TopNavbar from '../components/topnavbar';
import BottomNavbar from '../components/bottomnavbar';
import Footer from '../components/footer';
import MyCarNavbar from '../components/myCarNavbar';
import Store from 'store';
import Handbook from '../components/handbook';
import HandbookJS from '../utils/Handbook';

export default class MyCarDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      vehicles: Store.get('vehicles'),
      handbooks: [],
      isLoading: false,
    };
  }

  ContainsHandbook(arr, obj) {
    for (let i = 0; i < arr.length; i++) {
      const h = arr[i];
      if (h.name == obj.name) return true;
    }
    return false;
  }

  componentWillMount() {
    const handbooks = [...HandbookJS.GetHandbooks(this.state.vehicles)];
    const uniqueHandbooks = [];

    for (let i = 0; i < handbooks.length; i++) {
      const h = handbooks[i];
      if (!this.ContainsHandbook(uniqueHandbooks, h)) uniqueHandbooks.push(h);
    }

    this.setState({
      handbooks: uniqueHandbooks,
    });
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    const vehicles = Store.get('vehicles');

    this.setState({
      vehicles: vehicles,
    });
  }

  render() {
    const handbooks = this.state.handbooks;
    const classes = HandbookJS.GetClassStructure(handbooks);
    const nameClass = HandbookJS.GetNameStructure(handbooks);
    const fontSize = HandbookJS.GetFontSize(handbooks);

    return (
      <div>
        <TopNavbar />
        <BottomNavbar />
        <MyCarNavbar />

        <div className="container position-relative" style={{ zIndex: 50 }}>
          <section
            className="section1 wow fadeIn"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <div className="content p-3 p-xl-5 col-md-10 offset-md-1 border-right border-bottom">
              <div className="row">
                <div className="col-lg-4 p-3 p-sm-4">
                  <h4 className="h4ServHistory" data-search-tag="title">
                    Vehicle Handbooks
                  </h4>
                  <p style={{ fontSize: 'small', color: '#6B6B6B' }}>
                    Make the most of your Hyundai by downloading your vehicle
                    handbook. It’s packed with information and instructions to
                    help you operate and maintain your vehicle. Learn more about
                    your engine specifications, advanced safety features,
                    operations and where to locate key vehicle information.
                    Always keep it close to hand for quick reference at home or
                    on the road
                  </p>
                  <hr />
                </div>

                <div className="col ml-lg-4 p-sm-4 border-lg-left">
                  <h4 className="page-inner-title">Your Handbook</h4>
                  <div className="row">
                    {handbooks.map((handbook) => (
                      <Handbook
                        value={handbook}
                        key={handbook.name}
                        className={classes}
                        name={nameClass}
                        font={fontSize}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <FooterNavbar />

        <Footer />
      </div>
    );
  }
}
