import React, { Component } from 'react';
import ArchiveNewsNavbar from '../components/ArchiveNewsNavbar';
import FooterNavbar from '../components/footerNavbar';
import Topnavbar from '../components/topnavbar';
import Bottomnavbar from '../components/bottomnavbar';
import Footer from '../components/footer';

export default class ArchivedAwardsNews extends Component {
  render() {
    return (
      <div className="">
        <Topnavbar />
        <Bottomnavbar />
        <ArchiveNewsNavbar />
        ArchivedAwardsNews
        <FooterNavbar />
        <Footer />
      </div>
    );
  }
}
