import React, { Component } from "react";

export default class Hy2017GenevaMotorShow extends Component {
  render() {
    return (
      <div className="container">
        <div className="fadeInDownBigFull">
          <div className="card-body newsPadding">
            <div className="card-deck " id="Hy2017GenevaMotorShow">
              <div className="card">
                <div className="row">
                  <div className="col-md">
                    <img
                      id="Hy2017GenevaMotorShow-gallery-1"
                      className="card-img-top img-fluid news-gallary-image active"
                      src="images/hy2017GenMotorShow.jpg"
                      alt="Card image cap"
                    />
                  </div>
                </div>

                <div className="card-block">
                  <div className="card-content">
                    <div className="col">
                      <div className="row whiteBG">
                        <div className="col-lg-4">
                          <div className="salesText mt-3">
                            <p className="theme-news-snippet">
                              “Two world premieres at the Hyundai booth in
                              Geneva: The New Generation i30 Tourer and the FE
                              Fuel Cell concept”
                            </p>
                          </div>
                          <hr />
                          <div
                            className="d-flex justify-content-around mb-2 largerSI social-share-buttons"
                            data-article-id="Hy2017GenevaMotorShow"
                            data-article-text="HYUNDAI 2017 Geneva Motor Show"
                          />
                        </div>

                        <div className="col-lg-8 border-lg-left">
                          <div className="row pt-3">
                            <div className="col-md-10 offset-md-1">
                              <p className="salesText" data-search-tag="title">
                                HYUNDAI MOTOR AT THE 2017 GENEVA INTERNATIONAL
                                MOTOR SHOW
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <div className="theme-news-body-text">
                                <ul>
                                  <li>
                                    Two world premieres at the Hyundai booth in
                                    Geneva: The New Generation i30 Tourer and
                                    the FE Fuel Cell concept
                                  </li>
                                  <li>
                                    The i30 Tourer is the second member of the
                                    i30 Family enhancing appeal for customers
                                    through its elegant and sleek profile while
                                    offering high versatility with one of
                                    largest boots in the segment
                                  </li>
                                  <li>
                                    The hydrogen-powered FE Fuel Cell concept
                                    gives an exciting outlook at the Next
                                    Generation fuel cell vehicle to be launched
                                    in 2018
                                  </li>
                                  <li>
                                    The IONIQ Plug-in Hybrid completes the IONIQ
                                    family making e-mobility more accessible
                                    while the autonomous IONIQ concept and
                                    robotic legs represent Hyundai future
                                    mobility
                                  </li>
                                  <li>
                                    With the exciting RN30 concept car and the
                                    Hyundai i20 Coupe WRC Hyundai Motor is
                                    presenting its high performance and
                                    motorsport capabilities.
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div className="row pt-3">
                            <div className="col-md-10 offset-md-1">
                              <p
                                className="theme-news-body-text"
                                data-search-tag="summary"
                              >
                                <strong>
                                  Geneva Motor Show, 07 March 2017
                                </strong>{" "}
                                – At the Geneva International Motor Show 2017
                                Hyundai Motor celebrates two world premieres:
                                the New Generation i30 Tourer and the
                                hydrogen-powered FE Fuel Cell concept car.
                                Further highlights include the IONIQ Plug-in
                                Hybrid which completes the IONIQ line-up along
                                with the IONIQ Hybrid and Electric version. The
                                Autonomous IONIQ concept showcases Hyundai
                                Motor’s future mobility vision and gives
                                visitors a chance to experience the technology
                                virtually while the exoskeletons display the
                                future of personal mobility. Hyundai Motor
                                sparks emotions in the high performance area
                                with the thrilling RN30 concept that is building
                                anticipation of the future high performance N
                                models as well as the Hyundai i20 Coupe WRC.
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <div className="theme-news-body-text">
                                <p>
                                  New Generation Hyundai i30 Wagon: elegance
                                  meets versatility
                                </p>
                                <p>
                                  Geneva is the stage for the unveiling of the
                                  New Generation Hyundai i30 Tourer. The new
                                  member of the i30 family retains the timeless
                                  design of its five-door sibling, adding
                                  elegance and versatility to its body style.
                                  With 602 litres of space (VDA 211) and 1,650
                                  litres (VDA 214) with the seats folded, the
                                  Tourer has one of the largest boots in this
                                  segment. Versatility is further enhanced by
                                  the additional storage space beneath the boot
                                  floor. Full-LED headlamps and vertical LED
                                  daytime running lights make for a strong
                                  visual appearance.
                                </p>
                                <p>
                                  Thomas Bürkle, Chief Designer at Hyundai
                                  Design Centre Europe, says: “The i30 Tourer
                                  speaks the language of the i30 family in terms
                                  of design and carries the new Hyundai family
                                  identity – the Cascading Grille. The tapering
                                  roofline and dynamic proportions give the
                                  Wagon an almost coupé-like silhouette. The
                                  sleek and dynamic profile is further
                                  emphasised by a rich chrome frame surrounding
                                  the side windows.”
                                </p>
                                <p>
                                  Thomas A. Schmid, Chief Operating Officer at
                                  Hyundai Motor Europe, says: “Designed,
                                  developed, tested and manufactured in Europe,
                                  the New Generation Hyundai i30 is Hyundai
                                  Motor’s DNA car for Europe. The i30 was
                                  created as the new people’s car. It is more
                                  than a model – it is a family of cars
                                  consisting of four body types, each with a
                                  unique character and united by a coherent,
                                  timeless design. With the new i30 Tourer we
                                  are now introducing the next body type,
                                  offering elegance and versatility for
                                  everyone.”
                                </p>
                                <p>
                                  Hyundai Motor reveals next generation
                                  fuel-cell concept
                                </p>
                                <p>
                                  In Geneva, Hyundai Motor is also unveiling its
                                  Future Eco (FE) Fuel Cell Concept, which
                                  foreshadows the next generation of
                                  hydrogen-powered vehicles. With a range of
                                  more than 800 kilometres between refuelling,
                                  the FE Fuel Cell Concept represents the next
                                  step for Hyundai Motor towards realising its
                                  ultimate ambition of creating a zero-emission
                                  Hydrogen Energy Society.
                                </p>
                                <p>
                                  Woong Chul Yang, Vice Chairman of Hyundai
                                  Motor Company, says: “Hyundai Motor has a
                                  heritage of building innovative,
                                  fuel-efficient vehicles and aims to become a
                                  global leader in sustainable mobility through
                                  its leadership in hydrogen fuel-cell
                                  technology. We want to realise our dream of a
                                  Hydrogen Energy Society and call upon
                                  stakeholders from various sectors, not just
                                  carmakers, to combine their efforts to ensure
                                  that hydrogen production, delivery and
                                  infrastructure are aligned.”
                                </p>
                                <p>
                                  Combining the best of both worlds: the IONIQ
                                  Plug-in Hybrid joins the line-up
                                </p>
                                <p>
                                  Hyundai Motor is also showcasing the IONIQ
                                  line-up with the primary focus on the IONIQ
                                  Plug-in Hybrid, due to its imminent arrival at
                                  European showrooms in the summer. IONIQ is the
                                  world’s first car with three electrified
                                  powertrains and part of Hyundai Motor’s global
                                  sustainability strategy to launch 14 eco cars
                                  by 2020, including five hybrid vehicles, four
                                  plug-in hybrid vehicles, four electric
                                  vehicles and one fuel-cell electric vehicle.
                                </p>
                                <p>
                                  The IONIQ Plug-in Hybrid combines the new 1.6
                                  GDI four-cylinder direct-injection petrol
                                  engine with a class-leading thermal efficiency
                                  of 40 percent, delivering a maximum power of
                                  105 PS and 147 Nm of torque with a 45 kW (61
                                  PS) electric motor powered by a 8.9 kWh
                                  lithium-ion polymer battery to enable an
                                  electric driving range up to 63 km while
                                  reducing CO2 emissions to 26 g/km* and fuel
                                  consumption to 1.1 l/100 km* (NEDC combined
                                  cycle).
                                </p>
                                <p>
                                  An autonomous IONIQ simulator will also be
                                  part of Hyundai’s presence at this year’s
                                  Geneva Motor Show. With a sleek design
                                  resembling the rest of the IONIQ line-up, the
                                  autonomous IONIQ is one of the few
                                  self-driving cars being developed to have a
                                  hidden LiDAR system in its front bumper
                                  instead of on the roof. Two virtual-reality
                                  cockpits will give visitors the chance to
                                  experience a drive in an autonomous car at the
                                  stand.
                                </p>
                                <p>
                                  “With the launch of the IONIQ Plug-in Hybrid
                                  we are completing the IONIQ family and making
                                  e-mobility more accessible for everyone.” says
                                  Jochen Sengpiehl, Vice President Marketing at
                                  Hyundai Motor Europe. “With the choice of
                                  three electrified powertrains, the IONIQ is an
                                  unique offer – with no compromise as regards
                                  to great design, driving pleasure and new
                                  connectivity features at a competitive price.
                                  It’s a car that is truly driven by e-motion.”
                                </p>
                                <p>
                                  For high performance enthusiasts: RN30 concept
                                  and i20 Coupe WRC
                                </p>
                                <p>
                                  Hyundai Motor is also showcasing the RN30
                                  concept in Geneva, building anticipation of
                                  the future high-performance ‘N’ models. Based
                                  on the New Generation Hyundai i30, the RN30
                                  was born as a racing machine featuring a
                                  2.0-litre turbo engine with 380 PS and an
                                  all-wheel drive.
                                </p>
                                <p>
                                  The IONIQ Plug-in enhanced the digital driving
                                  experience through its seven-inch digital
                                  information cluster. With a high resolution of
                                  1,280 x 720 pixels, the TFT screen displays
                                  all cluster information like the vehicle
                                  speed, drive range, navigation information,
                                  drive modes, battery status etc. Depending on
                                  the selected drive mode, the layout of
                                  instrument cluster is changing. In Sport Mode
                                  the display changes into a revolving digital
                                  speedometer surrounded by an analogue-style
                                  tachometer, indicating the engine’s rpm on red
                                  coloured background.
                                </p>
                                <p>
                                  Albert Biermann, Head of Vehicle Testing &
                                  High Performance Development at Hyundai Motor,
                                  says: “The RN30 embodies the concept of a
                                  strong, high-performance car that ensures
                                  dynamic, sporty driving. Soon to evolve into
                                  our first N model, the RN30 is inspired by our
                                  passion to provide a high-performance car that
                                  everybody can enjoy effortlessly.”
                                </p>
                                <p>
                                  Hyundai Motorsport is also presenting its
                                  current i20 Coupe WRC challenger at the Geneva
                                  Motor Show. Based on the Hyundai i20 Coupe, it
                                  has been developed to meet the WRC’s 2017
                                  technical regulations, which have given
                                  designers greater aerodynamic freedom.
                                </p>
                                <p>
                                  Team Principal Michel Nandan says: “The
                                  changes will raise the level of entertainment
                                  in the WRC all over the world, with wider and
                                  more powerful cars. We have been putting our
                                  experience from two complete WRC seasons into
                                  practice as we aim to build on our successful
                                  2016 campaign.”
                                </p>
                                <p>
                                  A personal mobility revolution with advanced
                                  wearable robots
                                </p>
                                <p>
                                  H-MEX, HUMA and H-WEX are Hyundai Motor’s
                                  latest innovations in advanced assistive
                                  medical, work and daily life exoskeletons. On
                                  show at the 2017 Geneva Motor Show, they
                                  facilitate personal mobility beyond the
                                  automobile. The H-MEX (Hyundai Medical
                                  Exoskeleton) helps patients with lower
                                  spinal-cord injuries to regain the ability to
                                  walk. HUMA (Hyundai Universal Medical Assist)
                                  exoskeleton provides assisted mobility support
                                  for people with limited muscular power.
                                  Meanwhile, the H-WEX (Hyundai Waist
                                  Exoskeleton) provides upper-body and hip
                                  support to prevent back injuries for workers
                                  doing repetitive manual work or heavy lifting.
                                </p>
                                <p>
                                  Tae Won Lim, Head of Hyundai Motor Central
                                  Advanced Research and Engineering Institute,
                                  says: “We have a vision that Hyundai Motor
                                  will become more than just a manufacturer of
                                  cars and the advances we make in assistive
                                  robotic technologies will allow us to offer
                                  customers new levels of mobility freedom. In
                                  future, we hope our pioneering exoskeleton
                                  devices will enrich the daily lives of users
                                  and form the basis for us to provide more
                                  mobility platforms for the well-being of our
                                  customers.”
                                </p>
                                <p>
                                  * Fuel efficiency, CO2 emission and driving
                                  range data is provisional, subject to
                                  homologation.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
