import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';

export default class TucsonLongDistance extends Component {
  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="TucsonLongDistance" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="TucsonLongDistance-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/Tucson-SPT-AB.png") ',
              }}
            />
          </div>
        </div>

        <div className="card-block">
          <div className="card-content">
            <div className="col">
              <div className="row whiteBG">
                <div className="col-lg-4">
                  <div className="salesText mt-3">
                    <p className="theme-news-snippet">
                      “Hyundai Tucson wins Auto Trader’s Best Car for Long
                      Distances in New Car Awards”
                    </p>
                  </div>
                  <hr />
                  <NewsMedia
                    articleId="TucsonLongDistance"
                    articleText="TucsonLong Distance"
                  />
                </div>

                <div className="col-lg-8 border-lg-left">
                  <div className="row pt-3">
                    <div className="col-md-10 offset-md-1">
                      <p className="salesText" data-search-tag="title">
                        HYUNDAI TUCSON NAMED BEST CAR FOR LONG DISTANCES BY REAL
                        CAR OWNERS AT AUTO TRADER NEW CAR AWARDS
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-10 offset-md-1">
                      <div className="theme-news-body-text">
                        <ul>
                          <li>
                            Hyundai Tucson wins Auto Trader’s Best Car for Long
                            Distances in New Car Awards
                          </li>
                          <li>
                            Tucson praised for comfort, quiet cruising and
                            practicality
                          </li>
                          <li>
                            Auto Trader’s New Car Awards are voted for by 43,000
                            real car owners
                          </li>
                          <li>Tucson available from £20,160</li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="row pt-3 ">
                    <div className="col-md-10 offset-md-1 ">
                      <div className="theme-news-body-text">
                        {' '}
                        <p data-search-tag="summary">
                          <strong>High Wycombe, 30 May 2018 –</strong>The
                          Hyundai Tucson has been announced as the Best Car for
                          Long Distances in the Auto Trader New Car Awards 2018.
                          Voted for by real car owners, they praised the Tucson
                          for its comfortable ride, space and luxury equipment.{' '}
                        </p>
                        {displayShowMore && (
                          <p>
                            <a  
                              data-toggle="collapse"
                              href="#collapseTucson"
                              role="button"
                              aria-expanded="false"
                            >
                              More{' '}
                              <span>
                                <i className="fas fa-long-arrow-alt-down" />
                              </span>
                            </a>
                          </p>
                        )}
                        <div
                          className={moreSectionClassName}
                          id="collapseTucson"
                        >
                          <p>
                            Ivan Aistrop, Auto Trader’s Road Test Editor, said:
                            “The Tucson turned out to be the firm favourite with
                            the high-mileage owners in our survey, and that
                            should be no surprise to anyone. The big Hyundai
                            feels comfortable, stable and settled at motorway
                            cruising speeds, and things also stay pretty quiet
                            on a long motorway schlep. The generous luxury
                            equipment also helps keep you comfortable, connected
                            and entertained, and with good space and
                            practicality, the same should go for those in the
                            back.
                          </p>

                          <p>
                            Hyundai Motor UK President and CEO, Tony Whitehorn,
                            adds: “Tucson has gone from strength to strength
                            since we launched it in 2015 and has become a firm
                            favourite with our customers. With a bold and
                            distinctive design, this compact SUV is also
                            practical and offers a high level of quality and
                            comfort. We are delighted to receive this award as
                            it is a testament to the car’s popularity within
                            this competitive segment.”
                          </p>

                          <p>
                            The Hyundai Tucson is available from £20,160 (1.6
                            GDi 132PS Petrol Blue Drive 2WD Manual) up to
                            £34,230 (2.0 CRDi 185PS Diesel 4WD Automatic). For
                            more information, please visit:{' '}
                            <a  
                              href="https://www.hyundai.co.uk/new-cars/tucson"
                              target="_blank" rel="noopener noreferrer"
                            >
                              https://www.hyundai.co.uk/new-cars/tucson
                            </a>
                          </p>

                          <p>
                            This is the second annual New Car Awards from Auto
                            Trader, the UK’s largest digital automotive
                            marketplace. Unlike other car awards which rely on
                            editorial judging panels, Auto Trader’s New Car
                            Awards were decided by 43,000 real car owners. The
                            12 winners represent the consumer champions of the
                            new car market, based on the honest opinions of
                            those who have bought, driven and lived with their
                            new vehicles.
                          </p>

                          <p>
                            Erin Baker, Auto Trader’s Editorial Director, said:
                            “The Auto Trader New Car Awards champion the aspects
                            of driving and car ownership that really matter to
                            drivers and that aren’t always obvious in the buying
                            process – like reliability, safety, running costs
                            and driving experience.
                          </p>

                          <p>
                            “Our winners have the seal of approval from real
                            owners, which we hope will make finding the right
                            new car much simpler for car buyers. Congratulations
                            to all our well-deserving winners for continuing to
                            push the boundaries of innovation and offering car
                            buyers fantastic choice to suit every budget.”
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
