import React, { Component } from 'react';
import ArchiveNewsNavbar from '../components/ArchiveNewsNavbar';
import FooterNavbar from '../components/footerNavbar';
import TopNavbar from '../components/topnavbar';
import BottomNavbar from '../components/bottomnavbar';
import Footer from '../components/footer';
import NewsUtils from '../utils/News';

// Archived Product News --------------------------------------------------------------
import NextGenFuel from '../components/news/archivedHyundaiNews/nextGenFuel';
import AutonomousIoniq from '../components/news/archivedHyundaiNews/autonomousIoniq';
import IoniqBest from '../components/news/awards/ioniqBest';
import Hy2017GenevaMotorShow from '../components/news/archivedHyundaiNews/hy2017GenevaMotorShow';
import NewGenSantaFe from '../components/news/archivedProductNews/newGenSantaFe';
import I30FastbackLineUp from '../components/news/archivedProductNews/i30FastBack2';
import KonaElectric from '../components/news/archivedProductNews/konaElectric';
import AllNewKona from '../components/news/archivedProductNews/allNewKona';
import Kona from '../components/news/archivedProductNews/kona';
import I3024hrRace from '../components/news/archivedProductNews/i3024hrRace';
import SouthPole from '../components/news/archivedProductNews/southPole';
import NewGeni30Tourer from '../components/news/archivedProductNews/newGeni30Tourer';
import IoniqPlugin from '../components/news/archivedProductNews/ioniqPlugin';
import WinterTestingSweden from '../components/news/archivedProductNews/winterTestingSweden';
import I30WagonMotorShow from '../components/news/archivedProductNews/i30WagonMotorShow';
import FourthTimei10 from '../components/news/archivedAwards/fourthTimei10';
import IoniqBestSmallHatch from '../components/news/archivedAwards/ioniqBestSmallHatch';
import IoniqBestCityCar from '../components/news/archivedAwards/ioniqBestCityCar';
import IoniqGreenCarAwards from '../components/news/archivedAwards/ioniqGreenCarAwards';

export default class ArchivedProductsNews extends Component {
  constructor(props) {
    super(props);
    var displayArticle = null;

    this.myRef = React.createRef();

    const archivedProductNews = [
      {
        name: 'I30FastbackLineUp',
        linkText: "'Sophisticated' i30 Fastback Completes the Line-up",
        date: 'February 2018',
        component: <I30FastbackLineUp />,
        image: 'images/autonomousIoniq.jpg',
      },
      {
        name: 'KonaElectric',
        linkText: 'A Car of No Compromise: All-New Hyundai Kona Electric',
        date: 'February 2018',
        component: <KonaElectric />,
        image: 'images/konaElectric.jpg',
      },
      {
        name: 'IoniqBest',
        linkText: 'It’s Official! Ioniq Is the Uk’s Best Hybrid',
        date: 'January 2018',
        component: <IoniqBest />,
        image: 'images/i10BestCityCar.jpg',
      },
      {
        name: 'NewGenSantaFe',
        linkText:
          'Hyundai Motor Releases First Teaser Image of the Fourth Generation Santa Fe',
        date: 'January 2018',
        component: <NewGenSantaFe />,
        image: 'images/ngSantaFe.jpg',
      },
      {
        name: 'FourthTimei10',
        linkText: 'i10 Wins Award for the Fourth Time Running. Honest!',
        date: 'June 2017',
        component: <FourthTimei10 />,
        image: 'images/i10FourthTime.jpg',
      },
      {
        name: 'AllNewKona',
        linkText: 'It’s Here! All-New Kona Is Unveiled in Milan',
        date: 'June 2017',
        component: <AllNewKona />,
        image: 'images/newKona.jpg',
      },
      {
        name: 'Kona',
        linkText:
          'Hyundai Motor Expands Its Suv Family with the All-New Hyundai Kona',
        date: 'April 2017',
        component: <Kona />,
        image: 'images/kona.jpg',
      },
      {
        name: 'I3024hrRace',
        linkText:
          'Hyundai Motor to Test Close to Production i30 N in Endurance Race in Nürburgring Ahead of 24 Hours Race Participation',
        date: 'April 2017',
        component: <I3024hrRace />,
        image: 'images/24hrRace.jpg',
      },
      {
        name: 'SouthPole',
        linkText:
          'Unleash Your Inner Explorer: Own the Family Car That Drove to the South Pole and Back',
        date: 'April 2017',
        component: <SouthPole />,
        image: 'images/southPole.jpg',
      },
      {
        name: 'AutonomousIoniq',
        linkText:
          'Hyundai Motor Presents Autonomous Ioniq at the 2017 Geneva Motor Show',
        date: 'March 2017',
        component: <AutonomousIoniq />,
        image: 'images/autonomousIoniq.jpg',
      },
      {
        name: 'NewGeni30Tourer',
        linkText:
          'New Generation Hyundai i30 Tourer: Elegance Meets Versatility',
        date: 'March 2017',
        component: <NewGeni30Tourer />,
        image: 'images/ngi30Tourer.jpg',
      },
      {
        name: 'IoniqPlugin',
        linkText:
          'Combining the Best of Both Worlds: Ioniq Plug-In at the 2017 Geneva International Motor Show',
        date: 'March 2017',
        component: <IoniqPlugin />,
        image: 'images/ioniqPlugin.jpg',
      },
      {
        name: 'Hy2017GenevaMotorShow',
        linkText: 'Hyundai Motor at the 2017 Geneva International Motor Show',
        date: 'March 2017',
        component: <Hy2017GenevaMotorShow />,
        image: 'images/hy2017GenMotorShow.jpg',
      },
      {
        name: 'NextGenFuel',
        linkText:
          'Hyundai Motor Reveals Next Generation Fuel Cell Concept at 2017 Geneva Motor Show',
        date: 'March 2017',
        component: <NextGenFuel />,
        image: 'images/nextGenFuel.jpg',
      },
      {
        name: 'WinterTestingSweden',
        linkText:
          'Hyundai Motor’s First High-Performance Model: The Hyundai i30 N – Winter Testing in Sweden with Thierry Neuville',
        date: 'March 2017',
        component: <WinterTestingSweden />,
        image: '',
      },
      {
        name: 'I30WagonMotorShow',
        linkText:
          'Hyundai Motor Shows First Impression of New Generation i30 Wagon',
        date: 'February 2017',
        component: <I30WagonMotorShow />,
        image: 'images/ngi30Wagon.jpg',
      },
      {
        name: 'IoniqBestSmallHatch',
        linkText:
          'Ioniq Continues to Charge Ahead - Awarded Best Small Hatch at the Uk Car of the Year Awards 2017',
        date: 'January 2017',
        component: <IoniqBestSmallHatch />,
        image: 'images/ioniqSmallHatchAward.jpg',
      },
      {
        name: 'IoniqBestCityCar',
        linkText:
          'The Best Things Come in Small Packages - New Hyundai i10 Wins Best City Car at 2017 What Car? Awards',
        date: 'January 2017',
        component: <IoniqBestCityCar />,
        image: 'images/i10BestCityCar.jpg',
      },
      {
        name: 'IoniqGreenCarAwards',
        linkText: 'Hyundai Ioniq Electrifies at the Next Green Car Awards',
        date: 'December 2016',
        component: <IoniqGreenCarAwards />,
        image: 'images/ioniqGreenCarAwards.jpg',
      },
    ];

    this.state = {
      archivedProductNews: archivedProductNews,
      displayArticle: displayArticle,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);

    NewsUtils.attachThumbnailClick();
  }

  componentDidUpdate() {
    NewsUtils.attachThumbnailClick();
  }

  handleNavClick(article) {
    this.setState({
      displayArticle: article,
    });

    window.scrollTo({
      top: this.myRef.current.offsetTop + -20,
      behavior: 'smooth',
    });
  }

  backToTop() {
    window.scrollTo(0, 0);
  }

  render() {
    const defaultArticle = this.state.archivedProductNews[0];
    const displayArticle =
      this.state.displayArticle != null
        ? this.state.displayArticle
        : defaultArticle;

    const archivedProductNews = this.state.archivedProductNews.map(
      (article, index) => {
        return (
          <li key={index} className="nav-item mb-4 mb-md-3">
            <a  
              className="nav-link"
              data-search-component-link={article.name}
              onClick={() => this.handleNavClick(article)}
            >
              {article.image && (
                <div
                  className="theme-news-archive-title-image rounded-top d-md-none  "
                  style={{ backgroundImage: 'url(' + article.image + ' )' }}
                />
              )}
              {!article.image && (
                <div className="theme-news-archive-no-image border-top border-right  mt-4 d-md-none " />
              )}
              <div className="theme-news-archive-mobile-link-border">
                <p className="mb-md-0 pr-3 pt-3 pt-md-0">{article.linkText}</p>
              </div>
            </a>
            <div className="news-link-date d-none d-md-block">
              {article.date}
            </div>
          </li>
        );
      },
    );

    return (
      <div className="">
        <TopNavbar />
        <BottomNavbar />
        <ArchiveNewsNavbar />
        <div className="container">
          <div className="pt-4 pb-3 text-center">
            <h4 className="h4ServHistory d-lg-none ">
              <span className="newsPageTitle">Archived Product News</span>
            </h4>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-4 mb-5 mb-md-0">
              <div
                className="news-nav-container"
                data-search-category="product"
              >
                <ul className="nav flex-column">{archivedProductNews}</ul>
              </div>
            </div>
            <div
              className="col-12 col-md-8 border-md-left border-lg-left"
              ref={this.myRef}
            >
              <div className="row">
                {displayArticle != null && (
                  <div
                    className="theme-news-archive-container-mobile"
                    data-search-component={displayArticle.name}
                  >
                    {React.cloneElement(displayArticle.component, {
                      displayShowMore: false,
                    })}
                  </div>
                )}
              </div>

              <button
                type="button"
                className="btn btn-link float-right"
                onClick={this.backToTop}
              >
                Back to Top
              </button>
            </div>
          </div>
        </div>

        <FooterNavbar />
        <Footer />
      </div>
    );
  }
}
