import React, { Component } from "react";

export default class MexicanPodium extends Component {
  render() {
    return (
      <div className="container">
        <div className="fadeInDownBigFull">
          <div className="card-body newsPadding">
            <div className="card-deck " id="MexicanPodium">
              <div className="card">
                <div className="row">
                  <div className="col-md">
                    <img
                      id="MexicanPodium-gallery-1"
                      className="card-img-top img-fluid news-gallary-image active"
                      src="images/mexicanPodium.jpg"
                      alt="Card image cap"
                    />
                  </div>
                </div>

                <div className="card-block">
                  <div className="card-content">
                    <div className="col">
                      <div className="row whiteBG">
                        <div className="col-lg-4">
                          <div className="salesText mt-3">
                            <p className="theme-news-snippet">
                              “Hayden Paddon completed the rally in fifth place
                              to score important Championship points”
                            </p>
                          </div>
                          <hr />
                        </div>

                        <div className="col-lg-8 border-lg-left">
                          <div className="row pt-3">
                            <div className="col-md-10 offset-md-1">
                              <p className="salesText" data-search-tag="title">
                                HYUNDAI MOTORSPORT SECURES MEXICAN PODIUM AND
                                POWER STAGE VICTORY
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <div className="theme-news-body-text">
                                <ul>
                                  <li>
                                    Hyundai Motorsport has claimed its first
                                    podium finish of the 2017 FIA World Rally
                                    Championship (WRC) as Thierry Neuville
                                    clinched third in Rally México
                                  </li>
                                  <li>
                                    Hayden Paddon completed the rally in fifth
                                    place to score important Championship points
                                  </li>
                                  <li>
                                    Neuville won the Power Stage to take five
                                    extra points with Dani Sordo also scoring.
                                    Hyundai Motorsport took six stage wins in
                                    total in Mexico.
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div className="row pt-3">
                            <div className="col-md-10 offset-md-1">
                              <p
                                className="theme-news-body-text"
                                data-search-tag="summary"
                              >
                                <strong>March 12, 2017 –</strong> Hyundai
                                Motorsport has opened its 2017 podium tally
                                after Thierry Neuville completed Rally México in
                                third position overall.
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <div className="theme-news-body-text">
                                <p>
                                  After disappointing results in Monte-Carlo and
                                  Sweden, in which they were forced to
                                  relinquish a commanding lead, the Belgian crew
                                  of Neuville and co-driver Nicolas Gilsoul
                                  finally returned to the top-three after a
                                  solid weekend. They finished the rally in
                                  style with a Power Stage win and five extra
                                  points.
                                </p>
                                <p>Back on the podium</p>
                                <p>
                                  The result marks Hyundai Motorsport’s 21st WRC
                                  podium in an event where the team also claimed
                                  its 100thstage win this weekend. It also
                                  heralds the team’s second podium in Mexico,
                                  repeating the feat from its debut season in
                                  2014 when Neuville also scored third.
                                </p>
                                <p>
                                  Hayden Paddon scored his first top-five result
                                  of the year while Dani Sordo was forced to
                                  settle for eighth after 17 contested stages.
                                  Fuel pressure issues hampered all three crews
                                  on Friday, which prevented them from mounting
                                  a stronger fight for the overall victory.{" "}
                                </p>
                                <p>
                                  The final morning of the rally was far from
                                  straightforward with rain falling early on. A
                                  total of 54.90 competitive kilometres were
                                  run, with the 32.96km La Calera test followed
                                  by the 21.94km Derramadero Power Stage. Still,
                                  all Hyundai Motorsport crews completed the
                                  rally to pick up important points for both
                                  Manufacturers’ and Drivers’ Championships.
                                </p>
                                <p>
                                  Crew Notes: Neuville/Gilsoul (#5 Hyundai i20
                                  Coupe WRC)
                                </p>
                                <p>
                                  Neuville and Gilsoul took a total of four
                                  stage wins during a tricky Mexico weekend to
                                  reiterate their 2017 credentials. After issues
                                  on Friday, the Belgian duo opted to focus on
                                  securing a commendable podium, on which they
                                  will build for the next round in Corsica,
                                  where they finished second less than six
                                  months ago.{" "}
                                </p>
                                <p>
                                  Neuville said: “I can only say that I am
                                  really happy to have finished on the podium
                                  here in Mexico. It’s dedicated to the whole
                                  team of Hyundai Motorsport who have kept
                                  pushing me after the disappointment of the
                                  first two rallies. I described Monte and
                                  Sweden as fast but frustrating, so it’s great
                                  to be back in the top-three and scoring Power
                                  Stage points today. The team has worked hard
                                  this weekend, to fix the car after some
                                  problems on Friday. It was their persistence
                                  and determination that allowed us to stay in
                                  the podium positions, so thanks to them.
                                  Onwards and upwards.”
                                </p>
                                <p>
                                  Crew Notes: Paddon/Kennard (#4 Hyundai i20
                                  Coupe WRC)
                                </p>
                                <p>
                                  Fifth place will give Paddon and co-driver
                                  John Kennard a morale boost as they look to
                                  reassert themselves in the 2017 Championship.
                                  The Kiwis have taken a measured approach in
                                  the face of difficulties this weekend to
                                  register a solid finish – their best of the
                                  season so far.
                                </p>
                                <p>
                                  Paddon commented: “There’s not much to say
                                  about this rally, in which we had some
                                  problems. We can only take consolation from a
                                  top-five finish, but with some more luck we
                                  know we can be fighting higher up – and for
                                  the podium. It’s been good to be back on
                                  gravel, it’s just unfortunate that we couldn’t
                                  show a better performance. We can only keep on
                                  working in the hope that the tide will turn
                                  soon. Corsica is next, where I’m hopeful we
                                  can get better results.”
                                </p>
                                <p>
                                  Crew Notes: Sordo/Martí (#6 Hyundai i20 Coupe
                                  WRC)
                                </p>
                                <p>
                                  Sordo and compatriot co-driver Marc Martí
                                  showed real intent and promise in Mexico,
                                  demonstrating their podium potential with two
                                  stage wins on Saturday morning. Losing time in
                                  the Friday runs at the León autodrome – due to
                                  fuel pressure issues - pushed the Spaniards
                                  out of contention but they will look to
                                  overturn their misfortune as WRC returns on
                                  tarmac in Corsica next month.
                                </p>
                                <p>
                                  Sordo said: “I am pleased to get to the end of
                                  this rally, but I’m trying to remain positive.
                                  It’s not been an easy weekend for us. We have
                                  battled against problems, which has not given
                                  us the confidence we needed to push hard. We
                                  had some complications with the wipers this
                                  morning, which was definitely interesting when
                                  it was raining on the first stage. We did all
                                  we could in the Power Stage, and I’m happy to
                                  take one point. We haven’t had much luck in
                                  Mexico so we have to just put it behind us and
                                  focus on the next round, Tour de Corse.”
                                </p>
                                <p>Pushing the reset button</p>
                                <p>
                                  Hyundai Motorsport hopes that today’s podium
                                  will be the start of even greater things to
                                  come this season after a character building
                                  start to 2017.
                                </p>
                                <p>
                                  Team Principal Michel Nandan said: “We have
                                  not had an easy weekend here in Mexico so I
                                  think we can be satisfied to take a podium
                                  finish and the Power Stage win. Thierry and
                                  Nicolas really deserved that. Unlike Monte and
                                  Sweden, where we clearly had the pace and
                                  reliability to win the rally, we’ve faced a
                                  few issues this weekend, which have prevented
                                  our crews from pushing for victory. We’ve
                                  worked through everything methodically as a
                                  team, but we need to review things carefully
                                  when we get back to the factory. It has been a
                                  useful learning weekend; not forgetting it’s
                                  our first event on gravel with our new car.
                                  The manufacturers battle is close this season;
                                  I remain confident we will find our rhythm and
                                  register our first win with the Hyundai i20
                                  Coupe WRC sooner rather than later. For now,
                                  we leave Mexico with lessons learned and
                                  concentrating on the upcoming events.”
                                </p>
                                <p>Next Rally</p>
                                <p>
                                  The WRC moves back to Europe for the fourth
                                  round of the season, and the tarmac tests of
                                  Tour de Corse, which moves to an earlier
                                  spring date.
                                </p>
                                <p>
                                  The rally will take place from 6-9 April, just
                                  six months after its 2016 event in which
                                  Hyundai Motorsport finished in second place.
                                </p>
                                <p>
                                  Final Overall Classification - Rally Mexico{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
