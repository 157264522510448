import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';
import Store from 'store';
import ReactGA from 'react-ga';
export default class HealthySeas extends Component {
  componentDidMount = () => {};

  clickHandler = () => {};

  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="healthySeas" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="healthySeas-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/hs1.jpg") ',
              }}
            />
            {/* <div
              id="Bayon-gallery-2"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/hyundaielectric2.jpg")',
              }}
            /> */}
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                {/* <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#Bayon-gallery-2"
                    style={{
                      backgroundImage: 'url("images/hyundaielectric2.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#Bayon-gallery-1"
                    style={{
                      backgroundImage: 'url("images/hyundaielectric1.jpg")',
                    }}
                  />
                </div> */}
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “Hyundai partners with Healthy Seas to foster a sustainable
                  future”
                </p>
              </div>
              <hr />

              <NewsMedia articleId="awards" articleText="awards" />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    HYUNDAI PARTNERS WITH HEALTHY SEAS TO FOSTER A SUSTAINABLE
                    FUTURE
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    {' '}
                    <ul>
                      <li>
                        In pursuit of the company’s goal to establish
                        sustainable leadership, Hyundai Motor has announced a
                        pan-European partnership with the marine conservation
                        organisation Healthy Seas
                      </li>
                      <li>
                        By partnering with Healthy Seas, Hyundai is furthering
                        its commitment to “Progress for Humanity” with a
                        particular focus on the seas and oceans
                      </li>
                      <li>
                        Based in the Netherlands, Healthy Seas aims to free the
                        oceans of abandoned fishing nets and other debris
                        through education, prevention, and clean-up activities
                      </li>
                      <li>
                        Partnership activities will take place across Europe,
                        starting in Greece
                      </li>
                    </ul>
                  </p>
                  {displayShowMore && (
                    <p>
                      <a
                        data-toggle="collapse"
                        href="#HealthySeas"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </div>

              <div className={moreSectionClassName} id="HealthySeas">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      13 April 2021 -{' '}
                      <strong>
                        Hyundai Motor is partnering with the ocean conservation
                        organisation Healthy Seas to combat ocean pollution,
                        nurture sustainable marine ecosystems, and support a
                        circular economy.
                      </strong>
                      <br />
                      <br />
                      As the company leading the way in clean and future
                      mobility solutions, cleaning up and preventing marine
                      pollution aligns with Hyundai’s global strategy. By
                      re-establishing a healthy relationship with nature,
                      Hyundai plans to improve human wellbeing, in line with its
                      vision of “Progress for Humanity”.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      By collaborating with Healthy Seas, Hyundai will be not
                      only providing a cleaner environment for generations to
                      come but also shaping sustainable futures for the
                      communities it works with.
                      <p
                        style={{
                          color: '#ac6b4f',
                          fontSize: '1rem',
                          fontStyle: 'italic',
                        }}
                      >
                        "As a purpose-driven company, Hyundai understands that
                        recycling and sustainability aren’t trends, but rather a
                        need for our society. Through our progressive,
                        inclusive, and responsible spirit, we are committed to
                        the development of a sustainable society. This is why
                        our strategy tackles environmental problems head on and
                        works with communities to safeguard a sustainable
                        future."
                      </p>
                      <p className="theme-news-body-text">
                        <span style={{ fontSize: '1rem' }}>
                          <strong>Michael Cole</strong>
                        </span>{' '}
                        <br />
                        <span style={{ color: 'dimgray' }}>
                          President and CEO of Hyundai Motor Europe
                        </span>
                      </p>
                      <video width="451" height="300" controls>
                        <source src="images/hs4.mp4" />
                      </video>
                      Healthy Seas, a journey from Waste to Wear
                      <br />
                      <br />
                      The world’s seas and oceans play a major role in
                      sustaining life on Earth, but they are constantly
                      threatened. A joint report by the Food and Agriculture
                      Organization of the United Nations and the United Nations
                      Environment Programme estimates that more than 580 million
                      kilogrammes of fishing gear are discarded in the ocean
                      annually, accounting for one-tenth of all marine litter.
                      Hyundai has chosen to partner with Healthy Seas because
                      the Dutch organisation strives to combat this issue.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      <img src="images/hs5.jpg" className="img-fluid" />
                      <br />
                      <br />
                      Healthy Seas’ activities are threefold. Firstly, they work
                      with teams of volunteer divers from the charity
                      organisation Ghost Diving to recover abandoned fishing
                      nets, known as ‘ghost nets’, from coral reefs and
                      shipwrecks. These fragile ecosystems are underwater
                      hotspots for marine biodiversity. By removing marine
                      debris and ocean plastic, Healthy Seas ensures the
                      survival of a healthy underwater ecosystem for a
                      sustainable future.
                      <br />
                      <br />
                      <img src="images/hs2.jpg" className="img-fluid" />
                      <br />
                      <br /> Healthy Seas also organises educational programmes
                      at local schools to teach children the importance of
                      keeping the world’s oceans clean. In hopes of preventing
                      more ocean pollution, younger generations learn about the
                      negative effects it can have on marine and human life.
                      <br />
                      <br /> Finally, Healthy Seas collaborates with fishermen,
                      fish farms, and local communities to prevent fishing nets
                      from ending up in landfills or the environment. Together,
                      they work on solutions to dispose of the nets responsibly.
                      <br />
                      <br /> Working with a network of partners, Healthy Seas
                      ensures plastic waste is recycled into new materials in a
                      circular economy. Reclaimed fishing nets and other nylon
                      waste can become ECONYL®, a regenerated nylon yarn that
                      can, in turn, be used to make new products.
                      <br />
                      <br />
                      <img src="images/hs3.jpg" className="img-fluid" />
                      <br />
                      <br />
                      <span style={{ fontSize: '1rem' }}>
                        Working together to improve communities
                      </span>
                      <br />
                      <br />
                      This partnership with Hyundai will allow Healthy Seas to
                      expand its programmes all over Europe. Initial partnership
                      activities will begin April 2021 with clean-up efforts to
                      be rolled out in Greece, followed by Norway, Germany, the
                      UK, the Netherlands, Spain, Italy, and France, as well as
                      in the North and Mediterranean Seas.
                      <br />
                      <br />
                      <p
                        style={{
                          color: '#ac6b4f',
                          fontSize: '1rem',
                          fontStyle: 'italic',
                        }}
                      >
                        "We’re excited to begin this cooperation with Hyundai on
                        a pan-European level. It is clear that we share the same
                        passion for protecting the environment with Hyundai’s
                        leadership in developing sustainable mobility solutions
                        and our experience protecting marine ecosystems. With
                        Hyundai’s support and our environmental expertise, we
                        look forward to expanding our operations to clean up the
                        oceans and seas on an even larger scale."
                      </p>
                      <p className="theme-news-body-text">
                        <span style={{ fontSize: '1rem' }}>
                          <strong>Veronika Mikos</strong>
                        </span>{' '}
                        <br />
                        <span style={{ color: 'dimgray' }}>
                          Director of Healthy Seas
                        </span>
                      </p>
                      Hyundai’s main role in the partnership is supporting
                      Healthy Seas with funding, so complex clean-up activities
                      can come to fruition. The first scheduled activity is
                      planned to take place in early summer in Greece to help a
                      local community by removing fishing nets and other debris
                      lying on the seabed, floating on the surface, and strewn
                      across the beaches.
                      <br />
                      <br />
                      This partnership between Hyundai Motor and Healthy Seas
                      will initially be for one year with a possibility for
                      extension.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
