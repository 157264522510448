import React, { Component } from "react";

export default class IoniqGreenCarAwards extends Component {
  render() {
    return (
      <div className="container">
        <div className="fadeInDownBigFull">
          <div className="card-body newsPadding">
            <div className="card-deck " id="IoniqGreenCarAwards">
              <div className="card">
                <div className="row">
                  <div className="col-md">
                    <img
                      id="IoniqGreenCarAwards-gallery-1"
                      className="card-img-top img-fluid news-gallary-image active"
                      src="images/ioniqGreenCarAwards.jpg"
                      alt="Card image cap"
                    />
                  </div>
                </div>

                <div className="card-block">
                  <div className="card-content">
                    <div className="col">
                      <div className="row whiteBG">
                        <div className="col-lg-4">
                          <div className="salesText mt-3">
                            <p className="theme-news-snippet">
                              “IONIQ is the world’s first vehicle to offer 3
                              electrified powertrains on 1 dedicated platform”
                            </p>
                          </div>
                          <hr />
                          <div
                            className="d-flex justify-content-around mb-2 largerSI social-share-buttons"
                            data-article-id="IoniqGreenCarAwards"
                            data-article-text="Ioniq Green Car Awards"
                          />
                        </div>

                        <div className="col-lg-8 border-lg-left">
                          <div className="row pt-3">
                            <div className="col-md-10 offset-md-1">
                              <p className="salesText" data-search-tag="title">
                                HYUNDAI IONIQ ELECTRIFIES AT THE NEXT GREEN CAR
                                AWARDS
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <div className="theme-news-body-text">
                                <ul>
                                  <li>
                                    Hyundai IONIQ Electric wins the Next Green
                                    Car's Car Of The Year 2016 as well as
                                    winning the Small Family car category.
                                  </li>
                                  <li>
                                    The first IONIQ Electrics have already
                                    delivered to customers
                                  </li>
                                  <li>
                                    IONIQ is the world’s first vehicle to offer
                                    3 electrified powertrains on 1 dedicated
                                    platform
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div className="row pt-3">
                            <div className="col-md-10 offset-md-1">
                              <p
                                className="theme-news-body-text"
                                data-search-tag="summary"
                              >
                                <strong>December 1, 2016 –</strong> High
                                Wycombe, 1st December 2016 – The new Hyundai
                                IONIQ has been hailed as Next Green Car’s Car of
                                the Year 2016. Next Green Car’s judges also
                                awarded IONIQ Electric with the top spot in
                                Small Family category, against the Renault
                                Megane, Fiat Tipo and Volvo V40. The judges
                                praised IONIQ’s capabilities as an all round
                                family car, as well as its recognised eco
                                credentials as a zero emission electric vehicle.
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                The Next Green Car judge’s explained the
                                verdicts "It's tough to find any weaknesses with
                                the Hyundai IONIQ Electric. Plenty of
                                range-stretching features help make the IONIQ
                                Electric an easy EV to drive over long
                                distances, while the company has also packed in
                                plenty of performance, practicality, and value
                                for money into the package.”
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                Tony Whitehorn, President and CEO Hyundai Motor
                                UK, said “We are very pleased to receive both of
                                these awards from Next Green Car. It is
                                particularly satisfying to hear the judge’s
                                verdicts commending IONIQ Electric for its all
                                round abilities, as it underlines what we
                                already know; that for our customers, the
                                complete vehicle package is just as important as
                                IONIQ’s zero emission performance.”{" "}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                Hyundai’s IONIQ Hybrid and Electric went on sale
                                at the end of October and since then almost 300
                                cars have been delivered. Both models will be
                                joined in Hyundai showrooms by IONIQ Plug-in,
                                which begins sales in Q2 2017. The IONIQ range
                                is part of Hyundai Motor Group’s overall plan of
                                launching 28 eco-friendly cars by 2020.{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
