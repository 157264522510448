import React, { Component } from 'react';
import './contactPref.css';
import FooterNavbar from '../components/footerNavbar';
import Store from 'store';
import TopNavbar from '../components/topnavbar';
import BottomNavbar from '../components/bottomnavbar';
import Footer from '../components/footer';
import ProfileNav from '../components/profileNavbar';
import JSEncrypt from 'jsencrypt';

export default class ContactPref extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);

    const customer = Store.get('customerInfo');

    const publicKey = process.env.REACT_APP_PREF_ENCRYPT_KEY.replace(
      /\|/g,
      '\n',
    );
    const token = process.env.REACT_APP_PREF_TOKEN;

    var encrypt = new JSEncrypt();
    encrypt.setPublicKey(publicKey);
    var encryptedEmail = encrypt.encrypt(customer.email);

    var CPSscript = document.createElement('script');
    CPSscript.id = 'hyundaicpsscript';
    var Param1 = document.createAttribute('Param1');
    Param1.value = '' + token + '';
    CPSscript.setAttributeNode(Param1);
    var Param2 = document.createAttribute('Param2');
    Param2.value = '' + encryptedEmail + '';
    CPSscript.setAttributeNode(Param2);
    CPSscript.src = '' + process.env.REACT_APP_PREF_SCRIPT_URL + '';

    root.parentNode.querySelector('#hyundaicpsscript') != null &&
      root.parentNode.querySelector('#hyundaicpsscript').remove();
    root.parentNode.insertBefore(CPSscript, root.nextSibling);
  }

  render() {
    return (
      <div>
        <TopNavbar />
        <BottomNavbar />

        <div className="container">
          <ProfileNav />

          <section
            className="section1 wow fadeIn"
            data-wow-duration="2s"
            data-wow-delay="0.2s"
          >
            <div className="content p-3 p-xl-5 col-md-10 offset-md-1 border-right border-bottom">
              <div className="row">
                <div className="col-md-3 p-3">
                  <h4 className="h4ServHistory" data-search-tag="title">
                    Contact
                    <br />
                    preferences
                  </h4>
                  <p />
                </div>

                <div className="col-md-9  p-4 contact-perf-content">
                  <p className="remindersBlue" data-search-tag="summary">
                    Please let us know exactly how you'd like us to contact you,
                    just tick the boxes below. You can change your preferences
                    as often as you like.
                  </p>
                  <h6>
                    <strong>Keeping in touch with you</strong>
                  </h6>
                  <p className="remindersBlue">
                    Hyundai Motor UK Ltd would like to keep in touch in a way
                    that suits you, so you can be one of the first to hear about
                    our news, product developments and offers.{' '}
                  </p>
                  <p className="remindersBlue">
                    All your information will be protected and only used by
                    Hyundai Motor UK Ltd., our authorised dealers and repairers.
                    We'll never pass data to third parties for their use.{' '}
                  </p>
                  <p className="remindersBlue">
                    You can change your mind anytime by calling our call centre
                    on{' '}
                    <strong>
                      <a href="tel:+44800981981" title="0800 981 981">
                        0800 981 981
                      </a>
                    </strong>
                    , through the unsubscribe link in all our emails or updating
                    your preferences here in MyHyundai.{' '}
                  </p>
                  <p className="remindersBlue">
                    For full details click through to our{' '}
                    <a
                      href="https://www.hyundai.com/uk/en/privacy-policy.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span id="cpUnderline">Privacy Policy.</span>
                    </a>
                  </p>

                  <div
                    id="HyundaiPreferenceCentre"
                    className="theme-contactPref-plugin-style-overide"
                  />

                  <div>
                    <div className="clearfix" />

                    <p className="small-bottom-text">
                      <small>
                        If you wish to update your preferences with either a
                        Hyundai retailer or Hyundai Finance, you will need to
                        contact them directly.
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <FooterNavbar />
        </div>

        <Footer />
      </div>
    );
  }
}
