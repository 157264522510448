import React, { Component } from 'react';
import { Link } from 'react-router-dom';

//-- CSS
import './postItemMultiple.css';

export default class PostItemMultiple extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageSrc: props.imageSrc,
      imgAlt: props.imageAlt,
      title: props.title,
      closesText: props.closesText || '',
      link: props.link,
      buttonText: props.buttonText || 'Enter',
    };
  }

  render() {
    const {
      imageSrc,
      imgAlt,
      title,
      closesText,
      link,
      buttonText,
    } = this.state;

    return (
      <div className="pb-5 col-md-6 d-flex">
        <div className="card w-100">
          <div className="card-img-top">
            <Link to={link} title={title} className="d-block">
              <img
                className="img-fluid mx-auto d-block theme-postItemMultiple-item-border w-100"
                src={imageSrc}
                alt={imgAlt}
              />
            </Link>
          </div>
          <div className="card-block d-flex flex-column flex-grow-1">
            <h5 className="text-center py-2">{title}</h5>
            {closesText.length > 0 && (
              <p className="text-center small">{closesText}</p>
            )}
            <Link
              to={link}
              className="btn btn-primary removeRadius btn-block text-truncate mt-auto"
            >
              {buttonText}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
