import React, { Component } from "react";

export default class IoniqBestCityCar extends Component {
  render() {
    return (
      <div className="container">
        <div className="fadeInDownBigFull">
          <div className="card-body newsPadding">
            <div className="card-deck " id="IoniqBestCityCar">
              <div className="card">
                <div className="row">
                  <div className="col-md">
                    <img
                      id="IoniqBestCityCar-gallery-1"
                      className="card-img-top img-fluid news-gallary-image active"
                      src="images/i10BestCityCar.jpg"
                      alt="i10 Best City Car"
                    />
                  </div>
                </div>

                <div className="card-block">
                  <div className="card-content">
                    <div className="col">
                      <div className="row whiteBG">
                        <div className="col-lg-4">
                          <div className="salesText mt-3">
                            <p className="theme-news-snippet">
                              “i10 Premium SE also wins “Best buy more than
                              £10,000” in the city car category”
                            </p>
                          </div>
                          <hr />
                          <div
                            className="d-flex justify-content-around mb-2 largerSI social-share-buttons"
                            data-article-id="IoniqBestCityCar"
                            data-article-text="Ioniq Best City Car"
                          />
                        </div>

                        <div className="col-lg-8 border-lg-left">
                          <div className="row pt-3">
                            <div className="col-md-10 offset-md-1">
                              <p className="salesText" data-search-tag="title">
                                THE BEST THINGS COME IN SMALL PACKAGES - NEW
                                HYUNDAI i10 WINS BEST CITY CAR AT 2017 WHAT CAR?
                                AWARDS
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <div className="theme-news-body-text">
                                {" "}
                                <ul>
                                  <li>
                                    4th consecutive What Car? win for the multi
                                    award winning city car
                                  </li>
                                  <li>
                                    i10 Premium SE also wins “Best buy more than
                                    £10,000” in the city car category
                                  </li>
                                  <li>
                                    On sale from 12th January with prices
                                    starting from £8,495*
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div className="row pt-3">
                            <div className="col-md-10 offset-md-1">
                              <p
                                className="theme-news-body-text"
                                data-search-tag="summary"
                              >
                                <strong>
                                  High Wycombe, 12th January 2017 –
                                </strong>{" "}
                                The New i10 has been awarded What Car?’s Best
                                City Car award for the 4th consecutive year,
                                holding off both a new challenge from the Suzuki
                                Celerio and the established competition from the
                                Skoda Citigo.
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                Steve Huntingford, What Car? Editor said: “We
                                have no hesitation in singling out the i10 as
                                our winner: it’s cheap to buy and to run, comfy,
                                and easy to drive in town. But it also has
                                luxuries that you rarely get in this class, plus
                                a five-year warranty. The i10 feels like a class
                                above the rest, with the most room, the smartest
                                interior and the smoothest engine. The i10 is a
                                proper, grown-up car, not just a mere runaround,
                                and that’s why it’s our favourite city car.”
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                The New i10 1.2 Premium SE also won “Best buy
                                for more than £10,000”. Huntingford commented:
                                “we recommend top-spec Premium SE trim, which
                                now gets class-leading infotainment on a 7.0
                                inch touchscreen, sat-nav, Apple CarPlay,
                                Bluetooth and a DAB radio. You also get climate
                                control, heated seats and a heated steering
                                wheel – all on a city car. The rest of the i10
                                remains as appealing as ever.”{" "}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                Tony Whitehorn, President and CEO, Hyundai Motor
                                UK said “Since its launch in 2014, i10 has
                                continually received praise from both industry
                                and buyers alike and has become one of the
                                stalwarts of our range. For 2017, we have
                                introduced New i10 which not only introduces a
                                fresh new look but also brings technology to the
                                segment that is usually only found in the
                                classes above.”{" "}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                New i10 is available from £8,495* on the road.
                                For more information, please visit
                                www.hyundai.co.uk{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
