import React, { Component } from "react";

export default class NextGenFuel extends Component {
  render() {
    return (
      <div className="container">
        <div className="fadeInDownBigFull">
          <div className="card-body newsPadding">
            <div className="card-deck " id="NextGenFuel">
              <div className="card">
                <div className="row">
                  <div className="col-md">
                    <img
                      id="NextGenFuel-gallery-1"
                      className="card-img-top img-fluid news-gallary-image active"
                      src="images/nextGenFuel.jpg"
                      alt="Card image cap"
                    />
                  </div>
                </div>

                <div className="card-block">
                  <div className="card-content">
                    <div className="col">
                      <div className="row whiteBG">
                        <div className="col-lg-4">
                          <div className="salesText mt-3">
                            <p className="theme-news-snippet">
                              “Latest hydrogen fuel cell technology delivers
                              evolved performance and efficiency”
                            </p>
                          </div>
                          <hr />
                        </div>

                        <div className="col-lg-8 border-lg-left">
                          <div className="row pt-3">
                            <div className="col-md-10 offset-md-1">
                              <p className="salesText" data-search-tag="title">
                                HYUNDAI MOTOR REVEALS NEXT GENERATION FUEL CELL
                                CONCEPT AT 2017 GENEVA MOTOR SHOW
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <div className="theme-news-body-text">
                                <ul>
                                  <li>
                                    Hyundai Motor showcases future vision for
                                    zero-emission mobility
                                  </li>
                                  <li>
                                    Latest hydrogen fuel cell technology
                                    delivers evolved performance and efficiency
                                  </li>
                                  <li>
                                    Futuristic FE Fuel Cell Concept hints at
                                    next phase of Hyundai Motor’s eco-vehicle
                                    program
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div className="row pt-3">
                            <div className="col-md-10 offset-md-1">
                              <p
                                className="theme-news-body-text"
                                data-search-tag="summary"
                              >
                                <strong>
                                  Geneva Motor Show, 07 March 2017
                                </strong>{" "}
                                – Hyundai Motor today unveiled its futuristic FE
                                Fuel Cell Concept that looks ahead to the next
                                generation of hydrogen-powered vehicles. Having
                                established itself as a global leader for
                                hydrogen transportation, the company has
                                reaffirmed its commitment to fuel cell vehicle
                                development at the Geneva Motor Show 2017. The
                                FE Fuel Cell Concept represents the next step
                                for Hyundai Motor toward realizing its ultimate
                                ambition of creating a zero-emission Hydrogen
                                Energy Society.
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <div className="theme-news-body-text">
                                <p>
                                  In early 2017 at the Davos World Economic
                                  Forum, Hyundai Motor Company joined the launch
                                  of the Hydrogen Council, a global initiative
                                  to promote the development and
                                  commercialization of fuel cell cars as an
                                  alternative to fossil fuel vehicles. This
                                  follows two decades at the forefront of
                                  hydrogen vehicle development, most recently
                                  with the ix35 Fuel Cell (Tucson Fuel Cell in
                                  some markets), which in 2013 became the
                                  world’s first mass-produced hydrogen-powered
                                  vehicle, and is currently on sale in 17
                                  countries around the world.
                                </p>
                                <p>
                                  Woong Chul Yang, Vice Chairman of Hyundai
                                  Motor Company, said: “Hyundai Motor has a
                                  heritage of building innovative,
                                  fuel-efficient vehicles that advance the
                                  automotive industry’s environmental progress.
                                  Our FE Fuel Cell Concept is an important
                                  evolution of our pioneering hydrogen
                                  leadership, and moves us closer to our vision
                                  for a ‘Hydrogen Society’, where transportation
                                  is clean, efficient and enhances the lives of
                                  our customers.”
                                </p>
                                <p>
                                  The FE Fuel Cell Concept continues Hyundai
                                  Motor’s commitment to fuel cell vehicle
                                  development and is part of the company’s
                                  eco-vehicle program that will see 14 or more
                                  new environmentally-focused models introduced
                                  by 2020. Spurred on by greater global demand
                                  for fuel-efficient, eco-friendly vehicles, the
                                  program continues the example of innovation
                                  set by IONIQ, the first car to offer a choice
                                  of three electrified powertrains in a single
                                  body type.
                                </p>
                                <p>
                                  The FE Fuel Cell Concept’s flowing form is
                                  inspired by nature and water – the car’s only
                                  emission – with the clean and calm design
                                  emphasizing its non-polluting nature. Across
                                  the car, form follows function to deliver a
                                  minimalist style. Exemplifying this approach,
                                  the FE Concept features a dramatic and stylish
                                  rear air foil and integrated vents that
                                  provide aerodynamic efficiency through
                                  intelligent engineering.
                                </p>
                                <p>
                                  The concept’s name ‘FE’ stands for Future Eco,
                                  reflecting the innovative new technologies
                                  that complement the car’s eco-friendliness – a
                                  recognition of its unique fuel source. One of
                                  the most notable characteristics of the new
                                  concept is its internal air humidifier, which
                                  recycles water emitted by the car’s clean
                                  hydrogen energy circulation to create a more
                                  comfortable cabin environment.
                                </p>
                                <p>
                                  The FE Concept showcases Hyundai Motor’s
                                  fourth generation of hydrogen fuel cell
                                  technology, an evolution of research,
                                  development and real-world evaluation programs
                                  around the world. When compared with the
                                  current generation system used in the Tucson
                                  ix35 Fuel Cell, the new technology is 20%
                                  lighter, and achieves 10% greater efficiency.
                                  In addition, the power density of the fuel
                                  cell stack is increased by 30%, boosting the
                                  car’s range significantly.
                                </p>
                                <p>
                                  As another reinforcement of the car’s
                                  eco-credentials and focus on customer
                                  convenience, the FE Concept features portable
                                  battery packs – charged by the car’s energy
                                  output – to power passenger devices.
                                  Meanwhile, the trunk features an integrated
                                  storage and charging space for an electric
                                  scooter, demonstrating how Hyundai Motor is
                                  developing mobility solutions to match future
                                  lifestyles.
                                </p>
                                <p>
                                  The new concept car is more than simply
                                  beautiful and innovative; it is highly capable
                                  too. The car is designed to run for more than
                                  800 kilometers between refueling,
                                  acknowledging the current limited hydrogen
                                  infrastructure. Elements of the FE Fuel Cell
                                  Concept will influence an SUV Fuel Cell model
                                  set for launch in 2018, which will feature
                                  advanced ‘Hyundai Smart Sense’ driver
                                  assistance technologies, alongside an
                                  extensive hydrogen-powered range.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
