import React, { Component } from 'react';

export default class ValidationMessage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    var className =
      this.props.className == null
        ? 'invalid-feedback'
        : this.props.className + ' invalid-feedback';
    const message =
      this.props.validation == null ? '' : this.props.validation.message;

    if (message == null || message.length == 0) {
      return null;
    }

    return (
      <div className={className}>
        <div className="theme-main-invalid-icon">
          <i className="fas fa-exclamation-triangle" />
        </div>
        <span className="theme-main-invalid-message">{message}</span>
      </div>
    );
  }
}
