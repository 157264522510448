import React, { Component } from 'react';
import NewsNavbar from '../components/newsNavbar';
import FooterNavbar from '../components/footerNavbar';
import Topnavbar from '../components/topnavbar';
import Bottomnavbar from '../components/bottomnavbar';
import Footer from '../components/footer';
import './news.css';
import NewsUtils from '../utils/News';
import NewsNavWells from '../components/news/newsNavWells';

// Product News -----------------------------------------------------------------------

export default class News extends Component {
  constructor(props) {
    super(props);

    var articles = [];

    const displayItemName =
      this.props.location.query != null &&
      this.props.location.query.displayItem != null
        ? this.props.location.query.displayItem
        : null;

    if (displayItemName != null) {
      var itemIndex;
      for (var i = 0; i < articles.length; i++) {
        if (articles[i].name === displayItemName) {
          itemIndex = i;
          break;
        }
      }
      const articleRemoved = articles.splice(itemIndex, 1)[0];
      articles.splice(0, 0, articleRemoved);
    }

    this.state = {
      newsList: articles,
      displayCount: 2,
    };

    this.handleShowMoreClick = this.handleShowMoreClick.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    NewsUtils.attachThumbnailClick();
  }

  handleShowMoreClick() {
    this.setState((prevState) => {
      return {
        displayCount: prevState.displayCount + 2,
      };
    });
  }

  componentDidUpdate() {
    NewsUtils.attachThumbnailClick();
  }

  render() {
    const displayNews = this.state.newsList
      .slice(0, this.state.displayCount)
      .map((item, index) => (
        <div
          key={index}
          data-search-component={item.name}
          className="mb-5 w-100" //w-100 e.i.11 fix
        >
          {React.cloneElement(item.component, {
            displayShowMore: true,
          })}
        </div>
      ));
    const displayMoreButton =
      this.state.displayCount < this.state.newsList.length;

    return (
      <div>
        <Topnavbar />
        <Bottomnavbar />
        <NewsNavbar />
        <div className="container">
          <section className="section1 row mx-0">
            <div className="col-md-10 offset-md-1">
              <h4 className="h4ServHistory">News</h4>
              <p className="small">
                The latest 6 months news articles are listed below and all
                others can be viewed in the archive section.
              </p>
              <div className="row">{displayNews}</div>
            </div>
            {displayMoreButton && (
              <div className="col p-5">
                <div className="text-center">
                  <button
                    className="btn btn-primary removeRadius"
                    onClick={this.handleShowMoreClick}
                  >
                    Show more news
                  </button>
                </div>
              </div>
            )}
          </section>
        </div>

        <NewsNavWells />
        <FooterNavbar />
        <Footer />
      </div>
    );
  }
}
