import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import MyCarNavbar from '../components/myCarNavbar';
import './serviceHistory.css';
import FooterNavbar from '../components/footerNavbar';
import Topnavbar from '../components/topnavbar';
import Bottomnavbar from '../components/bottomnavbar';
import Footer from '../components/footer';
import CarSelector from '../components/carSelector';
import ServiceHistoryItem from '../components/serviceHistory/serviceHistoryItem';
import Store from 'store';

export default class ServiceHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      vehicles: [],
      selectedIndex: 0,
    };

    this.handleSelectionChanged = this.handleSelectionChanged.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const vehicles = Store.get('vehicles');

    if (vehicles && vehicles.length > 0) {
      this.setState({
        vehicles: vehicles,
      });
    }
  }

  handleSelectionChanged(index) {
    this.setState((prevState) => {
      return {
        selectedIndex: index,
      };
    });
  }

  render() {
    var serviceHistory = null;

    const vehicle = this.state.vehicles[this.state.selectedIndex];

    if (
      vehicle != null &&
      vehicle.hyundaiUkServiceHistoryList != null &&
      vehicle.hyundaiUkServiceHistoryList.length !== 0
    ) {
      serviceHistory = vehicle.hyundaiUkServiceHistoryList.map(
        (item, index) => {
          return (
            <ServiceHistoryItem
              key={index}
              history={item}
              isNew={vehicle.isNew}
            />
          );
        },
      );
    }

    return (
      <div>
        <Topnavbar />
        <Bottomnavbar />
        <MyCarNavbar />

        <div className="container">
          <section
            className="section1 wow fadeIn"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <div className="content p-3 p-xl-5 col-md-10 offset-md-1 border-right border-bottom">
              <div className="row">
                <div className="col-lg-4 p-3 p-sm-4">
                  <h4 className="h4ServHistory" data-search-tag="title">
                    Retailer Visits
                  </h4>
                  <p />
                  <p className="personalBlue">Please select car below.</p>
                  <CarSelector
                    vehicles={this.state.vehicles}
                    onSelectionChanged={this.handleSelectionChanged}
                  />
                </div>

                <div className="col ml-lg-4 offset-lg-1 p-sm-4 border-lg-left">
                  {vehicle != null && (
                    <h4 className="page-inner-title">
                      Your Hyundai {vehicle.model}
                    </h4>
                  )}
                  {serviceHistory}
                  {serviceHistory == null && (
                    <p className="remindersBlue">
                      You currently don’t have any Dealer Visits records for
                      this vehicle. Once you have had your Hyundai serviced at a
                      Hyundai Dealership it will be recorded here
                    </p>
                  )}
                  <i>
                    <small>
                      Dealer visits information is not currently available for
                      those with a Fixed Price Service Plan.
                    </small>
                  </i>
                </div>
              </div>
            </div>
          </section>
        </div>
        <FooterNavbar />

        <Footer />
      </div>
    );
  }
}
