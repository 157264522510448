import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';

export default class TwoAwards extends Component {
  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="twoAwards" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="twoAwards-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/twoAwards1.png") ',
              }}
            /><div
              id="twoAwards-gallery-2"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/twoAwards2.png")',
              }}
            />
        </div>
          </div>
          

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row flex-wrap">
                 <div className="theme-news-thumbnail-container  img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#twoAwards-gallery-1"
                    style={{
                      backgroundImage: 'url("images/twoawards1.png") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail ml-2">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#twoAwards-gallery-2"
                    style={{
                      backgroundImage: 'url("images/twoawards2.png") ',
                    }}
                  />
                </div>
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “Hyundai IONIQ tops the <i>Company Car Today </i> ‘PHEV of the Year’ category attracting praise for real-world efficiency and range.”
                </p>
              </div>
              <hr />

              <NewsMedia
                articleId="twoAwards"
                articleText="Motoring mega-mag chooses Hyundai"
              />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">                    
                    
                    HYUNDAI MOTOR UK SCOOPS TWO PRIZES AT THE COMPANY CAR TODAY AWARDS                    

                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <ul
                    className="theme-news-body-text"
                    data-search-tag="summary"
                  >
                    <li>
                      <strong>
                        Hyundai IONIQ tops the <i>Company Car Today </i> ‘PHEV of the Year’ category attracting praise for real-world efficiency and range
                      </strong>
                    </li>
                    <li>
                      <strong>
                        Hyundai i10 wins in the ‘City Car of the Year’ category
                      </strong>
                    </li>
                    <li>
                      <strong>
                        Continuing investment in fleet operations ensures industry-leading levels of customer service
                      </strong>
                    </li>
                  </ul>
                  
                  {displayShowMore && (
                    <p>
                      <a
                        data-toggle="collapse"
                        href="#collapsetwoAwards"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                  {/* <p className="theme-news-body-text">The i30 Fastback’s sloping roofline, long bonnet and muscular body, along with its slim cabin and wide wheel arches give the car a strong stance. That strength, however, is far from being merely skin deep.</p> */}
                </div>
              </div>

              <div className={moreSectionClassName} id="collapsetwoAwards">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      <strong>High Wycombe, 23 January 2020</strong> – Hyundai Motor UK has claimed another two accolades, with both the IONIQ Plug-in Hybrid and the i10 being named the UK’s best plug-in hybrid and city car respectively at <i>Company Car Today’s</i> 2020 ‘CCT100’ awards.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      The judging panel, comprising <i>Company Car Today’s</i> editorial staff and expert contributors, praised IONIQ’s fleet credentials including clever packaging, real-world efficiency and driving range. The new IONIQ Plug-in Hybrid's impressive total driving range of up to 660 miles includes up to 39 miles of zero-emissions electric power. Low CO2 emissions of 26g/km also makes it attractive to business users, sitting in the 16% BIK bracket in 2020/21.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      “The plug-in hybrid market is set for massive growth thanks to the company car tax changes coming in April, and Hyundai’s IONIQ is an excellent exponent of the technology,” said <i>Company Car Today</i> Editor Paul Barker. “The IONIQ was revised and improved last autumn, making an already appealing and very sensible company car purchase even better.”
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      Hyundai’s i10 was praised for its low running costs and impressive practicality. The all-new i10 offers fleet buyers impressive combined fuel economy of up to 56.5mpg, low P11D values, and a low BIK of just 24%. It also offers impressive luggage capacity of 252 litres with the seats up and 1,050 with the seats down, and a range of industry leading safety features.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      “The Hyundai i10’s running costs and practicality mean that, even at the end of its life cycle it’s still a great fleet vehicle,” Barker continued. “The new model hitting showrooms now builds on the outgoing car’s strengths to become an even better company car proposition.”
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      Both models benefit from Hyundai’s industry-leading five-year unlimited mileage warranty and roadside assistance package, while standard equipment includes the latest active safety features, such as Forward Collision-Avoidance Assist, Lane Keeping Assist, and Intelligent Speed Limit Warning.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      Ashley Andrew, Managing Director, Hyundai Motor UK, said:  “It is very gratifying to see IONIQ Plug-in Hybrid and i10 recognised in these highly-regarded awards, endorsing both car’s standing as the most impressive in their segments. The accolades are also testament to Hyundai’s commitment to the fleet sector; recent investment in our fleet department is allowing us to grow, while still providing the high levels of service that existing Hyundai fleet and business customers have come to expect.”
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      Hyundai continues to invest in its fleet and business operations. Building on the exceptional service that has seen Hyundai UK awarded <i>Company Car Today’s </i> Best SME Aftersales Programme for five consecutive years, Hyundai continues to strengthen its fleet aftersales charter, dedicated fleet support line and national fleet centre programme, to service more business and fleet customers than ever before.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
