import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import Auth from './utils/Auth';
import AuthenticatedComponent from './components/AuthenticatedComponent';

export default ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      var currentPath = props.location.pathname;
      return Auth.isAuthenticated() ? (
        <div data-search-route={currentPath}>
          <AuthenticatedComponent {...props}>
            <Component {...props} />
          </AuthenticatedComponent>
        </div>
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      );
    }}
  />
);
