import React, { Component } from "react";
import { Link, Redirect } from 'react-router-dom'
import ReactGA from 'react-ga';

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);

        ReactGA.initialize(props.gaTrackingCode);

        this.state = {
            hasError: false
        };
    }

    componentDidCatch(error, info) {

        ReactGA.exception({
            description: error,
            fatal: true
        })
        if (process.env.NODE_ENV !== "development") {
            this.setState({ hasError: true });
        }
    }

    render() {
        if (this.state.hasError) {
            return (
                <Redirect to="/error" />
            )
        }
        return this.props.children;
    }
}