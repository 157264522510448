import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';

export default class IoniqUsedCar extends Component {
  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="IoniqUsedCar" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="ioniqUsedCar-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/ioniq_used1.jpg") ',
              }}
            />
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#ioniqUsedCar-gallery-1"
                    style={{
                      backgroundImage: 'url("images/ioniq_used1_th.jpg") ',
                    }}
                  />
                </div>
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “Best Used Green Car, the revolutionary vehicle has returned
                  to the top spot of the podium”
                </p>
              </div>
              <hr />

              <NewsMedia
                articleId="IoniqUsedCar"
                articleText="IONIQ leaves competitors seeing double"
              />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    IONIQ LEAVES COMPETITORS SEEING DOUBLE
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    {' '}
                    Not long after IONIQ Hybrid was named WhatCar?’s Best Used
                    Green Car, the revolutionary vehicle has returned to the top
                    spot of the podium, picking up two awards at the Carbuyer
                    &amp; DrivingElectric Awards 2019.
                  </p>
                  {displayShowMore && (
                    <p>
                      <a
                        data-toggle="collapse"
                        href="#collapseioniqUsedCar"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                  {/* <p className="theme-news-body-text">The i30 Fastback’s sloping roofline, long bonnet and muscular body, along with its slim cabin and wide wheel arches give the car a strong stance. That strength, however, is far from being merely skin deep.</p> */}
                </div>
              </div>

              <div className={moreSectionClassName} id="collapseioniqUsedCar">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      IONIQ was named Carbuyer Company Car of the Year, while
                      the IONIQ Plug-in was named DrivingElectric’s Compact
                      Plug-in Hybrid of the Year at the awards, which were
                      judged by the editorial teams of Carbuyer.co.uk and
                      DrivingElectric. The awards are designed with car buyers
                      in mind, and deliver on the publications’ promises of
                      being the internet’s most insightful car-buying research
                      tool and specialist electric vehicle website,
                      respectively.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      “By offering the IONIQ with a choice of hybrid, plug-in
                      hybrid and pure electric powertrains, Hyundai created a
                      unique proposition in the market - one that’s perfectly
                      tailored for company car drivers looking to minimise
                      costs,” said Steve Fowler, Editor-in-Chief of
                      Carbuyer.co.uk and DrivingElectric. “More than that, the
                      IONIQ is practical, comfortable and good to drive in all
                      its guises, a great company car to suit a range of usage
                      patterns.”{' '}
                    </p>
                  </div>
                </div>

                {/* <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {" "}
                      The SUV will feature the 2.2 CRDi “R” engine,
                      which has been upgraded to further reduce
                      emissions, and will be available with front
                      wheel drive or 4WD, with a 6-speed manual
                      gearbox or an all-new 8-speed automatic
                                  transmission.{" "}
                    </p>
                  </div>
                </div> */}

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      It was the IONIQ range as a whole that won Carbuyer’s
                      Company Car of the Year, but the judges picked out the
                      IONIQ Plug-in Hybrid for particular praise as
                      DrivingElectric’s Compact Plug-in of the Year.{' '}
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      “The Hyundai IONIQ is at its best in plug-in hybrid form
                      delivering family-friendly practicality and the prospect
                      of zero exhaust emissions motoring for owners who can
                      operate it within the genuine 26-mile real world electric
                      range. Solid build and user-friendly tech also count in
                      the favour of this great value all-rounder,” continued
                      Steve.{' '}
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      IONIQ Hybrid offers fuel economy of up to 68.2mpg, while
                      the Electric version is capable of 174 miles of zero
                      emissions motoring on a single charge. The IONIQ Plug-in
                      offers a mix of both, with 30 miles of zero emissions
                      motoring and a 1.6-litre GDI petrol engine.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      To configure your IONIQ, go to:{' '}
                      <a href="https://www.hyundai.co.uk/new-cars/ioniq">
                        www.hyundai.co.uk/new-cars/ioniq
                      </a>{' '}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
