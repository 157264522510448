import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';
import Store from 'store';
import ReactGA from 'react-ga';
export default class FashionCollection extends Component {
  componentDidMount = () => {};

  clickHandler = () => {};

  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="fashionCollection-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/fashionCollectionheader.jpg") ',
              }}
            />
            <div
              id="fashionCollection-gallery-2"
              className="theme-news-header-image "
              style={{
                backgroundImage: 'url("images/fashionCollection1.jpg") ',
              }}
            />
            <div
              id="fashionCollection-gallery-3"
              className="theme-news-header-image "
              style={{
                backgroundImage: 'url("images/fashionCollection2.jpg") ',
              }}
            />
            <div
              id="fashionCollection-gallery-4"
              className="theme-news-header-image "
              style={{
                backgroundImage: 'url("images/fashionCollection3.jpg") ',
              }}
            />
            {/* <div
              id="Bayon-gallery-2"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/hyundaielectric2.jpg")',
              }}
            /> */}
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#fashionCollection-gallery-2"
                    style={{
                      backgroundImage: 'url("images/fashionCollection1.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#fashionCollection-gallery-3"
                    style={{
                      backgroundImage: 'url("images/fashionCollection2.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#fashionCollection-gallery-4"
                    style={{
                      backgroundImage: 'url("images/fashionCollection3.jpg") ',
                    }}
                  />
                </div>
                {/* <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#Bayon-gallery-1"
                    style={{
                      backgroundImage: 'url("images/hyundaielectric1.jpg")',
                    }}
                  />
                </div> */}
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “Hyundai Motor Launches ‘Re:Style 2021’ Fashion Collection
                  Repurposing Discarded Vehicle Materials ”
                </p>
              </div>
              <hr />

              <NewsMedia articleId="awards" articleText="awards" />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    HYUNDAI MOTOR LAUNCHES 'RE:STYLE 2021' FASHION COLLECTION
                    REPURPOSING DISCARDED VEHICLE MATERIALS
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    {' '}
                    <ul>
                      <li>
                        Hyundai Motor Company partners with L’Eclaireur and
                        Boontheshop to introduce 12 fashion items created with
                        discarded wearable materials from vehicles
                      </li>
                      <li>
                        Re:Style 2021 marks third year of celebrating upcycling
                        and eco-friendly fashion at the vanguard of the evolving
                        industry
                      </li>
                      <li>
                        Doona Bae, KyoHwan Koo and Shudu highlight the event’s
                        purpose and philosophy in a video available on YouTube
                        (Hyundai Worldwide channel)
                      </li>
                    </ul>
                  </p>
                  {displayShowMore && (
                    <p>
                      <a
                        data-toggle="collapse"
                        href="#fashionCollection"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </div>

              <div className={moreSectionClassName} id="fashionCollection">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      October 14 2021 -{' '}
                      <strong>
                        Hyundai Motor Company has launched the third annual
                        iteration of “Re:Style”,
                      </strong>
                      <br />
                      <br />
                      “Re:Style” is an upcycling fashion project that uses
                      materials discarded during the automobile manufacturing
                      process and illuminates the company’s sustainable
                      enterprise beyond the automotive industry.
                      <br />
                      <br /> The Re:Style 2021 is launched in Seoul and Paris by
                      partnering with fashion select shops L’Eclaireur and
                      Boontheshop. The unique collaboration involving the three
                      companies highlights Hyundai Motor’s ever-growing
                      commitment to eco-friendly manufacturing, creative outside
                      collaboration and engagement with the environmentally
                      conscious consumers. In particular, Hyundai Motor’s
                      partnerships with L’Eclaireur and Boontheshop reflect the
                      two fashion companies’ shared commitment to eco-friendly
                      production and offering of easily accessible and wearable
                      fashion items.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      <img
                        src="images/fashioncollection1.jpg"
                        className="img-fluid"
                      />
                      <br />
                      <br />
                      “With the third annual celebration of Re:Style this year,
                      Hyundai Motor Company continues its dedication to
                      facilitate the ‘progress for humanity,’ its brand vision
                      and objective,” said Thomas Schemera, Global Chief
                      Marketing Officer and Head of the Customer Experience
                      Division of Hyundai Motor Company. “We are stepping up our
                      collaborations steadily with various industries around the
                      world to usher in a sustainable future. The Re:Style 2021
                      is one of them, and we hope this project serves as a focal
                      point for young consumers worldwide to understand the
                      impact of manufacturing leftover materials on our shared
                      environment and how we can creatively and practically
                      reutilize them.”
                      <br />
                      <br />{' '}
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      <img
                        src="images/fashioncollection2.jpg"
                        className="img-fluid"
                      />
                      <br />
                      <br />
                      At both the online and offline shops of L’Eclaireur and
                      Boontheshop, twelve fashion items comprising track
                      jackets, sweatshirts, zip-up hoodies, short pants and long
                      pants are on sale in limited quantities. The offline shops
                      run for two weeks from October 14 through 28, while the
                      online shops (ONLINE SHOPS URL: leclaireur.com,
                      sivillage.com) run for four weeks through November 10.
                      <br />
                      <br /> For this year’s Re:Style, leftover materials from
                      car manufacturing, such as airbags and seatbelts, have
                      been used. In addition, eco-friendly materials
                      incorporated into the Hyundai IONIQ 5, such as Bio PET and
                      recycled fibres, were also used, enhancing wearability and
                      demonstrating the potential for utilizing bio materials in
                      fashion and beyond.
                      <br />
                      <br /> “As a company in charge of fashion distribution, we
                      have always had concerns about the impact of discarded
                      clothing materials on our planet Earth,” said Michael
                      Hadida, CEO of L’Eclaireur. “So, by reusing the wearable
                      pieces from automobile manufacturing and channelling the
                      generated profits into other projects, we hope to create a
                      virtuous cycle of sustainability and uniquely contribute
                      to saving our planet.”
                      <br />
                      <br />
                      <img
                        src="images/fashioncollection3.jpg"
                        className="img-fluid"
                      />
                      <br />
                      <br />
                      DJ Kim, the Executive Director of BoontheShop, said: “It
                      is highly meaningful to participate in this creative and
                      value-adding collaboration with Hyundai Motor Company. We
                      hope that Re:style 2021 will spark new interest and common
                      understanding among today’s conscious consumers about
                      their purchasing decisions that take into consideration
                      the holistic nature of our shared environment.”
                      <br />
                      <br /> The proceeds from the sales of these items will go
                      to various sustainable projects by Hyundai Motor, with
                      L’Eclaireur and BoontheShop joining the cause on the basis
                      of their shared commitment. An online video{' '}
                      <a
                        href="https://www.youtube.com/user/HyundaiWorldwide"
                        target="_blank"
                      >
                        (HyundaiWorldwide - YouTube)
                      </a>{' '}
                      featuring actress Doona Bae, actor KyoHwan Koo and virtual
                      influencer Shudu, all wearing Re:Style items, is
                      available, illuminating the campaign’s purpose and
                      philosophy.
                      <br />
                      <br />
                      {/* <img
                        src="images/fashionCollection4.png"
                        className="img-fluid"
                      />
                      <br />
                      <br />
                      <strong>
                        Installation view of Hyundai fashionCollection: Anicka
                        Yi: In Love With The World
                      </strong>
                      <br />
                      <br />
                      Hyundai fashionCollection: Anicka Yi: In Love With The
                      World is curated by Achim Borchardt-Hume, Director of
                      Exhibitions and Programmes, Tate Modern; Mark Godfrey,
                      former Senior Curator, International Art, Tate Modern; and
                      Carly Whitefield, Assistant Curator, International Art,
                      Tate Modern. Produced by Petra Schmidt, Production
                      Manager, Tate Modern. It will be accompanied by a new book
                      from Tate Publishing.
                      <br />
                      <br /> Hyundai fashionCollection: Anicka Yi: In Love With
                      The World October 12, 2021 – January 16, 2022, Tate Modern
                      In partnership with Hyundai Motor. Supported by the Anicka
                      Yi Supporters, with additional support from the Supporters
                      Circle and Tate Americas Foundation
                      <br />
                      <br />
                      <img
                        src="images/fashionCollection5.png"
                        className="img-fluid"
                      />
                      <strong>
                        Installation view of Hyundai fashionCollection: Anicka
                        Yi: In Love With The World
                      </strong>
                      <br />
                      <br />
                      <strong>About Anicka Yi</strong> <br />
                      <br />
                      Anicka Yi was born in Seoul in 1971 and now lives and
                      works in New York City. She has been the subject of solo
                      exhibitions at museums including the Solomon R. Guggenheim
                      Museum in New York, Kunsthalle Basel, and the
                      Fridericianum in Kassel. Her work featured in the Venice
                      Biennale in 2019, the Whitney Biennial in 2017 and the
                      Gwangju Biennale in 2016, as well as in many group
                      exhibitions around the world. She won the Hugo Boss Prize
                      in 2016 and has held residencies and fellowships at the
                      Berggruen Institute in Los Angeles, the Headlands Center
                      for the Arts, and the Center for Art Science and
                      Technology at MIT.
                      <br />
                      <br />
                      <strong>About Tate Modern Turbine Hall</strong>
                      <br />
                      <br /> Since Tate Modern opened in 2000, the Turbine Hall
                      has hosted some of the world’s most memorable and
                      acclaimed works of contemporary art, reaching an audience
                      of millions each year. The way artists have interpreted
                      this vast industrial space has revolutionized public
                      perceptions of contemporary art in the twenty-first
                      century. The annual Hyundai fashionCollection gives
                      artists an opportunity to create new work for this unique
                      context. The fashionCollections are made possible by the
                      long-term partnership between Tate and Hyundai Motor,
                      confirmed until 2026 as part of the longest initial
                      commitment from a corporate partner in Tate’s history. */}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
