import React, { Component } from 'react';
import './servicePlans.css';
import FooterNavbar from '../components/footerNavbar';
import Store from 'store';
import TopNavbar from '../components/topnavbar';
import BottomNavbar from '../components/bottomnavbar';
import Footer from '../components/footer';
import $ from 'jquery';
import RadioGroup from '../components/Feedback/FeedBackRadioGroup';
import FeedBackCheckBox from '../components/Feedback/FeedBackCheckBox';
import FeedbackNavbar from '../components/feedbackNavbar';

export default class Feedback extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form_data: {},
      validationMessage: '',
      isLoading: false,
    };

    this.radioOptions = [
      'useful',
      'neither useful or not useful',
      'not useful',
    ];

    // Question 3
    this.radioOptions3 = ['easy', 'neither easy or difficult', 'difficult'];

    // Question 4
    this.radioOptions4 = [
      'helpful',
      'neither helpful or unhelpful',
      'unhelpful',
    ];

    // Which parts of the MyHyundai website do you often visit?
    this.checkBoxOptions = [
      'Car details',
      'Offers',
      'News',
      'Competitions',
      'Profile',
      'Service Products',
    ];

    // How useful do you find each of the following:
    this.usefulOptions = [
      'MyInterest',
      'Contact preferences',
      'Dealer search',
      'Vehicle reminders',
      'Dealer visit history',
      'Service products',
    ];
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleOptionChange = (changeEvent) => {
    var radioOption = changeEvent.target.name;
    var radioGroup =
      changeEvent.target.parentElement.parentElement.hasAttribute('name')
        ? changeEvent.target.parentElement.parentElement.attributes.name.value
        : false;

    changeEvent.persist();

    if (radioGroup) {
      this.setState(
        (prevState) => {
          return {
            form_data: {
              ...prevState.form_data,
              [radioGroup]: {
                ...prevState.form_data[radioGroup],
                [radioOption]: changeEvent.target.value,
              },
            },
          };
        },
        () => {
          if (this.state.form_data.length !== 0) {
            this.setState({ validationMessage: '' });
          }
        },
      );
    } else {
      this.setState(
        (prevState) => {
          return {
            form_data: {
              ...prevState.form_data,
              [radioOption]: changeEvent.target.value,
            },
          };
        },
        () => {
          if (this.state.form_data.length !== 0) {
            this.setState({ validationMessage: '' });
          }
        },
      );
    }
  };

  handleCheckBoxChange = (changeEvent) => {
    changeEvent.persist();

    if (!this.state.form_data.hasOwnProperty('often_visit')) {
      this.setState((prevState) => ({
        form_data: { ...prevState.form_data, often_visit: [] },
      }));
    }

    if (changeEvent.target.checked) {
      return this.setState(
        (prevState) => ({
          form_data: {
            ...prevState.form_data,
            often_visit: [
              ...prevState.form_data.often_visit,
              changeEvent.target.name,
            ],
          },
        }),
        () => {
          this.setState({ validationMessage: '' });
        },
      );
    } else {
      this.setState(
        {
          form_data: {
            often_visit: this.state.form_data.often_visit.filter(function (
              item,
            ) {
              return item !== changeEvent.target.name;
            }),
          },
        },
        () => {
          if (this.state.form_data.often_visit.length === 0) {
            delete this.state.form_data.often_visit;
            this.setState(this.state);
          }
        },
      );
    }
  };

  handleTextChange = (event) => {
    event.persist();
    this.setState(
      (prevState) => ({
        form_data: {
          ...prevState.form_data,
          comments: event.target.value,
        },
      }),
      () => {
        if (!this.state.form_data.comments) {
          delete this.state.form_data.comments;
          this.setState(this.state);
        } else {
          this.setState({ validationMessage: '' });
        }
      },
    );
  };

  handleSubmit = (event) => {
    event.preventDefault();

    var test = Object.keys(this.state.form_data).some(
      (k) => this.state.form_data[k],
    );
    if (!test) {
      this.setState({
        validationMessage: 'Please fill out the form before submitting',
      });
    } else {
      this.setState({ validationMessage: '', isLoading: true }, () => {
        const parentThis = this;

        // Send data here ->

        return fetch(
          process.env.REACT_APP_API_URL +
            '/HyundaiUk/FeedBack/PostFeedback?key=' +
            Store.get('loginToken').replace(/['"']+/g, ''),
          {
            method: 'post',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
            body: JSON.stringify(this.state.form_data),
          },
        ).then((response) => {
          if (response.ok) {
            this.setState({ feedback: 'Sent', form_data: {} });
            $('#submitModal').modal('show');

            $('#submitModal').on('hidden.bs.modal', function (e) {
              window.location.reload(false);
            });

            parentThis.refs.form.reset();
          } else {
            this.setState({ feedback: 'Failed' });
          }
          this.setState({ isLoading: false });
        });
      });
    }
  };

  render() {
    return (
      <div>
        <TopNavbar />
        <BottomNavbar />

        <div className="container">
          {<FeedbackNavbar />}
          <section
            className="section1 wow fadeIn"
            data-wow-duration="2s"
            data-wow-delay="0.2s"
          >
            <div className="content p-3 p-xl-5 col-md-10 offset-md-1 border-right border-bottom">
              <div className="row">
                <div className="col-lg-4 p-3 p-sm-4">
                  <h4 className="h4ServHistory" data-search-tag="title">
                    <strong>Happy with MyHyundai?</strong>
                  </h4>
                  <p className="mb-0" data-search-tag="summary">
                    MyHyundai is here to help you get the most out of owning a
                    Hyundai. In order to make it as useful as possible, we'd
                    love to know what you think.
                  </p>
                </div>

                <div className="col-lg-8 p-sm-4 border-lg-left">
                  <div className="theme-main-position-relative">
                    <img
                      className="card-img-top img-fluid"
                      src="images/feedback.png"
                    />
                  </div>
                  <h4
                    className="h4ServHistory mt-4 mb-4"
                    data-search-tag="title"
                  >
                    <strong>What do you think?</strong>
                  </h4>
                  <p className="remindersBlue" data-search-tag="summary">
                    As a Hyundai owner, MyHyundai is your website and we'd like
                    to know what you think of it. Your feedback will help us to
                    make the site better for you.
                  </p>
                  <form onSubmit={this.handleSubmit} ref="form">
                    <ol className="theme-feedback-ol">
                      <li>
                        {' '}
                        <p className="font-weight-bold">
                          Which parts of the MyHyundai website do you often
                          visit?
                        </p>
                        <div className="row">
                          {this.checkBoxOptions.map((checkBox, i) => {
                            return (
                              <div className="col-6" key={i}>
                                <FeedBackCheckBox
                                  name={checkBox}
                                  onChange={this.handleCheckBoxChange}
                                  id={i.toString()}
                                />
                              </div>
                            );
                          })}
                        </div>
                        <br />
                      </li>

                      <li className="mb-3">
                        <p className="font-weight-bold">
                          How useful do you find each of the following:
                          <br />
                          <span className="font-weight-light">
                            (Scale of 1 'useful', 2 'neither useful or not
                            useful', 3 'not useful')
                          </span>
                        </p>
                        {/* How useful do you find each of the following: */}
                        {this.usefulOptions.map((option, i) => {
                          return (
                            <RadioGroup
                              key={i}
                              radioGroupName="usefulness"
                              radioOptions={this.radioOptions}
                              radioGroupTitle={option}
                              onChange={this.handleOptionChange}
                            />
                          );
                        })}
                      </li>

                      <li>
                        <p className="font-weight-bold">
                          How easy is it to find the information you're looking
                          for?
                          <br />
                          <span className="font-weight-light">
                            (Scale of 1 'easy', 2 'neither easy or difficult', 3
                            'difficult')
                          </span>
                        </p>
                        {/*  How easy is it to find the information you're looking
                      for? */}
                        <div className="mb-3">
                          <RadioGroup
                            radioOptions={this.radioOptions3}
                            radioTitle="information"
                            onChange={this.handleOptionChange}
                          />
                        </div>
                      </li>
                      <li>
                        <p className="font-weight-bold">
                          How would you rate the MyHyundai website overall?
                          <br />
                          <span className="font-weight-light">
                            (Scale of 1 'helpful', 2 'neither helpful or
                            unhelpful', 3 'unhelpful')
                          </span>
                        </p>
                        {/*  How would you rate the MyHyundai website overall? */}
                        <div className="mb-3">
                          <RadioGroup
                            radioOptions={this.radioOptions4}
                            radioTitle="website"
                            onChange={this.handleOptionChange}
                          />
                        </div>
                      </li>

                      <li>
                        <p className="font-weight-bold">
                          What else would you like to see on MyHyundai?
                          <br />
                        </p>
                        <div className="form-group">
                          <textarea
                            className="form-control"
                            id="comments"
                            rows="3"
                            onChange={this.handleTextChange}
                          />
                        </div>

                        <br />
                      </li>
                    </ol>

                    <div className="row">
                      <div className="col-lg-8">
                        {this.state.validationMessage && (
                          <p className="text-danger py-2">
                            {this.state.validationMessage}
                          </p>
                        )}
                      </div>
                      <div className="col-lg-4 text-right">
                        <button
                          className="btn btn-primary removeRadius position-relative"
                          type="submit"
                        >
                          <div
                            className={'theme-button-spinner'}
                            style={{
                              display: this.state.isLoading ? 'block' : 'none',
                            }}
                          >
                            <i className="fas fa-spinner fa-spin fa-lg text-white" />
                          </div>
                          <span
                            style={{
                              visibility: this.state.isLoading
                                ? 'hidden'
                                : 'visible',
                            }}
                          >
                            Submit
                          </span>
                        </button>
                        <br />
                        <br />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
          <FooterNavbar />
        </div>

        <Footer />

        <div
          className="modal fade"
          id="submitModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="submitModal"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Thank you</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body text-center ">
                <p className="small">
                  Thanks for giving us your feedback on the MyHyundai website.
                  <br />
                  {/*You'll now be entered into a free prize draw to win £50 worth
                  of Amazon vouchers.
                  <br />
                  Fingers crossed.*/}
                </p>
                <button
                  type="button"
                  className="btn btn-sm btn-outline-primary removeRadius"
                  data-dismiss="modal"
                >
                  Close window
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* terms */}

        <div
          className="modal fade"
          id="tAndCModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="tAndCModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-lg modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="tAndCModalLabel">
                  T&amp;Cs – MyHyundai – Website Feedback
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                Definition: "Prize" means the Prize consisting of two (2)
                individuals who will win £50 worth of Amazon vouchers for
                submitting their feedback. <br />
                <br />
                “Prize Draw" means the competition described on the site.
                <br /> <br />
                "Terms and Conditions" means the terms and conditions set out in
                this document
                <br /> <br />
                "Winners" means the Entrants who won the Prize
                <br /> <br />
                “Promoter” means Hyundai Motor UK Ltd
                <br /> <br />
                <ol className="theme-ol-list">
                  <li>
                    Entrants and their guests must be a resident in the UK, and
                    over the age of 17. Employees of the Promoter, their Dealers
                    and agents and members of their immediate families, or
                    anyone connected to the creation and administration of the
                    Draw are restricted from entry into this Prize Draw.
                  </li>
                  <li>
                    The Prize Draw is free and no purchase is necessary. To
                    enter each entrant must currently own a Hyundai and submit
                    their feedback through MyHyundai. This will run for 12
                    months and each winner will be selected on 15th August 2019
                    and then 15th February 2020.
                  </li>
                  <li>
                    Only 1 (one) entry per household. Entries submitted with
                    incomplete information, or found to contain invalid
                    information, shall be deemed invalid entries.
                  </li>
                  <li>
                    No automated, third party or bulk entries are permitted.
                    Multiple entries in breach of this provision will result in
                    those entries being disqualified. If the Promoter has
                    reasonable grounds to suspect that such means are being used
                    to circumvent this condition then it may require the entrant
                    to prove that such means were not used. If the Promoter
                    becomes aware of an entrant using means to circumvent this
                    condition then these entries will be disqualified and any
                    Prize entitlement will be void.
                  </li>
                  <li>
                    There will be two (2) Winners. The Prize may be subject to
                    change. The Winner will be selected by means of a random
                    draw conducted under the supervision of an independent
                    adjudicator. Hyundai Motor UK’s decision is final and no
                    correspondence will be entered into. The Prize is not to be
                    re-sold, and is non-transferrable. The promoter reserves the
                    right to request proof of identity to verify eligibility
                    conditions.
                  </li>
                  <li>
                    The Winner will be notified via email within 5 working days
                    of the closing date of the Prize Draw entry. The Winners of
                    the competition will receive their prize directly via Royal
                    Mail, where you will need to sign for delivery. The Prize is
                    awarded conditionally upon acceptance. The Prize shall be
                    deemed as unclaimed or unaccepted and a supplementary Winner
                    may be drawn at the Promoter’s discretion, if unclaimed
                    within 24 hours of the email. The Promoter will not be
                    responsible for the inability of a Prize Winner to take up
                    the specified Prize.
                  </li>
                  <li>
                    The Promoter will not be held responsible for any entries
                    that are delayed or lost in transmission.
                  </li>
                  <li>
                    The Promoter is not responsible for any event that occurs
                    during the activity.
                  </li>
                  <li>
                    The Winners and their guests are responsible for any travel,
                    personal or incidental expenses incurred in connection with
                    the Prize.
                  </li>
                  <li>
                    Events may occur that render the event itself or awarding
                    the Prize impossible due to reasons beyond the control of
                    the Promoter and the entrant agrees that no liability shall
                    be attached to the Promoter as a result thereof. The
                    Promoter reserves the right to offer an alternative prize of
                    equal value should this prove necessary.
                  </li>
                  <li>
                    By entering the Prize Draw, all entrants confirm they have
                    read, accepted and agreed to be bound to the Terms and
                    Conditions of which the application instructions form part.
                  </li>
                  <li>
                    Winners’ Publicity. The Winners accept that the Promoter
                    will have the right, without additional payment or
                    permission, to use his/her name, county of residence,
                    interviews and submitted materials for the purposes of
                    announcing the Winners and for related promotional purposes.
                  </li>
                  <li>
                    The Promoter reserves the right to photograph & film and use
                    the name of the Winners for the purpose of publicity without
                    charge, and the Winners hereby consent to the same.
                  </li>
                  <li>
                    These terms and conditions are subject to English Law and
                    the exclusive jurisdiction of the English courts.
                  </li>
                  <li>
                    The Promoter is Hyundai Motor UK Limited Registered Office
                    728 London Road, High Wycombe, Buckinghamshire, HP11 1HE.
                    Please do not send entries to this address.
                  </li>
                  <li>
                    Any personal data we collect will be held in accordance with
                    General Data Protection Regulation. Your data will be used
                    for the purposes of this competition and not used
                    thereafter, except for winners who have agreed it can be
                    used for future promotional purposes.
                  </li>
                </ol>
                The entry details relating to the competition will be retained
                based on legitimate interest in the following situations:
                <br />
                <ol className="theme-ol-list-letter">
                  <li>
                    ) For winners
                    <ul>
                      <li>
                        a. Whilst the post competition marketing is being
                        performed
                      </li>
                      <li>
                        b. After this point only the minimum information to
                        confirm the winners when requested is needed
                      </li>
                    </ul>
                  </li>
                  <li>
                    ) For non-winners
                    <ul>
                      <li>a. For three months post competition completion</li>
                    </ul>
                  </li>
                </ol>
                For full details of our privacy policy please see our website
                <a href="www.hyundai.co.uk">www.hyundai.co.uk</a>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
