import React, { Component } from 'react';
import RequestHistoryNavbar from '../components/requestHistoryNavbar';
import './myReminders.css';
import FooterNavbar from '../components/footerNavbar';
import TopNavbar from '../components/topnavbar';
import BottomNavbar from '../components/bottomnavbar';
import Footer from '../components/footer';
import CarConfigured from '../components/requestHistory/CarConfigured';
export default class CarsConfigured extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <TopNavbar />
        <BottomNavbar />
        <RequestHistoryNavbar />

        <div className="container">
          <section
            className="section1 wow fadeIn"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <p className="text-center px-3">
              See your Hyundai request history below from the last 6 months
            </p>
            <div className="content p-3 p-xl-5 col-md-10 offset-md-1 border-right border-bottom">
              <div className="row">
                <div className="col-lg-3 p-3 p-sm-4">
                  <h4
                    className="font-weight-bold text-primary theme-main-request-history-sidebar-title mb-0 d-inline-block d-lg-block"
                    data-search-tag="title"
                  >
                    Cars
                    <br />
                    configured
                  </h4>
                  <p className="theme-main-request-history-sidebar-number text-info d-inline d-lg-block float-left float-lg-none">
                    1
                  </p>
                  <div className="clearfix border-bottom d-lg-none" />
                </div>

                <div className="col-lg-9 p-sm-4 border-lg-left">
                  <div className="row">
                    <CarConfigured
                      title="i20 Premium Nav"
                      engine="1.2 84ps Petrol 2WD Manual"
                      exterior="Passion Red"
                      interior="Black"
                      cashPrice="£15,810"
                    />
                  </div>
                  <a  
                    href="https://www.hyundai.co.uk/test-drive"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-primary btn-sm removeRadius"
                  >
                    Book a test drive
                  </a>
                </div>
              </div>
            </div>
          </section>
        </div>

        <FooterNavbar />

        <Footer />
      </div>
    );
  }
}
