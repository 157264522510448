import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';
import Store from 'store';
import ReactGA from 'react-ga';
export default class BusinessAwards extends Component {
  componentDidMount = () => {};

  clickHandler = () => {};

  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="businessAwards-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/business3.jpg") ',
              }}
            />
            <div
              id="businessAwards-gallery-2"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/business1.jpg")',
              }}
            />
            <div
              id="businessAwards-gallery-3"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/business2.jpg")',
              }}
            />
            <div
              id="businessAwards-gallery-4"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/business3.jpg")',
              }}
            />
            <div
              id="businessAwards-gallery-5"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/business4.jpg")',
              }}
            />
            <div
              id="businessAwards-gallery-6"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/business5.jpg")',
              }}
            />
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#businessAwards-gallery-2"
                    style={{
                      backgroundImage: 'url("images/business1.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#businessAwards-gallery-3"
                    style={{
                      backgroundImage: 'url("images/business2.jpg")',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#businessAwards-gallery-4"
                    style={{
                      backgroundImage: 'url("images/business3.jpg")',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#businessAwards-gallery-5"
                    style={{
                      backgroundImage: 'url("images/business4.jpg")',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#businessAwards-gallery-6"
                    style={{
                      backgroundImage: 'url("images/business5.jpg")',
                    }}
                  />
                </div>
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “Hyundai TUCSON and IONIQ 5 triumph at Business Car Awards”
                </p>
              </div>
              <hr />

              <NewsMedia articleId="awards" articleText="awards" />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    HYUNDAI TUCSON AND IONIQ 5 TRIUMPH AT BUSINESS CAR AWARDS
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    {' '}
                    <ul>
                      <li>
                        Hyundai’s fleet credentials bolstered with two wins at
                        Business Car Awards
                      </li>
                      <li>
                        IONIQ 5 named ‘One to Watch’ and TUCSON named ‘Best
                        Medium SUV’
                      </li>
                      <li>
                        TUCSON is available with Hybrid, Plug-in Hybrid or Mild
                        Hybrid powertrains
                      </li>
                      <li>IONIQ 5 is Hyundai’s most innovative EV yet</li>
                      <li>
                        TUCSON is priced from £28,760; IONIQ 5 from £36,995
                      </li>
                    </ul>
                  </p>
                  {displayShowMore && (
                    <p>
                      <a
                        data-toggle="collapse"
                        href="#businessAwards"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </div>

              <div className={moreSectionClassName} id="businessAwards">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      20th October 2021 -{' '}
                      <strong>
                        Two new Hyundai models have received prestigious
                        accolades at the Business Car Awards 2021.
                      </strong>
                      <br />
                      <br />
                      The latest generation Hyundai TUCSON was named ‘Best
                      Medium SUV', while the futuristic-looking Hyundai IONIQ 5
                      was proclaimed ‘One to Watch’ by the Business Car judging
                      panel members.
                      <br />
                      <br /> Summing up the unique appeal of Hyundai’s TUCSON to
                      fleet managers and business drivers alike, Business Car
                      Magazine Editor Martyn Collins commented: "The new TUCSON
                      stands out on the road with its striking design, and we
                      are confident it will stand out for fleets also with its
                      range of advanced electrified powertrains. Drivers are
                      sure to be impressed by its high-quality interior,
                      innovative technical features, and large boot capacity.”
                    </p>
                  </div>
                </div>

                {/* <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      Early in January, the{' '}
                      <a
                        href="https://www.hyundai.news/uk/brand/hyundai-i10-is-what-cars-best-small-car-for-the-city-2021/"
                        target="_blank"
                      >
                        all-new i10 was named What Car?’s Best Small Car for the
                        City,
                      </a>{' '}
                      then the previous generation{' '}
                      <a
                        href="https://www.hyundai.news/uk/model-news/hyundai-scores-double-win-at-what-car-used-car-awards/"
                        target="_blank"
                      >
                        i20 was named as First Car’s Used Car of the Year
                      </a>{' '}
                      as a ‘safe, reliable and easy to drive’ car for new
                      drivers. Continuing Hyundai’s small car wins, carwow named
                      the i10 as its Best Small Car, saying: “it’s a doddle to
                      drive but still roomy enough for four adults and their
                      stuff. It’s big on technology, too.”
                    </p>
                  </div>
                </div> */}

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      <img src="images/business1.jpg" className="img-fluid" />
                      <br />
                      <br />
                      In a year that saw no shortage of new electric cars arrive
                      in the UK, the Business Car ‘One to Watch’ award
                      represented another remarkable achievement for the Hyundai
                      IONIQ 5.
                      <br />
                      <br /> “The first in a new line of electric cars from
                      Hyundai, the IONIQ 5 makes a major statement, set to put
                      the brand toe-to-toe with the biggest names in the
                      marketplace, including established premium brands,”
                      commented Collins.
                      <br />
                      <br />
                      <img src="images/business2.jpg" className="img-fluid" />
                      <br />
                      <br />
                      Speaking at the Business Car Awards ceremony dinner at the
                      Nobu, Portman Square, London, Dominic Gill, Corporate
                      Sales Manager at Hyundai Motor UK, said:
                      <br />
                      <br /> “To have two models win accolades at the Business
                      Car Awards is excellent news for everyone at Hyundai UK.
                      We are utterly convinced of our new model lineup's quality
                      and appeal to the fleet sector, but it's always a special
                      moment to have that confirmed once again by industry
                      aficionados. And with the new Hyundai IONIQ 5 and Hyundai
                      TUCSON, it’s even more gratifying when one looks at the
                      calibre of the competition, shortlisted by Business Car,
                      in their respective model categories.”
                      <br />
                      <br />
                      <img src="images/business3.jpg" className="img-fluid" />
                      <br />
                      <br />
                      Tim White, Head of Fleet at Hyundai Motor UK at Hyundai
                      Motor UK, said: "The Business Car Awards set the gold
                      standard for recognising excellence in the automotive
                      fleet sector. The knowledge of Business Car’s independent
                      experts is second to none, and their judgment informs
                      fleet operators on the best cars they should be running.
                      To have the Hyundai TUCSON and the Hyundai IONIQ 5
                      included in Business Car's exclusive recommendation list
                      is the ultimate proof of our models’ fleet and business
                      user appeal.”
                      <br />
                      <br />
                      <img src="images/business4.jpg" className="img-fluid" />
                      <br />
                      <br />
                      Hyundai’s triumph at the Business Car Awards continues an
                      incredible year for the brand in which the company’s new
                      models have won numerous awards. In June, the Hyundai
                      TUCSON was named DieselCar & EcoCar Magazine’s ‘Car of the
                      Year 2021’, and the same month, Carbuyer hailed the TUCSON
                      as its ‘Car of the Year 2021’. In July, the Hyundai IONIQ
                      5 took 'Car of the Year' in the Auto Express New Car
                      Awards. The magazine bestowed a further five awards on
                      Hyundai models at the same ceremony.
                      <br />
                      <br /> Priced from £28,760, the Hyundai TUCSON is offered
                      to customers with a choice of three powertrains, Hybrid,
                      Plug-in Hybrid and Petrol with Mild Hybrid. The TUCSON’S
                      styling is sophisticated yet rugged, performance is
                      consummately blended with efficiency, and the engaging
                      driving experience is backed up by best-in-class safety.
                      Every TUCSON is equipped with advanced safety features
                      such as Forward Collision Avoidance Assist (FCA) Car,
                      Pedestrian and Cycle and Multi Collision Braking (MCA) as
                      standard.
                      <br />
                      <br /> Priced from £36,995, the sleekly styled Hyundai
                      IONIQ 5 pays homage to the marque's design heritage whilst
                      placing its focus on delivering the future of electric
                      mobility to customers today.
                      <br />
                      <br />
                      <img src="images/business5.jpg" className="img-fluid" />
                      <br />
                      <br />
                      Thanks to Hyundai’s industry-disrupting new Electric
                      Global Modular Platform (E-GMP), the IONIQ 5 provides
                      faster charging times, increased driving range, enhanced
                      handling and superior interior space. The modular,
                      flat-floor E-GMP has also enabled Hyundai’s designers to
                      revolutionise the car interior. The IONIQ 5 redefines what
                      is possible with a completely new in-car experience and
                      living space, offering sustainable materials, fully
                      flexible seating and numerous custom options.
                      <br />
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
