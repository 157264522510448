import React, { Component } from "react";

export default class I30WagonMotorShow extends Component {
  render() {
    return (
      <div className="container">
        <div className="fadeInDownBigFull">
          <div className="card-body newsPadding">
            <div className="card-deck " id="I30WagonMotorShow">
              <div className="card">
                <div className="row">
                  <div className="col-md">
                    <img
                      id="I30WagonMotorShow-gallery-1"
                      className="card-img-top img-fluid news-gallary-image active"
                      src="images/ngi30Wagon.jpg"
                      alt="Card image cap"
                    />
                  </div>
                </div>

                <div className="card-block">
                  <div className="card-content">
                    <div className="col">
                      <div className="row whiteBG">
                        <div className="col-lg-4">
                          <div className="salesText mt-3">
                            <p className="theme-news-snippet">
                              “Timeless design of i30 is enhanced by versatile
                              and elegant form of wagon”
                            </p>
                          </div>
                          <hr />
                          <div
                            className="d-flex justify-content-around mb-2 largerSI social-share-buttons"
                            data-article-id="I30WagonMotorShow"
                            data-article-text="I30 Wagon Motor Show"
                          />
                        </div>

                        <div className="col-lg-8 border-lg-left">
                          <div className="row pt-3">
                            <div className="col-md-10 offset-md-1">
                              <p className="salesText" data-search-tag="title">
                                HYUNDAI MOTOR SHOWS FIRST IMPRESSION OF NEW
                                GENERATION i30 WAGON
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <div className="theme-news-body-text">
                                <ul>
                                  <li>
                                    New wagon bodystyle will be the next member
                                    in the i30 family, following launch of
                                    five-door
                                  </li>
                                  <li>
                                    Timeless design of i30 is enhanced by
                                    versatile and elegant form of wagon
                                  </li>
                                  <li>
                                    The New Generation i30 Wagon will celebrate
                                    its world premiere at Geneva International
                                    Motor Show 2017 during the Hyundai press
                                    conference (8:15 am CET, 7 March 2017)
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div className="row pt-3">
                            <div className="col-md-10 offset-md-1">
                              <p
                                className="theme-news-body-text"
                                data-search-tag="summary"
                              >
                                <strong>17 February 2017 –</strong> This year’s
                                Geneva International Motor Show will be the
                                stage for the unveiling of the New Generation
                                Hyundai i30 Wagon. The new member of the i30
                                family maintains the timeless design of its
                                five-door sibling, adding elegance and
                                versatility in its bodystyle.
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                “The New Generation i30 Wagon speaks the
                                language of the i30 family in terms of design,
                                and it carries the new Hyundai family identity -
                                the Cascading Grille,” explains Thomas Bürkle,
                                Chief Designer at Hyundai Design Centre Europe.
                                “The tapering roofline and the dynamic
                                proportions give the Wagon an almost coupé-like
                                silhouette. The sleek and dynamic profile is
                                further emphasised by a rich chrome frame
                                surrounding the side windows.”
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                The New Generation i30 Wagon will celebrate its
                                world premiere at the Hyundai press conference
                                at the Geneva International Motor Show, taking
                                place on 7 March at 8:15 am (CET).{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
