import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';
import Store from 'store';
import ReactGA from 'react-ga';
export default class Feefo extends Component {
  componentDidMount = () => {};

  clickHandler = () => {};

  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="feefo" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="feefo-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/feefo1..png") ',
              }}
            />
            {/* <div
              id="Bayon-gallery-2"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/hyundaielectric2.jpg")',
              }}
            /> */}
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                {/* <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#Bayon-gallery-2"
                    style={{
                      backgroundImage: 'url("images/hyundaielectric2.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#Bayon-gallery-1"
                    style={{
                      backgroundImage: 'url("images/hyundaielectric1.jpg")',
                    }}
                  />
                </div> */}
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “Hyundai Motor UK awarded Feefo Gold Trusted Service Award”
                </p>
              </div>
              <hr />

              <NewsMedia articleId="awards" articleText="awards" />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    HYUNDAI MOTOR UK AWARDED FEEFO GOLD TRUSTED SERVICE AWARD
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    {' '}
                    <ul>
                      <li>
                        Hyundai Motor UK awarded ‘Gold’ standard for its UK
                        Accident Aftercare programme
                      </li>
                      <li>
                        The complimentary service, delivered in partnership with
                        Verex Group, was rated 4.6 out 5 by claims customers
                      </li>
                    </ul>
                  </p>
                  {displayShowMore && (
                    <p>
                      <a
                        data-toggle="collapse"
                        href="#Feefo"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </div>

              <div className={moreSectionClassName} id="Feefo">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      16 February 2021 -{' '}
                      <strong>
                        Hyundai Motor UK has received one of the highest levels
                        of recognition for its Accident Aftercare programme –
                        ‘Call Hyundai First’ – from leading customer feedback
                        provider Feefo.
                      </strong>
                      <br />
                      <br />
                      The ‘Feefo Gold Trusted Service’ award acknowledges that
                      Hyundai customers receive an exceptional experience from
                      the company’s dedicated team of customer care specialists
                      with a 4.6 out of 5 rating.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      Hyundai Accident Aftercare is a complimentary service
                      offered to all Hyundai vehicle owners. In the event of an
                      accident, the ‘Call Hyundai First’ Accident Aftercare team
                      looks after a customer’s insurance claim, provides
                      accident recovery and a courtesy car while their vehicle
                      is being repaired. They provide the highest quality repair
                      at a Hyundai approved accident repair centre using
                      exclusively Genuine Parts and the latest repair
                      technology.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      Feefo's independent seal of excellence recognises
                      businesses for delivering exceptional customer
                      experiences, and to achieve Gold Trusted status is a real
                      reflection of outstanding service to Hyundai customers at
                      the time of having an accident.
                      <p
                        style={{
                          color: '#ac6b4f',
                          fontSize: '1rem',
                          fontStyle: 'italic',
                        }}
                      >
                        "We take the safety of our customers extremely seriously
                        which is testament to the safety features available as
                        standard across our vehicle line-up. Our complimentary
                        Accident Aftercare programme ensures that we can also
                        offer care to our customers even in the unfortunate
                        event of an accident. This Gold Trusted Service award,
                        borne out of the feedback from our customers, is a
                        genuine confirmation that we are delivering a service
                        that our customers can totally rely upon."
                      </p>
                      <p className="theme-news-body-text">
                        <span style={{ fontSize: '1rem' }}>
                          <strong>Ashley Andrew</strong>
                        </span>{' '}
                        <br />
                        <span style={{ color: 'dimgray' }}>
                          MD of Hyundai Motor UK
                        </span>
                      </p>
                      <p
                        style={{
                          color: '#ac6b4f',
                          fontSize: '1rem',
                          fontStyle: 'italic',
                        }}
                      >
                        "It is tremendous to see such consistent, positive
                        feedback from Hyundai customers when dealing with
                        Hyundai Accident Aftercare. Our primary goal is to
                        deliver a first-rate service which offers Hyundai’s
                        customers total of peace of mind in the event of an
                        accident."
                      </p>
                      <p className="theme-news-body-text">
                        <span style={{ fontSize: '1rem' }}>
                          <strong>Antony Powell</strong>
                        </span>{' '}
                        <br />
                        <span style={{ color: 'dimgray' }}>
                          Client Director, Verex Group
                        </span>
                      </p>
                      <p
                        style={{
                          color: '#ac6b4f',
                          fontSize: '1rem',
                          fontStyle: 'italic',
                        }}
                      >
                        "The Trusted Service award has always been about
                        recognising companies that go way beyond the norm in
                        customer experience and generate great feedback from
                        happy customers. This year, despite the incredible
                        challenges of a global pandemic, so many companies using
                        Feefo have continued to provide remarkably high levels
                        of service and they deserve a huge amount of credit for
                        what they have achieved."
                      </p>
                      <p className="theme-news-body-text">
                        <span style={{ fontSize: '1rem' }}>
                          <strong>Steph Heasman</strong>
                        </span>{' '}
                        <br />
                        <span style={{ color: 'dimgray' }}>
                          Director of Customer Success at Feefo
                        </span>
                      </p>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
