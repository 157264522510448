import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';
import Store from 'store';
import ReactGA from 'react-ga';
export default class Expo2030 extends Component {
  componentDidMount = () => {};

  clickHandler = () => {};

  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="expo2030" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="oneMillion-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/expo2030Tile.jpeg") ',
              }}
            />
            {/* <div
              id="Bayon-gallery-2"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/hyundaielectric2.jpg")',
              }}
            /> */}
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                {/* <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#Bayon-gallery-2"
                    style={{
                      backgroundImage: 'url("images/hyundaielectric2.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#Bayon-gallery-1"
                    style={{
                      backgroundImage: 'url("images/hyundaielectric1.jpg")',
                    }}
                  />
                </div> */}
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “South Korea’s Bid to Host World Expo 2030”
                </p>
              </div>
              <hr />

              <NewsMedia articleId="awards" articleText="awards" />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    SOUTH KOREA'S BID TO HOST WORLD EXPO 2030
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    {' '}
                    <ul>
                      <li>
                        The Group is operating 45 eco-friendly World Expo 2030
                        logo-wrapped vehicles in Davos, Switzerland as delegate
                        transportation
                      </li>
                      <li>
                        The electrified fleet includes Hyundai Santa Fe PHEV as
                        well as Genesis GV60, Electrified GV70 and Electrified
                        G80 and is supported by Hyundai IONIQ 5’s V2L feature
                      </li>
                      <li>
                        The Group also is displaying the Genesis X concept at
                        the Davos venue hosting ‘Korea Night’
                      </li>
                      {/* <li>
                        Hyundai is investing £1 million in a programme of school
                        trips in order to prevent children missing out on such
                        experiences
                      </li>
                      <li>
                        Bookings are now open for the initiative, which has
                        launched with Hyundai’s Educator in Residence, Tim
                        Campbell MBE
                      </li> */}
                    </ul>
                  </p>
                  {displayShowMore && (
                    <p>
                      <a
                        data-toggle="collapse"
                        href="#expo"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </div>

              <div className={moreSectionClassName} id="expo">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      <strong>
                        Hyundai Motor Group (the Group) is promoting the South
                        Korean city of Busan’s bid to host World EXPO 2030 at
                        the World Economic Forum (WEF) in Davos, Switzerland, an
                        event that engages the world’s top political, business
                        and cultural leaders.
                      </strong>
                      <br />
                      <br />
                      During the forum held from Jan. 16 to 20, the Group is
                      operating a fleet of 58 vehicles wrapped with Busan’s
                      World Expo 2030 logo to promote the city to the
                      forum-attending world political and business leaders,
                      Swiss citizens and other visitors.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      In line with Busan’s World Expo vision for a sustainable
                      future (‘Transforming Our World, Navigating Towards a
                      Better Future’), the fleet includes 45 eco-friendly
                      models, including 18 Genesis Electrified G80, eight GV60,
                      four Electrified GV70 and 15 Hyundai Santa Fe plug-in
                      hybrid vehicles. In addition, dedicated electric vehicle
                      (EV) charging stations are set up near event venues along
                      with IONIQ 5 units to provide emergency charging using the
                      model’s Vehicle to Load (V2L) feature.
                      <br />
                      <br />
                      <img src="images/expo20302.jpeg" className="img-fluid" />
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      Not only will the fleet being used as official
                      transportation for the forum’s Korean delegates, but the
                      wrapped vehicles will also function as moving billboards
                      to promote Busan as the host city for the World Expo 2030.
                      <br />
                      <br />
                      Not only will the fleet being used as official
                      transportation for the forum’s Korean delegates, but the
                      wrapped vehicles will also function as moving billboards
                      to promote Busan as the host city for the World Expo 2030.
                      {/* <strong>
                        {' '}
                        "Having updated our brand’s image and pronunciation
                        locally, we wanted to explore pronunciation as a topic
                        more broadly and get people talking about those words
                        that we can potentially struggle with in everyday life,
                        such as quinoa and espresso. We have teamed up with
                        Seong-Jae, a.k.a Korean Billy, who is well known for his
                        light-hearted YouTube videos into different dialects
                        within the UK, to explore some British words from Korean
                        standpoint."
                      </strong>{' '}
                      - Ashley Andrew Managing Director, Hyundai Motor UK */}
                      <br />
                      <br /> To support the fleet, the Group arranged safety
                      measures in case of any sudden changes in winter weather
                      or other emergencies. The fleet is being equipped with AWD
                      and winter tires and a dedicated technical team is on site
                      to ensure safety.
                      <br />
                      <br /> The Group is also displaying the Genesis X concept
                      car in a transparent container at the entrance of the
                      event hall that is hosting ‘Korea Night’ during the WEF.
                      The EV-based concept car presents Genesis’ new perspective
                      on sustainable luxury car design.
                      {/* <a
                        href="https://motorstudio.hyundai.com/busan/ln/main.do?strgC=04&lang=en"
                        target="_blank"
                      >
                        Hyundai Motorstudio Busan
                      </a>{' '}
                      , the ‘Reflections in Motion’ exhibition invites visitors
                      to explore and reflect on the interactive relationships
                      between time and design as well as technology and styling,
                      with various featured installations underscoring Hyundai
                      Motor’s vision centered on enabling ‘Progress for
                      Humanity’. */}
                      <br />
                      <br />
                      Prior to WEF, the Group also promoted Busan’s bid at the
                      Bureau International des Expositions (BIE) General
                      Assembly in Paris in June and November last year, with
                      fleet vehicles wrapped with the Busan World Expo logo.
                      <br />
                      <br />
                      The Group also supported the Busan World Expo bid with
                      promotional banners and brochures during the official
                      vehicle delivery ceremony at the G20 Bali Summit in
                      Jakarta, Indonesia, last November.
                      {/* <strong>
                        "School trips are an integral part of a child’s
                        educations, and I remember my own outings with such fond
                        memories, even all these years later! What’s more, being
                        able to turn these trips into further learning within
                        the classroom is such an important skill and something
                        that should be available for everyone. This programme is
                        extremely timely and will be crucial in putting school
                        trips firmly back on the agenda"
                      </strong>{' '}
                      - Tim Campbell Hyundai's Educator in Residence;
                      <br /> */}
                      <br />
                      <br />
                      In addition, the Group is utilizing its global network to
                      further support the bid through various marketing
                      activities, such as new product launches. Also, the Group
                      is producing content to highlight Busan’s ability to host
                      the World Expo and its vision for the future and sharing
                      it through social channels, including YouTube, Instagram,
                      Facebook and LinkedIn.
                      <br />
                      <br />
                      <br />
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
