import Store from 'store';

export default {
  isAuthenticated() {
    var token = Store.get('loginToken');
    if (token) {
      return true;
    }
    return false;
  },
  init() {
    var token = Store.get('loginToken');
    if (token) {
      this.isAuthenticated = true;
    }
  },

  authenticateWithToken(authToken) {
    Store.set('loginToken', authToken);
  },

  authenticate(email, password, success, failure) {
    Store.each(function (value, key) {
      if (
        key !== 'campaign_name' &&
        key !== 'homeItemGrid' &&
        key !== 'public_config'
      ) {
        Store.remove([key]);
      }
    });

    fetch(process.env.REACT_APP_API_URL + '/authentication', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        username: email,
        password: password,
      }),
    })
      .then((response) => {
        // TODO: fails in EDGE - localhost

        if (response.ok) {
          return response.text();
        }
        throw new Error('Login failed');
      })
      .then((responseText) => {
        Store.set('loginToken', responseText);
        success();
      })
      .catch((error) => {
        this.signout();
        failure();
      });
  },

  authenticatePassword(email, password, authCallBack) {
    return new Promise((resolve, reject) => {
      fetch(process.env.REACT_APP_API_URL + '/authentication', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          username: email,
          password: password,
        }),
      }).then((response) => {
        if (response.ok) {
          resolve(true);
          if (typeof authCallBack === 'function') {
            authCallBack(true);
          }
        } else {
          resolve(false);
          if (typeof authCallBack === 'function') {
            authCallBack(false);
          }
        }
      });
    });
  },

  refreshToken(failed) {
    fetch(
      process.env.REACT_APP_API_URL +
        '/authentication/RefreshToken?key=' +
        Store.get('loginToken').replace(/['"']+/g, '') +
        '&Brand=HyundaiUk',
      {
        method: 'Get',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      },
    )
      .then((response) => {
        if (response.ok) {
          return response.text();
        }
        throw new Error('Login failed');
      })
      .then((responseText) => {
        Store.set('loginToken', responseText);
      })
      .catch((error) => {
        this.signout(failed);
      });
  },

  signout(callback) {
    this.clearStore();

    if (callback) {
      callback();
    }
  },

  clearStore() {
    Store.each(function (value, key) {
      if (key !== 'homeItemGrid') {
        Store.remove([key]);
      }
    });
  },
};
