import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';
import Store from 'store';
import ReactGA from 'react-ga';
export default class Tucson extends Component {
  componentDidMount = () => {
    ReactGA.event({
      label: 'tucson',
      category: 'Promotion',
      action: `tucson Promo News item opened`,
      gaOptions: {
        userId: Store.get('customer') && Store.get('customer').urn,
      },
    });
  };

  clickHandler = () => {
    ReactGA.event({
      label: 'tucson',
      category: 'Promotion',
      action: `tucson Promo News item Link Click`,
      gaOptions: {
        userId: Store.get('customer') && Store.get('customer').urn,
      },
    });
  };

  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="tucson-news" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="tucson-gallery-3"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/tucson-header.png")',
              }}
            />
            <div
              id="tucson-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/tucson-news-1.png") ',
              }}
            />
            <div
              id="tucson-gallery-2"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/tucson-news-2.png")',
              }}
            />
            <div
              id="tucson-gallery-4"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/tucson-news-3.png")',
              }}
            />
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#tucson-gallery-3"
                    style={{
                      backgroundImage: 'url("images/tucson-header.png") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#tucson-gallery-1"
                    style={{
                      backgroundImage: 'url("images/tucson-news-1.png") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#tucson-gallery-2"
                    style={{
                      backgroundImage: 'url("images/tucson-news-2.png") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#tucson-gallery-4"
                    style={{
                      backgroundImage: 'url("images/tucson-news-3.png") ',
                    }}
                  />
                </div>
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">“New – Tucson coming soon”</p>
              </div>
              <hr />

              <NewsMedia
                articleId="tucson-news"
                articleText="ALL-NEW HYUNDAI TUCSON FINISHES INTENSIVE TESTING AND
                QUALITY ASSURANCE"
              />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    ALL-NEW HYUNDAI TUCSON FINISHES INTENSIVE TESTING AND
                    QUALITY ASSURANCE
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    <ul>
                      <li>
                        Before its official market launch, the all-new Tucson
                        has been thoroughly tested. Among others, tests included
                        ride and handling and overall quality
                      </li>
                      <li>
                        The all-new Tucson promises not only striking, unique
                        looks, but also fun-to-drive, efficient powertrains and
                        practical characteristics for everyday usage, from
                        four-wheel drive to towing capabilities
                      </li>
                      <li>
                        Like its predecessor, it will be produced in Europe, for
                        Europe
                      </li>
                    </ul>
                  </p>
                  {displayShowMore && (
                    <p>
                      <a
                        data-toggle="collapse"
                        href="#tucson"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </div>

              <div className={moreSectionClassName} id="tucson">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      <b>Leatherhead, 8 September 2020</b> –The all-new Tucson
                      has completed the last steps of testing and quality
                      assurance. Following an intensive development period in
                      Hyundai testing centres, proving grounds and roads across
                      the globe, the all-new Hyundai Tucson has been fine-tuned
                      in the Czech Republic and Austria ahead of its market
                      launch.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      The all-new Tucson is the fourth generation of Hyundai’s
                      best-selling SUV. It is the first Tucson to be developed
                      under Hyundai’s new design identity, Sensuous Sportiness.
                      It is also fun-to-drive, thanks to responsive engine and
                      transmission choices, paired with practical
                      characteristics for everyday usage, from four-wheel drive
                      to towing capabilities.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      Vehicle quality is a priority for Hyundai. In recent
                      years, the company has consistently been recognised with
                      awards reflecting this. In the 2020 U.S. Initial Quality
                      Study, consumer bible J.D. Power ranked the
                      third-generation Tucson the best compact SUV on the
                      market.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      <i>
                        &quot;Hyundai is committed to producing vehicles of the
                        highest quality. This is also embodied in our
                        industry-leading Five Year Unlimited Mileage Warranty
                        package, which offers peace-of-mind for customers,&quot;
                      </i>{' '}
                      said Andreas-Christoph Hofmann, Vice President Marketing
                      and Product at Hyundai Motor Europe.{' '}
                      <i>
                        &quot;Thanks to the extensive testing of the all-new
                        Tucson ahead of its market introduction, customers can
                        feel confident that they are selecting a car that is one
                        of the best for initial and long-term quality.&quot;
                      </i>
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      <b>Intensive development period</b>
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      In recent months, the all-new Tucson has been developed
                      and tested in Europe performing accelerated durability
                      tests and dynamic testing on the most demanding race track
                      in the world, the famous Nürburgring Nordschleife, as well
                      as undergoing rigorous public-road testing throughout
                      Europe from the coldest winter testing conditions in
                      Sweden to trailer testing in the Alps and hot weather
                      testing in the south of Spain to ensure that the high
                      expectations of European customers are met..
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      Throughout the testing process on a dedicated proving
                      ground in Czech Republic, the all-new Tucson had to prove
                      its quality in extreme conditions and was put through its
                      paces in various scenarios. As a true SUV, the four-wheel
                      drive system, torque distribution, clutch controllability
                      and torque build up at low speeds were tried and tested
                      under different conditions. This thorough testing enables
                      drivers to take the all-new Tucson on to difficult terrain
                      without compromise.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      On challenging roads, for example, Hyundai engineers
                      verified the overall body structure rigidity, opening all
                      doors, tail gate and panorama roof with two wheels on the
                      ground and two wheels diagonally in the air. The doors
                      were closed without any problems during this challenge,
                      confirming the vehicle’s body rigidity. On the Noise
                      Vibration Harshness (NVH) surfaces, engineers identified
                      abnormal noises the car made in certain situations and
                      reduced them in order to guarantee the best experience for
                      all-new Tucson customers. Each surface provided a
                      different frequency of noise in the vehicle, allowing for
                      thorough testing.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      Engineers also drove the vehicle into water to check that
                      features such as the underbody covers and wheel guards
                      would hold properly after driving in water, even at higher
                      speeds. In addition, they examined whether the model can
                      easily drive in water fords with a depth of 20 to 30
                      centimetres. Furthermore, the parking brake, Autohold
                      assistant and Downhill Brake Control had to prove their
                      durability on steep hills with inclines between 30 to 45
                      per cent. Stress was also applied to the clutch during a
                      vehicle launch on a slope to ensure the all-new Tucson is
                      ready for day-to-day challenges.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      After proving that it is built to last in the Czech
                      Republic, the all-new Tucson had to demonstrate its towing
                      capacity in the Austrian Alps. On the Grossglockner
                      mountain, Hyundai’s engineers took the all-new Tucson on a
                      difficult route with a trailer, testing the vehicle’s
                      behavior while towing a trailer uphill, downhill and
                      around corners.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      The ongoing final testing run allows testing engineers to
                      incorporate their latest findings in the final series
                      production vehicle, ensuring the all-new Tucson will
                      deliver a fun and reliable experience for future
                      customers.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      With more than more than 7 million units sold globally
                      since it launched in 2004, 1.4 million of them in Europe
                      alone, the Tucson is Hyundai’s best seller in the region.
                      Production will take place at the Hyundai Motor
                      Manufacturing Czech (HMMC) production plant in Nošovice,
                      Czech Republic, making it the third generation Tucson to
                      be produced in Europe, for Europe.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      The digital world premiere of the all-new Tucson will take
                      place at 01:30am (UK) on 15 September. The livestream, a
                      teaser video and images will be available at{' '}
                      <a href="https://hyundai.com" target="_blank">
                        hyundai.com{' '}
                      </a>{' '}
                      and on Hyundai’s social media channels.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
