import { isNumber } from 'util';

export default {
  parseDate: function (value) {
    var parts = value.split('/');
    if (parts.length !== 3) {
      return null;
    }

    if (
      !/^\d{4}$/.test(parts[2]) ||
      !/^\d{1,2}$/.test(parts[1]) ||
      !/^\d{1,2}$/.test(parts[0])
    ) {
      return null;
    }

    const year = parseInt(parts[2], 10);
    const month = parseInt(parts[1], 10);
    const day = parseInt(parts[0], 10);

    if (isNaN(year) || isNaN(month) || isNaN(day)) {
      return null;
    }
    return new Date(year, month - 1, day);
  },
  parseDateFromApi: function (value) {
    if (value == null) {
      return null;
    }
    var tIndex = value.indexOf('T');

    var parts =
      tIndex > -1 ? value.substr(0, tIndex).split('-') : value.split('-');
    if (parts.length !== 3) {
      return null;
    }

    if (
      !/^\d{4}$/.test(parts[0]) ||
      !/^\d{1,2}$/.test(parts[1]) ||
      !/^\d{1,2}$/.test(parts[2])
    ) {
      return null;
    }

    const year = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10);
    const day = parseInt(parts[2], 10);

    if (isNaN(year) || isNaN(month) || isNaN(day)) {
      return null;
    }

    if (year === 1) {
      return null;
    }

    return new Date(year, month - 1, day);
  },
  formatDate: function (dateValue) {
    console.log({ dateValue });
    if (dateValue == null) {
      return '';
    }
    if (dateValue.getFullYear() === 1) {
      return '';
    }
    return (
      dateValue.getDate() +
      '/' +
      (dateValue.getMonth() + 1) +
      '/' +
      dateValue.getFullYear()
    );
  },
  formatDateLongDate: function (dateValue) {
    if (dateValue == null) {
      return '';
    }
    if (dateValue.getFullYear() === 1) {
      return '';
    }

    return (
      dateValue.getDate() +
      this.getDateSuffix(dateValue.getDate()) +
      ' ' +
      this.getMonth(dateValue.getMonth()) +
      ' ' +
      dateValue.getFullYear()
    );
  },
  formatDateFrom_ddmmyyyy_toLongdate: function (dateString) {
    if (dateString == null || dateString.length !== 8) {
      return '';
    }

    var date = new Date(
      parseInt(dateString.substr(4), 10),
      parseInt(dateString.substr(2, 2), 10) - 1,
      parseInt(dateString.substr(0, 2), 10),
    );
    return this.formatDateLongDate(date);
  },
  formatDateFrom_ddmmyyyy: function (dateString) {
    if (dateString == null || dateString.length !== 8) {
      return '';
    }

    var date = new Date(
      parseInt(dateString.substr(4), 10),
      parseInt(dateString.substr(2, 2), 10) - 1,
      parseInt(dateString.substr(0, 2), 10),
    );
    return date;
  },
  formateDateForApi: function (dateValue) {
    if (dateValue == null) {
      return null;
    }
    return (
      dateValue.getFullYear() +
      '-' +
      (dateValue.getMonth() + 1) +
      '-' +
      dateValue.getDate()
    );
  },
  formatDateFromApi: function (dateValue) {
    if (dateValue == null || dateValue == '') return '';
    try {
      var dateWithoutTime = dateValue.slice(0, 10);
      var dateWithoutDashes = dateWithoutTime.replace(/-/g, '');
      var date = dateWithoutDashes.slice(6, 8);
      var month = dateWithoutDashes.slice(4, 6);
      month = month - 1;
      if (month[0] == 0) month = month[1];
      var year = dateWithoutDashes.slice(0, 4);
      var dateInString = this.getMonth(month);
      return date + ' ' + dateInString + ' ' + year;
    } catch (err) {
      return 'date unspecified';
    }
  },
  getMonth: function (jsMonth) {
    var months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    return months[jsMonth];
  },
  getDateSuffix: function (day) {
    if (day === 1) {
      return 'st';
    }
    if (day === 2) {
      return 'nd';
    }
    if (day === 3) {
      return 'rd';
    }

    return 'th';
  },
  getDaysDifference: function (date1, date2) {
    var one_day = 1000 * 60 * 60 * 24;

    var date1_ms = date1.getTime();
    var date2_ms = date2.getTime();

    var difference_ms = date2_ms - date1_ms;

    return Math.ceil(difference_ms / one_day);
  },
  getToday: function () {
    var d = new Date();
    return new Date(d.getFullYear(), d.getMonth(), d.getDate());
  },
};
