import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';

export default class TopGearAward extends Component {
  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="TopGearAward" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="topGearAward-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/top_gear1.jpg") ',
              }}
            />
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a  
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#topGearAward-gallery-1"
                    style={{
                      backgroundImage: 'url("images/top_gear1_th.jpg") ',
                    }}
                  />
                </div>
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “Top motoring mag BBC Top Gear Magazine has crowed Hyundai its
                  Manufacturer of the Year!”
                </p>
              </div>
              <hr />

              <NewsMedia
                articleId="topGearAward"
                articleText="Motoring mega-mag chooses Hyundai"
              />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    MOTORING MEGA-MAG CHOOSES HYUNDAI
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    {' '}
                    Hold the front page! Stop the presses! Top motoring mag BBC
                    Top Gear Magazine has crowned Hyundai its manufacturer of
                    the year!
                  </p>
                  {displayShowMore && (
                    <p>
                      <a  
                        data-toggle="collapse"
                        href="#collapsetopGearAward"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                  {/* <p className="theme-news-body-text">The i30 Fastback’s sloping roofline, long bonnet and muscular body, along with its slim cabin and wide wheel arches give the car a strong stance. That strength, however, is far from being merely skin deep.</p> */}
                </div>
              </div>

              <div className={moreSectionClassName} id="collapsetopGearAward">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      In its latest Awards Issue, which is on sale now, BBC Top
                      Gear Magazine praises Hyundai for its rapid development,
                      and diverse and ambitious product range.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      “After decades of us telling you Hyundais have less
                      personality than call centre hold music, the South Koreans
                      have pulled it off,” the magazine writes. “Hyundai now
                      boasts an arsenal of cars to make established
                      conglomerates jealous. An underdog that wins best in show?
                      Humble Hyundai has come a long way.”{' '}
                    </p>
                  </div>
                </div>

                {/* <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {" "}
                      The SUV will feature the 2.2 CRDi “R” engine,
                      which has been upgraded to further reduce
                      emissions, and will be available with front
                      wheel drive or 4WD, with a 6-speed manual
                      gearbox or an all-new 8-speed automatic
                                  transmission.{" "}
                    </p>
                  </div>
                </div> */}

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      Top Gear lavished specific praise on the ‘mega-hyped’
                      i30N, the ‘teardrop eco hatch’ IONIQ and the
                      ‘quirky-looking but chilled driving family’ Kona Electric.{' '}
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      “To receive this recognition from BBC Top Gear Magazine is
                      a testament to just how far we have come even in the last
                      decade,” said Tony Whitehorn, President and CEO, Hyundai
                      Motor UK. “In 2008, we sold just 28,000 vehicles in the
                      UK. Fast-forward to 2018, we are one of the top 10 car
                      manufacturers in the UK, the number one reason people buy
                      our cars is design, and we will sell more than 90,000 cars
                      before the end of the year. Our brand continues to evolve
                      and we are very proud that we are able to offer our
                      customers even greater choice than ever before.”{' '}
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      From city cars to hot hatches to SUVs both large and
                      small, and with a choice of combustion and hybrid engines
                      as well as zero emissions electric and hydrogen power,
                      Hyundai now offers one of the broadest product ranges in
                      the industry. Take a look at{' '}
                      <a href="https://www.hyundai.co.uk/new-cars">
                        www.hyundai.co.uk/new-cars
                      </a>{' '}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
