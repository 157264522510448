import React, { Component } from "react";

export default class SutCancer extends Component {
  render() {
    return (
      <div className="container">
        <div className="fadeInDownBigFull">
          <div className="card-body newsPadding">
            <div className="card-deck " id="SutCancer">
              <div className="card">
                <div className="row">
                  <div className="col-md">
                    <img
                      id="SutCancer-gallery-1"
                      className="card-img-top img-fluid news-gallary-image active"
                      src="images/sutCancer.jpg"
                      alt="Card image cap"
                    />
                  </div>
                </div>

                <div className="card-block">
                  <div className="card-content">
                    <div className="col">
                      <div className="row whiteBG">
                        <div className="col-lg-4">
                          <div className="salesText mt-3">
                            <p className="theme-news-snippet">
                              “Jumping straight into action, we will soon be
                              launching a whole range of exciting and innovative
                              fundraising activities across the country”
                            </p>
                          </div>
                          <hr />
                        </div>

                        <div className="col-lg-8 border-lg-left">
                          <div className="row pt-3">
                            <div className="col-md-10 offset-md-1">
                              <p className="salesText" data-search-tag="title">
                                HYUNDAI MOTOR UK HAS JOINED THE REBELLION
                                AGAINST CANCER BY BECOMING AN OFFICIAL PARTNER
                                OF STAND UP TO CANCER 2017
                              </p>
                            </div>
                          </div>

                          <div className="row pt-3">
                            <div className="col-md-10 offset-md-1">
                              <div className="theme-news-body-text">
                                {" "}
                                <p data-search-tag="summary">
                                  <strong>May 2017 –</strong> Hyundai Motor UK
                                  is proud to announce it has become an Official
                                  Partner of Stand Up to Cancer, bringing the
                                  two organisations’ innovative,
                                  technology-driven approaches together to fight
                                  the disease.
                                </p>
                                <p>
                                  “One in two of us will be diagnosed with
                                  cancer in our lifetime, but the crucial thing
                                  is that together we can actually do something
                                  about it. I hope that through our fundraising
                                  plans, we can inspire people to stand up,
                                  donate and save lives”, says Tom Whitehorn,
                                  Hyundai UK President and CEO.
                                </p>
                                <p>
                                  Jumping straight into action, we will soon be
                                  launching a whole range of exciting and
                                  innovative fundraising activities across the
                                  country, including a world-first project using
                                  a very special IONIQ Electric – so watch this
                                  space for the big reveal in the next few
                                  weeks…
                                </p>
                                <p>
                                  Our whole Hyundai UK team will be at their
                                  entrepreneurial best to take on a series of
                                  challenges – and will be inviting you to join
                                  in with an array of fun and intriguing
                                  activities to raise the funds to help Stand up
                                  to Cancer turn breakthroughs in the lab into
                                  real-life treatments for us all.
                                </p>
                                <p>
                                  Stand Up to Cancer is a joint national
                                  fundraising campaign from Cancer Research UK
                                  and Channel 4 that has raised more than £38
                                  million since 2012 to accelerate research and
                                  development of new treatments and tests,
                                  supporting more than 40 clinical trials and
                                  benefiting thousands of patients up and down
                                  the country.
                                </p>
                                <p>
                                  To find out more about the discoveries and
                                  difference made to our communities by Stand Up
                                  to Cancer, go to{" "}
                                  <a  
                                    href="https://www.standuptocancer.org.uk/"
                                    target="_blank" rel="noopener noreferrer"
                                  >
                                    standuptocancer.org.uk
                                  </a>.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
