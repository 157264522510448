import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';
import Store from 'store';
import ReactGA from 'react-ga';
export default class HyundaiEv extends Component {
  componentDidMount = () => {};

  clickHandler = () => {};

  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="hyundaiEv-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/hyundaievheader.png") ',
              }}
            />
            <div
              id="hyundaiEv-gallery-2"
              className="theme-news-header-image "
              style={{
                backgroundImage: 'url("images/hyundaiev1.png") ',
              }}
            />
            <div
              id="hyundaiEv-gallery-3"
              className="theme-news-header-image "
              style={{
                backgroundImage: 'url("images/hyundaiev2.png") ',
              }}
            />
            <div
              id="hyundaiEv-gallery-4"
              className="theme-news-header-image "
              style={{
                backgroundImage: 'url("images/hyundaiev3.png") ',
              }}
            />
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#hyundaiEv-gallery-2"
                    style={{
                      backgroundImage: 'url("images/hyundaiev1.png") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#hyundaiEv-gallery-3"
                    style={{
                      backgroundImage: 'url("images/hyundaiev2.png") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#hyundaiEv-gallery-4"
                    style={{
                      backgroundImage: 'url("images/hyundaiev3.png") ',
                    }}
                  />
                </div>
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “Hyundai leads industry desirability rankings as consumers
                  tune into EVs”
                </p>
              </div>
              <hr />

              <NewsMedia articleId="awards" articleText="awards" />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    HYUNDAI LEADS INDUSTRY DEDIRABILITY RANKINGS AS CONSUMERS
                    TUNE INTO EVS
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    {' '}
                    <ul>
                      <li>
                        Hyundai Motor UK has taken top spot in two industry
                        rankings with its fully electrified IONIQ 5 and IONIQ
                        Electric.
                      </li>
                      <li>
                        Auto Trader sees Hyundai IONIQ 5 coming out top for ad
                        views during recent fuel crisis
                      </li>
                      <li>
                        Hyundai leads Aston Barclay desirability index for the
                        UK’s 25 most desirable used cars
                      </li>
                      <li>
                        IONIQ 5 is the first model in Hyundai’s EV-exclusive
                        IONIQ sub-brand and has seen strong consumer demand,
                        with over 1,000 registrations since launch
                      </li>
                    </ul>
                  </p>
                  {displayShowMore && (
                    <p>
                      <a
                        data-toggle="collapse"
                        href="#hyundaiEv"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </div>

              <div className={moreSectionClassName} id="hyundaiEv">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      25TH October 2021 -{' '}
                      <strong>
                        Hyundai Motor UK is leading two industry rankings as
                        consumers tune into decarbonising road transport and
                        look for the most desirable electrified mobility
                        solutions on sale.
                      </strong>
                      <br />
                      <br />
                      The revolutionary IONIQ 5 has been at the forefront of
                      consumer shortlists for electric vehicles since going on
                      sale earlier this year. With the recent fuel shortages,
                      Auto Trader has seen a surge in EV ad views, with IONIQ 5
                      topping the list. As a go-to destination for car buyers,
                      Auto Trader reported new and used electric model ad views
                      increased a record 28% and 61% respectively versus the
                      previous weekend of the fuel crisis. The site also saw an
                      increase in the number of people sending enquiries to
                      retailers.
                      <br />
                      <br />{' '}
                      <strong>
                        Ian Plummer, commercial director, Auto Trader, said:
                      </strong>{' '}
                      “Four out of five of the top performing new cars on Auto
                      Trader were electric, with the Hyundai IONIQ 5 coming out
                      top. The launch of ‘cool’ and aspirational models has been
                      key to shifting the consumer perception of electric. This
                      has been accelerated further by the significant increase
                      in advertising among manufacturers, which is helping to
                      drive awareness and dispel lingering myths.”
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      <img src="images/hyundaiev1.png" className="img-fluid" />
                      <br />
                      <br />
                      Additionally, Aston Barclay has ranked the UK’s 25 most
                      desirable used cars as part of its desirability index,
                      further highlighting consumer shifts toward electric
                      mobility solutions. For the first time, an electric model
                      has topped the ranking with Hyundai IONIQ Electric the
                      most in-demand used car across both its physical and
                      online auction channels during October. This reinforces
                      how zero and low emission used cars are growing in
                      popularity as consumers become ever more aware of
                      environmental impact.
                      <br />
                      <br />{' '}
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      <img src="images/hyundaiev2.png" className="img-fluid" />
                      <br />
                      <br />
                      <strong>
                        Martin Potter, Aston Barclay’s Managing Director said:
                      </strong>
                      “Our latest index highlights the increased desire for
                      electric and hybrids as used cars. More consumers are
                      considering owning EVs for the first time which is
                      increasing the demand in the wholesale market. With the
                      extended lead times currently facing the industry,
                      consumers are maintaining their focus on the premium used
                      car market. Dealers continue to compete for the same stock
                      to satisfy consumer demand which is fuelling price rises.”
                      <br />
                      <br />
                      <img src="images/hyundaiev3.png" className="img-fluid" />
                      <br />
                      <br />
                      Aston Barclay’s index takes into consideration three key
                      metrics: web views prior to sale, number of physical and
                      online bids per sale, and the sale price achieved as a
                      percentage of CAP average.
                      <br />
                      <br />
                      <strong>
                        {' '}
                        Ashley Andrew, Managing Director Hyundai Motor UK, said:
                      </strong>{' '}
                      “To see such heightened consumer interest and demand for
                      our no-compromise approach with our EV line-up is great to
                      see. IONIQ 5 cements Hyundai as pioneers in the
                      electrified mobility space and as we journey towards
                      decarbonising road transport we look to take consumers
                      with us on the road ahead to a cleaner World.”
                      <br />
                      <br />
                      {/* <img src="images/hyundaiEv4.png" className="img-fluid" />
                      <br />
                      <br />
                      <strong>
                        Ashley Andrew, Managing Director, Hyundai Motor UK,
                        said:
                      </strong>
                      “The i20 N was engineered to deliver a pure,
                      uncompromising driving experience majoring on fun, while
                      also ensuring it excels in everyday practicality and
                      usability. The challenge was to create the ultimate
                      affordable performance car. To see it not only lining up
                      against genuine icons of the supercar world but even
                      toppling all of them and coming out on top in one of the
                      industry’s most comprehensive performance group tests is
                      the ultimate seal of approval.”
                      <br />
                      <br />
                      <img src="images/hyundaiEv5.png" className="img-fluid" />
                      <br />
                      <br />
                      The Hyundai i20 N is available to order now, delivering
                      World Rally Championship-inspired styling and performance
                      from just £24,995 On the Road (OTR). */}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
