export const BaseService = [
  'Complete Hyundai Roadside Assistance and Accident Aftercare registration',
  'Complete VHC',
  'Check all systems with GDS',
  'Reset Service Reminder System',
  'Replace Engine Oil & Filter',
  'Inspect Engine Coolant Specific Gravity',
  'Check Tyre Pressures (incl. spare/space saver)',
  'Inspect Front and Rear Brake Discs',
  'Inspect Fluid Leaks',
  'Lubricate locks and hinges',
  'Inspect Cabin Filter',
  'Road Test Vehicle',
  'Anti-Perforation Body Check',
  'Wash and Vacuum',
  'Inspect Brake Fluid',
  'Inspect Clutch Fluid',
  'Inspect Parking Brake',
  'Check AC System',
  'Inspect Alternator Oil Hose and Vacuum Hose',
  'Inspect Fuel System, Tank and Lines',
  'Inspect Driveshafts & Boots',
  'Inspect Front and Rear Wheel Bearings',
  'Inspect Bolts & Nuts on Chassis & Body',
];
export const InterimService = [
  'Replace Air Cleaner',
  'Replace Fuel Filter (Diesel Models)',
  'Inspect Turbo/Intercooler hoses',
  'Inspect Parking Brake Shoes',
  'Inspect Drive Belt',
  'Replace Brake Fluid',
  'Replace Clutch Fluid',
  'Remove and Rotate Road Wheels',
  'Check Parking Brake (adjust if required)',
];
export const FullService = [
  'Replace Spark Plugs (Petrol Models)',
  'Inspect Fuel Filter (Petrol Models)',
  'Replace Engine Coolant',
  'Inspect Transfer Box Oil (4WD only)',
  'Inspect Rear Axle Oil (4WD/Rear wheel drive models only)',
  'Inspect Transmission Oil',
  'Replace Cabin Filters',
];
