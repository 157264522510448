import Dates from './DateFormat';

export default {
  anyInvalid: function (state) {
    if (state == null || state.validation == null) {
      return false;
    }
    var validationState = state.validation;

    for (var property in validationState) {
      if (validationState.hasOwnProperty(property)) {
        if (!validationState[property].isValid) {
          return true;
        }
      }
    }

    return false;
  },
  validateAll: function (state) {
    if (state == null || state.validation == null) {
      return null;
    }

    var newState = state;

    for (var property in newState.validation) {
      if (newState.validation.hasOwnProperty(property)) {
        newState = this.validate(newState, property);
      }
    }

    return newState;
  },
  validate: function (state, fieldName) {
    const value = state[fieldName];
    return {
      ...state,
      validation: {
        ...state.validation,
        [fieldName]: this.updateValidationState(
          value,
          state.validation[fieldName],
        ),
      },
    };
  },
  updateValidationState: function (value, validationState) {
    if (
      validationState != null &&
      validationState.rules != null &&
      validationState.rules.length != 0
    ) {
      for (var i = 0; i < validationState.rules.length; i++) {
        var message = validationState.rules[i](value);
        if (message != null) {
          return {
            ...validationState,
            isValid: false,
            message: message,
          };
        }
      }
    }
    return {
      ...validationState,
      isValid: true,
      message: null,
    };
  },
  initValidationState: function (rules, customRule) {
    var rules = rules.map((rule) => {
      switch (rule) {
        case 'required': {
          return this.validateRequired;
        }
        case 'postcode': {
          return this.validatePostcode;
        }
        case 'email': {
          return this.validateEmail;
        }
        case 'phone': {
          return this.validatePhone;
        }
        case 'terms': {
          return this.validateTerms;
        }
        case 'option-selected': {
          return this.validateOptionSelected;
        }
        case 'password': {
          return this.validatePassword;
        }
        case 'date': {
          return this.validateDate;
        }
        case 'past-date': {
          return this.validatePastDate;
        }
        case 'future-date': {
          return this.validateFutureDate;
        }
        case 'number': {
          return this.validateNumber;
        }
        case 'match': {
          return this.validateMatch;
        }
        case 'length100': {
          return this.validateLength100;
        }
      }
    });

    if (customRule != null) {
      rules.push(customRule);
    }

    return {
      isValid: true,
      message: null,
      rules: rules,
    };
  },
  initRequiredValidationState: function () {
    return this.initValidationState(['required']);
  },
  validateRequired: function (value) {
    var isValid = value != null && value.length != 0;
    return isValid ? null : 'Required';
  },
  validateReminder: function (date, reminder) {
    const isZeroDate = date == '0001-01-01T00:00:00';

    date = Dates.formatDate(Dates.parseDateFromApi(date)) || date;

    if ((date == null || isZeroDate) && reminder != 0) {
      return 'Date is required';
    }

    if (date != null && !isZeroDate && reminder == 0) {
      return 'Reminder period is required';
    }

    if (date != null && reminder != 0) {
      const validateDate = this.validateDate(date);
      if (validateDate) {
        return validateDate;
      } else {
        const validateFutureDate = this.validateFutureDate(date);
        if (validateFutureDate) {
          return validateFutureDate;
        } else {
          return null;
        }
      }
    }

    return null;
  },
  validatePostcode: function (value) {
    var isValid = /[A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2}/i.test(value);
    return isValid ? null : 'Invalid postcode';
  },
  validateMatch: function (firstValue, secondValue) {
    var match = firstValue == secondValue;
    return match ? null : 'Passwords do not Match';
  },

  validateEmail: function (value) {
    // General Email Regex (RFC 5322 Official Standard) - https://emailregex.com/
    var isValid = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value,
    );

    return isValid ? null : 'Invalid email';
  },
  validatePhone: function (value) {
    var isValid =
      value.length == 0 || /^(?:0|\+?44 ?)(?:\d\s?){9,10}$/.test(value);

    return isValid ? null : 'Invalid phone number';
  },
  validateTerms: function (value) {
    var isValid = value.toString() == 'true';
    return isValid ? null : 'You must accept the terms and conditions';
  },
  validateOptionSelected: function (value) {
    var isValid = value.toString() == 'true';
    return isValid ? null : 'You must select at least one';
  },
  validateDate: function (value) {
    var isValid = /^((([012]?\d|30)\/(0?[469]|11)|([012]?\d|3[01])\/(0?[13578]|1[02])|([01]?\d|2[0-9])\/0?2)\/(20|19)\d{2})?$/.test(
      value,
    );
    return isValid ? null : 'Invalid date';
  },
  validatePastDate: function (value) {
    var date = Dates.parseDate(value);
    var isValid = date <= Dates.getToday();
    return isValid ? null : 'Cannot be in the future';
  },
  validateFutureDate: function (value) {
    var date = Dates.parseDate(value);
    var isValid = date > Dates.getToday();
    return isValid ? null : 'Cannot be in the past';
  },
  validateNumber: function (value) {
    var isValid = /^(\d+)?$/.test(value);
    return isValid ? null : 'Invalid number';
  },
  validatePassword: function (value) {
    var error = '';

    if (!/^(?=.*[a-zA-Z]).+$/.test(value)) {
      error += 'Must contain an alpha character \r\n';
    }
    if (!/^(?=.*[A-Z]).+$/.test(value)) {
      error += 'Must contain an upper case character \r\n';
    }

    if (!/^(?=.*[0-9]).+$/.test(value)) {
      error += 'Must contain a number \r\n';
    }
    if (value.length < 8) {
      error += 'Minimum 8 characters \r\n';
    }
    if (value.length > 25) {
      error += 'Maximum of 25 characters \r\n';
    }
    return error.length == 0 ? null : error;
  },
  validateLength100: function (value) {
    const characters = value.split('');
    var isValid = characters.length < 100;
    return isValid ? null : 'Maximum of 100 characters';
  },
};
