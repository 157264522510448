import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';
import Store from 'store';
import ReactGA from 'react-ga';
export default class KonaN extends Component {
  componentDidMount = () => {
    ReactGA.event({
      label: 'New i20',
      category: 'Promotion',
      action: `i20 Promo News item opened`,
      gaOptions: {
        userId: Store.get('customer') && Store.get('customer').urn,
      },
    });
  };

  clickHandler = () => {
    ReactGA.event({
      label: 'New i20',
      category: 'Promotion',
      action: `i20 Promo News item Link Click`,
      gaOptions: {
        userId: Store.get('customer') && Store.get('customer').urn,
      },
    });
  };

  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="KonaN" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="konaN-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/konaN1.jpg") ',
              }}
            />
            <div
              id="konaN-gallery-2"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/konaN2.jpg")',
              }}
            />
            <div
              id="konaN-gallery-3"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/konaN3.jpg")',
              }}
            />
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#konaN-gallery-2"
                    style={{
                      backgroundImage: 'url("images/konaN2.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#konaN-gallery-1"
                    style={{
                      backgroundImage: 'url("images/konaN1.jpg")',
                    }}
                  />
                </div>
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “Hyundai Motor to Unveil The All-New KONA N”
                </p>
              </div>
              <hr />

              <NewsMedia
                articleId="newi20"
                articleText="All New i20 is a big deal"
              />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    HYUNDAI MOTOR TO UNVEIL THE ALL-NEW KONA N AT 'N DAY'
                    DIGITAL EVENT
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    {' '}
                    <ul>
                      <li>
                        The all-new KONA N, a performance SUV, makes its world
                        premiere at the Hyundai N Day digital event on April 27,
                        2021
                      </li>
                      <li>
                        {' '}
                        Hyundai Motor will share the brand’s philosophy, new
                        brand claim and future e-performance direction
                      </li>
                    </ul>
                  </p>
                  {displayShowMore && (
                    <p>
                      <a
                        data-toggle="collapse"
                        href="#newi20"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </div>

              <div className={moreSectionClassName} id="newi20">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      15 April 2021 –{' '}
                      <strong>
                        Hyundai Motor Company will unveil the all-new KONA N at
                        it’s first-ever Hyundai N Day, a digital showcase for
                        its high-performance N Brand, on April 27, 2021.
                      </strong>
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      KONA N is not only the latest addition to Hyundai’s
                      growing high-performance N line-up but is also the first N
                      model with an SUV body type. With a powerful combination
                      of versatility, spirited acceleration, agility and
                      racetrack capability, KONA N stands to change what people
                      expect from an SUV. The all-new KONA N will be presented
                      by Albert Biermann, President and Head of R&D Division at
                      Hyundai Motor Group.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      <img src="images/konaN3.jpg" className="img-fluid" />
                      <p style={{ color: 'dimgray', fontSize: 'smaller' }}>
                        Hyundai N | N Day Teaser – The all new KONA N World
                        Premiere
                      </p>
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      The company will use N Day as a platform for sharing the N
                      brand high-performance philosophy, introduce the new brand
                      claim, share news about upcoming N models, and talk about
                      its future e-performance direction. This part will be
                      presented by Thomas Schemera, Executive Vice President and
                      Head of Customer Experience Division.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      <img src="images/konaN2.jpg" className="img-fluid" />
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      <p
                        style={{
                          color: '#ac6b4f',
                          fontSize: '1rem',
                          fontStyle: 'italic',
                        }}
                      >
                        "We are very excited to unveil the KONA N, a true hot
                        SUV with all the driving fun N offers. With our first
                        ever N Day, we aim to continue the story of N and share
                        our vision with this digital format to further engage
                        with our enthusiasts."
                      </p>
                    </p>
                    <p className="theme-news-body-text">
                      <span style={{ fontSize: '1rem' }}>
                        <strong>Till Wartenberg</strong>
                      </span>{' '}
                      <br />
                      <span style={{ color: 'dimgray' }}>
                        Vice President and Head of N Brand Management &
                        Motorsport Sub-Division at Hyundai Motor Company
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
