import Store from 'store';

export default {
  load() {
    return new Promise((resolve, reject) => {
      fetch('data/Config.json', {
        method: 'GET',
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error('Error in getting public_config');
          }
        })
        .then((values) => {
          Store.set('public_config', values);
          resolve(true);
        })
        .catch((error) => {
          reject(new Error('reject'));
        });
    });
  },
};
