import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Store from 'store';
//-- CSS
import './offersSideNav.css';

export default class OffersSideNav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      offers: Store.get('offers'),
    };
  }

  render() {
    return (
      <div data-search-tag="menu" data-search-exclude>
        <Link to="/offers" className="theme-offerSideNav-title">
          <h4>
            <strong>Offers</strong>
          </h4>
        </Link>

        <br />

        {this.state.offers.map((offer, index) => {
          return (
            <Link to={offer.link} key={index} title={offer.title}>
              <small>
                <p className="asUnderline">{offer.name}</p>
              </small>
            </Link>
          );
        })}
      </div>
    );
  }
}
