import React, { Component } from "react";

export default class I3024hrRace extends Component {
  render() {
    return (
      <div className="container">
        <div className="fadeInDownBigFull">
          <div className="card-body newsPadding">
            <div className="card-deck " id="I3024hrRace">
              <div className="card">
                <div className="row">
                  <div className="col-md">
                    <img
                      id="I3024hrRace-gallery-1"
                      className="card-img-top img-fluid news-gallary-image active"
                      src="images/24hrRace.jpg"
                      alt="24hr Race"
                    />
                  </div>
                </div>

                <div className="card-block">
                  <div className="card-content">
                    <div className="col">
                      <div className="row whiteBG">
                        <div className="col-lg-4">
                          <div className="salesText mt-3">
                            <p className="theme-news-snippet">
                              “It’s going to have a high-performance 2.0-litre
                              turbo engine with six-speed manual transmission”
                            </p>
                          </div>
                          <hr />
                          <div
                            className="d-flex justify-content-around mb-2 largerSI social-share-buttons"
                            data-article-id="I3024hrRace"
                            data-article-text="I30 24hr Race"
                          />
                        </div>

                        <div className="col-lg-8 border-lg-left">
                          <div className="row pt-3">
                            <div className="col-md-10 offset-md-1">
                              <p className="salesText" data-search-tag="title">
                                HYUNDAI MOTOR TO TEST CLOSE TO PRODUCTION i30 N
                                IN ENDURANCE RACE IN NÜRBURGRING AHEAD OF 24
                                HOURS RACE PARTICIPATION
                              </p>
                            </div>
                          </div>

                          <div className="row pt-3">
                            <div className="col-md-10 offset-md-1">
                              <p
                                className="theme-news-body-text"
                                data-search-tag="summary"
                              >
                                {" "}
                                <strong>April 2017 –</strong> We can already
                                promise you it’s going to be thrilling. It’s
                                going to have real racing DNA. It’s going to
                                have a high-performance 2.0-litre turbo engine
                                with six-speed manual transmission, just waiting
                                to be enjoyed.
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                Because the new Hyundai i30N, the first model
                                from our high-performance sub-brand N, has been
                                undergoing fierce testing and refinement around
                                the 73 legendary corners of the Nürburgring
                                during its first 24-hour endurance race on 8
                                April 2017.
                              </p>
                              <p className="theme-news-body-text">
                                With our engineers from South Korea joining
                                forces with our European team at the track, the
                                concentration has been on refining technical
                                specifications and features that are very close
                                to what you’ll be able to drive in the
                                production i30N when it hits the road near you
                                later this year.
                              </p>
                              <p className="theme-news-body-text">
                                We’ll be racing again at the infamous ADAC
                                Zurich 24-hour race at the Nürburgring at the
                                end of May to continue to hone the car’s
                                performance characteristics and racing pedigree.
                              </p>
                              <p className="theme-news-body-text">
                                Want to know more about the new i30N and
                                Hyundai’s high-performance N brand? Keep
                                watching this space as we build up to the i30N
                                global launch later in 2017.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
      </div>
    );
  }
}
