import React, { Component } from "react";

export default class FutureOfMobility extends Component {
  render() {
    return (
      <div className="container">
        <div className="fadeInDownBigFull">
          <div className="card-body newsPadding">
            <div className="card-deck " id="FutureOfMobility">
              <div className="card">
                <div className="row">
                  <div className="col-md">
                    <img
                      id="FutureOfMobility-gallery-1"
                      className="card-img-top img-fluid news-gallary-image active"
                      src="images/futureOfMobility2017.jpg"
                      alt="Card image cap"
                    />
                  </div>
                </div>

                <div className="card-block">
                  <div className="card-content">
                    <div className="col">
                      <div className="row whiteBG">
                        <div className="col-lg-4">
                          <div className="salesText mt-3">
                            <p className="theme-news-snippet">
                              “Strategy includes hyper-connected car platform to
                              seamlessly link mobility with the home and
                              workplace”
                            </p>
                          </div>
                          <hr />
                        </div>

                        <div className="col-lg-8 border-lg-left">
                          <div className="row pt-3">
                            <div className="col-md-10 offset-md-1">
                              <p className="salesText" data-search-tag="title">
                                HYUNDAI MOTOR REVEALS VISION FOR ‘FUTURE
                                MOBILITY’ AT THE 2017 CONSUMER ELECTRONICS SHOW
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <div className="theme-news-body-text">
                                <ul>
                                  <li>
                                    Hyundai Motor showcases advanced
                                    self-driving technology through urban trials
                                    of Autonomous IONIQ models in Las Vegas
                                  </li>
                                  <li>
                                    Strategy includes hyper-connected car
                                    platform to seamlessly link mobility with
                                    the home and workplace
                                  </li>
                                  <li>
                                    Hyundai Motor’s vision for future mobility
                                    incorporates wearable robotics,
                                    micro-mobility solutions and interactive,
                                    health-conscious technology
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div className="row pt-3">
                            <div className="col-md-10 offset-md-1">
                              <p
                                className="theme-news-body-text"
                                data-search-tag="summary"
                              >
                                <strong>January 5, 2017 –</strong> Hyundai Motor
                                is showcasing key elements of its strategy for
                                future mobility at the Consumer Electronics Show
                                (CES) held in Las Vegas, from January 5 to 8,
                                2017. This next generation of convergent
                                technologies focuses on linking cars to
                                customer’s lives, through intelligent autonomous
                                driving, smart hyper-connectivity solutions and
                                innovations to promote wellness in mobility.
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                <strong>
                                  Autonomous IONIQ concepts reveal advanced
                                  self-driving technologies
                                </strong>
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                Hyundai Motor is using live demonstrations and
                                immersive virtual reality displays to present
                                the capabilities of its latest self-driving
                                technology. Two cars are navigating test routes
                                in the Las Vegas boulevards as part of ongoing
                                advanced trials in urban environments, while CES
                                visitors can experience a driverless journey via
                                immersive Virtual Reality simulators at the
                                Hyundai Motor booth.{" "}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                Moving forward with the use of LiDAR technology,
                                Hyundai Motor has hidden the hardware behind the
                                Autonomous IONIQ’s front bumper rather than
                                using the typical roof-mounted approach,
                                retaining the new car’s sleek design. Hyundai
                                Motor’s latest technology allows the cars to
                                navigate the most challenging situations safely,
                                including high levels of pedestrian traffic,
                                stop lights and signs, road construction and
                                road blocks, speed bumps, dogs, small children
                                and even intersections without traffic signals.{" "}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                <strong>
                                  Connected Car platform combines intelligent
                                  automotive and IT technologies
                                </strong>
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                Another interactive experience at Hyundai
                                Motor’s CES booth demonstrates how the
                                ‘hyper-connected car’ will be central to buying
                                greater convenience, comfort and enjoyment to
                                tomorrow’s lifestyles.{" "}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                With an objective to create hyper-connected
                                intelligent cars that boast the safest and most
                                advanced self-driving systems on the market,
                                Hyundai Motor is collaborating with Cisco, a
                                worldwide leader in IT and security technology.
                                The joint program will create a platform
                                optimised for connected cars, which will help
                                enable two-way communication both internally
                                between the car’s system and externally with
                                road infrastructure, other vehicles, internet of
                                Things (loT) devices, and the cloud.{" "}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                <strong>
                                  ‘Mobility Vision’ concept converges
                                  hyper-connectivity and Smart House
                                </strong>
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                Through its innovative ‘Mobility Vision’
                                concept, Hyundai Motor is demonstrating the
                                potential reach of its advanced technologies.
                                The Smart House concept shown at CES seamlessly
                                integrates the car into the daily lives of
                                users, blurring the line between mobility and
                                customer’s living and working spaces.{" "}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                The concept on show suggests how the car could
                                integrate itself with the living space when
                                docked, before becoming a mobile living space
                                when customers need to move around. Hyundai
                                Motor’s vision sees customers living, without
                                interruption, while on the move as the comfort,
                                convenience and connectivity features of the car
                                and the home are combined into ‘one space’.{" "}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                <strong>
                                  Health + Mobility Concept for wellness in
                                  mobility
                                </strong>
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                Also at CES, Hyundai Motor is presenting ‘Health
                                + Mobility Concept’, its future vision for
                                health-conscious vehicles that connect mobility
                                and wellness. An immersive virtual reality room
                                demonstrates how in-car conditions can be
                                created to better manage stress and associated
                                consequences resulting from commuting, allowing
                                drivers to intentionally shift modes for
                                increased personal productivity or relaxation.
                                The interactive health-conscious technology
                                monitors a suite of health and wellbeing
                                indicators via a series of sensors throughout
                                the cockpit.{" "}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                <strong>
                                  Wearable robotics offer new freedom in
                                  mobility
                                </strong>{" "}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                Hyundai Motor has revealed new wearable powered
                                robots that are set to revolutionise the future
                                of personal mobility. The latest innovations in
                                advanced assistive exoskeletons for medical,
                                work and daily life reveal the company’s
                                ambition to provide assisted-mobility support
                                beyond the automobile for the well-being of
                                customers.{" "}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                The range of wearable exoskeletons presented at
                                CES feature on-board motion control systems to
                                support patients with lower spinal cord
                                injuries, or provide stroll support for the
                                elderly in everyday life. A workplace-focused
                                exoskeleton provides upper-body and hip support
                                to prevent back injuries for workers lifting
                                heavy objects.{" "}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                <strong>
                                  IONIQ Scooter provides final-mile mobility
                                </strong>{" "}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                Hyundai Motor has unveiled the IONIQ Scooter, an
                                innovative portable-mobility concept that allows
                                customers to continue their journey after
                                parking to easily reach their final destination.
                                The lightweight, electrically-powered scooter is
                                conveniently stored and charged in the front
                                door of the IONIQ Electric, with an easy
                                one-touch folding structure for maximised
                                portability.{" "}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                The scooter is the result of a study into
                                alternatives for ‘first-and-last mile mobility’,
                                and is another innovation from Hyundai Motor’s
                                long-term research and development program,
                                Project IONIQ, which works to redefine future
                                mobility through innovation.{" "}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                {" "}
                                Also on display at the Hyundai Motor booth at
                                CES is the latest IONIQ Hybrid model, which
                                includes enhancements to further its reputation
                                as one of the world’s most fuel efficient cars.{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
