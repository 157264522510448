import $ from 'jquery';

export default {
  attachThumbnailClick() {
    $('.theme-news-thumbnail-container a').on('click', function(e) {
      e.preventDefault();
      const me = $(this);

      $(me)
        .closest('[data-search-component]')
        .find('.theme-news-header-image.active')
        .removeClass('active');

      $(me.data('hero-image')).addClass('active');
    });
  },
};
