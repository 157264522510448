import Store from 'store';
import GetData from '../utils/GetData';

export default {
  SortIDString(text) {
    let articleTitle = text;
    articleTitle = articleTitle
      .split('---')
      .join('$$placeholer$$')
      .toLowerCase();
    articleTitle = articleTitle.split('-').join(' ').toLowerCase();
    articleTitle = articleTitle
      .split('$$placeholer$$')
      .join(' - ')
      .toLowerCase();
    return articleTitle;
  },
  SetToActive(navName) {
    const navItems = document.querySelectorAll('.whn-nav-item');
    const navItem = document.getElementById(navName.toLowerCase());
    for (let i = 0; i < navItems.length; i++) {
      const element = navItems[i];
      element.classList.remove('active');
    }
    if (navItem != null) {
      navItem.classList.add('active');
    }
  },
  NewArticle(prevID, props) {
    if (
      props.match &&
      props.match.params &&
      props.match.params.id &&
      prevID != props.match.params.id
    ) {
      return true;
    } else {
      return false;
    }
  },
  IDPropsPresent(props) {
    if (props.match && props.match.params && props.match.params.id) {
      return true;
    } else {
      return false;
    }
  },

  GetArticleByTitle(articles, title) {
    let articlesList = articles;
    let articleTitle = title;

    if (articlesList == undefined) articlesList = Store.get('articles');

    if (articlesList != undefined)
      for (let i = 0; i < articlesList.length; i++) {
        let article = articlesList[i];
        if (article.previewPage.title.toLowerCase() == articleTitle) {
          return article;
        }
      }
    return null;
  },
  ReturnArticle(articles, id) {
    for (let i = 0; i < articles.length; i++) {
      let element = articles[i];
      if (element.id == id) return element;
    }
  },
  GetRelatedArticles(relatedArticlesIds) {
    const articles = Store.get('articles');
    let relatedArticles = [];
    if (relatedArticlesIds.length != 0) {
      for (let i = 0; i < relatedArticlesIds.length; i++) {
        const relatedID = relatedArticlesIds[i];
        for (let j = 0; j < articles.length; j++) {
          const article = articles[j];
          if (article.id == relatedID) {
            const newArticle = {
              startDate: article.startDate,
              title: article.previewPage.title,
              src: article.previewPage.mainImage,
              alt: article.name,
              id: article.id,
              related: article.relatedToIDs,
            };
            relatedArticles.push(newArticle);
          }
        }
      }
    }
    return relatedArticles;
  },
  async GetArticles() {
    const getArticles = async () => {
      await GetData.getArticles();
    };
    return await getArticles();
  },
  IsPresentInStore(store, name) {
    const fromStore = Store.get(name);
    if (
      (store == undefined || store.length == 0) &&
      !this.ObjectPresent(fromStore)
    ) {
      return false;
    } else {
      return true;
    }
  },
  IsPresentInState(state) {
    if (!this.ObjectPresent(state)) {
      return false;
    } else {
      return true;
    }
  },
  IsArticleImagePopulated() {
    const articleImage = document.getElementById('article-div');
    if (articleImage != undefined) {
      if (articleImage.firstChild.src == '') {
        return false;
      } else {
        return true;
      }
    }
  },
  ObjectPresent(obj) {
    if ((obj != null || obj != undefined) && obj.length != 0) {
      return true;
    } else {
      return false;
    }
  },
};
