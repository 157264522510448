import React, { Component } from 'react';
import TopNavbar from '../components/topnavbar';
import BottomNavbar from '../components/bottomnavbar';
import FooterNavbar from '../components/footerNavbar';
import Footer from '../components/footer';
import Store from 'store';
import SearchResult from '../components/search/searchResult';

export default class SearchResults extends Component {
  constructor(props) {
    super(props);

    const searchTerm =
      props.location.query != null && props.location.query.searchTerm != null
        ? props.location.query.searchTerm
        : '';

    this.state = {
      searchTerm: searchTerm,
      enteredSearch: searchTerm,
      results: [],
      isLoading: false,
      showError: false,
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);

    if (this.state.searchTerm.length !== 0) {
      this.search();
    }
  }

  search = () => {
    this.setState({
      showError: false,
      isLoading: true,
    });

    if (
      this.state.searchTerm == null ||
      this.state.searchTerm == undefined ||
      this.state.searchTerm.length === 0
    ) {
      this.setState({
        results: [],
        isLoading: false,
      });
    } else {
      fetch(
        process.env.REACT_APP_API_URL +
          '/search?key=' +
          Store.get('loginToken').replace(/['"']+/g, '') +
          '&searchterm=' +
          this.state.searchTerm,
        {
          method: 'get',
        },
      )
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          }
        })
        .then((response) => {
          this.setState({
            results: response,
            isLoading: false,
          });
        })
        .catch((error) => {
          console.log('error', error);
          this.setState({
            isLoading: false,
            showError: true,
          });
        });
    }
  };

  handleClick = () => {
    this.setState(
      {
        searchTerm: this.state.enteredSearch,
      },
      this.search,
    );
  };

  handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.setState(
        {
          searchTerm: this.state.enteredSearch,
        },
        this.search,
      );
    }
  };

  handleSearchTermChange = (e) => {
    const target = e.target;
    this.setState({
      enteredSearch: target.value,
    });
  };

  render() {
    let results = [];
    if (this.state.results != undefined) {
      results = this.state.results
        .filter(function (item, pos, ary) {
          //remove duplicate news past items
          return !pos || item.displayTitle !== ary[pos - 1].displayTitle;
        })
        .map((result, index) => {
          return <SearchResult key={index} result={result} />;
        });
    }

    return (
      <div>
        <TopNavbar />
        <BottomNavbar hideSearch={true} />

        <div className="container">
          <section
            className="section1 wow fadeIn"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <div className="content p-5 col-md-10 offset-md-1 border-right border-bottom">
              <div className="row">
                <div className="col-md-4 p-3">
                  <h4 className="h4ServHistory">
                    <strong>Search Results</strong>
                  </h4>
                </div>

                <div className="col-md-8  p-4 border-left">
                  <div className="input-group mb-2">
                    <div className="input-group-prepend ">
                      <span
                        className="input-group-text bottomNavPrepend"
                        id="basic-addon1"
                      >
                        <i className="fas fa-search" />
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control bottomNavPrependInput"
                      placeholder="Search"
                      aria-label="Search"
                      aria-describedby="basic-addon1"
                      value={this.state.enteredSearch}
                      onChange={this.handleSearchTermChange}
                      onKeyDown={this.handleKeyDown}
                    />
                    <button
                      type="button"
                      className="btn btn-primary btn-small removeRadius"
                      onClick={this.handleClick}
                    >
                      Go
                    </button>
                  </div>
                  <hr />

                  {this.state.isLoading && (
                    <p className="midnightBlue">
                      <i className="fas fa-spinner fa-spin fa-lg" />
                    </p>
                  )}

                  {this.state.showError && (
                    <p className="midnightBlue">
                      Sorry, we couldn't find any matches for your search
                    </p>
                  )}

                  {this.state.results && this.state.results.length !== 0 && (
                    <div>{results}</div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>

        <FooterNavbar />

        <Footer />
      </div>
    );
  }
}
