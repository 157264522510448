import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';

export default class TucsonHybrid extends Component {
  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="TucsonHybrid" className="col">
        <div className="col bg-white border-right border-bottom">
          <div className="row py-3">
            <div className="col-lg-4">
              <div className="mt-3">
                <p className="theme-news-snippet">
                  “It will be available in combination with more engines in the
                  future, as part of our highly diverse mix of electrified
                  solutions.”
                </p>
              </div>
              <hr />
              <NewsMedia articleId="TucsonHybrid" articleText="Tucson Hybrid" />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    TAKE A WALK ON THE MILD SIDE
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    Europe’s best-selling Hyundai is about to become even more
                    impressive. Not only will the New Tucson feature a major
                    redesign and a wider range of advanced technologies when it
                    launches this summer, but it will also be one of the first
                    cars in the world to offer a diesel mild hybrid powertrain
                    system supported by an electric motor.
                  </p>
                  {displayShowMore && (
                    <p>
                      <a  
                        data-toggle="collapse"
                        href="#collapseTucsonHybrid"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </div>

              <div className={moreSectionClassName} id="collapseTucsonHybrid">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      Unlike a traditional hybrid, a mild hybrid’s electric
                      motor is not powerful enough to drive the car on its own,
                      but instead assists the engine at certain times with extra
                      power. The technologically advanced combination of the
                      2.0-litre diesel engine and 48-volt mild hybrid powertrain
                      system will improve fuel efficiency by as much as seven
                      per cent and reduce CO2 emissions, marking another
                      milestone in the brand’s commitment to a cleaner future.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      “With our new mild hybrid powertrain system, we are
                      further expanding the company’s electrification strategy
                      to make clean technologies accessible for even more
                      customers,” said Andreas-Christoph Hofmann, Vice President
                      Marketing and Product at Hyundai Motor Europe. “It will be
                      available in combination with more engines in the future,
                      as part of our highly diverse mix of electrified
                      solutions.”
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      So how does the technology work? Well, (very!) basically,
                      a Mild Hybrid Starter Generator (MHSG) decides when to
                      support the diesel engine with power from the battery –
                      either by reducing engine load with light acceleration or
                      to provide additional torque to the engine under strong
                      acceleration. The battery is recharged when the car is
                      decelerating in gear (going downhill, for example) or
                      braking. And this all happens seamlessly, so you won’t
                      even notice!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
