import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';
import Store from 'store';
import ReactGA from 'react-ga';
export default class ExecutiveChair extends Component {
  componentDidMount = () => {};

  clickHandler = () => {};

  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="execChair" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="oneMillion-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/execchairTile.jpeg") ',
              }}
            />
            {/* <div
              id="Bayon-gallery-2"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/hyundaielectric2.jpg")',
              }}
            /> */}
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                {/* <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#Bayon-gallery-2"
                    style={{
                      backgroundImage: 'url("images/hyundaielectric2.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#Bayon-gallery-1"
                    style={{
                      backgroundImage: 'url("images/hyundaielectric1.jpg")',
                    }}
                  />
                </div> */}
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “Hyundai Motor Group Executive Chair Euisun Chung Named
                  MotorTrend Person of the Year, Topping Its 2023 Power List”
                </p>
              </div>
              <hr />

              <NewsMedia articleId="awards" articleText="awards" />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    Hyundai Motor Group Executive Chair Euisun Chung Named
                    MotorTrend Person of the Year, Topping Its 2023 Power List
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    {' '}
                    <ul>
                      <li>
                        Executive Chair Chung tops MotorTrend’s prestigious 2023
                        Power List
                      </li>
                      <li>
                        MotorTrend praises Chung’s ability to “lead Hyundai
                        Motor Group into a new age”
                      </li>
                      <li>
                        Latest honor follows proliferation of MotorTrend awards
                        for HMG models, including 2023 SUV of the Year for
                        Hyundai IONIQ 5, 2023 Car of the Year for Genesis G90
                      </li>
                      <li>
                        Group’s pioneering of smart cities, robots and Advanced
                        Air Mobility praised
                      </li>
                      <li>
                        Four more Group executives feature prominently in
                        MotorTrend’s 2023 Power List
                      </li>
                      {/* <li>
                        Hyundai is investing £1 million in a programme of school
                        trips in order to prevent children missing out on such
                        experiences
                      </li>
                      <li>
                        Bookings are now open for the initiative, which has
                        launched with Hyundai’s Educator in Residence, Tim
                        Campbell MBE
                      </li> */}
                    </ul>
                  </p>
                  {displayShowMore && (
                    <p>
                      <a
                        data-toggle="collapse"
                        href="#executiveChair"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </div>

              <div className={moreSectionClassName} id="executiveChair">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      <strong>
                        Hyundai Motor Group (the Group)’s Executive Chair,
                        Euisun Chung, has today been honored with the MotorTrend
                        Person of the Year award, having secured the number one
                        position in MotorTrend’s esteemed 2023 Power List.
                      </strong>
                      <br />
                      <br />
                      The MotorTrend Power List ranks the 50 most influential
                      people in the global automotive industry each year. During
                      the past five years, Executive Chair Chung has risen
                      progressively from his first appearance on the power list
                      at No. 12 in 2018 to No. 5 in 2022 and now No. 1 in 2023.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      MotorTrend named Chung ‘Person of the Year’ for “leading
                      Hyundai Motor Group into a new age.” It also highlighted
                      the exceptional caliber of the vehicles developed under
                      Chung’s watch, stating:
                      <br />
                      <br />
                      <stromg>
                        The vehicles coming out of Hyundai Motor Group are
                        notable for their eye-catching design, quality, clever
                        packaging, and good value – and they are proving
                        increasingly difficult to beat.
                      </stromg>{' '}
                      - MotorTrend
                      {/* <img src="images/expo20302.jpeg" className="img-fluid" /> */}
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      This latest critical acclaim follows a proliferation of
                      MotorTrend awards for the Group’s models, which include
                      the Genesis G90 being hailed as the automotive media
                      brand’s 2023 Car of the Year and the Hyundai IONIQ 5 named
                      its 2023 SUV of the Year. Previously, the Genesis GV70 and
                      Kia Telluride took similar honors. “Hyundai, Genesis, and
                      Kia have been hitting balls out of the park repeatedly,”
                      asserted MotorTrend.
                      <br />
                      <br />
                      In addition to his skill in plotting the course for the
                      Group’s current success, MotorTrend extolled Chung’s
                      vision of the future. It recognised his “big-picture”
                      thinking and his ability to initiate solutions
                      traditionally regarded beyond the bounds of the automotive
                      industry.
                      {/* <strong>
                        {' '}
                        "Having updated our brand’s image and pronunciation
                        locally, we wanted to explore pronunciation as a topic
                        more broadly and get people talking about those words
                        that we can potentially struggle with in everyday life,
                        such as quinoa and espresso. We have teamed up with
                        Seong-Jae, a.k.a Korean Billy, who is well known for his
                        light-hearted YouTube videos into different dialects
                        within the UK, to explore some British words from Korean
                        standpoint."
                      </strong>{' '}
                      - Ashley Andrew Managing Director, Hyundai Motor UK */}
                      <br />
                      <br />
                      <strong>
                        "As Executive Chair, Chung is putting his stamp on the
                        company’s future. His grasp of where the world and the
                        industry are headed, combined with a desire to make the
                        planet a better place, spurs a broader mission to become
                        a leader in electric vehicles and advanced mobility."
                      </strong>{' '}
                      - MotorTrend
                      <br />
                      <br />
                      It also highlighted the Group’s pioneering role in smart
                      city planning and a myriad of mobility solutions,
                      including robots and Advanced Air Mobility (AAM).
                      <br />
                      <br /> Commenting on the depth and reach of Chung’s
                      vision, MotorTrend added: “In that sense, he does his
                      father and grandfather proud, pushing the entire Hyundai
                      Motor Group to new levels. The man and his vision, and the
                      juggernaut that his company is becoming, loom loud and
                      large. For his vision and leadership, then, Euisun Chung
                      is our 2023 MotorTrend Person of the Year.”
                      <img src="images/execchair2.jpeg" className="img-fluid" />
                      {/* <a
                        href="https://motorstudio.hyundai.com/busan/ln/main.do?strgC=04&lang=en"
                        target="_blank"
                      >
                        Hyundai Motorstudio Busan
                      </a>{' '}
                      , the ‘Reflections in Motion’ exhibition invites visitors
                      to explore and reflect on the interactive relationships
                      between time and design as well as technology and styling,
                      with various featured installations underscoring Hyundai
                      Motor’s vision centered on enabling ‘Progress for
                      Humanity’. */}
                      <br />
                      <br />
                      Also ranked on MotorTrend’s Power List are Luc
                      Donckerwolke, President and Chief Creative Officer of
                      Hyundai Motor Group (3); Jose Muñoz, President and Global
                      Chief Operating Officer of Hyundai Motor Company (10);
                      SangYup Lee, Executive Vice President and Head of Hyundai
                      Global Design Center (28); and Karim Habib (44), Executive
                      Vice President and Head of Kia Global Design Center.
                      <br />
                      <br />
                      Previous recognition for Chung’s pioneering thinking
                      includes the Executive Chair being named Newsweek’s
                      ‘Visionary of the Year’ at its inaugural World’s Greatest
                      Auto Disrupters event in 2022 and being honored with the
                      coveted Issigonis Trophy at the 2021 Autocar Awards.
                      {/* <strong>
                        "School trips are an integral part of a child’s
                        educations, and I remember my own outings with such fond
                        memories, even all these years later! What’s more, being
                        able to turn these trips into further learning within
                        the classroom is such an important skill and something
                        that should be available for everyone. This programme is
                        extremely timely and will be crucial in putting school
                        trips firmly back on the agenda"
                      </strong>{' '}
                      - Tim Campbell Hyundai's Educator in Residence;
                      <br /> */}
                      <br />
                      <br />
                      Speaking earlier in January, at a New Year town hall
                      meeting at the Group’s Namyang R&D Center, Chung
                      underlined his continued commitment to the success of the
                      Group. He urged everyone within the organisation to spare
                      no effort to ensure the delivery of the best possible
                      products and services for its customers.
                      <br />
                      <br />
                      <strong>
                        "We must take on challenges based on trust, and the
                        positive results must lead to even stronger trust. No
                        great product or technology is complete without the
                        trust of our customers. Quality is a priority for all of
                        us. From product planning to design, production, sales,
                        and after-sales maintenance, all teams and employees
                        must place quality and the customer as the top priority.
                        We must all go above and beyond to earn the respect and
                        loyalty of our customers."
                      </strong>{' '}
                      - Euisun Chung, The Groups Executive Chair
                      <br />
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
