import React, { Component, useState } from 'react';
import './personal.css';
import FooterNavbar from '../components/footerNavbar';
import TopNavbar from '../components/topnavbar';
import BottomNavbar from '../components/bottomnavbar';
import Footer from '../components/footer';
import MyCarNavbar from '../components/myCarNavbar';
import Store from 'store';
import Handbook from '../components/handbook';
import FuelCalculatorDisplayContent from './fuelCalculatorDisplayContent';
import HandbookJS from '../utils/Handbook';
import { Helmet } from 'react-helmet';
import MainImage from '../images/fuelcalc1.jpg';
import { componentWillAppendToBody } from 'react-append-to-body';

export default class FuelCalculator extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();

    this.state = {
      vehicles: Store.get('vehicles'),
      handbooks: [],
      isLoading: false,
      showContent: false,
    };
    this.showContentHandler = this.showContentHandler.bind(this);
  }

  ContainsHandbook(arr, obj) {
    for (let i = 0; i < arr.length; i++) {
      const h = arr[i];
      if (h.name == obj.name) return true;
    }
    return false;
  }

  componentWillMount() {
    const handbooks = [...HandbookJS.GetHandbooks(this.state.vehicles)];
    const uniqueHandbooks = [];

    for (let i = 0; i < handbooks.length; i++) {
      const h = handbooks[i];
      if (!this.ContainsHandbook(uniqueHandbooks, h)) uniqueHandbooks.push(h);
    }

    this.setState({
      handbooks: uniqueHandbooks,
    });
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    const vehicles = Store.get('vehicles');

    this.setState({
      vehicles: vehicles,
    });
  }

  showContentHandler() {
    this.setState({ showContent: !this.state.showContent });
  }

  render() {
    const handbooks = this.state.handbooks;
    const classes = HandbookJS.GetClassStructure(handbooks);
    const nameClass = HandbookJS.GetNameStructure(handbooks);
    const fontSize = HandbookJS.GetFontSize(handbooks);

    return (
      <div>
        <TopNavbar />
        <BottomNavbar />
        <MyCarNavbar />

        {this.state.showContent ? (
          <FuelCalculatorDisplayContent showContent={this.state.showContent} />
        ) : (
          <React.Fragment>
            <div className="container position-relative" style={{ zIndex: 50 }}>
              <section
                className="section1 wow fadeIn"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <div className="content p-3 p-xl-5 col-md-10 offset-md-1 border-right border-bottom">
                  <div className="row">
                    <div className="col-lg-6">
                      <img src={MainImage} className="img img-fluid" />
                    </div>

                    <div className="col ml-lg-4 p-sm-4 border-lg-left">
                      <h4 className="page-inner-title">
                        Fuel Saving Calculator
                      </h4>
                      There are several significant savings that can be made
                      from owning an Electric or Hybrid car. Find out how much
                      you can save on fuel and road tax costs when choosing a
                      hybrid or electrified powertrain over a conventional
                      diesel or petrol engine.
                      <br />
                      <br />
                      {/* <a
                    href="https://preprod-hyundaifinance.visarcvm10.visarchosting.co.uk/ev-calculator/"
                    target="_blank"
                  > */}
                      <button
                        type="button"
                        className="btn btn-primary btn-block btn-sm removeRadius"
                        onClick={() =>
                          this.setState({
                            showContent: !this.state.showContent,
                          })
                        }
                      >
                        Find Out More
                      </button>
                      {/* </a> */}
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <FooterNavbar />

            <Footer />
          </React.Fragment>
        )}
      </div>
    );
  }
}
