import React, { Component } from 'react';

export default class HyundaiBanner extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="col-12 " id="hyundai-banner">
        <h4>
          Login to your <b>MyHyundai</b> account
        </h4>
      </div>
    );
  }
}
