import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SaveData from '../utils/SaveData';
import GetData from '../utils/GetData';
import MyCarNavbar from '../components/myCarNavbar';
import './myCarDetails.css';
import FooterNavbar from '../components/footerNavbar';
import Store from 'store';
import TopNavbar from '../components/topnavbar';
import BottomNavbar from '../components/bottomnavbar';
import Footer from '../components/footer';

import CarSelector from '../components/carSelector';
import CarDetails from '../components/myCarDetails/carDetails';
import $ from 'jquery';

export default class MyCarDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      vehicles: [],
      selectedIndex: 0,
      modalSaveVehicleThankYou: false,
      isLoading: false,
      removedVehicleSuccess: false,
      removedVehicleFail: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    const vehicles = Store.get('vehicles');

    const keyID = Store.get('loginToken').replace(/['"']+/g, '');
    //console.log(keyID);

    this.setState({
      vehicles: vehicles,
    });

    $('#carOwnedModal').on('hidden.bs.modal', () => {
      this.setState({
        removedVehicleSuccess: false,
        removedVehicleFail: false,
      });
    });
  }

  handleSelectionChanged = (index) => {
    const vehicles = Store.get('vehicles');

    this.setState((prevState) => {
      return {
        selectedIndex: index,
        vehicles: vehicles,
      };
    });
  };

  handleNoLongerOwnClick = (vehicle, index) => {
    this.setState({ modalSaveVehicleThankYou: false });

    const vehicles = Store.get('vehicles');
    this.setState(() => {
      return {
        selectedIndex: index,
        vehicles: vehicles,
      };
    });
    //do a modal!
    $('#carOwnedModal').modal('show');
  };

  handleGoToHomePage = () => {
    window.location.href = `${process.env.REACT_APP_HOME}`;
  }

  handleNoLongerOwnConfirmClick = () => {
    this.setState({ isLoading: true });

    const vin = this.state.vehicles[this.state.selectedIndex].vin;

    SaveData.removeVehicle(
      async () => {
        await GetData.getVehicles();

        this.setState({
          removedVehicleSuccess: true,
          isLoading: false,
          vehicles: Store.get('vehicles'),
        });

        
      },
      () => {
        this.setState({
          removedVehicleFail: true,
          isLoading: false,
        });
      },
      vin,
    );
  };

  render() {
    const vehicle = this.state.vehicles[this.state.selectedIndex];

    const { removedVehicleSuccess, removedVehicleFail } = this.state;

    return (
      <div>
        <TopNavbar />
        <BottomNavbar />
        <MyCarNavbar />

        <div className="container position-relative" style={{ zIndex: 50 }}>
          <section
            className="section1 wow fadeIn"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <div className="content p-3 p-xl-5 col-md-10 offset-md-1 border-right border-bottom">
              <div className="row">
                <div className="col-lg-4 p-3 p-sm-4">
                  <h4 className="h4ServHistory" data-search-tag="title">
                    Vehicle details
                  </h4>
                  <p className="personalBlue">Please select car below.</p>
                  <CarSelector
                    vehicles={this.state.vehicles}
                    onSelectionChanged={this.handleSelectionChanged}
                    // Excluded from Phase 2
                    // Remove Vehicle Button
                    onNoLongerOwnedClick={this.handleNoLongerOwnClick}
                    showButton={true}
                  />
                </div>

                {vehicle != null && (
                  <CarDetails
                    vehicle={vehicle}
                    vehicleIndex={this.state.selectedIndex}
                  />
                )}
              </div>
            </div>
          </section>
        </div>

        <FooterNavbar />

        <Footer />

        {/* No longer own this vehicle - Modal   */}
        <div
          className="modal fade"
          id="carOwnedModal"
          tabIndex="-1"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            {!removedVehicleSuccess && !removedVehicleFail && (
              <div className="modal-content">
                <div className="modal-header">
                  <h6 className="modal-title ">
                    Are you sure you want to update these changes?
                  </h6>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body text-center">
                  {vehicle != null && (
                    <div className="small">
                      <p>
                        We'd just like you to confirm that you no longer own the
                        vehicle shown below:
                      </p>
                      <p>Hyundai {vehicle.model}</p>
                      <p className="text-uppercase">REG: {vehicle.registration}</p>

                      <p className="text-uppercase">VIN: {vehicle.vin}</p>

                      <p>
                        By confirming this you will no longer receive any safety
                        notifications or information for this vehicle from
                        Hyundai Motor UK Ltd.
                      </p>
                      <p>
                        <button
                          type="button"
                          className="btn  btn-primary  removeRadius"
                          onClick={this.handleNoLongerOwnConfirmClick}
                        >
                          <div
                            className={
                              (this.state.isLoading
                                ? 'visible '
                                : 'invisible ') +
                              'position-absolute left-0 right-0 '
                            }
                          >
                            <i className=" fas fa-spinner fa-spin fa-lg text-white" />
                          </div>

                          <span
                            className={
                              this.state.isLoading ? 'invisible ' : 'visible '
                            }
                          >
                            I no longer own this vehicle
                          </span>
                        </button>
                      </p>
                      <p>
                        <button
                          type="button"
                          className="btn  btn-outline-dark  removeRadius"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            )}

            {/* ==================================== Vehicle removed Fail ==================================== */}
            {removedVehicleFail && (
              <div className="modal-content">
                <div className="modal-header">
                  <h6 className="modal-title ">Error</h6>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body text-center">
                  <div className="small">
                    <p>Oops, please try again.</p>

                    <p>
                      If you need any help, please contact customer services on
                      0800981981
                    </p>
                    <p />
                    <p>
                      <button
                        type="button"
                        className="btn  btn-outline-dark  removeRadius"
                        data-dismiss="modal"
                      >
                        Close window
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            )}

            {/* ==================================== Vehicle removed Successful ==================================== */}
            {removedVehicleSuccess && (
              <div className="modal-content"
              onBlur={this.handleGoToHomePage}>
                <div className="modal-header">
                  <h6 className="modal-title ">Thank you</h6>
                  <Link
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    to="/"
                    onClick={this.handleGoToHomePage}                    
                  >
                    <span aria-hidden="true">&times;</span>
                  </Link>
                </div>
                <div className="modal-body text-center">
                  <div className="small">
                    <p>
                      Thank you for confirming you no longer own this vehicle,
                      we will update our records.
                    </p>

                    <p>
                      If you need any help, please contact customer services on
                      0800981981
                    </p>
                    <p />
                    <p>
                      <Link
                        type="button"
                        className="btn btn-outline-dark removeRadius"
                        data-dismiss="modal"
                        to="/"
                        onClick={this.handleGoToHomePage}
                      >
                        Close window
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
