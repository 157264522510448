import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';
class MercuryPrize extends Component {
  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="mercury" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="mercury-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/mercury-charli-xcx.jpg")',
              }}
            />
            <div
              id="mercury-gallery-2"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/mercury-ddh.jpg")',
              }}
            />
            <div
              id="mercury-gallery-3"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/mercury-dua.jpg")',
              }}
            />
            <div
              id="mercury-gallery-4"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/mercury-fibs.jpg")',
              }}
            />
            <div
              id="mercury-gallery-5"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/mercury-georgia.jpg")',
              }}
            />
            <div
              id="mercury-gallery-6"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/mercury-has.jpg")',
              }}
            />
            <div
              id="mercury-gallery-7"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/mercury-lanterns.jpg")',
              }}
            />
            <div
              id="mercury-gallery-8"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/mercury-laura.jpg")',
              }}
            />
            <div
              id="mercury-gallery-9"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/mercury-mk.jpg")',
              }}
            />
            <div
              id="mercury-gallery-10"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/mercury-moses.jpg")',
              }}
            />
            <div
              id="mercury-gallery-11"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/mercury-porridge.jpg")',
              }}
            />
            <div
              id="mercury-gallery-12"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/mercury-stormzy.jpg")',
              }}
            />
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#mercury-gallery-1"
                    style={{
                      backgroundImage: 'url("images/mercury-charli-xcx.jpg")',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#mercury-gallery-2"
                    style={{
                      backgroundImage: 'url("images/mercury-ddh.jpg")',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#mercury-gallery-3"
                    style={{
                      backgroundImage: 'url("images/mercury-dua.jpg")',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#mercury-gallery-4"
                    style={{
                      backgroundImage: 'url("images/mercury-fibs.jpg")',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#mercury-gallery-5"
                    style={{
                      backgroundImage: 'url("images/mercury-georgia.jpg")',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#mercury-gallery-6"
                    style={{
                      backgroundImage: 'url("images/mercury-has.jpg")',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#mercury-gallery-7"
                    style={{
                      backgroundImage: 'url("images/mercury-lanterns.jpg")',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#mercury-gallery-8"
                    style={{
                      backgroundImage: 'url("images/mercury-laura.jpg")',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#mercury-gallery-9"
                    style={{
                      backgroundImage: 'url("images/mercury-mk.jpg")',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#mercury-gallery-10"
                    style={{
                      backgroundImage: 'url("images/mercury-moses.jpg")',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#mercury-gallery-11"
                    style={{
                      backgroundImage: 'url("images/mercury-porridge.jpg")',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#mercury-gallery-12"
                    style={{
                      backgroundImage: 'url("images/mercury-stormzy.jpg")',
                    }}
                  />
                </div>
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “2020 Hyundai Mercury prize”
                </p>
              </div>
              <hr />

              <NewsMedia
                articleId="mercury"
                articleText="Michael Kiwanuka wins the 2020 Hyundai Mercury Prize for Album of the Year"
              />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    Michael Kiwanuka wins the 2020 Hyundai Mercury Prize for
                    Album of the Year
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    <ul>
                      <li>
                        Michael Kiwanuka announced tonight as the overall winner
                        of the 2020 Hyundai Mercury Prize for Album of the Year
                        live on BBC One’s The One Show, for his album ‘KIWANUKA’
                      </li>
                      <li>
                        Hyundai Motor, the Mercury Prize’s title partner since
                        2016, has been supporting nominees, judges and
                        management with a fleet of zero-emission Kona Electrics
                      </li>
                      <li>
                        While it hasn’t been possible for a live show this year
                        due to Government restrictions, there are opportunities
                        to watch performances from the 12 shortlisted artists
                        and the overall winner on BBC broadcasts
                      </li>
                      <li>
                        To celebrate this year’s winner, Hyundai has putMichael
                        Kiwanuka’sname in lights on its screen at Piccadilly
                        Circus in London
                      </li>
                    </ul>
                  </p>
                  {displayShowMore && (
                    <p>
                      <a
                        data-toggle="collapse"
                        href="#collapsefuture"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                  {/* <p className="theme-news-body-text">The i30 Fastback’s sloping roofline, long bonnet and muscular body, along with its slim cabin and wide wheel arches give the car a strong stance. That strength, however, is far from being merely skin deep.</p> */}
                </div>
              </div>

              <div className={moreSectionClassName} id="collapsefuture">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      <b>Leatherhead, 24 September 2020 – </b>Michael Kiwanuka
                      was announced tonight, Thursday, 24 September 2020, as the
                      overall winner of the 2020 Hyundai Mercury Prize for Album
                      of the Year for his album ‘KIWANUKA’. Radio 1’s Annie Mac
                      revealed the winner live on The One Show on BBC One.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      The judging panel said: “KIWANUKA by Michael Kiwanuka is
                      the well deserved winner of the 2020 Hyundai Mercury Prize
                      2020 for Album of the Year. Classic yet contemporary,
                      drawing on the history of music while remaining an
                      intensely personal work of self expression, this is an
                      album that will stand the test of time. Songs such as{' '}
                      <i>Hero</i>
                      and You Ain’t The Problem deal with hot button topics like
                      race and identity, but in a reflective way that draws the
                      listener in. From its narrative flow to the interludes,
                      from Civil Rights speeches to its panoramic mix of
                      everything from psychedelic rock to piano jazz,{' '}
                      <i>KIWANUKA</i>
                      is not only a complete work, but also one that is borne of
                      the courage of its creator to build his own world and
                      invite us in. Warm, rich, hugely accomplished and
                      belonging to no one genre but its own, <i>KIWANUKA</i> is
                      a masterpiece.”
                    </p>
                  </div>
                </div>

                {/* <div className="row">
                      <div className="col-md-10 offset-md-1">
                        <p className="theme-news-body-text">
                          {" "}
                          The SUV will feature the 2.2 CRDi “R” engine,
                          which has been upgraded to further reduce
                          emissions, and will be available with front
                          wheel drive or 4WD, with a 6-speed manual
                          gearbox or an all-new 8-speed automatic
                                      transmission.{" "}
                        </p>
                      </div>
                    </div> */}

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      Michael Kiwanukasaid: “I don’t even know what to say - I’m
                      speechless. This is amazing…I don’t even have any words.
                      This is ridiculous, it’s crazy! I’m so happy. Third time’s
                      a charm. It’s blown my mind. I’m over the moon, I'm so
                      excited - this is for art, for music, for albums. This is
                      the only thing I've ever wanted to do so to win a Mercury
                      is a dream come true. I’m so happy. Music and art means so
                      much to me and this is an award that celebrates that so
                      I’m over the moon.”
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      Ashley Andrew, Hyundai Motor UK’s Managing Director,
                      commented: ‘Huge congratulations toMichael Kiwanukaon
                      winning the 2020 Hyundai Mercury Prize for Album of the
                      Year. His album ‘KIWANUKA’ is beautifully bold and
                      emotive, thoroughly deserving this coveted prize. Even
                      though a live show hasn’t been possible this year, we will
                      be applauding the overall winner by puttingMichael’s name
                      in lights on our iconic Piccadilly Circus screen in
                      London.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      “In the same spirt as the Hyundai Mercury Prize, we
                      challenge convention and strive for progress in everything
                      we do at Hyundai - which is why I’m delighted to be title
                      partner for the fifth year running now. We’re extremely
                      proud to support British musicians and have enabled all
                      nominated artists, as well as the wider Hyundai Mercury
                      Prize team, to travel around in style and, importantly, in
                      an environmentally friendly way this year by providing a
                      fleet of our innovative zero-emission Kona Electric
                      vehicles.”
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      As all events are subject to current Government guidelines
                      & restrictions there has not be a live awards show this
                      year. However there is extensive television, radio and
                      online coverage of the Prize across the BBC, which will
                      showcase and celebrate the achievements of the 12 artists
                      shortlisted this year.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      As all events are subject to current Government guidelines
                      & restrictions there has not be a live awards show this
                      year. However there is extensive television, radio and
                      online coverage of the Prize across the BBC, which will
                      showcase and celebrate the achievements of the 12 artists
                      shortlisted this year.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      <b>BBC programmes:</b>
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      <b>Hyundai Mercury Prize 2020 ‘Album of the Year’</b>{' '}
                      special programme broadcast on BBC Four last night: The
                      programme, voiced by BBC Radio 6 Music presenter Lauren
                      Laverne, included specially filmed and socially distanced
                      performances by Anna Meredith, Georgia, Lanterns on the
                      Lake, Laura Marling, Moses Boyd, Porridge Radio and Sports
                      Team (all filmed in independent venues in Brighton,
                      Gateshead and London), a unique performance from Charli
                      XCX from LA as well as performance footage from Dua Lipa,
                      Kano, Michael Kiwanuka and Stormzy.The programme can be
                      viewed on <b>BBC iPlayer</b>{' '}
                      <a
                        target="_blank"
                        href="https://www.bbc.co.uk/iplayer/episode/m000mttq/mercury-prize-2020-hyundai-mercury-prize-2020-album-of-the-year"
                      >
                        here
                      </a>{' '}
                      along with some of the tracks performed by this year’s
                      shortlisted artists.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      <b>
                        Hyundai Merucry Prize 2020 ‘Later… with Jools Holland’
                      </b>
                      , on BBC Two, Friday, 25 September, 10pm to 10.45pm:
                      Later... with Jools Holland: Mercury Prize 2020 launches
                      the new seven-part autumn series of Later…with Jools
                      Holland and will feature the winner of the Mercury Prize
                      2020 in conversation with Jools.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      <b>Hyundai Mercury Prize special with Tom Ravenscroft</b>{' '}
                      on BBC Radio 6 Music, Thursday, 24 September, 7pm to 9pm:
                      Tonight, following the announcement of the overall winner
                      on The One Show, Tom will present a special show on 6
                      Music in celebration of the winning album, which will also
                      include an interview with the artist who created it.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      The Hyundai Mercury Prize 2020: Album of the Year
                      programme and Later… with Jools Holland: Mercury Prize
                      2020 will be available to watch on BBC iPlayer, along with
                      some of the tracks performed by this year’s shortlisted
                      artists. The BBC Sounds app can be downloaded to catch all
                      the 6 Music coverage live and on demand. The Prize’s
                      digital music partner, YouTubeMusic, will also host the
                      official Prize playlist featuring the 12 shortlisted
                      artists andalbums and support the Prize's first ever
                      international live stream.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      The Hyundai Mercury Prize ‘Albums of the Year’ celebrate
                      and promote the best of UK music, recognising artistic
                      achievement across an eclectic range of contemporary music
                      genres.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      Hyundai, the Mercury Prize’s title partner since 2016,
                      will be celebrating the shortlisted artists and continuing
                      to support innovations in British music. As part of its
                      support for this year’s Hyundai Mercury Prize, the car
                      brand has provided a fleet of zero-emission Kona Electric
                      vehicles to help the nominees, judges and wider Hyundai
                      Mercury Prize team get around in an
                      environmentally-friendly way.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      Hyundai has the most diverse range of alternatively fueled
                      vehicles in the market, including the KONA Electric, the
                      UK’s first fully electric compact SUV which has a range of
                      up to 278 miles on a single charge (64kWh versions) and a
                      high-end sound system from Krell.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      <b>The 2020 shortlisted artists were:</b>
                      <ul>
                        <li>
                          <b>Anna Meredith</b> ‘FIBS‘
                        </li>
                        <li>
                          <b>Charli XCX</b> ‘how i’m feeling now‘
                        </li>
                        <li>
                          <b>Dua Lipa</b> ‘Future Nostalgia‘
                        </li>
                        <li>
                          <b>Georgia</b> ‘Seeking Thrills‘
                        </li>
                        <li>
                          <b>Kano</b> ‘Hoodies All Summer‘
                        </li>
                        <li>
                          <b>Lanterns on the Lake</b> ‘Spook the Herd‘
                        </li>
                        <li>
                          <b>Laura Marling</b> ‘Song for Our Daughter‘
                        </li>
                        <li>
                          <b>Michael Kiwanuka</b> ‘KIWANUKA‘
                        </li>
                        <li>
                          <b>Moses Boyd</b> ‘Dark Matter‘
                        </li>
                        <li>
                          <b>Porridge Radio</b> ‘Every Bad‘
                        </li>
                        <li>
                          <b>Sports Team</b> ‘Deep Down Happy‘
                        </li>
                        <li>
                          <b>Stormzy </b> ‘Heavy is the Head‘
                        </li>
                      </ul>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MercuryPrize;
