import React, { Component, Fragment } from 'react';
import SelectField from '../selectField';
import ValidationMessage from '../validationMessage';
import InputField from '../inputField';
export class NameAddress extends Component {
  KEY = 'EE91-ZE94-XD76-TR95';

  state = {
    showLoqateDropDown: '',
    loqate: '',
    loqateDisabled: true,
    loqateIsLoading: false,
  };
  componentDidMount = () => {
    const addressSearchInput = document.querySelector('#inpLoqate');
    if (addressSearchInput) {
      addressSearchInput.addEventListener('keydown', this.loqateArrowKeys);
    }
  };

  componentWillUnmount = () => {
    document
      .querySelector('#inpLoqate')
      .removeEventListener('keydown', this.loqateArrowKeys);
  };

  loqateArrowKeys = (e) => {
    const li = document.querySelectorAll('#loqateUl > li');

    if (this.state.loqate && li.length > 0) {
      if (e.keyCode === 40) {
        // Arrow Down
        e.preventDefault();
        if (this.state.loqateArrowDown ==='') {
          this.setState({ loqateArrowDown: 0 });
        } else if (this.state.loqateArrowDown + 1 < li.length) {
          this.setState({ loqateArrowDown: this.state.loqateArrowDown + 1 });
        }

        li.forEach((el) => {
          el.classList.remove('active');
        });

        if (this.state.loqateArrowDown < li.length) {
          li[this.state.loqateArrowDown].classList.add('active');
        }
        return false;
      }

      //Arrow up
      if (e.keyCode === 38) {
        e.preventDefault();

        if (this.state.loqateArrowDown ==='') {
          this.setState({ loqateArrowDown: 0 });
        } else if (this.state.loqateArrowDown > 0) {
          this.setState({ loqateArrowDown: this.state.loqateArrowDown - 1 });
        } else if (this.state.loqateArrowDown === 0) {
          this.setState({ loqateArrowDown: '' });
        }
        li.forEach((el) => {
          el.classList.remove('active');
        });

        if (this.state.loqateArrowDown !=='') {
          li[this.state.loqateArrowDown].classList.add('active');
        }
        return false;
      }
      // Enter key
      if (e.keyCode === 13) {
        if (this.state.loqateArrowDown !=='') {
          this.handleLoqateSelectChange(
            this.state.loqateSelect[this.state.loqateArrowDown],
          );
          this.setState({
            loqateArrowDown: '',
          });
        }
      }
    }
  };

  handleLoqateChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    this.setState(
      {
        [name]: value,
        loqateArrowDown: '',
      },

      async () => {
        if (value) {
          await fetch(
            `https://api.addressy.com/Capture/Interactive/Find/v1.1/json3.ws?Key=${this.KEY}&Text=${this.state.loqate}&IsMiddleware=false&Origin=&Countries=GBR&Limit=10&Language=en-gb`,
          )
            .then((response) => {
              if (response.ok) {
                return response.json();
              }
            })
            .then((result) => {
              if (result.Items[0].Type !== 'Address') {
                (async () => {
                  await fetch(
                    `https://api.addressy.com/Capture/Interactive/Find/v1.1/json3.ws?Key=${this.KEY}&Text=${this.state.loqate}&IsMiddleware=false&Origin=&Countries=GBR&Limit=10&Language=en-gb&container=${result.Items[0].Id} `,
                  )
                    .then((response) => {
                      if (response.ok) {
                        return response.json();
                      }
                    })
                    .then((result) => {
                      if (!result) {
                        throw new Error('no result');
                      }

                      if (this.state.loqate) {
                        this.setState({
                          showLoqateDropDown: true,
                          loqateSelect: result.Items,
                        });
                      }
                    });
                })();
              } else {
                if (!result) {
                  throw new Error('no result');
                }
                if (this.state.loqate) {
                  this.setState({
                    showLoqateDropDown: true,
                    loqateSelect: result.Items,
                  });
                }
              }
            })
            .catch((e) => console.log(e));
        } else {
          this.setState({
            showLoqateDropDown: false,
            loqateSelect: {},
            loqateArrowDown: '',
          });
        }
      },
    );
  };

  handleLoqateSelectChange = (value) => {
    this.setState(
      {
        loqate: '',
        loqateDisabled: false,
        loqateIsLoading: true,
      },

      () => {
        //Reset validation
        this.props.handleValidationChange &&
          this.props.handleValidationChange(['addressLine1', 'postCode']);

        return fetch(
          `https://api.addressy.com/Capture/Interactive/Retrieve/v1/json3.ws?Key=${
            this.KEY
          }&id=${encodeURIComponent(value.Id)}  `,

          {
            headers: {
              'Access-Control-Allow-Origin': '*',
              Accept: 'application/json, text/plain, */*',
              'Content-Type':
                'application/x-www-form-urlencoded; charset=UTF-8',
            },
          },
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.Items[0].Error) {
              this.setState({
                showLoqateDropDown: false,
                loqateIsLoading: false,
              });
              throw new Error(data[0].Error);
            }

            const address = data.Items[0];

            if (!address) {
              return;
            }

            this.props.handleStateChange(
              {
                houseName: '',
                addressLine1: '',
                addressLine2: '',
                addressLine4: '',
              },
              () => {
                //House Name

                if (
                  address.BuildingName !=='' &&
                  !address.Line1.includes(address.BuildingName) &&
                  !address.Line2.includes(address.BuildingName)
                ) {
                  this.props.handleStateChange({
                    houseName: address.BuildingName,
                  });
                }
                //Address Line 1
                this.props.handleStateChange({
                  addressLine1: address.Line1,
                  postCode: address.PostalCode,
                });

                //Address Line 2
                if (
                  address.Line2 !=='' &&
                  address.Line2 !== address.District &&
                  address.Line2 !== address.City &&
                  address.Line2 !== address.Province
                ) {
                  this.props.handleStateChange({
                    addressLine2: address.Line2,
                  });
                }

                //Town
                //County

                if (address.District !== '') {
                  this.props.handleStateChange({
                    //town
                    addressLine4: address.District,
                    //county
                    addressLine5: address.City,
                  });
                } else {
                  this.props.handleStateChange({
                    //town
                    addressLine4: address.City,
                    //county
                    addressLine5: address.Province,
                  });
                }

                this.setState({
                  showLoqateDropDown: false,
                  loqateIsLoading: false,
                });
              },
            );
          })
          .catch((error) => {
            this.setState({ loqateIsLoading: false });
          });
      },
    );
  };

  render() {
    const {
      handleInputChange,

      // Name Data
      title,
      firstName,
      surname,
      // Address Data
      houseName,
      addressLine1,
      addressLine2,
      addressLine4,
      addressLine5,
      postCode,
      // Visibility
      titleVisibility,
      firstNameVisibility,
      surnameVisibility,
      houseNameVisibility,
      addressLine1Visibility,
      addressLine2Visibility,
      addressLine4Visibility,
      addressLine5Visibility,
      postCodeVisibility,
      //Validation
      titleValidation,
      firstNameValidation,
      surnameValidation,
      addressLine1Validation,
      postCodeValidation,
    } = this.props;

    const titles = [
      'Dr',
      'Lady',
      'Lord',
      'Miss',
      'Mr',
      'Mrs',
      'Ms',
      'Mx',
      'Other',
      'Professor',
      'Reverend',
    ].map((title) => (
      <option key={title} value={title}>
        {title}
      </option>
    ));
    return (
      <Fragment>
        <div className="row mb-5">
          <div className="col-md-3">
            {/* =========================== Title =========================== */}

            {titleVisibility && (
              <div className="form-group">
                <small>
                  <label htmlFor="inpTitle">Title *</label>
                </small>
                <SelectField
                  id="inpTitle"
                  name="title"
                  value={title}
                  onChange={handleInputChange}
                  validation={titleValidation}
                >
                  <option value="">Choose...</option>
                  {titles}
                </SelectField>
                <ValidationMessage validation={titleValidation} />
              </div>
            )}
          </div>
          <div className="col">
            {/* =========================== First Name =========================== */}

            {firstNameVisibility && (
              <div className="form-group">
                <small>
                  <label htmlFor="inpFirstName">First Name *</label>
                </small>
                <InputField
                  id="inpFirstName"
                  name="firstName"
                  value={firstName}
                  onChange={handleInputChange}
                  validation={firstNameValidation}
                />
                <ValidationMessage validation={firstNameValidation} />
              </div>
            )}
          </div>
          <div className="col">
            {/* =========================== Last Name ============================ */}

            {surnameVisibility && (
              <div className="form-group">
                <small>
                  <label htmlFor="inpSurname">Last Name *</label>
                </small>
                <InputField
                  name="surname"
                  id="inpSurname"
                  value={surname}
                  onChange={handleInputChange}
                  validation={surnameValidation}
                />
                <ValidationMessage validation={surnameValidation} />
              </div>
            )}
          </div>
        </div>

        <div className="row mb-5">
          <div className="col-md-12">
            {/* ============================ Loqate ============================ */}

            {(houseNameVisibility ||
              addressLine1Visibility ||
              addressLine2Visibility ||
              addressLine4Visibility ||
              addressLine5Visibility ||
              postCodeVisibility) && (
              <div className="form-group position-relative">
                <small>
                  <label htmlFor="inpLoqate">Start typing address</label>
                </small>
                <InputField
                  type="search"
                  autoComplete="off"
                  id="inpLoqate"
                  name="loqate"
                  value={this.state.loqate}
                  onChange={this.handleLoqateChange}
                />

                {this.state.showLoqateDropDown && (
                  <div className="position-absolute w-100 theme-loqate-dropdown-container">
                    {this.state.loqateIsLoading && (
                      <div className="theme-loqate-loading d-flex  justify-content-center align-items-center">
                        <i className="fa fa-spinner fa-spin fa-lg text-body" />
                      </div>
                    )}

                    <ul
                      id="loqateUl"
                      className="list-unstyled theme-loqate-dropdown mb-0"
                    >
                      {this.state.loqateSelect.map((i, index) => (
                        <li
                          key={index}
                          data-value={i.Description}
                          onClick={() => this.handleLoqateSelectChange(i)}
                        >
                          {i.Text + ' ' + i.Description}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            )}
            <div className="row">
              <div className="col-12 col-sm-6">
                {/* ========================== House name ========================== */}

                {(houseNameVisibility ||
                  addressLine1Visibility ||
                  addressLine2Visibility ||
                  addressLine4Visibility ||
                  addressLine5Visibility ||
                  postCodeVisibility) && (
                  <div className="form-group">
                    <small>
                      <label htmlFor="inpNameOrNumber">
                        House name or number
                      </label>
                    </small>
                    <InputField
                      id="inpNameOrNumber"
                      name="houseName"
                      value={houseName}
                      onChange={handleInputChange}
                      disabled
                    />
                  </div>
                )}
              </div>
              <div className="col-12 col-sm-6">
                {/* ========================== Address line 1 ========================== */}

                {addressLine1Visibility && (
                  <div className="form-group">
                    <small>
                      <label htmlFor="inpAdd1">Address line 1 *</label>
                    </small>
                    <InputField
                      id="inpAdd1"
                      name="addressLine1"
                      value={addressLine1}
                      onChange={handleInputChange}
                      validation={addressLine1Validation}
                      disabled={this.state.loqateDisabled}
                    />
                    <ValidationMessage validation={addressLine1Validation} />
                  </div>
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-sm-6">
                {/* ========================== Address line 2 ========================== */}

                {addressLine2Visibility && (
                  <div className="form-group">
                    <small>
                      <label htmlFor="inpAdd2">Address line 2</label>
                    </small>
                    <InputField
                      name="addressLine2"
                      id="inpAdd2"
                      value={addressLine2}
                      onChange={handleInputChange}
                      disabled
                    />
                  </div>
                )}
              </div>
              <div className="col-12 col-sm-6">
                {/* =============================== Town =============================== */}

                {addressLine4Visibility && (
                  <div className="form-group">
                    <small>
                      <label htmlFor="inpTown">Town</label>
                    </small>
                    <InputField
                      name="addressLine4"
                      id="inpTown"
                      value={addressLine4}
                      onChange={handleInputChange}
                      disabled
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-sm-6">
                {/* ============================== County ============================== */}

                {addressLine5Visibility && (
                  <div className="form-group">
                    <small>
                      <label htmlFor="inpCounty">County</label>
                    </small>
                    <InputField
                      name="addressLine5"
                      id="inpCounty"
                      value={addressLine5}
                      onChange={handleInputChange}
                      disabled
                    />
                  </div>
                )}
              </div>
              <div className="col-12 col-sm-6">
                {/* ============================== Postcode ============================== */}

                {postCodeVisibility && (
                  <div className="form-group">
                    <small>
                      <label htmlFor="inpPostcode">Postcode *</label>
                    </small>

                    <InputField
                      name="postCode"
                      id="inpPostcode"
                      type="text"
                      value={postCode}
                      onChange={handleInputChange}
                      validation={postCodeValidation}
                      disabled
                    />
                    <ValidationMessage validation={postCodeValidation} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
