import React, { Component } from 'react';
import NewsMedia from '../newsMedia';
import Store from 'store';
import ReactGA from 'react-ga';
class NewI30N1 extends Component {
  componentDidMount = () => {
    ReactGA.event({
      label: 'new i30n 1',
      category: 'Promotion',
      action: `new i30n 1 Promo News item opened`,
      gaOptions: {
        userId: Store.get('customer') && Store.get('customer').urn,
      },
    });
  };

  clickHandler = () => {
    ReactGA.event({
      label: 'new i30n 1',
      category: 'Promotion',
      action: `new i30n 1 Promo News item Link Click`,
      gaOptions: {
        userId: Store.get('customer') && Store.get('customer').urn,
      },
    });
  };

  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="new-i30n1-news" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="newi30n1-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/newi30n1front.jpg") ',
              }}
            />
            <div
              id="newi30n1-gallery-2"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/newi30n1rear1.jpg") ',
              }}
            />
            <div
              id="newi30n1-gallery-3"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/newi30n1rear2.jpg") ',
              }}
            />
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#newi30n1-gallery-1"
                    style={{
                      backgroundImage: 'url("images/newi30n1front.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#newi30n1-gallery-2"
                    style={{
                      backgroundImage: 'url("images/newi30n1rear1.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#newi30n1-gallery-3"
                    style={{
                      backgroundImage: 'url("images/newi30n1rear2.jpg") ',
                    }}
                  />
                </div>
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">“New Hyundai i30 N”</p>
              </div>
              <hr />

              <NewsMedia
                articleId="new-i30n1-news"
                articleText="New Hyundai i30 N will feature new design and dual-clutch transmission"
              />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    New Hyundai i30 N will feature new design and dual-clutch
                    transmission
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    <ul>
                      <li>
                        Hyundai Motor has released the first pictures of the new
                        i30 N, revealing a design defined by performance
                      </li>
                      <li>
                        New, lightweight forged 19-inch wheels enhance the
                        sporty character of the car
                      </li>
                      <li>
                        First application of high-performance eight-speed dual
                        clutch transmission (N DCT) on a Hyundai model in Europe
                      </li>
                      <li>
                        The new i30 N will be ready to tackle the road and the
                        track
                      </li>
                    </ul>
                  </p>
                  {displayShowMore && (
                    <p>
                      <a
                        data-toggle="collapse"
                        href="#newi30n1"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </div>

              <div className={moreSectionClassName} id="newi30n1">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      <b>Leatherhead, 16 September 2020 –</b> Hyundai Motor has
                      revealed the first information about the latest generation
                      of its best-selling hot hatch, the i30 N. In a series of
                      low-lit images which were released today, viewers can see
                      that the new i30 N will have an updated design focused on
                      dynamic performance, emotion and statement.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      The new i30 N features new, aggressive-looking front and
                      rear bumpers, two large exhaust pipes, a wide centre
                      grille with the N signature, and new rear lamps and new
                      LED headlamps with V-shaped DRLs. The new design makes the
                      car look fast even when it is standing still.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      The images also show newly-developed 19-inch forged alloy
                      wheels, which are lighter than those of the previous i30
                      N, contributing to weight reduction in the car.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      Hyundai has also revealed that the new i30 will be
                      available with the company’s eight-speed dual clutch
                      transmission (N DCT), including dedicated N performance
                      shifting functions – the first Hyundai car in Europe to do
                      so.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      With these updates, the new i30 N will be ready to tackle
                      the road and the track. More details will be revealed
                      soon.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NewI30N1;
