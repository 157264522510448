import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Store from 'store';
import HandbookJS from '../utils/Handbook';

export default class MyCarNavbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      handbooks: [],
      vehicles: Store.get('vehicles'),
    };
  }

  componentWillMount() {
    const handbooks = HandbookJS.GetHandbooks(this.state.vehicles);
    this.setState({
      handbooks: handbooks,
    });
  }

  render() {
    return (
      <div className="container" data-search-exclude>
        <nav className="navbar navbar-expand-lg navbar-light bg-light p-0 dropShadow secondary-nav">
          <div className="slantedDiv">My Car</div>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto navbarText mr-xl-4 mr-lg-3 w-100  p-3 p-lg-0 w-100 ">
              <li className="nav-item pr-5">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/myCarDetails"
                >
                  Vehicle Details
                  <span className="sr-only">(current)</span>
                </NavLink>
              </li>
              <li className="nav-item  pr-5">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/myReminders"
                >
                  My Reminders
                </NavLink>
              </li>

              <li className="nav-item  pr-5">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/serviceHistory"
                >
                  Retailer Visits
                </NavLink>
              </li>
              {/* <li className="nav-item  pr-5">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/fuelCalculator"
                >
                  Fuel Saving Calculator
                </NavLink>
              </li> */}

              {this.state.handbooks && this.state.handbooks.length != 0 && (
                <li className="nav-item  pr-5">
                  <NavLink
                    className="nav-link"
                    activeClassName="active"
                    to="/myHandbook"
                  >
                    My Handbook
                  </NavLink>
                </li>
              )}
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}
