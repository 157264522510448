import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';
import Store from 'store';
import ReactGA from 'react-ga';
export default class KonaElectric extends Component {
  componentDidMount = () => {};

  clickHandler = () => {};

  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="KonaElectric" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="KonaElectric-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/hyundaielectric1.jpg") ',
              }}
            />
            <div
              id="KonaElectric-gallery-2"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/hyundaielectric2.jpg")',
              }}
            />
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#KonaElectric-gallery-2"
                    style={{
                      backgroundImage: 'url("images/hyundaielectric2.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#KonaElectric-gallery-1"
                    style={{
                      backgroundImage: 'url("images/hyundaielectric1.jpg")',
                    }}
                  />
                </div>
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “KONA Electric: Longest range of any Plug-In Car
                  Grant-Eligible Vehicle”
                </p>
              </div>
              <hr />

              <NewsMedia articleId="KonaElectric" articleText="Kona Electric" />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    KONA ELECTRIC: LONGEST RANGE OF ANY PLUG-IN CAR
                    GRANT-ELIGIBLE VEHICLE
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    {' '}
                    <ul>
                      <li>
                        Hyundai Motor UK has reduced prices of its electric car
                        range following changes to the government’s Plug-In Car
                        Grant
                      </li>
                      <li>
                        {' '}
                        KONA Electric 64KWh now has the longest electric range –
                        at 300 miles (WLTP) – of any Plug-In Car Grant-eligible
                        vehicle
                      </li>
                      <li>
                        {' '}
                        Hyundai has restructured its pricing and offers for each
                        of its EV model prices to maximise customer choice
                      </li>
                      <li>
                        {' '}
                        Hyundai currently offers IONIQ Electric and KONA
                        Electric, with IONIQ 5 joining the line-up later this
                        year
                      </li>
                    </ul>
                  </p>
                  {displayShowMore && (
                    <p>
                      <a
                        data-toggle="collapse"
                        href="#konaElectric"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </div>

              <div className={moreSectionClassName} id="konaElectric">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      8 April 2021 -{' '}
                      <strong>
                        Hyundai Motor UK has altered the pricing strategy of its
                        electric vehicles to deliver better value and more
                        choice to its customers, following changes to the
                        government’s Plug-In Car Grant last month.
                      </strong>
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      The new pricing means that KONA Electric 64KWh now has the
                      longest electric range – 300 miles (WLTP) – of any Plug-in
                      Car Grant-eligible vehicle.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      Previously electric cars up to £50,000 were eligible for a
                      £3,000 grant, but the changes reduce those figures to
                      £35,000 and £2,500 respectively. Three Hyundai models
                      qualified under the change to the threshold, which would
                      limit customer choice. Now all but one Hyundai electric
                      vehicle qualifies for the grant and prices have been
                      reduced across the range.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      <img
                        src="images/hyundaielectric2.jpg"
                        className="img-fluid"
                      />{' '}
                      <br /> <br />
                      The IONIQ Electric range sees prices cut for the Premium
                      model to £32,995 and the Premium SE to £34,995 (before
                      first registration fee applied). Premium models are
                      already well equipped, with 10.2-inch widescreen
                      navigation, 7-inch TFT Driver Information Display,
                      Wireless Charging Pad, and heated seats and steering
                      wheel. The Premium SE, meanwhile, adds ventilated front
                      seats, heated rear seats, leather seat facings and Memory
                      Function driver’s seat.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      KONA Electric 39KWh prices have also been reduced with the
                      SE Connect now costing £30,395 and Premium at £31,745
                      (before first registration fee applied). The KONA Electric
                      64KWh, capable of travelling up to 300 miles on a single
                      charge, now costs £34,995 in Premium specification and
                      £37,145 for the Premium SE (before first registration fee
                      applied) . SE Connect models are equipped with two
                      10.2-inch screens, 17-inch alloy wheels and a Krell
                      8-speaker premium audio system while Premium adds
                      technology like full LED headlights.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      <p
                        style={{
                          color: '#ac6b4f',
                          fontSize: '1rem',
                          fontStyle: 'italic',
                        }}
                      >
                        "When it comes to electric vehicles, cost is a big
                        consideration so it was important for us, in light of
                        the changes to the PICG, to provide our customers with
                        some additional savings, which we hope will help to
                        maintain demand for zero emission vehicles. IONIQ
                        Electric and KONA Electric are currently among the most
                        popular zero emissions vehicles on sale today, and by
                        maintaining the choice and the value available, Hyundai
                        will remain on track to be one of the largest suppliers
                        of electric vehicles in the country."
                      </p>
                    </p>
                    <p className="theme-news-body-text">
                      <span style={{ fontSize: '1rem' }}>
                        <strong>Ashley Andrew</strong>
                      </span>{' '}
                      <br />
                      <span style={{ color: 'dimgray' }}>
                        Managing Director, Hyundai Motor UK
                      </span>
                    </p>
                  </div>
                </div>
                {/* <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      More information about the all-new IONIQ 5 can be found in
                      the{' '}
                      <a
                        href="https://www.hyundai.news/uk/model-news/hyundai-ioniq-5-redefines-electric-mobility-lifestyle/"
                        target="_blank"
                      >
                        press kit.
                      </a>
                    </p>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
