import React, { Component } from 'react';
import './personal.css';
import FooterNavbar from '../components/footerNavbar';
import Store from 'store';
import Topnavbar from '../components/topnavbar';
import Bottomnavbar from '../components/bottomnavbar';
import Footer from '../components/footer';
import ProfileNav from '../components/profileNavbar';
import Profile from '../components/contactDetails/Profile';

import ImageUpload from '../components/personal/imageUpload';

export default class Personal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: '',
      surname: '',
      email: '',
      confirmPassword: '',
      password: '',
      //--
      loginToken: Store.get('loginToken').replace(/['"']+/g, ''),
      saveSuccess: false,
      saveFailed: false,
      savePasswordSuccess: false,
      savePasswordFailed: false,
      isLoading: false,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    const customer = Store.get('customerInfo');
    if (customer) {
      this.setState({
        firstName: customer.forenames,
        surname: customer.surname,
      });
    }
  }

  render() {
    const loginToken = this.state.loginToken;

    return (
      <div>
        <Topnavbar />
        <Bottomnavbar />
        <div className="container">
          <ProfileNav />

          <section
            className="section1 wow fadeIn"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <ImageUpload
              className="col-md-10 offset-md-1 theme-profile-header-img-container"
              uniqueName="wallpaper-photo"
              loginToken={loginToken}
              imageType="hyundai-wallpaper"
              defaultImage="images/default-wallpaper.jpg"
            >
              <p className="personalUsername d-none d-md-block">
                <span className="personalFirst">{this.state.firstName}</span>{' '}
                <span className="personalLast">{this.state.surname}</span>
              </p>
            </ImageUpload>

            <div className="content p-3 p-xl-5 col-md-10 offset-md-1 border-right border-bottom">
              <ImageUpload
                className="theme-profile-img-container"
                imgContainerClassName="theme-personel-profile-width"
                uniqueName="profile-photo"
                loginToken={loginToken}
                imageType="hyundai-profile"
                defaultImage="images/default-person.jpg"
              />

              <div className="row">
                <div className="col-md-12 col-lg-3 p-3 p-md-4">
                  <p className="d-block  d-md-none">
                    <span className="personalFirst">
                      {this.state.firstName}
                    </span>
                    <span className="personalLast">{this.state.surname}</span>
                  </p>

                  <h4 className="h4ServHistory" data-search-tag="title">
                    Your contact <br className="d-none d-lg-block " />
                    details
                  </h4>
                  <p />
                  <p className="personalBlue" data-search-tag="summary">
                    To make sure we can send you the information you request,
                    please update your details if required.
                  </p>
                </div>

                <div className="col-md-12  col-lg-8  offset-lg-1 p-3 p-md-4">
                  <Profile />
                </div>
              </div>
            </div>
          </section>

          <FooterNavbar />
        </div>
        <Footer />
      </div>
    );
  }
}
