import React, { Component } from 'react';

export default class CarConfigured extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: props.title,
      engine: props.engine,
      exterior: props.exterior,
      interior: props.interior,
      cashPrice: props.cashPrice,
    };
  }

  render() {
    const { title, engine, exterior, interior, cashPrice } = this.props;

    return (
      <div className="col-6 col-sm-4 theme-main-request-history-card mb-5">
        <img
          className="img-fluid flex-shrink-0  theme-main-request-history-card-image mx-auto d-block mb-lg-5 mb-3"
          src="images/car-test.png"
          alt="Card image cap"
        />
        <div className=" ">
          <h5 className=" text-info">
            <small>
              <strong>{title}</strong>
            </small>
          </h5>
          <p className="small">
            <strong>Engine:&nbsp;</strong>
            {engine}
            <br />
            <strong>Exterior:&nbsp;</strong>
            {exterior}
            <br />
            <strong>Interior:&nbsp;</strong>
            {interior}
            <br />
            <strong>CashPrice:&nbsp;</strong>
            {cashPrice}
            <br />
          </p>
        </div>
      </div>
    );
  }
}
