import React, { Component } from 'react';
import MyCarNavbar from '../components/myCarNavbar';
import './myReminders.css';
import FooterNavbar from '../components/footerNavbar';
import TopNavbar from '../components/topnavbar';
import BottomNavbar from '../components/bottomnavbar';
import Footer from '../components/footer';
import Store from 'store';
import CarSelector from '../components/carSelector';
import ReminderList from '../components/reminders/reminderList';
import SaveData from '../utils/SaveData';

import ReactPixel from 'react-facebook-pixel';
export default class MyReminders extends Component {
  state = {
    vehicles: [],
    selectedIndex: 0,
    isLoading: false,
    saveSuccess: false,
    saveFailed: false,
    email: Store.get('customerInfo') && Store.get('customerInfo').email,
    fromFacebook: Store.get('campaign_name') === 'facebook',
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    const vehicles = Store.get('vehicles');

    if (vehicles && vehicles.length > 0) {
      this.setState({
        vehicles: vehicles,
      });
    }
  }

  handleSelectionChanged = (index) => {
    this.setState((prevState) => {
      return {
        selectedIndex: index,
        saveSuccess: false,
        saveFailed: false,
      };
    });
  };

  updateNewVehicles = (newVehicle, campaign, updatedService) => {
    this.setState(
      (prevState) => {
        let newList = prevState.vehicles;
        newList[prevState.selectedIndex] = newVehicle;

        return {
          ...prevState,
          vehicles: newList,
          isLoading: true,
        };
      },
      () => {
        var me = this;
        SaveData.saveVehicle(
          me.state.vehicles,
          function () {
            if (updatedService && me.state.fromFacebook) {
              ReactPixel.trackCustom('from facebook update service', {
                email: me.state.email,
              });
            }

            me.setState(
              {
                saveSuccess: true,
                isLoading: false,
              },
              () => Store.set('vehicles', me.state.vehicles),
            );
          },
          function () {
            me.setState({
              saveFailed: true,
              isLoading: false,
            });
          },
          campaign,
        );
      },
    );
  };

  render() {
    const vehicle = this.state.vehicles[this.state.selectedIndex];

    return (
      <div>
        <TopNavbar />
        <BottomNavbar />
        <MyCarNavbar />

        <div className="container">
          <section
            className="section1 wow fadeIn"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <div className="content p-3 p-xl-5 col-md-10 offset-md-1 border-right border-bottom">
              <div className="row">
                <div className="col-lg-4 p-3 p-sm-4">
                  <h4 className="h4ServHistory" data-search-tag="title">
                    My Reminders
                  </h4>
                  <p />
                  <p className="personalBlue" data-search-tag="summary">
                    With our reminder emails, you'll never miss an important
                    car-related date.
                  </p>
                  <p className="personalBlue">
                    Because you can set up reminders for things like servicing,
                    insurance, MOT, road tax and breakdown cover.
                  </p>
                  <p className="personalBlue">Please select car below.</p>
                  <CarSelector
                    vehicles={this.state.vehicles}
                    onSelectionChanged={this.handleSelectionChanged}
                  />
                </div>

                {vehicle != null && (
                  <div className="col ml-lg-4   p-sm-4 border-lg-left">
                    <h4 className="page-inner-title">
                      Your Hyundai {vehicle.model}
                    </h4>
                    <p className="remindersBlue">
                      If you would like to receive Hyundai email reminders
                      please enter the due dates below and choose when you would
                      like to receive them. Some of them are pre-set based on
                      our records but you can easily tailor them to suit you. By
                      clicking ‘Save details’ you are agreeing to receive emails
                      from us regarding your vehicle.
                    </p>
                    <br />
                    <ReminderList
                      vehicle={vehicle}
                      vehicleIndex={this.state.selectedIndex}
                      orFranDidIt={this.updateNewVehicles}
                      isLoading={this.state.isLoading}
                      saveSuccess={this.state.saveSuccess}
                      isFailed={this.state.isFailed}
                    />
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>

        <FooterNavbar />

        <Footer />
      </div>
    );
  }
}
