import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';

export default class evConcept extends Component {
  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="EvConcept" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="evConcept-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage:
                  'url("images/productNews/evConcept/evConcept1.jpg") ',
              }}
            />
            <div
              id="evConcept-gallery-2"
              className="theme-news-header-image"
              style={{
                backgroundImage:
                  'url("images/productNews/evConcept/evConcept2.jpg")',
              }}
            />
            <div
              id="evConcept-gallery-3"
              className="theme-news-header-image"
              style={{
                backgroundImage:
                  'url("images/productNews/evConcept/evConcept3.jpg")',
              }}
            />

            <div
              id="evConcept-gallery-4"
              className="theme-news-header-image"
              style={{
                backgroundImage:
                  'url("images/productNews/evConcept/evConcept4.jpg")',
              }}
            />
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a  
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#evConcept-gallery-1"
                    style={{
                      backgroundImage:
                        'url("images/productNews/evConcept/evConcept1.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a  
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#evConcept-gallery-2"
                    style={{
                      backgroundImage:
                        'url("images/productNews/evConcept/evConcept2.jpg")',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a  
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#evConcept-gallery-3"
                    style={{
                      backgroundImage:
                        'url("images/productNews/evConcept/evConcept3.jpg")',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail mt-3">
                  <a  
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#evConcept-gallery-4"
                    style={{
                      backgroundImage:
                        'url("images/productNews/evConcept/evConcept4.jpg")',
                    }}
                  />
                </div>
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “Hyundai's strategy for future mobility and never has that
                  been more apparent than in its brand new concept car”
                </p>
              </div>
              <hr />

              <NewsMedia
                articleId="evConcept"
                articleText="Hyundai defines its future with the 45 EV Concept"
              />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p
                    className="salesText text-uppercase"
                    data-search-tag="title"
                  >
                    Hyundai defines its future with the 45 EV Concept
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    ‘STYLE SET FREE’ is Hyundai's strategy for future mobility
                    and never has that been more apparent than in its brand new
                    concept car. Unveiled at this year's Frankfurt Motor Show,
                    45 is an electric vehicle concept that focuses on autonomous
                    technologies and intelligent design. Whilst the exterior is
                    strikingly futuristic (kinetic cube lamps, anyone?) the
                    concept reimagines the in-car experience in the age of
                    electrification and autonomous technologies. So what better
                    place to start than inside?
                  </p>
                  {displayShowMore && (
                    <p>
                      <a  
                        data-toggle="collapse"
                        href="#evConcept"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </div>

              <div className={moreSectionClassName} id="evConcept">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="font-weight-bold">The interior</p>
                    <p className="theme-news-body-text">
                      What would you do inside a self-driven vehicle? With 45,
                      Hyundai imagines the interior as a living space that can
                      transform to accommodate a range of demands. Hyundai’s
                      ‘STYLE SET FREE’ strategy for future mobility emphasises
                      personalisation of design and function, after all.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      Hyundai’s design team has evolved the interior beyond a
                      driving space into a living space. Inspired by furniture
                      design, the minimalist cabin is relaxing and spacious,
                      with lounge chair-style seats front and rear that can
                      rotate so passengers face each other (they even swivel to
                      face outwards when the doors are opened to make getting in
                      and out effortless). The front-seat passengers, meanwhile,
                      can interact with the infotainment system via a
                      projection-beam interface, replacing a single central
                      touchscreen with a series of displays and functions
                      integrated into the dashboard.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      This complete reimagination of how cars can be packaged
                      has been made possible, in part, by the concept's
                      completely flat ‘skateboard floor’ which houses the
                      electric vehicle's batteries to give the interior the
                      sense of a living space. Clean lines and a sense of
                      ‘high-end electronics’ add to the feeling of space and
                      reinforce the simplistic modernity of 45’s design.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="font-weight-bold">The exterior</p>
                    <p className="theme-news-body-text">
                      The name 45 pays homage to one of the most iconic vehicles
                      in Hyundai's history; the 1974 Pony Coupé Concept. Like
                      that original coupé concept, 45 strips away complexity to
                      celebrate clean lines and minimalistic structure. The
                      aerodynamic and lightweight design of the monocoque-style
                      body is dramatic, and the car's name is, in part, a
                      reference to the 45-degree angles at the front and rear
                      that give the concept a diamond-shaped silhouette.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      A host of new technologies come as standard! There's a
                      ‘kinetic cube lamp’ design in the lattice radiator grille,
                      a hidden Camera Monitoring System (CMS), side cameras
                      instead of traditional wing mirrors which rotate the lens
                      past a brush to ensure perfect visibility at all times…
                      There are even active LED lights at the bottom of the door
                      that highlight the car's long-distance battery capability
                      so the driver knows how much longer they can drive before
                      they even get into the car.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      Combining heritage with vision, 45 offers an entirely new
                      in-car experience for self-driving vehicles of tomorrow.
                      It incorporates the evolution of Hyundai's ‘Sensuous
                      Sportiness’ design language. <i>'Sensuous' </i> for
                      enhanced emotional values that you can experience through
                      design, and <i>'Sportiness' </i> for a determination to
                      implement those values through innovative mobility
                      solutions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
