import React, { Component } from 'react';

export default class InputField extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    var className =
      this.props.className == null
        ? 'form-control form-control-sm flatInput'
        : this.props.className;
    className =
      !this.props.validation ||
      this.props.validation == null ||
      this.props.validation.isValid
        ? className
        : className + ' is-invalid';

    const type = this.props.type == null ? 'text' : this.props.type;

    return (
      <input
        disabled={this.props.disabled}
        maxLength={this.props.maxLength}
        autoComplete={this.props.autoComplete}
        id={this.props.id}
        type={type}
        className={className}
        placeholder={this.props.placeholder}
        name={this.props.name}
        value={this.props.value}
        onChange={this.props.onChange}
        onClick={this.props.onClick}
      />
    );
  }
}
