import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import FooterNavbar from '../components/footerNavbar';
import Store from 'store';
import Topnavbar from '../components/topnavbar';
import Bottomnavbar from '../components/bottomnavbar';
import CompetitionSideNav from '../components/competitionSideNav';
import Footer from '../components/footer';
import PostItemSingular from '../components/postItemSingular';
import PostItemMultiple from '../components/postItemMultiple';
import Config from '../utils/Config';

//-- CSS
import './contactPref.css';
import './compsOverview.css';

export default class CompsOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      competitions: Store.get('competitionInfo'),
      config: {},
    };
  }

  async LoadPublicConfig() {}

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({
      config: Store.get('public_config'),
    });
  }

  render() {
    if (
      this.state.config &&
      this.state.config.data &&
      !this.state.config.data.competitions_active
    ) {
      return (
        <Redirect
          push
          to={{
            pathname: '/',
          }}
        />
      );
    }

    return (
      <div>
        <Topnavbar />
        <Bottomnavbar />

        <div className="container">
          <section
            className="section1 wow fadeIn"
            data-wow-duration="2s"
            data-wow-delay="0.2s"
          >
            <div className="content pb-5  p-md-5 col-md-10 offset-md-1 border-right border-bottom">
              <div className="row">
                <div className="col-md-3 mt-4 border-md-right">
                  <CompetitionSideNav competitions={this.state.competitions} />
                </div>

                <div className="col-md-9  p-md-4 contact-perf-content">
                  <h4 className="h4ServHistory" data-search-tag="title">
                    MyHyundai offers you more
                  </h4>
                  <br />
                  <p className="remindersBlue" data-search-tag="summary">
                    More competitions, more great prizes, more chances to win.
                    As a MyHyundai customer, you can enter as many of our
                    competitions as you like and here's where you'll find them.
                  </p>

                  {(() => {
                    const displayCompetitions =
                      this.state.competitions === null
                        ? []
                        : this.state.competitions.filter(
                            (competition) =>
                              competition.directLinkOnly === false,
                          );

                    if (displayCompetitions.length === 0) {
                      return (
                        <img
                          src="images/check.jpg"
                          className="img-fluid mx-auto d-block"
                        />
                      );
                    } else if (displayCompetitions.length === 1) {
                      return (
                        <div
                          className="row"
                          data-testing-automation="competition-singular"
                        >
                          {/* --------------------- Singular post be here ---------------------- */}
                          <PostItemSingular
                            imageSrc={displayCompetitions[0].thumbnailImage}
                            imageAlt={
                              displayCompetitions[0]
                                .competitionPageImageAlternativeText
                            }
                            title={displayCompetitions[0].longTitle}
                            link={{
                              pathname: '/competition',
                              search: '?id=' + displayCompetitions[0].id,
                            }}
                            closesText={displayCompetitions[0].closureInfo}
                          />
                          {/* ----------------------- /END Singular post ----------------------- */}
                        </div>
                      );
                    } else {
                      return (
                        <div
                          className="row"
                          data-testing-automation="competition-multiple"
                        >
                          {/* --------------------- Multiple posts be here --------------------- */}
                          {displayCompetitions.map((competition, index) => {
                            const link = {
                              pathname: '/competition',
                              search: '?id=' + competition.id,
                            };
                            return (
                              <PostItemMultiple
                                imageSrc={competition.thumbnailImage}
                                imageAlt={
                                  competition.competitionPageImageAlternativeText
                                }
                                title={competition.longTitle}
                                link={link}
                                closesText={competition.closureInfo}
                                key={index.toString()}
                              />
                            );
                          })}

                          {/* ---------------------- /END Multiple posts ----------------------- */}
                        </div>
                      );
                    }
                  })()}
                </div>
              </div>
            </div>
          </section>

          <FooterNavbar />
        </div>

        <Footer />
      </div>
    );
  }
}
