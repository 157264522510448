import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';
import Store from 'store';
import ReactGA from 'react-ga';
export default class Newi20 extends Component {
  componentDidMount = () => {
    ReactGA.event({
      label: 'New i20',
      category: 'Promotion',
      action: `i20 Promo News item opened`,
      gaOptions: {
        userId: Store.get('customer') && Store.get('customer').urn,
      },
    });
  };

  clickHandler = () => {
    ReactGA.event({
      label: 'New i20',
      category: 'Promotion',
      action: `i20 Promo News item Link Click`,
      gaOptions: {
        userId: Store.get('customer') && Store.get('customer').urn,
      },
    });
  };

  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="Newi20" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="iron-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/i201.jpg") ',
              }}
            />
            <div
              id="iron-gallery-2"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/i202.jpg")',
              }}
            />
            <div
              id="iron-gallery-3"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/iron3.jpg")',
              }}
            />
            <div
              id="iron-gallery-4"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/iron4.jpg")',
              }}
            />
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#iron-gallery-1"
                    style={{
                      backgroundImage: 'url("images/i201.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#iron-gallery-2"
                    style={{
                      backgroundImage: 'url("images/i202.jpg")',
                    }}
                  />
                </div>
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “Hyundai Motor teases new design for the all-new i20”
                </p>
              </div>
              <hr />

              <NewsMedia
                articleId="newi20"
                articleText="All New i20 is a big deal"
              />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    HYUNDAI MOTOR TEASES NEW DESIGN FOR THE ALL-NEW i20
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    {' '}
                    <ul>
                      <li>
                        First teaser of the all-new i20 reveals a completely new
                        design
                      </li>
                      <li>
                        {' '}
                        First model in Europe to implement Hyundai’s ‘Sensuous
                        Sportiness’ design language
                      </li>
                      <li>
                        World premiere of all-new i20 at Geneva Motor Show in
                        March 2020
                      </li>
                    </ul>
                  </p>
                  {displayShowMore && (
                    <p>
                      <a
                        data-toggle="collapse"
                        href="#newi20"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </div>

              <div className={moreSectionClassName} id="newi20">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      <strong>Offenbach, 5 February 2020 – </strong>Hyundai
                      Motor has teased the all-new i20, indicating that it will
                      feature a completely new design and that it will be the
                      first car in Europe to carry Hyundai’s new design
                      language: ‘Sensuous Sportiness’.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      Sensuous Sportiness is characterised by the harmony
                      between four fundamental elements: proportion,
                      architecture, styling and technology. The purpose of this
                      design direction is to bring instinctive beauty to Hyundai
                      vehicles, creating emotional value and giving them a
                      distinctive look.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      The preview images show that the all-new i20 will have a
                      more sporty and dynamic look. The cascading grille in the
                      front running horizontally to the sides results in the
                      characteristic wide and low body. Also the unique lighting
                      signature underlines the width and the stance of the
                      all-new i20. The bold character line on the side of the
                      body emphasizes the car’s sporty proportions and agility.
                      The rear is complemented with a horizontal tail lamp
                      design which is seamlessly connected with the rear glass.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      The horizontal blades covering the dashboard are a real
                      icon of the all-new i20 interior. The slick treatment
                      emphasises the width of the front fascia and neatly
                      disguises the air vents. To further enhance the elegance
                      and the aesthetics of the interior, the new digital
                      cluster and the main centre fascia have been combined
                      together and feature two 10.25-inch screens.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      The all-new i20 will be revealed at the Geneva Motor Show
                      in March 2020. More details will be released in the near
                      future.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
