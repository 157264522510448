import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import './activation.css';
import QueryString from 'query-string';
import Validation from '../utils/Validation';
import InputField from '../components/inputField';
import ValidationMessage from '../components/validationMessage';
import ReactPasswordStrength from 'react-password-strength';

export default class PasswordReset extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: '',
      password1: '',
      password2: '',
      isSuccess: false,
      isFailure: false,
      isLoading: false,
      validation: {
        password1: Validation.initValidationState(['required', 'password']),
        password2: Validation.initValidationState(['required'], () =>
          PasswordReset.passwordsMatch(this.state),
        ),
      },
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleResetClick = this.handleResetClick.bind(this);
  }

  componentDidMount() {
    const values = QueryString.parse(this.props.location.search);
    this.setState({
      token: values.token,
    });
  }

  handleInputChangePass1 = (val) => {
    this.setState({ password1: val.password }, () =>
      this.validateField('password1'),
    );
  };

  handleInputChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    this.setState(
      {
        [name]: value,
      },
      () => this.validateField(name),
    );
  }

  validateField(name) {
    this.setState((prevState) => {
      return Validation.validate(prevState, name);
    });
  }
  static validationClass(val) {
    var className = '';
    className =
      !val || val == null || val.isValid
        ? className
        : className + ' is-invalid';

    return className;
  }

  static passwordsMatch(state) {
    if (state.password1 !== state.password2) {
      return 'Both passwords fields must be the same';
    }

    return null;
  }

  handleResetClick() {
    var newState = Validation.validateAll(this.state);

    if (Validation.anyInvalid(newState)) {
      this.setState(newState);
    } else {
      this.setState({
        isLoading: true,
      });

      fetch(
        process.env.REACT_APP_API_URL +
          '/Authentication/ResetPassword?token=' +
          encodeURIComponent(this.state.token) +
          '&password=' +
          this.state.password1 +
          '&brand=HyundaiUk',
      ).then((response) => {
        if (response.ok) {
          this.setState({
            isSuccess: true,
            isLoading: false,
            password1: '',
            password2: '',
          });
        } else {
          this.setState({
            isFailure: true,
            isLoading: false,
          });
        }
      });
    }
  }

  render() {
    return (
      <div id="passwordResetContainer" className="container">
        <div className="jumbotron p-10">
          {this.state.logInSuccess && <Redirect to="/" />}
          <div className="d-flex flex-column flex-sm-row justify-content-between text-center">
            <div>
              <img src="images/hyundai_logoF.png" style={{ maxHeight: 25 }} />
            </div>
            <div className="p-2 p-sm-0">
              <i className="fas fa-phone" />{' '}
              <a href="tel:0800981981" title="Call Us Now">
                0800 981 981
              </a>
            </div>
          </div>

          <div className="theme-login-bg-image p-3 mt-0 mt-sm-4">
            <div className="container">
              <div className="row">
                <div className="col-lg-7 ml-auto mt-5 mb-4 p-3 bg-white">
                  <p className="frmTitleText">Password Reset</p>
                  <p className="frmTitleTextSmaller">
                    Nearly there. Just enter your new password, then click
                    'Reset'.
                  </p>

                  <form>
                    <div className="form-row">
                      <div
                        id="passwordResetPasswordStrengthContainer"
                        className="form-group col-md-6 pr-3"
                      >
                        <small>
                          <label htmlFor="passwordResetPasswordInput">
                            Password *
                          </label>
                        </small>

                        <ReactPasswordStrength
                          className={
                            'theme-main-flat-password-strength-container  ' +
                            PasswordReset.validationClass(
                              this.state.validation.password1,
                            )
                          }
                          minLength={8}
                          minScore={2}
                          changeCallback={this.handleInputChangePass1}
                          inputProps={{
                            name: 'password1',
                            className:
                              'form-control form-control-sm theme-main-flat-password-input',
                            id: 'passwordResetPasswordInput',
                            value: this.state.password1,
                          }}
                        />
                        <div
                          className={
                            'form-control d-none' +
                            PasswordReset.validationClass(
                              this.state.validation.password1,
                            )
                          }
                        />
                        <ValidationMessage
                          validation={this.state.validation.password1}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        {
                          <small>
                            <label htmlFor="passwordResetConfirmPasswordInput">
                              Confirm password *
                            </label>
                          </small>
                        }
                        <InputField
                          type="password"
                          id="passwordResetConfirmPasswordInput"
                          name="password2"
                          autoComplete="new-password"
                          value={this.state.password2}
                          onChange={this.handleInputChange}
                          validation={this.state.validation.password2}
                        />
                        <ValidationMessage
                          validation={this.state.validation.password2}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6 offset-md-6">
                        <button
                          id="passwordResetResetButton"
                          type="button"
                          className=" btn btn-block btn-primary btn-sm removeRadius"
                          onClick={this.handleResetClick}
                        >
                          {this.state.isLoading ? (
                            <div>
                              <i className="fas fa-spinner fa-spin fa-lg text-white" />
                            </div>
                          ) : (
                            <span>Reset</span>
                          )}
                        </button>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col">
                        {this.state.isSuccess && (
                          <p>
                            <small>
                              <i className="fas fa-check-circle text-success" />{' '}
                              Your password has been changed, please{' '}
                              <Link id="passwordResetLoginButton" to="/">
                                login
                              </Link>
                              .
                            </small>
                          </p>
                        )}
                        {this.state.isFailure && (
                          <p>
                            <small>
                              <i className="fas fa-exclamation-triangle text-danger" />{' '}
                              This link is either invalid or expired. Please
                              follow the{' '}
                              <Link
                                id="passwordResetForgottenPasswordButton"
                                to="/"
                              >
                                forgotten password
                              </Link>{' '}
                              link to start again.
                            </small>
                          </p>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
