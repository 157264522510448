import React, { Component } from 'react';
import twitterX from '../../src/images/twitterX.svg';

export default class NewsMedia extends Component {
  render() {
    var rootUrl = window.location.href;
    var existingHashIndex = rootUrl.indexOf('#');
    if (existingHashIndex >= 0) {
      rootUrl = rootUrl.substring(0, existingHashIndex);
    }

    const rawUrl = rootUrl + '#' + this.props.articleId;
    const rawText = this.props.articleText;
    const url = encodeURIComponent(rawUrl);
    const text = encodeURIComponent(rawText);
    const fbUrl = 'https://facebook.com/sharer/sharer.php?u=' + url;
    const twitterUrl =
      'https://twitter.com/intent/tweet/?text=' + text + '&url=' + url;
    const mailUrl = 'mailto:?subject=' + text + '&body=' + url;
    const pinterestUrl =
      'https://pinterest.com/pin/create/button/?url=' +
      url +
      '&media=' +
      url +
      '&description=' +
      text;
    const googleUrl = 'https://plus.google.com/share?url=' + url;

    return (
      <div className="theme-news-social-circle-container ">
        {/* Facebook */}
        <a
          href={fbUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="theme-news-social-circle"
        >
          <span className="fa-layers fa-fw  ">
            <i className="fab fa-facebook-f " />
          </span>
        </a>

        {/* Twitter */}
        <a
          href={twitterUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="theme-news-social-circle"
        >
          <span className="fa-layers fa-fw ">
            {/* <i className="fab fa-twitter" /> */}
            <i>
              <img
                src={twitterX}
                className="img-fluid"
                style={{ marginBottom: '4px' }}
              />
            </i>
          </span>
        </a>

        {/* Mail */}
        <a href={mailUrl} className="theme-news-social-circle">
          <span className="fa-layers fa-fw">
            <i className="fas fa-envelope" />
          </span>
        </a>

        {/* Pinterest */}
        <a
          href={pinterestUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="theme-news-social-circle"
        >
          <span className="fa-layers fa-fw">
            <i className="fab fa-pinterest-p " />
          </span>
        </a>

        {/* Google */}
        <a
          href={googleUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="theme-news-social-circle"
        >
          <span className="fa-layers fa-fw">
            <i className="fab fa-google-plus-g" />
          </span>
        </a>
      </div>
    );
  }
}
