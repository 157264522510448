import React, { Component } from 'react';
import NewsMedia from '../newsMedia';
import Store from 'store';
import ReactGA from 'react-ga';
import './newi30N2.css';
class NewI30N2 extends Component {
  componentDidMount = () => {
    ReactGA.event({
      label: 'new i30n 2',
      category: 'Promotion',
      action: `new i30n 2 Promo News item opened`,
      gaOptions: {
        userId: Store.get('customer') && Store.get('customer').urn,
      },
    });
  };

  clickHandler = () => {
    ReactGA.event({
      label: 'new i30n 2',
      category: 'Promotion',
      action: `new i30n 2 Promo News item Link Click`,
      gaOptions: {
        userId: Store.get('customer') && Store.get('customer').urn,
      },
    });
  };

  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="new-i30n2-news" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="newi30n1-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/newi30n1front.jpg") ',
              }}
            />
            <div
              id="newi30n1-gallery-2"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/newi30n1rear1.jpg") ',
              }}
            />
            <div
              id="newi30n1-gallery-3"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/newi30n1rear2.jpg") ',
              }}
            />
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#newi30n1-gallery-1"
                    style={{
                      backgroundImage: 'url("images/newi30n1front.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#newi30n1-gallery-2"
                    style={{
                      backgroundImage: 'url("images/newi30n1rear1.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#newi30n1-gallery-3"
                    style={{
                      backgroundImage: 'url("images/newi30n1rear2.jpg") ',
                    }}
                  />
                </div>
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “Shift the moment: new Hyundai i30 N enhanced for maximum
                  driving fun”
                </p>
              </div>
              <hr />

              <NewsMedia
                articleId="new-i30n2-news"
                articleText="Shift the moment: new Hyundai i30 N enhanced for maximum driving fun"
              />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    Shift the moment: new Hyundai i30 N enhanced for maximum
                    driving fun
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    <ul>
                      <li>
                        The Hyundai i30 N has been enhanced with a new design
                        focused on performance, emotion and statement
                      </li>
                      <li>
                        For the first time, the new i30 N will be available with
                        N DCT, a wet-type eight-speeddual clutch transmission,
                        which offers paddle shifters and enables three new N
                        performance functions for a sportier experience
                      </li>
                      <li>
                        New 19-inch forged alloy wheels and N Light Seats allow
                        for additional weight reduction, resulting in more agile
                        handling
                      </li>
                      <li>
                        Additional high-performance driving features combined
                        with updated safety and connectivity technology make the
                        new i30 N a truly racetrack-capable everyday sports car
                      </li>
                    </ul>
                  </p>
                  {displayShowMore && (
                    <p>
                      <a
                        data-toggle="collapse"
                        href="#newi30n2"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </div>

              <div className={moreSectionClassName} id="newi30n2">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      <b>Leatherhead, 24 September 2020 –</b> Hyundai Motor has
                      unveiled the new i30 N, which comes with an enhanced
                      design, a new wet-type eight-speed dual clutch
                      transmission (N DCT), and new advanced driver assistance
                      and safety features, providing drivers with a better
                      experience whether they’re on the road or on the track.
                      With a variety of new features to improve both every day
                      and high-performance driving situations, the new i30 is
                      truly a racetrack-capable everyday sports car. The i30 N
                      became Hyundai’s first high-performance production car
                      when it launched in 2017. So far, over 25,000 i30 N units
                      have been sold in Europe. The new i30 N builds on the
                      success of its predecessor. As well as offering a range of
                      design and driving enhancements, it is also equipped with
                      more lightweight materials, resulting in more agility and
                      better handling. Like the previous version, the new i30 N
                      is available in both hatchback and fastback body types.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      “Since its market debut in 2017, the Hyundai N original
                      high-performance car has received multiple outstanding
                      reviews and awards. Drivers seeking maximum fun-to-drive
                      on the road as well as on the track, now receive an even
                      wider range of performance and design features, with the
                      new Hyundai i30 N,” says Thomas Schemera, Executive Vice
                      President and Head of Product Division at Hyundai Motor
                      Company. “Especially the new design, new wheels, N Light
                      Seats and the new wet-type eight-speed dual clutch
                      transmission, make the Hyundai N experience sportier and
                      more convenient than ever before.”
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p style={{ marginBottom: '0' }}>
                      <b>
                        An enhanced exterior design focused on dynamic
                        performance
                      </b>
                    </p>
                    <p className="theme-news-body-text">
                      The new i30 N's design can be described in three words:
                      performance, emotion, statement. Beyond a styling
                      exercise, performance defines the changes made exclusively
                      for the i30 N. Every design feature around the car has
                      been developed with focus on dynamic performance: the new
                      i30 N’s appearance shows that it was naturally born for
                      the racetrack. At the front, the N signature can be found
                      on the wide centre grille. Its specific, sharp mesh design
                      reminds the viewer of airplane wings and has been
                      optimised to allow efficient engine cooling. The N logo
                      has also been integrated onto the wheel caps. Other design
                      enhancements include new LED headlamps with V-shaped
                      Daytime Running Lights (DRLs). Meanwhile, the outer bumper
                      corners, dominated by the typical aerodynamic side fins,
                      incorporate the air curtains to significantly improve the
                      airflow and reduce turbulence into the wheel housing.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      The new i30 hatchback N features an updated rear end
                      design, while the rear of the new i30 Fastback N remains
                      unchanged. On the hatchback, the large wing spoiler with
                      the distinctive N triangular brake light creates downforce
                      and excellent balance, while the rear lamps have also been
                      updated and feature a new LED signature. Two large exhaust
                      pipes integrated into the lower bumper diffuser complete
                      the image of the pure high-performance i30 N. The form
                      following function design of the new i30 N makes the car
                      look fast even in standstill. Purposeful and energetic, it
                      is ready to tackle the road and track. The new i30 N comes
                      in seven exterior colours, including Performance Blue,
                      which is exclusive to Hyundai N models.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      <b>Exterior colours:</b>
                      <ul>
                        <li>Performance Blue</li>
                        <li>Polar White</li>
                        <li>Engine Red</li>
                        <li>Sunset Red</li>
                        <li>Shadow Grey</li>
                        <li>Dark Knight</li>
                        <li>Phantom Black</li>
                      </ul>
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      The new i30 N with standard trim is fitted with 18-inch
                      alloy wheels. When outfitted with the Performance Package
                      (see below), it comes with a series of design upgrades,
                      including the newly-developed 19-inch forged alloy wheels.
                      The lightweight, optimised, five-double-spoke wheels were
                      designed for minimum unsprang mass and high strength.
                      Together, the 19-inch alloy wheels are 14.4 kg lighter
                      than the standard 18-inch wheels. Their dark satin grey
                      matte finish, contrasting with the N-specific red brake
                      callipers with N logo, underlines the racing spirit of the
                      car. The new i30 N with Performance Package has also been
                      outfitted with high-performance Pirelli P-Zero tyres,
                      developed especially for the i30 N, while the standard
                      trim offers Michelin Pilot Super Sport tyres.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      “Since its market debut in 2017, the Hyundai N original
                      high-performance car has received multiple outstanding
                      reviews and awards. Drivers seeking maximum fun-to-drive
                      on the road as well as on the track, now receive an even
                      wider range of performance and design features, with the
                      new Hyundai i30 N,” says Thomas Schemera, Executive Vice
                      President and Head of Product Division at Hyundai Motor
                      Company. “Especially the new design, new wheels, N Light
                      Seats and the new wet-type eight-speed dual clutch
                      transmission, make the Hyundai N experience sportier and
                      more convenient than ever before.”
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p style={{ marginBottom: '0' }}>
                      <b>Interior design connects car with driver</b>
                    </p>
                    <p className="theme-news-body-text">
                      The interior of the new i30 N is focused on connecting the
                      driver with the car at all touch points. The analogue
                      cluster includes features such as the active variable LED
                      red zone, which varies according to engine oil
                      temperature, and the shift timing indicator, which shows
                      the driver the best time to switch gears. The new i30 N is
                      also available with a heated steering wheel and heated
                      front seats. The new i30 N features sporty, metal pedals
                      and Performance Blue stitching on both body types. New to
                      this model are seatbelts with Performance Blue accents.
                      The interior comes in one-tone Black with full cloth or
                      artificial suede and leather seats.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p style={{ marginBottom: '0' }}>
                      <b>N Light Seats</b>
                    </p>
                    <p className="theme-news-body-text">
                      For the first time, the new i30 N comes with the option of
                      N Light Seats, a set of lightweight, high-performance
                      front seats. These uniquely-designed monoform
                      high-performance sport seats are 2.2 kg lighter than the
                      standard seats. The pronounced bolsters offer excellent
                      lateral support. N Light Seats are made of premium leather
                      and Alcantara materials and come with Performance Blue
                      stitching and an illuminated N logo on an integrated
                      headrest for a high-quality feeling.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p style={{ marginBottom: '0' }}>
                      <b>
                        Improved engine delivers an even more intense motorsport
                        feeling
                      </b>
                    </p>
                    <p className="theme-news-body-text">
                      The new i30 N comes with a 2.0-litre turbocharged engine
                      with either a six-speed manual transmission (6MT) or – for
                      the first time – a wet-type eight-speed dual clutch
                      transmission (N DCT). With the standard trim, the new i30
                      N with 6MT delivers a maximum power output of 250 PS and a
                      maximum torque of 353 Nm. New i30 N customers can also
                      select the Performance Package option. With the
                      Performance Package, the engine delivers 280 PS. This is
                      an increase of 5 PS from the original i30 N, and is
                      available with both 6MT and N DCT. The maximum torque for
                      the Performance Package variation has been increased by 39
                      Nm to 392 Nm.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      The 280 PS engine offers flat power, which ensures high
                      responsiveness and improved acceleration for even more fun
                      on the road or on the track. Flat power provides more
                      torque and power at lower RPMs, thus utilising more of the
                      engine’s potential in everyday driving situations. The new
                      i30 N keeps maximum torque between 1,950 and 4,600 RPM,
                      and achieves maximum power at 5,200 RPM. This improves
                      acceleration in the mid- and high-speed range and delivers
                      a consistently high performance even in a variety of
                      ambient conditions.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      Both trim levels have a maximum speed of 250 km/h, and the
                      new i30 N with the Performance Package can go from 0-100
                      km/h in 5.9 seconds, an improvement of 0.2 seconds or 3
                      per cent as compared to the previous model.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      As with its predecessor, the new i30 N is equipped with a
                      range of high-performance driving features including Rev
                      Matching, Launch Control, Rear stiffness bar, and more.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p style={{ marginBottom: '0' }}>
                      <b>N DCT makes the new i30 N even more fun to drive</b>
                    </p>
                    <p className="theme-news-body-text">
                      The new N DCT eight-speed dual clutch transmission enables
                      a range of high-performance driving features, making the
                      new i30 N even more fun to drive. N DCT is a wet type
                      transmission, meaning that oil is used to cool the clutch
                      components, which creates less friction and allows a
                      higher amount of torque to be transferred through the
                      gearbox. The new transmission option was designed to
                      provide the engaging experience of a manual transmission
                      with the convenience of an automatic transmission. Drivers
                      can choose to enter manual mode and shift gears either by
                      using the paddle shifters on the steering wheel or the
                      gear stick. The paddle shifters allow the driver to shift
                      gears without taking their hands off the steering wheel
                      for an even sportier experience. If the driver decides to
                      use the gear lever instead, the gear lever comes with
                      sporty shifting logic: to downshift, push forward, and to
                      upshift, pull back.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      The N DCT enables three new N performance functions for
                      even more driving fun: N Grin Shift, N Power Shift and N
                      Track Sense Shift.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      N Grin Shift (NGS) increases torque by allowing
                      turbocharger overboost and maximises transmission response
                      for 20 seconds – performance that is sure to bring a grin
                      to the driver’s face. To activate, the driver pushes a
                      button on the steering wheel, and a countdown begins on
                      the cluster showing the remaining seconds for this
                      function. When NGS is activated, the driver will
                      experience a “push feel” when upshifting.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      N Power Shift (NPS) engages when the car accelerates with
                      more than 90 percent of throttle, thereby mitigating any
                      reduction in torque by using upshifts to deliver maximum
                      power to the wheels. This gives the driver a responsive
                      feeling of dynamic acceleration when shifting.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      Finally, N Track Sense Shift (NTS) automatically
                      recognises when the road conditions are optimal for
                      dynamic driving, for example if it recognises that the car
                      is driving on a racetrack, and activates automatically. By
                      selecting the right gear and shift timing, it provides
                      optimal performance, just like a professional race car
                      driver.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p style={{ marginBottom: '0' }}>
                      <b>N Grin Control System</b>
                    </p>
                    <p className="theme-news-body-text">
                      As with the previous generation i30 N, the N Grin Control
                      System gives customers the choice between five distinct
                      driving modes: Eco, Normal, Sport, N and N Custom. The
                      driving modes adjust the parameters of the engine, the
                      suspension, Electronic Stability Control (ESC), N Corner
                      Carving Differential (Electronically-controlled Limited
                      Slip Differential), exhaust sound, steering, and
                      transmission to optimise them for a variety of driving
                      conditions. In the N Custom mode, drivers can select from
                      Eco, Normal, Sport and Sport+ settings for each of these
                      components to match driving preferences and road
                      conditions.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p style={{ marginBottom: '0' }}>
                      <b>Ride and handling</b>
                    </p>
                    <p className="theme-news-body-text">
                      As with its predecessor, the new i30 N features an
                      Electronic Controlled Suspension. The suspension and
                      steering systems have been retuned, resulting in improved
                      ride and handling performance for both transmission types.
                      Customers who select the Performance Package can also
                      benefit from the N Corner Carving Differential, an
                      Electronically Controlled Limited Slip Differential
                      (eLSD). Additionally for the Performance Package, the
                      front brake disc size has been increased from 345mm to
                      360mm for a better braking performance. The weight saved
                      through the high-performance N Light Seats and 19-inch
                      forged alloy wheels results in better performance and
                      handling for a more agile driving experience.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p style={{ marginBottom: '0' }}>
                      <b>Safety</b>
                    </p>
                    <p className="theme-news-body-text">
                      The new i30 N has been upgraded to include more Hyundai
                      SmartSense active safety and driver assistance features.
                      Forward Collision-Avoidance Assist has been upgraded to
                      include pedestrian detection (FCA-P). Lane Following
                      Assist (LFA) works to keep the vehicle centred in its lane
                      even before the vehicle begins to drift to one side. The
                      hatchback version of the new i30 N with N DCT comes with
                      the option of Blind-Spot Collision-Avoidance Assist
                      (BCA-R). This function can activate the brakes to prevent
                      a collision when switching lanes on the highway or when
                      exiting a parallel parking spot. Similarly, Rear
                      Cross-Traffic Collision-Avoidance Assist (RCCA), also
                      available on the hatchback with N DCT, can activate the
                      brakes when an obstruction is detected directly behind the
                      vehicle and the car is in reverse, such as when exiting a
                      normal parking spot.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      <b>Hyundai SmartSense safety features:</b>
                      <ul>
                        <li>
                          [NEW] Forward Collision-Avoidance Assist with vehicle
                          and now also with pedestrian detection (FCA-P)
                        </li>
                        <li>[NEW] Lane Following Assist (LFA)</li>
                        <li>
                          [NEW] Blind-Spot Collision Warning (BCW) (hatchback
                          only)
                        </li>
                        <li>
                          [NEW] Blind-Spot Collision-Avoidance Assist (BCA-R)
                          (only DCT, hatchback only)
                        </li>
                        <li>
                          [NEW] Rear Cross-Traffic Collision-Avoidance Warning
                          (RCCW) (hatchback only)
                        </li>
                        <li>
                          [NEW] Rear Cross-Traffic Collision-Avoidance Assist
                          (RCCA) (only DCT, hatchback only)
                        </li>
                      </ul>
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      Another safety feature, e-call, can contact emergency
                      services at the push of a button, and automatically
                      contacts first responders if the vehicle’s airbags have
                      been deployed.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p style={{ marginBottom: '0' }}>
                      <b>Tech & Connectivity</b>
                    </p>
                    <p className="theme-news-body-text">
                      The new i30 N features a Performance Driving Data System
                      to monitor and improve the driver’s track skills, with
                      updated graphics for even more ease of use. This feature
                      saves and displays driving data, including information on
                      PS, torque, turbo boost. It also includes a lap and
                      acceleration timer, which comes in handy on the track.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      The new i30 N comes with an optional 10.25-inch
                      touchscreen navigation system with Apple CarPlay and
                      Android Auto. It is also available with the latest version
                      of Bluelink, Hyundai’s state-of-the-art connected car
                      services. This offers a range of new benefits and services
                      for Hyundai customers including Connected Routing, Last
                      Mile Navigation and live parking information, and a new
                      user profile feature.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      <b>Technical Specifications</b>
                      <table>
                        <tr>
                          <th className="table-border"> </th>
                          <th className="table-border">New i30 N</th>
                          <th className="table-border">
                            New i30 N w/ Performance Package
                          </th>
                        </tr>
                        <tr>
                          <td className="table-border">
                            <b>Engine</b>
                          </td>
                          <td className="table-border">2.0 T-GDI</td>
                          <td className="table-border">2.0 T-GDI</td>
                        </tr>

                        <tr>
                          <td className="table-border">
                            <b>Transmission</b>
                          </td>
                          <td className="table-border">6MT</td>
                          <td className="table-border">6MT or N DCT</td>
                        </tr>

                        <tr>
                          <td className="table-border">
                            <b>Power</b>
                          </td>
                          <td className="table-border">250 PS</td>
                          <td className="table-border">280 PS</td>
                        </tr>

                        <tr>
                          <td className="table-border">
                            <b>Max. torque</b>
                          </td>
                          <td className="table-border">353 Nm</td>
                          <td className="table-border">392 Nm</td>
                        </tr>

                        <tr>
                          <td className="table-border">
                            <b>Top speed (km/h)</b>
                          </td>
                          <td className="table-border">250</td>
                          <td className="table-border">250</td>
                        </tr>
                      </table>
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      The new i30 N will be available in Europe starting in
                      early 2021. Exact timing and pricing will vary by market.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NewI30N2;
