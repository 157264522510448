import React, { Component } from 'react';

export default class ReminderOption extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    if (this.props.onChange != null) {
      this.props.onChange(this.props.value);
    }
  }

  render() {
    const className = this.props.selected
      ? 'btn  btn-primary w-100 rembSelected'
      : 'btn  btn-primary btn-sm w-100 remb';

    return (
      <div className="px-2 px-sm-0  pb-3">
        <button
          className={className}
          onClick={this.handleClick}
          disabled={this.props.disabled}
        >
          <small>
            {this.props.lineOne}
            <br />
            {this.props.lineTwo}
          </small>
        </button>
      </div>
    );
  }
}
