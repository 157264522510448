import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Store from 'store';
export default class ServiceProductNavbar extends Component {
  state = {
    customer: Store.get('customerInfo'),
    vehicles: Store.get('vehicles'),
    overFive:
      (Store.get('vehiclesInfo') &&
        Store.get('vehiclesInfo').isAnyVehicleOverFiveYears) ||
      false,
    underFive:
      (Store.get('vehiclesInfo') &&
        Store.get('vehiclesInfo').isAnyVehicleUnderFiveYears) ||
      false,
    fromFacebook: Store.get('campaign_name') === 'facebook',
  };

  render() {
    const { customer } = this.state;
    return (
      <nav
        className="navbar navbar-expand-lg navbar-light bg-light p-0 dropShadow secondary-nav"
        data-search-exclude
      >
        <div className="slantedDiv">Service Products</div>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto navbarText p-3 p-lg-0">
            {customer.customerType === 2 && (
              <li className="nav-item pr-5">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/servicePlans"
                >
                  Service Plans <span className="sr-only">(current)</span>
                </NavLink>
              </li>
            )}
            {((customer.customerType !== 2 && this.state.overFive) ||
              this.state.fromFacebook) && (
              <li className="nav-item pr-5">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/essentialPlans"
                >
                  e<strong>sense</strong>tials{' '}
                  <span className="sr-only">(current)</span>
                </NavLink>
              </li>
            )}
            {customer.customerType === 2 && (
              <li className="nav-item pr-5">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/peaceOfMind"
                >
                  Peace Of Mind <span className="sr-only">(current)</span>
                </NavLink>
              </li>
            )}
          </ul>
        </div>
      </nav>
    );
  }
}
