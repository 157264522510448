import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import './login.css';
import Store from 'store';

export default class Incentives extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedIncentive: 0,
      incentiveCode: -1,
      savedSuccess: false,
    };
  }

  componentDidMount() {
    //make call to check incentives here
    this.checkIncentives();
  }

  checkIncentives() {
    fetch(
      process.env.REACT_APP_API_URL +
        '/HyundaiUk/Incentive/Get?key=' +
        Store.get('loginToken').replace(/['"']+/g, ''),
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          this.handleLoginError();
          throw new Error('Error in activating customer');
        }
      })
      .then((responseText) => {
        var selected = 0;
        if (responseText === 3 || responseText === 4) {
          selected = responseText;
        }
        this.setState({
          incentiveCode: responseText,
          selectedIncentive: selected,
        });
      })
      .catch((error) => {
        console.log('Error in getting Customer info ');
      });
  }

  redeem = () => {
    fetch(
      process.env.REACT_APP_API_URL +
        '/HyundaiUk/Incentive/Post?key=' +
        Store.get('loginToken').replace(/['"']+/g, '') +
        '&Incentive=' +
        this.state.selectedIncentive,
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(''),
      },
    )
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            savedSuccess: true,
          });
        } else {
          this.handleLoginError();
          throw new Error('Error in activating customer');
        }
      })
      .catch((error) => {
        console.log('Error in activating customer ');
      });
  };

  optionChanged = (e) => {
    this.setState({
      selectedIncentive: e.target.value,
    });
  };

  render() {
    if (
      this.state.savedSuccess ||
      (this.state.incentiveCode !== 4 && this.state.incentiveCode > -1)
    ) {
      return <Redirect to="/" />;
    }
    return (
      <div id="incentivesContainer" className="container">
        <div className="jumbotron p-10">
          <div>
            <div className="d-flex flex-column flex-sm-row justify-content-between text-center">
              <div>
                <img src="images/hyundai_logoF.png" style={{ maxHeight: 25 }} />
              </div>
              <div className="p-2 p-sm-0">
                <i className="fas fa-phone" />{' '}
                <a href="tel:0800981981" title="Call Us Now">
                  0800 981 981
                </a>
              </div>
            </div>
            <div className="p-3 p-sm-5">
              <p>Congratulations on activating your MyHyundai account. </p>

              {/* {this.state.incentiveCode === 1 && (
 
                <div className="jumbotron pb-5 bg-white" >
                <p>Please choose which gift you would like to receive.</p>
                <form>
                 <div className="form-group form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    checked={this.state.selectedIncentive === 3}
                    name="chooseIncentive"
                    id="donation"
                    value="3"
                    onChange={this.optionChanged}
                  />
                  <label className="form-check-label" for="donation">
                    £5 donation to charity
                  </label>
                </div>
                    <div className="form-group form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        checked={this.state.selectedIncentive === 4}
                        name="chooseIncentive"
                        id="flask"
                        value="4"
                        onChange={this.optionChanged}
                      />
                      <label className="form-check-label" for="flask">
                        Hyundai flask
                      </label>
                      <button
                        className="btn btn-primary btn-sm removeRadius float-right"
                        onClick={this.redeem}
                      >
                        Continue
                      </button>
                    </div>
                  </form>
                </div>
              )} */}

              {/* {this.state.incentiveCode === 3 && (
                <div>
                  <div className="jumbotron pb-5 bg-white"  >
                    <p>
                      Stand Up To Cancer will now receive a £5 donation on your
                      behalf.
                    </p>
                    <p>
                      Hyundai will process the donation within a few working
                      days.
                    </p>

                    <button
                      className="btn btn-primary btn-sm removeRadius float-right"
                      onClick={this.redeem}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              )} */}

              {this.state.incentiveCode === 4 && (
                //Hyundai flask
                <div>
                  <div className="jumbotron pb-5 bg-white">
                    <p>
                      Your mug will be posted to you via Royal Mail within 10
                      working days.
                    </p>

                    <button
                      className="btn btn-primary btn-sm removeRadius float-right"
                      onClick={this.redeem}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
