import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';

export default class GoEditions extends Component {
  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="GoEditions" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="goEditions-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage:
                  'url("images/productnews/goEditions/headerimage.png") ',
              }}
            />
          </div>
        </div>

        <div className="col bg-white border-right border-bottom">
          <div className="row py-3">
            <div className="col-lg-4">
              <hr />

              <NewsMedia articleId="GoEditions" articleText="Go Editions" />
            </div>
            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-8 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    On your marks, get set, Go
                  </p>
                </div>
              </div>
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    The 2018 FIFA World Cup <sup>&trade;</sup> kicks off in
                    Russia in mid-June and to celebrate Hyundai’s sponsorship of
                    the greatest sporting spectacle on earth, the i10, i20, i30
                    (both the five-door and Tourer) and Tucson are all available
                    as Go! models.
                  </p>
                  {displayShowMore && (
                    <p>
                      <a  
                        data-toggle="collapse"
                        href="#collapseGoEdition"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </div>

              <div className={moreSectionClassName} id="collapseGoEdition">
                <div className="row pt-3">
                  <div className="col-md-8 offset-md-1">
                    <p className="theme-news-body-text">
                      The strong limited-edition line up comes with high l high
                      levels of equipment at a great price, and includes
                      striking features such as a touchscreen navigation, a
                      unique interior upholstery design, a new wheel design (on
                      i20, i30, Tucson) and a unique exterior colour; Champion
                      Blue.
                    </p>
                  </div>
                </div>

                <div className="row pt-3">
                  <div className="col-md-8 offset-md-1">
                    <p className="theme-news-body-text">
                      The i10 and i20 variants are based on their SE equivalent,
                      while i30 and Tucson are based on the SE N SE Nav, with
                      the following additional equipment:
                    </p>
                  </div>
                </div>

                <div className="row pt-3">
                  <div className="col-md-8 offset-md-1">
                    <img
                      src="images/i10-Go.jpg"
                      className="w-100 mb-2"
                      alt="Hyundai i10"
                    />
                    <p className="subtitle">i10 Go! SE</p>
                    <p className="theme-news-body-text">
                      <strong>Exterior:</strong> 14” Alloy Wheels, Go! Emblem,
                      Gloss Black Door Mirrors, Privacy Glass, Black Side
                      Moulding <br />
                      <strong>Interior:</strong> Unique Seat pattern/stitching,
                      Black Headliner, Leather Steering Wheel and Gear Knob{' '}
                      <br />
                      <strong>Technology:</strong> Touchscreen Navigation with
                      Apple CarPlay + Android Auto
                    </p>
                  </div>
                </div>

                <div className="row pt-3">
                  <div className="col-md-8 offset-md-1">
                    <img
                      src="images/i20-Go.jpg"
                      className="w-100 mb-2"
                      alt="Hyundai i20"
                    />
                    <p className="subtitle">i20 Go! SE</p>
                    <p className="theme-news-body-text">
                      <strong>Exterior:</strong> 15” Alloy Wheels (new), Go!
                      Emblem, Gloss Black Door Mirrors, Privacy Glass, Black
                      Side Moulding, Projection Headlights, LED Daytime Running
                      Lights <br />
                      <strong>Interior:</strong> Unique Seat pattern/stitching,
                      Black Headliner, Armrest <br />
                      <strong>Technology:</strong> Touchscreen Navigation
                      Ventilation/Visibility: Automatic Headlights and Wipers,
                      Auto Dimming Rear View Mirror
                    </p>
                  </div>
                </div>

                <div className="row pt-3">
                  <div className="col-md-8 offset-md-1">
                    <img
                      src="images/i30-Go.jpg"
                      className="w-100 mb-2"
                      alt="Hyundai i30"
                    />
                    <p className="subtitle">i30 Go! SE</p>
                    <p className="theme-news-body-text">
                      <strong>Exterior:</strong> 17” Alloy Wheels (Fastback
                      version), Go! Go! Emblem, Gloss Black Door Mirrors,
                      Privacy Glass, Fastback Front Bumper <br />
                      <strong>Interior:</strong> Unique Seat pattern/stitching,
                      Black Headline <br />
                      <strong>Ventilation/Visibility:</strong> Climate Control
                      and Auto Defog
                    </p>
                  </div>
                </div>

                <div className="row pt-3">
                  <div className="col-md-8 offset-md-1">
                    <img
                      src="images/Tucson-Go.jpg"
                      className="w-100 mb-2"
                      alt="Hyundai Tucson"
                    />
                    <p className="subtitle">Tucson Go! SE</p>
                    <p className="theme-news-body-text">
                      <strong>Exterior:</strong> 19” Alloy Wheels (new), Go!
                      Emblem, Gloss Black Door Mirrors, Privacy Glass <br />
                      <strong>Interior:</strong> Unique Seat pattern/stitching,
                      Black Headliner, Dark Silver Interior Accent
                    </p>
                  </div>
                </div>

                <div className="row pt-3">
                  <div className="col-md-8 offset-md-1">
                    <p className="theme-news-body-text">
                      The Go! Special Editions are available now in a choice of
                      colours. For more information visit{' '}
                      <a href="http://go.hyundai.co.uk" target="_blank" rel="noopener noreferrer">
                        http://go.hyundai.co.uk
                      </a>
                    </p>
                  </div>
                </div>

                <div className="row pt-3">
                  <div className="col-md-8 offset-md-1">
                    <p className="theme-news-body-text">
                      Is Isn’t it time you scored yourself a winner?
                    </p>
                  </div>
                </div>

                <div className="row pt-3">
                  <div className="col-md-8 offset-md-1">
                    <p className="small">23/04/2018</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
