import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';

export default class KonaScot extends Component {
  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="KonaScot" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="konaScot-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/kona_scot1.jpg") ',
              }}
            />
            <div
              id="konaScot-gallery-2"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/kona_scot2.jpg")',
              }}
            />
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#konaScot-gallery-1"
                    style={{
                      backgroundImage: 'url("images/kona_scot1_th.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#konaScot-gallery-2"
                    style={{
                      backgroundImage: 'url("images/kona_scot2_th.jpg")',
                    }}
                  />
                </div>
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “Now, Kona can add (yet another!) award to its list of merits
                  thanks to its success at this year’s Scottish Car of the Year
                  Awards.”
                </p>
              </div>
              <hr />

              <NewsMedia
                articleId="konaScot"
                articleText="Kona’s Scot the lot!"
              />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    KONA’S SCOT THE LOT!
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    {' '}
                    Kona is turning heads across the UK with its style, economy
                    and practicality, not to mention the security of a five-year
                    warranty. Now, Kona can add (yet another!) award to its list
                    of merits thanks to its success at this year’s Scottish Car
                    of the Year Awards.
                  </p>
                  {displayShowMore && (
                    <p>
                      <a
                        data-toggle="collapse"
                        href="#collapsekonaScot"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                  {/* <p className="theme-news-body-text">The i30 Fastback’s sloping roofline, long bonnet and muscular body, along with its slim cabin and wide wheel arches give the car a strong stance. That strength, however, is far from being merely skin deep.</p> */}
                </div>
              </div>

              <div className={moreSectionClassName} id="collapsekonaScot">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      Kona came out on top in the highly competitive Best Small
                      Car category, beating the likes of the VW Polo, Ford
                      EcoSport, SEAT Arona, Kia Stonic and Citroen Aircross.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      "The Small Car category was the toughest of all to judge
                      this year with so many diverse new products arriving on
                      the market,” said John Murdoch, President of the
                      Association of Scottish Motoring Writers. “We selected the
                      Hyundai Kona because of its all-round package – it’s
                      spacious, practical, a great drive and its style certainly
                      stands out from the crowd."{' '}
                    </p>
                  </div>
                </div>

                {/* <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {" "}
                      The SUV will feature the 2.2 CRDi “R” engine,
                      which has been upgraded to further reduce
                      emissions, and will be available with front
                      wheel drive or 4WD, with a 6-speed manual
                      gearbox or an all-new 8-speed automatic
                                  transmission.{" "}
                    </p>
                  </div>
                </div> */}

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      And the Kona range continues to evolve with the addition
                      of a powerful and efficient diesel engine into the range
                      earlier this year, as well as an all-electric model
                      hitting dealerships in 2019.{' '}
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      “We are delighted to receive this award from the
                      Association of Scottish Motoring Writers,” said Kona
                      Product Manager Jamie Woods. “The choice of powertrains,
                      combined with Kona’s style, practicality and high level of
                      safety and driver assistance features ensures a strong
                      presence for the car in what is a highly competitive
                      marketplace.”{' '}
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      Kona Electric will be available in dealerships in 2019.
                      Find out more at{' '}
                      <a href="https://www.hyundai.co.uk/new-cars/kona">
                        www.hyundai.co.uk/new-cars/kona
                      </a>{' '}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
