import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';
import Store from 'store';
import ReactGA from 'react-ga';
export default class Ionic6Winner extends Component {
  componentDidMount = () => {};

  clickHandler = () => {};

  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="bestCarAwards-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/ionic6best.jpeg") ',
              }}
            />
            {/* <div
              id="bestCarAwards-gallery-2"
              className="theme-news-header-image "
              style={{
                backgroundImage: 'url("images/bca1.jpg") ',
              }}
            />
            <div
              id="bestCarAwards-gallery-3"
              className="theme-news-header-image "
              style={{
                backgroundImage: 'url("images/bca2.jpg") ',
              }}
            />
            <div
              id="bestCarAwards-gallery-4"
              className="theme-news-header-image "
              style={{
                backgroundImage: 'url("images/bca3.jpg") ',
              }}
            />
            <div
              id="bestCarAwards-gallery-5"
              className="theme-news-header-image "
              style={{
                backgroundImage: 'url("images/bca4.jpg") ',
              }}
            />
            <div
              id="bestCarAwards-gallery-6"
              className="theme-news-header-image "
              style={{
                backgroundImage: 'url("images/bca5.jpg") ',
              }}
            /> */}
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                {/* <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#bestCarAwards-gallery-2"
                    style={{
                      backgroundImage: 'url("images/bca1.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#bestCarAwards-gallery-3"
                    style={{
                      backgroundImage: 'url("images/bca2.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#bestCarAwards-gallery-4"
                    style={{
                      backgroundImage: 'url("images/bca3.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#bestCarAwards-gallery-5"
                    style={{
                      backgroundImage: 'url("images/bca4.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#bestCarAwards-gallery-6"
                    style={{
                      backgroundImage: 'url("images/bca5.jpg") ',
                    }}
                  /> */}
                {/* </div> */}
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “Hyundai IONIQ 6 is Euro NCAP’s ‘Best in Class’ Car 2022 in
                  the ‘Large Family Car’ category”
                </p>
              </div>
              <hr />

              <NewsMedia articleId="awards" articleText="awards" />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    HYUNDAI IONIQ 6 IS EURO NCAP'S 'BEST IN CLASS' CAR 2022 IN
                    THE 'LARGE FAMILY CAR' CATEGORY
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    {' '}
                    <ul>
                      <li>
                        Hyundai IONIQ 6 awarded ‘Best in Class’ of 2022 by Euro
                        NCAP in the ‘Large Family Car’ category
                      </li>
                      <li>
                        Hyundai’s Electrified Streamliner recently achieved
                        maximum five-star rating in Euro NCAP safety test
                      </li>
                      <li>
                        Newest model in Hyundai’s all-electric IONIQ line-up
                        brand excelled in ‘Adult Occupant’, ‘Child Occupant’ and
                        ‘Safety Assist’ categories
                      </li>
                      {/* <li>
                        SANTA FE named ‘Seven-seater of the Year’ and ‘Best
                        Plug-in Hybrid for Big Families’
                      </li>
                      <li>
                        Hailed for its “practicality, upmarket feel and comfort”
                      </li> */}
                    </ul>
                  </p>
                  {displayShowMore && (
                    <p>
                      <a
                        data-toggle="collapse"
                        href="#ioniq6"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </div>

              <div className={moreSectionClassName} id="ioniq6">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      <>
                        The European New Car Assessment Programme (Euro NCAP)
                        has announced the best-rated cars of 2022 in terms of
                        safety. Last year was the independent vehicle assessment
                        organisation’s busiest-ever year, as it tested 66 new
                        passenger cars. In a further demonstration of Hyundai’s
                        EV leadership, the company’s IONIQ 6 Electrified
                        Streamliner was awarded ‘Best in Class’ in the ‘Large
                        Family Car’ category.
                      </>
                      <br />
                      <br />
                      <strong>
                        "This latest Euro NCAP ‘Best in Class’ 2022 title for
                        IONIQ 6 follows on from last year’s maximum five-star
                        safety rating, and proves that it is one of the safest
                        EVs on the European marke. Hyundai is committed to
                        providing all road users with the highest level of
                        safety, while at the same time offering innovative
                        mobility solutions to our customers. This latest
                        accolade by Euro NCAP underscores our industry-leading
                        position in the field of future mobility."
                      </strong>{' '}
                      - Andreas-Christoph Hofmann, Vice President Marketing,
                      Product and PR at Hyundai Motor Europe
                      <br />
                      <br />
                      To define the Euro NCAP ‘Best in Class’, a calculation is
                      made of the weighted sum of the scores in each of the four
                      areas of assessment: ‘Adult Occupant Protection’, ‘Child
                      Occupant Protection’, ‘Vulnerable Road User Protection’
                      and ‘Safety Assist’. This sum is used by the organisation
                      as the basis for comparison of the vehicles. Cars qualify
                      for ‘Best in Class’ based only on their rating with
                      standard safety equipment. Additional ratings based on
                      optional equipment are excluded.
                    </p>
                  </div>
                </div>

                {/* <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      Early in January, the{' '}
                      <a
                        href="https://www.hyundai.news/uk/brand/hyundai-i10-is-what-cars-best-small-car-for-the-city-2021/"
                        target="_blank"
                      >
                        all-new i10 was named What Car?’s Best Small Car for the
                        City,
                      </a>{' '}
                      then the previous generation{' '}
                      <a
                        href="https://www.hyundai.news/uk/model-news/hyundai-scores-double-win-at-what-car-used-car-awards/"
                        target="_blank"
                      >
                        i20 was named as First Car’s Used Car of the Year
                      </a>{' '}
                      as a ‘safe, reliable and easy to drive’ car for new
                      drivers. Continuing Hyundai’s small car wins, carwow named
                      the i10 as its Best Small Car, saying: “it’s a doddle to
                      drive but still roomy enough for four adults and their
                      stuff. It’s big on technology, too.”
                    </p>
                  </div>
                </div> */}

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {/* <img src="images/bca1.jpg" className="img-fluid" /> */}
                      <span style={{ fontSize: '1rem' }}></span> IONIQ 6’s
                      victory in the ‘Large Family Car’ category follows on from
                      its successful Euro NCAP safety test last year. In
                      November 2022, it was announced that the latest model of
                      Hyundai’s all-electric IONIQ line-up brand was awarded
                      with the maximum five-star rating.
                      <br />
                      <br />
                      According to Euro NCAP, IONIQ 6 achieved “exceptionally
                      high results” in the ‘Adult Occupant Protection’ with a
                      score of 97 per cent, claiming first place in the
                      category. Meanwhile, the vehicle assessment organisation
                      praised Hyundai’s Electrified Streamliner in the field of
                      ‘Child Occupant Protection’, where it scored 87 per cent:
                      “IONIQ 6 provided good protection for all critical body
                      regions of both the 6- and 10-year dummies in the frontal
                      offset and side barrier tests and scored maximum points in
                      this part of the assessment”. The model also achieved a
                      high score of 90 per cent in the ‘Safety Assist’ section.
                      <br />
                      <br />
                      <strong>
                        <h1>
                          IONIQ 6 offers the highest levels of safety and
                          convenience on the road
                        </h1>{' '}
                      </strong>
                      <br />
                      IONIQ 6 is equipped with the next level of Hyundai Smart
                      Sense Advanced Driver Assistance Systems, ensuring the
                      highest levels of safety and convenience on the road.
                      These include Highway Driving Assist 2 (HDA 2), which
                      helps to maintain a set distance and speed from the
                      vehicle ahead when driving on a highway and helps to
                      centre the vehicle in the lane while driving, it can even
                      overtake the vehicle in front. HDA 2 allows IONIQ 6 to
                      reach Level 2 autonomous driving.
                      <br />
                      <br />
                      Meanwhile, Navigation-based Smart Cruise Control (NSCC)
                      helps to maintain distance from the vehicle ahead and
                      drive at a speed set by the driver by reflecting the
                      learned driving style of the driver. It also uses the
                      navigation system’s road information to optimise automated
                      driving and perform speed adjustment for safe driving
                      through corners and intersection. In addition, Hyundai’s
                      Electrified Streamliner is fitted with seven airbags as
                      standard, including a driver seat centre-side airbag for
                      additional passive safety.
                      {/* <img src="images/bca3.jpg" className="img-fluid" /> */}
                      <br />
                      <br />
                      {/* Additionally, the release early in January of the UK’s
                      latest car registration figures by the Society of Motor
                      Manufacturers and Traders revealed that Hyundai had
                      recorded its largest ever market share of 5.0% in 2022. */}
                      {/* <img src="images/bca4.jpg" className="img-fluid" /> */}
                      {/* <br />
                      <br /> */}
                      {/* <strong>
                        "The awards from What Car? are particularly pleasing as
                        they illustrate the depth of Hyundai’s current range of
                        vehicles. Our N cars have created a massive impact in a
                        short space of time, and the i20 N is a fantastic
                        example of an exciting performance model that can be
                        comfortably driven on a daily basis, while the SANTA FE
                        offers a fantastic blend of practicality, comfort and
                        electrification for SUV customers."
                      </strong>{' '}
                      - Ashley Andrew, Managing Director of Hyundai Motor UK */}
                      {/* <br />
                      <br /> */}
                      {/* Showcasing the strength of the full model range, a sixth
                      award was presented to Hyundai as the i20 N won ‘Best Hot
                      Hatchback’ of the year.
                      <br />
                      <br />
                      <strong>Richard Ingram said:</strong> “Hyundai has turned
                      the hot hatch market on its head – first with the i30 N
                      and more recently with the smaller but just as riotous i20
                      N. Frenetic, agile and quick, the compact Hyundai will
                      keep up with most sports cars but with the added benefit
                      of five doors and a big boot.”
                      <br />
                      <br />
                      <img src="images/bca5.jpg" className="img-fluid" />
                      <br />
                      <br />
                      Like every driver-focused Hyundai N model, the i20N was
                      born at Namyang, Hyundai Motorsport’s Global Research &
                      Development Centre in South Korea, and honed on the
                      Nürburgring Nordschleife race circuit in Germany.
                      Featuring a potent 204PS turbocharged petrol engine and a
                      mechanical limited-slip differential, the i20N delivers
                      stunning performance and ultra-agile handling on road and
                      track. Pioneering connected and safety technologies
                      enhance every journey. Like every Hyundai model sold in
                      the UK, the i20N provides a five-year unlimited mileage
                      warranty, five annual vehicle health checks and roadside
                      assistance. Pricing begins at £24,995.
                      <br />
                      <br />
                      <strong>
                        Ashley Andrew, Managing Director of Hyundai Motor UK
                        said:
                      </strong>
                      “We are delighted that the Hyundai TUCSON has been
                      presented with the coveted ‘Car of the Year’ award by the
                      panel of experts at Carbuyer. This endorsement is further
                      validation that the latest generation TUCSON remains one
                      of the best SUVs in the UK market, as well as the best
                      hybrid available. We are also thrilled to see the wider
                      Hyundai range recognised across a number of prestigious
                      honours. IONIQ 5 continues to set the standard for
                      electric mobility, whilst the i20 N is recognised as the
                      best hot hatchback yet again.”
                      <br />
                      <br /> */}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
