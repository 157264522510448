import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';
import Store from 'store';
import ReactGA from 'react-ga';
export default class OneMillionPound extends Component {
  componentDidMount = () => {};

  clickHandler = () => {};

  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="oneMillions" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="oneMillion-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/onemillionheader.jpeg") ',
              }}
            />
            {/* <div
              id="Bayon-gallery-2"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/hyundaielectric2.jpg")',
              }}
            /> */}
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                {/* <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#Bayon-gallery-2"
                    style={{
                      backgroundImage: 'url("images/hyundaielectric2.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#Bayon-gallery-1"
                    style={{
                      backgroundImage: 'url("images/hyundaielectric1.jpg")',
                    }}
                  />
                </div> */}
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “Hyundai pledges £1m for school trips to remember”
                </p>
              </div>
              <hr />

              <NewsMedia articleId="awards" articleText="awards" />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    HYUNDAI PLEDGES £1M FOR SCHOOL TRIPS TO REMEMBER
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    {' '}
                    <ul>
                      <li>
                        Almost half of parents admit their fondest childhood
                        memories include school trips
                      </li>
                      <li>
                        With over a third confessing these outings impacted
                        their future career path
                      </li>
                      <li>
                        Hyundai is investing £1 million in a programme of school
                        trips in order to prevent children missing out on such
                        experiences
                      </li>
                      <li>
                        Bookings are now open for the initiative, which has
                        launched with Hyundai’s Educator in Residence, Tim
                        Campbell MBE
                      </li>
                    </ul>
                  </p>
                  {displayShowMore && (
                    <p>
                      <a
                        data-toggle="collapse"
                        href="#oneMillion"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </div>

              <div className={moreSectionClassName} id="oneMillion">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      <strong>
                        Nearly half of parents (46%) admit that their fondest
                        childhood memories included school trips.
                      </strong>
                      <br />
                      <br />A study of 1,600 parents of school aged children,
                      commissioned by leading car manufacturer, Hyundai, has
                      revealed that 44% feel nostalgic about their formative
                      years and trips outside the classroom. Over a third (32%)
                      also admit that school trips had such an impact that they
                      shaped their future career path.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {/* <img src="images/ponyexib2.jpg" className="img-fluid" /> */}
                      Two thirds (65%) of those surveyed confess that they wish
                      they had appreciated school trips and their benefits more
                      when they were at school, with just under three quarters
                      (72%) revealing that they want their children to have the
                      opportunity to go on more school trips.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      Nearly two fifths (39%) also recognise there is a chance
                      their youngster won’t be offered the opportunity to go on
                      school trips during the 2022 to 2023 academic year due to
                      lack of school funding and volunteers.
                      <br />
                      <br />
                      This research has been commissioned by Hyundai to launch
                      its ‘Great British School Trip’ programme. The ambitious
                      initiative will provide 25,000 school children aged seven
                      to 14 with free school trips in 2023. The automotive
                      company will offer bursaries to help the schools most in
                      need to fund their school trips, including booking fees
                      and travel costs.
                      <br />
                      <br />
                      <strong>
                        {' '}
                        "When you’re a child, it’s great to get out and about to
                        bring your learning to life, so it’s no wonder it’s such
                        a key memory for so many. Getting out of the classroom
                        not only creates memories but fundamentally also brings
                        a sense of excitement to a particular school subject.
                        Also, school trips are often the first opportunity for a
                        child to gain a sense of independence so it’s no
                        surprise that many people remember the small things like
                        reserving the back of the bus and spending time with
                        friends in a new environment. We firmly believe that
                        school trips are an essential part of our young people’s
                        development and something that every child should have
                        access to, creating the same memories as their parents"
                      </strong>{' '}
                      - Ashley Andrew Managing Director, Hyundai Motor UK
                      <br />
                      <br /> Through the ambitious initiative, Hyundai is
                      investing £1 million in the programme, which has been
                      shaped around Hyundai’s vision for the future, Progress
                      for Humanity. This vision focuses on how connections and
                      collaboration can help accelerate progress and innovation.
                      The programme will be centred around four themes:
                      Imagination and Curiosity, People and the Planet, Journeys
                      and Adventures and Breakthroughs and Discoveries.
                      <br />
                      <br /> The research drew attention to the benefits of
                      school outings, with the opportunity to visit places
                      outside of the classroom cited as the main advantage, as
                      well as being able to link topics and subjects in a more
                      meaningful way and allowing children to contextualise
                      their classroom learnings.
                      {/* <a
                        href="https://motorstudio.hyundai.com/busan/ln/main.do?strgC=04&lang=en"
                        target="_blank"
                      >
                        Hyundai Motorstudio Busan
                      </a>{' '}
                      , the ‘Reflections in Motion’ exhibition invites visitors
                      to explore and reflect on the interactive relationships
                      between time and design as well as technology and styling,
                      with various featured installations underscoring Hyundai
                      Motor’s vision centered on enabling ‘Progress for
                      Humanity’. */}
                      <br />
                      <br />
                      Over 350 venues have shown their support for The Great
                      British School Trip by signing up as trip locations
                      already. Children will be able to explore locations
                      including Peak Activity Centres, The YHA, Disney
                      Theatrical and The V&A, amongst others.
                      <br />
                      <br />
                      Working together on the initiative, Tim Campbell MBE is
                      Hyundai’s Educator in Residence. Tim sits on the City of
                      London Education board and is Chair of Governors at an
                      outstanding London secondary school. Tim recently visited
                      Ghyllgrove Primary School in Essex to discuss the
                      importance of school trips and chat about his own memories
                      from his school days. Speaking about the programme, Tim
                      said:
                      <br />
                      <br />
                      <strong>
                        "School trips are an integral part of a child’s
                        educations, and I remember my own outings with such fond
                        memories, even all these years later! What’s more, being
                        able to turn these trips into further learning within
                        the classroom is such an important skill and something
                        that should be available for everyone. This programme is
                        extremely timely and will be crucial in putting school
                        trips firmly back on the agenda"
                      </strong>{' '}
                      - Tim Campbell Hyundai's Educator in Residence;
                      <br />
                      <br />
                      Bookings for The Great British School Trip are now open
                      where teachers can select a school trip location. To find
                      out more visit:{' '}
                      <a
                        href="https://blog.hyundai.co.uk/experiences/hyundai-csv/hyundai-pledges-1m-for-school-trips-to-remember"
                        target="_blank"
                      >
                        https://blog.hyundai.co.uk/experiences/hyundai-csv/hyundai-pledges-1m-for-school-trips-to-remember
                      </a>
                      <br />
                      <br />
                      <br />
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
