import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';

export default class SantaFe extends Component {
  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="SantaFe" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="santafe-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/santafe1.jpg") ',
              }}
            />
            <div
              id="santafe-gallery-2"
              className="theme-news-header-image"
              style={{
                backgroundImage:
                  'url("images/santafe2.jpg "), url(" images/solidGrey.jpg") ',
              }}
            />
            <div
              id="santafe-gallery-3"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/santafe3.jpg ") ',
              }}
            />
          </div>
        </div>

        <div className="col bg-white border-right border-bottom">
          <div className="row py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a  
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#santafe-gallery-1"
                    style={{
                      backgroundImage: 'url("images/santafe1.jpg")',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a  
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#santafe-gallery-2"
                    style={{
                      backgroundImage: 'url("images/santafe2.jpg")',
                    }}
                  />
                </div>
                {/* <div className="theme-news-thumbnail-container img-thumbnail">
                  <a  
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#santafe-gallery-3"
                    style={{
                      backgroundImage:
                        'url("images/santafe3.jpg")'
                    }}
                  />


                </div> */}
              </div>
              <div className="mt-3">
                <p className="theme-news-snippet">
                  “The UK loves SUVs – more and more are being bought every
                  year. Fortunately for the nation’s car drivers, Hyundai’s
                  range of SUVs is continuing to grow. ”
                </p>
              </div>
              <hr />
              <NewsMedia articleId="SantaFe" articleText="Santa Fe" />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    TALKIN 'BOUT MY GENERATION
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p
                    className="theme-news-body-text"
                    data-search-tag="summary"
                  >
                    The UK loves SUVs – more and more are being bought
                    every year. Fortunately for the nation’s car
                    drivers, Hyundai’s range of SUVs is continuing to
                    grow. Kona was launched into the fast-growing
                    sub-compact SUV category last year, and expands
                    its range in 2018 with the launch of both diesel
                    and electric versions. The much-loved Tucson,
                    meanwhile, has been given a refresh for 2018, and
                    now we can announce that the New Generation Santa
                    Fe will go on sale on 01 September.
                            </p>
                  {displayShowMore && (
                    <p>
                      <a  
                        data-toggle="collapse"
                        href="#collapseSantaFe"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </div>

              <div className={moreSectionClassName} id="collapseSantaFe">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      Hyundai’s headlining SUV has been restyled and redesigned,
                      both inside and out, while an array of new equipment,
                      safety and powertrain features have also been included.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      “All new from the ground up, the New Generation Santa Fe
                      represents a huge step change for the model,” says Tony
                      Whitehorn, President and CEO Hyundai Motor UK. “With a new
                      exterior and interior design, elevated equipment levels
                      and premium levels of refinement, the New Generation Santa
                      Fe reasserts its position at the top of the Hyundai
                      range.”{' '}
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      The SUV will feature the 2.2 CRDi “R” engine, which has
                      been upgraded to further reduce emissions, and will be
                      available with front wheel drive or 4WD, with a 6-speed
                      manual gearbox or an all-new 8-speed automatic
                      transmission.{' '}
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      Cutting-edge technology fitted to the 8-speed automatic
                      gearbox gives improved control in corners by tailoring
                      upshifts and engine braking to specific driving
                      conditions. Meanwhile, on 4WD models HTRAC, an evolution
                      of Hyundai’s “Torque on Demand” 4WD system, gives the
                      driver manual control over torque distribution with the
                      introduction of three 4WD modes.{' '}
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      Away from the engine, the roomy New Generation Santa Fe
                      comes packed with standard kit: seven seats, roof rails,
                      front and rear parking sensors with rear view camera,
                      privacy glass, dual zone climate control, heated front
                      seats, driver’s seat height adjustment, leather steering
                      wheel, automatic windscreen wipers, DAB radio with Apple
                      CarPlay™ and Android Auto™, cruise control, Lane Keep
                      Assist, Autonomous Emergency Braking and Smart High Beam
                      Assist... the list goes on!{' '}
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      Prices will start from £33,425 for the SE 2.2 CRDi 200PS
                      6-speed 2WD manual and will increase to £36,995 (starting
                      price) for the Premium 2.2 CRDi 200PS 6-speed 2WD manual
                      and £41,495 (starting price) for the top-of-the-range
                      Premium SE 2.2 CRDi 200PS 8-speed automatic 2WD.{' '}
                    </p>
                    <p>
                      For more specific information on each variant’s kit and
                      specification, please click{' '}
                      <a  
                        href="https://www.hyundai.co.uk/new-cars/santa-fe-2017"
                        target="_blank" rel="noopener noreferrer"
                      >
                        here
                      </a>
                      .{' '}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
