import React, { Component } from 'react';
import './contactPref.css';
import FooterNavbar from '../components/footerNavbar';
import Topnavbar from '../components/topnavbar';
import Bottomnavbar from '../components/bottomnavbar';
import Footer from '../components/footer';
import OffersSideNav from '../components/offersSideNav';

export default class OffersMerchandise extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Topnavbar />
        <Bottomnavbar />

        <div className="container">
          {/* <ProfileNav /> */}

          <section
            className="section1 wow fadeIn"
            data-wow-duration="2s"
            data-wow-delay="0.2s"
          >
            <div className="content pb-5  p-md-5 col-md-10 offset-md-1 border-right border-bottom">
              <div className="row">
                <div className="col-md-3 mt-4 border-md-right">
                  <OffersSideNav />
                </div>

                <div className="col-md-9    p-md-4  contact-perf-content">
                  <h4 className="h4ServHistory" data-search-tag="title">
                    It's not just our cars that are great value
                  </h4>

                  <hr />
                  <div>
                    <img
                      className="card-img-top img-fluid"
                      src="images/november_offers2.jpg"
                    />
                  </div>
                  <br />
                  <p className="remindersBlue" data-search-tag="summary">
                    If you like to take your hot drink with you on a journey or
                    stay dry when you arrive or keep your picnic cool, we've got
                    a range of genuine merchandise to complement your Hyundai
                    perfectly. Especially with 10%* off for MyHyundai members.
                    The discount will need to be applied to your shopping
                    basket.
                  </p>
                  <p className="remindersBlue">
                    Your code is<strong> MYHYUNDAI</strong>
                  </p>

                  <a  
                    href="https://accessories.hyundai.co.uk/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {' '}
                    <button className="btn btn-sm removeRadius btn-primary">
                      Shop now
                    </button>
                  </a>
                  <p className="small mt-3">
                    *10% discount applies to the full RRP and does not include
                    fitting. Valid online only until 31st March 2019.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <FooterNavbar />
        </div>

        <Footer />
      </div>
    );
  }
}
