import React, { Component } from 'react';
import WHNItem from './whnItem';
import { Link } from 'react-router-dom';
import GetData from '../utils/GetData';
import Store from 'store';
import DateFormat from '../utils/DateFormat';
import './whnLogin.css';
import NavBar from './newsletter/loginPageNavbar';
import ReactGA from 'react-ga';

export default class whnLogin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      articles: [],
    };
  }

  async componentDidMount() {
    ReactGA.event({
      label: 'News Articles',
      category: 'WHN',
      action: `News Article Login Page loaded`,
      gaOptions: {
        siteSpeedSampleRate: 100,
      },
    });

    await GetData.getArticles();
    const articlesList = Store.get('articles');
    let articlesFiltered = [];
    if (articlesList.length > 6) {
      articlesFiltered = articlesList.splice(0, 6);
    } else {
      articlesFiltered = articlesList;
    }

    this.setState({
      articles: articlesFiltered,
    });
  }

  render() {
    return (
      <div>
        {/* <div className="container pb-2 " style={{ backgroundColor: '#F5F5F5' }}>
          <hr />
          <NavBar />
          <hr />
          <div className="row pl-3 pr-3">
            {this.state.articles.map((article, i) => (
              <WHNItem
                className="card col-lg-4 col-md-6 col-sm-12 pl-2 pr-2 article-from-login"
                bodytop="card-body pt-2 ml-2 mr-2 body-from-login"
                bodybottom="card-text-div pt-3 pb-3 pl-4 pr-4 ml-2 mr-2 text-from-login row"
                startDate={DateFormat.formatDateFromApi(article.startDate)}
                category={article.category}
                title={article.previewPage.title}
                maintitle={article.mainPage.name}
                src={article.previewPage.mainImage}
                mainsrc={article.mainPage.mainImage}
                text={article.mainPage.html}
                alt={article.name}
                id={article.id}
                related={article.relatedToIDs}
                key={i}
              />
            ))}
          </div>
          <Link
            id="moreArticlesButton"
            className="btn btn-primary btn-block removeRadius col-lg-2 col-md-4 col-sm-6 pt-2 m-3"
            to="/newsletter"
          >
            See more articles
          </Link>
        </div> */}
      </div>
    );
  }
}
