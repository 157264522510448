import React, { Component } from 'react';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';

const style = {
  width: '90%',
  height: '100%',
};

export class GoogleMap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      map: null,
      dealer: props.dealer,
      home: null,
      dealers: props.dealers,
      geoCodeFailed: '',
    };

    this.handleGoogleMapReady = this.handleGoogleMapReady.bind(this);
  }

  componentDidUpdate() {
    var me = this;
    if (
      this.props.locationSearch != null &&
      this.props.locationSearch.length !== 0 &&
      this.props.locationSearch !== this.state.geoCodeFailed
    ) {
      if (
        this.state.home == null ||
        this.state.home.name !== this.props.locationSearch
      ) {
        var geocoder = new this.props.google.maps.Geocoder();
        geocoder.geocode(
          {
            address: this.props.locationSearch,
            region: 'GB',
          },
          function(results, status) {
            if (status === 'OK') {
              me.handleHomeGeocodeResult(results[0].geometry.location);
            } else {
              me.setState({
                geoCodeFailed: me.props.locationSearch,
              });
            }
          },
        );
      }
    } else {
      if (this.state.home != null) {
        this.setState({
          home: null,
          dealer: this.props.dealer,
        });
      } else if (
        this.props.dealer != null &&
        this.state.dealer != null &&
        this.props.dealer.code !== this.state.dealer.code
      ) {
        this.setState({
          home: null,
          dealer: this.props.dealer,
        });
      }
    }
  }

  handleHomeGeocodeResult(latlng) {
    this.setState(
      {
        home: {
          latLng: latlng,
          name: this.props.locationSearch,
        },
        dealer: null,
      },
      this.findNearestDealer,
    );
  }

  findNearestDealer() {
    var me = this;
    const dealers = this.getClosest25();
    const destinations = dealers.map((dealer) => ({
      lat: dealer.extra.aftersalesLatitude,
      lng: dealer.extra.aftersalesLongitude,
    }));

    var distanceMarixService = new this.props.google.maps.DistanceMatrixService();
    distanceMarixService.getDistanceMatrix(
      {
        origins: [this.state.home.latLng],
        destinations: destinations,
        travelMode: 'DRIVING',
        unitSystem: this.props.google.maps.UnitSystem.IMPERIAL,
        avoidHighways: false,
        avoidTolls: false,
      },
      function(response, status) {
        if (status !== 'OK') {
          console.log('Error getting distances: ' + status);
        } else {
          if (
            response.rows != null &&
            response.rows.length >= 0 &&
            response.rows[0].elements != null &&
            response.rows[0].elements.length > 0
          ) {
            var results = response.rows[0].elements;

            var nearest = { index: -1, distance: 999999 };

            for (var i = 0; i < results.length; i++) {
              var result = results[i];

              if ((result.status = 'OK')) {
                if (result.distance.value < nearest.distance) {
                  nearest.distance = result.distance.value;
                  nearest.index = i;
                }
              }
            }

            const closestDealer = dealers[nearest.index];
            me.setState(
              {
                dealer: closestDealer,
              },
              function() {
                if (this.props.onDealerSearchResultFound) {
                  this.props.onDealerSearchResultFound(closestDealer);
                }
              },
            );
          }
        }
      },
    );
  }

  getClosest25() {
    const homeLat = this.state.home.latLng.lat();
    const homeLng = this.state.home.latLng.lng();

    var roughDistances = this.state.dealers.map((dealer) => ({
      dealer: dealer,
      distance: this.calculateRoughDistance(
        homeLat,
        homeLng,
        dealer.extra.aftersalesLatitude,
        dealer.extra.aftersalesLongitude,
      ),
    }));

    var sorted = roughDistances.sort((a, b) => a.distance - b.distance);
    return sorted.slice(0, 25).map((item) => item.dealer);
  }

  calculateRoughDistance(lat1, lon1, lat2, lon2) {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    return dist * 1.609344; //kilometers
  }

  getDealerLatLong() {
    if (this.state.dealer != null && this.state.dealer.extra != null) {
      return {
        lat: this.state.dealer.extra.aftersalesLatitude,
        lng: this.state.dealer.extra.aftersalesLongitude,
      };
    }
  }

  handleGoogleMapReady(mapProps, map) {
    this.setState({
      map: map,
    });

    this.adjustMap();
  }

  adjustMap() {
    if (this.state.map) {
      var dealerLatLong = this.getDealerLatLong();

      if (this.state.home != null) {
        var bounds = new this.props.google.maps.LatLngBounds();
        bounds.extend(this.state.home.latLng);

        if (dealerLatLong != null) {
          bounds.extend(dealerLatLong);
        }

        this.state.map.fitBounds(bounds);
      } else if (dealerLatLong != null) {
        this.state.map.setCenter(dealerLatLong);
        this.state.map.setZoom(12);
      } else {
        this.state.map.setCenter(this.props.centre);
        this.state.map.setZoom(6);
      }
    }
  }

  render() {
    this.adjustMap();
    const dealerLatLong = this.getDealerLatLong();

    return (
      <div>
        <div>
          <Map
            google={this.props.google}
            initialCenter={this.props.centre}
            zoom={6}
            style={style}
            onReady={this.handleGoogleMapReady}
          >
            {dealerLatLong != null && (
              <Marker
                title={this.state.dealer.name}
                name={this.state.dealer.name}
                position={dealerLatLong}
              />
            )}

            {this.state.home != null && (
              <Marker
                title={this.state.home.name}
                name={this.state.home.name}
                position={this.state.home.latLng}
                icon={{
                  url:
                    'https://chart.googleapis.com/chart?chst=d_map_pin_icon&chld=home|00990A|000000',
                }}
              />
            )}
          </Map>
        </div>
        {this.state.geoCodeFailed.length !== 0 && (
          <p className="text-danger text-center">
            <small>
              <i className="fas fa-exclamation-triangle" /> Unable to find "
              {this.props.locationSearch}", please try again
            </small>
          </p>
        )}
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyAQC0crnSO_67mfDqiEcyJnPIyIQNrnseU',
})(GoogleMap);
