import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';

export default class BestEV extends Component {
  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="BestEV" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="bestEV-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/bestev.jpg") ',
              }}
            />
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#bestEV-gallery-1"
                    style={{
                      backgroundImage: 'url("images/bestev_th.jpg") ',
                    }}
                  />
                </div>
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “Praised by Kona Electric owners and users of HonestJohn.co.uk
                  for its range of up to 279 miles and driveability.”
                </p>
              </div>
              <hr />

              <NewsMedia
                articleId="bestEV"
                articleText="Motoring mega-mag chooses Hyundai"
              />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    KONA ELECTRIC WINS 'BEST EV' TITLE AT 2019 HONEST JOHN
                    AWARDS
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    {' '}
                    Hyundai Motor wins new ‘Best EV’ category with Kona Electric
                    at 2019 Honest John Awards. Praised by Kona Electric owners
                    and users of HonestJohn.co.uk for its range of up to 279
                    miles and driveability.
                  </p>
                  {displayShowMore && (
                    <p>
                      <a
                        data-toggle="collapse"
                        href="#collapsebestEV"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                  {/* <p className="theme-news-body-text">The i30 Fastback’s sloping roofline, long bonnet and muscular body, along with its slim cabin and wide wheel arches give the car a strong stance. That strength, however, is far from being merely skin deep.</p> */}
                </div>
              </div>

              <div className={moreSectionClassName} id="collapsebestEV">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      3 May 2019 - Hyundai’s Kona Electric wins the brand new
                      award category of ‘Best EV’ at last night’s Honest John
                      Awards, as voted for by users of HonestJohn.co.uk.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      Hyundai’s Kona Electric wins the brand new award category
                      of ‘Best EV’ at last night’s Honest John Awards, as voted
                      for by users of{' '}
                      <a href="https://www.honestjohn.co.uk/" target="_blank">
                        HonestJohn.co.uk
                      </a>
                      .
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      The Hyundai Kona is a true electric game-changer. Sensibly
                      priced, easy to drive and capable of covering up to
                      279-miles on a single charge, Hyundai has delivered the
                      electric crossover that car buyers want. Over the past 12
                      months the Kona’s popularity has soared with our readers
                      and powered its way to the top of electric car table.
                      David Ross, Honest John Editor
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      Kona Electric offers the very latest in battery electric
                      powertrain and connected technology, powered by a 150kW
                      (204ps), or a 100kW (136ps) electric motor driving the
                      front wheels. Prices start from £30,750 on the road for
                      the 39kWh and £36,345 for the 64kWh (excluding Plug-in Car
                      Grant PICG which is currently £3,500).
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      The fact that Kona Electric has been recognised as Best EV
                      by the users of HonestJohn.co.uk is a major accolade and
                      we couldn’t be more delighted. The car is innovative,
                      affordable, and a real game changer in the world of
                      electric vehicles. Hyundai is committed to becoming a
                      leader in electrification, with plans to become a global
                      top three EV manufacturer by 2025. Ashley Andrew, Hyundai
                      Motor UK Managing Director
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      The Kona benefits from Hyundai’s comprehensive five-year
                      unlimited mileage warranty and roadside assistance
                      package. The car is packed with the latest equipment as
                      standard, including the latest active safety features,
                      such as Forward Collision-Avoidance Assist and Lane
                      Keeping Assist.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      Now in its ninth consecutive year, the Honest John Awards
                      don’t follow the traditional awards format; instead the
                      winners are chosen by the best experts of all: the website
                      users. The awards recognise and reward the cars that
                      HonestJohn.co.uk users search for most on the site. The
                      reviews which create the most interest among
                      HonestJohn.co.uk users are the winners.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
