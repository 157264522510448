import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';
import Store from 'store';
import ReactGA from 'react-ga';
export default class CustomerBrand extends Component {
  componentDidMount = () => {};

  clickHandler = () => {};

  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="customerBrand-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/customerbrandheader.png") ',
              }}
            />
            <div
              id="customerBrand-gallery-2"
              className="theme-news-header-image "
              style={{
                backgroundImage: 'url("images/customerbrand1.png") ',
              }}
            />
            {/* <div
              id="Bayon-gallery-2"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/hyundaielectric2.jpg")',
              }}
            /> */}
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#customerBrand-gallery-2"
                    style={{
                      backgroundImage: 'url("images/customerbrand1.png") ',
                    }}
                  />
                </div>
                {/* <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#Bayon-gallery-1"
                    style={{
                      backgroundImage: 'url("images/hyundaielectric1.jpg")',
                    }}
                  />
                </div> */}
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “Hyundai delivers leading customer brand experience in 2021”
                </p>
              </div>
              <hr />

              <NewsMedia articleId="awards" articleText="awards" />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    HYUNDAI DELIVERS LEADING CUSTOMER BRAND EXPERIENCE IN 2021
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    {' '}
                    <ul>
                      <li>
                        Hyundai achieves highest brand Reputation score in the
                        UK, ahead of 29 competitors with 12% increase from 2020
                      </li>
                      <li>
                        The 2021 Automotive Reputation Report ranks top
                        dealerships and brands across Europe, analysing 5
                        million customer-generated ratings and reviews
                      </li>
                      <li>
                        Review volumes for 2021 higher than ever. Nearly 80% of
                        consumers say reviews are important and 41% read at
                        least five reviews before visiting a dealership.
                      </li>
                      <li>
                        2021 has seen strong consumer interest and demand across
                        latest Hyundai models, including new Tucson and IONIQ 5
                      </li>
                    </ul>
                  </p>
                  {displayShowMore && (
                    <p>
                      <a
                        data-toggle="collapse"
                        href="#customerBrand"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </div>

              <div className={moreSectionClassName} id="customerBrand">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      15 October 2021 -{' '}
                      <strong>
                        Hyundai Motor UK has recorded its highest-ever
                        performance in the 2021 Automotive Reputation Report,
                        taking the top spot for Brand Reputation with a score of
                        715 out of 1,000 and second highest performance for
                        visibility.
                      </strong>
                      <br />
                      <br />
                      Reputation, the global leader in reputation experience
                      management has unveiled findings from its annual
                      automotive industry report, looking at key industry trends
                      from the past year and ranking the top auto brands,
                      dealerships, and dealer groups across the UK.
                      <br />
                      <br /> As part of the report, Hyundai leads the way for
                      brands in the UK, with a 12% rise on 2020 brand reputation
                      performance, whilst also delivering the highest sentiment
                      score at 83%. This acts as recognition of the hard work
                      across the dealer network under challenging market
                      conditions.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      <img
                        src="images/customerbrand1.png"
                        className="img-fluid"
                      />
                      <br />
                      <br />
                      Analysing 15,000 automotive brands and dealerships across
                      Europe, Reputation also found that reviews are more
                      important than ever, With record volumes for 2021. Nearly
                      80% of consumers say reviews are important and 64%
                      surveyed say they would travel more than 20 miles to a
                      top-rated dealership.
                      <br />
                      <br />{' '}
                      <strong>
                        Ashley Andrew, Managing Director of Hyundai Motor UK
                        said:
                      </strong>{' '}
                      “We are delighted to see the Hyundai brand performing so
                      strongly in the 2021 Automotive Reputation Report,
                      demonstrating the hard work of our dealers in delivering a
                      class leading customer experience. Hyundai dealers
                      recognise the role that online review presence can play in
                      driving positive customer experience and we continue to
                      focus on delivering the best experience across our
                      network.” <br />
                      <br />
                      <strong>
                        Jason Grier, Chief Customer Officer of Reputation said:
                      </strong>
                      “After the disruption the automotive industry has faced
                      this year—from global pandemic uncertainty to surging
                      demand and eroding margins—it’s more important than ever
                      to deliver a positive consumer experience and manage
                      online reputation, Hyundai has set itself apart in this
                      regard, solidifying its reputation and ranking as one of
                      the top brands in the UK.”
                      <br />
                      <br />{' '}
                    </p>
                  </div>
                </div>

                {/* <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      <img
                        src="images/customerBrand2.png"
                        className="img-fluid"
                      />
                      <br />
                      <br />
                      <strong>
                        Martin Potter, Aston Barclay’s Managing Director said:
                      </strong>
                      “Our latest index highlights the increased desire for
                      electric and hybrids as used cars. More consumers are
                      considering owning EVs for the first time which is
                      increasing the demand in the wholesale market. With the
                      extended lead times currently facing the industry,
                      consumers are maintaining their focus on the premium used
                      car market. Dealers continue to compete for the same stock
                      to satisfy consumer demand which is fuelling price rises.”
                      <br />
                      <br />
                      <img
                        src="images/customerBrand3.png"
                        className="img-fluid"
                      />
                      <br />
                      <br />
                      Aston Barclay’s index takes into consideration three key
                      metrics: web views prior to sale, number of physical and
                      online bids per sale, and the sale price achieved as a
                      percentage of CAP average.
                      <br />
                      <br />
                      <strong>
                        {' '}
                        Ashley Andrew, Managing Director Hyundai Motor UK, said:
                      </strong>{' '}
                      “To see such heightened consumer interest and demand for
                      our no-compromise approach with our EV line-up is great to
                      see. IONIQ 5 cements Hyundai as pioneers in the
                      electrified mobility space and as we journey towards
                      decarbonising road transport we look to take consumers
                      with us on the road ahead to a cleaner World.”
                      <br />
                      <br />
                      <img src="images/customerBrand4.png" className="img-fluid" />
                      <br />
                      <br />
                      <strong>
                        Ashley Andrew, Managing Director, Hyundai Motor UK,
                        said:
                      </strong>
                      “The i20 N was engineered to deliver a pure,
                      uncompromising driving experience majoring on fun, while
                      also ensuring it excels in everyday practicality and
                      usability. The challenge was to create the ultimate
                      affordable performance car. To see it not only lining up
                      against genuine icons of the supercar world but even
                      toppling all of them and coming out on top in one of the
                      industry’s most comprehensive performance group tests is
                      the ultimate seal of approval.”
                      <br />
                      <br />
                      <img src="images/customerBrand5.png" className="img-fluid" />
                      <br />
                      <br />
                      The Hyundai i20 N is available to order now, delivering
                      World Rally Championship-inspired styling and performance
                      from just £24,995 On the Road (OTR).
                    </p>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
