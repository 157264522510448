import React, { Component } from 'react';
import Dates from '../../utils/DateFormat'

export default class ServiceHistoryItem extends Component {
    constructor(props) {
        super(props);

        // this.state = {
        //     vehicles =[],
        //     selectedIndex: 0
        // };

    }

    render() {
        const history = this.props.history;
        if (history == null) {
            return null;
        }

        const serviceDetails = history.actions.map((action, index) =>
            <li key={index}><strong>{action.type}</strong> - {action.category}</li>
        );

        return (
            <div className="card-deck mb-4">
                <div className="card shCard">
                    <div className="card-header shCard">
                        Date of work:{" "}
                        <span className="shHeaderLight">{Dates.formatDateFrom_ddmmyyyy_toLongdate(history.dateOfWork)}</span>
                        <span className="float-right d-none d-lg-block">
                            Mileage: <span className="shHeaderLight">{history.mileage}</span>
                        </span>
                        <hr className="shHR" />
                    </div>
                    <div className="card-body shCardBody">
                        {this.props.isNew &&
                            <div>
                                <small className="serviceDescription">
                                    <span className="darkText">Invoice No: </span>{" "}
                                    {history.hyundaiUkInvoiceNumber}
                                </small>
                                <hr className="shHR" />
                            </div>
                        }
                        <p />
                        <div className="row">
                            <div className="col-lg-4 mb-2">
                                <small>
                                    <strong>Work carried out by:</strong>
                                    <br />
                                    {history.dealerName}
                                </small>
                            </div>

                            <div className="col-lg-8">
                                <small>
                                    <strong>Service details:</strong>
                                    <br />
                                    <ul>
                                        {serviceDetails}
                                    </ul>
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}