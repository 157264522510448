import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';
class HyundaiAirMobility extends Component {
  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="air-mobility-news" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="air-mobility-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage:
                  'url("images/hyundai-air-mobility-overview.jpg") ',
              }}
            />
            <div
              id="air-mobility-gallery-2"
              className="theme-news-header-image"
              style={{
                backgroundImage:
                  'url("images/hyundai-air-mobility-teaser.jpg") ',
              }}
            />
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#air-mobility-gallery-1"
                    style={{
                      backgroundImage:
                        'url("images/hyundai-air-mobility-overview.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#air-mobility-gallery-2"
                    style={{
                      backgroundImage:
                        'url("images/hyundai-air-mobility-teaser.jpg") ',
                    }}
                  />
                </div>
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “Shift the moment: new Hyundai i30 N enhanced for maximum
                  driving fun”
                </p>
              </div>
              <hr />

              <NewsMedia
                articleId="air-mobility-news"
                articleText="
                Hyundai Motor to Accelerate Urban Air Mobility Test Flights in Partnership with Incheon Airport, Hyundai Construction, KT"
              />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    Hyundai Motor to Accelerate Urban Air Mobility Test Flights
                    in Partnership with Incheon Airport, Hyundai Construction,
                    KT
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    <ul>
                      <li>
                        Four companies will work to accelerate the development
                        of Urban Air Mobility and establish basis for test
                        flights under the agreement
                      </li>
                      <li>
                        The work undertaken by the partnership will align with
                        the Korean UAM Roadmap
                      </li>
                      <li>
                        The partnership will support building a robust
                        infrastructure and business model as well as innovative
                        UAM vehicles
                      </li>
                    </ul>
                  </p>
                  {displayShowMore && (
                    <p>
                      <a
                        data-toggle="collapse"
                        href="#newi30n1"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </div>

              <div className={moreSectionClassName} id="newi30n1">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      <b>Leatherhead, 22 September 2020 – </b>Hyundai Motor
                      Company is partnering with Incheon International Airport
                      Corp. (IIAC), Hyundai Engineering & Construction Co. and
                      KT Corp. to accelerate the development of Urban Air
                      Mobility (UAM) and work together to conduct test flights.
                      With a memorandum of understanding signed on September 18,
                      the four parties will work toward commercialising UAM by
                      2028.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      In June 2020, the Korean government announced the Korean
                      UAM Roadmap, outlining steps needed to commercialise UAM.
                      Hyundai Motor and its partners will align with the roadmap
                      that includes the Korean UAM Grand Challenge, the
                      public-private joint demonstration project that provides
                      the basis for jointly studying the construction and
                      operation of vertiports, airports for UAMs.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      Hyundai will push forward developing the UAM business and
                      securing business cases while IIAC will work to establish
                      the infrastructure and study feasibility of utilising UAM
                      as airport shuttles. Hyundai Construction will utilise its
                      expertise to build vertiports as well as the transit hub
                      that will connect UAM to other public transportations. KT
                      will set up the communications infrastructure and make
                      business cases for UAM as a mobility service. All four
                      parties will be sharing necessary information during the
                      development as well as jointly working on test flights.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      “The breadth and depth of this partnership shows what it
                      will take to build a comprehensive UAM ecosystem to serve
                      megacities like Seoul,” said Jaiwon Shin, Executive Vice
                      President and Head of Urban Air Mobility Division at
                      Hyundai Motor. “Building a robust infrastructure and
                      business model is just as important as developing
                      innovative UAM vehicles. This partnership demonstrates
                      Hyundai’s commitment to facilitating progress for humanity
                      by ushering in a new era of urban air mobility that will
                      revolutionise transportation.”
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      At CES 2020, Hyundai unveiled its innovative vision for
                      urban mobility and its first concept Urban Air Mobility
                      vehicle equipped with eVTOL.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HyundaiAirMobility;
