import React, { Component } from 'react';
import './contactPref.css';
import FooterNavbar from '../components/footerNavbar';
import Store from 'store';
import Topnavbar from '../components/topnavbar';
import Bottomnavbar from '../components/bottomnavbar';
import Footer from '../components/footer';
import $ from 'jquery';
import JSEncrypt from 'jsencrypt';
import OffersSideNav from '../components/offersSideNav';

export default class OffersFebruary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customer: Store.get('customerInfo'),
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Topnavbar />
        <Bottomnavbar />

        <div className="container">
          {/* <ProfileNav /> */}

          <section
            className="section1 wow fadeIn"
            data-wow-duration="2s"
            data-wow-delay="0.2s"
          >
            <div className="content pb-5  p-md-5 col-md-10 offset-md-1 border-right border-bottom">
              <div className="row">
                <div className="col-md-3 mt-4 border-md-right">
                  <OffersSideNav />
                </div>

                <div className="col-md-9  p-md-4 contact-perf-content">
                  <div>
                    <img
                      className="card-img-top img-fluid"
                      src="images/feb2.jpg"
                    />
                  </div>
                  <br />
                  <h4
                    className="h4ServHistory"
                    data-search-tag="title"
                    style={{ textAlign: 'center' }}
                  >
                    The Hyundai Upgrade Event
                    <br />
                    Get up to £3,000 additional saving off a new Hyundai in
                    March *
                  </h4>
                  <br />
                  <div style={{ textAlign: 'center' }}>
                    <p>Hi {this.state.customer.forenames},</p>
                    <p>
                      If you've not yet ordered your new Hyundai, there's still
                      time. The Upgrade event now runs until the end of March *.
                    </p>
                  </div>
                  <hr />

                  <br />
                  <p className="remindersBlue" data-search-tag="summary">
                    <div
                      style={{
                        backgroundColor: '#e7e4e4',
                        textAlign: 'center',
                        padding: '20px',
                        width: '115%',
                        marginLeft: '-24px',
                      }}
                    >
                      <h3>Your upgrade is ready</h3> There’s something for
                      everyone in our range, from beautifully efficient family
                      cars to high-performance N models. Each and every Hyundai
                      comes complete with the latest tech and safety features
                      along with a five year unlimited mileage warranty‡. So
                      which one will you choose?
                    </div>
                  </p>
                  {/* <p className="remindersBlue">
                    Your code is<strong> HYU10</strong>
                  </p> */}
                  <div>
                    <div className="row">
                      <div className="col-md-6 ">
                        <img src="images/upgrade2.png" className="img-fluid " />
                      </div>
                      <div
                        className="col-md-6"
                        style={{ textAlign: 'center', padding: '10px' }}
                      >
                        <h4>All-new TUCSON</h4>{' '}
                        <span style={{ color: '#3e80ab' }}>
                          4.6% APR Representative and up to £2,000 towards your
                          deposit.
                          <br /> Save an additional £600 when you order before
                          the end of March *.
                        </span>{' '}
                        <br />
                        <br />
                        Welcome to the revolution. Advanced and experimental,
                        the all-new TUCSON is not simply an evolution of its
                        predecessor, but a full-blown design revolution. Leading
                        the way in progressive design, it also offers state-of-
                        the-art smart tech.
                        <br />
                        <br />
                        <a
                          href="http://try.hyundai.co.uk/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {' '}
                          <button className="btn btn-sm removeRadius btn-primary">
                            Find out more
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      backgroundColor: 'whitesmoke',
                      textAlign: 'center',
                      padding: '10px',
                      width: '115%',
                      marginLeft: '-24px',
                    }}
                  ></div>
                  <div>
                    <div className="row">
                      <div className="col-md-6 p-3">
                        <img src="images/upgrade7.png" className="img-fluid" />
                      </div>
                      <div
                        className="col-md-6"
                        style={{ textAlign: 'center', padding: '10px' }}
                      >
                        <h4>All-new i20</h4>{' '}
                        <span style={{ color: '#3e80ab' }}>
                          4.6% APR Representative and £1,250 towards your
                          deposit.
                          <br /> Save an additional £500 when you order before
                          the end of March*
                        </span>{' '}
                        <br />
                        <br />
                        Dynamic and distinctive, the all-new i20 is setting new
                        standards in the small car segment. Combining loads of
                        smart tech like best-in-class safety and connectivity
                        features.
                        <br />
                        <br />
                        <a
                          href="http://try.hyundai.co.uk/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {' '}
                          <button className="btn btn-sm removeRadius btn-primary">
                            Find out more
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      backgroundColor: 'whitesmoke',
                      textAlign: 'center',
                      padding: '10px',
                      width: '115%',
                      marginLeft: '-24px',
                    }}
                  ></div>
                  <div>
                    <div className="row">
                      <div className="col-md-6 p-3">
                        <img src="images/upgrade4.png" className="img-fluid" />
                      </div>
                      <div
                        className="col-md-6"
                        style={{ textAlign: 'center', padding: '10px' }}
                      >
                        <h4>IONIQ Hybrid</h4>{' '}
                        <span style={{ color: '#3e80ab' }}>
                          4.6% APR Representative and £1,750 towards your
                          deposit.
                          <br /> Save an additional £1,750 when you order before
                          the end of March *.
                        </span>{' '}
                        <br />
                        <br />
                        IONIQ Hybrid delivers everything you expect from a
                        conventional car, but with all the environmental and
                        economic benefits that come with electrified driving.
                        IONIQ also comes in full Electric and Plug-in Hybrid.
                        <br />
                        <br />
                        <a
                          href="http://try.hyundai.co.uk/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {' '}
                          <button className="btn btn-sm removeRadius btn-primary">
                            Find out more
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      backgroundColor: 'whitesmoke',
                      textAlign: 'center',
                      padding: '10px',
                      width: '115%',
                      marginLeft: '-24px',
                    }}
                  ></div>
                  <div>
                    <div className="row">
                      <div className="col-md-6 p-3">
                        <img src="images/upgrade5.png" className="img-fluid" />
                      </div>
                      <div
                        className="col-md-6"
                        style={{ textAlign: 'center', padding: '10px' }}
                      >
                        <h4>KONA Electric</h4>{' '}
                        <span style={{ color: '#3e80ab' }}>
                          4.6% APR Representative and £2,500 towards your
                          deposit.
                          <br /> Save an additional £1,000 when you order before
                          the end of March *.
                        </span>{' '}
                        <br />
                        <br />
                        Combining expansive space and head- turning good looks.
                        With an exceptional electric driving range of up to 300
                        miles, the KONA Electric gives you the best of both
                        worlds – with no compromises. KONA also comes in Hybrid
                        and standard petrol engines.
                        <br />
                        <br />
                        <a
                          href="http://try.hyundai.co.uk/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {' '}
                          <button className="btn btn-sm removeRadius btn-primary">
                            Find out more
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      backgroundColor: 'whitesmoke',
                      textAlign: 'center',
                      padding: '10px',
                      width: '115%',
                      marginLeft: '-24px',
                    }}
                  ></div>
                  <div>
                    <div className="row">
                      <div className="col-md-6 p-3">
                        <img src="images/upgrade6.png" className="img-fluid" />
                      </div>
                      <div
                        className="col-md-6"
                        style={{ textAlign: 'center', padding: '10px' }}
                      >
                        <h4>2020 SANTA FE</h4>{' '}
                        <span style={{ color: '#3e80ab' }}>
                          4.6% APR Representative and £3,000 towards your
                          deposit.
                          <br /> Save an additional £3,000 when you order before
                          the end of March.
                        </span>{' '}
                        <br />
                        <br />
                        With strong character lines and a bold confident design,
                        the 2020 SANTA FE delivers a powerful first impression.
                        Relaxed and refined, the spacious interior features
                        high- quality materials crafted to create a comfortable
                        and calming driving experience.
                        <br />
                        <br />
                        <a
                          href="http://try.hyundai.co.uk/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {' '}
                          <button className="btn btn-sm removeRadius btn-primary">
                            Find out more
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      backgroundColor: 'whitesmoke',
                      textAlign: 'center',
                      padding: '10px',
                      width: '115%',
                      marginLeft: '-24px',
                    }}
                  ></div>
                  <div>
                    <div className="row">
                      <div className="col-md-6 p-3">
                        <img src="images/upgrade3.png" className="img-fluid" />
                      </div>
                      <div
                        className="col-md-6"
                        style={{ textAlign: 'center', padding: '10px' }}
                      >
                        <h4>2020 TUCSON</h4>{' '}
                        <span style={{ color: '#3e80ab' }}>
                          4.6% APR Representative and up to £4,500 towards your
                          deposit.
                          <br /> Save an additional £1,750 when you order before
                          the end of March *.
                        </span>{' '}
                        <br />
                        <br />
                        Go further with the TUCSON. Take control of a powerful
                        engine with impressive strength and efficiency. Stay
                        safe with innovative design features you can rely on.
                        Whatever drives you, choose a car that can keep up.
                        <br />
                        <br />
                        <a
                          href="http://try.hyundai.co.uk/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {' '}
                          <button className="btn btn-sm removeRadius btn-primary">
                            Find out more
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      backgroundColor: 'whitesmoke',
                      textAlign: 'center',
                      padding: '10px',
                      width: '115%',
                      marginLeft: '-24px',
                    }}
                  ></div>
                  <div>
                    <div className="row">
                      <div
                        className="col-md-12"
                        style={{ textAlign: 'center', padding: '10px' }}
                      >
                        <h4>Want to see more?</h4>
                        Discover what you can save across the full Hyundai
                        range.
                        <br />
                        <br />
                        <a
                          href="http://try.hyundai.co.uk/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {' '}
                          <button className="btn btn-sm removeRadius btn-primary">
                            See all models
                          </button>
                        </a>
                      </div>

                      <div style={{ fontSize: '0.6rem', opacity: '80%' }}>
                        <br />
                        <br />* T&Cs apply at{' '}
                        <a
                          href="https://www.hyundai.co.uk/legal/upgrade-event-feb-2021"
                          target="_blank"
                        >
                          www.hyundai.co.uk.
                        </a>{' '}
                        Saving against list price. Over 18s and participating UK
                        (excluding Channel Islands) dealers only. Savings vary
                        by model. Valid for new private retail purchases made by
                        31.03.21, which are paid for and registered by 31.03.21.
                        <br />
                        <br />‡ Warranty terms and exclusions apply – please see{' '}
                        <a
                          href="https://www.hyundai.co.uk/five-year-unlimited-mileage-warranty"
                          target="_blank"
                        >
                          this page
                        </a>{' '}
                        for more details.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <FooterNavbar />
        </div>

        <Footer />
      </div>
    );
  }
}
