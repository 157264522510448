import React, { Component } from 'react';
import './handbook.css';

class Handbook extends Component {
  state = {};

  render() {
    return (
      <div className={this.props.className}>
        <div
          style={{
            backgroundColor: '#032B5F',
            minHeight: '60px',
            color: 'white',
          }}
          className={this.props.name}
        >
          <h5 className={this.props.font}>{this.props.value.name}</h5>
        </div>
        <img src={this.props.value.imageUrl} style={{ width: '100%' }} />
        <br />
        <div className="handbook-button-container">
          <a href={this.props.value.url} target="_blank">
            <img src="images/downloadLogo.png" style={{ marginTop: '1rem' }} />
          </a>
        </div>
      </div>
    );
  }
}

export default Handbook;
