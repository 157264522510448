import React, { Component, Fragment } from 'react';
import ArchiveNewsNavbar from '../components/ArchiveNewsNavbar';
import FooterNavbar from '../components/footerNavbar';
import Topnavbar from '../components/topnavbar';
import Bottomnavbar from '../components/bottomnavbar';
import Footer from '../components/footer';

// Archived Hyundai News --------------------------------------------------------------

export default class ArchivedHyundaiNews extends Component {
  render() {
    return (
      <Fragment>
        <Topnavbar />
        <Bottomnavbar />
        <ArchiveNewsNavbar />
        ArchivedHyundaiNews
        <FooterNavbar />
        <Footer />
      </Fragment>
    );
  }
}
