import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Parser } from 'html-to-react';

export default class SearchResult extends Component {
  buildLink = () => {
    const child = this.createHtmlElement(this.props.result.displayTitle);

    switch (this.props.result.routeData.siteSection) {
      case 'page':
        if(this.props.result.routeData.search == null) {
          return <Link to={this.props.result.routeData.route}>{child}</Link>;
        }
        else {
          return <Link to={{
            pathname: this.props.result.routeData.route,
            search: '?' + this.props.result.routeData.search,
          }}>{child}</Link>;
        }
      case 'productNews':
        return (
          <Link
            to={{
              pathname: '/productNews',
              query: { displayItem: this.props.result.routeData.componentName },
            }}
          >
            {' '}
            {this.props.result.displayTitle}
          </Link>
        );
      case 'hyundaiNews':
        return (
          <Link
            to={{
              pathname: '/hyundaiNews',
              query: { displayItem: this.props.result.routeData.componentName },
            }}
          >
            {' '}
            {this.props.result.displayTitle}
          </Link>
        );
      case 'awardsNews':
        return (
          <Link
            to={{
              pathname: '/awardsNews',
              query: { displayItem: this.props.result.routeData.componentName },
            }}
          >
            {' '}
            {this.props.result.displayTitle}
          </Link>
        );
      case 'newsPast':
        return (
          <Link
            to={{
              pathname: '/newsPast',
              query: {
                category: this.props.result.routeData.category,
                displayItem: this.props.result.routeData.componentName,
              },
            }}
          >
            {child}
          </Link>
        );
      default:
        return this.props.result.routeData.siteSection;
    }
  };

  createHtmlElement(innerHtml) {
    const htmlToReactParser = new Parser();
    return htmlToReactParser.parse(innerHtml);
  }

  render() {
    if (this.props.result == null) {
      return null;
    }

    const link = this.buildLink();
    const summary = this.createHtmlElement(this.props.result.summary);

    return (
      <div className="search-result">
        {link}
        <p>
          <small>{summary}</small>
        </p>
      </div>
    );
  }
}
