import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';

export default class I10knocksCompetition extends Component {
  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="I10knocksCompetition" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="i10knocksCompetition-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/i10awards1.jpg") ',
              }}
            />
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a  
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#i10knocksCompetition-gallery-1"
                    style={{
                      backgroundImage: 'url("images/i10awards1_th.jpg") ',
                    }}
                  />
                </div>
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “It may be small, but the i10 certainly punches above its
                  weight”
                </p>
              </div>
              <hr />

              <NewsMedia
                articleId="i10knocksCompetition"
                articleText="i10 knocks out competition"
              />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    i10 KNOCKS OUT COMPETITION. AGAIN!
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    {' '}
                    It may be small, but the i10 certainly punches above its
                    weight. Built to thrive in towns, it is nippy, nimble, easy
                    to park, oozes cool, and is outrageously affordable to run.
                  </p>
                  {displayShowMore && (
                    <p>
                      <a  
                        data-toggle="collapse"
                        href="#collapsei10knocksCompetition"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                  {/* <p className="theme-news-body-text">The i30 Fastback’s sloping roofline, long bonnet and muscular body, along with its slim cabin and wide wheel arches give the car a strong stance. That strength, however, is far from being merely skin deep.</p> */}
                </div>
              </div>

              <div
                className={moreSectionClassName}
                id="collapsei10knocksCompetition"
              >
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      Now the multi-award winning i10, which has previously been
                      voted What Car? Best City Car for four straight years, has
                      been named Used Small Car of the Year for 2018 at the Car
                      Dealer Used Car Awards. Judges scored the nominees on
                      factors such as residual values, desirability and
                      reliability.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      “The i10 is everything a small car needs to be. It
                      impressed our judges with excellent reliability and good
                      specification choices,” said James Baggott, CEO of Baize
                      Group. “This, combined with a strong balance of residual
                      value and quick stock turnaround, means it flies off
                      dealers’ forecourts.”{' '}
                    </p>
                  </div>
                </div>

                {/* <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {" "}
                      The SUV will feature the 2.2 CRDi “R” engine,
                      which has been upgraded to further reduce
                      emissions, and will be available with front
                      wheel drive or 4WD, with a 6-speed manual
                      gearbox or an all-new 8-speed automatic
                                  transmission.{" "}
                    </p>
                  </div>
                </div> */}

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      Tony Whitehorn, President and CEO Hyundai Motor UK added,
                      “The new i10 continues to be one of our best-selling
                      models, and this award is testament to the popular i10’s
                      many attributes including its reliability and excellent
                      value for money. It shows that those who buy a used i10
                      are getting the best packaged small car for their money.”{' '}
                    </p>
                  </div>
                </div>

                {/* <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {" "}
                      For more information on the i10, which is one of the most spacious and refined models in its class, visit www.hyundai.co.uk/new-cars/i10  {" "}
                    </p>
                  </div>
                </div> */}

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      For more information on the i10, which is one of the most
                      spacious and refined models in its class, visit{' '}
                      <a href="https://www.hyundai.co.uk/new-cars/i10">
                        www.hyundai.co.uk/new-cars/i10
                      </a>{' '}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
