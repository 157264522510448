import React, { Component } from 'react';
import './contactPref.css';
import FooterNavbar from '../components/footerNavbar';
import Store from 'store';
import Topnavbar from '../components/topnavbar';
import Bottomnavbar from '../components/bottomnavbar';
import Footer from '../components/footer';
import $ from 'jquery';
import JSEncrypt from 'jsencrypt';
import OffersSideNav from '../components/offersSideNav';

export default class OffersTate extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Topnavbar />
        <Bottomnavbar />

        <div className="container">
          {/* <ProfileNav /> */}

          <section
            className="section1 wow fadeIn"
            data-wow-duration="2s"
            data-wow-delay="0.2s"
          >
            <div className="content pb-5  p-md-5 col-md-10 offset-md-1 border-right border-bottom">
              <div className="row">
                <div className="col-md-3 mt-4 border-md-right">
                  <OffersSideNav />
                </div>

                <div className="col-md-9  p-md-4 contact-perf-content">
                  <h4 className="h4ServHistory" data-search-tag="title">
                    10% off Tate merchandise
                  </h4>
                  <hr />
                  <div>
                    <img
                      className="card-img-top img-fluid"
                      src="images/Tate_combined_May18_LP.jpg"
                    />
                  </div>
                  <br />
                  <p className="remindersBlue" data-search-tag="summary">
                    Thanks to our ongoing partnership with Tate. MyHyundai
                    members can enjoy 10% off when shopping online at the Tate
                    shop. With books, art prints , t-shirts, plates and more to
                    choose from, you can fill your home with art and save money.
                    The discount will need to be applied to your shopping
                    basket.
                  </p>
                  <p className="remindersBlue">
                    Your code is<strong> HYU10</strong>
                  </p>

                  <a
                    href="https://shop.tate.org.uk/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {' '}
                    <button className="btn btn-sm removeRadius btn-primary">
                      Shop now
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </section>

          <FooterNavbar />
        </div>

        <Footer />
      </div>
    );
  }
}
