import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';
import Store from 'store';
import ReactGA from 'react-ga';
export default class HyundaiTucson extends Component {
  componentDidMount = () => {};

  clickHandler = () => {};

  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="hyundaiTucson" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="oneMillion-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/hytucmain.jpg") ',
              }}
            />
            {/* <div
              id="Bayon-gallery-2"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/hyundaielectric2.jpg")',
              }}
            /> */}
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                {/* <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#Bayon-gallery-2"
                    style={{
                      backgroundImage: 'url("images/hyundaielectric2.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#Bayon-gallery-1"
                    style={{
                      backgroundImage: 'url("images/hyundaielectric1.jpg")',
                    }}
                  />
                </div> */}
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “Hyundai announces prices and specifications for New Tucson”
                </p>
              </div>
              <hr />

              <NewsMedia articleId="awards" articleText="awards" />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    HYUNDAI ANNOUNCES PRICES AND SPECIFICATIONS FOR NEW TUCSON
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    {' '}
                    <ul>
                      <li>
                        Five high-specification models: Advance, Premium,
                        Ultimate and sporty N Line and N Line S trims
                      </li>
                      <li>Prices start from £32,000</li>
                      <li>
                        Redesigned exterior and interior with enhanced driver
                        convenience functions
                      </li>
                      <li>Technical data to be confirmed</li>
                      <li>
                        New Tucson models available to order from 3rd April
                      </li>
                      {/* <li>UK specification to be confirmed</li> */}
                      {/* <li>
                        Racetrack Capability: Up to 478 kW / 650 PS with N Grin
                        Boost engaged, increased endurance with industry leading
                        thermal management and regenerative braking
                      </li>
                      <li>
                        Everyday Sportscar: N e-shift and N Active Sound +
                        deliver feeling and sounds of driving high-performance
                        ICE cars
                      </li>
                      <li>
                        Among IONIQ 5 N’s many exclusive performance features
                        are N Battery Preconditioning, N Race, N Pedal, N Brake
                        Regen, N Drift Optimizer, N Torque Distribution, N
                        Launch Control, N Grin Boost, N Active Sound +, N
                        e-shift, N Road Sense and Track SOC
                      </li> */}
                      {/* <li>
                        Hyundai is investing £1 million in a programme of school
                        trips in order to prevent children missing out on such
                        experiences
                      </li>
                      <li>
                        Bookings are now open for the initiative, which has
                        launched with Hyundai’s Educator in Residence, Tim
                        Campbell MBE
                      </li> */}
                    </ul>
                  </p>
                  {displayShowMore && (
                    <p>
                      <a
                        data-toggle="collapse"
                        href="#hytuc"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </div>

              <div className={moreSectionClassName} id="hytuc">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      <strong>
                        Hyundai Motor UK has announced pricing and
                        specifications for the new Tucson, enhanced with a more
                        dynamic appearance that embodies the company's
                        progressive vision for future mobility.
                      </strong>
                      <br />
                      <br />
                      <img src="images/hytuc1.jpg" className="img-fluid" />
                      <br />
                      <br />
                      The new Tucson inherits the essence of the previous
                      model's "Parametric Dynamics" design language while
                      evolving with a more refined and powerful aesthetic. The
                      front fascia features a redesigned front with thin,
                      angular lines in the radiator grille, complemented by
                      hidden "Parametric Jewel" daytime running lights (DRLs).
                      The front bumper and skid plates are also new, extending
                      seamlessly from side to side and creating a dynamic and
                      visually wider stance.
                      <br />
                      <br />
                      <img src="images/hytuc2.jpg" className="img-fluid" />
                      <br />
                      <br />
                      At the rear, the skid plates are integrated into the
                      bumper moulding and enlarged horizontally, reinforcing the
                      impression of a wider wheelbase. Redesigned alloy wheels
                      on all models further accentuates the car's dynamism and
                      enhance its overall proportions.
                      <br />
                      <br /> The interior of the new Tucson prioritizes
                      spaciousness and practicality, achieved through a
                      redesigned dashboard with a new horizontal crash pad
                      design that creates a sense of openness. Physical heating
                      and ventilation controls and a redesigned centre console
                      with buttons for regularly used functions feature on the
                      new centre console, whilst the automatic and hybrid
                      powertrains feature a wireless charging pad housed in the
                      centre armrest. New Tucson also receives an updated
                      driver’s cockpit, with a 12.3” drivers supervision
                      instrument cluster and the latest connected car Navigation
                      Cockpit (ccNC).
                      <br />
                      <br />
                      Standard on all new Tucson models, the award-winning
                      connected car Navigation Cockpit (ccNC) is the latest
                      version of Hyundai’s’ ccNC and offers a range of content
                      including natural language voice recognition and real‒time
                      navigation. Features can be continuously updated to the
                      latest specifications via Over‒the‒Air (OTA) wireless
                      software updates. The ccNC is also home to the display for
                      the reverse view camera and supports wireless Apple
                      CarPlay® and Android Auto®.
                      <br />
                      <br />
                      New Tucson continues to offer customers a range of
                      powertrain options based around the 1.6-litre petrol
                      SmartStream efficient turbocharged engine. Available
                      options include the conventional engine in a 6-speed
                      manual with 2WD, electrified 48-volt Mild Hybrid with
                      7-speed dual clutch transmission with 2WD or 4WD, Hybrid 6
                      speed automatic transmission in 2WD or 4WD or Plug-in
                      Hybrid 6 speed automatic transmission in 2WD or 4WD.
                      <br />
                      <br />
                      The new Tucson range starts with the Advance 1.6T 6-speed
                      manual, priced from £32,000. The Advance trim includes 17”
                      alloy wheels, body coloured door handles and mirrors, LED
                      headlights and taillights, privacy glass, front and rear
                      parking sensors and keyless entry. Interior features
                      include premium cloth trim with leather steering wheel,
                      height adjustment for driver and front passenger seats,
                      customisable interior lighting and front and rear USB-C
                      charging points. The Advance trim level is also available
                      with the powertrain combinations of 1.6T 48V Mild Hybrid 7
                      speed dual clutch transmission 2WD, 1.6T Hybrid 6 speed
                      auto 2WD and 1.6T Plug-in Hybrid 2WD.
                      <br />
                      <br />
                      Priced from £34,500, the Premium 1.6T 6-speed manual
                      builds on the Advanced trim level with the addition of 18”
                      alloy wheels, metal TUCSON branded scuff plates, premium
                      combination cloth and leather seat trim, with electrically
                      adjustable and heated front seats, rear heated seats,
                      heated steering wheel, smart electric tailgate and rear
                      window sun blinds. The Premium trim introduces the KRELL
                      audio system with 8 speakers and subwoofer and a wireless
                      charging pad for compatible devices, along with the
                      addition of the Junction Turning function to the Forward
                      Collison Avoidance Assist safety system already standard
                      on the Advance trim. DCT and Hybrid models also feature
                      smart adaptive cruise control with stop/go function and
                      Highway Drive Assist. The Premium trim level is also
                      available with the powertrain combinations of 1.6T 48V
                      Mild Hybrid 7 speed dual clutch transmission 2WD, 1.6T
                      Hybrid 6 speed auto 2WD and 1.6T Plug-in Hybrid available
                      in both 2WD or 4WD.
                      <br />
                      <br />
                      From £34,500, the N Line trim level adds a number of
                      external styling changes to enhance the models sporting
                      aesthetic, with bespoke 19” N Line wheels, body coloured
                      wheel arches and N Line design package with changes to
                      both front and rear bumper design. Interior changes
                      include N Line interior design package with red accents
                      and stitching and details, such as the red ring around the
                      engine start/stop button, and N Line seats in textile faux
                      suede trim in Obsidian Black. The N Line trim level is
                      also available with the powertrain combinations of 1.6T
                      48V Mild Hybrid 7 speed dual clutch transmission 2WD, 1.6T
                      Hybrid 6 speed auto 2WD and 1.6T Plug-in Hybrid available
                      in both 2WD or 4WD.
                      <br />
                      <br />
                      <img src="images/hytuc3.jpg" className="img-fluid" />
                      <br />
                      <br />
                      In addition to the N Line, customers can also chose the N
                      Line S. Priced from £37,000 , the N Line S adds to the N
                      Line specification with electrically adjustable, heated
                      and ventilated front seats, rear heated seats, heated
                      steering wheel, matrix LED headlamps, panoramic sunroof
                      with tilt and slide function, the KRELL premium audio
                      system, surround view monitor, blind spot view monitor,
                      parking distance warning with front, side and rear
                      detection zones, and parking collision avoidance assist on
                      DCT and Hybrid powertrains. The N Line S trim level shares
                      the powertrain options with the N Line model.
                      <br />
                      <br />
                      The Ultimate trim level, available from £37,000, built
                      upon the classy styling of the Premium trim, with the
                      addition of 19” alloy wheels and matrix LED headlamps.
                      Interior equipment is also enhanced, with the addition of
                      driver’s seat position memory function, ventilated front
                      seats and the option of Black, Moss Grey or Cypress Green
                      leather seat trim. Safety additions include the surround
                      view monitor, blind spot view monitor, parking distance
                      warning with front, side and rear detection zones, and
                      parking collision avoidance assist on DCT and Hybrid
                      powertrains. The Ultimate trim level is also available
                      with the powertrain combinations of 1.6T 48V Mild Hybrid 7
                      speed dual clutch transmission 2WD, 1.6T Hybrid 6 speed
                      auto 2WD and 1.6T Plug-in Hybrid available in both 2WD or
                      4WD.
                      <br />
                      <br />
                      The new Tucson range is available in a range of exterior
                      finishes and interior trim options:
                      <br />
                      <br />
                      <img src="images/hytuc4.jpg" className="img-fluid" />
                      <br />
                      <br />
                      <strong>
                        "Tucson has been a phenomenally successful model for
                        Hyundai, with private buyers and company car choosers
                        alike making it their SUV of choice. The significant
                        interior and exterior updates for the refreshed Tucson
                        will ensure that its popularity continues."
                      </strong>
                      <br />
                      Ashley Andrew
                      <br />
                      President of Hyundai and Genesis UK
                      <br />
                      <br />
                      All new Tucson models come with Hyundai’s industry leading
                      5 Year Unlimited Mileage Warranty, Roadside Assistance
                      package, 5 Year Annual Health Check, and 12 Year Anti
                      Corrosion Warranty.
                      <br />
                      <br />
                      The C02 and technical data has not yet been confirmed, as
                      such the prices listed are indicative and could be subject
                      to change once the C02 and technical data has been
                      confirmed.
                      <br />
                      <br />
                      Customers purchasing their New Tucson on a PCP product
                      through Hyundai Capital will also benefit from deposit
                      contribution of up to £2000 Offer only available from 3rd
                      April 2024 to 1st July 2024:
                      <br />
                      <br />
                      {/* For further information, customers can visit{' '} */}
                      <a
                        href="https://www.hyundai.news/newsroom/dam/uk/models/20240403_tucson_pricing_specs/hyundai-tucson-uk-pricing-0424-v2.pdf"
                        target="_blank"
                      >
                        New Tucson pricing
                      </a>{' '}
                      (PDF)
                      {/* <img src="images/newbayon2.png" className="img-fluid" /> */}
                      <br />
                      <a
                        href="https://www.hyundai.news/newsroom/dam/uk/models/20240403_tucson_pricing_specs/hyundai-tucson-uk-specifications-0424-v2.pdf"
                        target="_blank"
                      >
                        New Tucson specifications
                      </a>{' '}
                      (PDF)
                      <br />
                      <em>Technical Data to be confirmed.</em>
                      <br />
                      <br />
                      {/* <em>
                        The Hybrid Upgrade Event is a cash saving of £2,200 for
                        Tucson Hybrid Premium, £600 for Tucson Hybrid Ultimate
                        or N Line S, or £1,800 for Tucson Hybrid SE Connect .
                        Available on Hybrid models only. T&Cs apply. Subject to
                        status. 18s and over. Guarantee may be required. Hyundai
                        Finance, RH2 9AQ. All offers are subject to
                        availability, while stocks last and are only available
                        to private retail customers on new cars purchased,
                        registered in the UK between 1st February 2024 and 31st
                        March 2024 and sourced through Hyundai Motor UK Ltd or
                        its authorised retailers. Deposit contributions are not
                        valid in conjunction with Affinity.
                      </em>
                      <br />
                      <br /> */}
                      {/* <img src="images/newbayon3.png" className="img-fluid" />
                      <br />
                      <br />
                      A total of nine exterior colours are available, including
                      four new colours: Lumen Grey Pearl, Meta Blue Pearl, Lucid
                      Lime Metallic and Vibrant Blue Pearl. An optional two-tone
                      roof in Phantom Black is also available with several
                      exterior colours.
                      <br />
                      <br />
                      <h2>Advanced connectivity and technology</h2> In line with
                      Hyundai’s customer-centric approach, new Bayon offers
                      advanced connectivity technology. Its state-of-the-art
                      digital cockpit and top-line infotainment features have
                      been made even better, thanks to the introduction of
                      Over-the-Air (OTA) map updates.
                      <br />
                      <br /> Customers now benefit from a 10.25-inch LCD
                      instrument cluster, with a separate 10.25-inch Audio
                      Visual Navigation Screen (AVN) screen as standard. New
                      Bayon continues to offer features that were already
                      popular in the previous version, such as the optional
                      10.25-inch configurable digital cluster with different
                      graphics, depending on the drive mode, Apple CarPlay and
                      Android Auto, wireless charger, and the latest update to
                      Bluelink® telematics.
                      <br />
                      <br />
                      <img src="images/newbayon4.png" className="img-fluid" />
                      <br />
                      <br />
                      Two USB-C ports – one in the front and one in the rear –
                      make it easier to even charge simultaneously the latest
                      tech devices. The front USB-A port also allows data
                      transfer, enabling occupants to connect their phone to the
                      vehicle’s infotainment system. A premium Bose sound system
                      completes the package, promising occupants a high-quality
                      listening experience thanks to its eight speakers –
                      including a subwoofer.
                      <br />
                      <br /> <i>UK specification to be confirmed</i>
                      <br />
                      <br />
                      <h3>Enhanced interior lighting</h3>
                      <img src="images/newbayon5.png" className="img-fluid" />
                      <br />
                      <br />
                      New Bayon features a roomy, well-lit interior, with a
                      strong focus on maximising front and rear passenger
                      comfort, and a large boot space.
                      <br />
                      <br />
                      <img src="images/newbayon6.png" className="img-fluid" />
                      <br />
                      <br />
                      The refreshed model has replaced lightbulbs with LED
                      technology for increased in-car illumination, from
                      optional LED Room/Map and Sunvisor lamps, and has the
                      option of multicolour front ambient lighting technology,
                      which enables passengers to adjust the colour of the
                      interior lighting to fit their mood.
                      <br />
                      <br />
                      <h3>State-of-the-art safety</h3> A number of
                      semi-autonomous driving features set new Bayon, the
                      smallest member of Hyundai’s SUV family, apart from its
                      competitors. Lane Following Assist (LFA) works to keep the
                      vehicle centred in its lane. Forward Collision-Avoidance
                      Assist (FCA) first sounds an alarm, then, if necessary,
                      applies the brakes to prevent a collision from occurring.
                      This feature includes car, pedestrian, and cyclist
                      detection, as well as Junction Turning, which can apply
                      the brakes to prevent a collision with an oncoming car
                      when turning at an intersection. Another advanced feature,
                      Navigation-based Smart Cruise Control (NSCC), uses data
                      from the vehicle’s navigation system to adjust speed
                      autonomously when driving on a highway or expressway.
                      <br />
                      <br /> Blind-Spot Collision-Avoidance Assist (BCA) uses
                      multiple sensors positioned around the car to monitor
                      corners and, if another vehicle is detected, a visual
                      alert appears on the exterior mirrors. Where necessary,
                      BCA acts on the differential brake to avoid a collision or
                      reduce the impact damage. Bayon also includes several
                      features that gently redirect the driver’s attention when
                      their focus slips. Driver Attention Warning (DAW) analyses
                      driving patterns to help detect drowsy or distracted
                      driving. This system works in tandem with Leading Vehicle
                      Departure Alert (LVDA), which alerts the driver when the
                      vehicle ahead of them starts moving forward.
                      <br />
                      <br /> Bayon now features second-generation eCall based on
                      the 4G network, which automatically alerts emergency
                      services if the vehicle’s airbags are deployed in case of
                      an accident. Alternatively, occupants can also activate
                      this feature with the press of a button.
                      <br />
                      <br />
                      <i>UK specification to be confirmed</i>
                      <br />
                      <br />
                      <strong>Disclaimer: CO2 and emissions data</strong> <br />
                      <br />
                      <ul>
                        <li>
                          The new Bayon is not currently available for sale. CO2
                          values for this model have not yet been determined.
                        </li>
                      </ul>
                      <br /> */}
                      {/* <br />
                      This powerful EV benefits from several technical
                      improvements. Its electric motors spin up to 21,000 RPM
                      providing 478 kW / 650 PS with N Grin Boost engaged. This
                      is a result of the two-stage inverter with increased
                      energy efficiency for stronger power output from the new
                      84 kWh battery.
                      <br />
                      <br /> */}
                      {/* Several exclusive features aid track performance. N Grin
                      Boost maximizes acceleration by a power increment of 10
                      seconds and N Launch Control provides three different
                      traction levels for the fastest possible start, allowing
                      drivers to launch their vehicle like a professional race
                      car. Track SOC (state-of-charge) automatically calculates
                      battery consumption per lap.
                      <br />
                      <br />
                      IONIQ 5 N sets a new benchmark for thermal management in
                      severe racetrack conditions. Compared to its competitors,
                      IONIQ 5 N boasts a higher resistance to heat induced power
                      degradation – loss of maximum power due to overheating.
                      <br />
                      <br />
                      The enhanced battery thermal management system, with
                      increased cooling area, better motor oil cooler and
                      battery chiller. The independent radiators for the battery
                      and motor maximize IONIQ 5 N’s resistance to performance
                      degradation before and during intense track driving.
                      <br />
                      <br />
                      Before driving, the driver can utilize N Battery
                      Pre-conditioning to optimize the battery cells to the most
                      power-efficient temperature by choosing between ‘Drag’
                      mode for a short burst of full power or ‘Track’ mode that
                      optimizes the lowest possible battery temperature for more
                      laps.
                      <br />
                      <br />N Race feature further optimizes the EV’s endurance
                      on the circuit, giving drivers more direct control over
                      the car’s energy usage. N Race applies a
                      motorsport-derived approach to maximizing resources for
                      the fastest possible times. While this type of setting
                      change would normally require a team of mechanics and
                      equipment, IONIQ 5 N offers it at the convenience of a few
                      button presses.
                      <br />
                      <br /> */}
                      {/* With N Race, drivers are given the strategic choice to
                      prioritize ‘Endurance’ or ‘Sprint.’ ‘Endurance’ maximizes
                      range on the racetrack. This is achieved by limiting the
                      peak power, which results in a slower build-up of
                      temperature. Comparatively, ‘Sprint’ prioritizes power and
                      provides shorter bursts of full energy. This will help
                      drivers accurately manage their energy strategy and create
                      an ideal performance window to achieve their driving
                      performance targets.
                      <br />
                      <br />
                      <img src="images/ioniq5n6.jpg" className="img-fluid" />
                      <br />
                      <br />
                      IONIQ 5 N boasts N-tuned brakes, which represent Hyundai’s
                      most powerful braking system. It features 400-mm diameter
                      front discs with four-piston monobloc calipers and 360-mm
                      rear discs. These brakes are constructed with new
                      lightweight materials and optimized airflow for improved
                      cooling efficiency. In addition, the regenerative (regen)
                      braking system typically used for enhanced efficiency has
                      been reinterpreted in IONIQ 5 N to enhance braking
                      performance. It serves as the primary source of brake
                      force, while the mechanical brakes supplement additional
                      braking force when required, ensuring enhanced endurance
                      on the track.
                      <br />
                      <br />
                      To address the harsh braking demands of the Nürburgring
                      Nordschleife, Hyundai N engineers designed N Brake Regen,
                      an N-specific region braking system developed specifically
                      for IONIQ 5 N. It offers up to a maximum of 0.6 G
                      decelerative force – an industry leading figure – through
                      N Brake Regen alone and remains engaged under ABS
                      activation with a maximum of 0.2 G. The risk of brake fade
                      due to the EV’s relative heaviness is minimized by
                      maximizing regen braking power, which results in lower
                      brake force applied to the mechanical brakes.
                      <br />
                      <br />
                      The seamless blending between the N Brake Regen and
                      hydraulic brakes creates a smooth transition that is
                      imperceptible to the driver. Moreover, left foot braking
                      allows the brake and accelerator pedal to work at the same
                      time when driving on the track.
                      <br />
                      <br />
                      <strong>
                        State-of-the-art technology delivers shift feeling and
                        emotional fun in an Everyday Sportscar
                      </strong>
                      <br />
                      <br />
                      As an everyday sportscar, IONIQ 5 N delivers an engaging
                      shift feeling and emotional driving through the integrated
                      N e-shift and N Active Sound +. N e-shift provides a sense
                      of control over power delivery and shift feeling while N
                      Active Sound + works collectively to deliver not only a
                      futuristic EV sound but also ICE-like engine and exhaust
                      sounds that provide a more engaging experience behind the
                      wheel. Together, they enhance the fun and joy of driving
                      by simulating the jolt feel and sound of an ICE vehicle.
                      <br />
                      <br />
                      Many performance enthusiasts have been outspoken about the
                      lack of driver feedback behind the wheel of many EVs.
                      That’s why Hyundai N engineers developed the N e-shift and
                      N Active Sound + functions to deliver greater driver
                      feedback and therefore more precise driver control of
                      IONIQ 5 N’s dynamic driving. The unique emphasis on
                      precise power control differentiates N’s approach to EV
                      sound from past applications.
                      <br /> */}
                      {/* <br />N e-shift simulates the eight-speed dual-clutch
                      transmission (DCT) of internal combustion engine (ICE) N
                      cars. It simulates a gearshift by controlling motor torque
                      output and simulates the jolt feeling between shifts. As a
                      result, it provides a more precise, interactive driving
                      experience that feels closer to ICEs than previous EVs.
                      <br />
                      <br />
                      <img src="images/ioniq5n7.jpg" className="img-fluid" />
                      <br />
                      <br />N Active Sound+ is paired with N e-shift to enhance
                      the driving experience of EVs by creating a more engaging
                      and exciting sensory experience for the driver. It
                      incorporates a 10-speaker system (eight internal, two
                      external) that offers three distinct sound themes. The
                      ‘Ignition’ theme simulates the sound of N’s 2.0T engines
                      and ‘Evolution’ provides a signature high-performance
                      sound inspired by the RN22e. The ‘Supersonic’ theme is a
                      unique concept inspired by twin-engine fighter jets,
                      featuring variable volume during cornering. The objective
                      of N Active Sound+ is to provide drivers with an acoustic
                      reference to gauge the power being utilized.
                      <br />
                      <br />
                      IONIQ 5 N also is equipped with N Road Sense, which
                      automatically recommends the activation of N Mode when
                      double-curved road signs are detected, enabling drivers to
                      enjoy every second of their drive to the fullest (EU
                      only).
                      <br />
                      <br />
                      Like the standard IONIQ 5, its N variant is equipped with
                      Vehicle-to-Load (V2L), which allows the driver to use the
                      stored energy from the EV’s high-voltage battery to
                      recharge devices, such as an e-bike, electrical camping
                      equipment or domestic appliances – even when the vehicle
                      is turned off.
                      <br />
                      <br />
                      <img src="images/ioniq5n8.jpg" className="img-fluid" />
                      <br />
                      <br />
                      <strong>
                        Performance-enhancing design elements to inspire
                        passionate driving
                      </strong>
                      <br />
                      <br />
                      IONIQ 5 N’s exterior adds many performance-enhancing
                      design elements, such as contrasting black trim parts and
                      a wing-type rear spoiler, which emphasize the sporty
                      aspects of the standard model and maximize its circuit
                      driving potential.
                      <br />
                      <br />
                      The N model further distinguishes itself from the standard
                      IONIQ 5 with a change of proportions. It is 20 mm lower
                      overall, 50 mm wider at the bottom to accommodate wider
                      tires and 80 mm longer due to the more prominent diffuser.
                      <br />
                      <br />
                      <img src="images/ioniq5n9.jpg" className="img-fluid" />
                      <br />
                      <br />
                      At the front, there is a N Mask graphic fascia with
                      functional mesh along with air curtain and air flaps for
                      extra cooling. A lip spoiler runs across the lower part of
                      the bumper to emphasize the car’s slow-slung stance and
                      convey its performance intent.
                      <br />
                      <br />
                      <img src="images/ioniq5n10.jpg" className="img-fluid" />
                      <br />
                      <br />
                      The EV N-exclusive ‘Luminous Orange’ at the bottom of the
                      black front bumper cover continues along the side skirts,
                      giving the IONIQ 5 N a racetrack-ready look.
                      <br />
                      <br />
                      <img src="images/ioniq5n11.jpg" className="img-fluid" />
                      <br />
                      <br />
                      This impression is further reinforced by the forged
                      21-inch aluminum wheels that are wrapped in high-grip
                      275/35R21 Pirelli P-Zero tires for improved ride and
                      handling and better grip in track performance.
                      <br />
                      <br />
                      <img src="images/ioniq5n12.jpg" className="img-fluid" />
                      <br />
                      <br />
                      At the rear, the prominent, N-only wing-type spoiler,
                      along with orange-accented rear diffuser and air outlet,
                      help control airflow to support optimal aerodynamic
                      performance, further highlighting IONIQ 5 N’s
                      high-performance capability and adding to its aggressive
                      styling. Integrated in the spoiler is a triangular N-only
                      HMSL brake light. Below the rear hatch is an N-exclusive
                      black bumper cover with checker flag reflector graphics.
                      There is also a rear window wiper.
                      <br />
                      <br />
                      IONIQ 5 N’s interior neatly conveys the N family look with
                      the application of N-branded elements, including the
                      steering wheel, seats, door scuff panels and metal pedals,
                      etc., and is optimized for track driving.
                      <br />
                      <br />
                      <img src="images/ioniq5n13.jpg" className="img-fluid" />
                      <br />
                      <br />
                      The newly designed N steering wheel prominently features
                      the N logo for the first time, flanked by N buttons that
                      set the desired drive mode, arranged to suit the driver’s
                      preference. Driving modes can be customized and matched to
                      each button in various combinations to maximize IONIQ 5
                      N’s fun driving spirit. N Grin Boost button positioned at
                      3 o’clock allows instant and intuitive access to maximize
                      acceleration and driving fun.
                      <br />
                      <br />
                      <img src="images/ioniq5n14.jpg" className="img-fluid" />
                      <br />
                      <br />
                      Paddles are optimally positioned at driver’s fingertips to
                      engage N e-shift and N Pedal features.
                      <br />
                      <br />
                      IONIQ 5 N’s center console is optimized for track driving
                      with knee pads and shin support as well as a sliding
                      armrest. An extension to the lower part of the console
                      enhances rigidity to further support sport driving. For
                      everyday use, it also offers a C-type USB, wireless
                      charger and cupholders.
                      <br />
                      <br />
                      The N seats have reinforced bolsters, so that even if
                      strong lateral acceleration occurs during sharp cornering,
                      it firmly supports the upper and lower body to maintain a
                      stable driving posture at all times. The N bucket seats
                      are positioned about 20 mm lower compared to the basic
                      specification, designed for customers who pursue
                      high-performance drivability. N emblems are arranged front
                      and rear of the seat with welcome lighting at front. The
                      two-toned design theme of the bucket seats carries over to
                      the rear.
                      <br />
                      <br />
                      <img src="images/ioniq5n15.jpg" className="img-fluid" />
                      <br />
                      <br />
                      Due to the nature of N driving, IONIQ 5 N’s pedals are
                      optimized for foot contact in track driving scenarios that
                      involve oversteer, drifting and power slides, reducing the
                      risk of foot slippage in two-foot driving. The footrest is
                      optimized to maintain a stable driving posture at all
                      times, including strong deceleration moments.
                      <br />
                      <br />
                      The new checker flag theme – a track-inspired riff on
                      IONIQ 5’s iconic parametric pixels – can be found on the
                      door scuff panels metal pedals and footrest, representing
                      N Brand’s motorsport spirit.
                      <br />
                      <br />
                      Much like the standard IONIQ 5, sustainable materials are
                      applied throughout IONIQ 5 N, including recyclable
                      paperette door decorative garnish, BIO PET yarn from
                      sugarcane, recycled PET yarn from recycled PET bottles,
                      BIO TPO from sugarcane and eco processed leather. IONIQ 5
                      N also adds recycled tire pigment paint and recycled poly
                      Alcantara seat cover cloth.
                      <br />
                      <br />
                      <strong>
                        IONIQ 5 N at Goodwood Festival of Speed 2023
                      </strong>
                      <br />
                      <br />
                      IONIQ 5 N will take center stage as the N brand makes its
                      inaugural appearance at the Goodwood Festival of Speed
                      2023. N brand has constructed a dedicated stand that will
                      showcase multiple vehicles, including i20 N WRC, N Vision
                      74, RN22e and the newly unveiled IONIQ 5 N. Additionally,
                      the stand will feature various public activations, such as
                      race simulators and photo booths.
                      <br />
                      <br />
                      <img src="images/ioniq5n16.jpg" className="img-fluid" />
                      <br />
                      <br />
                      (Right) Euisun Chung, Executive Chair of Hyundai Motor
                      Group (Left) Jaehoon Chang, President and CEO of Hyundai
                      Motor Company
                      <br />
                      <br />
                      <img src="images/ioniq5n17.jpg" className="img-fluid" />
                      <br />
                      <br />
                      (From left) Joon Park, Vice President of N Brand
                      Management Group, Simon Loasby, Head of Hyundai Styling
                      Group, Jaehoon Chang, President and CEO of Hyundai Motor
                      Company, Euisun Chung, Executive Chair of Hyundai Motor
                      Group, Albert Biermann, Executive Technical Advisor, Till
                      Wartenberg, Vice President and Head of N Brand and
                      Motorsport
                      <br />
                      <br />
                      <img src="images/ioniq5n18.jpg" className="img-fluid" />
                      <br />
                      <br />
                      (From left) Jaehoon Chang, President and CEO of Hyundai
                      Motor Company, Till Wartenberg, Vice President and Head of
                      N Brand and Motorsport
                      <br />
                      <br />
                      The highlight of N brand’s presence at Goodwood will be
                      the exclusive ‘N Moment’ held during the iconic Goodwood
                      hill climb. The N Moment will showcase N brand’s
                      motorsport models and rolling labs, culminating in the
                      presentation of the new IONIQ 5 N and the world debut of N
                      brand's ‘IONIQ 5 N Drift Spec’ show car. Furthermore, a
                      2023 specification i20 N WRC will participate in the
                      Forest rally stage.
                      <br />
                      <br />
                      <strong>Specifications</strong>
                      <br />
                      <br />
                      <strong>Motor output</strong>
                      <br />
                      <ul>
                        <li>Front: 166 kW / 226 PS</li>
                        <li>Rear: 282 kW / 383 PS</li>
                        <li>Total output: 448 kW / 609 PS</li>
                        <li>
                          Boost output: 478 kW / 650 PS <br />
                          (Front: 175 kW, 238 PS / Rear: 303 kW, 412 PS)
                        </li>
                      </ul>
                      <br />
                      <strong>Battery</strong>
                      <br />
                      Capacity: 84 kWh
                      <br />
                      <br />
                      <strong>Charging</strong>
                      <br />
                      <ul>
                        <li>Charge Speed: 350 kW</li>
                        <li>Charge time: 10% to 80% in 18 minutes</li>
                      </ul>
                      <br />
                      <strong>Dimesions</strong>
                      <br />
                      <ul>
                        <li>Length: 4,715 mm</li>
                        <li>Width: 1,940 mm</li>
                        <li>Height: 1,585 mm</li>
                        <li>Wheelbase: 3,000 mm</li>
                      </ul>
                      <br />
                      <strong>Tires</strong>
                      <br />
                      Pirelli P-Zero 275/35R21
                      <br />
                      <strong>Braking system</strong>
                      <br /> */}
                      {/* <ul>
                        <li>Front: 4 piston 400 mm</li>
                        <li>Rear: 1 piston 360 mm</li>
                      </ul>
                      <br />
                      <strong>Regenerative braking</strong>
                      <br />
                      <br />
                      Deceleration: 0.6 G (0.2 G during ABS activation)
                      <br />
                      <br />
                      <strong>0 – 100 km/h</strong>
                      <br />
                      <br />
                      3.4 sec. (Boost)
                      <br />
                      <br />
                      <strong>Top speed</strong>
                      <br />
                      <br />
                      260 Km/h
                      <br />
                      <br />
                      <strong>Range</strong>
                      <br />
                      <br />
                      To be announced at regional market launch
                      <br />
                      <br />
                      <strong>Exterior colors</strong>
                      <br />
                      <br />
                      Performance Blue Matte, Performance Blue, Abyss Black
                      Pearl, Cyber Gray Metallic, Ecotronic Gray Matte,
                      Ecotronic Gray , Atlas White Matte, Atlas White, Gravity
                      Gold Matte and Soultronic Orange Pearl
                      <br />
                      <br />
                      <strong>Interior color</strong>
                      <br />
                      <br />
                      Black with Performance Blue accents
                      <br />
                      <br />
                      <em>
                        * Energy consumption figures are not finalized. Please
                        only use the estimates as written above. Vehicle
                        specifications and features may vary depending on
                        country/region and are subject to change without notice.
                      </em> */}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
