import React from 'react';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';
import Store from 'store';

export default class SlideLink extends React.Component {
  clickHandler = (link) => {
    if (link === 'newsletter') {
      ReactGA.event({
        label: 'News Articles',
        category: 'WHN',
        action: `Home Page MyHyundai NewsLetter "Image" Clicked`,
        gaOptions: {
          siteSpeedSampleRate: 100,
          userId: Store.get('customer') && Store.get('customer').urn,
        },
      });
    }
  };

  render() {
    const { slide, imageClassName } = this.props;

    if (slide.isRoute) {
      return (
        <div>
          {slide.linkPath ==
          'https://www.hyundaiexperience.com/environment/exterior' ? (
            <a
              href="https://www.hyundaiexperience.com/environment/exterior"
              target="_blank"
            >
              {slide.title}
            </a>
          ) : (
            <Link to={slide.linkPath} title={slide.title}>
              <img
                className={imageClassName}
                src={slide.imagePath}
                alt={slide.imageAlt}
              />
              <div className="slide-overlay">{slide.overlay}</div>
            </Link>
          )}
        </div>
      );
    } else {
      return (
        <a href={slide.linkPath} target="_blank">
          <img
            className={imageClassName}
            src={slide.imagePath}
            alt={slide.imageAlt}
          />
        </a>
      );
    }
  }
}
