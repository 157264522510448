import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './essentialPlans.css';
import FooterNavbar from '../components/footerNavbar';
import Store from 'store';
import Topnavbar from '../components/topnavbar';
import Bottomnavbar from '../components/bottomnavbar';
import Footer from '../components/footer';
import ServiceProductNavbar from '../components/serviceProductNavbar.jsx';
import ReactPixel from 'react-facebook-pixel';
import {
  BaseService,
  InterimService,
  FullService,
} from '../data/ServiceData.js';

import { IconContext } from 'react-icons';
import { FiCheckCircle } from 'react-icons/fi';

export default class essentialPlans extends Component {
  state = {
    email: Store.get('customerInfo') && Store.get('customerInfo').email,
  };

  componentDidMount() {
    if (Store.get('campaign_name') ==='facebook') {
      ReactPixel.trackCustom('from facebook view esensetials', {
        email: this.state.email,
      });
    }

    window.scrollTo(0, 0);
  }

  render() {
    const iconStyleBase = { className: 'text-orange', size: '20' };
    const iconStyleInterim = { className: 'text-info', size: '20' };
    const iconStyleFull = { className: 'text-primary', size: '20' };

    return (
      <div>
        <Topnavbar />
        <Bottomnavbar />

        <div className="container">
          {/* <ProfileNav /> */}
          {<ServiceProductNavbar />}
          <section
            className="section1 wow fadeIn"
            data-wow-duration="2s"
            data-wow-delay="0.2s"
          >
            <div className="content p-3 p-xl-5 col-md-10 offset-md-1 border-right border-bottom">
              <div className="row">
                <div className="col-lg-3 p-3 p-sm-4">
                  {/* <OffersSideNav /> */}
                  <h4 className="h4ServHistory" data-search-tag="title">
                    <strong>e</strong>sense<strong>tials servicing</strong>
                  </h4>
                  <p className="mb-0" data-search-tag="summary">
                    e<strong>sense</strong>tials servicing is for you if your
                    Hyundai is outside of its warranty period. Prices start from
                    just £99* and every service includes one year’s free
                    breakdown cover worth £135<sup>†</sup>.
                  </p>
                </div>

                <div className="col-lg-8 offset-lg-1 p-sm-4 border-lg-left">
                  <div className="theme-main-position-relative">
                    <img
                      src="images/essentials/esensetials_logo.png"
                      className="theme-essentials-page-logo"
                    />
                    <img
                      className="card-img-top img-fluid"
                      srcSet="
                      images/essentials/MotivAW_1200.png 1x,
                      images/essentials/MotivAW_1200@2x.png 2x,
                      images/essentials/MotivAW_1200@3x.png 3x
                      "
                      src="images/essentials/MotivAW_1200.png"
                    />
                  </div>
                  <h4
                    className="h4ServHistory mt-4 mb-4 text-center"
                    data-search-tag="title"
                  >
                    <strong>All about care</strong>
                  </h4>

                  <p
                    className="remindersBlue text-center"
                    data-search-tag="summary"
                  >
                    e<strong>sense</strong>tials servicing is for Hyundai
                    vehicles which are outside of their warranty period. Choose
                    from Base, Interim or Full services, and add your MOT for
                    just £40. Every e<strong>sense</strong>tials service
                    includes one year's free comprehensive{' '}
                    <a  
                      href="https://www.hyundai.co.uk/assistance"
                      target="_blank" rel="noopener noreferrer"
                      className="text-info text-underline"
                    >
                      breakdown cover
                    </a>{' '}
                    from the AA (worth £135<sup>†</sup>).
                  </p>
                  <div className="row mb-5">
                    {/* //Book Online */}
                    <div className="col-6">
                      <div className="row">
                        <div className="col-10 col-sm-8 mx-auto">
                          <a  
                            href="https://www.hyundai.co.uk/owning/book-a-service"
                            target="_blank" rel="noopener noreferrer"
                            className="btn btn-block btn-orange btn-sm removeRadius"
                          >
                            <span>Book Online</span>
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* //Set an remindersBlue  */}
                    <div className="col-6">
                      <div className="row">
                        <div className="col-10 col-sm-8 mx-auto">
                          <Link
                            to="/myReminders"
                            className="btn btn-block btn-primary btn-sm removeRadius"
                          >
                            <span>Set Reminder</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="theme-bg-white-red p-3 mx-n3 mx-sm-0 mb-5">
                    {/* ======================================== Base Service ======================================== */}

                    <div className="row mb-3">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-8">
                            <h5>
                              e<strong>sense</strong>tials{' '}
                              <strong>Base Service</strong>
                            </h5>
                            <p className="small">
                              Includes 23 key vehicle checks and actions.{' '}
                              <br className="d-none d-xl-inline" />A cost
                              effective service with all the advantages{' '}
                              <br className="d-none d-xl-inline" />
                              of Hyundai Dealer Servicing.
                            </p>
                            <p className="small">
                              What's included in your e<strong>sense</strong>
                              tials service?
                            </p>
                          </div>
                          <div className="col-4">
                            <p className="text-orange text-right theme-font-large">
                              £99.00<sup>*</sup>
                            </p>

                            <a  
                              href="https://www.hyundai.co.uk/owning/book-a-service"
                              target="_blank" rel="noopener noreferrer"
                              className="btn btn-block btn-orange btn-sm removeRadius"
                            >
                              <span>Book Now</span>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <a  
                          href="#baseService"
                          className="theme-bg-orange px-3 py-1 text-white text-decoration-none w-100 d-block"
                          data-toggle="collapse"
                        >
                          e<strong>sense</strong>tials{' '}
                          <strong>Base Service</strong>
                          <span className="float-right font-weight-bold">
                            £99.00<sup>*</sup>
                          </span>
                        </a>
                        <div id="baseService" className=" collapse ">
                          <ul className="theme-essentials-list list-group list-group-flush small">
                            {/* Base Service List */}
                            {BaseService.map((item, index) => {
                              return (
                                <li
                                  className="list-group-item d-flex justify-content-between align-items-center"
                                  key={index}
                                >
                                  <span>{item}</span>
                                  <span>
                                    <IconContext.Provider value={iconStyleBase}>
                                      <FiCheckCircle />
                                    </IconContext.Provider>
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>

                    {/* ====================================== Interim Service ======================================= */}

                    <div className="row mb-3">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-8">
                            <h5>
                              e<strong>sense</strong>tials{' '}
                              <strong>Interim Service</strong>
                            </h5>
                            <p className="small">
                              Includes 32 key vehicle checks and actions.{' '}
                              <br className="d-none d-xl-inline" />A cost
                              effective, in depth service with all the{' '}
                              <br className="d-none d-xl-inline" />
                              advantages of Hyundai Dealer servicing.
                            </p>
                            <p className="small">
                              What's included in your e<strong>sense</strong>
                              tials service?
                            </p>
                          </div>
                          <div className="col-4">
                            <p className="text-info text-right theme-font-large">
                              £169.00<sup>*</sup>
                            </p>

                            <a  
                              href="https://www.hyundai.co.uk/owning/book-a-service"
                              target="_blank" rel="noopener noreferrer"
                              className="btn btn-block btn-info btn-sm removeRadius"
                            >
                              <span>Book Now</span>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <a  
                          href="#interimService"
                          className="theme-bg-light-blue px-3 py-1 text-white text-decoration-none w-100 d-block"
                          data-toggle="collapse"
                        >
                          e<strong>sense</strong>tials{' '}
                          <strong>Interim Service</strong>
                          <span className="float-right font-weight-bold">
                            £169.00<sup>*</sup>
                          </span>
                        </a>
                        <div id="interimService" className="collapse">
                          <ul className="theme-essentials-list list-group list-group-flush small">
                            <li className="list-group-item d-flex justify-content-center align-items-center theme-bg-lighter-blue">
                              <span className="font-weight-bold small">
                                INCLUDES ALL BASE LEVEL CHECKS PLUS:
                              </span>
                            </li>

                            {/* Interim Service List */}
                            {InterimService.map((item, index) => {
                              return (
                                <li
                                  className="list-group-item d-flex justify-content-between align-items-center"
                                  key={index}
                                >
                                  <span>{item}</span>
                                  <span>
                                    <IconContext.Provider
                                      value={iconStyleInterim}
                                    >
                                      <FiCheckCircle />
                                    </IconContext.Provider>
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>

                    {/* ======================================== Full Service ======================================== */}

                    <div className="row">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-8">
                            <h5>
                              e<strong>sense</strong>tials{' '}
                              <strong>Full Service</strong>
                            </h5>
                            <p className="small">
                              Includes 39 key vehicle checks and actions.{' '}
                              <br className="d-none d-xl-inline" />
                              The Full Service is the top level in the Hyundai{' '}
                              <br className="d-none d-xl-inline" />e
                              <strong>sense</strong>tials programme. A
                              comprehensive yet{' '}
                              <br className="d-none d-xl-inline" />
                              cost effective, in depth service with all the{' '}
                              <br className="d-none d-xl-inline" />
                              advantages of Hyundai Dealer servicing.
                            </p>
                            <p className="small">
                              What's included in your e<strong>sense</strong>
                              tials service?
                            </p>
                          </div>
                          <div className="col-4">
                            <p className="text-primary text-right theme-font-large">
                              £229.00<sup>*</sup>
                            </p>

                            <a  
                              href="https://www.hyundai.co.uk/owning/book-a-service"
                              target="_blank" rel="noopener noreferrer"
                              className="btn btn-block btn-primary btn-sm removeRadius"
                            >
                              <span>Book Now</span>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <a  
                          href="#fullService"
                          className="theme-bg-dark-blue px-3 py-1 text-white text-decoration-none w-100 d-block"
                          data-toggle="collapse"
                        >
                          e<strong>sense</strong>tials{' '}
                          <strong>Full Service</strong>
                          <span className="float-right font-weight-bold">
                            £229.00<sup>*</sup>
                          </span>
                        </a>
                        <div id="fullService" className="collapse">
                          <ul className="theme-essentials-list list-group list-group-flush small">
                            <li className="list-group-item d-flex justify-content-center align-items-center theme-bg-lighter-dark-blue">
                              <span className="font-weight-bold small">
                                INCLUDES ALL BASE &amp; INTERIM LEVEL CHECKS
                                PLUS:
                              </span>
                            </li>

                            {/* Interim Service List */}
                            {FullService.map((item, index) => {
                              return (
                                <li
                                  className="list-group-item d-flex justify-content-between align-items-center"
                                  key={index}
                                >
                                  <span>{item}</span>
                                  <span>
                                    <IconContext.Provider value={iconStyleFull}>
                                      <FiCheckCircle />
                                    </IconContext.Provider>
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>

                    {/* //End */}
                  </div>
                  {/*  */}

                  <div className="row mb-5">
                    {/* //Book Online */}
                    <div className="col-6">
                      <div className="row">
                        <div className="col-10 col-sm-8 mx-auto">
                          <a  
                            href="https://www.hyundai.co.uk/owning/book-a-service"
                            target="_blank" rel="noopener noreferrer"
                            className="btn btn-block btn-orange btn-sm removeRadius"
                          >
                            <span>Book Online</span>
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* //Set an remindersBlue  */}
                    <div className="col-6">
                      <div className="row">
                        <div className="col-10 col-sm-8 mx-auto">
                          <Link
                            to="/myReminders"
                            className="btn btn-block btn-primary btn-sm removeRadius"
                          >
                            <span>Set Reminder</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*  */}
                  <div className="row mb-5">
                    <div className="col-12">
                      <div className="text-center ">
                        <sup className="align-sub font-size-reset">*</sup>
                        <span className="small">
                          Addition of MOT + £40.00. SUV Supplement + £20.00.
                        </span>
                        <br />
                        <sup>†</sup>Average AA renewal price.
                      </div>
                    </div>
                  </div>

                  {/*  */}
                </div>
              </div>
            </div>
          </section>
          <FooterNavbar />
        </div>

        <Footer />
      </div>
    );
  }
}
