import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';

export default class KonaAwards extends Component {
  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="KonaAwards" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="konaAwards-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/e-kona1.jpg") ',
              }}
            />
            <div
              id="konaAwards-gallery-3"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/e-kona3.jpg")',
              }}
            />
            <div
              id="konaAwards-gallery-2"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/e-kona2.jpg")',
              }}
            />
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#konaAwards-gallery-1"
                    style={{
                      backgroundImage: 'url("images/e-kona1_th.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#konaAwards-gallery-3"
                    style={{
                      backgroundImage: 'url("images/e-kona3.jpg")',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#konaAwards-gallery-2"
                    style={{
                      backgroundImage: 'url("images/e-kona2_th.jpg")',
                    }}
                  />
                </div>
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “Hyundai has won three major accolades at the Next Green Car
                  Awards”
                </p>
              </div>
              <hr />

              <NewsMedia
                articleId="KonaAwards"
                articleText="Auto Express Awards"
              />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    GOLD STAR FOR GREEN CARS
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    {' '}
                    When it comes to going green, Hyundai is setting the gold
                    standard! Having been recently named Electric Vehicle
                    Manufacturer of the Year at the GreenFleet Awards, Hyundai
                    has won three major accolades at the Next Green Car Awards.
                  </p>
                  {displayShowMore && (
                    <p>
                      <a
                        data-toggle="collapse"
                        href="#konaAwards"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                  {/* <p className="theme-news-body-text">The i30 Fastback’s sloping roofline, long bonnet and muscular body, along with its slim cabin and wide wheel arches give the car a strong stance. That strength, however, is far from being merely skin deep.</p> */}
                </div>
              </div>

              <div className={moreSectionClassName} id="konaAwards">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      Kona Electric was singled out for special praise by the
                      judges, picking up Family Car of the Year and the coveted
                      Car of the Year titles for its extraordinary value for
                      money and family-friendly practicality.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      "With its 279-mile range, the Hyundai Kona Electric
                      represents outstanding value for money when compared with
                      rival offerings. Hyundai is the first to bring a
                      long-distance EV (Electric Vehicle) to mass-market
                      customers – and with such a capable package,” the judging
                      panel said.{' '}
                    </p>
                  </div>
                </div>

                {/* <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {" "}
                      The SUV will feature the 2.2 CRDi “R” engine,
                      which has been upgraded to further reduce
                      emissions, and will be available with front
                      wheel drive or 4WD, with a 6-speed manual
                      gearbox or an all-new 8-speed automatic
                                  transmission.{" "}
                    </p>
                  </div>
                </div> */}

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      Hyundai is investing significantly in low and zero
                      emissions powertrains, and is currently the only
                      manufacturer to offer hybrid, plug-in hybrid, fully
                      electric and Fuel Cell vehicles, with the NEXO Fuel Cell
                      Vehicle due to go on sale early in the new year. This
                      proved to be more than enough for Hyundai to add the
                      flagship Manufacturer of the Year title to its earlier
                      brace of awards, proving that three definitely is the
                      magic number.{' '}
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      “While Hyundai's Kona Electric has been making headlines
                      this year, the company's plug-in activities are far more
                      significant than just one model. Now with the IONIQ
                      Electric, IONIQ Plug-In, and Kona Electric, Hyundai is
                      becoming a class-leading electric brand," said the Next
                      Green Car Awards judges.{' '}
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      Tony Whitehorn, Hyundai Motor UK President and CEO, was
                      thrilled with his company’s hat-trick. “We have a very
                      clear mission to become a world-leader in electrified and
                      fuel cell vehicles, and it gives me great pleasure to see
                      that the big strides we have already achieved are gaining
                      recognition,” he said. “The future of both our fuel cell
                      and other electrified powertrains is taking shape as we
                      speak, but I guarantee you there’s much more to come.”{' '}
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      Hyundai’s commitment to reducing emissions will see 18 new
                      electrified models introduced by 2025, with three new
                      fully electric vehicles by 2022. Watch this space!{' '}
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text"> 21/12/2018</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
