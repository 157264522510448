import React, { Component } from 'react';
import './whnArticle.css';
import TopNavbar from '../components/topnavBarWHN';
import NavbarLoggedOut from '../components/NavBarWHN';
import NavbarLoggedIn from '../components/bottomnavbar';
import BottomNavBar from '../components/bottomnavbarWHN';
import Footer from '../components/footerWHN';
import Store from 'store';
import WHNItem from '../components/whnItem';
import FooterNavbar from '../components/footerNavbar';
import DateFormat from '../utils/DateFormat';
import Form from '../components/competitionForm';
import ReactGA from 'react-ga';
import ArticleFunction from '../utils/Articles';

export default class whnArticle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loggedInNav: false,
      articles: [],
      article: [],
      relatedArticles: [],
      id: '',
      articleTitle: '',
      related: [],
      form: {
        inCompetition: false,
        includeEmailPreference: false,
        includePhonePreference: false,
        includeAnswer: false,
        headerText: '',
        privacyPolicy: '',
        termsAndConditions: '',
      },
    };
  }

  UpdateArticle() {
    const articleID = this.props.match.params.id;

    let articles;
    const articlesFromStore = Store.get('articles');
    let article;
    const articleFromStore = Store.get('article');
    let articleTitle;
    let relatedArticles;
    let formObject;

    if (ArticleFunction.IsPresentInStore(articlesFromStore, 'articles')) {
      if (articlesFromStore == undefined) {
        articles = Store.get('articles');
      } else {
        articles = articlesFromStore;
      }
    } else {
      if (ArticleFunction.IsPresentInState(this.state.articles)) {
        articles = this.state.articles;
      }
    }

    this.setState({
      articles: articles,
    });

    if (this.state.article == undefined || this.state.article.length == 0) {
      if (ArticleFunction.IsPresentInStore(articleFromStore, 'article')) {
        if (articleFromStore == undefined) {
          article = Store.get('article');
        } else {
          article = articleFromStore;
        }
      } else {
        if (ArticleFunction.IsPresentInState(this.state.article)) {
          article = this.state.article;
          articleTitle = article.name;
        } else {
          if (ArticleFunction.IDPropsPresent(this.props)) {
            articleTitle = ArticleFunction.SortIDString(
              this.props.match.params.id,
            );
            article = ArticleFunction.GetArticleByTitle(articles, articleTitle);
          }
        }
      }
    } else {
      const articleFromState = this.state.article;
      if (articleFromState.id != articleID) {
        articleTitle = ArticleFunction.SortIDString(this.props.match.params.id);
        article = ArticleFunction.GetArticleByTitle(articles, articleTitle);
      }
    }

    if (ArticleFunction.ObjectPresent(article)) {
      const category = article.category;
      if (category != undefined) {
        ArticleFunction.SetToActive(category.toLowerCase());
      }

      const relatedArticlesIds = article.relatedToIDs;
      if (relatedArticlesIds != undefined && relatedArticlesIds.length != 0) {
        relatedArticles = ArticleFunction.GetRelatedArticles(
          relatedArticlesIds,
        );
      }

      formObject = {
        inCompetition: article.inCompetition,
        includeEmailPreference: article.includeEmailPreference,
        includePhonePreference: article.includePhonePreference,
        includeAnswer: article.includeAnswer,
        headerText: article.headerText,
        privacyPolicy: article.privacyPolicyHtml,
        termsAndConditions: article.termsAndConditionsHtml,
      };
      this.setState({
        article: article,
        id: article.id,
      });
    }

    if (relatedArticles == undefined) relatedArticles = [];

    Store.set('articles', articles);
    Store.set('article', article);
    Store.set('whnForm', formObject);

    this.setState({
      relatedArticles: relatedArticles,
      form: formObject,
      articleTitle: this.props.match.params.id,
    });

    window.scrollTo(0, 0);
    //console.log('Article in store: ', this.state.article);
  }

  componentDidUpdate() {
    //console.log('article from Update: ', this.state.article);
    if (ArticleFunction.NewArticle(this.state.articleTitle, this.props)) {
      this.setState({
        article: [],
      });

      ReactGA.event({
        label: 'News Article',
        category: 'WHN',
        action: `News Article Viewed - ${this.props.match.params.id}`,
        gaOptions: {
          siteSpeedSampleRate: 100,
          userId: Store.get('customer') && Store.get('customer').urn,
        },
      });

      this.UpdateArticle();
      window.scrollTo(0, 0);
    }

    if (
      (!ArticleFunction.ObjectPresent(this.state.article) &&
        ArticleFunction.ObjectPresent(Store.get('article'))) ||
      (!ArticleFunction.ObjectPresent(this.state.articles) &&
        ArticleFunction.ObjectPresent(Store.get('articles')))
    ) {
      this.UpdateArticle();
    }
  }

  async componentDidMount() {
    this.UpdateArticle();
  }

  async componentWillMount() {
    let articles;
    const articlesFromStore = Store.get('articles');
    let article;
    const articleID = this.props.match.params.id;
    let articleTitle;

    let loggedIn;

    if (Store.get('loginToken') == undefined) {
      loggedIn = false;
    } else {
      loggedIn = true;
    }

    if (ArticleFunction.IsPresentInStore(articlesFromStore, 'articles')) {
      if (articlesFromStore == undefined) {
        articles = Store.get('articles');
      } else {
        articles = articlesFromStore;
      }
    } else {
      await ArticleFunction.GetArticles();
      articles = Store.get('articles');
    }

    const articleFromState = this.state.article;
    if (
      articleFromState.id != articleID &&
      ArticleFunction.ObjectPresent(articles)
    ) {
      articleTitle = ArticleFunction.SortIDString(this.props.match.params.id);
      article = ArticleFunction.GetArticleByTitle(articles, articleTitle);
    }

    this.setState({
      articles: articles,
      article: article,
      loggedInNav: loggedIn,
      articleTitle: articleTitle,
    });

    Store.set('articles', articles);
    Store.set('article', article);
    window.scrollTo(0, 0);
  }

  render() {
    const article = this.state.article;

    if (article == undefined || article.length == 0) {
      return (
        <div>
          <TopNavbar />
          {this.state.loggedInNav && <NavbarLoggedIn {...this.props} />}
          {!this.state.loggedInNav && <NavbarLoggedOut {...this.props} />}
          <BottomNavBar />

          <div id="article-div" className="container position-relative mt-3">
            <img className="p-2 col-12" />
            <div className="row">
              <div className="pl-3 pr-3 col-lg-8 col-sm-12">
                <h2
                  className="text-primary pt-2 pb-1"
                  style={{ fontWeight: 600 }}
                ></h2>
                <div className="row container pb-2">
                  <h5
                    style={{ fontWeight: 'bold' }}
                    className="pr-1 category"
                  ></h5>
                  <h5></h5>
                </div>
                <div></div>
              </div>
              <div className="pl-3 pr-3 col-lg-4 col-sm-12"></div>
            </div>
          </div>

          <br />
          {this.state.loggedInNav && <FooterNavbar />}
          <Footer />
        </div>
      );
    } else {
      const articleDate = DateFormat.formatDateFromApi(article.startDate);
      return (
        <div>
          <TopNavbar />
          {this.state.loggedInNav && <NavbarLoggedIn {...this.props} />}
          {!this.state.loggedInNav && <NavbarLoggedOut {...this.props} />}
          <BottomNavBar />

          <div id="article-div" className="container position-relative mt-3">
            <img
              className="p-2 col-12"
              src={article.mainPage.mainImage}
              alt={article.mainPage.title}
            />
            <div className="row">
              <div className="pl-3 pr-3 col-lg-8 col-sm-12">
                <h2
                  className="text-primary pt-2 pb-1"
                  style={{ fontWeight: 600 }}
                >
                  {article.name}
                </h2>
                <div className="row container pb-2">
                  <h5 style={{ fontWeight: 'bold' }} className="pr-1 category">
                    {article.category}
                  </h5>
                  <h5>| {articleDate}</h5>
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: article.mainPage.html,
                  }}
                ></div>
              </div>
              <div className="pl-3 pr-3 col-lg-4 col-sm-12">
                {this.state.relatedArticles &&
                  this.state.relatedArticles.map((article, i) => (
                    <WHNItem
                      className="card article-from-article"
                      bodytop="card-body pt-2 ml-2 mr-2 body-from-article"
                      bodybottom="card-text-div pt-3 pb-3 pl-4 pr-4 ml-2 mr-2 text-from-article row"
                      startDate={DateFormat.formatDateFromApi(
                        article.startDate,
                      )}
                      title={article.title}
                      src={article.src}
                      alt={article.alt}
                      id={article.id}
                      key={i}
                    />
                  ))}
              </div>
              {this.state.form &&
                this.state.form.inCompetition &&
                this.state.form.headerText != '' &&
                this.state.form.termsAndConditions != '' && (
                  <Form form={this.state.form} id={this.state.id} />
                )}
            </div>
          </div>

          <br />
          {this.state.loggedInNav && <FooterNavbar />}
          <Footer />
        </div>
      );
    }
  }
}
