import React, { Component } from 'react';
import './whnGrid.css';
import TopNavbar from '../components/topnavbar';
import NavbarLoggedOut from '../components/NavBarWHN';
import NavbarLoggedIn from '../components/bottomnavbar';
import BottomNavBar from '../components/bottomnavbarWHN';
import WHNItem from '../components/whnItem';
import Footer from '../components/footer';
import GetData from '../utils/GetData';
import Store from 'store';
import DateFormat from '../utils/DateFormat';
import FooterNavbar from '../components/footerNavbar';

export default class whn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filteredCategory: '',
      filteredText: '',
      articles: [],
      filteredArticles: [],
      placementArticles: [],
      loggedInNav: false,
    };
  }

  componentDidUpdate() {
    if (this.props.location.state) {
      const filteredCategory = this.props.location.state.filteredCategory;
      const filteredText = this.props.location.state.filteredText;

      if (filteredCategory == undefined && filteredText != undefined) {
        if (this.state.filteredText != filteredText) {
          this.FilterFromText(filteredText);
        }
      } else if (filteredCategory != undefined && filteredText == undefined) {
        if (this.state.filteredCategory != filteredCategory) {
          this.FilterFromNav(filteredCategory);
        }
      }
    }
  }

  SetToActive(navName) {
    const navItems = document.querySelectorAll('.whn-nav-item');
    const navItem = document.getElementById(navName.toLowerCase());
    for (let i = 0; i < navItems.length; i++) {
      const element = navItems[i];
      element.classList.remove('active');
    }
    if (navItem != null) {
      navItem.classList.add('active');
    }
  }

  componentWillMount() {
    if (Store.get('loginToken') == undefined) {
      this.setState({
        loggedInNav: false,
      });
    } else {
      this.setState({
        loggedInNav: true,
      });
    }
  }

  async componentDidMount() {
    await GetData.getArticles();
    const articles = Store.get('articles');
    this.setState({
      articles: articles,
    });
    this.FilterFromNav('');
  }

  FilterFromNav(filteredCategory) {
    const articles = this.state.articles;
    let articlesFiltered = [];

    this.SetToActive(filteredCategory);

    if (filteredCategory == '') {
      articlesFiltered = articles;
    } else {
      for (let i = 0; i < articles.length; i++) {
        const element = articles[i];
        if (element.category == filteredCategory)
          articlesFiltered.push(element);
      }
    }

    this.setState({
      filteredArticles: articlesFiltered,
      filteredCategory: filteredCategory,
      filteredText: '',
    });
  }

  FilterFromText(text) {
    const articles = this.state.articles;
    let articlesFiltered = [];
    const inputText = text.toLowerCase();

    if (text == '') {
      articlesFiltered = articles;
    } else {
      for (let i = 0; i < articles.length; i++) {
        const element = articles[i];
        const name = element.name.toLowerCase();
        const html = element.mainPage.html.toLowerCase();
        if (name.includes(inputText) || html.includes(inputText))
          articlesFiltered.push(element);
      }
    }

    this.setState({
      filteredArticles: articlesFiltered,
      filteredText: text,
      filteredCategory: '',
    });
  }

  render() {
    return (
      <div>
        <TopNavbar />
        {this.state.loggedInNav && <NavbarLoggedIn {...this.props} />}
        {!this.state.loggedInNav && <NavbarLoggedOut {...this.props} />}
        <BottomNavBar />

        <div className="container position-relative mt-3">
          <div className="row p-4">
            {this.state.filteredArticles.map((article, i) => (
              <WHNItem
                className="card col-lg-3 col-md-6 col-sm-12 article-from-grid"
                bodytop="card-body pt-2 ml-2 mr-2 body-from-grid"
                bodybottom="card-text-div pt-3 pb-3 pl-4 pr-4 ml-2 mr-2 text-from-grid row"
                startDate={DateFormat.formatDateFromApi(article.startDate)}
                category={article.category}
                title={article.previewPage.title}
                maintitle={article.mainPage.title}
                src={article.previewPage.mainImage}
                mainsrc={article.mainPage.mainImage}
                text={article.mainPage.html}
                alt={article.name}
                id={article.id}
                related={article.relatedToIDs}
                key={i}
              />
            ))}
          </div>
        </div>
        {this.state.loggedInNav && <FooterNavbar />}
        <Footer />
      </div>
    );
  }
}
