// customerType
// 0  Not Set
// 1  Customer
// 2  Prospect

// Visibility
// "optional" determines whether both "customerType" and "modelType" must be met or either.
//  optional = "or"

export const offers = [
  // {
  //   link: '/offersTate',
  //   name: 'Tate merchandise',
  //   imageSrc: 'images/tate_comp.png',
  //   imageAlt: 'Tate pictures',
  //   title: '10% off Tate merchandise',
  //   closesText: '',
  //   buttonText: 'View offer',
  //   carousel: {
  //     isRoute: false,
  //     linkPath: '/offers',
  //     imagePath: 'images/offers_tate_online.jpg',
  //     imageAlt: '10% off Tate merchandise',
  //     text: 'There are currently no offers',
  //     title: 'There are currently no offers',
  //   },
  //   visibility: {
  //     customerType: [0, 1, 2],
  //     optional: true,
  //     modelType: '',
  //     excludedType: [],
  //   },
  // },
  // MyHyundai 15% off SUV accessories - Kona
  // {
  //   link: '/offersHybrid',
  //   name: 'Drive Hybrid',
  //   imageSrc: 'images/hev_event_header_tile.jpg',
  //   imageAlt: 'Drive Hybrid',
  //   title: 'Drive Hybrid',
  //   closesText: '',
  //   buttonText: 'View offer',
  //   carousel: {
  //     isRoute: true,
  //     linkPath: '/offersHybrid',
  //     imagePath: 'images/hybrid.jpg',
  //     imageAlt: 'Drive Hybrid',
  //     text: 'Drive Hybrid',
  //     title: 'Drive Hybrid',
  //   },
  //   visibility: {
  //     customerType: [0, 1, 2],
  //     optional: true,
  //     modelType: '',
  //     excludedType: [],
  //   },
  // },
  /*{
    link: '/offersSummerCheck',
    name: 'Summer Checks',

    imageSrc: 'images/summerCheck_238x240.jpg',
    imageAlt: 'Summer Checks',
    title: 'Summer Checks',
    closesText: ' ',
    buttonText: 'View offer',
    carousel: {
      isRoute: true,
      linkPath: '/offersSummerCheck',
      imagePath: 'images/summerCheck510x189.jpg',
      imageAlt: 'accessories',
      text: 'Summer Checks',
      title: 'Summer Checks',
    },
    visibility: {
      customerType: [0, 1, 2],
      optional: true,
      modelType: '',
      excludedType: [],
      serviceOrMotOverThreeMonths: true,
    },
  },*/
];
