import React, { Component } from 'react';
import imageExists from 'image-exists';

export default class VehicleImage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayImage: false,
      useDefaultImage: false,
      imagePath: '',
      imagePathTested: false,
      vehicleId: 0,
      vehicle: this.props.vehicle || {},
    };
    this.handleImageExistsReturn = this.handleImageExistsReturn.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.vehicle == null) {
      return null;
    }

    if (nextProps.vehicle.id === prevState.vehicleId) {
      return null;
    }

    return {
      ...prevState,
      imagePath: VehicleImage.buildImagePath(nextProps),
      imagePathTested: false,
      vehicleId: nextProps.vehicle.id,
    };
  }

  componentDidMount() {
    this.testImage();
  }

  componentDidUpdate() {
    this.testImage();
  }

  testImage() {
    if (!this.state.imagePathTested) {
      if (this.state.vehicle != null) {
        imageExists(this.state.imagePath, this.handleImageExistsReturn);
      } else {
        this.setState({
          displayImage: true,
          useDefaultImage: true,
        });
      }
    }
  }

  static buildImagePath(props) {
    if (props.vehicle == null) {
      return '';
    }

    var regexResult = props.vehicle.model
      .toLowerCase()
      .match(
        /^([^\s]+)\s?(\(([^\(^0-9^\.]*)\)|([^\(^0-9^\.]*))?\s?(\(([0-9]+)\Wdoor\))?\)?/,
      );
    if (regexResult == null) {
      return '';
    }

    var baseModel = regexResult[1];
    var additional1 = regexResult[3];
    var additional2 = regexResult[4];
    var doors = regexResult[6];

    var model = baseModel;
    if (additional1) {
      model = model + additional1.substr(0, 3);
    }
    if (additional2) {
      model = model + additional2.substr(0, 3);
    }
    if (doors) {
      model = model + '-' + doors + 'D';
    }

    var version = props.vehicle.version;

    var paint = '';
    var paintParts = props.vehicle.description.split(' ');

    if (paintParts.length === 2) {
      paint = paintParts[0].substr(0, 1) + paintParts[1].substr(0, 1);
    }

    //  console.log(model + '-' + version + '-' + paint);

    return 'images/vehicles/' + model + '-' + version + '-' + paint + '.png';
  }

  handleImageExistsReturn(exists) {
    this.setState(
      {
        displayImage: true,
        useDefaultImage: !exists,
        imagePathTested: true,
      },
      () => {
        if (this.props.onChange != null) {
          this.props.onChange(this.state.useDefaultImage, this.state.imagePath);
        }
      },
    );
  }

  render() {
    var imageSrc = '';
    if (this.state.displayImage) {
      if (this.state.useDefaultImage) {
        if (this.props.defaultImage == null) {
          imageSrc = 'images/shadow-vehicle.jpg';
        } else {
          imageSrc = this.props.defaultImage;
        }
      } else {
        imageSrc = this.state.imagePath;
      }
    }

    const containerClassName =
      this.props.className == null
        ? 'vehicle-image-container mb-2'
        : this.props.className;
    const imageClassName = this.state.useDefaultImage
      ? this.props.defaultImgClassName
      : this.props.imgClassName;

    if (imageSrc !== '') {
      return (
        <div className={containerClassName}>
          <img src={imageSrc} className={imageClassName} />
          {this.props.overlayModel && this.state.useDefaultImage && (
            <div className="vehicle-image-overlay">
              {this.props.vehicle.model}
            </div>
          )}
        </div>
      );
    } else {
      return null;
    }
  }
}
