import React, { Component } from "react";

export default class WearableRobots extends Component {
  render() {
    return (
      <div className="container">
        <div className="fadeInDownBigFull">
          <div className="card-body newsPadding">
            <div className="card-deck " id="WearableRobots">
              <div className="card">
                <div className="row">
                  <div className="col-md">
                    <img
                      id="WearableRobots-gallery-1"
                      className="card-img-top img-fluid news-gallary-image active"
                      src="images/wearableRobots.jpg"
                      alt="Card image cap"
                    />
                  </div>
                </div>

                <div className="card-block">
                  <div className="card-content">
                    <div className="col">
                      <div className="row whiteBG">
                        <div className="col-lg-4">
                          <div className="salesText mt-3">
                            <p className="theme-news-snippet">
                              “Robotic developments include a wearable
                              exoskeleton to assist with heavy lifting in the
                              workplace”
                            </p>
                          </div>
                          <hr />
                        </div>

                        <div className="col-lg-8 border-lg-left">
                          <div className="row pt-3">
                            <div className="col-md-10 offset-md-1">
                              <p className="salesText" data-search-tag="title">
                                HYUNDAI MOTOR SHOWCASES ADVANCED WEARABLE ROBOTS
                                AT GENEVA MOTOR SHOW
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <div className="theme-news-body-text">
                                <ul>
                                  <li>
                                    Innovations in assistive technologies allow
                                    Hyundai Motor to deliver mobility solutions
                                    beyond the automobile
                                  </li>
                                  <li>
                                    Hyundai Motor’s wearable powered robots can
                                    provide independent personal mobility for
                                    paraplegics and the elderly
                                  </li>
                                  <li>
                                    Robotic developments include a wearable
                                    exoskeleton to assist with heavy lifting in
                                    the workplace
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div className="row pt-3">
                            <div className="col-md-10 offset-md-1">
                              <p
                                className="theme-news-body-text"
                                data-search-tag="summary"
                              >
                                <strong>
                                  Geneva Motor Show, 07 March 2017
                                </strong>{" "}
                                – Hyundai Motor will showcase new wearable
                                powered robots that will revolutionize the
                                future of personal mobility at the 2017 Geneva
                                Motor Show. The advanced assistive medical, work
                                and daily life exoskeletons demonstrate Hyundai
                                Motor’s ambition to provide customers with
                                personal mobility solutions and freedom beyond
                                the automobile.
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <div className="theme-news-body-text">
                                <p>
                                  The H-MEX (Hyundai Medical EXoskeleton) helps
                                  patients with lower spinal cord injuries
                                  regain the ability to walk. By utilizing a
                                  wireless clutch with on-board motion control
                                  system, the equipment gives paraplegics the
                                  ability to sit, stand, move, turn and even
                                  walk up or down stairs.
                                </p>
                                <p>
                                  The HUMA (Hyundai Universal Medical Assist)
                                  exoskeleton operates on a different control
                                  principle, but it provides assisted mobility
                                  support for people with limited muscular
                                  power. It provides assistive torque to help
                                  those with limited mobility to easily move
                                  their bodies. By adjusting assistive torque
                                  according to each form of movement, such as
                                  walking, running, or going up and down stairs,
                                  HUMA assists users to move effortlessly. It
                                  can support up to 40kg of a wearer’s weight
                                  and, unloaded, can run at a speed up to 12km/h
                                  – capabilities that would benefit for
                                  military/industrial purposes such as
                                  load-carrying. Meanwhile, the Hyundai Waist
                                  EXoskeleton, known as H-WEX, provides
                                  upper-body and hip support to prevent back
                                  injuries for workers doing repetitive manual
                                  work or lifting heavy objects.
                                </p>
                                <p>
                                  H-MEX and HUMA are powered by removable and
                                  rechargeable battery packs and their
                                  light-weight frames are adjustable in length
                                  to fit different sizes of user for ultimate
                                  comfort. The lower back and knees are
                                  supported with harness fixture points, while
                                  the devices change shape and flexes around the
                                  artificial joint structures of the robotic
                                  frame to manage body posture and deliver gait
                                  efficiency for walking.
                                </p>
                                <p>
                                  In this vein, H-MEX provides
                                  individually-tailored gait pattern adjustment
                                  by calculating a series of factors, including
                                  walking pace, length of stride, and torso
                                  tilting angle via an application program
                                  installed in a smart device. Moreover, HUMA
                                  uses a series of advanced joints and
                                  mechanisms to align its movement with that of
                                  the user, enabling agile motion.
                                </p>
                                <p>
                                  The H-WEX takes similar form but instead
                                  provides a safety solution for the workplace,
                                  assisting users with heavy lifting and other
                                  strenuous or repetitive activities. Activating
                                  the Waist Assist function enables the
                                  exoskeleton to flex its joints at up to 180
                                  degrees per second, with an operating
                                  algorithm built-in to ensure ultimate user
                                  safety. As with the other exoskeletons, the
                                  frame of the device supports and protects the
                                  user’s lower back and upper legs for optimal
                                  body positioning and is light-weight to ensure
                                  portability and ease of use.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
