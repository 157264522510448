import React, { Component } from "react";

export default class IoniqPlugin extends Component {
  render() {
    return (
      <div className="container">
        <div className="fadeInDownBigFull">
          <div className="card-body newsPadding">
            <div className="card-deck " id="IoniqPlugin">
              <div className="card">
                <div className="row">
                  <div className="col-md">
                    <img
                      id="IoniqPlugin-gallery-1"
                      className="card-img-top img-fluid news-gallary-image active"
                      src="images/ioniqPlugin.jpg"
                      alt="Card image cap"
                    />
                  </div>
                </div>

                <div className="card-block">
                  <div className="card-content">
                    <div className="col">
                      <div className="row whiteBG">
                        <div className="col-lg-4">
                          <div className="salesText mt-3">
                            <p className="theme-news-snippet">
                              “Hyundai Motor’s global sustainability strategy
                              aims to launch 14 eco cars until 2020”
                            </p>
                          </div>
                          <hr />
                        </div>

                        <div className="col-lg-8 border-lg-left">
                          <div className="row pt-3">
                            <div className="col-md-10 offset-md-1">
                              <p className="salesText" data-search-tag="title">
                                COMBINING THE BEST OF BOTH WORLDS: IONIQ PLUG-IN
                                AT THE 2017 GENEVA INTERNATIONAL MOTOR SHOW
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <div className="theme-news-body-text">
                                <ul>
                                  <li>
                                    The IONIQ is world’s first car which offers
                                    three electrified powertrains: hybrid,
                                    electric and plug-in hybrid – the IONIQ
                                    Plug-in, showcased at the 2017 Geneva
                                    International Motor Show, is the latest
                                    IONIQ version to be launched.
                                  </li>
                                  <li>
                                    The IONIQ Plug-in combines the best of both
                                    worlds offering up to 63 km of electric
                                    driving range and CO2emissions as low as 26
                                    g/km with fuel consumption of only 1.1 l/100
                                    km (NEDC combined cycle).
                                  </li>
                                  <li>
                                    The IONIQ combines award-winning aerodynamic
                                    design, state-of-the-art connectivity
                                    features, as well as a maximum five-star
                                    safety rating from Euro NCAP for an
                                    appealing e-mobility package.
                                  </li>
                                  <li>
                                    Hyundai Motor’s global sustainability
                                    strategy aims to launch 14 eco cars until
                                    2020
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div className="row pt-3">
                            <div className="col-md-10 offset-md-1">
                              <p
                                className="theme-news-body-text"
                                data-search-tag="summary"
                              >
                                <strong>Geneva Motor Show, 7 March 2017</strong>{" "}
                                – At the 2017 Geneva International Motor Show,
                                Hyundai Motor is showcasing the IONIQ line-up
                                with focus on the IONIQ Plug-in, due to arrive
                                in showrooms in the UK in July. The IONIQ is the
                                world’s first car with three electrified
                                powertrains. The IONIQ Plug-in is the last model
                                to be launched after the successful market
                                introduction of the IONIQ Hybrid and Electric in
                                the second half of 2016. The IOINQ line-up
                                democratises technology making e-mobility more
                                accessible to a wider audience. The IONIQ is
                                part of Hyundai Motor’s global sustainability
                                strategy to launch 14 eco cars until 2020
                                including five hybrid vehicles, four plug-in
                                hybrid vehicles, four electric vehicles and one
                                fuel-cell electric vehicle.
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <div className="theme-news-body-text">
                                <p>
                                  “With the launch of the IONIQ Plug-in we are
                                  completing the IONIQ family and making
                                  e-mobility more accessible for everyone.” says
                                  Jochen Sengpiehl, Vice President Marketing at
                                  Hyundai Motor Europe. “With the choice of
                                  three electrified powertrains, the IONIQ is an
                                  unique offer – with no compromise as regards
                                  to great design, driving pleasure and new
                                  connectivity features at a competitive price.
                                  It’s a car that is truly driven by e-motion.”
                                </p>
                                <p>
                                  Since launch of the IONIQ Hybrid in the second
                                  half of 2016, the IONIQ has received thirteen
                                  European awards, with several ‘Car of the
                                  Year’ titles across Europe for its overall
                                  performance, a 2016 Red Dot design award and a
                                  five-star Euro NCAP rating in recognition of
                                  its outstanding safety.
                                </p>
                                <p>
                                  {" "}
                                  IONIQ Plug-in combining the best of both
                                  worlds
                                </p>
                                <p>
                                  The IONIQ Plug in combines Hyundai Motor’s new
                                  1.6 GDI direct-injection petrol, four-cylinder
                                  engine with class-leading thermal efficiency
                                  of 40 percent delivering maximum power of 105
                                  PS and 147 Nm of torque with a 45 kW (61 PS)
                                  electric motor that is powered by a 8.9 kWh
                                  lithium-ion polymer battery to enable electric
                                  driving range up to 63 km while reducing CO2
                                  emission to 26 g/km* and fuel consumption to
                                  1.1 l/100 km* (NEDC combined cycle).
                                </p>
                                <p>
                                  Direct driver engagement and great standard
                                  safety
                                </p>
                                <p>
                                  The IONIQ Plug-in has been engineered for
                                  optimum driving dynamics and ride comfort.
                                  Hyundai Motor’s in-house developed six-speed
                                  dual-clutch transmission enhances driver
                                  engagement while increasing efficiency. The
                                  different drive modes enhance the driving
                                  experience. The Sport Mode applies a sportier
                                  steering feel, different transmission logic
                                  and a sporty design for the 7-inch TFT digital
                                  instrument cluster.
                                </p>
                                <p>
                                  The lightweight structure, multilink rear
                                  suspension and intelligent powertrain layout
                                  also contribute to the driving experience of
                                  the IONIQ Plug-in. Advanced active safety
                                  technologies are made accessible with
                                  Autonomous Emergency Braking including
                                  pedestrian detection, Lane Keeping Assist
                                  System and Smart Cruise Control as standard
                                  features. Additionally customers can choose
                                  Blind Spot Detection including Rear Cross
                                  Traffic Alert.
                                </p>
                                <p>
                                  The light and rigid body features 53% Advanced
                                  High Strength Steel for better ride and
                                  handling characteristics while enhancing
                                  passive safety through high impact energy
                                  absorption and minimised distortion in the
                                  event of a collision.
                                </p>
                                <p>
                                  Appealing design with class-leading
                                  aerodynamics
                                </p>
                                <p>
                                  The IONIQ Plug-in’s exterior features LED
                                  headlamps and specially designed 16-inch
                                  wheels, while sharing most general details
                                  with the Hybrid, such as the front grille, the
                                  vertical C-shaped LED daytime running lights
                                  and an elegant blue or silver character line
                                  below the front and rear bumpers. The sleek
                                  aerodynamic shape of the IONIQ helps to
                                  achieve a class-leading drag coefficient of
                                  just 0.24 Cd and creates an appealing
                                  silhouette.
                                </p>
                                <p>
                                  Connectivity and driving experience for the
                                  mobile generation
                                </p>
                                <p>
                                  The IONIQ delivers intuitive smart
                                  connectivity and infotainment features via its
                                  eight-inch high resolution touchscreen
                                  including Apple CarPlay and Android Auto,
                                  wireless inductive charging, LIVE services for
                                  real-time traffic information as well as
                                  Hyundai Motor’s unique Lifetime MapcareTM**
                                  offering free annual map and software updates.
                                </p>
                                <p>
                                  The IONIQ Plug-in enhanced the digital driving
                                  experience through its seven-inch digital
                                  information cluster. With a high resolution of
                                  1,280 x 720 pixels, the TFT screen displays
                                  all cluster information like the vehicle
                                  speed, drive range, navigation information,
                                  drive modes, battery status etc. Depending on
                                  the selected drive mode, the layout of
                                  instrument cluster is changing. In Sport Mode
                                  the display changes into a revolving digital
                                  speedometer surrounded by an analogue-style
                                  tachometer, indicating the engine’s rpm on red
                                  coloured background.
                                </p>
                                <p>IONIQ Hybrid</p>
                                <p>
                                  The IONIQ Hybrid’s electric motor delivers 32
                                  kW (43.5 PS) with maximum torque of 170 Nm,
                                  powered by a lithium-ion-polymer battery with
                                  1.56 kWh capacity positioned under the rear
                                  seats. In combination with the 1.6-GDI engine,
                                  IONIQ Hybrid offers a system output of 103.6
                                  kW (141 PS) rpm and up to 265 Nm of torque
                                  accelerating to a top speed of up to 185 km/h
                                  with CO2 emissions as low as 79 g/km
                                  (combined).
                                </p>
                                <p>IONIQ Electric</p>
                                <p>
                                  The IONIQ Electric offers pure e-mobility
                                  through a 28 kWh lithium-ion polymer battery
                                  for a maximum range of over 280 km (based on
                                  NEDC). The instantly available maximum torque
                                  of 295 Nm is delivered by the electric motor
                                  with a maximum output of 88 kW (120 PS)
                                  through the single-speed reducer transmission
                                  accelerating the car up to 165 km/h.
                                </p>
                                <p>200,000-kilometre warranty on the battery</p>
                                <p>
                                  In addition to the industry-leading Five Years
                                  Unlimited Mileage Warranty, Hyundai Motor
                                  includes an enhanced package for customers who
                                  opt for the IONIQ, adding an eight-year,
                                  200,000-kilometre warranty on the high-voltage
                                  battery. Together with a comprehensive list of
                                  standard features and a competitive price, the
                                  IONIQ Plug-in provides unique value among
                                  alternative-powertrain cars.
                                </p>
                                <p>
                                  Thomas Schmid, Chief Operating Officer at
                                  Hyundai Motor Europe, says: “Since the
                                  successful European launch of the IONIQ Hybrid
                                  and Electric in autumn of 2016, we are
                                  continuing to electrify Europe with the
                                  introduction of the IONIQ Plug-in. It is
                                  another step towards our goal to launch 14 eco
                                  cars until 2020 worldwide to reduce emissions
                                  and to make future mobility more appealing to
                                  a wider audience.”
                                </p>
                                <p>
                                  * Fuel efficiency, CO2 emissions and driving
                                  range data is provisional, subject to
                                  homologation.
                                </p>
                                <p>
                                  **Lifetime MapCare™ is the commercial term of
                                  Hyundai’s subscription to MapCare™ programme
                                  by which annual map updates will be provided
                                  for the eligible Hyundai vehicles for a
                                  minimum period of ten years after the model’s
                                  end of production.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
