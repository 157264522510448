import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';
import Store from 'store';
import ReactGA from 'react-ga';
export default class I20NWinner extends Component {
  //componentDidMount = () => {};

  clickHandler = () => {};

  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="bestCarAwards-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/rewardi20NTile.jpeg") ',
              }}
            />
            {/* <div
              id="bestCarAwards-gallery-2"
              className="theme-news-header-image "
              style={{
                backgroundImage: 'url("images/bca1.jpg") ',
              }}
            />
            <div
              id="bestCarAwards-gallery-3"
              className="theme-news-header-image "
              style={{
                backgroundImage: 'url("images/bca2.jpg") ',
              }}
            />
            <div
              id="bestCarAwards-gallery-4"
              className="theme-news-header-image "
              style={{
                backgroundImage: 'url("images/bca3.jpg") ',
              }}
            />
            <div
              id="bestCarAwards-gallery-5"
              className="theme-news-header-image "
              style={{
                backgroundImage: 'url("images/bca4.jpg") ',
              }}
            />
            <div
              id="bestCarAwards-gallery-6"
              className="theme-news-header-image "
              style={{
                backgroundImage: 'url("images/bca5.jpg") ',
              }}
            /> */}
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                {/* <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#bestCarAwards-gallery-2"
                    style={{
                      backgroundImage: 'url("images/bca1.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#bestCarAwards-gallery-3"
                    style={{
                      backgroundImage: 'url("images/bca2.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#bestCarAwards-gallery-4"
                    style={{
                      backgroundImage: 'url("images/bca3.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#bestCarAwards-gallery-5"
                    style={{
                      backgroundImage: 'url("images/bca4.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#bestCarAwards-gallery-6"
                    style={{
                      backgroundImage: 'url("images/bca5.jpg") ',
                    }}
                  /> */}
                {/* </div> */}
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “Major wins for Hyundai i20 N and SANTA FE at 2023 What Car?
                  Awards”
                </p>
              </div>
              <hr />

              <NewsMedia articleId="awards" articleText="awards" />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    MAJOR WINS FOR HYUNDAI i20N AND SANTA FE AT 2023 WHAT CAR?
                    AWARDS
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    {' '}
                    <ul>
                      <li>
                        Hyundai collects three major honours at the 2023 What
                        Car? Awards
                      </li>
                      <li>
                        Exhilarating i20 N crowned ‘Hot Hatch of the Year’
                      </li>
                      <li>
                        Judges praise its “brilliant body control and grip”
                      </li>
                      <li>
                        SANTA FE named ‘Seven-seater of the Year’ and ‘Best
                        Plug-in Hybrid for Big Families’
                      </li>
                      <li>
                        Hailed for its “practicality, upmarket feel and comfort”
                      </li>
                    </ul>
                  </p>
                  {displayShowMore && (
                    <p>
                      <a
                        data-toggle="collapse"
                        href="#i20n"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </div>

              <div className={moreSectionClassName} id="i20n">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      <strong>
                        Hyundai’s impressive start to 2023 has continued with
                        two of its key models picking up wins at the What Car?
                        Car of the Year Awards.
                      </strong>
                      <br />
                      <br />
                      The What Car? team of expert judges named the i20 N as the
                      number one choice in the fiercely fought ‘Hot Hatch of the
                      Year’ category, while the SANTA FE SUV was crowned
                      ‘Seven-seater of the Year’ and recognised as the ‘Best
                      Plug-in Hybrid for Big Families’.
                      <br />
                      <br />
                      The Hyundai i20 N, which is priced from £25,750 and is
                      powered by a turbocharged 204ps 1.6-litre T-GDi engine,
                      has attracted a string of rave reviews since its
                      introduction in 2021, thanks to a compelling blend of
                      thrilling performance and pin-sharp handling.
                    </p>
                  </div>
                </div>

                {/* <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      Early in January, the{' '}
                      <a
                        href="https://www.hyundai.news/uk/brand/hyundai-i10-is-what-cars-best-small-car-for-the-city-2021/"
                        target="_blank"
                      >
                        all-new i10 was named What Car?’s Best Small Car for the
                        City,
                      </a>{' '}
                      then the previous generation{' '}
                      <a
                        href="https://www.hyundai.news/uk/model-news/hyundai-scores-double-win-at-what-car-used-car-awards/"
                        target="_blank"
                      >
                        i20 was named as First Car’s Used Car of the Year
                      </a>{' '}
                      as a ‘safe, reliable and easy to drive’ car for new
                      drivers. Continuing Hyundai’s small car wins, carwow named
                      the i10 as its Best Small Car, saying: “it’s a doddle to
                      drive but still roomy enough for four adults and their
                      stuff. It’s big on technology, too.”
                    </p>
                  </div>
                </div> */}

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {/* <img src="images/bca1.jpg" className="img-fluid" /> */}
                      <span style={{ fontSize: '1rem' }}></span>{' '}
                      <strong>
                        “The Hyundai i20 N harks back to the days when hot
                        hatches were all about keeping costs low and enjoyment
                        high. On a winding road, you can take full advantage of
                        its brilliant body control and grip, thanks to precise,
                        well weighted steering that allows you to place the car
                        exactly where you want it. And on the way out of
                        corners, the limited-slip differential allows you to
                        deploy maximum power with minimal tyre scrabble.”
                      </strong>{' '}
                      - Steve Huntingford, What Car? Editor
                      <br />
                      <br />
                      The Hyundai SANTA FE, priced from £42,445, continues to be
                      one of the UK’s most popular seven-seater cars, with
                      elegant styling, a spacious interior and generous,
                      high-tech equipment. Customers are offered the choice of
                      powerful electrified Hybrid and Plug-in Hybrid versions,
                      as well as an efficient diesel.
                      <br />
                      <br />
                      <strong>
                        "In a lot of seven-seaters, the third row is strictly
                        for emergencies, but the Hyundai SANTA FE has room for
                        seven adults. Crucially, though, this practicality
                        doesn't come at the expense of an upmarket feel, while
                        the comfortable ride and generous standard equipment add
                        further to the car's appeal. The 1.6 T-GDi Plug-in
                        Hybrid variant will save company car drivers a lot of
                        money, while the non-plug-in 1.6 T-GDi Hybrid is
                        similarly appealing as a private buy."
                      </strong>{' '}
                      - Steve Huntingford, What Car? Editor
                      <br />
                      <br />
                      <img
                        src="images/santafewinner.jpeg"
                        className="img-fluid"
                      />
                      <br />
                      <br />
                      Despite 2023 being only three weeks old, Hyundai’s year
                      has started in impressive fashion. Already the IONIQ 5 has
                      been named Large Electric Car of the Year by SME fleet
                      specialist Company Car & Van, while the European New Car
                      Assessment Programme recognised the IONIQ 6’s safety
                      credentials by awarding it ‘Best in Class’ in the ‘Large
                      Family Car’ category.
                      {/* <img src="images/bca3.jpg" className="img-fluid" /> */}
                      <br />
                      <br />
                      Additionally, the release early in January of the UK’s
                      latest car registration figures by the Society of Motor
                      Manufacturers and Traders revealed that Hyundai had
                      recorded its largest ever market share of 5.0% in 2022.
                      {/* <img src="images/bca4.jpg" className="img-fluid" /> */}
                      <br />
                      <br />
                      <strong>
                        "The awards from What Car? are particularly pleasing as
                        they illustrate the depth of Hyundai’s current range of
                        vehicles. Our N cars have created a massive impact in a
                        short space of time, and the i20 N is a fantastic
                        example of an exciting performance model that can be
                        comfortably driven on a daily basis, while the SANTA FE
                        offers a fantastic blend of practicality, comfort and
                        electrification for SUV customers."
                      </strong>{' '}
                      - Ashley Andrew, Managing Director of Hyundai Motor UK
                      <br />
                      <br />
                      {/* Showcasing the strength of the full model range, a sixth
                      award was presented to Hyundai as the i20 N won ‘Best Hot
                      Hatchback’ of the year.
                      <br />
                      <br />
                      <strong>Richard Ingram said:</strong> “Hyundai has turned
                      the hot hatch market on its head – first with the i30 N
                      and more recently with the smaller but just as riotous i20
                      N. Frenetic, agile and quick, the compact Hyundai will
                      keep up with most sports cars but with the added benefit
                      of five doors and a big boot.”
                      <br />
                      <br />
                      <img src="images/bca5.jpg" className="img-fluid" />
                      <br />
                      <br />
                      Like every driver-focused Hyundai N model, the i20N was
                      born at Namyang, Hyundai Motorsport’s Global Research &
                      Development Centre in South Korea, and honed on the
                      Nürburgring Nordschleife race circuit in Germany.
                      Featuring a potent 204PS turbocharged petrol engine and a
                      mechanical limited-slip differential, the i20N delivers
                      stunning performance and ultra-agile handling on road and
                      track. Pioneering connected and safety technologies
                      enhance every journey. Like every Hyundai model sold in
                      the UK, the i20N provides a five-year unlimited mileage
                      warranty, five annual vehicle health checks and roadside
                      assistance. Pricing begins at £24,995.
                      <br />
                      <br />
                      <strong>
                        Ashley Andrew, Managing Director of Hyundai Motor UK
                        said:
                      </strong>
                      “We are delighted that the Hyundai TUCSON has been
                      presented with the coveted ‘Car of the Year’ award by the
                      panel of experts at Carbuyer. This endorsement is further
                      validation that the latest generation TUCSON remains one
                      of the best SUVs in the UK market, as well as the best
                      hybrid available. We are also thrilled to see the wider
                      Hyundai range recognised across a number of prestigious
                      honours. IONIQ 5 continues to set the standard for
                      electric mobility, whilst the i20 N is recognised as the
                      best hot hatchback yet again.”
                      <br />
                      <br /> */}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
