import React, { Component } from "react";

export default class AutonomousIoniq extends Component {
  render() {
    return (
      <div className="container">
        <div className="fadeInDownBigFull">
          <div className="card-body newsPadding">
            <div className="card-deck " id="AutonomousIoniq">
              <div className="card">
                <div className="row">
                  <div className="col-md">
                    <img
                      id="AutonomousIoniq-gallery-1"
                      className="card-img-top img-fluid news-gallary-image active"
                      src="images/autonomousIoniq.jpg"
                      alt="Card image cap"
                    />
                  </div>
                </div>

                <div className="card-block">
                  <div className="card-content">
                    <div className="col">
                      <div className="row whiteBG">
                        <div className="col-lg-4">
                          <div className="salesText mt-3">
                            <p className="theme-news-snippet">
                              “Hyundai Motor exhibits its vision for future
                              mobility with Autonomous IONIQ”
                            </p>
                          </div>
                          <hr />
                          <div
                            className="d-flex justify-content-around mb-2 largerSI social-share-buttons"
                            data-article-id="AutonomousIoniq"
                            data-article-text="Autonomous Ioniq"
                          />
                        </div>

                        <div className="col-lg-8 border-lg-left">
                          <div className="row pt-3">
                            <div className="col-md-10 offset-md-1">
                              <p className="salesText" data-search-tag="title">
                                HYUNDAI MOTOR PRESENTS AUTONOMOUS IONIQ AT THE
                                2017 GENEVA MOTOR SHOW
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <div className="theme-news-body-text">
                                <ul data-search-tag="summary">
                                  <li>
                                    Hyundai Motor exhibits its vision for future
                                    mobility with Autonomous IONIQ
                                  </li>
                                  <li>
                                    Visitors invited to experience autonomous
                                    driving through immersive virtual reality
                                    simulators at Hyundai Motor booth
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div className="row pt-3">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                <strong>
                                  Geneva Motor Show, 07 March 2017
                                </strong>
                                – After its successful test drives on the
                                streets of Las Vegas, Hyundai Motor brings its
                                Autonomous IONIQ model as an exhibit to the
                                Geneva Motor Show 2017. The advanced autonomous
                                IONIQ virtual reality cockpits will also give
                                visitors the chance to experience a drive in an
                                autonomous car on the stand.
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                Moving forward with the use of LiDAR technology,
                                Hyundai Motor has fitted the hardware behind the
                                autonomous IONIQ’s front bumper rather than
                                using the typical roof-mounted approach,
                                retaining the new car’s sleek design. The car’s
                                advanced self-driving systems are kept as simple
                                as possible by integrating existing functions
                                from the production model, including the Smart
                                Cruise Control system’s forward-facing radar and
                                Lane Keeping Assist cameras.
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                The system also uses a GPS antenna to search for
                                a location of each vehicle and high-definition
                                mapping software delivers pinpoint accuracy for
                                location, road gradient and curvature, plus lane
                                width and indication data. In addition, a Blind
                                Spot Detection radar ensures that even simple
                                lane changes are executed safely.
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                Hyundai Motor’s latest technology allows the
                                cars to navigate the most challenging situations
                                safely, including high levels of pedestrian
                                traffic, stop lights and signs, road
                                construction and road blocks, speed bumps, dogs,
                                small children and even intersections without
                                traffic signals. Through extensive testing in
                                urban environments Hyundai Motor is continuing
                                to develop and refine its self-driving
                                technologies with the goal of using less
                                computing power, resulting in a low-cost
                                platform that the typical consumer can afford.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
