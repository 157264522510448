import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';
import './whnItem.css';
import Store from 'store';

export default class whnItem extends Component {
  clickHandler = () => {
    ReactGA.event({
      label: 'News Article',
      category: 'WHN',
      action: `News Article Clicked - ${this.props.maintitle}`,
      gaOptions: {
        siteSpeedSampleRate: 100,
        userId: Store.get('customer') && Store.get('customer').urn,
      },
    });
  };

  render() {
    let articleTitle = this.props.title;
    if (articleTitle.length > 47) {
      articleTitle = articleTitle.slice(0, 47) + '...';
    }

    let dashedArticleTitle = this.props.title;
    dashedArticleTitle = dashedArticleTitle.split(' ').join('-').toLowerCase();

    return (
      <div
        className={this.props.className}
        text={this.props.text}
        category={this.props.category}
        maintitle={this.props.maintitle}
        mainsrc={this.props.mainsrc}
      >
        <Link
          to={{
            pathname: '/newsletter/' + dashedArticleTitle,
            state: {
              title: this.props.maintitle,
              src: this.props.mainsrc,
              startDate: this.props.startDate,
              text: this.props.text,
              category: this.props.category,
              alt: this.props.alt,
              id: this.props.id,
              related: this.props.related,
            },
          }}
          onClick={this.clickHandler}
        >
          <img
            className="card-img-top pl-2 pr-2 pt-4 pb-0 article-image"
            src={this.props.src}
            alt={this.props.alt}
          />
          <div className={this.props.bodytop}>
            <h5 className="card-title text-primary" style={{ fontWeight: 600 }}>
              {articleTitle}
            </h5>
          </div>
          <div className={this.props.bodybottom}>
            <p className="card-text pr-2" style={{ fontSize: 'small' }}>
              {this.props.startDate}
            </p>
            <div className="read-logo"></div>
          </div>
        </Link>
      </div>
    );
  }
}
