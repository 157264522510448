import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CheckField from '../checkField';
import InputField from '../inputField';
import SelectField from '../selectField';
import Validation from '../../utils/Validation';
import ValidationMessage from '../validationMessage';

const Parser = require('html-to-react').Parser;

export default class CustomField extends Component {
  static propTypes = {
    numberType: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    options: PropTypes.array,
    value: PropTypes.any,
  };

  constructor(props) {
    super(props);

    this.state = {
      validation: {
        [this.props.name]: Validation.initRequiredValidationState(),
      },
    };
  }

  onComponentDidMount = () => {
    this.validate();
  };

  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    let value = target.type ==='checkbox' ? target.checked : target.value;

    if (this.props.stateHandler !== null) {
      this.props.stateHandler(name, value);
    }

    this.setState(
      {
        [name]: value,
      },
      () => this.validate(),
    );
  };

  validate = () => {
    this.setState((prevState) => {
      return Validation.validate(prevState, this.props.name);
    });
  };

  buildCheckBox = () => {
    const parser = new Parser();
    const label = parser.parse(this.props.description);

    return (
      <CheckField
        name={this.props.name}
        id={this.props.name}
        checked={this.state.value}
        onChange={this.handleInputChange}
        label={label}
      />
    );
  };

  buildTextInput = () => {
    const parser = new Parser();
    const label = parser.parse(this.props.description);

    return (
      <div className="form-group">
        <label htmlFor={this.props.name}>{label}</label>
        <InputField
          id={this.props.name}
          name={this.props.name}
          value={this.state.value}
          onChange={this.handleInputChange}
          validation={this.state.validation[this.props.id]}
        />
        <ValidationMessage validation={this.state.validation[this.props.id]} />
      </div>
    );
  };

  buildRadioButton = () => {
    const parser = new Parser();
    const label = parser.parse(this.props.description);
    const options = this.props.options.map((option, index) => {
      return (
        <div className="custom-control custom-radio" key={'radio' + index}>
          <input
            id={option.id}
            type="radio"
            className="custom-control-input"
            name={this.props.name}
            checked={this.state.value}
            value={parser.parse(option.description)}
            onChange={this.handleInputChange}
          />
          <label className="custom-control-label" htmlFor={option.id}>
            {parser.parse(option.description)}
          </label>
        </div>
      );
    });

    return (
      <div className="pb-3" key="radio">
        {label}
        {options}
      </div>
    );
  };

  buildSelectInput = () => {
    const parser = new Parser();
    const label = parser.parse(this.props.description);
    const options = this.props.options.map((option) => (
      <option key={option.id} value={option.id}>
        {parser.parse(option.description)}
      </option>
    ));
    return (
      <div className="col-md-12 pb-2">
        {label}
        <SelectField
          name={this.props.name}
          value={this.state.title}
          onChange={this.handleInputChange}
          validation={this.state.validation.title}
        >
          <option value="">Choose...</option>
          {options}
        </SelectField>
      </div>
    );
  };

  renderByType = () => {
    switch (this.props.numberType) {
      case 1:
        return this.buildTextInput();
      case 2:
        return this.buildSelectInput();
      case 3:
        return this.buildRadioButton();
      case 4:
        return this.buildCheckBox();
      default:
        return null;
    }
  };

  render() {
    return this.renderByType();
  }
}
