import React, { Component } from 'react';
import './servicePlans.css';
import FooterNavbar from '../components/footerNavbar';
import Store from 'store';
import Topnavbar from '../components/topnavbar';
import Bottomnavbar from '../components/bottomnavbar';
import Footer from '../components/footer';
import $ from 'jquery';
import JSEncrypt from 'jsencrypt';
import OffersSideNav from '../components/offersSideNav';
import ServiceProductNavbar from '../components/serviceProductNavbar.jsx';

const ulStyle = {
  padding: '5px',
};

export default class ServicePlans extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Topnavbar />
        <Bottomnavbar />

        <div className="container">
          {/* <ProfileNav /> */}
          {<ServiceProductNavbar />}
          <section
            className="section1 wow fadeIn"
            data-wow-duration="2s"
            data-wow-delay="0.2s"
          >
            <div className="content p-3 p-xl-5 col-md-10 offset-md-1 border-right border-bottom">
              <div className="row">
                <div className="col-lg-3 p-3 p-sm-4">
                  {/* <OffersSideNav /> */}
                  <h4 className="h4ServHistory" data-search-tag="title">
                    <strong>Hyundai Service Plans</strong>
                  </h4>
                  <p className="mb-0" data-search-tag="summary">
                    Hyundai Service Plans are an easy way to look after your
                    car, so you’re free to live your driving life without
                    worrying about servicing costs.
                  </p>
                </div>

                <div className="col-lg-8 offset-lg-1 p-sm-4 border-lg-left">
                  <div className="theme-main-position-relative">
                    <img
                      className="card-img-top img-fluid"
                      src="images/service_plan_img2.jpg"
                    />
                    <div className="blurbDiv theme-servicePlans-blurb">
                      <p>Any model, any age, any mileage.</p>
                      <p>Inflation proof.</p>
                      <p>Flexible payment options.</p>
                      <a  
                        href=" https://hyundai.service123.co.uk/ServicePlan/Quote"
                        target="_blank" rel="noopener noreferrer"
                      >
                        {' '}
                        <button
                          className="btn btn-sm removeRadius btn-primary servicePlans"
                          style={{ width: '11rem' }}
                        >
                          Find out more
                        </button>
                      </a>
                    </div>
                  </div>
                  <h4
                    className="h4ServHistory mt-4 mb-4"
                    data-search-tag="title"
                  >
                    <strong>Hyundai Service Plans</strong>
                  </h4>

                  <p className="remindersBlue" data-search-tag="summary">
                    As a Hyundai driver you want to be able to enjoy your
                    driving life - your weekend away, impromptu adventure or
                    even your daily commute – without worrying about the cost of
                    servicing your car.
                  </p>
                  <p className="remindersBlue">
                    A Hyundai Service Plan is the perfect way to keep your car
                    on the road and benefit from the freedom that being a
                    Hyundai driver brings. It gives you extra servicing
                    certainty on your own terms and helps you stay one step
                    ahead with your servicing costs.
                  </p>
                  <h4
                    className="h4ServHistory mt-4 mb-4 "
                    data-search-tag="title"
                  >
                    <strong>A service plan that's made for you</strong>
                  </h4>
                  <p className="remindersBlue">
                    Hyundai Service Plans are tailored to your individual
                    requirements, your mileage, plan duration and MOT
                    requirements, no matter what model of Hyundai you drive, how
                    old it is, or how many miles are on the clock.
                  </p>
                  <h4
                    className="h4ServHistory mt-4 mb-4"
                    data-search-tag="title"
                  >
                    <strong>
                      There’s plenty to like about
                      <br /> Hyundai Service Plans:
                    </strong>{' '}
                  </h4>

                  <ul className="remindersBlue">
                    <li style={ulStyle}>
                      Open to everyone, if you drive a Hyundai, you’re eligible
                      for a Hyundai Service Plan.
                    </li>
                    <li style={ulStyle}>
                      Your flexible individual service plan is based on both
                      your car and your driving life: your annual mileage, MOT
                      requirements and how long you’d like the plan to last.
                    </li>
                    <li style={ulStyle}>
                      Simply enter your details{' '}
                      <a  
                        href="https://hyundai.service123.co.uk/ServicePlan/Quote"
                        target="_blank" rel="noopener noreferrer"
                        style={{
                          textDecoration: 'underline',
                          fontWeight: 'bold',
                          color: '#212529',
                        }}
                      >
                        online
                      </a>{' '}
                      to receive a quote for your bespoke Hyundai Service Plan,
                      or if you prefer pop into your{' '}
                      <a  
                        href="https://www.hyundai.co.uk/dealer-locator?gclid=EAIaIQobChMIu4ncyZ_J3AIViLHtCh1yhg_NEAAYASABEgL1sfD_BwE"
                        target="_blank" rel="noopener noreferrer"
                        style={{
                          textDecoration: 'underline',
                          fontWeight: 'bold',
                          color: '#212529',
                        }}
                      >
                        local dealer.
                      </a>{' '}
                    </li>
                    <li style={ulStyle}>
                      After you’ve found the right service plan for you, you’ll
                      have the choice of paying in full or spreading the cost
                      with easily arranged, fixed monthly payments. No interest
                      to pay. No credit checks. No loans.
                    </li>
                    <li style={ulStyle}>
                      Clear pricing – when we say fixed, we mean fixed!
                      Inflation-proof pricing along with a full breakdown of
                      what’s included in your plan.
                    </li>
                    <li style={ulStyle}>
                      Expert servicing for added peace of mind – your Hyundai
                      will be serviced by Hyundai trained technicians using
                      Genuine Hyundai Parts in line with the recommended service
                      schedule.
                    </li>
                  </ul>

                  <h4
                    className="h4ServHistory mt-4 mb-4"
                    data-search-tag="title"
                  >
                    <strong>You’re just a few clicks from extra freedom</strong>
                  </h4>

                  <p className="remindersBlue">
                    We want you to live your driving life to the full, from the
                    sunny day trips to the seaside to seamless school runs.
                  </p>

                  <p className="remindersBlue">
                    Wherever your driving life takes you, stay one step ahead
                    with a Hyundai Service Plan.
                  </p>

                  <p className="remindersBlue">
                    Get started by configuring your plan and receive a quote in
                    just a few clicks{' '}
                    <a  
                      href="https://hyundai.service123.co.uk/ServicePlan/Quote"
                      target="_blank" rel="noopener noreferrer"
                      style={{
                        textDecoration: 'underline',
                        fontWeight: 'bold',
                        color: '#212529',
                      }}
                    >
                      here.
                    </a>{' '}
                  </p>

                  <p className="remindersBlue">
                    Or, if you’d like to discuss your options, please contact
                    your{' '}
                    <a  
                      href="https://www.hyundai.co.uk/dealer-locator?gclid=EAIaIQobChMIu4ncyZ_J3AIViLHtCh1yhg_NEAAYASABEgL1sfD_BwE"
                      target="_blank" rel="noopener noreferrer"
                      style={{
                        textDecoration: 'underline',
                        fontWeight: 'bold',
                        color: '#212529',
                      }}
                    >
                      local Hyundai dealer
                    </a>{' '}
                    for more information.
                  </p>

                  <div className="my-3">
                    <a  
                      href=" https://hyundai.service123.co.uk/ServicePlan/Quote"
                      target="_blank" rel="noopener noreferrer"
                    >
                      {' '}
                      <button
                        className="btn btn-sm removeRadius btn-primary servicePlans"
                        style={{ width: '11rem' }}
                      >
                        Find out more
                      </button>
                    </a>
                  </div>

                  <p className="remindersBlue">
                    <a  
                      href="https://staging.loyaltylogistix.com/shared/myhyundaiuk/terms/HyundaiServicePlanT&Cs_r2.pdf"
                      target="_blank" rel="noopener noreferrer"
                      style={{
                        textDecoration: 'underline',
                        fontWeight: 'bold',
                        color: '#212529',
                      }}
                    >
                      Terms and Conditions apply.
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </section>
          <FooterNavbar />
        </div>

        <Footer />
      </div>
    );
  }
}
