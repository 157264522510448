import React, { Component } from 'react';

export default class WinterTestingSweden extends Component {
  render() {
    return (
      <div className="container">
        <div className="fadeInDownBigFull">
          <div className="card-body newsPadding">
            <div className="card-deck" id="WinterTestingSweden">
              <div className="card">
                <div className="card-block">
                  <div className="card-content">
                    <div className="col">
                      <div className="row whiteBG">
                        <div className="col-lg-4">
                          <div className="salesText mt-3">
                            <p className="theme-news-snippet">
                              “Enhancing the i30 family with a third member and
                              adding a high-performance model to provide maximum
                              driving enjoyment for everyone”
                            </p>
                          </div>
                          <hr />
                        </div>

                        <div className="col-lg-8 border-lg-left">
                          <div className="row pt-3">
                            <div className="col-md-10 offset-md-1">
                              <p className="salesText" data-search-tag="title">
                                HYUNDAI MOTOR’S FIRST HIGH-PERFORMANCE MODEL:
                                THE HYUNDAI i30 N – WINTER TESTING IN SWEDEN
                                WITH THIERRY NEUVILLE
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <div className="theme-news-body-text">
                                <ul>
                                  <li>
                                    Hyundai i30 N will be Hyundai Motor’s first
                                    high-performance car, currently at the
                                    development stage before being launched in
                                    Europe in the second half of 2017
                                  </li>
                                  <li>
                                    Enhancing the i30 family with a third member
                                    and adding a high-performance model to
                                    provide maximum driving enjoyment for
                                    everyone
                                  </li>
                                  <li>
                                    Hyundai Motor’s N brings together motorsport
                                    experience and high-performance road car
                                    development, with Thierry Neuville testing
                                    the car in Sweden
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div className="row pt-3">
                            <div className="col-md-10 offset-md-1">
                              <p
                                className="theme-news-body-text"
                                data-search-tag="summary"
                              >
                                <strong>March 15, 2017</strong> – Hyundai Motor
                                is carrying out winter testing on its first
                                high-performance model: the i30 N. Due to be
                                launched across Europe in the second half of
                                2017, the i30 N will be the third member of the
                                i30 family, following the launch of the
                                five-door version in January and the i30 Wagon
                                at the Geneva Motor Show in March. Hyundai
                                Motor’s first N model will bring together
                                motorsport experience and high-performance road
                                car development, creating maximum driving
                                enjoyment on the road and on the track in an
                                accessible high-performance car package.
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <div className="theme-news-body-text">
                                <p>
                                  During the recent winter testing in Sweden,
                                  Thierry Neuville, World Rally Championship
                                  driver of Hyundai Motorsport, tested the car
                                  on a frozen lake to get a feeling for its
                                  performance and to provide feedback to the
                                  engineers in order to further enhance the
                                  development process. His driving experience
                                  and feedback were captured on video, now
                                  released for enthusiasts to see.
                                </p>
                                <p>
                                  “So many people have been looking forward to
                                  this car, so it’s a pleasure for me to be
                                  testing it,” said Thierry Neuville. “The
                                  engineers have done a great job on the gearbox
                                  and the differential. There is good traction
                                  and good stability in the slippery testing
                                  conditions. And it’s very easy to handle. A
                                  normal driver should get a sensation similar
                                  to that of a racing car.”
                                </p>
                                <p>
                                  Hyundai Motor is undergoing winter testing in
                                  Arjeplog, Sweden to develop and tune the car’s
                                  dynamic systems. The Electronic Stability
                                  Control, the Limited Slip Differential, the
                                  steering, shock absorbers, suspension and
                                  tyres are all being tested under extreme
                                  weather conditions, with temperatures as low
                                  as minus 30 degrees Celsius.
                                </p>
                                <p>
                                  Alexander Eichler, Head of High-Performance
                                  Vehicle Test and Development at Hyundai Motor
                                  Europe’s Technical Centre, outlined the
                                  objective for the i30 N as follows: “The main
                                  target for us is to ensure driving enjoyment.
                                  Symbolising a chicane, the ‘N’ logo embodies
                                  this aspect - an agile vehicle that is fun to
                                  drive.”
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
