import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './topnavbar.css';

export default class TopNavbar extends Component {
  constructor(props) {
    super();

    this.state = {
      show: false,
    };
  }

  render() {
    return (
      <div className="container" data-search-exclude>
        <nav className="theme-nav bg-white">
          <div className="d-flex justify-content-between  align-content-start flex-wrap">
            <Link className="navbar-brand m-auto m-sm-0" to="/">
              <img className="mainLogo" src="images/hyundai_logo.png" />
            </Link>
            <div className="pt-2 m-auto m-sm-0">
              <div className="d-inline-block">
                <a
                  href="https://www.hyundai.co.uk/owning/book-a-service"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="username pr-3 ">
                    <img
                      src="images/book-service-icon.png"
                      id="topServiceImage"
                    />
                    <small> Book a service</small>
                  </span>
                </a>
              </div>

              <div className="d-inline-block">
                <span className="username pr-3">|</span>
                <span className="username">
                  <i className="fas fa-phone" />
                  <a href="tel:0800981981" title="Call Us Now">
                    <small> 0800 981 981</small>
                  </a>
                </span>
              </div>
            </div>{' '}
          </div>
        </nav>
        <hr id="navHr" />
      </div>
    );
  }
}
