import React, { Component } from 'react';
import NewsMedia from '../newsMedia';
import Store from 'store';
import ReactGA from 'react-ga';
class NewTucson extends Component {
  componentDidMount = () => {
    ReactGA.event({
      label: 'new tucson',
      category: 'Promotion',
      action: `new tucson Promo News item opened`,
      gaOptions: {
        userId: Store.get('customer') && Store.get('customer').urn,
      },
    });
  };

  clickHandler = () => {
    ReactGA.event({
      label: 'new tucson',
      category: 'Promotion',
      action: `new tucson Promo News item Link Click`,
      gaOptions: {
        userId: Store.get('customer') && Store.get('customer').urn,
      },
    });
  };

  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="new-tucson-news" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="new-tucson-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/new-tucson-main.jpg")',
              }}
            />
            <div
              id="new-tucson-gallery-2"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/new-tucson-1.jpg")',
              }}
            />
            <div
              id="new-tucson-gallery-3"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/new-tucson-2.jpg")',
              }}
            />
            <div
              id="new-tucson-gallery-4"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/new-tucson-3.jpg")',
              }}
            />
            <div
              id="new-tucson-gallery-5"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/new-tucson-4.jpg")',
              }}
            />
            <div
              id="new-tucson-gallery-6"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/new-tucson-5.jpg")',
              }}
            />
            <div
              id="new-tucson-gallery-7"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/new-tucson-6.jpg")',
              }}
            />
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#new-tucson-gallery-1"
                    style={{
                      backgroundImage: 'url("images/new-tucson-main.jpg")',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#new-tucson-gallery-2"
                    style={{
                      backgroundImage: 'url("images/new-tucson-1.jpg")',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#new-tucson-gallery-3"
                    style={{
                      backgroundImage: 'url("images/new-tucson-2.jpg")',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#new-tucson-gallery-4"
                    style={{
                      backgroundImage: 'url("images/new-tucson-3.jpg")',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#new-tucson-gallery-5"
                    style={{
                      backgroundImage: 'url("images/new-tucson-4.jpg")',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#new-tucson-gallery-6"
                    style={{
                      backgroundImage: 'url("images/new-tucson-5.jpg")',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#new-tucson-gallery-7"
                    style={{
                      backgroundImage: 'url("images/new-tucson-6.jpg")',
                    }}
                  />
                </div>
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">"All-new Hyundai Tucson"</p>
              </div>
              <hr />

              <NewsMedia
                articleId="new-tucson-news"
                articleText="The all-new Hyundai Tucson: a smart tech hero with a standout design"
              />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p style={{ fontSize: 'small' }}>
                    Please note – actual specifications will differ by market
                    and will be subject to further announcement nearer the time
                    of on sale
                  </p>
                  <p className="salesText" data-search-tag="title">
                    The all-new Hyundai Tucson: a smart tech hero with a
                    standout design
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    <ul>
                      <li>
                        Advanced and experimental: the all-new Tucson is not
                        just an evolution, but a Hyundai design revolution
                      </li>
                      <li>
                        New Parametric Hidden Lights – a state-of-the-art design
                        technology – give the all-new Tucson a unique presence
                        on the road
                      </li>
                      <li>
                        A best-in-class safety package and family-oriented
                        convenience sets new standards for forward-thinking
                        customers
                      </li>
                      <li>
                        The all-new Tucson offers the most electrified
                        powertrain line-up in the compact SUV segment, including
                        hybrid, plug-in hybrid, 48-volt mild hybrid options
                      </li>
                    </ul>
                  </p>
                  {displayShowMore && (
                    <p>
                      <a
                        data-toggle="collapse"
                        href="#newtucson"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </div>

              <div className={moreSectionClassName} id="newtucson">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      <b>Leatherhead, 15 September 2020 –</b> Hyundai Motor has
                      unveiled the all-new Tucson, which features an advanced,
                      experimental design and state-of the-art technology,
                      setting new standards in the compact SUV segment. In
                      addition, it offers the most electrified powertrain
                      line-up in its class, underscoring Hyundai’s commitment to
                      clean driving, while retaining its true SUV character.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      The all-new Tucson is the fourth generation of Hyundai’s
                      successful best-seller with more than 7 million units sold
                      around the globe since it launched in 2004. Of these, 1.4
                      million units have been sold in Europe. That makes it the
                      company’s bestselling SUV globally. Hyundai’s new compact
                      SUV arrives on the market with a revolutionary and
                      ambitious new look that follows the company’s new Sensuous
                      Sportiness design identity.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      By offering the most electrified powertrain line-up in the
                      compact SUV segment, including hybrid, plug-in hybrid, and
                      48-volt mild hybrid options, the all-new Tucson completes
                      the electrification of Hyundai’s SUV fleet in Europe.
                      Other electrified SUVs produced by Hyundai include the
                      fully-electric and hybrid versions of the Kona, NEXO, its
                      second-generation fuel cell electric vehicle, and the
                      Santa Fe with hybrid and plug-in hybrid options on top of
                      the line-up. By introducing electrification to its latest
                      models, Hyundai consolidates its position as the
                      manufacturer with broadest range of electrified
                      powertrains.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      The all-new Tucson is a global model. It will be produced
                      in several factories worldwide, and will become the
                      third-generation of the model to be produced in Europe at
                      the Hyundai Motor Manufacturing Czech (HMMC) production
                      plant in Nošovice, Czech Republic. The second generation
                      Tucson was the first model produced at HMMC. This
                      underlines Hyundai’s commitment to the European market.
                      “Hyundai is the brand with the broadest line-up of
                      electrified powertrains, ranging from mild hybrid, hybrid,
                      plug-in hybrid, battery electric to fuel cell electric
                      variants,” says Michael Cole, President and CEO at Hyundai
                      Motor Europe. “With the introduction of the all-new Tucson
                      it was key to also expand the electrification to our
                      global best-selling SUV. This launch marks a further
                      milestone for Hyundai, as we now offer electrified
                      versions of our full SUV range. With its progressive
                      design and state-of-the-art powertrain line-up, the
                      all-new Tucson will remain one of the key products for
                      Hyundai in Europe.”
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p style={{ marginBottom: '0' }}>
                      <b>
                        ‘Parametric Dynamics’: a revolutionary and unique
                        exterior at the cutting-edge of SUV design
                      </b>
                    </p>
                    <p className="theme-news-body-text">
                      Rather than simply an evolution of the previous generation
                      model, the all-new Tucson represents a revolution for
                      Hyundai indesign terms. Overall, it features a bigger and
                      wider body than its predecessor.Its muscular stance
                      combines sharp angles and dynamic proportions with rich
                      surfaces, ensuring a progressive look without compromising
                      on Tucson’s rugged SUV heritage. The all-new Tucson is the
                      first fully-changed Hyundai SUV to be developed according
                      to the company’s new“Sensuous Sportiness” design identity.
                      This direction is characterised by the harmony between
                      four fundamental elements: proportion, architecture,
                      styling and technology. Its purpose is to bring a sensuous
                      and emotional touch to Hyundai vehicles, realised by
                      innovative technology and solutions.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      “The mission of ‘Sensuous Sportiness’ is to elevate the
                      emotional qualities of automotive design. We want our
                      customers to feel moved. With the all-new Tucson, we are
                      introducing its ultimate evolution and a definitive
                      statement about Hyundai’s unstoppable forward momentum,”
                      said SangYup Lee, Senior Vice President and Head of
                      Hyundai Global Design Center. “Tucson’s advanced,
                      experimental design is true to its pioneering spirit and
                      raises the game in the industry’s most competitive
                      segment.”
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      The progressive design language of the all-new Tucson was
                      inspired by Hyundai’s Vision T SUV concept, which was
                      unveiled at 2019 AutoMobility LA. Also guided by Sensuous
                      Sportiness, Vision T’s design themes revolve around the
                      concept of Dynamism and represent an “urban adventurer”
                      SUV design direction.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      Refraining from traditional drawing and sketching methods,
                      Hyundai’s designers developed the all-new Tucson’s
                      futuristic design elements through geometric algorithms
                      produced by cutting-edge digital technology. This process,
                      known as “parametric dynamics”, utilises lines, faces,
                      angles and shapes created through digital data to create
                      unprecedented, bold design aesthetics. As a result,
                      prominent geometric patterns known as “parametric jewels”
                      appear throughout the SUV’s design, giving it a
                      progressive character.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      The most prominent display of these parametric jewels is
                      on the vehicle’s front grille, whereParametric Hidden
                      Lights provide a strong first impression. When the lights
                      are off, the front of the vehicle appears covered in dark,
                      geometric patterns, with no distinction between the
                      signature LED Daytime Running Lights (DRLs), which are
                      seamlessly integrated into the grille. Thanks to
                      state-of-the-art half-mirror lighting technology, when the
                      DRLs are switched on, the dark chrome appearance of the
                      grille transforms into jewel-like shapes, bringing an
                      eye-catching element to an otherwise sleek appearance.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      Parametric jewels also feature as a prominent design
                      element on the side of the vehicle.Chiseled surfaces
                      create a striking contrast between a sleek silhouette and
                      masculine wedge, suggesting forward motion even when
                      standing still. Taut athletic shapes seamlessly merge into
                      angular wheel arches, where alloy wheels provide a
                      powerful and dynamic stance.The Tucson’s sporty
                      sensibility is accentuated through a chrome accent line
                      with an edged parabolic shape, starting on the side
                      mirrors and continuing all the way to the C-pillar. From
                      the side view, the wrap-around doors are complemented by a
                      sharp and bold side character line, which sits in harmony
                      with the robust and rugged look of the dynamic and
                      angulated wheel arches.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      On the rear, wide tail lamps with parametric hidden light
                      details continue the design theme. The all-new Tucson’s
                      rear bumper also integrates parametric pattern details
                      with a three-dimensional effect, coupled with a sporty
                      garnish. It is the first Hyundai model to apply hidden
                      rear wipers, which sit under the spoiler. High-tech design
                      elements are crowned by a smooth glass Hyundai logo, which
                      looks three-dimensional but actually does not protrude
                      from the outer surface, in a departure from traditional
                      automotive manufacture emblems.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1"></div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p style={{ marginBottom: '0' }}>
                      <b>Exterior colours:</b>
                    </p>
                    <p className="theme-news-body-text">
                      All-new Tucson customers can choose from nine exterior
                      colours overall, including three new ones: Shimmering
                      Silver, Amazon Grey and Teal. Moreover, the option of a
                      two-tone roof in either a Phantom Black or Dark Knight
                      offers customers even more scope for personalisation. Each
                      exterior body colour can be combined with a two-tone roof.
                    </p>
                    <ul>
                      <li>
                        Shimmering Silver [NEW] with optional Dark Knight roof
                      </li>
                      <li>
                        Amazon Grey [NEW] with optional Phantom Black roof
                      </li>
                      <li>Teal [NEW] with optional Phantom Black roof</li>
                      <li>Phantom Black with optional Dark Knight roof</li>
                      <li>Polar White with optional Phantom Black roof</li>
                      <li>Engine Red with optional Phantom Black roof</li>
                      <li>Sunset Red with optional Phantom Black roof</li>
                      <li>Silky Bronze with optional Dark Knight roof</li>
                      <li>Dark Knight with optional Phantom Black roof</li>
                    </ul>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p style={{ marginBottom: '0' }}>
                      <b>
                        A clean and sophisticated interior combines maximum
                        comfort with seamless technology{' '}
                      </b>
                    </p>
                    <p className="theme-news-body-text">
                      Getting into the all-new Tucson’s sophisticated and
                      spacious interior, or INTERSPACE, is like entering a
                      neatly organised room where everyday concerns disappear.
                      Inside, space, technology and information intersect
                      harmoniously. Featuring sensuous forms inspired by
                      waterfalls,twin silver garnish lines streaming from the
                      centre fascia to the rear doors harmonise neatly layered
                      premium surface materials in complementary neutral tones.
                      The seamless integration of segment-leading technology
                      provides all-new Tucson customers with an advanced and
                      fully-customisable digital experience. The new 10.25-inch
                      AVN-T screen fills the centre of the vehicle. Hyundai’s
                      designers dispensed with physical knobs and buttons, so
                      all AVN and heat, ventilation and air conditioning
                      functions are controlled via touch, making it the first
                      Hyundai model to feature a full touchscreen console.
                      High-quality soft-touch materials appear throughout the
                      interior, lifting the look and feel to a new level, while
                      new indirect air vents start from the doors and flow to
                      the centre console.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      The evolution of Tucson’s interior layout led to a lower
                      instrument display and removal of the gauge cluster
                      housing for a decluttered surface to enhance the feeling
                      of openness inside.The broad ridge of the dashboard blends
                      seamlessly with the doors, wrapping around front occupants
                      like a deep gorge.These futuristic features provide an
                      even more progressive feeling and bring new levels of
                      high-tech design value to customers.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      The ergonomically-positioned arm rest is connected with
                      the shift by wire application, for intuitive use by the
                      driver, while also giving the car a clean and modern
                      appeal.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      Occupants can also enjoy pleasant ambient mood lighting,
                      which is positioned in the two side pockets of the centre
                      fascia, in the wireless charging pad and door map pocket.
                      It offers 64 configurable colours and 10 levels of
                      brightness to fit every mood and offer even more scope for
                      personalisation.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      In addition, customers can choose from three interior
                      trims, including one-tone Black in fabric or leather,
                      two-tone Black and Beige in fabric or leather and the Teal
                      colour pack. The Teal pack offers a black textile or
                      leather seat with teal stitching on the seats, armrest and
                      gear shift. Teal textile inserts also feature on the cloth
                      crash pad and door trim.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      <b>Interior colours:</b>
                      <ul>
                        <li>One-tone Black (fabric or leather)</li>
                        <li>
                          Two-tone Black and Moss Grey (fabric or leather)
                        </li>
                        <li>Teal pack (fabric or leather)</li>
                      </ul>
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p style={{ marginBottom: '0' }}>
                      <b>State-of-the-art technology and connectivity</b>
                    </p>
                    <p className="theme-news-body-text">
                      The all-new Tucsonis equipped with a host of
                      state-of-the-art human-oriented technology features to
                      provide an advanced digital experience. The fully digital
                      configurable dual cockpit consists of a new 10.25-inch
                      open cluster and 10.25-inch AVN-T screen. Thisoffers a
                      personalised space that is intuitively optimised for a
                      high-tech user experience. For example, the colour schemes
                      of the cluster change depending on which drive mode is
                      selected. Hyundai is offering the latest version of its
                      state-of-the-art Bluelink connected car services with the
                      10.25-inch infotainment system in the all-new Tucson. The
                      upgraded Bluelink offers a range of new benefits and
                      services for Hyundai customers, including Connected
                      Routing, Last Mile Navigation and a new User Profile
                      feature. Meanwhile, customers can locate their vehicle,
                      lock and unlock it remotely, or view vehicle attributes
                      like fuel level, via the Bluelink app.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      Occupants can tailor their individual infotainment choices
                      with the new User Profile feature. This allows customers
                      to install up to two main accounts and one guest account,
                      and upload a profile picture via the Bluelink app. User
                      Profile stores users’ infotainment preferences, such as
                      language, Bluetooth, navigation and voice recognition
                      settings, as well as their favourite radio stations, in
                      the cloud.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      If drivers have to park their car within a 200-metre to
                      two-kilometre radius from the destination, Last Mile
                      Navigation can be activated to resume the guidance in the
                      Bluelink app on the driver’s smartphone.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      In addition, the all-new Tucson will feature calendar
                      integration inside the infotainment system, meaning the
                      user will be able to see their Apple or Google Calendar on
                      the AVN screen. Synchronisation is done via Bluelink App
                      settings. This is just one more reason for customers to
                      use and download the Bluelink app.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      With the new cloud-based Connected Routing navigation,
                      driving routes are calculated on a powerful server inside
                      the Bluelink cloud environment. This ensures more accurate
                      traffic forecasting, more precise times of arrival, and
                      more reliable route recalculation. As an additional
                      benefit of Connected Routing, the autofill functionality
                      saves drivers time in selecting a destination. Apple
                      CarPlay and Android Auto allow customers to mirror the
                      functionality of their iOS and Android smartphones in a
                      simplified and convenient manner. With the eight-inch
                      Display Audio, smartphones can be mirrored wirelessly.
                      Other infotainment and connectivity features on the
                      all-new Tucson include a premium sound system by KRELL for
                      an enhanced listening experience, a wireless charging pad
                      in the centre console, as well as front and rear USB ports
                      for even more convenience, particularly on long journeys.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      Finally, Hyundai LIVE Services add real-time parking
                      information and fuel station locations including prices,
                      as well as weather information. Destinations or points of
                      interest can also be sent from the app to the in-car
                      navigation system.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p style={{ marginBottom: '0' }}>
                      <b>
                        The all-new Tucson delivers increased comfort and
                        convenience for every type of driver
                      </b>
                    </p>
                    <p className="theme-news-body-text">
                      With a range of new convenience features, Hyundai offers
                      even more comfort for all-new Tucson customers.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      For the first time in a Hyundai vehicle, the all-new
                      Tucson’s hidden type Multi-Air Mode technology consists of
                      a combination of direct and indirect air vents for air
                      conditioning and heating to create a more pleasant indoor
                      environment with more gentle air flow. When this mode is
                      activated, the air is dispersed to the newly added
                      multi-air slots in the front, in addition to the normal
                      air vents. The overall wind volume remains the same, and
                      is therefore just as effective at changing the
                      temperature, but the dispersion of wind reduces direct air
                      contact and softens the air. This mode can be switched on
                      and off based on the preference of the driver.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      The three-zone climate control now caters to passengers in
                      the rear seats in addition to occupants in the front of
                      the vehicle. In addition, ventilated seats in the front
                      and heated seats in the front and rear provide further
                      comfort for the driver and passenger alike.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      In addition, with Walk-in Device drivers can slide and
                      recline the passenger seat for the convenience of front-
                      or rear-seat occupants with the simple use of a button on
                      the seat’s bolsters. Rear Sleeping Mode allows the
                      driver’s row to listen to their selected audio without the
                      same audio being transmitted to the rear speakers, so that
                      passengers who are sleeping will not be disturbed. For
                      improved user convenience, the rear seats of the all-new
                      Tucson can now be folded in a 4:2:4 ratio. Remote Folding
                      furthermore allows the seats to be folded at a distance.
                      The folding levers are located on the side walls of the
                      boot.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p style={{ marginBottom: '0' }}>
                      <b>Increased roominess</b>
                    </p>
                    <p className="theme-news-body-text">
                      The all-new Tucson’s dynamic proportions and new platform
                      provide a wide, open interior space that guarantees
                      occupants comfort and roominess usually reserved for
                      models in the segment above. At 20mm longer, 15mm wider,
                      and a wheelbase that has been increased by 10mm as
                      compared to the previous generation, the all-new Tucson is
                      more spacious than ever before. As a result, rear
                      passengers can enjoy 26mm of additional legroom. This has
                      been achieved for both the hybrid and internal combustion
                      engine models, due to a clever placement of the battery
                      underneath the rear passenger seats. Meanwhile, boot space
                      has been increased by 33 to 107 litres, depending on the
                      chosen trim and powertrain, offering up to 620litres
                      overall for luggage with the seats up and up to 1,799
                      litres with the seats folded.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      <b>Dimensions (in mm)</b>
                      <ul>
                        <li>Length: 4,500 (+20mm)</li>
                        <li>Width: 1,865 (+15mm)</li>
                        <li>Height: 1,650 (+5mm)</li>
                        <li>Wheelbase: 2,680 (+10mm)</li>
                        <li>Overhang: front 895mm, rear 925mm</li>
                        <li>Second row legroom: 996 (+26mm)</li>
                      </ul>
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      <b>Boot (in litres)</b>
                      <ul>
                        <li>
                          Volume with seats up (Gasoline/ Diesel/ Gasoline 48V/
                          Diesel 48V/ Hybrid): 620 / 598 / 577 / 546 / 616
                        </li>
                        <li>
                          Maximum volume, with seats folded (Gasoline /Diesel
                          /Gasoline 48V/ Diesel 48V/ Hybrid): 1,799 / 1,777 /
                          1,756 / 1,725 / 1,795
                        </li>
                      </ul>
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p style={{ marginBottom: '0' }}>
                      <b>
                        Most comprehensive active safety and driving assist
                        package in its class
                      </b>
                    </p>
                    <p className="theme-news-body-text">
                      With best-in-class advanced driver-assistance systems and
                      an active safety package with a rangeof innovative
                      features, the all-new Tucson delivers even more peace of
                      mind for Hyundai customers.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      The all-new Tucson is outfitted with an enhanced
                      seven-airbag system, which features a new first row center
                      side airbag, a feature unique to this segment. This
                      prevents front row passengers from colliding with one
                      another in the case of a collision, minimising the risk of
                      serious injuries. When the deployment of the airbags is
                      triggerd by a front or side impact, Multi-collision
                      braking automatically applies emergency brakes to reduce
                      the risk of secondary, or multiple, collisions. The
                      all-new Tucson has been equipped withForward
                      Collision-Avoidance Assist (FCA), which sounds alarms when
                      a collision becomes imminent with cars, bicycles, or
                      pedestrians ahead. When the driver fails to react and
                      brake in time, the system automatically controls the
                      brakes to avoid an accident. For the first time in a
                      Tucson, FCA now includes Junction Turning, which expands
                      the range of protection to include collisions at
                      intersections. This function detects oncoming cars from
                      the other side when turning left at a junction and, if a
                      collision appears likely, applies the brakes to stop the
                      car.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      Lane Following Assist (LFA) keeps the vehicle safety
                      centred within the lane. Using the forward camera to
                      recognise the intended lane, LFA will automatically
                      provide corrective steering input to help maintain the
                      centre path. This works together with the Lane Keeping
                      Assist (LKA) function, which prevents accidental lane
                      departure and now detects road edges as well as lines.
                      Driver Attention Warning (DAW) is a driver protection
                      feature which helps monitor driving patterns in order to
                      detect fatigued driving and prevent potential accidents.
                      This works in tandem with Leading Vehicle Departure Alert
                      (LVDA). LVDA alerts the driver when the vehicle ahead of
                      them starts moving forward and they do not react quickly
                      enough, for example at traffic lights.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      Introduced for the first time in a compact SUV, Highway
                      Driving Assist (HDA) makes highway driving safer and more
                      comfortable. HDA regulates the car’s speed and maintains
                      its distance to other vehicles in front, as well as the
                      current lane of travel on the highway. A special button on
                      the steering wheel allows the driver to activate HDA with
                      just one click.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      HDA works in combination with LFA and navigation-based
                      Smart Cruise Control Curve (NSCC-C). With this feature,
                      all-new Tucson drivers can set the speed using SCC then,
                      for greater safety and convenience, NSCC-C is capable of
                      automatically adjusting the vehicle speed for the incoming
                      curve, based on local road information. It reduces the
                      cruising speed and, upon exiting the curve, returns to the
                      preset speed level.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      Another new feature is Intelligent Speed Limit Assist
                      (ISLA). As well as displaying speed limits on the AVN
                      screen, ISLA automatically adjusts the all-new Tucson’s
                      speed so that drivers do not exceed the limit. For even
                      more assurance, the all-new Tucson has been outfitted with
                      a series of functions that increase visibility on the
                      road. Blind-spot Collision Avoidance Assist (BCA). This
                      system now applies differential braking, in addition to
                      visual and audio warnings, to prevent the car from
                      colliding into other vehicles when changing lanes. This
                      function uses the vehicle’s front-view camera and rear
                      corner radars to calculate the relative position and speed
                      of vehicles on the side or in the Tucson’s blind-spot. BCA
                      is equipped with Rear Cross-traffic Collision-avoidance
                      Assist. When backing out, RCCA alerts the driver to the
                      approach of a vehicle crossing their rearward path. If
                      necessary, the brakes are applied to prevent a collision.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      A feature unique to the segment is the Blind view Monitor.
                      When the driver signals a lane change, one of the two main
                      dials of the 10.25’’ Supervision Cluster will display a
                      view of the blind spot of the respective side. This offers
                      an enhancement of the views on the side mirror, and also
                      provides additional visual support in dark and rainy
                      conditions.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      The all-new Tucson also offers the Surround View Monitor
                      (SVM), so that drivers can experience a 360-degree,
                      three-dimensional view of the vehicle from various
                      viewpoints. This includes zooming in and out for the
                      front, rear, left and right of the vehicleSVM also
                      utilises a bird’s eye view to further enhance the
                      visibility of potential obstacles.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      TSVM is also synchronised with the new Remote Smart
                      Parking Assist (RSPA), which is available on the hybrid
                      version of the all-new Tucson. RSPA aids drivers when
                      parking and pulling out by automatically move forward and
                      rear the vehicle, even if outside of the car. The feature
                      can be activated and controlled via the Smart Key. The
                      vehicle utilises ultrasonic sensors to identify obstacles,
                      and emergency braking is applied whenever the risk of a
                      collision is detected. When switched to automatic mode,
                      High Beam Assist (HBA) detects both oncoming vehicles and
                      those in the same lane ahead at night, as well as changing
                      to low-beam as appropriate, thereby reducing blinding
                      effects on other drivers. When no more vehicles are
                      detected, the system automatically reactivates the high
                      beams, maximising the driver’s range of vision.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      Other improved and innovative features make the all-new
                      Tucson an assuring and reliable family-friendly car. The
                      new Rear Occupant Alert (ROA) system monitors the rear
                      seats via a sensor that detects the movements. Visual and
                      auditory warnings are then relayed to the driver to ensure
                      they remove passengers from the rear seats before leaving
                      and locking the vehicle. Rear Passenger Sleeping Mode
                      (RPSM) lowers the rear speakers in order to make the back
                      of the car more relaxing for occupants, when undertaking
                      long journeys. In addition, the new Safe Exit Warning
                      (SEW) function alerts occupants if there is oncoming
                      traffic when they attempt to step out of the car. This
                      feature is being introduced to a mainstream compact SUV
                      for the first time.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      <b>
                        Available Hyundai SmartSense active safety and driving
                        assistance features:
                      </b>
                      <ul>
                        <li>
                          Forward Collision-Avoidance Assist (FCA with car,
                          pedestrian and cyclist protection) [NEW: Junction
                          Turning]
                        </li>
                        <li>Leading Vehicle Departure Alert (LVDA) [NEW]</li>
                        <li>Highway Driving Assist (HDA) 1.5 [NEW]</li>
                        <li>
                          Navigation-based Smart Cruise Control Curve (NSCC-C)
                          [NEW: using navigation data]
                        </li>
                        <li>Intelligent Speed Limit Assist (ISLA) [NEW]</li>
                        <li>Blind Spot-Collision Warning (BCW)</li>
                        <li>
                          Blind-spot Collision Avoidance Assist (BCA) [NEW]
                        </li>
                        <li>Safe Exit Warning (SEW) [NEW]</li>
                        <li>
                          Remote Smart Parking Assist (RSPA) [NEW: HEV/PHEV
                          only]
                        </li>
                        <li>Surround View Monitor (SVM) [upgraded]</li>
                        <li>Blind View Monitor (BVM) [NEW]</li>
                        <li>Rear Occupant Alert (ROA) [NEW]</li>
                        <li>Lane Following Assist (LFA) [NEW]</li>
                        <li>Lane Keeping Assist (LKA) [upgraded]</li>
                        <li>Driver Attention Warning (DAW)</li>
                        <li>Rear Cross Traffic Collision Warning (RCCW)</li>
                        <li>
                          Rear Cross-traffic Collision-avoidance Assist (RCCA)
                          [NEW]
                        </li>
                        <li>High Beam Assist (HBA)</li>
                      </ul>
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      For further peace of mind, Hyundai offers its
                      industry-leading five-year unlimited mileage warranty with
                      the all-new Tucson.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p style={{ marginBottom: '0' }}>
                      {' '}
                      <b>
                        Four-wheel drive and Terrain Modes make the all-new
                        Tucson a true SUV
                      </b>
                    </p>
                    <p className="theme-news-body-text">
                      The all-new Tucson consolidates its true SUV credentials
                      with 4x4 capabilities, thanks to its new Terrain Mode
                      selector, which provides even more enjoyment for drivers.
                      This feature, available on hybrid version with automatic
                      transmission, utilises Hyundai’s signature HTRAC
                      four-wheel drive technology, which enables agile handling
                      and better torque application depending on wheel grip and
                      vehicle speed. In addition to different drive modes, three
                      additional terrain modes – Mud, Sand and Snow – optimise
                      the driving performance and HTRAC settings for an enhanced
                      driving experience on a variety of terrain.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p style={{ marginBottom: '0' }}>
                      <b>
                        A comfortable or sporty ride with new Electronic
                        Controlled Suspension and tuning
                      </b>
                    </p>
                    <p className="theme-news-body-text">
                      The all-new Tucson has been developed and tested in Europe
                      performing accelerated durability tests and dynamic
                      testing on the most demanding race track in the world, the
                      famous Nürburgring Nordschleife, as well as undergoing
                      rigorous public-road testing throughout Europe from the
                      coldest winter testing conditions in Sweden to trailer
                      testing in the Alps and hot weather testing in the south
                      of Spain to ensure that the high expectations of European
                      customers are met. Customers can choose between two
                      different suspension systems for the all-new Tucson, with
                      both offering adaptive and conventional dampers for
                      enhanced ride and handling. Thanks to the flexibility
                      offered by the Electronic Controlled Suspension’s (ECS)
                      adaptive damping technology, Hyundai’s engineers achieved
                      a very versatile driving behaviour, depending on the
                      situation and driver preference. For daily commuting in
                      Normal or Eco mode, the development was focused on a
                      comfortable, flat and stable ride on even the most
                      challenging roads. The ECS automatically and continuously
                      controls the vehicle’s suspension to maximise driving
                      comfort and performance. It achieves this by taking into
                      account driving conditions such as speed, road surfaces,
                      cornering, stopping requirements and acceleration. By
                      controlling the damping force on each individual wheel,
                      ECS reduces roll, pitch and vertical motion to enhance
                      ride and handling. When choosing the Sport mode, all-new
                      Tucson drivers can enjoy an extra level of response and
                      control.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      Meanwhile, the conventional dampers use a new valve
                      technology that offers more tuning flexibility. A
                      MacPherson strut on the front and multi-link suspension on
                      the rear ensure good levels of comfort and handling.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      The all-new Tucson’s Rack-mounted Motor Driven Power
                      Steering (R-MDPS), which features a new generation Belt
                      Drive steering gear, was tuned to match the character of
                      the vehicle’s suspension. Over the course of many months
                      of testing with both prototype vehicles and cutting-edge
                      engineering simulation tools, Hyundai’s engineers finely
                      calibrated the system to ensure drivers can experience
                      smooth and connected steering. Drivers who select Sport
                      Mode will experience a sharper steering effort build-up,
                      before enjoying a greater sense of precision when driving
                      for instance on the motorway.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      Hyundai has developed a wide variety of tyre options for
                      the all-new Tucson with three aerodynamic wheel choices:
                      17-inch steel or alloy, or 18- and 19-inch alloy options.
                      The tyre choices were developed in close cooperation with
                      top-tier tyre manufacturers Continental and Michelin, with
                      special attention given to aspects such as comfort, road
                      noise isolation and the handling performance. In addition,
                      Hyundai’s engineers achieved low rolling resistance
                      values, which contribute to minimising the vehicle’s fuel
                      consumption and emissions. This underlines the company’s
                      commitment to producing eco-friendly cars without
                      compromising on performance.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p style={{ marginBottom: '0' }}>
                      <b>
                        The broadest range of electrified powertrains in the
                        compact SUV segment
                      </b>
                    </p>
                    <p className="theme-news-body-text">
                      By offering three electrified powertrain options and two
                      internal combustion engines with four transmission
                      choices, the all-new Tucson suits every type of driver.
                      With gasoline and diesel 48-volt mild hybrid, full hybrid
                      and plug-in hybrid available to consumers, the all-new
                      Tucson offers the widest range of electrified powertrains
                      in the compact SUV segment. In addition to the electrified
                      powertrains, the all-new Tucson also offers a range of
                      gasoline and diesel options.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      The all-new Tucson was developed to reduce emissions
                      without compromising on being fun to drive. The hybrid
                      version consists of the new 1.6-litre T-GDI (turbocharged
                      gasoline direct injection) Smartstream engine and a 44.2
                      kW electric motor, with a 1.49 kWh lithium-ion polymer
                      battery. It is paired with a six-speed automatic
                      transmission (6AT) and available with two-wheel or
                      four-wheel drive. The system is the most powerful in the
                      all-new Tucson’s powertrain line-up, with a combined
                      output of 230 PS power, meaning it offers low emissions
                      without compromising on driving fun. In terms of 48-volt
                      mild hybrid technology, customers can choose from three
                      different options:
                    </p>
                    <ul>
                      <li>
                        1.6-litre T-GDI Smartstream with 150 PS and two-wheel
                        drive
                      </li>
                      <li>
                        1.6-litre T-GDI Smartstream with 180 PS with optional
                        four-wheel drive
                      </li>
                      <li>
                        1.6-litre CRDi Smartstream with 136 PS with optional
                        four-wheel drive
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      Hyundai has combined its efficient and responsive 48-volt
                      mild hybrid technology with the 150 PS1.6-litre T-GDI,
                      which will be available with six-speed Intelligent Manual
                      Transmission (6iMT) and a seven-speed dual clutch
                      transmission (7DCT). Another well-balanced option is the
                      1.6-litre T-GDI with 180 PS, which will be offered with
                      six-speed iMT with two-wheel drive in addition to 7DCT
                      with four-wheel drive. Meanwhile, the 136 PS 1.6-litre
                      CRDi 48V mild hybrid engine arrives with 7DCT and either
                      four- and two-wheel drive.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      When not mated to the 48-volt mild hybrid system, the
                      1.6-litre T-GDI Smartstream gasoline engine is available
                      with 150 PS, a six-speed manual transmission and four- or
                      two-wheel drive. The 1.6-litre CRDi Smartstream diesel
                      engine will be available with 115 PS and a six-speed
                      manual transmission with two-wheel drive.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      The all-new Tucson’s 1.6 T-GDI Smartstream engine will be
                      equipped with Hyundai’s Continuously Variable Valve
                      Duration (CVVD) technology. CVVD optimises engine
                      performance and fuel efficiency while also being
                      eco-friendly. The valve control technology regulates the
                      duration of the valve opening and closing according to
                      driving conditions. This is the only system that can
                      change the duration of the valve opening during a ride,
                      depending on conditions. A plug-in hybrid version of the
                      all-new Tucson with a 1.6-litre T-GDI engine and 265 PS
                      will also be available to customers. More details will be
                      revealed closer to the market launch in 2021.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p style={{ marginBottom: '0' }}>
                      <b>Hyundai’s new Intelligent Manual Transmission (iMT)</b>
                    </p>
                    <p className="theme-news-body-text">
                      All-new Tucson customers have the option of Hyundai’s
                      newly developed iMT. iMT operates purely electronically,
                      and decouples the engine from the transmission after the
                      driver releases the accelerator while driving at constant
                      speed, allowing the car to enter into coasting mode. This
                      improves overall fuel efficiency. The driver’s chosen gear
                      remains engaged even with the engine off. The engine
                      restarts in the same gear as soon as the driver presses
                      either the brake or accelerator pedals thanks to the burst
                      of power provided by the Mild Hybrid Starter Generator
                      (MHSG). The transmission restarts the engine in neutral
                      (with an open clutch) if the driver pushes the clutch
                      pedal to change gear, or if the vehicle speed is too low
                      for the current engaged gear. The first all-new Tucson
                      models – hybrid, diesel and gasoline including 48-volt
                      mild hybrid – will appear in Hyundai’s European showrooms
                      towards the end of this year. Meanwhile, the plug-in
                      hybrid version and N Line trim will arrive at the
                      beginning of next year.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      Link digital world premiere:
                    </p>
                    <iframe
                      src="https://youtu.be/COi2cKu_nZw?controls=1"
                      style={{ width: '100%' }}
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NewTucson;
