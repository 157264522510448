import React, { Component } from 'react';
import Store from 'store';
import VehicleImage from '../components/vehicleImage';

export default class CarSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedIndex: 0,
    };
  }

  handleSelectImage = (index) => {
    this.setState(
      {
        selectedIndex: index,
      },
      function() {
        Store.set('selectedVehicle', index);
        this.raiseOnSelectionChanged();
      },
    );
  };

  componentDidMount() {
    var selectedIndex = Store.get('selectedVehicle');
    if (selectedIndex != null) {
      this.setState(
        {
          selectedIndex: selectedIndex,
        },
        this.raiseOnSelectionChanged,
      );
    }
  }

  raiseOnSelectionChanged() {
    if (this.props.onSelectionChanged) {
      this.props.onSelectionChanged(this.state.selectedIndex);
    }
  }

  render() {
    const showButton = this.props.onNoLongerOwnedClick ? true : false;

    const vehicleImages = this.props.vehicles.map((vehicle, index) => {
      const className =
        index === this.state.selectedIndex
          ? 'img-fluid vehicle-sidebar-image'
          : 'img-fluid vehicle-sidebar-image unselectedCar';
      return (
        <div className="col-sm-6 col-lg-12" key={index}>
          <div onClick={() => this.handleSelectImage(index)}>
            <VehicleImage
              className={'vehicle-image-container mx-auto mb-2'}
              vehicle={vehicle}
              imgClassName={className}
              defaultImgClassName={className}
              overlayModel={true}
            />
          </div>
          {showButton && (
            <button
              type="button"
              className="btn btn-block btn-secondary btn-sm removeRadius"
              data-toggle="modal"
              data-target="#confirmOnwVehicleModal"
              onClick={() => {
                this.props.onNoLongerOwnedClick(vehicle, index);
                this.handleSelectImage(index);
              }}
            >
              No longer own this vehicle
            </button>
          )}

          <hr />
        </div>
      );
    });

    const className =
      this.props.className == null
        ? 'vehicle-selector row'
        : this.props.className;

    return <div className={className}>{vehicleImages}</div>;
  }
}
