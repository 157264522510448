import React, { Component } from "react";

export default class Superflex extends Component {
  render() {
    return (
      <div className="container">
        <div className="fadeInDownBigFull">
          <div className="card-body newsPadding">
            <div className="card-deck " id="Superflex">
              <div className="card">
                <div className="row">
                  <div className="col-md">
                    <img
                      id="Superflex-gallery-1"
                      className="card-img-top img-fluid news-gallary-image active"
                      src="images/superflex2017.jpg"
                      alt="Card image cap"
                    />
                  </div>
                </div>

                <div className="card-block">
                  <div className="card-content">
                    <div className="col">
                      <div className="row whiteBG">
                        <div className="col-lg-4">
                          <div className="salesText mt-3">
                            <p className="theme-news-snippet">
                              “SUPERFLEX to be the third artist in a major
                              series of large-scale installations for Tate
                              Modern’s Turbine Hall”
                            </p>
                          </div>
                          <hr />
                        </div>

                        <div className="col-lg-8 border-lg-left">
                          <div className="row pt-3">
                            <div className="col-md-10 offset-md-1">
                              <p className="salesText" data-search-tag="title">
                                HYUNDAI COMMISSION ENLISTS SUPERFLEX FOR 2017
                                TATE MODERN EXHIBITION
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <div className="theme-news-body-text">
                                {" "}
                                <ul>
                                  <li>
                                    Globally-acclaimed artist collective
                                    SUPERFLEX to bring playfully-subversive take
                                    to the Hyundai Commission 2017
                                  </li>
                                  <li>
                                    SUPERFLEX to be the third artist in a major
                                    series of large-scale installations for Tate
                                    Modern’s Turbine Hall
                                  </li>
                                  <li>
                                    Hyundai continues its contribution to the
                                    art community as the Hyundai Commission
                                    enters its third year
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div className="row pt-3">
                            <div className="col-md-10 offset-md-1">
                              <p
                                className="theme-news-body-text"
                                data-search-tag="summary"
                              >
                                {" "}
                                <strong>
                                  High Wycombe, March 16, 2017 –
                                </strong>{" "}
                                Internationally renowned Danish artist
                                collective SUPERFLEX has been selected to
                                undertake the third exhibition in the Hyundai
                                Commission series. The Copenhagen-based artist
                                group, recognised worldwide for its engaging and
                                often humorous perspectives on global societies
                                and cultures, will take over the iconic Turbine
                                Hall at Tate Modern from 3 October 2017.
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <div className="theme-news-body-text">
                                {" "}
                                <p>
                                  Founded in 1993 by Bjørnstjerne Christiansen,
                                  Jakob Fenger and Rasmus Nielsen, SUPERFLEX
                                  covers diverse topics from migration and
                                  alternative energy production to the power of
                                  global capital and the regulation of
                                  intellectual property. Referring to their
                                  works as tools, SUPERFLEX explores alternative
                                  means of creating, disseminating, and
                                  maintaining social and economic dialogue.
                                </p>
                                <p>
                                  Hyundai Motor said: "We are delighted to
                                  welcome SUPERFLEX as the third Hyundai
                                  Commission at Tate Modern. With their unique
                                  and witty approach, SUPERFLEX addresses
                                  important and complex issues of our times. We
                                  are looking forward to experiencing how their
                                  project for this year's Hyundai Commission
                                  will stimulate our imagination and challenge
                                  our understanding of the world."
                                </p>
                                <p>
                                  SUPERFLEX challenges the traditional confines
                                  and expectations of art and the exhibition
                                  space. Superkilen (2011) a major public park
                                  project in one of Copenhagen’s most diverse
                                  neighbourhoods was developed through
                                  collaboration with local residents from over
                                  50 countries. SUPERFLEX employed a strategy
                                  they call ‘extreme participation’ to engage
                                  the community and create a unifying urban
                                  space with a distinct international identity.
                                  In contrast, Hospital Equipment (2014)
                                  highlights the role of context in the
                                  definition of artistic practice. Consisting of
                                  an installation of surgical equipment
                                  dispatched directly from gallery to conflict
                                  zone, the work oscillates from ‘readymade’
                                  artwork to potentially lifesaving, functional
                                  object.
                                </p>
                                <p>
                                  The Hyundai Commission: SUPERFLEX (3 October
                                  2017 – 2 April 2018) will be the latest in a
                                  succession of ground-breaking work made
                                  possible through the partnership between
                                  Hyundai Motor and Tate. For the inaugural 2015
                                  exhibition, Abraham Cruzvillegas created the
                                  internationally-acclaimed sculpture Empty Lot,
                                  which included more than 23 tonnes of London
                                  soil, from which grass, weeds and flowers
                                  slowly emerged. The 2016 commission, entitled
                                  Anywhen by Philippe Parreno, remains open
                                  until 2 April 2017. Anywhen reimagines the
                                  expansive Turbine Hall with a constantly
                                  changing sequence of lights, soundscapes and
                                  films that bring the building’s architecture
                                  to life and challenge the minds of visitors.
                                </p>
                                <p>
                                  At Tate Modern, the Turbine Hall has hosted
                                  some of the world’s most acclaimed works of
                                  art since it opened in 2000. Reaching an
                                  audience of millions each year, every
                                  interpretation of the vast industrial space
                                  has revolutionised public perceptions of
                                  contemporary art in the modern day. The
                                  Hyundai Commission gives artists an
                                  opportunity to create new work within these
                                  unique parameters. It is made possible by the
                                  long-term partnership between Hyundai Motor
                                  and Tate, confirmed until 2025, as part of the
                                  longest initial commitment from a corporate
                                  sponsor in Tate’s history.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
