import Store from 'store';
import { offers } from './OffersData.js';
import Dates from '../utils/DateFormat';

export default {
  successCallback: null,
  failCallback: null,

  populateModels(success, failure) {
    this.successCallback = success;
    this.failCallback = failure;

    const promises = [
      this.getCustomer(),
      this.getVehicles(),
      this.getVehicleInterest(),
      this.getCompetitions(),
      this.getCarOffers(),
    ];

    Promise.all(promises)
      .then((result) => {
        // We need to have Customers and Vehicles before we call Offers
        return this.getOffers() ? this.successCallback() : this.failCallback();
      })
      .catch((error) => {
        this.failCallback();
      });
  },

  getCustomer() {
    return new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_API_URL +
          '/customer?key=' +
          Store.get('loginToken').replace(/['"']+/g, '') +
          '&Brand=HyundaiUk',
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            this.handleLoginError();
            throw new Error('Error in getting Customer info');
          }
        })
        .then((customer) => {
          Store.set('customerInfo', customer);

          resolve(true);
        })
        .catch((error) => {
          reject(new Error('reject'));
        });
    });
  },

  getVehicles() {
    return new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_API_URL +
          '/vehicle?key=' +
          Store.get('loginToken').replace(/['"']+/g, '') +
          '&Brand=HyundaiUk',
        {
          pragma: 'no-cache',
          'cache-control': 'no-cache',
        },
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            this.handleLoginError();
            throw new Error('Error in getting Vehicle info');
          }
        })
        .then((vehicles) => {
          var sortedVehicles = vehicles.sort(function (a, b) {
            var aDate = Date.parse(a.registrationDate);
            var bDate = Date.parse(b.registrationDate);
            if (isNaN(aDate)) {
              return 1;
            }
            if (isNaN(bDate)) {
              return -1;
            }
            return bDate - aDate;
          });

          // ----------------------------- //

          //  For Service Products -
          //      eSENSEtials servicing (page) visible if any car reaches 5yrs & 1d old
          //      Service Plans (page) visible if any car is  younger 5yrs & 1d old

          let isAnyVehicleOverFiveYears = false;
          let isAnyVehicleUnderFiveYears = false;

          // If there are vehicles.
          if (vehicles.length > 0) {
            //Loop through all the vehicle and return true if any vehicle is older than 5 years.
            isAnyVehicleOverFiveYears = vehicles.some((vehicle) => {
              // Get Registration date.
              let regDate = new Date(vehicle.registrationDate);
              // Minus one day.
              regDate.setDate(regDate.getDate() + 1);

              // Check  any vehicle that reached the age of 5 years and 1 day old
              const regDateCheck =
                regDate.setYear(regDate.getFullYear() + 5) < new Date();

              // If one returns true, this will stop and return true
              return regDateCheck;
            });

            //Loop through all the vehicle and return true if any vehicle is older than 5 years.
            isAnyVehicleUnderFiveYears = vehicles.some((vehicle) => {
              // Get Registration date.
              let regDate = new Date(vehicle.registrationDate);

              regDate.setDate(regDate.getDate() + 1);

              // Check if younger than 5 years old and 1 day.
              const regDateCheck =
                regDate.setYear(regDate.getFullYear() + 5) > new Date();

              // If one returns true, this will stop and return true
              return regDateCheck;
            });
          }

          const vehiclesInfo = {
            isAnyVehicleOverFiveYears: isAnyVehicleOverFiveYears,
            isAnyVehicleUnderFiveYears: isAnyVehicleUnderFiveYears,
          };

          //

          Store.set('vehiclesInfo', vehiclesInfo);
          Store.set('vehicles', sortedVehicles);

          resolve(true);
        })

        .catch((error) => {
          reject(new Error('reject'));
        });
    });
  },

  getVehicleInterest() {
    return new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_API_URL +
          '/hyundaiuk/vehicleinterest/get?key=' +
          Store.get('loginToken').replace(/['"']+/g, ''),
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            this.handleLoginError();
            throw new Error('Error in getting getVehicleInterests');
          }
        })
        .then((vehicleInterest) => {
          Store.set('vehicleInterest', vehicleInterest);

          resolve(true);
        })
        .catch((error) => {
          reject(new Error('reject'));
        });
    });
  },

  getOffers() {
    // filter offers by customer type
    return new Promise((resolve, reject) => {
      // filter offers by customer type

      const customer = Store.get('customerInfo');
      const vehicles = Store.get('vehicles');

      var vehicleModels = [];
      for (var { model } of vehicles) {
        vehicleModels.push(model);
      }

      const filteredOffers =
        offers === null
          ? []
          : offers.filter(function (offer) {
              const optional = offer.visibility.optional;

              const modelType = vehicleModels.some(
                (vehicle) =>
                  offer.visibility.modelType.indexOf(
                    vehicle
                      .replace(/\([^()]*\)/g, '')
                      .trim()
                      .toLowerCase(),
                  ) >= 0,
              );

              const excludedType = vehicleModels.some(
                (vehicle) =>
                  offer.visibility.excludedType.indexOf(
                    vehicle
                      .replace(/\([^()]*\)/g, '')
                      .trim()
                      .toLowerCase(),
                  ) >= 0,
              );

              let serviceOrMotOverThreeMonths = true;

              if (offer.visibility.serviceOrMotOverThreeMonths) {
                serviceOrMotOverThreeMonths = vehicles.every((vehicle) => {
                  //Is nextMotDate inside 3 months

                  const nextMotDate = new Date(vehicle.nextMotDate);

                  const nextMotDateCheck =
                    nextMotDate < new Date() ||
                    nextMotDate.setMonth(nextMotDate.getMonth() - 3) >
                      new Date();

                  //Is nextServiceDate inside three months
                  const nextServiceDate = new Date(vehicle.nextServiceDate);

                  const nextServiceDateCheck =
                    nextServiceDate < new Date() ||
                    nextServiceDate.setMonth(nextServiceDate.getMonth() - 3) >
                      new Date();

                  //is Date of work inside 6 months
                  const dateOfWorkWithin6months = vehicle.hyundaiUkServiceHistoryList.every(
                    (list) => {
                      const dateOfWork = Dates.formatDateFrom_ddmmyyyy(
                        list.dateOfWork,
                      );

                      const dateOfWorkCheck =
                        dateOfWork.setMonth(dateOfWork.getMonth() + 6) <
                        new Date();

                      return dateOfWorkCheck;
                    },
                  );

                  return (
                    nextMotDateCheck &&
                    nextServiceDateCheck &&
                    dateOfWorkWithin6months
                  );
                });
              }

              const customerType = offer.visibility.customerType.includes(
                customer.customerType,
              );

              if (optional) {
                if (
                  (customerType || modelType) &&
                  !excludedType &&
                  serviceOrMotOverThreeMonths
                ) {
                  return offer;
                } else if (excludedType) {
                  return null;
                }
              } else {
                if (
                  customerType &&
                  modelType &&
                  !excludedType &&
                  serviceOrMotOverThreeMonths
                ) {
                  return offer;
                } else if (excludedType) {
                  return null;
                }
              }
            });
      Store.set('offers', filteredOffers);
      resolve(true);
    });
  },

  getCompetitions() {
    return new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_API_URL +
          '/HyundaiUk/Competition/Load?key=' +
          Store.get('loginToken').replace(/['"']+/g, ''),
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error('Error in getting competition info');
          }
        })
        .then((competitions) => {
          Store.set('competitionInfo', competitions);

          resolve(true);
        })

        .catch((error) => {
          reject(new Error('reject'));
        });
    });
  },

  getCarOffers() {
    return new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_API_URL +
          '/HyundaiUk/SalesOffer/get?key=' +
          Store.get('loginToken').replace(/['"']+/g, ''),
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error('Error in getting car sales offers');
          }
        })
        .then((carOffers) => {
          Store.set('carOffers', []);

          resolve(true);
        })

        .catch((error) => {
          reject(new Error('reject'));
        });
    });
  },

  refreshCompetitions() {
    fetch(
      process.env.REACT_APP_API_URL +
        '/HyundaiUk/Competition/Load?key=' +
        Store.get('loginToken').replace(/['"']+/g, ''),
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Error in getting competition info');
        }
      })
      .then((competitions) => {
        Store.set('competitionInfo', competitions);
      })
      .catch((error) => {
        console.warn(error);
      });
  },

  async getArticles() {
    const response = await fetch(
      `${process.env.REACT_APP_REWARDS_API_URL}/News`,
      {
        headers: {
          authKey: '8RFGHJI87654RFGHJI89',
          clientReference: 'HUK',
        },
      },
    ).catch((error) => {
      console.log(new Error('reject'));
    });

    const data = await response.json();
    Store.set('articles', data);
  },

  getFeedbackForm() {
    return new Promise((resolve, reject) => {
      fetch(
        `${process.env.REACT_APP_QUESTIONNAIRE_API_URL}/Questionnaire/feedback`,
        {
          headers: {
            authKey: 'CJ8324U0F32NUD08U032U0G43G43',
            clientReference: 'HMUK',
          },
        },
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            this.handleLoginError();
            throw new Error('Error in getting feedback Form');
          }
        })
        .then((questionnaire) => {
          Store.set('questionnaire', questionnaire);
          resolve(true);
        })
        .catch((error) => {
          reject(new Error('reject'));
        });
    });
  },
};
