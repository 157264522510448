import React from 'react';
import SlideLink from './slideLink';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';
import Store from 'store';

export default class Promo extends React.Component {
  clickHandler = (link) => {
    if (link === 'newsletter') {
      ReactGA.event({
        label: 'News Articles',
        category: 'WHN',
        action: `Home Page MyHyundai NewsLetter "Title" Clicked`,
        gaOptions: {
          siteSpeedSampleRate: 100,
          userId: Store.get('customer') && Store.get('customer').urn,
        },
      });
    }
  };

  render() {
    const promo = this.props.promo;
    const slide = promo.slides[0];

    if (slide == null) {
      return null;
    }

    return (
      <div className="card">
        <div className="card-header homeCH customise-handle">
          <i className="fas fa-arrows-alt promos-move"></i>
          {promo.link ==
          'https://www.hyundaiexperience.com/environment/exterior' ? (
            <a
              href="https://www.hyundaiexperience.com/environment/exterior"
              target="_blank"
            >
              {promo.name}
            </a>
          ) : (
            <Link to={promo.link} onClick={() => this.clickHandler(promo.link)}>
              {promo.name}
            </Link>
          )}
        </div>
        <SlideLink slide={slide} imageClassName="card-img" />
        <div className="card-block">
          <div className="card-footer text-center" title={slide.title}>
            {slide.text}
          </div>
        </div>
      </div>
    );
  }
}
