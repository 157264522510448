import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './footer.css';

export default class FooterWHN extends Component {
  render() {
    return (
      <footer className="container footer " data-search-exclude>
        <div className="row clrBlack mx-0" data-wow-delay="0.2s">
          <div className="col-md-3 col-lg">
            <img
              className="img-fluid"
              id="footerLogo"
              src="../images/Hyundai_Logo_Hor_Reversed.png"
            />
          </div>
          <div className="col-sm-4 col-md-3 col-lg text-center">
            <a
              href="https://www.hyundai.co.uk/brochure-request"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="text-muted">
                <small>Brochure</small>
              </span>
            </a>
          </div>
          <div className="col-sm-4 col-md-3 col-lg text-center">
            <a
              href="http://www.hyundai.co.uk/test-drive"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="text-muted">
                <small>Test Drive</small>
              </span>
            </a>
          </div>
          {/* <div className="col-sm-4 col-md-3 col-lg text-center">
            <a href="http://clicktobuy.hyundai.co.uk/" target="_default">
              <span className="text-muted">
                <small>Click to Buy</small>
              </span>
            </a>
          </div> */}
          <div className="col-sm-4 col-md-3  offset-md-3  offset-lg-0 col-lg text-center">
            <a
              href="https://www.hyundai.co.uk/dealer-locator"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="text-muted">
                <small>
                  Dealer
                  <span className="d-none d-lg-inline d-xl-none">s</span>
                  &nbsp;
                  <span className="d-lg-none d-xl-inline">Locator</span>
                </small>
              </span>
            </a>
          </div>
          <div className="col-sm-4 col-md-3 col-lg text-center">
            <Link to="/contactus">
              <span className="text-muted">
                <small>Contact Us</small>
              </span>
            </Link>
          </div>
          <div className="col-sm-4 col-md-3 col-xl socMedia">
            <a
              href="https://www.facebook.com/hyundaiuk"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-facebook-f iconSpacer whiteLink" />
            </a>
            <a
              href="https://twitter.com/hyundai_uk/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-twitter iconSpacer whiteLink" />
            </a>
            <a
              href="https://www.linkedin.com/company/hyundaimotoruk"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-linkedin-in iconSpacer whiteLink" />
            </a>
            <a
              href="https://www.instagram.com/hyundaiuk/?hl=en"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-instagram iconSpacer whiteLink" />
            </a>
            <a
              href="https://www.youtube.com/user/HyundaiMotorUK"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-youtube whiteLink" />
            </a>
          </div>
        </div>
        <div className="row">
          {' '}
          <div className="col">
            <p className="text-center small px-3 px-sm-0 mb-0">
              <small>
                &#169; Copyright {new Date().getFullYear()} Hyundai Motor UK Ltd
              </small>
              <small>
                <span className="float-left">
                  <a
                    href="https://www.hyundai.com/uk/en/legal-disclaimer.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {' '}
                    Legal
                  </a>{' '}
                  |{' '}
                  <a
                    href="https://www.hyundai.com/uk/en/privacy-policy.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {' '}
                    Privacy
                  </a>
                </span>
              </small>
            </p>{' '}
          </div>
        </div>{' '}
      </footer>
    );
  }
}
