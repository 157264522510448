import React, { Component } from 'react';
import './contactPref.css';
import FooterNavbar from '../components/footerNavbar';
import Store from 'store';
import Topnavbar from '../components/topnavbar';
import Bottomnavbar from '../components/bottomnavbar';
import Footer from '../components/footer';
import $ from 'jquery';
import JSEncrypt from 'jsencrypt';
import OffersSideNav from '../components/offersSideNav';

export default class OffersSummerCheck extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Topnavbar />
        <Bottomnavbar />

        <div className="container">
          {/* <ProfileNav /> */}

          <section
            className="section1 wow fadeIn"
            data-wow-duration="2s"
            data-wow-delay="0.2s"
          >
            <div className="content pb-5  p-md-5 col-md-10 offset-md-1 border-right border-bottom">
              <div className="row">
                <div className="col-md-3 mt-4 border-md-right">
                  <OffersSideNav />
                </div>

                <div className="col-md-9  p-md-4 contact-perf-content">
                  <h4 className="h4ServHistory" data-search-tag="title">
                    Stay cool with a Hyundai Summer Check
                  </h4>
                  <hr />
                  <div>
                    <img
                      className="card-img-top img-fluid"
                      src="images/summerCheckCompetition-banner.jpg"
                    />
                  </div>
                  <br />
                  <p className="remindersBlue" data-search-tag="summary">
                    As the temperature rises, our Summer Check will keep both
                    you and your Hyundai nice and chilled.
                  </p>
                  <p className="remindersBlue" data-search-tag="summary">
                    You want to know that your car's in tip top condition when
                    you're heading off on a summer trip so it makes sense to get
                    it checked by trained Hyundai technicians.
                  </p>{' '}
                  <p className="remindersBlue" data-search-tag="summary">
                    For just £24.99 we'll test your air-conditioning and top up
                    the coolant and screen wash. And you'll receive a free
                    Chilly's stainless steel water bottle worth £25*.
                  </p>
                  <p className="remindersBlue" data-search-tag="summary">
                    Your Summer Check includes:
                  </p>
                  <ul className="remindersBlue" data-search-tag="summary">
                    <li>
                      Air conditioning temperature, blower operation and cabin
                      filter check
                    </li>
                    <li>
                      Engine coolant and screen wash levels check and top-up
                    </li>
                    <li>Engine management software update</li>
                    <li>
                      Vehicle Health Check – a visual inspection of key areas of
                      your vehicle
                    </li>
                    <li>A free 500ml Hyundai branded Chilly's bottle</li>
                  </ul>
                  <p className="remindersBlue" data-search-tag="summary">
                    If you'd like to breeze through summer this year, book now.
                  </p>
                  <p>
                    <strong>*While stocks last</strong>
                  </p>
                  <a  
                    href="https://www.hyundai.co.uk/owning/book-a-service"
                    target="_blank" rel="noopener noreferrer"
                  >
                    <button className="btn btn-sm removeRadius btn-primary">
                      Book now
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </section>

          <FooterNavbar />
        </div>

        <Footer />
      </div>
    );
  }
}
