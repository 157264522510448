import React, { Component } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import Dropzone from 'react-dropzone';
import $ from 'jquery';
export default class ImageUpload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      file: null,
      fileIsInvalid: false,
      isLoading: true,
      image: null,
      imageCan: null,
      errorMessage: null,
      file_size: null,
      file_limit_exceeded: false,
      upload_button_disabled: true,
    };
  }

  componentDidMount() {
    $('body').popover({
      selector: '[data-toggle="popover"]',
      trigger: 'hover',
      html: true,
    });

    fetch(
      process.env.REACT_APP_API_URL +
        '/customer/image?key=' +
        this.props.loginToken +
        '&imageType=' +
        this.props.imageType,
      {
        method: 'get',
      },
    )
      .then((response) => {
        if (response.ok) {
          return response.text();
        }
        throw new Error('Get images failed');
      })
      .then((responseData) => {
        var data = JSON.parse(responseData);
        if (data.images.length > 0) {
          this.setState({
            image: data.images[0],
            isLoading: false,
          });
        } else {
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((e) => console.log(e));
  }

  componentDidUpdate() {
    //TODO: change this to vanilla

    $('.dropzone').on('dragover', function() {
      $(this).addClass('drophover');
    });

    $('.dropzone').on('dragleave', function() {
      $(this).removeClass('drophover');
    });

    if ($('#' + this.props.uniqueName + '-modal').length) {
      $('#' + this.props.uniqueName + '-modal').appendTo('body');
    }
  }

  handleDrop = (acceptedFiles, rejectedFiles) => {
    this.setState({ imageCan: acceptedFiles[0].preview });

    if (acceptedFiles && acceptedFiles.length > 0) {
      this.setState({
        file: acceptedFiles[0],
        fileIsInvalid: false,
      });
    } else {
      this.setState({
        file: null,
        fileIsInvalid: true,
      });
    }
  };

  /**
   * Convert a base64 string in a Blob according to the data and contentType.
   *
   * @param b64Data {String} Pure base64 string without contentType
   * @param contentType {String} the content type of the file i.e (image/jpeg - image/png - text/plain)
   * @param sliceSize {Int} SliceSize to process the byteCharacters
   *
   * @return Blob
   */
  b64toBLOB = (b64Data, contentType, sliceSize) => {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });

    return blob;
  };

  splitLastSlash = (string) => {
    if (string == null) {
      return;
    }
    var nameArray = string.split('/');
    var name = nameArray.pop();
    return name;
  };

  formatBytes = (a, b) => {
    if (0 === a) return '0 Bytes';
    var c = 1024,
      d = b || 2,
      e = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      f = Math.floor(Math.log(a) / Math.log(c));
    return parseFloat((a / Math.pow(c, f)).toFixed(d)) + ' ' + e[f];
  };

  croppedImage = () => {
    var ImageURL = this.refs.cropper.getCroppedCanvas().toDataURL();

    if (!ImageURL) {
      return;
    }
    var block = ImageURL.split(';');

    // Get the content type of the image
    var contentType = block[0].split(':')[1];

    // Get data string part of base64
    var realData = block.length > 1 ? block[1].split(',')[1] : null;

    // Convert base64 data to a blob
    var blob = this.b64toBLOB(realData, contentType);

    //Get the location of the image
    var nameURL = URL.createObjectURL(blob);

    //Get the name of the file
    var name = this.splitLastSlash(nameURL);

    //Get the file extension
    var fileExt = this.splitLastSlash(contentType);

    //We can't use new File constructor here (I.E. Edge compatibility) so we create a pseudo File object
    blob.name = name + '.' + fileExt;
    blob.lastModified = Date.now();
    blob.lastModifiedDate = new Date();

    this.setState({
      file: blob,
      file_size: this.formatBytes(blob.size, 2),
      file_limit_exceeded: blob.size >= 3145728,
      upload_button_disabled: blob.size >= 3145728,
    });
  };

  handleUpload = () => {
    let data = new FormData();

    data.append('image', this.state.file, this.state.file.name);

    var httpMethod = this.state.image == null ? 'post' : 'put';
    var query =
      this.state.image == null
        ? 'key=' + this.props.loginToken + '&imageType=' + this.props.imageType
        : 'key=' + this.props.loginToken + '&imageId=' + this.state.image.id;

    fetch(process.env.REACT_APP_API_URL + '/customer/image?' + query, {
      method: httpMethod,
      body: data,
    })
      .then((response) => response.json())
      .then((responseData) => {
        if (responseData.status === 0) {
          // Success
          if (responseData.images.length > 0) {
            this.setState({
              image: responseData.images[0],
              isLoading: false,
              errorMessage: null,
            });
          } else {
            throw new Error('No images returned');
          }
        } else if (responseData.status === 1) {
          // Missing Image
          this.setState({
            errorMessage: 'Please choose the image to upload',
            isLoading: false,
          });
        } else if (responseData.status === 2) {
          // Invalid image
          this.setState({
            errorMessage: 'The file was not a valid image',
            isLoading: false,
          });
        } else if (responseData.status === 3) {
          // Image too large
          this.setState({
            errorMessage: 'The image was too large',
            isLoading: false,
          });
        } else if (responseData.status === 4) {
          // Invalid Image Type
          this.setState({
            errorMessage: 'There was an error saving your photo',
            isLoading: false,
          });
        } else if (responseData.status === 5) {
          // Unauthorised
          this.setState({
            errorMessage: 'You do no have access to this photo',
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          errorMessage: 'There was an error saving your photo',
          isLoading: false,
        });
      });

    this.setState({
      isLoading: true,
    });

    this.clear();
  };

  handleClear = () => {
    this.clear();
  };

  clear() {
    if (this.state.file != null) {
      window.URL.revokeObjectURL(this.state.file.preview);
    }
    this.setState({
      file: null,
      fileIsInvalid: false,
      file_size: null,
      file_limit_exceeded: false,
      upload_button_disabled: true,
    });
  }

  render() {
    const { file, fileIsInvalid, isLoading, errorMessage } = this.state;
    const className = this.props.className + ' imageEditButtonContainer';
    const imgContainerClassName = this.props.imgContainerClassName + '  ';
    const modalId = this.props.uniqueName + '-modal';

    const ratio =
      this.props.uniqueName ==='wallpaper-photo' ? 925 / 166 : 1 / 1;

    const modalName =
      this.props.uniqueName ==='wallpaper-photo'
        ? 'Profile Cover'
        : 'Profile Picture';

    const hasFile = file != null;

    const limitExceeded = this.state.file_limit_exceeded && hasFile;

    const uploadButton =
      this.state.file_limit_exceeded || this.state.upload_button_disabled;

    const imageUrl =
      this.state.image == null
        ? this.props.defaultImage
        : this.state.image.urls.lg;

    const showLoading = isLoading;
    const showError = !isLoading && errorMessage != null;
    const showImage = !showLoading && !showError && imageUrl != null;

    const backgroundImage = {
      backgroundImage: 'url(' + imageUrl + ')',
    };

    return (
      <div className={className}>
        <div className={'row ' + imgContainerClassName}>
          {showLoading && (
            <div className="image-loading">
              <i className="fas fa-spinner fa-pulse" />
            </div>
          )}
          {showError && (
            <div className="image-loading">
              <p className="text-danger text-center">{errorMessage}</p>
            </div>
          )}
          {showImage && (
            <div
              className={'theme-personal-image ' + this.props.uniqueName}
              style={backgroundImage}
            />
          )}
          {this.props.children}
          <button
            className="btn btn-sm theme-btn-edit removeRadius persButtonLeft image-edit-button"
            data-toggle="modal"
            data-target={'#' + modalId}
          >
            <i className="far fa-edit" /> <small>Edit</small>
          </button>

          {/* ====================== Upload Modal ====================== */}

          <div
            id={modalId}
            className="modal fade"
            tabIndex="-1"
            role="dialog"
            data-keyboard="false"
            data-backdrop="static"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Upload {modalName}</h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={this.handleClear}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body text-center">
                  {!hasFile ? (
                    <Dropzone
                      onDrop={this.handleDrop}
                      accept="image/*"
                      multiple={false}
                      className="dropzone"
                    >
                      <div>
                        <p className="pt-3 px-3 theme-personal-upload-text">
                          Drop image here to upload
                        </p>
                        <p className="small mt-0 theme-personal-upload-text">
                          or
                        </p>
                        <p>
                          <button className="btn btn-default theme-personal-upload-btn removeRadius ">
                            Select File
                          </button>
                        </p>
                        <p className="small text-muted  theme-personal-upload-text">
                          <small className="small">
                            Maximum upload file size: 3MB
                          </small>
                        </p>
                      </div>
                    </Dropzone>
                  ) : (
                    <div>
                      <Cropper
                        ref="cropper"
                        src={this.state.imageCan}
                        style={{ height: 400, width: '100%' }}
                        // Cropper.js options
                        viewMode={2}
                        aspectRatio={ratio}
                        guides={false}
                        preview={'.' + this.props.uniqueName}
                        autoCropArea={1}
                        crop={this.croppedImage}
                        dragMode={'move'}
                      />
                    </div>
                  )}
                  {fileIsInvalid && (
                    <p className="text-danger">
                      <small>
                        The selected file is invalid file type, please try
                        another.
                      </small>
                    </p>
                  )}
                </div>
                <div className="modal-footer justify-content-between">
                  <div
                    className={
                      this.state.file_limit_exceeded
                        ? 'text-danger'
                        : 'text-success'
                    }
                  >
                    <span>{this.state.file_size}</span>
                    {limitExceeded && (
                      <span>
                        &nbsp;limit exceeded&nbsp;
                        <button
                          type="button"
                          className="btn btn-link text-muted"
                          data-container="body"
                          data-toggle="popover"
                          data-placement="top"
                          data-content="Try cropping the image to reduce the file size"
                        >
                          <i className="far fa-question-circle" />
                        </button>
                      </span>
                    )}
                  </div>
                  <div>
                    <button
                      type="button"
                      className="btn btn-outline-secondary removeRadius"
                      data-dismiss="modal"
                      onClick={this.handleClear}
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      className={'removeRadius ml-3 btn btn-primary'}
                      disabled={uploadButton}
                      data-dismiss="modal"
                      onClick={this.handleUpload}
                    >
                      Upload
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
