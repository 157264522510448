import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './contactPref.css';
import FooterNavbar from '../components/footerNavbar';
import Store from 'store';
import TopNavbar from '../components/topnavbar';
import BottomNavbar from '../components/bottomnavbar';
import Footer from '../components/footer';
import OffersSideNav from '../components/offersSideNav';
import $ from 'jquery';
import JSEncrypt from 'jsencrypt';
import PostItemSingular from '../components/postItemSingular';
import PostItemMultiple from '../components/postItemMultiple';

export default class Offers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customer: Store.get('customerInfo'),
      offers: Store.get('offers'),
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { offers } = this.state;

    return (
      <div>
        <TopNavbar />
        <BottomNavbar />

        <div className="container">
          <section
            className="section1 wow fadeIn"
            data-wow-duration="2s"
            data-wow-delay="0.2s"
          >
            <div className="content pb-5  p-md-5 col-md-10 offset-md-1 border-right border-bottom">
              <div className="row">
                <div className="col-md-3 mt-4 border-md-right">
                  <OffersSideNav />
                </div>

                <div className="col-md-9  p-md-4 contact-perf-content">
                  <h4 className="h4ServHistory" data-search-tag="title">
                    <strong>MyHyundai offers you more</strong>
                  </h4>
                  <br />
                  <p className="remindersBlue">
                    <span data-search-tag="summary">
                      One of the many benefits of MyHyundai are the exclusive
                      offers. Including discounts from our partnerships,
                      money-off Hyundai products and a lot more.
                    </span>{' '}
                    The offers are updated on a regular basis so don't forget to
                    come back and check what we have on offer.
                  </p>
                  {(() => {
                    if (this.state.offers.length === 0) {
                      return <div>There are currently no offers</div>;
                    } else if (this.state.offers.length === 1) {
                      return (
                        <div
                          className="row"
                          data-testing-automation="offer-singular"
                        >
                          {/* --------------------- Singular post be here ---------------------- */}
                          <PostItemSingular
                            imageSrc={offers[0].imageSrc}
                            imageAlt={offers[0].imageAlt}
                            title={offers[0].title}
                            link={offers[0].link}
                            closesText={offers[0].closesText}
                            buttonText={offers[0].buttonText}
                          />
                          {/* --------------------- ---------------------- --------------------- */}
                        </div>
                      );
                    } else {
                      return (
                        <div
                          className="row"
                          data-testing-automation="offer-multiple"
                        >
                          {/* --------------------- Multiple posts be here --------------------- */}
                          {this.state.offers.map((offer, index) => {
                            return (
                              <PostItemMultiple
                                imageSrc={offer.imageSrc}
                                imageAlt={offer.imageAlt}
                                title={offer.title}
                                link={offer.link}
                                closesText={offer.closesText}
                                buttonText={offer.buttonText}
                                key={index.toString()}
                              />
                            );
                          })}
                          {/* --------------------- ---------------------- --------------------- */}
                        </div>
                      );
                    }
                  })()}
                </div>
              </div>
            </div>
          </section>

          <FooterNavbar />
        </div>

        <Footer />
      </div>
    );
  }
}
