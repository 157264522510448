import React, { Component } from 'react';

export default class InputField extends Component {
  render() {
    var className =
      this.props.className == null
        ? 'custom-control-input'
        : this.props.className;
    className =
      !this.props.validation ||
      this.props.validation == null ||
      this.props.validation.isValid
        ? className
        : className + ' is-invalid';

    const type = this.props.type == null ? 'checkbox' : this.props.type;

    return (
      <div className="custom-control custom-checkbox">
        <input
          id={this.props.id}
          type={type}
          className={className}
          name={this.props.name}
          checked={this.props.checked}
          onChange={this.props.onChange}
        />
        <label
          id={this.props.id + 'Label'}
          className="custom-control-label"
          htmlFor={this.props.id}
        >
          {this.props.label}
        </label>
        <div className="invalid-feedback">
          {this.props.validation ? this.props.validation.message : ''}
        </div>
      </div>
    );
  }
}
