import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';
import Store from 'store';
import ReactGA from 'react-ga';
export default class KonaCamp extends Component {
  componentDidMount = () => {};

  clickHandler = () => {};

  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="konaCamp" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="oneMillion-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/konaCampTile.jpg") ',
              }}
            />
            {/* <div
              id="Bayon-gallery-2"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/hyundaielectric2.jpg")',
              }}
            /> */}
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                {/* <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#Bayon-gallery-2"
                    style={{
                      backgroundImage: 'url("images/hyundaielectric2.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#Bayon-gallery-1"
                    style={{
                      backgroundImage: 'url("images/hyundaielectric1.jpg")',
                    }}
                  />
                </div> */}
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “Hyundai celebrates the power of imagination in new KONA
                  campaign”
                </p>
              </div>
              <hr />

              <NewsMedia articleId="awards" articleText="awards" />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    HYUNDAI CELEBRATES THE POWER OF IMAGINATION IN NEW KONA
                    CAMPAIGN
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    {' '}
                    <ul>
                      <li>
                        Hyundai introduces the all-new KONA Electric launch
                        campaign with the tagline “Live Unlimited”
                      </li>
                      <li>
                        The campaign sparks inspiration, asking "When did you
                        stop believing anything is possible?”, embodying the
                        brand's commitment to innovation and progress for
                        humanity.
                      </li>
                      <li>
                        The company created a memorable campaign that reconnects
                        with childhood imagination.
                      </li>
                      {/* <li>
                        Hyundai TUCSON is best Mid-size SUV, while New Hyundai
                        i10 is best City Car
                      </li>
                      <li>
                        Prestigious Auto Express awards inform buyers about the
                        finest cars on the market
                      </li>
                      <li>
                        All-new KONA is available to order now, with prices from
                        £25,725
                      </li> */}
                      {/* <li>
                        Among IONIQ 5 N’s many exclusive performance features
                        are N Battery Preconditioning, N Race, N Pedal, N Brake
                        Regen, N Drift Optimizer, N Torque Distribution, N
                        Launch Control, N Grin Boost, N Active Sound +, N
                        e-shift, N Road Sense and Track SOC
                      </li> */}
                      {/* <li>
                        Hyundai is investing £1 million in a programme of school
                        trips in order to prevent children missing out on such
                        experiences
                      </li>
                      <li>
                        Bookings are now open for the initiative, which has
                        launched with Hyundai’s Educator in Residence, Tim
                        Campbell MBE
                      </li> */}
                    </ul>
                  </p>
                  {displayShowMore && (
                    <p>
                      <a
                        data-toggle="collapse"
                        href="#konaCampaign"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </div>

              <div className={moreSectionClassName} id="konaCampaign">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      <strong>
                        Hyundai Motor Europe has unveiled a launch campaign for
                        the all-new KONA. The innovative release, which recently
                        aired in Europe, aims to showcase how a child's
                        imagination knows no bounds, while also highlighting the
                        endless opportunities brought about by technological
                        advancements.
                      </strong>
                      <br />
                      <br />
                      <h2>The emotive launch of the all-new KONA</h2>
                      <br />
                      The campaign emphasizes not only the provision of the
                      all-new KONA’s cutting-edge technology, but also the
                      creation of emotional experiences. The launch encapsulates
                      the essence of Hyundai's vision in the direction of "Live
                      Unlimited". It aims to conquer the unimaginable and
                      promote innovative solutions for everyday life. The new
                      KONA stands as a testament to this vision, encouraging
                      customers to embark on a new era of sustainable
                      transportation. Accompanied by a beautiful piece of visual
                      storytelling, Hyundai skilfully depicts the car's advanced
                      features and extended electrical range, empowering people
                      to drive with confidence.
                      <br />
                      <br />
                      <h2>Connecting with the inner-child</h2>
                      <br /> In a captivating hero film, the new KONA EV takes
                      centre stage, embarking on a whimsical journey through the
                      city. Along the way, the car encounters knights on horses,
                      a chasing dragon, a racecar, and even a monster truck.
                      <br />
                      <br />
                      <img src="images/konaCamp1.jpg" className="img-fluid" />
                      <br />
                      <br />
                      However, a twist reveals that these extraordinary
                      scenarios were products of a child's imagination, the one
                      from Noah, a boy inspired by the trips he took in the new
                      KONA. This striking revelation underscores the idea that
                      the new model is not just a car but a vehicle that sparks
                      imagination and inspires to pursue our dreams.
                      <br />
                      <br />
                      <img src="images/konaCamp2.jpg" className="img-fluid" />
                      <br />
                      <br />
                      The film concludes with a powerful message: "When did you
                      stop believing anything is possible?" This message truly
                      reflects the spirit of Hyundai as the company relentlessly
                      pursues its goal of becoming a sustainable mobility
                      solutions provider. By wholeheartedly embracing this
                      mindset, Hyundai recognizes the intrinsic link between
                      realizing our full potential and driving progress. It
                      serves as a reminder that meaningful advancements in
                      sustainable mobility can only be achieved by pushing
                      boundaries and embracing new ideas, much like how we do
                      when we connect with our inner child. The imagery in the
                      film symbolizes how the new generation KONA Electric, with
                      its cutting-edge technology, can unlock new possibilities
                      and enrich the lives of customers, offering a more
                      fulfilling experience.
                      <br />
                      <br />
                      <img src="images/konaCamp3.jpg" className="img-fluid" />
                      <br />
                      <br />
                      <strong>
                        "We are thrilled to launch the new KONA Electric, a true
                        embodiment of Hyundai’s commitment to innovation and
                        sustainable mobility. With its captivating hero film and
                        imaginative storytelling, we are inviting our customers
                        to rediscover the joy of limitless possibilities. We are
                        excited to inspire a new wave of belief based on our
                        brand’s spirit: have you tried?""
                      </strong>
                      <br />
                      Laura Rathai
                      <br />
                      Director of Marketing at Hyundai Motor Europe
                      <br />
                      <br />
                      <h2>Future-Ready Driving with a stunning design</h2>{' '}
                      <br />
                      The second-generation KONA sets a new standard in size and
                      future readiness, making it the ideal choice for
                      progressive European customers seeking an adaptable e-SUV
                      that complements their dynamic lifestyles. Designed with a
                      future-ready concept in mind, the new KONA will remain up
                      to date throughout its entire life cycle, thanks to
                      features like Over-the-Air (OTA) updates.
                      <br />
                      <br /> Putting consumer convenience at the forefront, the
                      new model offers a spacious interior and exceptional
                      comfort, ensuring a great experience and peace of mind for
                      drivers and passengers alike. It boasts the longest
                      All-Electric Range (AER) among its competitors with 514 km
                      and features advanced technologies like its Digital Key 2
                      Touch feature which provides seamless access. The
                      integration of double 12.3-inch displays delivers an
                      immersive and interactive experience for drivers.
                      <br />
                      <br /> With its larger size, future readiness,
                      consumer-centric convenience, and connected technologies,
                      the new KONA Electric sets the stage for a dynamic driving
                      experience that caters to the needs and desires of today's
                      forward-thinking customers.
                      <br />
                      <br />
                      <img src="images/konaCamp4.jpg" className="img-fluid" />
                      <br />
                      <br />
                      <strong>
                        "It is Hyundai's clear commitment to innovation and a
                        customer-centric approach that moves us to continuously
                        push boundaries and redefine driving experiences. The
                        second-generation KONA represents the embodiment of our
                        brand's values, combining progressive design, advanced
                        technology, and unparalleled convenience. It is a
                        testament to Hyundai's unwavering dedication to
                        providing customers with vehicles that inspire
                        confidence while exceeding expectations as we move
                        towards the future of mobility."
                      </strong>
                      <br />
                      Andreas-Christoph Hofmann
                      <br />
                      Vice President Marketing, Product & PR at Hyundai Motor
                      Europe
                      <br />
                      <br />
                      <h2>A multifaceted launch strategy</h2> Hyundai's latest
                      launch was produced in Budapest to showcase the European
                      lifestyle and the city’s stunning architecture. One of the
                      standout visual effects is the seamless spinning movement
                      of the new KONA, reminiscent of the way kids play with
                      their toy cars. The practical effect was executed by
                      placing the car on a specially built turning platform for
                      the shot.
                      <br />
                      <br /> The strategic marketing campaign, designed by
                      Innocean Berlin, the creative agency behind this project,
                      aims to inspire progressive mindsets through various
                      formats. TV spots, online videos, and social media assets
                      are utilized to effectively present Hyundai's “Live
                      Unlimited” vision. Alongside the hero film, the launch
                      includes impactful 3D Out-of-home (OOH) and print ads,
                      which add drama and amplify the video’s impact.
                      <br />
                      <br /> To further enhance the product discovery
                      experience, new walkaround videos have been created by
                      design and innovation agency AKQA, providing in-depth
                      exploration of the model's features. Additionally, shorter
                      15" product films have been developed to highlight
                      specific features, offering a quick glimpse of what makes
                      this model unique. With its engaging and innovative
                      marketing approach, Hyundai's latest launch aims to
                      captivate audiences and leave a lasting impression.
                      <br />
                      <br />
                      <strong>Disclaimer: CO2 and emissions data</strong>
                      <br />
                      <br /> Hyundai’s all-new KONA Electric is not currently
                      available for sale. Electricity consumption and CO2 values
                      for this model have not yet been determined.
                      <br />
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
