import React from 'react';
import SlideLink from './slideLink';
import { Link } from 'react-router-dom';
import Store from 'store';
export default class PromoCarousel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      customer: Store.get('customerInfo'),
    };
  }

  render() {
    const promo = this.props.promo;
    const id = promo.key + 'Carousel';
    const indicators = promo.slides.map(function(slide, i) {
      if (id ==='competitionsCarousel') {
        if (i === 0) {
          //Start display count
          return (
            <div className="notActive" key={i}>
              <span className="compsCounter">{promo.slides.length}</span>{' '}
              <span className="compsLive"> Competitions Live</span>
            </div>
          );
        }
      }
      //  End display count
      else {
        if (i === 0) {
          return (
            <li
              key={i}
              data-target={'#' + id}
              data-slide-to={i}
              className="active"
            >
              <span className="carouselNumbers">{i + 1}</span>
            </li>
          );
        } else {
          return (
            <li key={i} data-target={'#' + id} data-slide-to={i}>
              <span className="carouselNumbers2">{i + 1}</span>
            </li>
          );
        }
      }
    });

    const carouselSlides = promo.slides.map(function(slide, i) {
      const additionalCssName = i === 0 ? 'active' : '';
      return (
        <div key={i} className={'carousel-item ' + additionalCssName}>
          <SlideLink slide={slide} imageClassName="d-block w-100" />
          <div
            className="card-footer text-center  theme-styles-overflow-ellipsis theme-home-card-footer"
            title={slide.title}
          >
            {slide.text}
          </div>
        </div>
      );
    });

    return (
      <div className="card">
        <div className="card-header homeCH customise-handle">
          <i className="fas fa-arrows-alt promos-move" />
          <Link to={promo.link}>{promo.name}</Link>
        </div>
        <div
          id={id}
          className="carousel slide customise-handle"
          data-ride="carousel"
          data-interval="false"
        >
          <ol className="carousel-indicators text-center">{indicators}</ol>
          <div className="carousel-inner">{carouselSlides}</div>
          <a  
            className="carousel-control-prev text-white"
            href={'#' + id}
            role="button"
            data-slide="prev"
          >
            <img src="images/left.png" alt="previous" />
            <span className="sr-only">Previous</span>
          </a>
          <a  
            className="carousel-control-next text-white"
            href={'#' + id}
            role="button"
            data-slide="next"
          >
            <img src="images/right.png" alt="next" />
            <span className="sr-only">Next</span>
          </a>
        </div>
      </div>
    );
  }
}
