import React, { Component } from "react";

export default class FourthTimei10 extends Component {
  render() {
    return (
      <div className="container">
        <div className="fadeInDownBigFull">
          <div className="card-body newsPadding">
            <div className="card-deck " id="FourthTimei10">
              <div className="card">
                <div className="row">
                  <div className="col-md">
                    <img
                      id="FourthTimei10-gallery-1"
                      className="card-img-top img-fluid news-gallary-image active"
                      src="images/i10FourthTime.jpg"
                      alt="Card image cap"
                    />
                  </div>
                </div>

                <div className="card-block">
                  <div className="card-content">
                    <div className="col">
                      <div className="row whiteBG">
                        <div className="col-lg-4">
                          <div className="salesText mt-3">
                            <p className="theme-news-snippet">
                              “The Hyundai i10 has taken the Most Popular City
                              Car award for the fourth year in row”
                            </p>
                          </div>
                          <hr />
                          <div
                            className="d-flex justify-content-around mb-2 largerSI social-share-buttons"
                            data-article-id="FourthTimei10"
                            data-article-text="Fourth Time i10"
                          />
                        </div>

                        <div className="col-lg-8 border-lg-left">
                          <div className="row pt-3">
                            <div className="col-md-10 offset-md-1">
                              <p className="salesText" data-search-tag="title">
                                i10 WINS AWARD FOR THE FOURTH TIME RUNNING.
                                HONEST!
                              </p>
                            </div>
                          </div>

                          <div className="row pt-3">
                            <div className="col-md-10 offset-md-1">
                              <div className="theme-news-body-text">
                                <p data-search-tag="summary">
                                  <strong>June 2017 –</strong> The New i10 is no
                                  stranger to the limelight, and has been at it
                                  once again, scooping up the gong for Most
                                  Popular City Car at the 2017 Honest John
                                  Awards ¬– for the fourth consecutive year!{" "}
                                </p>
                                <p>
                                  “The Hyundai i10 has taken the Most Popular
                                  City Car award for the fourth year in row,
                                  which is an amazing achievement given the high
                                  level of the competition,” said
                                  HonestJohn.co.uk’s Managing Editor, Dan
                                  Harrison. “Over the past 12 months, the i10
                                  has generated more interest with our readers
                                  than any other car in its class, with many
                                  attracted to its five-year warranty, low
                                  running costs and refined interior."{" "}
                                </p>
                                <p>
                                  Now in its seventh consecutive year, the
                                  Honest John Awards are won by the cars that
                                  are most searched for by the website’s users.{" "}
                                </p>
                                <p>
                                  “We are delighted the i10 has continued to
                                  impress the HonestJohn.co.uk users,” said Tony
                                  Whitehorn, President and CEO, Hyundai Motor
                                  UK. “Receiving the Most Popular City Car award
                                  for the fourth year running proves that our
                                  customers continue to value the stylish and
                                  refined package that the i10 offers. For 2017,
                                  we introduced New i10, with a fresh new look
                                  and technology that is usually only found in
                                  the classes above.”
                                </p>
                                <p>
                                  The New i10 starts at £9,540. For more visit{" "}
                                  <a
                                    href="http://www.hyundai.co.uk/"
                                    target="_blank"
                                  >
                                    www.hyundai.co.uk
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
