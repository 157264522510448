import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';
import Store from 'store';
import ReactGA from 'react-ga';
export default class Newi10 extends Component {
  componentDidMount = () => {
    ReactGA.event({
      label: 'New i10',
      category: 'Promotion',
      action: `i10 Promo News item opened`,
      gaOptions: {
        userId: Store.get('customer') && Store.get('customer').urn,
      },
    });
  };

  clickHandler = () => {
    ReactGA.event({
      label: 'New i10',
      category: 'Promotion',
      action: `i10 Promo News item Link Click`,
      gaOptions: {
        userId: Store.get('customer') && Store.get('customer').urn,
      },
    });
  };

  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="Newi10" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="iron-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/newi101.jpg") ',
              }}
            />
            <div
              id="iron-gallery-2"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/newi102.jpg")',
              }}
            />
            <div
              id="iron-gallery-3"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/iron3.jpg")',
              }}
            />
            <div
              id="iron-gallery-4"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/iron4.jpg")',
              }}
            />
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#iron-gallery-1"
                    style={{
                      backgroundImage: 'url("images/newi101.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#iron-gallery-2"
                    style={{
                      backgroundImage: 'url("images/newi102.jpg")',
                    }}
                  />
                </div>
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “All New i10 is a big deal”
                </p>
              </div>
              <hr />

              <NewsMedia
                articleId="newi10"
                articleText="All New i10 is a big deal"
              />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    ALL NEW I10 IS A BIG DEAL
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    {' '}
                    The i10 has always been a big deal in the small car market,
                    with its engineers and designers distilling big car thinking
                    into a compact city car built to thrive in town. It’s nippy,
                    nimble, and easy to park. It also oozes cool and is
                    outrageously cheap to run, which is perhaps why, in the
                    past, it has been voted What Car?’s Best City Car four years
                    on the run.
                  </p>
                  {displayShowMore && (
                    <p>
                      <a
                        data-toggle="collapse"
                        href="#newi10"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </div>

              <div className={moreSectionClassName} id="newi10">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      The i10 has always been a big deal in the small car
                      market, with its engineers and designers distilling big
                      car thinking into a compact city car built to thrive in
                      town. It’s nippy, nimble, and easy to park. It also oozes
                      cool and is outrageously cheap to run, which is perhaps
                      why, in the past, it has been voted What Car?’s Best City
                      Car four years on the run.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      The All New i10 - the third generation of Hyundai’s hit
                      city car - has undergone a comprehensive upgrade ahead of
                      its mid-January launch. Essentially a brand new car from
                      the ground up, All New i10 has been repositioned with a
                      focus on technology and connectivity, it has the highest
                      levels of safety equipment within the A segment, and has
                      space for five seats when many of its competitors can only
                      offer four.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      The car has undergone a substantial make over inside and
                      out. While it still has parking-friendly dimensions, its
                      slightly longer wheelbase (40mm), wider body (20 mm), and
                      lowered roof (20 mm) improve curb appeal, as do the
                      newly-designed optional 16-inch alloy wheels.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      Step inside and you’ll see that this city car’s talents
                      aren’t confined to the city. It’s roomy, refined, and full
                      of clever gadgets. The previous entry level S
                      specification is no longer available, which allows
                      customers to enjoy the increased specification of SE trim
                      as the starting point of the range. Time to take a closer
                      look…
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      i10 SE The entry level i10 offers a high level of standard
                      equipment including DAB radio with 3.8” display,
                      Bluetooth, air conditioning, electric windows, leather
                      steering wheel and gear lever, electric mirror adjustment
                      and cruise control. It is packed with standard safety
                      equipment including Lane Keep Assist, Driver Attention
                      Alert, High Beam Assist and Forward Collision Warning
                      System with integrated Autonomous Emergency Braking, Lane
                      Departure Warning System with Lane Keep Assist, and eCall
                      which can automatically dial emergency services in the
                      event of a serious accident. The 1.0 MPi 67PS 5 speed
                      manual transmission is available from £12,495.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      i10 Premium Offering even more equipment, the i10 Premium
                      includes 16” alloy wheels, halogen bi-function headlamps
                      with integrated position light, LED daytime running lamps,
                      front fog lamps and privacy glass. Inside are heated front
                      seats and steering wheel, as well as a honeycomb design to
                      the dashboard, door and gear lever surround trims that is
                      unique to Premium. The optional £1,000 Tech Pack offers a
                      wireless charging pad for compatible handheld devices, an
                      intelligent speed limit warning function that provides the
                      driver with a visual indication of the speed limit, and an
                      8” touchscreen navigation with Bluelink® telematics.
                      Bluelink® notifies of any maintenance issues that need
                      attention as well as allowing owners to lock the vehicle
                      remotely, send navigation instructions via a smartphone,
                      and even locate the car via GPS. The 1.0 MPi 67PS
                      five-speed manual costs from £14,495, while the i10
                      Premium is also available with a 1.2 MPi 84PS four
                      cylinder engine with both a five-speed manual or
                      five-speed automated manual transmission.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      “The All New i10 repositions the model within the A
                      segment from a pure value for money offering to a model
                      comprising safety equipment and trim levels from the
                      premium segments above,” said Ashley Andrew, Managing
                      Director, Hyundai Motor UK. “We are confident that there
                      is a significant number of new car buyers looking for a
                      vehicle with city car manoeuvrability and high equipment
                      levels; a combination that All New i10 is perfectly
                      positioned to offer.”
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      For more on the All New i10, visit{' '}
                      <a
                        href="https://www.hyundai.co.uk/new-cars/i10"
                        onClick={this.clickHandler}
                      >
                        www.hyundai.co.uk/new-cars/i10
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
