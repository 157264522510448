import React, { Component } from "react";

export default class EnviroLeadership extends Component {
  render() {
    return (
      <div className="container">
        <div className="fadeInDownBigFull">
          <div className="card-body newsPadding">
            <div className="card-deck " id="EnviroLeadership">
              <div className="card">
                <div className="row">
                  <div className="col-md">
                    <img
                      id="EnviroLeadership-gallery-1"
                      className="card-img-top img-fluid news-gallary-image active"
                      src="images/envLeadership.jpg"
                      alt="Card image cap"
                    />
                  </div>
                </div>

                <div className="card-block">
                  <div className="card-content">
                    <div className="col">
                      <div className="row whiteBG">
                        <div className="col-lg-4">
                          <div className="salesText mt-3">
                            <p className="theme-news-snippet">
                              “Manufacturer recognised for dedication to
                              eco-friendliness and advances in future mobility”
                            </p>
                          </div>
                          <hr />
                          <div
                            className="d-flex justify-content-around mb-2 largerSI social-share-buttons"
                            data-article-id="EnviroLeadership"
                            data-article-text="Enviro Leadership"
                          />
                        </div>

                        <div className="col-lg-8 border-lg-left">
                          <div className="row pt-3">
                            <div className="col-md-10 offset-md-1">
                              <p className="salesText" data-search-tag="title">
                                HYUNDAI MOTOR RECOGNISED FOR ENVIRONMENTAL
                                LEADERSHIP
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <div className="theme-news-body-text">
                                {" "}
                                <ul data-search-tag="summary">
                                  <li>
                                    Hyundai Motor named greenest manufacturer of
                                    the year
                                  </li>
                                  <li>
                                    Expert panel names IONIQ Electric ‘Best
                                    Plug-in Car (0g/km)’ for fleets
                                  </li>
                                  <li>
                                    Manufacturer recognised for dedication to
                                    eco-friendliness and advances in future
                                    mobility{" "}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div className="row pt-3">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                {" "}
                                <strong>
                                  High Wycombe, March 16, 2017 –
                                </strong>{" "}
                                Hyundai Motor has been named Green Manufacturer
                                of the Year and producer of the Best Plug in Car
                                (0g/km), highlighting the brand and its model
                                range as the stand-out environmentally-friendly
                                option for fleet operators. The prestigious
                                awards from leading business automotive title
                                Fleet News recognises Hyundai Motor’s commitment
                                to eco-friendly practices that enhance the lives
                                of its customers while providing cost-effective
                                motoring options to fleet operators.
                              </p>
                              {/* <p className="theme-news-body-text">The i30 Fastback’s sloping roofline, long bonnet and muscular body, along with its slim cabin and wide wheel arches give the car a strong stance. That strength, however, is far from being merely skin deep.</p> */}
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <div className="theme-news-body-text">
                                {" "}
                                <p>
                                  A panel of expert judges consisting of fleet
                                  managers, leasing companies, pricing guides
                                  and Fleet News praised Hyundai Motor’s
                                  environmental considerations in everything the
                                  manufacturer does. These efforts range from
                                  investing heavily in low-emission technologies
                                  and running greener plants with zero-emission
                                  paint shops, through to using recycled
                                  materials for the IONIQ’s interior. Hyundai
                                  Motor also impressed the expert panel with its
                                  end-of-life vehicle environmental
                                  considerations, such as recycling exterior
                                  plastic for use in less visible parts of new
                                  cars like wheel guards and underbody
                                  coverings.{" "}
                                </p>
                                <p>
                                  Alongside the environmental award, the
                                  acknowledgment of the IONIQ Electric as the
                                  Best Plug-in Car (0g/km) highlights Hyundai
                                  Motor’s environmentally-conscious credentials
                                  and the investment placed in developing
                                  fuel-efficient, eco-friendly cars. Launched in
                                  2016, the IONIQ Electric offers best-in-class
                                  service, maintenance and repair costs, with
                                  the lowest prices at both the 3yr/30,000-mile
                                  and 5yr/50,000-mile points. Boasting the
                                  lowest BIK rates means company car drivers can
                                  access the capable car from £405 per year –
                                  just £33.75 per month (at the current 7%
                                  rate). This, combined with a range of 174
                                  miles and recharging times of just 33 minutes
                                  when charged to 80% battery, ensures that the
                                  IONIQ is set apart from the rest.{" "}
                                </p>
                                <p>
                                  Martin Wilson, Sales Director, Hyundai Motor
                                  UK, said, “We understand how important it is
                                  for fleet operators to manage costs and reduce
                                  their environmental impact, which is why we
                                  are constantly developing new techniques and
                                  technologies. Hyundai Motor is one of the
                                  world’s most forward-looking vehicle
                                  manufacturers and we are committed to develop
                                  technologies that realise the goal of clean
                                  mobility while making the lives of our
                                  customers better.”{" "}
                                </p>
                                <p>
                                  Hyundai Motor plans to bolster its range of
                                  environmentally-friendly vehicles to offer
                                  fleets and consumers a raft of new
                                  fuel-efficient, technologically-advanced
                                  vehicles within the next few years. The
                                  company was the first in the world to
                                  mass-produce a hydrogen fuel cell vehicle with
                                  commercial availability, the ix35 Fuel Cell,
                                  and achieved another world-first introducing
                                  the IONIQ – the first car to be available with
                                  three electrified powertrains.{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
      </div>
    );
  }
}
