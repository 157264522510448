import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';
import Store from 'store';
import ReactGA from 'react-ga';
export default class Bayon extends Component {
  componentDidMount = () => {};

  clickHandler = () => {};

  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="bayon" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="Bayon-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/bayon-01.jpg") ',
              }}
            />
            {/* <div
              id="Bayon-gallery-2"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/hyundaielectric2.jpg")',
              }}
            /> */}
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                {/* <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#Bayon-gallery-2"
                    style={{
                      backgroundImage: 'url("images/hyundaielectric2.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#Bayon-gallery-1"
                    style={{
                      backgroundImage: 'url("images/hyundaielectric1.jpg")',
                    }}
                  />
                </div> */}
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “Hyundai Motor reveals all-new BAYON”
                </p>
              </div>
              <hr />

              <NewsMedia articleId="Bayon" articleText="Bayon" />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    HYUNDAI MOTOR REVEALS ALL-NEW BAYON, A STYLISH AND SLEEK
                    CROSSOVER SUV
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    {' '}
                    <ul>
                      <li>
                        Hyundai unveils the all-new BAYON, a stylish and sleek
                        model in a crossover SUV body type
                      </li>
                      <li>
                        {' '}
                        BAYON features a compact exterior, roomy interior, as
                        well as a range of advanced safety and connectivity
                        features
                      </li>
                      <li>
                        {' '}
                        By launching BAYON, a new B-segment SUV, Hyundai is
                        offering even more choice to customers
                      </li>
                    </ul>
                  </p>
                  {displayShowMore && (
                    <p>
                      <a
                        data-toggle="collapse"
                        href="#Bayon"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </div>

              <div className={moreSectionClassName} id="Bayon">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      2 March 2021 -{' '}
                      <strong>
                        Hyundai Motor revealed Hyundai Bayon today - an all-new
                        crossover SUV designed specifically for Europe. As a
                        B-segment SUV, BAYON will be the latest and smallest
                        member of Hyundai’s expanding SUV family.
                      </strong>
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      The all-new BAYON features a compact exterior, a roomy
                      interior, and a long list of intelligent safety and
                      connectivity features which make it stand out in its
                      segment. With this, the all-new BAYON democratises
                      technology, making high-tech safety and connectivity
                      features accessible for all.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      <img src="images/bayon-02.jpg" className="img-fluid" />
                      <br />
                      <br />
                      In line with Hyundai’s existing SUV naming strategy,
                      BAYON’s name was inspired by a vacation hotspot: Bayonne,
                      the capital of the French Basque Country and one of the
                      most beautiful destinations in the south-west of France.
                      As BAYON is designed to meet the demands of the European
                      market, the company decided to give it a European name.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      <p
                        style={{
                          color: '#ac6b4f',
                          fontSize: '1rem',
                          fontStyle: 'italic',
                        }}
                      >
                        "As the SUV body type continues growing in popularity
                        throughout the world, Hyundai saw a demand for a model
                        capable of navigating European cities while at the same
                        time providing enough space to meet customers’ needs.
                        Class-leading connectivity and safety features, a sharp
                        and distinctive design, and the integration of Hyundai’s
                        signature 48-volt mild hybrid technology make BAYON
                        stand out in its segment."
                      </p>
                    </p>
                    <p className="theme-news-body-text">
                      <span style={{ fontSize: '1rem' }}>
                        <strong>Andreas-Christoph Hofmann</strong>
                      </span>{' '}
                      <br />
                      <span style={{ color: 'dimgray' }}>
                        Vice President of Marketing & Product at Hyundai Motor
                        Europe
                      </span>
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      With BAYON, Hyundai has now launched or enhanced seven new
                      models in just 12 months – or 20, counting all body types
                      and powertrain variations making 2020 a record year for
                      its fleet expansion.
                      <br />
                      <br />
                      <span style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                        {' '}
                        A sharp and distinctive design
                      </span>{' '}
                      <br />
                      <br />
                      BAYON is the latest design statement within the Hyundai
                      SUV family, distinctive through unexpected and
                      eye-catching proportions and strong graphic features.
                      Furthermore, it encompasses the Hyundai design identity
                      Sensuous Sportiness, defined by the harmony between
                      proportion, architecture, styling and technology. This
                      latest interpretation of Sensuous Sportiness combines
                      emotional values with innovative solutions.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      <p
                        style={{
                          color: '#ac6b4f',
                          fontSize: '1rem',
                          fontStyle: 'italic',
                        }}
                      >
                        "With its sharp look and integration of Hyundai’s SUV
                        key design elements, BAYON solidifies Hyundai’s SUV
                        design direction. BAYON’s unique and confident look,
                        embodied in its strong lines and arrow-shaped lights, is
                        expected to establish it as the most unique, outstanding
                        entry in the thriving European B-CUV segment."
                      </p>
                    </p>
                    <p className="theme-news-body-text">
                      <span style={{ fontSize: '1rem' }}>
                        <strong>Luc Donckerwolke</strong>
                      </span>{' '}
                      <br />
                      <span style={{ color: 'dimgray' }}>
                        Chief Creative Officer at Hyundai Motor Group
                      </span>
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      <span style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                        {' '}
                        Exterior design
                      </span>{' '}
                      <br />
                      <br />
                      BAYON is characterised by a distinctive exterior design
                      that separates it clearly from its competition. Unique
                      design solutions, high-tech looking details and a clean
                      look elevate it from the crowd.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      <img src="images/bayon-02.jpg" className="img-fluid" />
                      <br />
                      <br />
                      At the front, a wide grille opens at the bottom, creating
                      a solid stance. Three-part main lights combined with air
                      intakes establish a unique look and architecture. A
                      horizontal air intake band and DRLs establish width, and a
                      lower skid plate confirms its SUV identity.
                      <img src="images/bayon-04.jpg" className="img-fluid" />
                      <br />
                      <br />
                      On the side, a dynamic shoulder provides a wedge-shaped
                      appearance. The arrow-shaped C-pillar provides dynamic and
                      unexpected architecture. This, in combination with fender
                      feature and cladding, defines its unique character.
                      <img src="images/bayon-05.jpg" className="img-fluid" />
                      <br />
                      <br />
                      To the rear, arrow-shaped lights underline the pillar
                      dynamics. Their far-out position creates a wide stance. In
                      addition, a thin horizontal line connecting the taillights
                      further emphasises width and connects the rear and the
                      side in one gesture. Angular lines above the rear arch
                      emphasise volume, creating a vivid section. The strong
                      rear section and visually extended rear window initiate a
                      unique and expressive rear design. Full LED lights and
                      indicators complete its modern look.
                      <img src="images/bayon-07.jpg" className="img-fluid" />
                      <br />
                      <br />
                      BAYON is available with 15-inch steel wheels or 16- or
                      17-inch alloy wheels. A total of nine exterior colours are
                      available, including a new launch colour, Mangrove Green.
                      An optional two-tone roof in Phantom Black is also
                      available with several exterior colours.
                      <br />
                      <br />
                      Exterior colours:
                      <ul>
                        <li>
                          Mangrove Green Pearl [launch colour] with optional
                          Phantom Black roof
                        </li>
                        <li>Phantom Black Pearl</li>
                        <li>Polar White with optional Phantom Black roof</li>
                        <li>
                          Sleek Silver Metallic with optional Phantom Black roof
                        </li>
                        <li>
                          Elemental Brass Metallic with optional Phantom Black
                          roof
                        </li>
                        <li>
                          Dragon Red Pearl with optional Phantom Black roof
                        </li>
                        <li>
                          Aqua Turquoise Metallic with optional Phantom Black
                          roof
                        </li>
                        <li>Aurora Grey Pearl</li>
                        <li>
                          Intense Blue Pearl with optional Phantom Black roof
                        </li>
                      </ul>
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      <span style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                        {' '}
                        A modern, digital interior
                      </span>{' '}
                      <br />
                      <br />
                      <img src="images/bayon-09.jpg" className="img-fluid" />
                      <br />
                      <br />
                      BAYON features a clean, roomy, well-lit interior. There is
                      a strong focus on maximising front and rear passenger
                      comfort and increasing boot space.
                      <img src="images/bayon-12.jpg" className="img-fluid" />
                      <br />
                      <br />
                      The interior is defined by a range of connectivity
                      equipment, including a 10.25-inch digital cluster and a
                      10.25-inch AVN or 8-inch Display Audio. Occupants can
                      benefit from LED ambient lighting technology integrated
                      into the front passenger foot areas, door wells, and front
                      door pull handle areas, as well as the storage area below
                      the centre console.
                      <img src="images/bayon-08.jpg" className="img-fluid" />
                      <br />
                      <br />A carefully-selected neutral range of interior
                      colours and materials provides maximum compatibility with
                      the exterior colour range. A calm colour and trim concept
                      with small and subtle accents creates a serene atmosphere
                      which allows the driver to focus. BAYON comes standard
                      with a Full Black cloth interior, with two optional colour
                      combinations available.
                      <br />
                      <br />
                      Interior colour trims:
                      <ul>
                        <li>Full Black</li>
                        <li>Dark Grey + Light Grey</li>
                      </ul>
                      <span style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                        {' '}
                        Class-leading connectivity and technology
                      </span>{' '}
                      <br />
                      <br />
                      As with other Hyundai models, BAYON offers advanced
                      connectivity technology rarely found in its segment. It
                      offers a best-in-class digital cockpit and top-line
                      infotainment features. Passengers can choose between a
                      10.25-inch AVN screen or an 8-inch Display Audio. The
                      8-inch Display Audio is available with wireless Apple
                      CarPlay and Android Auto, making Hyundai the first car
                      manufacturer to introduce wireless phone mirroring into a
                      car of this size.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      Two USB ports for the front passengers and one for the
                      rear make it possible for up to three devices to charge
                      simultaneously. One of the front ports also allows data
                      transfer, enabling occupants to connect their phone to the
                      vehicle’s infotainment system. A premium Bose sound system
                      completes the package, promising occupants a high-quality
                      listening experience.
                      <br />
                      <br />
                      Available connectivity equipment:
                      <ul>
                        <li>10.25-inch Audio Video Navigation (AVN)</li>
                        <li>8-inch Display Audio</li>
                        <li>10.25-inch digital cluster</li>
                        <li>
                          Apple CarPlay and Android Auto (Offered wirelessly
                          with 8-inch Display Audio)
                        </li>
                        <li>Bose Premium Sound System</li>
                        <li>Wireless charging pad</li>
                        <li>Three USB ports</li>
                      </ul>
                      <span style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                        {' '}
                        Hyundai Bluelink Connected Car services
                      </span>{' '}
                      <br />
                      <br />
                      In addition, BAYON is equipped with the latest Hyundai
                      Bluelink upgrade, meaning users can benefit from
                      state-of-the-art Connected Car Services. The latest
                      Bluelink includes services such as Connected Routing and a
                      new User Profile feature. New to this generation of
                      Bluelink is Calendar Integration. This means the driver
                      can mirror their Google or Apple calendar in the vehicle’s
                      infotainment system. If the calendar appointment also has
                      an address, it can be imported directly into the vehicle’s
                      navigation system. In addition, customers can locate, lock
                      and unlock their car remotely using the Bluelink app, or
                      check vehicle information such as maintenance required or
                      fuel levels.
                      <br />
                      <br />
                      Bluelink and LIVE Services features:
                      <ul>
                        <li>
                          <strong>Connected Routing:</strong> cloud-based
                          navigation system that offers even more precise routes
                          for daily driving
                        </li>
                        <li>
                          <strong>Live Parking Services: </strong> now includes
                          on-street parking price information in addition to
                          availability of nearby parking space
                        </li>
                        <li>
                          <strong>Calendar integration: </strong> drivers can
                          sync their Google or Apple calendar with the car’s
                          infotainment system and navigate directly to
                          appointments
                        </li>
                        <li>
                          <strong>Remote services: </strong> drivers can
                          lock/unlock their car and view vehicle status using
                          the Bluelink app
                        </li>
                        <li>
                          <strong>Online voice recognition: </strong> powerful
                          online (cloud-based) voice recognition that can
                          control vehicle functions such as heating and cooling
                        </li>
                        <li>
                          <strong>Find My Car: </strong> drivers can locate
                          their vehicle easily if they forgot where they parked
                        </li>
                        <li>
                          <strong>Send to car (POI): </strong> search for local
                          points of interest and send results to car’s satellite
                          navigation
                        </li>
                        <li>
                          <strong>Security:</strong> Vehicle alarm notification
                        </li>
                        <li>
                          <strong>Maintenance:</strong> diagnostics and vehicle
                          report (e.g. tyre pressure and airbags), driving
                          information
                        </li>
                      </ul>
                      <span style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                        {' '}
                        Roominess & Convenience
                      </span>{' '}
                      <br />
                      <br />
                      Hyundai BAYON delivers the comfort and storage space of an
                      SUV with the agility and fuel efficiency of a B-segment
                      vehicle. Its compact exterior size and its family-friendly
                      interior mean customers can enjoy the best of both worlds:
                      while its compact size and excellent visibility make it
                      easy to enter and easy to handle, it also offers that safe
                      and robust feeling customers have come to expect from
                      Hyundai SUVs thanks to its high seating position
                      <br />
                      <br />
                      With 411 litres of boot space, BAYON stands out for its
                      large amount of storage space, despite its compact size.
                      As such, BAYON stands out for its roominess and usability.
                      An intelligent trunk cover which can be slid along the
                      rear of the back seat to cover the cargo brings an added
                      benefit. At 4,180mm long, 1,775mm wide, and 1,490mm tall
                      (or 1,500mm with 17-inch wheels), its dimensions strike a
                      perfect balance between compactness and convenience. It
                      features a wheel base of 2,580mm. Excellent legroom will
                      also ensure passengers experience a comfortable ride,
                      whether they’re sitting in the front seats or in the rear:
                      front passengers can enjoy 1,072mm of leg room, while rear
                      passengers have 882mm.
                      <br />
                      <br />
                      <img src="images/bayon-11.jpg" className="img-fluid" />
                      <br />
                      <br />
                      As a true SUV, BAYON offers a ground clearance of up to
                      183 mm (in combination with 17’’ wheels), higher than most
                      other vehicles in the B-segment.
                      <br />
                      <br />
                      <span style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                        {' '}
                        A best-in-class safety package
                      </span>{' '}
                      <br />
                      <br />
                      BAYON shares its safe and robust DNA with the other
                      members of the Hyundai SUV family, and it has the safety
                      features to match. Another way it stands out in its
                      segment, BAYON offers an extensive list of Hyundai
                      SmartSense safety features. Many of them are included in
                      the standard equipment already.
                      <br />
                      <br />A number of semi-autonomous driving features set
                      BAYON apart from its competitors. Lane Following Assist
                      (LFA) works to keep the vehicle centred in its lane.
                      Forward Collision-Avoidance Assist (FCA) first sounds an
                      alarm, then, if necessary, applies the brakes to prevent a
                      collision from occurring. This feature includes car,
                      pedestrian and cyclist detection, as well as Junction
                      Turning, which can apply the brakes to prevent a collision
                      with an oncoming car when turning left at an intersection.
                      <br />
                      <br />
                      BAYON also includes several features that gently redirect
                      the driver’s attention when their focus slips. Driver
                      Attention Warning (DAW) analyses driving patterns to help
                      detect drowsy or distracted driving. This system works in
                      tandem with Leading Vehicle Departure Alert (LVDA), which
                      alerts the driver when the vehicle ahead of them starts
                      moving forward and they do not react quickly enough. In
                      addition, Rear Occupant Alert (ROA) notifies the driver
                      before leaving the vehicle if sensors detect movement in
                      the back seat. This makes BAYON even safer for rear
                      passengers, including children or pets, making it a great
                      choice for families.
                      <br />
                      <br />
                      In case of an accident, eCall will automatically alert
                      emergency services if the vehicle’s airbags are deployed.
                      Alternatively, occupants can also activate this feature
                      with the press of a button.
                      <br />
                      <br />
                      Available SmartSense safety features:
                      <ul>
                        <li>Lane Keeping Assist (LKA)</li>
                        <li>Lane Following Assist (LFA)</li>
                        <li>Intelligent Speed Limit Assist (ISLA)</li>
                        <li>High Beam Assist (HBA)</li>
                        <li>
                          Forward Collision-Avoidance Assist (FCA) with
                          pedestrian and cyclist detection and Junction Turning
                        </li>
                        <li>Driver Attention Warning (DAW)</li>
                        <li>Leading Vehicle Departure Alert (LVDA)</li>
                        <li>Rear Occupant Alert (ROA)</li>
                        <li>Blind-Spot Collision Warning (BCW)</li>
                        <li>
                          Rear Cross-Traffic Collision-Avoidance Warning (RCCW)
                        </li>
                      </ul>
                      <video width="500px" height="300px" controls>
                        <source
                          src="images/bayon-highlights.mp4"
                          type="video/mp4"
                        />
                      </video>
                      <br />
                      <br />
                      <span style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                        {' '}
                        Powertrains
                      </span>{' '}
                      <br />
                      <br />
                      BAYON boasts excellent fuel efficiency and highly
                      competitive CO2 emissions ranking thanks to an updated
                      Kappa engine family. The T-GDi is a downsized
                      turbo-charged engine with direct injection for optimum
                      efficiency. When combined with Hyundai’s unique 48-volt
                      mild hybrid technology (48V) with intelligent Manual
                      Transmission (iMT), this results in even more enhanced
                      fuel savings and efficiency.
                      <br />
                      <br />
                      iMT disengages the engine from the transmission when the
                      driver releases the accelerator. This allows the vehicle
                      to start coasting, reducing emissions and saving fuel.
                      There are two different coasting levels associated with
                      iMT: first, when the engine is idle, and second, when the
                      engine is turned completely off. The engine is restarted
                      again as soon as the driver presses either the brake or
                      the accelerator pedal, and starts running again in the
                      same gear as when it was switched off. This results in
                      better fuel efficiency with no compromises on performance.
                      <br />
                      <br />
                      BAYON also features Hyundai’s unique Continuously Variable
                      Valve Duration (CVVD) technology, which regulates the
                      duration of the valve opening and closing according to
                      driving conditions. This optimises engine performance
                      while also improving fuel efficiency, resulting in reduced
                      emissions and saving fuel.
                      <br />
                      <br />
                      At the top of the line-up is a 1.0-litre T-GDi engine with
                      48V and 120 or 100 PS. This can be coupled with the
                      6-speed intelligent Manual Transmission (6iMT) or a
                      7-speed dual clutch transmission (7DCT).
                      <br />
                      <br />
                      The 1.0-litre T-GDi engine with 48V also comes with three
                      drive modes to optimise the engine response and steering
                      performance based on driving conditions. These are Eco,
                      Normal and Sport.
                      <br />
                      <br />
                      BAYON is the first Hyundai SUV to come equipped with Rev
                      Matching, a feature usually reserved for Hyundai’s
                      high-performance models. Rev matching synchronises the
                      engine to the output shaft, allowing for smoother or
                      sportier downshifts. It will be available for the
                      1.0-litre T-GDi engine with 48V when paired with the 6iMT
                      in Sport mode.
                    </p>
                    <img src="images/bayon-01.jpg" className="img-fluid" />
                    <br />
                    <br />
                    <img src="images/bayon-06.jpg" className="img-fluid" />
                    <br />
                    <br />
                    <img src="images/bayon-10.jpg" className="img-fluid" />
                    <br />
                    <br />
                    <img src="images/bayon-13.jpg" className="img-fluid" />
                    <br />
                    <br />
                    <img src="images/bayon-15.jpg" className="img-fluid" />
                    <br />
                    <br />
                    <img src="images/bayon-14.jpg" className="img-fluid" />
                    <br />
                    <br />
                    <img src="images/bayon-03.jpg" className="img-fluid" />
                    <br />
                    <br />
                    <img src="images/bayon-06.jpg" className="img-fluid" />
                    <br />
                    <br />
                    <img src="images/bayon-10.jpg" className="img-fluid" />
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
