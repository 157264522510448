import React, { Component } from 'react';

export default class TestDrive extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: props.title,
      requestedDate: props.requestedDate,
      dealer: props.dealer,
    };
  }

  render() {
    const { title, requestedDate, dealer } = this.props;

    return (
      <div className="col-6 col-sm-4 theme-main-request-history-card mb-5">
        <img
          className="img-fluid flex-shrink-0  theme-main-request-history-card-image mx-auto d-block mb-lg-5 mb-3"
          src={`images/offervehicles/${title
            .toLowerCase()
            .replace(/\s/g, '')}.jpg`}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = 'images/shadow-vehicle.jpg';
          }}
          alt="Car"
        />
        <div className=" ">
          <h5 className=" text-info">
            <small>
              <strong>{title}</strong>
            </small>
          </h5>
          <p className="small">
            <strong>Requested date:</strong>
            <br />
            {requestedDate}
          </p>
          <p className="small">
            <strong>Dealer:</strong>
            <br />
            {dealer}
          </p>
        </div>
      </div>
    );
  }
}
