import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';

export default class AutoExpressAwards extends Component {
  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="AutoExpressAwards" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="autoExpress-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/autoExpress1.jpg") ',
              }}
            />
            <div
              id="autoExpress-gallery-2"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/autoExpress2.jpg")',
              }}
            />
          </div>
        </div>

        <div className="col  bg-white border-right border-bottom">
          <div className="row py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a  
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#autoExpress-gallery-1"
                    style={{
                      backgroundImage: 'url("images/autoExpress1_th.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a  
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#autoExpress-gallery-2"
                    style={{
                      backgroundImage: 'url("images/autoExpress2_th.jpg")',
                    }}
                  />
                </div>
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “Launching an all-electric SUV means Hyundai has tapped into
                  two of the fastest-growing parts of the market”
                </p>
              </div>
              <hr />

              <NewsMedia
                articleId="AutoExpressAwards"
                articleText="Auto Express Awards"
              />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    ELECTRIC PERFORMANCE LIGHTS UP AWARDS FOR HYUNDAI
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    {' '}
                    Hyundai’s electric vehicles have zapped the competition with
                    a double victory at the prestigious Auto Express New Car
                    Awards 2018.
                  </p>
                  {displayShowMore && (
                    <p>
                      <a  
                        data-toggle="collapse"
                        href="#collapseautoExpress"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                  {/* <p className="theme-news-body-text">The i30 Fastback’s sloping roofline, long bonnet and muscular body, along with its slim cabin and wide wheel arches give the car a strong stance. That strength, however, is far from being merely skin deep.</p> */}
                </div>
              </div>

              <div className={moreSectionClassName} id="collapseautoExpress">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      While the England football team were making history in
                      Russia by winning their first ever World Cup penalty shoot
                      out Hyundai were making headlines back home by winning
                      Affordable Electric Car of the Year for the new KONA
                      Electric and Affordable Hybrid Car of the Year for the
                      IONIQ Plug-In Hybrid.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      The All-New Hyundai Kona Electric, which was commended by
                      the judges for its 300-mile range and affordable price,
                      was chosen ahead of models such as the Nissan Leaf and
                      Volkswagen e-Golf.{' '}
                    </p>
                  </div>
                </div>

                {/* <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {" "}
                      The SUV will feature the 2.2 CRDi “R” engine,
                      which has been upgraded to further reduce
                      emissions, and will be available with front
                      wheel drive or 4WD, with a 6-speed manual
                      gearbox or an all-new 8-speed automatic
                                  transmission.{" "}
                    </p>
                  </div>
                </div> */}

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      “Launching an all-electric SUV means Hyundai has tapped
                      into two of the fastest-growing parts of the market,” said
                      Steve Fowler, Editor-in-Chief of Auto Express. “The Kona
                      Electric has a 300-mile range, so it can go further on a
                      single charge than some of its closest rivals. Add an
                      affordable price tag and it shows that genuinely
                      mainstream electric cars are now viable. The Kona Electric
                      is an outstanding package, which is why we’ve named it
                      Affordable Electric Car of the Year 2018.”{' '}
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      The IONIQ Plug-In Hybrid, which came out on top against
                      the likes of the Toyota Prius in the affordable hybrid
                      category, impressed the judges with its efficiency,
                      comfort and technology.{' '}
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      “Our time in the real world with the Hyundai IONIQ PHEV
                      revealed it to be a very efficient car indeed, averaging
                      more than 80mpg almost effortlessly over 4,000 miles,”
                      said Steve. “CO2 emissions of 26g/km bolster the IONIQ
                      PHEV’s green credentials, and yet it’s still a spacious,
                      comfortable, well-equipped hatchback – and a deserved
                      winner of our Affordable Hybrid Car of the Year 2018
                      award.”{' '}
                    </p>
                    <p>
                      But Hyundai’s success wasn’t limited to its electric
                      vehicles. Hyundai models were shortlisted in six of the
                      New Car categories, with the much-lauded i30N scooping a
                      Highly Commended mention in the Hot Hatch category.{' '}
                    </p>

                    <p>
                      “To win two significant titles from Auto Express for Kona
                      Electric – a car which will be available to order soon in
                      the UK – and the IONIQ Plug-In Hybrid is a real testament
                      to our commitment to reducing the environmental impact of
                      our vehicles,” said Tony Whitehorn, President and CEO of
                      Hyundai Motor UK, after the awards ceremony. “As a
                      business, we are investing significantly in green
                      technologies and the IONIQ, KONA Electric and the
                      forthcoming fuel cell, NEXO are all fruits of the
                      investment which will see us launch 18 new electrified
                      models before 2025!”{' '}
                    </p>

                    <p>
                      The Hyundai IONIQ Plug-In Hybrid costs from £25,345* and
                      the Kona Electric is priced from £29,495 (excluding a
                      Plug-In Car Grant). For more information, please
                      visit:&nbsp;
                      <a href="https://www.hyundai.co.uk" target="_blank" rel="noopener noreferrer">
                        www.hyundai.co.uk
                      </a>{' '}
                    </p>

                    <p>*Price including current customer saving </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
