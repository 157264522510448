import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';
import Store from 'store';
import ReactGA from 'react-ga';
export default class Ioniq5 extends Component {
  componentDidMount = () => {
    ReactGA.event({
      label: 'New i20',
      category: 'Promotion',
      action: `i20 Promo News item opened`,
      gaOptions: {
        userId: Store.get('customer') && Store.get('customer').urn,
      },
    });
  };

  clickHandler = () => {
    ReactGA.event({
      label: 'New i20',
      category: 'Promotion',
      action: `i20 Promo News item Link Click`,
      gaOptions: {
        userId: Store.get('customer') && Store.get('customer').urn,
      },
    });
  };

  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="Ioniq5" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="Ioniq5-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/ioniq51.jpg") ',
              }}
            />
            <div
              id="Ioniq5-gallery-2"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/konaN2.jpg")',
              }}
            />
            <div
              id="Ioniq5-gallery-3"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/konaN3.jpg")',
              }}
            />
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                {/* <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#Ioniq5-gallery-2"
                    style={{
                      backgroundImage: 'url("images/konaN2.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#Ioniq5-gallery-1"
                    style={{
                      backgroundImage: 'url("images/konaN1.jpg")',
                    }}
                  />
                </div> */}
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “High demand for IONIQ 5 in Europe”
                </p>
              </div>
              <hr />

              <NewsMedia
                articleId="newi20"
                articleText="All New i20 is a big deal"
              />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    HIGH DEMAND FOR IONIQ 5 IN EUROPE
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    {' '}
                    <ul>
                      <li>
                        Unveiling of Hyundai IONIQ 5 generated 236,000 enquiries
                        from European customers so far
                      </li>
                      <li>
                        {' '}
                        3,000 units of limited edition IONIQ 5 Project 45 almost
                        three times over-subscribed within 24 hours
                      </li>
                      <li>
                        {' '}
                        Deliveries of first customer cars in Europe will begin
                        in the second quarter of 2021
                      </li>
                    </ul>
                  </p>
                  {displayShowMore && (
                    <p>
                      <a
                        data-toggle="collapse"
                        href="#ioniq5"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </div>

              <div className={moreSectionClassName} id="ioniq5">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      1 March 2021 -{' '}
                      <strong>
                        The world premiere of the Hyundai IONIQ 5 was followed
                        by the highest number of European customer enquiries of
                        any new car launch for the brand.
                      </strong>
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      Since the unveiling on 23 February, the company had
                      recorded 236,000 expressions of interest, while the 3,000
                      units of the limited edition IONIQ 5 Project 45 were
                      almost three times over-subscribed within 24 hours of
                      reservations opening.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      Debuted via a virtual world premiere, the IONIQ 5 quickly
                      generated a very high level of website traffic and social
                      media engagement from consumers in Europe – more than ever
                      previously recorded by Hyundai for such an event,
                      including the launch of the very popular all-new Tucson
                      SUV in 2020.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      IONIQ 5 is the first model built on Hyundai Motor Group’s
                      new dedicated architecture for battery electric vehicles,
                      called Electric Global Modular Platform (E-GMP). It is
                      also the first model in the new IONIQ product line-up
                      brand, redefining the electric mobility lifestyle with
                      sustainable and innovative features – from the
                      eco-friendly materials of its interior design, to
                      ultra-fast charging and Vehicle-to-Load function.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      The limited edition model will be the first IONIQ 5
                      produced and delivered to customers in Europe. It has a
                      very high specification and includes many of the most
                      innovative and eye-catching features of IONIQ 5, such as
                      the solar roof and the 20-inch alloy wheels.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      <p
                        style={{
                          color: '#ac6b4f',
                          fontSize: '1rem',
                          fontStyle: 'italic',
                        }}
                      >
                        "The exceptionally high interest in IONIQ 5 underlines
                        the strength of Hyundai in zero-emission mobility. With
                        its ultra-fast charging, long range and customisable
                        interior space, the IONIQ 5 is a game-changer that sets
                        the benchmark in its class – and these outstanding
                        characteristics have immediately proved to be attractive
                        to significant numbers of European customers."
                      </p>
                    </p>
                    <p className="theme-news-body-text">
                      <span style={{ fontSize: '1rem' }}>
                        <strong>Andreas-Christoph Hofmann</strong>
                      </span>{' '}
                      <br />
                      <span style={{ color: 'dimgray' }}>
                        Vice President Marketing & Product at Hyundai Motor
                        Europe
                      </span>
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      More information about the all-new IONIQ 5 can be found in
                      the{' '}
                      <a
                        href="https://www.hyundai.news/uk/model-news/hyundai-ioniq-5-redefines-electric-mobility-lifestyle/"
                        target="_blank"
                      >
                        press kit.
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
