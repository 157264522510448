import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';
import Store from 'store';
import ReactGA from 'react-ga';
export default class BestCarAwards extends Component {
  componentDidMount = () => {};

  clickHandler = () => {};

  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="bestCarAwards-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/bcaheader2.jpg") ',
              }}
            />
            <div
              id="bestCarAwards-gallery-2"
              className="theme-news-header-image "
              style={{
                backgroundImage: 'url("images/bca1.jpg") ',
              }}
            />
            <div
              id="bestCarAwards-gallery-3"
              className="theme-news-header-image "
              style={{
                backgroundImage: 'url("images/bca2.jpg") ',
              }}
            />
            <div
              id="bestCarAwards-gallery-4"
              className="theme-news-header-image "
              style={{
                backgroundImage: 'url("images/bca3.jpg") ',
              }}
            />
            <div
              id="bestCarAwards-gallery-5"
              className="theme-news-header-image "
              style={{
                backgroundImage: 'url("images/bca4.jpg") ',
              }}
            />
            <div
              id="bestCarAwards-gallery-6"
              className="theme-news-header-image "
              style={{
                backgroundImage: 'url("images/bca5.jpg") ',
              }}
            />
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#bestCarAwards-gallery-2"
                    style={{
                      backgroundImage: 'url("images/bca1.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#bestCarAwards-gallery-3"
                    style={{
                      backgroundImage: 'url("images/bca2.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#bestCarAwards-gallery-4"
                    style={{
                      backgroundImage: 'url("images/bca3.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#bestCarAwards-gallery-5"
                    style={{
                      backgroundImage: 'url("images/bca4.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#bestCarAwards-gallery-6"
                    style={{
                      backgroundImage: 'url("images/bca5.jpg") ',
                    }}
                  />
                </div>
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “Hyundai scoops six Carbuyer Best Car Awards as TUCSON named
                  ‘Car of the Year’ ”
                </p>
              </div>
              <hr />

              <NewsMedia articleId="awards" articleText="awards" />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    HYUNDAI SCOOPS SIX CARBUYER BEST CAR AWARDS AS TUCSON NAMED
                    'CAR OF THE YEAR'
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    {' '}
                    <ul>
                      <li>
                        Hyundai scoops six Carbuyer Best Car Awards as TUCSON
                        named ‘Car of the Year’
                      </li>
                      <li>
                        TUCSON also receives ‘Best Family Car’ and ‘Best Hybrid’
                        accolades
                      </li>
                      <li>
                        Hyundai IONIQ 5 triumphs as ‘Best Company Car’ and ‘Best
                        Family Electric Car’
                      </li>
                      <li>
                        Hyundai i20 N acclaimed as ‘Best Hot Hatchback’ for 2022
                      </li>
                    </ul>
                  </p>
                  {displayShowMore && (
                    <p>
                      <a
                        data-toggle="collapse"
                        href="#bestCarAwards"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </div>

              <div className={moreSectionClassName} id="bestCarAwards">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      6 October 2021 -{' '}
                      <strong>
                        Hyundai Motor UK has dominated the 2022 Carbuyer Best
                        Car Awards with six accolades in total, including the
                        prestigious ‘Car of the Year’ award for the all-new
                        Hyundai TUCSON SUV.
                      </strong>
                      <br />
                      <br />
                      The TUCSON was also named ‘Best Family Car’ and ‘Best
                      Hybrid Car’. In addition, the Hyundai IONIQ 5 was hailed
                      as the ‘Best Company Car’ and ‘Best Family Electric Car’,
                      whilst the Hyundai i20 N was honoured as the ‘Best Hot
                      Hatchback.’
                      <br />
                      <br />
                      All models were judged against market rivals on cost,
                      performance, comfort, safety and practicality, with
                      winners selected by Carbuyer’s team of experts after
                      rigorous testing.
                    </p>
                  </div>
                </div>

                {/* <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      Early in January, the{' '}
                      <a
                        href="https://www.hyundai.news/uk/brand/hyundai-i10-is-what-cars-best-small-car-for-the-city-2021/"
                        target="_blank"
                      >
                        all-new i10 was named What Car?’s Best Small Car for the
                        City,
                      </a>{' '}
                      then the previous generation{' '}
                      <a
                        href="https://www.hyundai.news/uk/model-news/hyundai-scores-double-win-at-what-car-used-car-awards/"
                        target="_blank"
                      >
                        i20 was named as First Car’s Used Car of the Year
                      </a>{' '}
                      as a ‘safe, reliable and easy to drive’ car for new
                      drivers. Continuing Hyundai’s small car wins, carwow named
                      the i10 as its Best Small Car, saying: “it’s a doddle to
                      drive but still roomy enough for four adults and their
                      stuff. It’s big on technology, too.”
                    </p>
                  </div>
                </div> */}

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      <img src="images/bca1.jpg" className="img-fluid" />
                      <br />
                      <br />
                      <span style={{ fontSize: '1rem' }}>
                        <strong>Richard Ingram, Editor, Carbuyer said</strong>
                      </span>{' '}
                      “A multiple award winner, the Hyundai TUCSON is both
                      Carbuyer’s Best Family Car as well as our Best Hybrid Car
                      for 2022. Lovely to drive, practical and easy to live
                      with, it’s also cheap to run thanks to its hybrid
                      powertrains. In fact, the new Tucson is quite simply the
                      best new car we’ve driven all year – making it the natural
                      winner of Carbuyer’s overall Car of the Year.”
                      <br />
                      <br />
                      Priced from £28,710, the ground-breaking Hyundai TUCSON
                      SUV is offered with a choice of three powertrains, Hybrid,
                      Plug-in Hybrid and Petrol with Mild Hybrid. The TUCSON’S
                      styling is sophisticated yet rugged, performance is
                      consummately blended with efficiency, and the engaging
                      driving experience is backed up by best-in-class safety.
                      Every TUCSON is equipped with advanced safety features
                      such as Forward Collision Avoidance Assist (FCA) Car,
                      Pedestrian and Cycle and Multi Collision Braking (MCA) as
                      standard.
                      <br />
                      <br />
                      <img src="images/bca2.jpg" className="img-fluid" />
                      <br />
                      <br />
                      Other Hyundai models impressed the judging panel, with
                      Richard Ingram lauding IONIQ 5 as an electric trailblazer:
                      <br />
                      <br />
                      “We’ve seen the electric car game move on significantly
                      over the past 12 months, and the IONIQ 5 is proof of just
                      how far the market has come. Futuristic not only in the
                      way it looks, its long range and ultra-rapid charging put
                      it at the cutting-edge, while its spacious cabin and
                      generous kit list make it a brilliant car to live with.”
                      <br />
                      <br />
                      <img src="images/bca3.jpg" className="img-fluid" />
                      <br />
                      <br />
                      The futuristic-looking Hyundai IONIQ 5 is based upon
                      Hyundai’s breakthrough Electric Global Modular Platform
                      (E-GMP), which delivers faster charging, increased driving
                      range, superior handling and more interior space. In
                      addition to revolutionising sustainable mobility, the
                      IONIQ 5 offers an interior that provides a whole new
                      in-car experience – redefining living and moving space.
                      Environmentally friendly materials, such as eco-processed
                      leather and recycled yarn, are used extensively in the
                      IONIQ 5.
                      <br />
                      <br />
                      <img src="images/bca4.jpg" className="img-fluid" />
                      <br />
                      <br />
                      Offered with a range of electric powertrains and two- and
                      four-wheel drive configurations, the IONIQ 5 is able to
                      charge from 10%-80% in just 18 minutes, accelerate from
                      0-62mph in as little as 5.2 seconds and travel up to 298
                      miles on a single charge.
                      <br />
                      <br />
                      Showcasing the strength of the full model range, a sixth
                      award was presented to Hyundai as the i20 N won ‘Best Hot
                      Hatchback’ of the year.
                      <br />
                      <br />
                      <strong>Richard Ingram said:</strong> “Hyundai has turned
                      the hot hatch market on its head – first with the i30 N
                      and more recently with the smaller but just as riotous i20
                      N. Frenetic, agile and quick, the compact Hyundai will
                      keep up with most sports cars but with the added benefit
                      of five doors and a big boot.”
                      <br />
                      <br />
                      <img src="images/bca5.jpg" className="img-fluid" />
                      <br />
                      <br />
                      Like every driver-focused Hyundai N model, the i20N was
                      born at Namyang, Hyundai Motorsport’s Global Research &
                      Development Centre in South Korea, and honed on the
                      Nürburgring Nordschleife race circuit in Germany.
                      Featuring a potent 204PS turbocharged petrol engine and a
                      mechanical limited-slip differential, the i20N delivers
                      stunning performance and ultra-agile handling on road and
                      track. Pioneering connected and safety technologies
                      enhance every journey. Like every Hyundai model sold in
                      the UK, the i20N provides a five-year unlimited mileage
                      warranty, five annual vehicle health checks and roadside
                      assistance. Pricing begins at £24,995.
                      <br />
                      <br />
                      <strong>
                        Ashley Andrew, Managing Director of Hyundai Motor UK
                        said:
                      </strong>
                      “We are delighted that the Hyundai TUCSON has been
                      presented with the coveted ‘Car of the Year’ award by the
                      panel of experts at Carbuyer. This endorsement is further
                      validation that the latest generation TUCSON remains one
                      of the best SUVs in the UK market, as well as the best
                      hybrid available. We are also thrilled to see the wider
                      Hyundai range recognised across a number of prestigious
                      honours. IONIQ 5 continues to set the standard for
                      electric mobility, whilst the i20 N is recognised as the
                      best hot hatchback yet again.”
                      <br />
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
