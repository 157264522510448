import React, { Component } from 'react';
import ReactGA from 'react-ga';

export default class Tracker extends Component {

    constructor(props) {
        super();

        ReactGA.initialize(props.gaTrackingCode);

    }

    componentDidMount() {
        this.logGoogleAnalytics();
    }

    componentDidUpdate() {
        this.logGoogleAnalytics();
    }

    logGoogleAnalytics() {
        ReactGA.pageview(window.location.pathname);
    }

    render() {
        return this.props.children;
    }
}