import React, { Component } from "react";

export default class KonaElectric extends Component {
  render() {
    return (
      <div className="container">
        <div className="fadeInDownBigFull">
          <div className="card-body newsPadding">
            <div className="card-deck " id="KonaElectric">
              <div className="card">
                <div className="row">
                  <div className="col-md">
                    <img
                      id="KonaElectric-gallery-1"
                      className="card-img-top img-fluid news-gallary-image active"
                      src="images/konaElectric.jpg"
                      alt="Card image cap"
                    />
                  </div>
                </div>

                <div className="card-block">
                  <div className="card-content">
                    <div className="col">
                      <div className="row whiteBG">
                        <div className="col-lg-4">
                          <div className="salesText mt-3">
                            <p className="theme-news-snippet">
                              “Hyundai Motor merges the two hottest trends in
                              the automotive market”
                            </p>
                          </div>
                          <hr />
                        </div>

                        <div className="col-lg-8 border-lg-left">
                          <div className="row pt-3">
                            <div className="col-md-10 offset-md-1">
                              <p className="salesText" data-search-tag="title">
                                A CAR OF NO COMPROMISE: ALL-NEW HYUNDAI KONA
                                ELECTRIC
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <div className="theme-news-body-text">
                                {" "}
                                <ul>
                                  <li>
                                    With the launch of the All-New Kona
                                    Electric, Hyundai Motor is offering the
                                    first fully-electric compact SUV
                                  </li>
                                  <li>
                                    New model combines the two fastest growing
                                    automotive trends: SUV and electrification
                                  </li>
                                  <li>
                                    Extending Hyundai’s eco car line up with
                                    class-leading range and two powertrain
                                    versions
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div className="row pt-3">
                            <div className="col-md-10 offset-md-1">
                              <div className="theme-news-body-text">
                                {" "}
                                <p data-search-tag="summary">
                                  <strong>
                                    High Wycombe, 6 February, 2018 -{" "}
                                  </strong>{" "}
                                  Hyundai Motor has released a first teaser
                                  image of its first fully-electric SUV ahead of
                                  the official unveiling, on February 27. The
                                  All-New Kona Electric reinforces the company’s
                                  leadership in the eco-car segment. Hyundai
                                  Motor will be the first automotive brand
                                  across Europe to make an all-electric compact
                                  SUV available to everyone, coming to the
                                  market in summer 2018.
                                </p>
                                <p>Efficiency meets fun-to-drive</p>
                                <p>
                                  The All-New Kona Electric will feature two
                                  different powertrain versions offering
                                  customers one of the most powerful electric
                                  engines on the market with a class leading
                                  range of almost 470 kilometres (internal
                                  target under WLTP regulations) and a great
                                  fun-to-drive character. Furthermore, drivers
                                  will benefit from a wide range of convenience
                                  and connectivity features as well as active
                                  safety and driving assistance technologies.
                                </p>
                                <p>
                                  Hyundai Motor merges the two hottest trends in
                                  the automotive market
                                </p>
                                <p>
                                  The All-New Kona Electric enables customers to
                                  combine the two worlds of eco-mobility and SUV
                                  style. In bringing this car to the market,
                                  Hyundai Motor is the first carmaker in Europe
                                  to merge these two hottest industry trends.
                                </p>
                                <p>
                                  Hyundai Motor is at the forefront of eco
                                  mobility, already offering the broadest range
                                  of powertrains. Hyundai marketed the first
                                  mass-produced fuel cell vehicle, the ix35, and
                                  recently introduced its successor, the All-New
                                  Nexo. Furthermore, the successful IONIQ is the
                                  only car available with three different
                                  electrified powertrains in one body type. The
                                  IONIQ Electric has just been ranked first in
                                  the prestigious ADAC EcoTest 2017.
                                </p>
                                <p>
                                  Further details will be disclosed on 27
                                  February, ahead of the car’s world premiere at
                                  the 2018 Geneva Motor Show.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
