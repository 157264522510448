import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';
import Store from 'store';
import ReactGA from 'react-ga';
export default class ArcticEnvironment extends Component {
  componentDidMount = () => {};

  clickHandler = () => {};

  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="arcticEnvironments" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="oneMillion-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/arcticMain.jpg") ',
              }}
            />
            {/* <div
              id="Bayon-gallery-2"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/hyundaielectric2.jpg")',
              }}
            /> */}
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                {/* <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#Bayon-gallery-2"
                    style={{
                      backgroundImage: 'url("images/hyundaielectric2.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#Bayon-gallery-1"
                    style={{
                      backgroundImage: 'url("images/hyundaielectric1.jpg")',
                    }}
                  />
                </div> */}
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “-30° Celsius North! Hyundai’s IONIQ 5 N High-performance EV
                  Prototype Conquers Extreme Arctic Environment”
                </p>
              </div>
              <hr />

              <NewsMedia articleId="awards" articleText="awards" />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    -30° CELCIUS NORTH! HYUNDAI'S IONIQ 5 N HIGH-PERFORMANCE EV
                    PROTOTYPE CONQUERS EXTREME ARCTIC ENVIRONMENT
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    {' '}
                    <ul>
                      <li>
                        Hyundai N is testing the all-electric, all-wheel-drive
                        IONIQ 5 N under extreme arctic winter conditions in
                        Arjeplog, Sweden
                      </li>
                      <li>
                        IONIQ 5 N is planned to debut in July 2023 as N brand’s
                        first high-performance mass-production EV, further
                        accelerating Hyundai’s electrification future
                      </li>
                      <li>
                        N brand releases the first teaser of IONIQ 5 N signaling
                        a shift in brand evolution with a new lineup of high
                        performance electric vehicles.
                      </li>
                      {/* <li>
                        Hyundai is investing £1 million in a programme of school
                        trips in order to prevent children missing out on such
                        experiences
                      </li>
                      <li>
                        Bookings are now open for the initiative, which has
                        launched with Hyundai’s Educator in Residence, Tim
                        Campbell MBE
                      </li> */}
                    </ul>
                  </p>
                  {displayShowMore && (
                    <p>
                      <a
                        data-toggle="collapse"
                        href="#arcticEnvironment"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </div>

              <div className={moreSectionClassName} id="arcticEnvironment">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      <strong>
                        Hyundai Motor Company revealed details about the extreme
                        winter testing of IONIQ 5 N, the first high-performance,
                        mass-production all-electric N brand model at the
                        Hyundai Mobis Proving ground in Arjeplog, Sweden.
                      </strong>
                      <br />
                      <br />
                      Given its position adjacent to the Arctic Circle, the
                      Hyundai Mobis Proving ground in Arjeplog offers everything
                      an automaker needs for comprehensive winter testing, such
                      as low-grip coefficient icy surfaces and deep sub-zero
                      temperatures as low as -30°C (-22°F). This allows Hyundai
                      N engineers to test new models, such as the IONIQ 5 N, in
                      the most extreme low friction conditions to achieve the
                      optimal balance between the car’s responsive
                      ‘fun-to-drive’ character and its ability to perform safely
                      and predictably in sub-zero conditions.
                    </p>
                    <img src="images/arctic1.jpg" className="img-fluid" />
                    <br />
                    <br />
                    <img src="images/arctic2.jpg" className="img-fluid" />
                    <br />
                    <br />
                    <img src="images/arctic3.png" className="img-fluid" />
                    <br />
                    <br />
                    <img src="images/arctic4.jpg" className="img-fluid" />
                    <br />
                    <br />
                    <img src="images/arctic5.jpg" className="img-fluid" />
                    <br />
                    <br />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      For IONIQ 5 N, Hyundai Motor has combined the EV’s
                      Electrified-Global Modular Platform (E-GMP) with N’s
                      motorsport-bred technologies and expertise to raise the
                      bar for electrified high performance and set the stage for
                      what is sure to become the true driving enthusiast’s
                      choice for a year-round performance EV.
                      <br />
                      <br />
                      <strong>
                        "Just as our N models are honed at the sharp corners of
                        the Nürburgring, our N models are also honed at the
                        sharp corners and icy surfaces of our proving ground in
                        Arjeplog, ensuring maximum performance in the most
                        extreme winter conditions. We’re proud to demonstrate
                        the IONIQ 5 N perfectly meets our broad performance
                        criteria, ensuring N Brand success as our first EV
                        production N model."
                      </strong>{' '}
                      - Till Wartenberg, Vice president of N Brand Management &
                      Motorsport sub-division at Hyundai Motor Company
                      {/* <img src="images/expo20302.jpeg" className="img-fluid" /> */}
                    </p>
                    {/* <img
                      src="images/arcticEnvironment2.jpeg"
                      className="img-fluid"
                    /> */}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      The motorsport-inspired 3 pillars of N performance –
                      corner rascal, racetrack capability, and everyday
                      sportscar – have long applied to N’s electrification
                      strategy. In 2015, N brand established its vision of a
                      sustainable future for the next generation of driving
                      enthusiasts by showing the N 2025 Vision Gran Turismo. N’s
                      electrification strategy began with the RM20e prototype
                      and Veloster N ETCR race car. Last year, N brand
                      introduced the RN22e, a rolling lab designed to
                      communicate a transfer of N’s electrification experience
                      to the brand’s first production EV, IONIQ 5 N, slated for
                      launch later this year.
                      <strong>
                        <br />
                        <br />
                        "Our electric transition was spearheaded by the
                        race-proven Veloster N ETCR and we sought to bridge
                        motorsport technology to the road with the RN22e. Now it
                        is time to bring all this promise to our beloved
                        customers and I am confident IONIQ 5 N will be able to
                        deliver. We’ve been able to demonstrate IONIQ 5 N’s
                        capability to provide driving fun even in the harshest
                        conditions of Arjeplog, Sweden."
                      </strong>{' '}
                      - Albert Biermann, Executive technical Advisor for Hyundai
                      Motor Group
                      <br />
                      <br />
                      <img src="images/arctic6.jpg" className="img-fluid" />
                      <br />
                      <br />
                      For IONIQ 5 N, N brand engineers have optimized IONIQ 5’s
                      E-GMP’s dual-motor architecture for high performance in
                      all driving conditions — even the frozen ice lakes of
                      Arjeplog — with knowhow transfer of all-surface AWD
                      capabilities demonstrated by Hyundai i20 N WRC Rally 1 car
                      in Sweden.
                      <br />
                      <br />
                      IONIQ 5 N will be the first N brand production vehicle
                      with all-wheel drive (AWD). N’s AWD story began in 2015
                      through competition in the World Rally Championship (WRC).
                      Over eight years of WRC participation, Hyundai has earned
                      numerous accolades, including two world championships.
                      {/* <strong>
                        {' '}
                        "Having updated our brand’s image and pronunciation
                        locally, we wanted to explore pronunciation as a topic
                        more broadly and get people talking about those words
                        that we can potentially struggle with in everyday life,
                        such as quinoa and espresso. We have teamed up with
                        Seong-Jae, a.k.a Korean Billy, who is well known for his
                        light-hearted YouTube videos into different dialects
                        within the UK, to explore some British words from Korean
                        standpoint."
                      </strong>{' '}
                      - Ashley Andrew Managing Director, Hyundai Motor UK */}
                      <br />
                      <br />
                      IONIQ 5 N’s corner-carving capability is further bolstered
                      by the N Drift Optimizer, which integrates front and rear
                      torque distribution, torque rate, suspension stiffness,
                      steering effort and the e-LSD (electronic-Limited Slip
                      Differential) system to create a driving mode dedicated
                      for drifting. It helps drivers of all skill levels to
                      enjoy drifting and it is designed with particular care for
                      drivers who are entering the sport of drifting for the
                      first time.
                      <br />
                      <br />
                      <img src="images/arctic7.jpg" className="img-fluid" />
                      <br />
                      <br />
                      With e-LSD specifically tuned for IONIQ 5 N, the
                      electronic control unit monitors input from wheel sensors
                      to accurately identify when a certain wheel needs extra
                      torque to enhance overall vehicle grip. That means e-LSD
                      improves handling during cornering and high-speed driving
                      on the racetrack as well as during adverse driving
                      conditions, such as slick ice and deep snow. The e- LSD
                      designed specifically for the IONIQ 5 N provides quicker
                      response and accuracy, which are especially important
                      considering the faster reacting torque delivery of EVs
                      compared to ICE vehicles.
                      <br />
                      <br />
                      In addition, IONIQ 5 N uses N Torque Distribution that is
                      optimized for different drive modes, allowing the driver
                      to select the torque level to both front and rear wheels.
                      N Torque Distribution and e-LSD work together to
                      distribute power to all four wheels in varying ratios and
                      are specifically designed to quickly respond to the
                      instantaneous and seamless power delivery inherent in EVs
                      – even in extreme low-friction conditions like those found
                      in Arjeplog.
                      <br />
                      <br />
                      <img src="images/arctic8.jpg" className="img-fluid" />
                      <br />
                      <br />
                      In conjunction with the winter test, N brand released
                      Hyundai N | IONIQ 5 N Teaser – Episode 1 highlighting the
                      IONIQ 5 N’s corner carving characteristics. The film
                      depicts the IONIQ 5 N drifting toe-to-toe with the Hyundai
                      Motorsport’s i20 N WRC Rally 1 car. The scene is set in
                      blue hour symbolizing the beginning of a new era for N
                      brand as it evolves one step further to the electrified
                      era.
                      {/* <br />
                      <br /> Commenting on the depth and reach of Chung’s
                      vision, MotorTrend added: “In that sense, he does his
                      father and grandfather proud, pushing the entire Hyundai
                      Motor Group to new levels. The man and his vision, and the
                      juggernaut that his company is becoming, loom loud and
                      large. For his vision and leadership, then, Euisun Chung
                      is our 2023 MotorTrend Person of the Year.”
                      <img src="images/execchair2.jpeg" className="img-fluid" /> */}
                      {/* <a
                        href="https://motorstudio.hyundai.com/busan/ln/main.do?strgC=04&lang=en"
                        target="_blank"
                      >
                        Hyundai Motorstudio Busan
                      </a>{' '}
                      , the ‘Reflections in Motion’ exhibition invites visitors
                      to explore and reflect on the interactive relationships
                      between time and design as well as technology and styling,
                      with various featured installations underscoring Hyundai
                      Motor’s vision centered on enabling ‘Progress for
                      Humanity’. */}
                      {/* <br />
                      <br />
                      Also ranked on MotorTrend’s Power List are Luc
                      Donckerwolke, President and Chief Creative Officer of
                      Hyundai Motor Group (3); Jose Muñoz, President and Global
                      Chief Operating Officer of Hyundai Motor Company (10);
                      SangYup Lee, Executive Vice President and Head of Hyundai
                      Global Design Center (28); and Karim Habib (44), Executive
                      Vice President and Head of Kia Global Design Center.
                      <br />
                      <br />
                      Previous recognition for Chung’s pioneering thinking
                      includes the Executive Chair being named Newsweek’s
                      ‘Visionary of the Year’ at its inaugural World’s Greatest
                      Auto Disrupters event in 2022 and being honored with the
                      coveted Issigonis Trophy at the 2021 Autocar Awards. */}
                      {/* <strong>
                        "School trips are an integral part of a child’s
                        educations, and I remember my own outings with such fond
                        memories, even all these years later! What’s more, being
                        able to turn these trips into further learning within
                        the classroom is such an important skill and something
                        that should be available for everyone. This programme is
                        extremely timely and will be crucial in putting school
                        trips firmly back on the agenda"
                      </strong>{' '}
                      - Tim Campbell Hyundai's Educator in Residence;
                      <br /> */}
                      {/* <br />
                      <br />
                      Speaking earlier in January, at a New Year town hall
                      meeting at the Group’s Namyang R&D Center, Chung
                      underlined his continued commitment to the success of the
                      Group. He urged everyone within the organisation to spare
                      no effort to ensure the delivery of the best possible
                      products and services for its customers.
                      <br />
                      <br />
                      <strong>
                        "We must take on challenges based on trust, and the
                        positive results must lead to even stronger trust. No
                        great product or technology is complete without the
                        trust of our customers. Quality is a priority for all of
                        us. From product planning to design, production, sales,
                        and after-sales maintenance, all teams and employees
                        must place quality and the customer as the top priority.
                        We must all go above and beyond to earn the respect and
                        loyalty of our customers."
                      </strong>{' '}
                      - Euisun Chung, The Groups Executive Chair
                      <br />
                      <br /> */}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
