import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';
import Store from 'store';
import ReactGA from 'react-ga';
export default class WhatsInAName extends Component {
  componentDidMount = () => {};

  clickHandler = () => {};

  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="oneMillions" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="oneMillion-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/whatsinanameTile.jpeg") ',
              }}
            />
            {/* <div
              id="Bayon-gallery-2"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/hyundaielectric2.jpg")',
              }}
            /> */}
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                {/* <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#Bayon-gallery-2"
                    style={{
                      backgroundImage: 'url("images/hyundaielectric2.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#Bayon-gallery-1"
                    style={{
                      backgroundImage: 'url("images/hyundaielectric1.jpg")',
                    }}
                  />
                </div> */}
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">“What’s in a name?”</p>
              </div>
              <hr />

              <NewsMedia articleId="awards" articleText="awards" />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    WHAT'S IN A NAME?
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    {' '}
                    <ul>
                      <li>
                        Half of the population admit to ‘Googling’ to check
                        their phrasing
                      </li>
                      <li>
                        Hyundai reveals top 25 most mispronounced words by Brits
                        and partners with Seong-Jae, a.k.a. Korean Billy, as it
                        updates its local pronunciation from ‘Hy-un-dai’ to the
                        global ‘Hyun-day’
                      </li>
                      {/* <li>
                        Hyundai is investing £1 million in a programme of school
                        trips in order to prevent children missing out on such
                        experiences
                      </li>
                      <li>
                        Bookings are now open for the initiative, which has
                        launched with Hyundai’s Educator in Residence, Tim
                        Campbell MBE
                      </li> */}
                    </ul>
                  </p>
                  {displayShowMore && (
                    <p>
                      <a
                        data-toggle="collapse"
                        href="#whatsinaname"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </div>

              <div className={moreSectionClassName} id="whatsinaname">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      <strong>
                        Hyundai reveals we’re a nation of sense checkers when it
                        comes to pronunciation
                      </strong>
                      <br />
                      <br />
                      Nearly three quarters of Brits (73%) say that they have a
                      good grasp of the English language, yet a new survey has
                      revealed that nearly half (47%) study the correct
                      pronunciation online via a search engine to check that
                      they’ve got it right.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {/* <img src="images/ponyexib2.jpg" className="img-fluid" /> */}
                      The survey of 2,000 adults highlighted 45% also still turn
                      to a dictionary or thesaurus to double check the
                      pronunciation of a word or phrase.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      The study, which has been commissioned by leading car
                      manufacturer, Hyundai, coincides with its change from the
                      anglicised pronunciation of ‘Hy-un-dai’ to the global
                      pronunciation: ‘Hyun-day’. A change that marks a new era
                      for Hyundai in the UK, with a tongue in cheek
                      multi-channel advert and collaboration with Anglophile
                      Korean Billy as he adapts to UK living and pronunciation.
                      The research reveals the top 25 most mispronounced words,
                      with parliament, tenterhooks, quinoa and cache making the
                      list.
                      <br />
                      <br />
                      {/* This research has been commissioned by Hyundai to launch
                      its ‘Great British School Trip’ programme. The ambitious
                      initiative will provide 25,000 school children aged seven
                      to 14 with free school trips in 2023. The automotive
                      company will offer bursaries to help the schools most in
                      need to fund their school trips, including booking fees
                      and travel costs. */}
                      <strong>
                        {' '}
                        "Having updated our brand’s image and pronunciation
                        locally, we wanted to explore pronunciation as a topic
                        more broadly and get people talking about those words
                        that we can potentially struggle with in everyday life,
                        such as quinoa and espresso. We have teamed up with
                        Seong-Jae, a.k.a Korean Billy, who is well known for his
                        light-hearted YouTube videos into different dialects
                        within the UK, to explore some British words from Korean
                        standpoint."
                      </strong>{' '}
                      - Ashley Andrew Managing Director, Hyundai Motor UK
                      <br />
                      <br /> The picture differs across the UK, with those in
                      the North East have admitted to having difficulty with
                      pronouncing ‘probably’ (28%), while the North West trip up
                      over ‘zoology’ (19%) and the West Midlands have struggled
                      with ‘quinoa’ (21%).
                      <br />
                      <br /> There’s also a split when it comes to men and
                      women. The survey discovered that over half of men (51%)
                      think that by simplifying words, it will make them easier
                      to pronounce. In comparison, over half of women (51%) will
                      turn to search engines to seek assurance in saying
                      something correctly.
                      {/* <a
                        href="https://motorstudio.hyundai.com/busan/ln/main.do?strgC=04&lang=en"
                        target="_blank"
                      >
                        Hyundai Motorstudio Busan
                      </a>{' '}
                      , the ‘Reflections in Motion’ exhibition invites visitors
                      to explore and reflect on the interactive relationships
                      between time and design as well as technology and styling,
                      with various featured installations underscoring Hyundai
                      Motor’s vision centered on enabling ‘Progress for
                      Humanity’. */}
                      <br />
                      <br />
                      Over 350 venues have shown their support for The Great
                      British School Trip by signing up as trip locations
                      already. Children will be able to explore locations
                      including Peak Activity Centres, The YHA, Disney
                      Theatrical and The V&A, amongst others.
                      <br />
                      <br />
                      It also appears that there is an unspoken rule when it
                      comes to correcting the way friends pronounce words and
                      phrases, even if we know they might be getting it wrong.
                      Just under a third (29%) have revealed that they wouldn’t
                      correct someone else even if they know they had said a
                      word incorrectly.
                      {/* <strong>
                        "School trips are an integral part of a child’s
                        educations, and I remember my own outings with such fond
                        memories, even all these years later! What’s more, being
                        able to turn these trips into further learning within
                        the classroom is such an important skill and something
                        that should be available for everyone. This programme is
                        extremely timely and will be crucial in putting school
                        trips firmly back on the agenda"
                      </strong>{' '}
                      - Tim Campbell Hyundai's Educator in Residence;
                      <br /> */}
                      <br />
                      To see more from Anglophile Korean Billy, take the
                      pronunciation quiz and read more about Hyundai’s evolving
                      brand by visiting:{' '}
                      <a
                        href="https://www.hyundai.co.uk/new-dawn."
                        target="_blank"
                      >
                        https://blog.hyundai.co.uk/experiences/hyundai-csv/hyundai-pledges-1m-for-school-trips-to-remember
                      </a>
                      <br />
                      <br />
                      <br />
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
