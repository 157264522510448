import Store from 'store';
import Auth from '../utils/Auth';

export default {
  saveCustomer(customerString, successCallback, failCallback) {
    fetch(
      process.env.REACT_APP_API_URL +
        '/Customer?key=' +
        Store.get('loginToken').replace(/['"']+/g, '') +
        '&Brand=HyundaiUk',
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: customerString,
      },
    )
      .then((response) => {
        if (response.status === 200) {
          if (successCallback) {
            successCallback();
          }
        } else {
          if (failCallback) {
            failCallback();
          }
        }
      })
      .catch(function (error) {
        if (failCallback) {
          failCallback();
        }
      });
  },
  saveVehicle(vehicles, successCallback, failCallback, campaign) {
    const vehicleString = JSON.stringify(vehicles);

    if (campaign) {
      fetch(
        process.env.REACT_APP_API_URL +
          '/Essentials/' +
          campaign +
          '?key=' +
          Store.get('loginToken').replace(/['"']+/g, ''),
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: vehicleString,
        },
      )
        .then((response) => {
          if (response.status === 200) {
            if (successCallback) {
              successCallback();
            }
          } else {
            if (failCallback) {
              failCallback();
            }
            throw new Error(response.message);
          }
        })
        .catch(function (error) {
          if (failCallback) {
            failCallback();
          }
        });
    } else {
      fetch(
        process.env.REACT_APP_API_URL +
          '/Vehicle?key=' +
          Store.get('loginToken').replace(/['"']+/g, '') +
          '&Brand=HyundaiUk',
        {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          body: vehicleString,
        },
      )
        .then((response) => {
          if (response.status === 200) {
            if (successCallback) {
              successCallback();
            }
          } else {
            if (failCallback) {
              failCallback();
            }
          }
        })
        .catch(function (error) {
          if (failCallback) {
            failCallback();
          }
        });
    }
  },

  removeVehicle(successCallback, failCallback, vin) {
    fetch(
      process.env.REACT_APP_API_URL +
        '/Manager/RemoveVehicle?key=' +
        Store.get('loginToken').replace(/['"']+/g, '') +
        '&vin=' +
        vin,
      {
        method: 'post',
      },
    )
      .then((response) => {
        if (response.status === 200) {
          if (successCallback) {
            successCallback();
          }
        } else if (response.status === 401) {
          Auth.signout();
        } else {
          if (failCallback) {
            failCallback();
          }
        }
      })
      .catch((error) => {
        if (failCallback) {
          failCallback();
        }
      });
  },
  updateEmail(email, updateCallback) {
    const customer = Store.get('customerInfo');

    customer.email = email;

    fetch(
      process.env.REACT_APP_API_URL +
        '/Customer?key=' +
        Store.get('loginToken').replace(/['"']+/g, '') +
        '&Brand=HyundaiUk',
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(customer),
      },
    ).then((response) => {
      if (response.status === 200) {
        Store.set('customerInfo', customer);

        if (typeof updateCallback === 'function') {
          updateCallback(true);
        }
      } else {
        if (typeof updateCallback === 'function') {
          updateCallback(false);
        }
      }
    });
  },

  updatePassword(password, updateCallback) {
    const newPassword = { newPassword: password };

    fetch(
      process.env.REACT_APP_API_URL +
        '/Account?key=' +
        Store.get('loginToken').replace(/['"']+/g, '') +
        '&Brand=HyundaiUk',
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newPassword),
      },
    ).then((response) => {
      if (response.status === 200) {
        if (typeof updateCallback === 'function') {
          updateCallback(true);
        }
      } else {
        if (typeof updateCallback === 'function') {
          updateCallback(false);
        }
      }
    });
  },

  async updateVehicle(vehicleObject) {
    const response = await fetch(
      process.env.REACT_APP_API_URL +
        '/Vehicle/Update?key=' +
        Store.get('loginToken').replace(/['"']+/g, ''),
      {
        method: 'post',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(vehicleObject),
      },
    ).catch((error) => {
      console.log(new Error('reject'));
    });
    return response.status;
  },

  closeAccount(closedCallback) {
    fetch(
      process.env.REACT_APP_API_URL +
        '/Account/CloseAccount?key=' +
        Store.get('loginToken').replace(/['"']+/g, '') +
        '&Brand=HyundaiUk',
      {
        method: 'post',
      },
    ).then((response) => {
      if (response.status === 200) {
        if (typeof closedCallback === 'function') {
          closedCallback(true);
        }
      } else {
        if (typeof closedCallback === 'function') {
          closedCallback(false);
        }
      }
    });
  },
};
