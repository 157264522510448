import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';

export default class SmartPhoneKey extends Component {
  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="SmartPhoneKey" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="smartPhoneKey-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/hyundai_digi_door_v2.jpg") ',
              }}
            />
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “Hyundai Motor Group announced the development of a ‘Digital
                  Key’, which allows users to unlock and start their Hyundai
                  vehicle via their smartphone. ”
                </p>
              </div>
              <hr />

              <NewsMedia
                articleId="smartPhoneKey"
                articleText="Hyundai Motor Group Develops Smartphone-based Digital Key"
              />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    HYUNDAI MOTOR GROUP DEVELOPS SMARTPHONE-BASED DIGITAL KEY
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <ul
                    className="theme-news-body-text"
                    data-search-tag="summary"
                  >
                    <li>
                      <strong>
                        Technology allows vehicle to be unlocked, started, and
                        driven without a physical key
                      </strong>
                    </li>
                    <li>
                      <strong>
                        Digital Key identifies user and vehicle adjusts settings
                        automatically, facilitating car-sharing
                      </strong>
                    </li>
                    <li>
                      <strong>
                        Technology to feature in new Hyundai vehicles starting
                        later this year
                      </strong>
                    </li>
                  </ul>

                  {displayShowMore && (
                    <p>
                      <a  
                        data-toggle="collapse"
                        href="#collapsesmartPhoneKey"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                  {/* <p className="theme-news-body-text">The i30 Fastback’s sloping roofline, long bonnet and muscular body, along with its slim cabin and wide wheel arches give the car a strong stance. That strength, however, is far from being merely skin deep.</p> */}
                </div>
              </div>

              <div className={moreSectionClassName} id="collapsesmartPhoneKey">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      <strong>SEOUL, Mar. 4, 2019</strong> – Hyundai Motor Group
                      announced the development of a ‘Digital Key’, which allows
                      users to unlock and start their Hyundai vehicle via their
                      smartphone. Replacing a traditional physical key, the new
                      Digital Key can be downloaded via an app and used by up to
                      four authorized people.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      The Digital Key can be downloaded via a mobile phone
                      application and Near Field Communication (NFC) technology
                      detects the presence of an authorized Digital Key-enabled
                      cellphone in close proximity to the vehicle door.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      The NFC antenna for entry identification is located in the
                      handles of the driver and front passenger’s doors, whilst
                      one for starting the engine is located within the wireless
                      charging pad.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      After unlocking the vehicle, the user can start the engine
                      by placing the smartphone on the wireless charging pad in
                      the center console and pressing an engine Start/Stop
                      button on the dashboard.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      The user’s preferred vehicle settings are stored in the
                      vehicle. When the key is recognized those settings are
                      adjusted automatically – including the position of
                      mirrors, seats and the steering wheel, as well as controls
                      for the audio, video and navigation (AVN) systems and
                      head-up display.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      Hyundai’s Digital Key can be used to control selected
                      vehicle systems remotely via their smartphone. Using
                      Bluetooth Low Energy (BLE) communication, users can lock
                      and unlock the vehicle, activate the alarm and start the
                      engine. In addition, once the vehicles with autonomous
                      parking features are commercialized, such features are
                      also expected to be remotely controlled.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      The level of access to different vehicle functions can be
                      tailored to each user, for a defined period. The vehicle
                      owner can preset the duration of vehicle use or limit the
                      use to only certain features when renting the vehicle. For
                      instance, it can even be used to enable a courier to open
                      the trunk to deliver a parcel.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      Once car sharing becomes more widespread, the Digital Key
                      will be further programmed to support hassle free vehicle
                      rental where the owner and the driver won’t have to meet
                      but can transfer the Digital Key via the mobile phone
                      application.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      As well as making it much easier for a Hyundai vehicle to
                      be included in a car-sharing program, in the future, the
                      Digital Key will be further enhanced to allow for features
                      such as an alarm to be triggered when the vehicle exceeds
                      a defined speed or travels outside a designated area.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      For cases such as using a valet service or visiting a
                      repair shop, where handing over a digital key is not
                      feasible, conventional smart key and card type key are
                      also provided.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      Hyundai Motor Group aims to gradually implement the
                      technology in its new production vehicles, starting later
                      this year.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      NFC is a form of Radio Frequency Identification (RFID),
                      which boasts a high level of security. The NFC wireless
                      data communication takes place only when the device and
                      the reader are placed several centimeters apart.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      “The Digital Key will benefit a very wide range of future
                      Hyundai customers, as well as enabling innovative new
                      schemes for vehicle sharing,” said Ho Yoo, Group Leader of
                      Hyundai Motor Group’s Electronics Development Group. “We
                      are studying other ways to harness this type of
                      connected-car technology to greatly enhance the driving
                      and ownership experience.”
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
