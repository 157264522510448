import React, { Fragment, Component } from 'react';
import FooterNavbar from '../components/footerNavbar';
import Topnavbar from '../components/topnavbar';
import Bottomnavbar from '../components/bottomnavbar';
import Footer from '../components/footer';
import Competition from '../components/competition/competition';
import Store from 'store';
import './competition.css';
import { Redirect } from 'react-router-dom';

export default class CompetitionPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      competition: Store.get('competitionInfo').find(
        (competition) =>
          competition.id ==
          new URLSearchParams(props.location.search).get('id'),
      ),
      renderedQuery: this.props.location.search,
    };
  }

  static getDerivedStateFromProps = (props, state) => {
    if (props.location.search !== state.renderedQuery) {
      return {
        renderedQuery: props.location.search,
        competition: Store.get('competitionInfo').find(
          (competition) =>
            competition.id ==
            new URLSearchParams(props.location.search).get('id'),
        ),
      };
    }
    return null;
  };

  getStandardFieldList = () => {
    const availableFields = [];
    const { competition } = this.state;
    if (competition.requiresAddressLine1) {
      availableFields.push('addressLine1');
    }
    if (competition.requiresAddressLine2) {
      availableFields.push('addressLine2');
    }
    if (competition.requiresAddressLine3) {
      availableFields.push('addressLine3');
    }
    if (competition.requiresAddressLine4) {
      availableFields.push('addressLine4');
    }
    if (competition.requiresAddressLine5) {
      availableFields.push('addressLine5');
    }
    if (competition.requiresEmail) {
      availableFields.push('email');
    }
    if (competition.requiresTitle) {
      availableFields.push('title');
    }
    if (competition.requiresForename) {
      availableFields.push('firstName');
    }
    if (competition.requiresHomePhone) {
      availableFields.push('homePhone');
    }
    if (competition.requiresWorkPhone) {
      availableFields.push('workPhone');
    }
    if (competition.requiresMobile) {
      availableFields.push('mobilePhone');
    }
    if (competition.requiresPostcode) {
      availableFields.push('postCode');
    }
    if (competition.requiresSurname) {
      availableFields.push('surname');
    }

    return availableFields;
  };

  render() {
    const { competition } = this.state;

    if (typeof competition ==='undefined') {
      return <Redirect to="/" />;
    }
    return (
      <Fragment>
        <Topnavbar />
        <Bottomnavbar />
        <div className="container">
          <section
            className="section1 wow fadeIn"
            data-wow-duration="2s"
            data-wow-delay="0.2s"
          >
            <Competition
              competitionId={competition.id}
              competitionTitle={competition.customTitle}
              competitionImage={competition.competitionPageImage}
              competitionImageAlt={
                competition.competitionPageImageAlternativeText
              }
              competitionDescription={competition.description}
              competitionWinnersDescription={competition.winDescription}
              competitionTermsTitle={competition.termsAndConditionsTitle}
              competitionTerms={competition.termsAndConditions}
              availableFields={this.getStandardFieldList()}
              customFields={competition.fields}
              competitionHasEntered={competition.hasEntered}
            />
          </section>
        </div>
        <FooterNavbar />
        <Footer />
      </Fragment>
    );
  }
}
