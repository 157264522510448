import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';
import Store from 'store';
import ReactGA from 'react-ga';
export default class EvEvent extends Component {
  componentDidMount = () => {
    ReactGA.event({
      label: 'Ev Event',
      category: 'Promotion',
      action: `Ev Event News item opened`,
      gaOptions: {
        userId: Store.get('customer') && Store.get('customer').urn,
      },
    });
  };

  clickHandler = () => {
    ReactGA.event({
      label: 'Ev Event',
      category: 'Promotion',
      action: `Ev Event News item Link Click`,
      gaOptions: {
        userId: Store.get('customer') && Store.get('customer').urn,
      },
    });
  };

  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="evEvent-news" className="col">
        <div className="row">
          <div className="col-md"></div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap"></div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “Hyundai celebrates World EV Day”
                </p>
              </div>
              <hr />

              <NewsMedia
                articleId="evEvent-news"
                articleText="Hyundai celebrates World EV Day with electric car test drive event"
              />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    Hyundai celebrates World EV Day with electric car test drive
                    event
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    <ul>
                      <li>
                        Hyundai passes 3,000 electric car sales in 2020 as
                        hundreds join test drive event
                      </li>
                      <li>
                        Hyundai boosts awareness of electric cars as founding
                        partner of World EV Day
                      </li>
                      <li>
                        Immediate availability could see hundreds of new
                        electric cars on the roads in the coming weeks
                      </li>
                    </ul>
                  </p>
                  {displayShowMore && (
                    <p>
                      <a
                        data-toggle="collapse"
                        href="#evEvent"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </div>

              <div className={moreSectionClassName} id="evEvent">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      <b>Leatherhead, 9 September 2020</b>– As a founding
                      partner of World EV Day, which takes place today, Hyundai
                      Motor UK has announced details of its largest electric car
                      test drive event, which will run from 11-20 September.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      As the car manufacturer that offers the broadest range of
                      electrified powertrains, Hyundai anticipates hundreds of
                      new customers will be converted to electric power during
                      the 10-day event.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      With full stock availability, customers ordering a new
                      KONA Electric or IONIQ Electric vehicle could enjoy
                      zero-emissions motoring less than two weeks after
                      ordering. Furthermore, customers buying a new KONA
                      Electric, IONIQ Electric or IONIQ Plug-In Hybrid during
                      the September test drive event will be offered their first
                      three services completely free. Customers interested in
                      taking part in the test drive can register at{' '}
                      <a href="https://www.hyundai.co.uk" target="_blank">
                        www.hyundai.co.uk
                      </a>
                      .
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      Ashley Andrew, Managing Director, Hyundai Motor UK, said:{' '}
                      <i>
                        &quot; With immediate availability across our electric
                        vehicle range, we’re keen to show as many customers as
                        possible the benefits of electric cars. Our test drive
                        event, in support of World EV Day, will enable hundreds
                        of motorists to get behind the wheel of an electric car
                        for the first time. I know they will be blown away by
                        the performance, refinement and financial
                        benefits.&quot;
                      </i>
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      Almost three-quarters of the Hyundai line-up will be
                      electrified by the end of the year and 2021 will see the
                      company introduce the next generation of pure EV models
                      under the IONIQ sub-brand. Despite the challenging market
                      condition in 2020, Hyundai has already sold more than
                      3,000 pure electric cars, a rise of 50% over the same
                      period in 2019.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      As a founding partner of World EV Day Hyundai is committed
                      to raising awareness of electric vehicles and their ease
                      of use, plus the environmental and financial benefits that
                      customers can enjoy.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      Andrew continues:{' '}
                      <i>
                        &quot;As a leader in eco-mobility and bringing
                        affordable technology to market, Hyundai is pleased to
                        partner with World EV Day to help accelerate the
                        transition to a lower carbon world. With more than 30
                        years of innovations in environmentally-friendly vehicle
                        development, Hyundai continues to look to the future.
                        &quot;
                      </i>
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      As a global demonstration of the worldwide shift in
                      attitudes towards sustainable transport, World EV Day
                      encourages motorists to make an EV pledge (
                      <a
                        href="https://www.worldevday.org/ev-pledge"
                        target="_blank"
                      >
                        worldevday.org/ev-pledge
                      </a>
                      ) by committing that their next car will be electric.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
