import React, { Component } from 'react';

export default class Quote extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: props.title,
      quoteDate: props.quoteDate,
      requestType: props.requestType,
    };
  }

  render() {
    const { title, quoteDate, requestType } = this.props;

    return (
      <div className="col-6 col-sm-4 theme-main-request-history-card mb-5">
        <img
          className="img-fluid flex-shrink-0  theme-main-request-history-card-image mx-auto d-block mb-lg-5 mb-3"
          src={`images/offervehicles/${title
            .toLowerCase()
            .replace(/\s/g, '')}.jpg`}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = 'images/shadow-vehicle.jpg';
          }}
          alt="Car"
        />
        <div>
          <h5 className="text-info">
            <small>
              <strong>{title}</strong>
            </small>
          </h5>
          <p className="small">
            <strong>Quote requested date:</strong>
            <br />
            {quoteDate}
          </p>
          <p className="small">
            <strong>Type of request:</strong>
            <br />
            {requestType}
          </p>
        </div>
      </div>
    );
  }
}
