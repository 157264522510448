import React, { Component } from 'react';
import NewsNavbar from '../components/newsNavbar';
import FooterNavbar from '../components/footerNavbar';
import Topnavbar from '../components/topnavbar';
import Bottomnavbar from '../components/bottomnavbar';
import Footer from '../components/footer';
import './news.css';
import NewsUtils from '../utils/News';
import NewsNavWells from '../components/news/newsNavWells';

// Awards -----------------------------------------------------------------------------
// import GreenFleet from '../components/news/awards/greenFleet';
import TopGearAward from '../components/news/awards/topGearAward';
import KonaScot from '../components/news/awards/konaScot';
import I10knocksCompetition from '../components/news/awards/i10knocksCompetition';
import KonaAwards from '../components/news/awards/konaAwards';
import WhatCar from '../components/news/awards/whatCar';
import BestEV from '../components/news/awards/bestEV';
import TwoAwards from '../components/news/awards/twoAwards';

import IoniqUsedCar from '../components/news/awards/ioniqUsedCar';
// import GQAwards from '../components/news/awards/gqAwards';
import JanuaryAwards from '../components/news/awards/januaryAwards';
import Feefo from '../components/news/awards/feefo';
import BestCarAwards from '../components/news/awards/bestCarAwards';
import Ioniq5 from '../components/news/awards/ioniq5';
import BusinessAwards from '../components/news/awards/businessAwards';
import I20NWinner from '../components/news/awards/i20nWinner';
import Ionic6Winner from '../components/news/awards/ionic6Winner';
import Ioniq5Winner from '../components/news/awards/ioniq5Winner';
import TucsonHonestJohn from '../components/news/awards/tucsonHonestJohn';
import Ionic6Award from '../components/news/awards/ionic6Award';
import I5n from '../components/news/awards/i5n';
import Ioniq5N from '../components/news/awards/ioniq5N';
import NewIoniq5n from '../components/news/awards/newIoniq5n';

export default class News extends Component {
  constructor(props) {
    super(props);

    var articles = [
      {
        name: 'newioniq5n',
        component: <NewIoniq5n />,
      },
      {
        name: 'ioniq5N',
        component: <Ioniq5N />,
      },
      {
        name: 'i5n',
        component: <I5n />,
      },
      {
        name: 'ioniq6Award',
        component: <Ionic6Award />,
      },
      {
        name: 'tucson',
        component: <TucsonHonestJohn />,
      },
      {
        name: 'ioniq5',
        component: <Ioniq5Winner />,
      },
      {
        name: 'ioniq6',
        component: <Ionic6Winner />,
      },

      {
        name: 'businessAwards',
        component: <BusinessAwards />,
      },
      {
        name: 'i20nWinner',
        component: <I20NWinner />,
      },
      {
        name: 'ioniq5',
        component: <Ioniq5 />,
      },
      {
        name: 'bestCarAwards',
        component: <BestCarAwards />,
      },
      {
        name: 'januaryAwards',
        component: <JanuaryAwards />,
      },
      {
        name: 'feefo',
        component: <Feefo />,
      },

      {
        name: 'twoAwards',
        component: <TwoAwards />,
      },
      {
        name: 'bestEV',
        component: <BestEV />,
      },
      {
        name: 'whatCar',
        component: <WhatCar />,
      },
      // {
      //   name: 'greenFleet',
      //   component: <GreenFleet />,
      // },
      {
        name: 'konaScot',
        component: <KonaScot />,
      },
      {
        name: 'topGearAward',
        component: <TopGearAward />,
      },
      {
        name: 'i10knocksCompetition',
        component: <I10knocksCompetition />,
      },
      {
        name: 'ioniqUsedCar',
        component: <IoniqUsedCar />,
      },
      {
        name: 'konaAwards',
        component: <KonaAwards />,
      },
    ];

    const displayItemName =
      this.props.location.query != null &&
      this.props.location.query.displayItem != null
        ? this.props.location.query.displayItem
        : null;

    if (displayItemName != null) {
      var itemIndex;
      for (var i = 0; i < articles.length; i++) {
        if (articles[i].name === displayItemName) {
          itemIndex = i;
          break;
        }
      }
      const articleRemoved = articles.splice(itemIndex, 1)[0];
      articles.splice(0, 0, articleRemoved);
    }

    this.state = {
      newsList: articles,
      displayCount: 2,
    };

    this.handleShowMoreClick = this.handleShowMoreClick.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    NewsUtils.attachThumbnailClick();
  }

  handleShowMoreClick() {
    this.setState((prevState) => {
      return {
        displayCount: prevState.displayCount + 2,
      };
    });
  }

  componentDidUpdate() {
    NewsUtils.attachThumbnailClick();
  }

  render() {
    const displayNews = this.state.newsList
      .slice(0, this.state.displayCount)
      .map((item, index) => (
        <div
          key={index}
          data-search-component={item.name}
          className="mb-5 w-100" //w-100 e.i.11 fix
        >
          {React.cloneElement(item.component, {
            displayShowMore: true,
          })}
        </div>
      ));
    const displayMoreButton =
      this.state.displayCount < this.state.newsList.length;

    return (
      <div>
        <Topnavbar />
        <Bottomnavbar />
        <NewsNavbar />

        <div className="container">
          <section className="section1 row mx-0">
            <div className="col-md-10 offset-md-1">
              <h4 className="h4ServHistory">News</h4>
              <p className="small">
                The latest 6 months news articles are listed below and all
                others can be viewed in the archive section.
              </p>
              <div className="row">{displayNews}</div>
            </div>

            {displayMoreButton && (
              <div className="col p-5">
                <div className="text-center">
                  <button
                    className="btn btn-primary removeRadius"
                    onClick={this.handleShowMoreClick}
                  >
                    Show more news
                  </button>
                </div>
              </div>
            )}
          </section>
        </div>
        <NewsNavWells />
        <FooterNavbar />
        <Footer />
      </div>
    );
  }
}
