import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './competition.css';
import Store from 'store';
import CompetitionSideNav from '../competitionSideNav';
import SaveData from '../../utils/SaveData';
import Validation from '../../utils/Validation';
import SelectField from '../selectField';
import InputField from '../inputField';
import CheckField from '../checkField';
import ValidationMessage from '../validationMessage';
import CustomField from './CustomField';
import GetData from '../../utils/GetData';
import { NameAddress } from '../Forms/NameAddress';

const Parser = require('html-to-react').Parser;

export default class Competition extends Component {
  static propTypes = {
    competitionId: PropTypes.number.isRequired,
    competitionTitle: PropTypes.string.isRequired,
    competitionImage: PropTypes.string,
    competitionImageAlt: PropTypes.string,
    competitionDescription: PropTypes.string.isRequired,
    competitionWinnersDescription: PropTypes.string.isRequired,
    competitionTerms: PropTypes.string.isRequired,
    competitionTermsTitle: PropTypes.string.isRequired,
    availableTitles: PropTypes.array,
    availableFields: PropTypes.array,
    customFields: PropTypes.array,
    competitionHasEntered: PropTypes.bool,
  };

  static defaultProps = {
    availableTitles: [
      'Mr',
      'Mrs',
      'Ms',
      'Miss',
      'Dr',
      'Professor',
      'Reverend',
      'Lord',
      'Lady',
    ],
    availableFields: [
      'title',
      'firstName',
      'surname',
      'addressLine1',
      'addressLine2',
      'addressLine3',
      'addressLine4',
      'addressLine5',
      'postCode',
      'homePhone',
      'mobilePhone',
      'workPhone',
      'email',
    ],
  };

  constructor(props) {
    super(props);

    this.state = {
      title: '',
      firstName: '',
      surname: '',
      houseName: '',
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      addressLine4: '',
      addressLine5: '',
      postCode: '',
      homePhone: '',
      mobilePhone: '',
      workPhone: '',
      email: '',
      phoneType: 'Mobile',
      terms: false,
      enteredPhoneNumber: '',
      saveCompetitionSuccess: false,
      saveCompetitionFailed: false,
      saveCustomerSuccess: false,
      saveCustomerFailed: false,
      competitionIsLoading: false,
      customerIsLoading: false,
      customFields: [],
      validation: this.buildValidationObject(),
      entered: false,
    };
  }

  buildValidationObject = () => {
    let validation = { terms: Validation.initValidationState(['terms']) };
    const phoneNumberRequired = this.props.availableFields.some((item) =>
      ['homePhone', 'mobilePhone', 'workPhone'].includes(item),
    );
    if (phoneNumberRequired) {
      validation.enteredPhoneNumber = Validation.initValidationState([
        'required',
        'phone',
      ]);
    }
    const filterFields = this.props.availableFields.filter(
      (item) => !['homePhone', 'mobilePhone', 'workPhone'].includes(item),
    );

    filterFields.forEach((item) => {
      if (item == 'email') {
        validation.email = Validation.initValidationState([
          'required',
          'email',
        ]);
      } else if (item == 'addressLine2' || item == 'addressLine3') {
        // required removed
      } else {
        validation[item] = Validation.initRequiredValidationState();
      }
    });

    return validation;
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.getCustomer();
  };

  getCustomer = () => {
    const customer = Store.get('customerInfo');

    if (customer) {
      this.setState({
        title: customer.title,
        firstName: customer.forenames,
        surname: customer.surname,
        addressLine1: customer.addressLine1,
        addressLine2: customer.addressLine2,
        addressLine3: customer.addressLine3,
        addressLine4: customer.addressLine4,
        addressLine5: customer.addressLine5,
        postCode: customer.postCode,
        homePhone: customer.homePhone,
        mobilePhone: customer.mobilePhone,
        enteredPhoneNumber: customer.mobilePhone,
        workPhone: customer.workPhone,
        email: customer.email,
        validation: this.buildValidationObject(),
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.competitionId != this.props.competitionId) {
      this.setState({
        entered: false,
        terms: false,

        saveCustomerFailed: false,
        saveCompetitionFailed: false,
      });

      this.getCustomer();
    }
  }

  handlePhoneChange = (event) => {
    const target = event.target;

    this.setState(
      (prevState) => {
        if (prevState.phoneType == 'Home') {
          return {
            homePhone: target.value,
            enteredPhoneNumber: target.value,
          };
        }

        if (prevState.phoneType == 'Work') {
          return {
            workPhone: target.value,
            enteredPhoneNumber: target.value,
          };
        }

        return {
          mobilePhone: target.value,
          enteredPhoneNumber: target.value,
        };
      },
      () => this.validateField('enteredPhoneNumber'),
    );
  };

  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    this.setState(
      {
        [name]: value,
      },
      () => this.validateField(name),
    );
  };

  handleCustomFieldChange = (field, value) => {
    this.setState({
      [field]: value,
    });
  };

  validateField(name) {
    this.setState((prevState) => {
      return Validation.validate(prevState, name);
    });
  }

  handlePhoneTypeChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    this.setState(
      (prevState) => {
        var enteredPhoneNumber = prevState.mobilePhone;
        if (value == 'Home') {
          enteredPhoneNumber = prevState.homePhone;
        }

        if (value == 'Work') {
          enteredPhoneNumber = prevState.workPhone;
        }

        return {
          phoneType: value,
          enteredPhoneNumber: enteredPhoneNumber,
        };
      },
      () => this.validateField('enteredPhoneNumber'),
    );
  };

  handleSubmitClick = () => {
    var newState = Validation.validateAll(this.state);

    if (Validation.anyInvalid(newState)) {
      this.setState(newState);
    } else {
      this.setState(
        {
          competitionIsLoading: true,
          customerIsLoading: true,
        },
        () => {
          const customer = Store.get('customerInfo');

          customer.title = this.state.title;
          customer.forenames = this.state.firstName;
          customer.surname = this.state.surname;
          customer.houseNameNumber = this.state.houseName;
          customer.addressLine1 = this.state.addressLine1;
          customer.addressLine2 = this.state.addressLine2;
          customer.addressLine3 = this.state.addressLine3;
          customer.addressLine4 = this.state.addressLine4;
          customer.addressLine5 = this.state.addressLine5;
          customer.postCode = this.state.postCode;
          customer.email = this.state.email;
          customer.homePhone = this.state.homePhone;
          customer.mobilePhone = this.state.mobilePhone;
          customer.workPhone = this.state.workPhone;

          this.saveCustomer(customer);
        },
      );
    }
  };

  saveCustomer = (customer) => {
    SaveData.saveCustomer(
      JSON.stringify(customer),
      () => {
        Store.set('customerInfo', customer);
        this.setState({
          saveCustomerSuccess: true,
          customerIsLoading: false,
        });
        this.enterCompetition();
      },
      () => {
        this.setState({
          saveCustomerFailed: true,
          customerIsLoading: false,
        });
      },
    );
  };

  constructAnswer = () => {
    const answers = [];

    answers.push({
      competitionID: this.props.competitionId,
      fieldID: 0,
      answerOptionID: 0,
      answer: this.state.phoneType,
    });

    this.props.customFields.forEach((field) => {
      const answer = {};
      answer.competitionID = this.props.competitionId;
      answer.fieldID = field.id;
      var answerOption;
      if (field.type != 1) {
        answer.answerOptionID = parseInt(this.state[field.id])
          ? parseInt(this.state[field.id])
          : 0;
      } else {
        answer.answerOptionID = 0;
      }
      if (field.type != 1) {
        answerOption = this.state[field.id] ? this.state[field.id] : false;
      } else {
        answerOption = this.state[field.id] ? this.state[field.id] : '';
      }
      answer.answer = answerOption;
      answers.push(answer);
    });

    return answers;
  };

  enterCompetition = () => {
    var competition = this.constructAnswer();

    fetch(
      process.env.REACT_APP_API_URL +
        '/HyundaiUk/Competition/Enter?key=' +
        Store.get('loginToken').replace(/['"']+/g, ''),
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(competition),
      },
    )
      .then((response) => {
        if (response.status == 200) {
          this.setState({
            entered: true,
            saveCompetitionSuccess: true,
            competitionIsLoading: false,
          });

          GetData.refreshCompetitions();
        } else {
          this.setState({
            saveCompetitionFailed: true,
            competitionIsLoading: false,
          });
        }
      })
      .catch(() => {
        this.setState({
          saveCompetitionFailed: true,
          competitionIsLoading: false,
        });
      });
  };

  createTitleOptions = () => {
    const titles = this.props.availableTitles;
    return titles.map((title) => (
      <option key={title} value={title}>
        {title}
      </option>
    ));
  };

  createPoneTypesOptions = () => {
    const availablePhoneTypes = [];
    if (this.props.availableFields.includes('mobilePhone')) {
      availablePhoneTypes.push('Mobile');
    }
    if (this.props.availableFields.includes('workPhone')) {
      availablePhoneTypes.push('Work');
    }
    if (this.props.availableFields.includes('homePhone')) {
      availablePhoneTypes.push('Home');
    }
    return availablePhoneTypes.map((option, index) => (
      <div key={option} className="custom-control custom-checkbox">
        <input
          id={index + 'check'}
          type="checkbox"
          name="phoneType"
          value={option}
          className="custom-control-input"
          checked={option == this.state.phoneType}
          onChange={this.handlePhoneTypeChange}
          disabled={!this.state.validation.enteredPhoneNumber.isValid}
        />
        <label className="custom-control-label" htmlFor={index + 'check'}>
          {option}
        </label>
      </div>
    ));
  };

  createDescription = () => {
    const parser = new Parser();
    const reactComponent = parser.parse(this.props.competitionDescription);
    return reactComponent;
  };

  createTerms = () => {
    const parser = new Parser();
    const reactComponent = parser.parse(this.props.competitionTerms);
    return reactComponent;
  };

  createCustomFields = () => {
    if (this.props.customFields) {
      const fields = this.props.customFields.map((field, index) => {
        return (
          <CustomField
            key={index}
            name={field.id.toString()}
            description={field.displayText}
            options={field.options}
            numberType={field.type}
            stateHandler={this.handleCustomFieldChange}
          />
        );
      });
      return fields;
    }
  };
  handleStateChange = (state, callback) => {
    this.setState(state, () => {
      callback ? callback() : null;
    });
  };

  renderForm = () => {
    const isLoading =
      this.state.customerIsLoading || this.state.competitionIsLoading;
    const isFailed =
      this.state.saveCustomerFailed || this.state.saveCompetitionFailed;
    const compId = this.props.competitionId;
    const formInValid = Validation.anyInvalid(this.state);
    return (
      <form method="post" className="competition-form">
        {this.props.customFields && (
          <div className="row">
            <div className="col pb-4">{this.createCustomFields()}</div>
          </div>
        )}
        <div className="row">
          <div className="col">
            <p className="mandatory">
              <span className="mandatory text-muted">
                <small>Fields marked with a * are mandatory</small>
              </span>
            </p>
          </div>
        </div>

        <NameAddress
          // Handlers
          handleInputChange={this.handleInputChange}
          //   handleValidationChange={this.handleValidationChange}
          handleStateChange={this.handleStateChange}
          // Name Data
          title={this.state.title}
          firstName={this.state.firstName}
          surname={this.state.surname}
          // Address Data
          houseName={this.state.houseName}
          addressLine1={this.state.addressLine1}
          addressLine2={this.state.addressLine2}
          addressLine4={this.state.addressLine4}
          addressLine5={this.state.addressLine5}
          postCode={this.state.postCode}
          // Visibility
          titleVisibility={this.props.availableFields.includes('title')}
          firstNameVisibility={this.props.availableFields.includes('firstName')}
          surnameVisibility={this.props.availableFields.includes('surname')}
          houseNameVisibility={this.props.availableFields.includes('houseName')}
          addressLine1Visibility={this.props.availableFields.includes(
            'addressLine1',
          )}
          addressLine2Visibility={this.props.availableFields.includes(
            'addressLine2',
          )}
          addressLine4Visibility={this.props.availableFields.includes(
            'addressLine4',
          )}
          addressLine5Visibility={this.props.availableFields.includes(
            'addressLine5',
          )}
          postCodeVisibility={this.props.availableFields.includes('postCode')}
          // Validation
          titleValidation={this.state.validation.title}
          firstNameValidation={this.state.validation.firstName}
          surnameValidation={this.state.validation.surname}
          addressLine1Validation={this.state.validation.addressLine1}
          postCodeValidation={this.state.validation.postCode}
        ></NameAddress>

        <div className="row">
          <div className="col-md-6">
            {this.props.availableFields.includes('email') && (
              <div className="form-group">
                <label>Email*</label>
                <div style={{ height: '75px' }}></div>
                <InputField
                  type="email"
                  name="email"
                  value={this.state.email}
                  onChange={this.handleInputChange}
                  validation={this.state.validation.email}
                />
                <ValidationMessage validation={this.state.validation.email} />
                <small id="passwordHelpBlock" className="form-text text-muted">
                  (This will be your user name for login)
                </small>
              </div>
            )}
          </div>
          <div className="col-md-6">
            {this.props.availableFields.some((r) =>
              ['homePhone', 'mobilePhone', 'workPhone'].includes(r),
            ) && (
              <div className="form-group">
                <div className="">
                  <label className="telephone-label small">
                    Telephone number*
                  </label>
                  <div className="theme-competitions-phone-options d-flex justify-content-between flex-wrap">
                    {this.createPoneTypesOptions()}
                  </div>
                </div>
                <InputField
                  name="enteredPhoneNumber"
                  value={this.state.enteredPhoneNumber}
                  onChange={this.handlePhoneChange}
                  validation={this.state.validation.enteredPhoneNumber}
                  type="tel"
                />
                <ValidationMessage
                  validation={this.state.validation.enteredPhoneNumber}
                />
                <small id="phoneHelpBlock" className="form-text text-muted">
                  (Please note, your contact number will be used for the purpose
                  of this competition only)
                </small>
              </div>
            )}
          </div>
        </div>

        <div className="row">
          <div className="col competition-body ">
            <p className="pt-md-2">
              {this.props.competitionWinnersDescription}
            </p>

            <div className="">
              <CheckField
                name="terms"
                id="checkAccept"
                checked={this.state.terms}
                onChange={this.handleInputChange}
                validation={this.state.validation.terms}
                label={[
                  <sup className="terms-link-sup" key="1">
                    ^
                  </sup>,
                  <span key="2" className="terms-link-span small">
                    I accept the
                    <button
                      type="button"
                      className=" terms-link"
                      data-toggle="modal"
                      data-target="#tAndCModal"
                    >
                      terms and conditions
                    </button>
                  </span>,
                ]}
              />
            </div>

            {formInValid && (
              <div className="text-left text-danger py-3">
                <small>
                  <i className="fas fa-exclamation-triangle" /> Please correct
                  the errors above before continuing.
                </small>
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 pt-3 text-center">
            <button
              type="button"
              className="btn btn-primary btn-block removeRadius competition-submit"
              disabled={this.state.hasErrors || isLoading}
              onClick={this.handleSubmitClick}
            >
              {isLoading ? (
                <div>
                  <i className="fas fa-spinner fa-spin fa-lg text-white" />
                </div>
              ) : (
                <span>Submit</span>
              )}
            </button>

            {isFailed && (
              <p className="text-danger">
                {' '}
                <small>
                  <i className="fas fa-exclamation-triangle" /> Error in saving
                  changes
                </small>
              </p>
            )}
          </div>
        </div>

        {/* Live IDs - Tate exhibition, Tate Dorothea, Tate Turner */}
        {(compId == 3 || compId == 4 || compId == 5) && (
          <div className="row">
            <div className="small col-12 py-3">
              The new Hyundai Commission welcomes Cuban installation and
              performance artist Tania Bruguera at the Turbine Hall, Tate Modern
              from 2 October 2018 to 24 February 2019, in partnership with
              Hyundai Motors.
            </div>
          </div>
        )}
      </form>
    );
  };

  render = () => {
    const { entered } = this.state;
    return (
      <div className="content pb-5  p-3 p-xl-5 col-md-10 offset-md-1 border-right border-bottom">
        <div className="row">
          <div className="col-lg-3 mt-4 border-lg-right">
            <CompetitionSideNav competitions={Store.get('competitionInfo')} />
          </div>
          <div className="col-lg-9  p-md-4 ">
            <h4 className="h4ServHistory">
              <span className="newsPageTitle" data-search-tag="title">
                {this.props.competitionTitle}
              </span>
            </h4>
            <hr />

            <div className="pb-5">
              <div className="row">
                <div className="col">
                  <img
                    className="card-img-top img-fluid limit"
                    src={this.props.competitionImage}
                    alt={this.props.competitionImageAlt}
                  />
                  <small>{this.props.competitionImageAlt}</small>
                </div>
              </div>
              <div>
                <div className="row">
                  <div className="col pb-2 competition-body">
                    {this.createDescription()}
                  </div>
                </div>

                {!entered &&
                  !this.props.competitionHasEntered &&
                  this.renderForm()}

                {(entered || this.props.competitionHasEntered) && (
                  <div className="row">
                    <div className="col competition-body">
                      <p>
                        <strong>
                          Thank you. You've entered the current competition.
                          Please check back again soon for a new competition.
                        </strong>
                      </p>
                      <p>
                        <strong>
                          Click{' '}
                          <a
                            href="interests"
                            className="text-underline text-body"
                          >
                            here to select which interests
                          </a>{' '}
                          appeal to you the most and we can bring you more of
                          the competitions you love.
                        </strong>
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="tAndCModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="tAndCModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-lg modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="tAndCModalLabel">
                  T&amp;Cs – {this.props.competitionTermsTitle}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">{this.createTerms()}</div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}
('');
