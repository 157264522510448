import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';

export default class NewGenerationSantaFe extends Component {
  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="NewGenerationSantaFe" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="SantaFEnew-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/SantaFE.jpg")',
              }}
            />
          </div>
        </div>

        <div className="col bg-white border-right border-bottom">
          <div className="row py-3">
            <div className="col-lg-4">
              <hr />
              <NewsMedia
                articleId="NewGenerationSantaFe"
                articleText="New Generation Santa Fe"
              />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-8 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    New Generation Santa Fe joins ranks of Fab Four
                  </p>
                  <p className="theme-news-body-text" data-search-tag="summary">
                    Some of the best things in life have come in fours; the
                    Beatles, for example, and fingers in a KitKat. The New
                    Generation Santa Fe is the latest member of the fab four
                    fanclub with the unveiling of its fourth version.
                  </p>
                  {displayShowMore && (
                    <p>
                      <a  
                        data-toggle="collapse"
                        href="#collapseNGSantaFe"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </div>

              <div className={moreSectionClassName} id="collapseNGSantaFe">
                <div className="row pt-3">
                  <div className="col-md-8 offset-md-1">
                    <p className="theme-news-body-text">
                      It proves that our largest car, which was first launched
                      in the UK back in 2001, is as popular as ever. The current
                      model, which will celebrate its world premiere this month
                      (February), is an elegant SUV with class-leading
                      roominess.
                    </p>
                    <p className="theme-news-body-text">
                      From the outside, the car has a powerful, wide stance and
                      an athletic, bold look, with refined lines that reinforce
                      the car’s status at the top of our SUV line-up. Inside,
                      premium quality, a roomy layout, and greater visibility
                      than ever before give it a luxury feel.
                    </p>
                  </div>
                </div>

                <div className="row pt-3">
                  <div className="col-md-8 offset-md-1">
                    <p className="theme-news-body-text">
                      “As part of its SmartSense technology, the Santa Fe offers
                      some of the latest active safety features around. Rear
                      Cross-Traffic Collision Avoidance Assist, for example,
                      which is a first for the brand, not only warns if vehicles
                      approach from the side when reversing out of areas with
                      low visibility, but the system can also apply the brakes
                      automatically. The Safety Exit Assist, meanwhile, prevents
                      accidents when vehicles approach from behind by
                      temporarily locking the doors, so that passengers can only
                      get out of the car when it’s safe.
                    </p>
                    <p className="theme-news-body-text">
                      Visit{' '}
                      <a  
                        href="http://www.hyundai.co.uk/new-cars/new-generation-santa-fe"
                        target="_blank" rel="noopener noreferrer"
                      >
                        www.hyundai.co.uk/new-cars/new-generation-santa-fe{' '}
                      </a>
                      for more details before the car makes its debut at the
                      Geneva Motor Show in March.
                    </p>
                    <p className="small">03/03/2018</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
