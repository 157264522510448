import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

export default class RequestHistoryNavbar extends Component {
  render() {
    return (
      <div className="container" data-search-exclude>
        <nav className="navbar navbar-expand-lg navbar-light bg-light p-0 dropShadow secondary-nav">
          <div className="slantedDiv">Request History</div>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto navbarText p-3 p-lg-0">
              <li className="nav-item pr-5">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/myQuotes"
                >
                  My quotes
                  <span className="sr-only">(current)</span>
                </NavLink>
              </li>
              <li className="nav-item pr-5">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/myTestDrives"
                >
                  My test drives
                </NavLink>
              </li>

              <li className="nav-item pr-5">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/myBrochures"
                >
                  My brochures
                </NavLink>
              </li>

              {/* <li className="nav-item pr-5">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/carsConfigured"
                >
                  Car configuration quotes
                </NavLink>
              </li> */}
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}
