import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';

export default class AirTaxi extends Component {
  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="AirTaxi" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="airTaxi-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/airtaximain.jpg") ',
              }}
            />
            <div
              id="airTaxi-gallery-2"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/airtaxi1.jpg") ',
              }}
            />
            <div
              id="airTaxi-gallery-3"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/airtaxi2.jpg") ',
              }}
            />

            <div
              id="airTaxi-gallery-4"
              className="theme-news-header-image "
              style={{
                backgroundImage: 'url("images/airtaxi3.jpg") ',
              }}
            />
            <div
              id="airTaxi-gallery-5"
              className="theme-news-header-image "
              style={{
                backgroundImage: 'url("images/airtaxi4.jpg") ',
              }}
            />
            <div
              id="airTaxi-gallery-6"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/airtaxi5.jpg") ',
              }}
            />
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#airTaxi-gallery-1"
                    style={{
                      backgroundImage: 'url("images/airtaximain.jpg") ',
                    }}
                  />
                </div>

                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#airTaxi-gallery-2"
                    style={{
                      backgroundImage: 'url("images/airtaxi1.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#airTaxi-gallery-3"
                    style={{
                      backgroundImage: 'url("images/airtaxi2.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail mt-3">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#airTaxi-gallery-4"
                    style={{
                      backgroundImage: 'url("images/airtaxi3.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail mt-3">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#airTaxi-gallery-5"
                    style={{
                      backgroundImage: 'url("images/airtaxi4.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail mt-3">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#airTaxi-gallery-6"
                    style={{
                      backgroundImage: 'url("images/airtaxi5.jpg") ',
                    }}
                  />
                </div>
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “Hyundai and Uber team up to create Air Taxis.”
                </p>
              </div>
              <hr />

              <NewsMedia
                articleId="airTaxi"
                articleText="Hyundai Motor, Tate Announce the Opening of the Hyundai Commission by Kara Walker"
              />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    HYUNDAI AND UBER TEAM UP TO CREATE AIR TAXIS
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text">
                    “Roads,” said Doctor Emmett Brown in the final scene of
                    hit-80s film Back To The Future, “where we’re going we don’t
                    need roads!”
                  </p>

                  <p className="theme-news-body-text">
                    OK, so 35 years later we still very much need roads which is
                    why Doc Brown would be very impressed with what is currently
                    on show at Booth 5431 in the Las Vegas Convention Center’s
                    North Hall.
                  </p>

                  <p className="theme-news-body-text">
                    The world-famous Consumer Electronics Show (CES) is in full
                    swing and has provided the perfect backdrop for Hyundai to
                    announce its Aerial Ridesharing Partnership with Uber.
                  </p>

                  {displayShowMore && (
                    <p>
                      <a
                        data-toggle="collapse"
                        href="#airTaxi"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </div>

              <div className={moreSectionClassName} id="airTaxi">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      Hyundai is the first car company to join the Uber Elevate
                      initiative – which aims to develop Uber Air Taxis for a
                      future aerial ride share network – and has unveiled a
                      full-scale electric Personal Air Vehicle (PAV) concept,
                      the S-A1, at the show.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      “Our vision of Urban Air Mobility will transform the
                      concept of urban transportation,” said Jaiwon Shin,
                      Executive Vice President and Head of Hyundai’s Urban Air
                      Mobility (UAM) Division. “We are confident that Uber
                      Elevate is the right partner to make this innovative
                      product readily available to as many customers as
                      possible.”
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      Hyundai’s fully electric S-A1 model is designed to take
                      off and land vertically, cruise at speeds of up to 180mph
                      at altitudes between 1,000 and 2,000 feet, and have a
                      range of up to 60 miles. The air taxi concept is powered
                      by multiple rotors and propellers to increase safety and
                      reduce noise, has been designed with four passenger seats,
                      and although it is intended to be piloted at first, it
                      will become autonomous over time.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      In the groundbreaking partnership, Hyundai will produce
                      and deploy the air vehicles, whilst Uber will provide
                      airspace support services, connections to ground
                      transportation, and customer interfaces through an aerial
                      ride share network. Both companies are collaborating on
                      infrastructure concepts to support take-off and landing.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      “Hyundai is our first vehicle partner with experience of
                      manufacturing passenger cars on a global scale. We believe
                      Hyundai has the potential to build Uber Air vehicles at
                      rates unseen in the current aerospace industry, producing
                      high quality, reliable aircraft at high volumes,” said
                      Eric Allison, head of Uber Elevate. “Combining Hyundai’s
                      manufacturing muscle with Uber’s technology platform
                      represents a giant leap forward for launching a vibrant
                      air taxi network in the coming years.”
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      As well as the S-A1 electric PAV concept, Hyundai has also
                      unveiled a Purpose Built Vehicle (PBV) concept and a new
                      infrastructure concept called the Hub at the CES show.
                      Hyundai’s vision for creating communities from future
                      transit systems means that when many PBVs and PAVs are
                      docked and connected to a Hub, they make a new public
                      space where diverse groups of people can come together.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
