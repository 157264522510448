import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';
import Store from 'store';
import ReactGA from 'react-ga';
export default class TucsonHonestJohn extends Component {
  componentDidMount = () => {};

  clickHandler = () => {};

  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="bestCarAwards-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/tucsonhonest.jpeg") ',
              }}
            />
            {/* <div
              id="bestCarAwards-gallery-2"
              className="theme-news-header-image "
              style={{
                backgroundImage: 'url("images/bca1.jpg") ',
              }}
            />
            <div
              id="bestCarAwards-gallery-3"
              className="theme-news-header-image "
              style={{
                backgroundImage: 'url("images/bca2.jpg") ',
              }}
            />
            <div
              id="bestCarAwards-gallery-4"
              className="theme-news-header-image "
              style={{
                backgroundImage: 'url("images/bca3.jpg") ',
              }}
            />
            <div
              id="bestCarAwards-gallery-5"
              className="theme-news-header-image "
              style={{
                backgroundImage: 'url("images/bca4.jpg") ',
              }}
            />
            <div
              id="bestCarAwards-gallery-6"
              className="theme-news-header-image "
              style={{
                backgroundImage: 'url("images/bca5.jpg") ',
              }}
            /> */}
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                {/* <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#bestCarAwards-gallery-2"
                    style={{
                      backgroundImage: 'url("images/bca1.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#bestCarAwards-gallery-3"
                    style={{
                      backgroundImage: 'url("images/bca2.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#bestCarAwards-gallery-4"
                    style={{
                      backgroundImage: 'url("images/bca3.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#bestCarAwards-gallery-5"
                    style={{
                      backgroundImage: 'url("images/bca4.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#bestCarAwards-gallery-6"
                    style={{
                      backgroundImage: 'url("images/bca5.jpg") ',
                    }}
                  /> */}
                {/* </div> */}
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “Hyundai TUCSON ranked as most satisfying car in the Honest
                  John Satisfaction Index 2022”
                </p>
              </div>
              <hr />

              <NewsMedia articleId="awards" articleText="awards" />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    HYUNDAI TUCSON RANKED AS MOST SATISFYING CAR IN THE HONEST
                    JOHN SATISFACTION INDEX 2022
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    {' '}
                    <ul>
                      <li>Hyundai TUCSON ranked as most satisfying car</li>
                      <li>
                        Hyundai IONIQ and Hyundai KONA Electric achieve 3rd and
                        4th places respectively
                      </li>
                      <li>
                        Hyundai TUCSON also scores 2nd place for reliability
                      </li>
                      {/* <li>
                        SANTA FE named ‘Seven-seater of the Year’ and ‘Best
                        Plug-in Hybrid for Big Families’
                      </li>
                      <li>
                        Hailed for its “practicality, upmarket feel and comfort”
                      </li> */}
                    </ul>
                  </p>
                  {displayShowMore && (
                    <p>
                      <a
                        data-toggle="collapse"
                        href="#tucson"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </div>

              <div className={moreSectionClassName} id="tucson">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      <>
                        Hyundai has been ranked highly in the annual Honest John
                        Satisfaction Index with several models receiving
                        recognition.
                      </>
                      <br />
                      <br />
                      The Hyundai TUCSON (2021 onwards) was ranked as the most
                      satisfying car, while the Hyundai IONIQ (2016 - 2022) came
                      third and the Hyundai KONA Electric (2019 onwards) came
                      fourth. The Hyundai TUCSON (2021 onwards) was also ranked
                      as the second most reliable car.
                      <br />
                      <br />
                      <img
                        src="images/tucsonhonest2.jpeg"
                        className="img-fluid"
                      />
                    </p>
                  </div>
                </div>

                {/* <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      Early in January, the{' '}
                      <a
                        href="https://www.hyundai.news/uk/brand/hyundai-i10-is-what-cars-best-small-car-for-the-city-2021/"
                        target="_blank"
                      >
                        all-new i10 was named What Car?’s Best Small Car for the
                        City,
                      </a>{' '}
                      then the previous generation{' '}
                      <a
                        href="https://www.hyundai.news/uk/model-news/hyundai-scores-double-win-at-what-car-used-car-awards/"
                        target="_blank"
                      >
                        i20 was named as First Car’s Used Car of the Year
                      </a>{' '}
                      as a ‘safe, reliable and easy to drive’ car for new
                      drivers. Continuing Hyundai’s small car wins, carwow named
                      the i10 as its Best Small Car, saying: “it’s a doddle to
                      drive but still roomy enough for four adults and their
                      stuff. It’s big on technology, too.”
                    </p>
                  </div>
                </div> */}

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      <br />
                      The Honest John Satisfaction Index is based on the
                      feedback of over 6,000 car owners who were asked to rate
                      them in several areas including reliability, fuel economy
                      and performance. Owners also ranked their cars for build
                      quality, ease of use, safety and repair costs.
                      <br />
                      <br />
                      <strong>
                        "The Hyundai Tucson is the best car by far in the latest
                        Honest John Satisfaction Index. Hyundai’s bold and
                        sophisticated SUV achieved an outstanding average of
                        96.64% in this year’s Index, with owners praising the
                        Tucson for its performance, practicality and low running
                        costs. The Tucson also achieved one of the highest
                        ratings for build quality, with owners giving the car an
                        average reliability score of 9.96 out of 10. It’s a
                        truly incredible result for a fantastic car."
                      </strong>{' '}
                      - Dan Powell, Senior Editor at HonestJohn.co.uk
                      <br />
                      <br />
                      <strong>
                        "To have TUCSON achieve a first place in the Honest John
                        Satisfaction Index 2022, with IONIQ and KONA Electric
                        not far behind is a true reflection of the complete
                        customer proposition that all Hyundai models offer both
                        in terms of styling and performance but also longer-term
                        reliability, affordability and ease of use.""
                      </strong>{' '}
                      - Ashley Andrew, Managing Director Hyundai Motor UK
                      <br />
                      <br />
                      {/* The IONIQ 5’s compelling credentials see a series of
                      enhancements for the 2023 model year. The introduction of
                      a 77kWh battery pack has seen the potential range extend
                      to up to 315 miles(1) and power increase to 325PS – an
                      11PS rise over the previous 72.6 kWh battery. A new
                      battery heating and conditioning feature is also now
                      standard across the range to enable more consistent
                      charging whilst the E-GMP platform’s standard 800v
                      charging system provides 350kW ultra-fast charger
                      compatibility. Handling and ride comfort have also been
                      upgraded with the addition of smart frequency dampers to
                      improve the response of the front and rear suspension. */}
                      <br />
                      {/* <img src="images/bca3.jpg" className="img-fluid" /> */}
                      <br />
                      <br />
                      {/* Additionally, the release early in January of the UK’s
                      latest car registration figures by the Society of Motor
                      Manufacturers and Traders revealed that Hyundai had
                      recorded its largest ever market share of 5.0% in 2022. */}
                      {/* <img src="images/bca4.jpg" className="img-fluid" /> */}
                      {/* <br />
                      <br /> */}
                      {/* <strong>
                        "The awards from What Car? are particularly pleasing as
                        they illustrate the depth of Hyundai’s current range of
                        vehicles. Our N cars have created a massive impact in a
                        short space of time, and the i20 N is a fantastic
                        example of an exciting performance model that can be
                        comfortably driven on a daily basis, while the SANTA FE
                        offers a fantastic blend of practicality, comfort and
                        electrification for SUV customers."
                      </strong>{' '}
                      - Ashley Andrew, Managing Director of Hyundai Motor UK */}
                      {/* <br />
                      <br /> */}
                      {/* Showcasing the strength of the full model range, a sixth
                      award was presented to Hyundai as the i20 N won ‘Best Hot
                      Hatchback’ of the year.
                      <br />
                      <br />
                      <strong>Richard Ingram said:</strong> “Hyundai has turned
                      the hot hatch market on its head – first with the i30 N
                      and more recently with the smaller but just as riotous i20
                      N. Frenetic, agile and quick, the compact Hyundai will
                      keep up with most sports cars but with the added benefit
                      of five doors and a big boot.”
                      <br />
                      <br />
                      <img src="images/bca5.jpg" className="img-fluid" />
                      <br />
                      <br />
                      Like every driver-focused Hyundai N model, the i20N was
                      born at Namyang, Hyundai Motorsport’s Global Research &
                      Development Centre in South Korea, and honed on the
                      Nürburgring Nordschleife race circuit in Germany.
                      Featuring a potent 204PS turbocharged petrol engine and a
                      mechanical limited-slip differential, the i20N delivers
                      stunning performance and ultra-agile handling on road and
                      track. Pioneering connected and safety technologies
                      enhance every journey. Like every Hyundai model sold in
                      the UK, the i20N provides a five-year unlimited mileage
                      warranty, five annual vehicle health checks and roadside
                      assistance. Pricing begins at £24,995.
                      <br />
                      <br />
                      <strong>
                        Ashley Andrew, Managing Director of Hyundai Motor UK
                        said:
                      </strong>
                      “We are delighted that the Hyundai TUCSON has been
                      presented with the coveted ‘Car of the Year’ award by the
                      panel of experts at Carbuyer. This endorsement is further
                      validation that the latest generation TUCSON remains one
                      of the best SUVs in the UK market, as well as the best
                      hybrid available. We are also thrilled to see the wider
                      Hyundai range recognised across a number of prestigious
                      honours. IONIQ 5 continues to set the standard for
                      electric mobility, whilst the i20 N is recognised as the
                      best hot hatchback yet again.”
                      <br />
                      <br /> */}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
