import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';

export default class playTime2 extends Component {
  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="playTime2" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="playTime2-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/i30n_hero.jpg") ',
              }}
            />
          </div>
        </div>
        <div className="col bg-white border-right border-bottom">
          <div className="row py-3">
            <div className="col-lg-4">
              <div className="mt-3">
                <p className="theme-news-snippet">
                  “i30 N was engineered to be fast, focused and – above all –
                  fun. Game on!”
                </p>
              </div>
              <hr />
              <NewsMedia articleId="playTime2" articleText="Play Time" />
            </div>
            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    Play time
                  </p>
                </div>
              </div>
              <div className="row pt-3 ">
                <div className="col-md-10 offset-md-1 ">
                  <div className="theme-news-body-text">
                    <p data-search-tag="summary">
                      i30 N, the first high-performance model in the N line-up,
                      roared into showrooms in January. The car is an ‘everyday’
                      sports car, just as at home on the daily commute as it is
                      slicing around a race track, but there’s no doubt it has
                      been engineered without compromise to be technologically
                      advanced and highly responsive.
                    </p>
                    {displayShowMore && (
                      <p>
                        <a  
                          data-toggle="collapse"
                          href="#collapsePlayTime"
                          role="button"
                          aria-expanded="false"
                        >
                          More{' '}
                          <span>
                            <i className="fas fa-long-arrow-alt-down" />
                          </span>
                        </a>
                      </p>
                    )}
                    <div className={moreSectionClassName} id="collapsePlayTime">
                      <p>
                        Built with a single-minded focus on the driver, the car
                        has been honed over thousands of miles at the infamous
                        Nürburgring racing circuit, home to Hyundai’s Test
                        Centre and known for being one of the most extreme race
                        tracks on the planet.
                      </p>

                      <p>
                        “The i30 N has been developed for no other purpose than
                        to deliver maximum driving fun in an accessible
                        high-performance package,” said Albert Biermann,
                        Executive Vice President, Performance Development and
                        High Performance Vehicle Division. To achieve this, the
                        car has five drive modes – Eco, Normal, Sport, N and N
                        Custom – which adjust the performance of the engine,
                        dampers, Electronic Stability Control, Electronic
                        Limited Slip Differential, the steering, rev-matching
                        and even the sound, to alter the character of the car.
                      </p>

                      <p>
                        But no matter the mode, i30 N has been engineered to
                        make the driver’s heart beat faster behind the wheel.
                        Hit the throttle and shift through the gears, and the
                        rev-matching function ensures smooth gear changes, while
                        the overboost technology increases the car’s
                        acceleration and torque.{' '}
                      </p>

                      <p>
                        On the track, the driver will be able to make full use
                        of the Launch Control, which monitors engine torque to
                        launch the car as quickly as possible, and the G-Force
                        meter which records the forces during cornering,
                        acceleration and deceleration. The feeling of speed is
                        enhanced even further by the sound from the variable
                        exhaust valve system.
                      </p>
                      <p>
                        Check out the site at{' '}
                        <a  
                          href="http://www.hyundai.co.uk/new-cars/i30n "
                          target="_blank" rel="noopener noreferrer"
                        >
                          www.hyundai.co.uk/new-cars/i30n{' '}
                        </a>
                      </p>
                      <p className="small">15/03/2018</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
