import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Validation from '../utils/Validation';
import Store from 'store';
import LoginForm from '../components/loginForm';
import './NavBarWHN.css';

export default class NavbarWHN extends Component {
  constructor(props) {
    super(props);

    let redirect = '/';

    this.state = {
      inputIsDisabled: false,
      error: false,
      connectionError: false,
      isLoaded: false,
      isLoading: false,
      email: '',
      password: '',
      logInSuccess: false,
      forgottonPasswordEmailValidationMessage: '',
      forgottenPasswordEmail: '',
      forgottenPasswordEmailSent: false,
      redirect: redirect,
      fromFacebook: Store.get('campaign_name') === 'facebook',
      validation: {
        email: Validation.initRequiredValidationState(),
        password: Validation.initRequiredValidationState(),
      },
    };
  }

  render() {
    return (
      <div className="container">
        <nav id="navbar" className="navbar navbar-light bg-white">
          <div className="container">
            <div id="nav-row" className="row">
              <div id="nav-banner" className="col-lg-6 col-sm-12">
                <h5 className="pl-2 pr-2" id="nav-bar-banner">
                  Log in to manage your <b>MyHyundai</b> services, or check out
                  the latest stories from the world of Hyundai below.
                </h5>
              </div>

              <div className="dropdown col-lg-2 col-sm-11 pl-2 pr-1">
                <div className="dropdown">
                  <button
                    className="btn btn-primary btn-block btn-sm removeRadius mt-3"
                    type="button"
                    id="dropdownMenuButtonLogin"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={{ fontSize: 'small', fontWeight: 501 }}
                  >
                    Login to MyHyundai
                  </button>
                  <div
                    id="hyundai-whn-nav-login"
                    className="dropdown-menu hyundai-whn-nav-login"
                    aria-labelledby="dropdownMenuButtonLogin"
                  >
                    <LoginForm {...this.props} />
                  </div>
                </div>
              </div>

              <div className="dropdown col-lg-2 col-sm-11 pl-2 pr-1">
                <div className="dropdown">
                  <button
                    className="btn btn-light btn-block btn-sm removeRadius border border-dark mt-3"
                    type="button"
                    id="dropdownMenuButtonMemberWHN"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={{
                      fontWeight: 501,
                      fontSize: 'small',
                    }}
                  >
                    Not a member?
                  </button>
                  <div
                    id="hyundai-whn-nav-member"
                    className="dropdown-menu col-md-12"
                    aria-labelledby="dropdownMenuButtonMemberWHN"
                  >
                    <Link
                      id="loginRegisterButtonNav"
                      className="btn btn-light removeRadius btn-block btn-sm mr-lg-5 nav-bar-button pl-3"
                      to="/registration"
                      style={{ fontSize: 'small' }}
                    >
                      <b>Register</b>
                    </Link>
                    <Link
                      id="loginActiveButtonNav"
                      className="btn btn-light removeRadius btn-block btn-sm mr-lg-5 nav-bar-button pl-3"
                      to="/activation"
                      style={{ fontSize: 'small' }}
                    >
                      <b>Activate Code</b>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}
