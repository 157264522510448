import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import './login.css';
import Auth from '../utils/Auth';
import GetData from '../utils/GetData';
import Validation from '../utils/Validation';
import InputField from '../components/inputField';
import ValidationMessage from '../components/validationMessage';
import Store from 'store';
import ReactPixel from 'react-facebook-pixel';
import Footer from '../components/footer';
import WHNLogin from '../components/whnLogin';
import HyundaiBanner from '../components/hyundaibanner';
import CookieConsent from 'react-cookie-consent';

export default class Login extends Component {
  constructor(props) {
    super(props);

    let redirect = '/';

    Store.remove('campaign_name');

    if (
      props.location != null &&
      props.location.state != null &&
      props.location.state.from != null &&
      props.location.state.from.pathname != null
    ) {
      // this is used if the user navigates to protected link but has not yet logged in. See PrivateRoute component

      redirect =
        props.location.state.from.pathname + props.location.state.from.search;
    } else if (props.location.search && props.location.search.length > 0) {
      // this is used if the user navigates to protected link but their session has expired. See AuthenticatedComponent component

      const queryParams = new URLSearchParams(props.location.search);

      if (queryParams.has('return')) {
        redirect = queryParams.get('return');
        //redirects to this page after login
      }
      if (queryParams.has('campaign_name')) {
        //Store campaign names
        Store.set('campaign_name', queryParams.get('campaign_name'));
      }
    }

    this.state = {
      inputIsDisabled: false,
      error: false,
      connectionError: false,
      isLoaded: false,
      isLoading: false,
      email: '',
      password: '',
      logInSuccess: false,
      forgottonPasswordEmailValidationMessage: '',
      forgottenPasswordEmail: '',
      forgottenPasswordEmailSent: false,
      redirect: redirect,
      fromFacebook: Store.get('campaign_name') === 'facebook',
      validation: {
        email: Validation.initRequiredValidationState(),
        password: Validation.initRequiredValidationState(),
      },
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleForgottenPasswordEmail =
      this.handleForgottenPasswordEmail.bind(this);
  }

  closeForgottenPassword = () => {
    this.setState({
      forgottenPasswordEmail: '',
      forgottonPasswordEmailValidationMessage: '',
      forgottenPasswordEmailSent: false,
    });
  };

  forgotPasswordLink = () => {
    this.setState({
      inputIsDisabled: false,
    });
  };

  sendPasswordReminder = () => {
    var validationMessage = Validation.validateRequired(
      this.state.forgottenPasswordEmail,
    );

    var validateEmail = Validation.validateEmail(
      this.state.forgottenPasswordEmail,
    );

    if (
      (validationMessage == null || validationMessage.length === 0) &&
      validateEmail == null
    ) {
      this.refs.btn.setAttribute('disabled', 'disabled');

      fetch(
        process.env.REACT_APP_API_URL +
          '/Authentication/ForgottenPassword?email=' +
          this.state.forgottenPasswordEmail +
          '&emailTemplate=f4d63799-d103-45fc-a2f4-2f6458ceaace&brand=HyundaiUk',
      )
        .then((response) => {
          if (response.ok) {
            this.setState({
              forgottenPasswordEmailSent: true,
              inputIsDisabled: true,
            });
          } else {
            this.handleLoginError();
            throw new Error('Error fetching password token');
          }
        })

        .catch((error) => {
          console.log('Error in getting password reminder token ');
        });
    } else {
      this.setState({
        forgottonPasswordEmailValidationMessage:
          validationMessage || validateEmail,
      });
    }
  };

  Login = (e) => {
    e.preventDefault();

    if (!navigator.onLine) {
      this.setState({
        connectionError: true,
        error: false,
      });
    } else {
      this.setState({
        connectionError: false,
        error: false,
      });

      var newState = Validation.validateAll(this.state);

      if (Validation.anyInvalid(newState)) {
        this.setState(newState);
      } else {
        this.setState({
          isLoading: true,
        });

        Auth.authenticate(
          this.state.email,
          this.state.password,
          this.authSuccess,
          this.handleLoginError,
        );
      }
    }
  };

  validateField(name) {
    this.setState((prevState) => {
      return Validation.validate(prevState, name);
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    this.setState(
      {
        [name]: value,
      },
      () => this.validateField(name),
    );
  }

  authSuccess = () => {
    const { fromFacebook, email } = this.state;
    if (fromFacebook) {
      ReactPixel.trackCustom('from facebook login', { email: email });
    }
    GetData.populateModels(this.getDataSuccess, this.getDataFailure);
  };

  getDataSuccess = () => {
    this.setState(
      {
        isLoading: false,
        logInSuccess: true,
      },
      () => {
        this.props.setVehicleInfo();

        if (this.state.fromFacebook) {
          this.props.setFromFacebook();
        }
      },
    );
    //console.log(Store.get('loginToken').replace(/['"']+/g, ''));
  };

  getDataFailure = () => {
    this.setState({
      error: true,
      isLoading: false,
    });
  };

  handleLoginError = () => {
    this.setState({
      error: true,
      isLoading: false,
    });
  };

  handleForgottenPasswordEmail(event) {
    const target = event.target;

    this.setState(
      {
        forgottenPasswordEmail: target.value,
      },
      this.validateForgottenPasswordEmail,
    );
  }

  validateForgottenPasswordEmail() {
    this.setState((prevState) => {
      return {
        forgottonPasswordEmailValidationMessage: Validation.validateRequired(
          prevState.forgottenPasswordEmail,
        ),
      };
    });
  }

  render() {
    const forgottonPasswordEmailClassName =
      this.state.forgottonPasswordEmailValidationMessage == null ||
      this.state.forgottonPasswordEmailValidationMessage.length === 0
        ? 'form-control form-control-sm removeRadius flatInput password-strength'
        : 'form-control form-control-sm removeRadius flatInput password-strength is-invalid';

    const forgottonPasswordEmailValidationMessage =
      this.state.forgottonPasswordEmailValidationMessage == null ||
      this.state.forgottonPasswordEmailValidationMessage.length === 0 ? null : (
        <div className="invalid-feedback">
          <i className="fas fa-exclamation-triangle" />{' '}
          {this.state.forgottonPasswordEmailValidationMessage}
        </div>
      );

    if (this.state.logInSuccess) {
      return <Redirect to={this.state.redirect} />;
    } else {
      return (
        <div>
          <div
            id="loginContainer"
            className="container"
            style={{ backgroundColor: '#F5F5F5' }}
          >
            <div
              className="theme-login-container pb-2"
              style={{ backgroundColor: '#F5F5F5' }}
            >
              <div className="d-flex flex-column flex-sm-row justify-content-between text-center pb-1">
                <div>
                  <img
                    src="images/hyundai_logoF.png"
                    style={{ maxHeight: 25, paddingBottom: '4px' }}
                  />
                </div>
                <div className="p-2 p-sm-0">
                  <i className="fas fa-phone" />{' '}
                  <a href="tel:0800981981" title="Call Us Now">
                    0800 981 981
                  </a>
                </div>
              </div>
              <hr />

              <div className="row">
                <div className="col-lg-12 theme-login-bg-image-mobile"></div>
                <div
                  className="p-sm-4 col-lg-8 col-sm-12"
                  style={{ backgroundColor: 'white' }}
                >
                  <div className="row mx-0">
                    <div className="innerContainer ">
                      <div className="row pt-3" style={{ opacity: 1 }}>
                        <HyundaiBanner />
                        <div className="formContainer col-lg-12 p-3 mt-2 mb-2 ml-0 mr-0">
                          <p className="frmTitleText display-4 d4Fix">
                            Member Login
                          </p>
                          <form>
                            <div className="row">
                              <div
                                className="form-group col-lg-6"
                                id="login-form-group"
                              >
                                <small>
                                  <label htmlFor="loginEmailInput">Email</label>
                                </small>
                                <InputField
                                  type="email"
                                  id="loginEmailInput"
                                  aria-describedby="emailHelp"
                                  name="email"
                                  value={this.state.email}
                                  onChange={this.handleInputChange}
                                  validation={this.state.validation.email}
                                  autoComplete="username email"
                                />
                                <ValidationMessage
                                  validation={this.state.validation.email}
                                />
                              </div>
                              <div
                                className="form-group col-lg-6"
                                id="regiser-form-group"
                              >
                                <div
                                  className="small"
                                  style={{ marginTop: '3px' }}
                                >
                                  <label htmlFor="loginPasswordInput">
                                    Password
                                  </label>
                                  <a
                                    href="#"
                                    tabIndex="3"
                                    className="float-right"
                                    data-toggle="modal"
                                    data-target="#loginForgottenPasswordModal"
                                    onClick={this.forgotPasswordLink}
                                  >
                                    Forgotten password?
                                  </a>
                                </div>
                                <InputField
                                  type="password"
                                  maxLength="25"
                                  id="loginPasswordInput"
                                  name="password"
                                  value={this.state.password}
                                  onChange={this.handleInputChange}
                                  validation={this.state.validation.password}
                                  autoComplete="current-password"
                                />
                                <ValidationMessage
                                  validation={this.state.validation.password}
                                />
                              </div>
                            </div>
                            <p />

                            <button
                              id="loginLoginButton"
                              type="submit"
                              className="btn btn-primary btn-block btn-sm removeRadius col-lg-5"
                              onClick={this.Login.bind(this)}
                            >
                              {this.state.isLoading ? (
                                <div>
                                  <i className="fas fa-spinner fa-spin fa-lg text-white" />
                                </div>
                              ) : (
                                <span>Login to MyHyundai</span>
                              )}
                            </button>
                          </form>

                          {this.state.connectionError && (
                            <div className="text-center text-danger">
                              <p />
                              <small>
                                <i className="fas fa-exclamation-triangle" /> No
                                internet connection available
                              </small>
                            </div>
                          )}
                          {this.state.error && (
                            <div className="text-center text-danger">
                              <p />
                              <small>
                                <i className="fas fa-exclamation-triangle" />{' '}
                                Invalid Username or Password
                              </small>
                            </div>
                          )}
                        </div>

                        <div className="linksContainer">
                          <div
                            className="row p-2 mt-1 mb-1 ml-0 mr-0"
                            id="block-row"
                          >
                            <div
                              className="block p-3 mt-2 mb-2"
                              id="member-block"
                              style={{ backgroundColor: '#F5F5F5' }}
                            >
                              <p className="frmTitleText display-4 d4Fix p-1">
                                Not a member?
                              </p>
                              <small>
                                <p className="mb-4 p-1">
                                  New to MyHyundai? To create your account, just
                                  click 'Register'.
                                </p>
                              </small>
                              <Link
                                id="loginRegisterButton"
                                className="btn btn-light removeRadius btn-block btn-sm border border-dark mr-lg-5 login-page p-1"
                                to="/registration"
                              >
                                Register
                              </Link>
                            </div>

                            <div
                              className="block p-3 mt-2 mb-2"
                              id="activation-block"
                              style={{ backgroundColor: '#F5F5F5' }}
                            >
                              <p className="frmTitleText display-4 d4Fix p-1">
                                Got an activation code?
                              </p>
                              <small>
                                <p className="mb-4 p-1">
                                  If you received an activation code from us,
                                  just click 'Activate'.
                                </p>
                              </small>
                              <Link
                                id="loginActiveButton"
                                className="btn btn-light removeRadius btn-block btn-sm border border-dark mr-lg-5 login-page p-1"
                                to="/activation"
                              >
                                Activate
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-0 theme-login-bg-image"></div>
              </div>
            </div>

            <WHNLogin />
            <Footer />

            {/* The Forgotten Modal  */}
            <div
              className="modal fade"
              id="loginForgottenPasswordModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="loginForgottenPasswordModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5
                      className="modal-title"
                      id="loginForgottenPasswordModalLabel"
                    >
                      Forgotten your password?
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={this.closeForgottenPassword}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <small>
                      {' '}
                      <label htmlFor="loginForgottenPasswordModalEmailInput">
                        No problem. Just enter your registered email address
                        below and we’ll send you a link to reset it.
                      </label>
                    </small>
                    <input
                      type="email"
                      id="loginForgottenPasswordModalEmailInput"
                      aria-describedby="emailHelp"
                      className={forgottonPasswordEmailClassName}
                      value={this.state.forgottenPasswordEmail}
                      onChange={this.handleForgottenPasswordEmail}
                      placeholder="Email"
                      disabled={this.state.inputIsDisabled}
                    />

                    {forgottonPasswordEmailValidationMessage}

                    {this.state.forgottenPasswordEmailSent && (
                      <small>
                        <i className="fas fa-check-circle text-success" />{' '}
                        Password reset email sent to{' '}
                        <strong>{this.state.forgottenPasswordEmail}</strong>
                      </small>
                    )}
                  </div>
                  <div className="modal-footer">
                    {this.state.forgottenPasswordEmailSent && (
                      <button
                        id="loginForgottenPasswordModalCloseButton"
                        type="button"
                        className="btn btn-outline-dark btn-sm removeRadius"
                        data-dismiss="modal"
                        onClick={this.closeForgottenPassword}
                      >
                        Close
                      </button>
                    )}
                    {!this.state.forgottenPasswordEmailSent && (
                      <button
                        id="loginForgottenPasswordModalCancelButton"
                        type="button"
                        className="btn btn-outline-dark btn-sm removeRadius"
                        data-dismiss="modal"
                        onClick={this.closeForgottenPassword}
                      >
                        Cancel
                      </button>
                    )}
                    {!this.state.forgottenPasswordEmailSent && (
                      <button
                        id="loginForgottenPasswordModalSendButton"
                        type="button"
                        className="btn btn-primary btn-sm removeRadius"
                        onClick={this.sendPasswordReminder}
                        ref="btn"
                      >
                        Send reminder
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <CookieConsent
              style={{ background: '#002c5f', fontSize: '13px' }}
              buttonStyle={{ background: '#fff' }}
            >
              This website uses cookies to enhance the user experience.
            </CookieConsent>
          </div>
        </div>
      );
    }
  }
}
