import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import './bottomnavbarWHN.css';
import Store from 'store';
import ReactGA from 'react-ga';
export default class BottomNavBarWHN extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: '',
    };
  }

  UpdateInputValue(inputText) {
    this.setState({
      text: inputText.target.value,
    });
  }

  clickHandler = (category) => {
    ReactGA.event({
      label: 'News Article',
      category: 'WHN',
      action: `News Category Click - ${category}`,
      gaOptions: {
        siteSpeedSampleRate: 100,
        userId: Store.get('customer') && Store.get('customer').urn,
      },
    });
  };

  render() {
    return (
      <div className="container" data-search-exclude>
        <nav className="navbar navbar-expand-lg navbar-light bg-light p-0 dropShadowWHN secondary-nav">
          {/* <div className="slantedDiv" style={{ fontSize: 'small' }}>
            MyHyundai Newsletter
          </div> */}
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto navbarText p-3 p-lg-0">
              <li className="nav-item pr-5">
                <NavLink
                  id="inspiration"
                  className="nav-link whn-nav-item"
                  activeClassName=""
                  to={{
                    pathname: '/newsletter',
                    state: {
                      filteredCategory: 'Inspiration',
                    },
                  }}
                >
                  Inspiration
                </NavLink>
              </li>
              <li className="nav-item pr-5">
                <NavLink
                  id="cars"
                  className="nav-link whn-nav-item"
                  activeClassName=""
                  to={{
                    pathname: '/newsletter',
                    state: {
                      filteredCategory: 'Cars',
                    },
                  }}
                >
                  Cars
                </NavLink>
              </li>

              <li className="nav-item pr-5">
                <NavLink
                  id="innovation"
                  className="nav-link whn-nav-item"
                  activeClassName=""
                  to={{
                    pathname: '/newsletter',
                    state: {
                      filteredCategory: 'Innovation',
                    },
                  }}
                >
                  Innovation
                </NavLink>
              </li>

              <li className="nav-item pr-5">
                <NavLink
                  id="culture"
                  className="nav-link whn-nav-item"
                  activeClassName=""
                  to={{
                    pathname: '/newsletter',
                    state: {
                      filteredCategory: 'Culture',
                    },
                  }}
                >
                  Culture
                </NavLink>
              </li>

              <li className="nav-item">
                <div className="form-inline d-block">
                  <form
                    className="input-group"
                    id="article-search-bar-nav-item"
                  >
                    <div className="input-group-prepend ">
                      <span
                        className="input-group-text bottomNavPrepend"
                        id="basic-addon1"
                      >
                        <i className="fas fa-fw  fa-search" />
                      </span>
                    </div>
                    <input
                      id="whn-input"
                      type="text"
                      className="form-control bottomNavPrependInput"
                      placeholder="Search articles..."
                      aria-label="Search"
                      aria-describedby="basic-addon1"
                      value={this.state.text}
                      onChange={(text) => this.UpdateInputValue(text)}
                      style={{ fontSize: '0.9rem' }}
                    />
                    <NavLink
                      to={{
                        pathname: '/newsletter',
                        state: {
                          filteredText: this.state.text,
                        },
                      }}
                      id="whn-input-button"
                      className="btn btn-primary btn-small "
                      type="button"
                      style={{
                        verticalAlign: 'center',
                        lineHeight: '1.7',
                        backgroundColor: 'none',
                      }}
                    >
                      Go
                    </NavLink>
                  </form>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}
