import React, { Component } from 'react';
import Store from 'store';
import SaveData from '../../utils/SaveData';
import Validation from '../../utils/Validation';
import SelectField from '../selectField';
import InputField from '../inputField';
import ValidationMessage from '../validationMessage';
import { NameAddress } from '../Forms/NameAddress';

export default class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newPasswordInputType: 'password',
      oldPasswordInputType: 'password',
      title: '',
      address: '',
      firstName: '',
      postCode: '',
      surname: '',
      email: '',
      houseName: '',
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      addressLine4: '',
      addressLine5: '',
      confirmPassword: '',
      password: '',
      homePhone: '',
      mobilePhone: '',
      workPhone: '',
      loginToken: Store.get('loginToken').replace(/['"']+/g, ''),
      saveSuccess: false,
      saveFailed: false,
      savePasswordSuccess: false,
      savePasswordFailed: false,
      isLoading: false,
      loqate: '',
      loqateSelect: [],
      loqateDisabled: true,
      loqateIsLoading: false,
      showLoqateDropDown: false,
      loqateArrowDown: '',
      validation: {
        title: Validation.initValidationState(['required']),
        firstName: Validation.initValidationState(['required']),
        surname: Validation.initValidationState(['required']),
        addressLine1: Validation.initValidationState(['required']),
        postCode: Validation.initValidationState(['required']),
        email: Validation.initValidationState(['required', 'email']),
      },
      validationMobilePhone: null,
      validationHomePhone: null,
      validationWorkPhone: null,
      formInValid: null,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    const customer = Store.get('customerInfo');

    if (customer) {
      let addressArray = customer.address.split('\n');

      this.setState({
        title: customer.title,
        address: addressArray,
        firstName: customer.forenames,
        postCode: customer.postCode,
        surname: customer.surname,
        previousEmail: customer.email,
        email: customer.email,
        mobilePhone: customer.mobilePhone,
        homePhone: customer.homePhone,
        workPhone: customer.workPhone,
        houseName: customer.houseNameNumber,
        addressLine1: customer.addressLine1,
        addressLine2: customer.addressLine2,
        addressLine3: customer.addressLine3,
        addressLine4: customer.addressLine4,
        addressLine5: customer.addressLine5,

        saveSuccess: false,
        saveFailed: false,
        savePasswordSuccess: false,
        savePasswordFailed: false,
        initialState: {
          mobilePhone: customer.mobilePhone,
          homePhone: customer.homePhone,
          workPhone: customer.workPhone,
        },
      });
    }
  }

  validatePhoneDynamic = (value, name) => {
    //if a phone number exists on initial load we stop the user from saving a null or empty number

    const initialValue = this.state.initialState[name];
    const isValid = /^(?:0|\+?44 ?)(?:\d\s?){9,10}$/.test(value);

    if (isValid || value === initialValue) {
      return null;
    }
    if (!value && initialValue) {
      return ' Alternate number required';
    }
    if (!isValid) {
      return ' Invalid Number';
    }
  };

  validateField = (name) => {
    this.setState((prevState) => {
      return Validation.validate(prevState, name);
    });
  };

  handlePhoneChange = (event) => {
    const target = event.target;
    const name = target.name.replace(/([A-Z])/g, '$1');
    var nameTitle = name.charAt(0).toUpperCase() + name.slice(1);

    const value = target.value;
    const propName = 'validation' + [nameTitle];
    var validationMessage = this.validatePhoneDynamic(value, name);

    this.setState({
      [propName]: validationMessage,
      [name]: value,
      saveSuccess: false,
    });
  };

  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type ==='checkbox' ? target.checked : target.value;
    this.setState(
      {
        [name]: value,
        saveSuccess: false,
      },
      () => this.validateField(name),
    );
  };

  handleValidationChange = (validation) => {
    validation.forEach((val) => {
      this.setState((prevState) => {
        return {
          validation: {
            ...prevState.validation,
            [val]: Validation.initValidationState(['required']),
          },
        };
      });
    });
  };

  saveCustomerDetails = (e) => {
    var newState = Validation.validateAll(this.state);

    this.setState({ saveFailed: false });

    if (
      Validation.anyInvalid(newState) ||
      this.state.validationMobilePhone ||
      this.state.validationHomePhone ||
      this.state.validationWorkPhone
    ) {
      this.setState(newState);
    } else {
      this.setState({
        isLoading: true,
      });

      e.preventDefault();

      const customer = Store.get('customerInfo');

      customer.title = this.state.title;
      customer.houseNameNumber = this.state.houseName;
      customer.forenames = this.state.firstName;
      customer.addressLine1 = this.state.addressLine1;
      customer.surname = this.state.surname;
      customer.addressLine2 = this.state.addressLine2;
      customer.addressLine4 = this.state.addressLine4;
      customer.email = this.state.email;
      customer.addressLine5 = this.state.addressLine5;
      customer.mobilePhone = this.state.mobilePhone;
      customer.homePhone = this.state.homePhone;
      customer.workPhone = this.state.workPhone;
      customer.postCode = this.state.postCode;

      const me = this;

      this.setState({
        loqateDisabled: true,
        saveSuccess: false,
      });

      SaveData.saveCustomer(
        JSON.stringify(customer),
        () => {
          Store.set('customerInfo', customer);
          me.setState({
            saveSuccess: true,
            isLoading: false,
          });
        },
        () => {
          me.setState({
            saveFailed: true,
            isLoading: false,
          });
        },
      );
    }
  };

  handleStateChange = (state, callback) => {
    this.setState(state, () => {
      callback ? callback() : null;
    });
  };

  render = () => {
    const formInValid =
      Validation.anyInvalid(this.state) ||
      this.state.validationMobilePhone ||
      this.state.validationHomePhone ||
      this.state.validationWorkPhone;

    return (
      <form autoComplete="off">
        <div className="row">
          <div className="col-md-12">
            {/* ========================== Name / Address ========================== */}

            <NameAddress
              // Handlers
              handleInputChange={this.handleInputChange}
              handleValidationChange={this.handleValidationChange}
              handleStateChange={this.handleStateChange}
              // Name Data
              title={this.state.title}
              firstName={this.state.firstName}
              surname={this.state.surname}
              // Address Data
              houseName={this.state.houseName}
              addressLine1={this.state.addressLine1}
              addressLine2={this.state.addressLine2}
              addressLine4={this.state.addressLine4}
              addressLine5={this.state.addressLine5}
              postCode={this.state.postCode}
              // Visibility
              titleVisibility={true}
              firstNameVisibility={true}
              surnameVisibility={true}
              houseNameVisibility={true}
              addressLine1Visibility={true}
              addressLine2Visibility={true}
              addressLine4Visibility={true}
              addressLine5Visibility={true}
              postCodeVisibility={true}
              // Validation
              titleValidation={this.state.validation.title}
              firstNameValidation={this.state.validation.firstName}
              surnameValidation={this.state.validation.surname}
              addressLine1Validation={this.state.validation.addressLine1}
              postCodeValidation={this.state.validation.postCode}
            />

            <div className="row mb-5">
              <div className="col-md-4">
                {/* ========================== Mobile Phone ========================== */}
                <div className="form-group">
                  <small>
                    <label htmlFor="inpMobPhone">Mobile Phone</label>
                  </small>
                  <InputField
                    className={
                      'form-control form-control-sm flatInput ' +
                      (this.state.validationMobilePhone ? 'is-invalid' : '')
                    }
                    type="tel"
                    name="mobilePhone"
                    id="inpMobPhone"
                    value={this.state.mobilePhone}
                    onChange={this.handlePhoneChange}
                  />

                  <div className="invalid-feedback">
                    <i className="fas fa-exclamation-triangle" />
                    {this.state.validationMobilePhone}
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                {/* ========================== Home Phone ========================== */}
                <div className="form-group">
                  <small>
                    <label htmlFor="inpHomePhone">Home Phone</label>
                  </small>

                  <InputField
                    className={
                      'form-control form-control-sm flatInput ' +
                      (this.state.validationHomePhone ? 'is-invalid' : '')
                    }
                    type="tel"
                    name="homePhone"
                    id="inpHomePhone"
                    value={this.state.homePhone}
                    onChange={this.handlePhoneChange}
                  />
                  <div className="invalid-feedback">
                    <i className="fas fa-exclamation-triangle" />
                    {this.state.validationHomePhone}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                {/* ========================== Work Phone ========================== */}
                <div className="form-group">
                  <small>
                    <label htmlFor="inpWorkPhone">Work Phone</label>
                  </small>
                  <InputField
                    className={
                      'form-control form-control-sm flatInput ' +
                      (this.state.validationWorkPhone ? 'is-invalid' : '')
                    }
                    type="tel"
                    name="workPhone"
                    id="inpWorkPhone"
                    value={this.state.workPhone}
                    onChange={this.handlePhoneChange}
                  />
                  <div className="invalid-feedback">
                    <i className="fas fa-exclamation-triangle" />
                    {this.state.validationWorkPhone}
                  </div>
                </div>
              </div>
            </div>
            {/* =========================== Email ============================ */}
            <div className="form-group">
              <small>
                <label htmlFor="inpEmail">
                  Email *
                  <span className="vSmallText">
                    {' '}
                    (This will be your user name for login)
                  </span>
                </label>
              </small>
              <InputField
                name="email"
                type="email"
                id="inpEmail"
                value={this.state.email}
                onChange={this.handleInputChange}
                validation={this.state.validation.email}
              />
              <ValidationMessage validation={this.state.validation.email} />
            </div>
          </div>
        </div>

        {/* ============================== Save Details ============================== */}
        <div className="row" />

        <div className="row mt-5">
          <div className="form-group col-md-6" />
          <div className="form-group col-md-6">
            {formInValid && (
              <div className="text-left text-danger py-3">
                <small>
                  <i className="fas fa-exclamation-triangle" /> Please correct
                  the errors above before continuing.
                </small>
              </div>
            )}
            <button
              type="button"
              className="btn btn-block btn-primary btn-sm float-right removeRadius"
              onClick={this.saveCustomerDetails.bind(this)}
            >
              {this.state.isLoading ? (
                <div>
                  <i className="fas fa-spinner fa-spin fa-lg text-white" />
                </div>
              ) : (
                <span>Save details</span>
              )}
            </button>
            <div className="clearfix mb-2" />
            {this.state.saveSuccess && (
              <div className="text-center text-success">
                <p>
                  <small>
                    <i className="fas fa-check-circle" /> Changes have been
                    saved
                  </small>{' '}
                </p>
              </div>
            )}
            {this.state.saveFailed && (
              <div className="text-center text-danger">
                <p />
                <small>
                  <i className="fas fa-exclamation-triangle" /> Error in saving
                  changes
                </small>
              </div>
            )}
          </div>
          <div className="col-12">
            <p className="mandatory">
              <small>Fields marked with a * are mandatory</small>
            </p>
          </div>
        </div>
      </form>
    );
  };
}
