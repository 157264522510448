import React, { Component } from 'react';
import './contactPref.css';
import FooterNavbar from '../components/footerNavbar';
import Topnavbar from '../components/topnavbar';
import Bottomnavbar from '../components/bottomnavbar';
import Footer from '../components/footer';
import OffersSideNav from '../components/offersSideNav';

export default class OffersHybrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
      show6: false,
      show7: false,
      show8: false,
      show9: false,
      show10: false,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Topnavbar />
        <Bottomnavbar />

        <div className="container">
          {/* <ProfileNav /> */}

          <section
            className="section1 wow fadeIn"
            data-wow-duration="2s"
            data-wow-delay="0.2s"
          >
            <div className="content pb-5  p-md-5 col-md-10 offset-md-1 border-right border-bottom">
              <div className="row">
                <div className="col-md-3 mt-4 border-md-right">
                  <OffersSideNav />
                </div>

                <div className="col-md-9    p-md-4  contact-perf-content">
                  {/* <h4 className="h4ServHistory" data-search-tag="title">
                    Let it snow! Our Winter Visibility Service is only £29.99*
                  </h4>

                  <hr /> */}
                  <div>
                    <img
                      className="card-img-top img-fluid"
                      src="images/hev_event_header_.jpg"
                    />
                  </div>
                  <br />
                  <p className="remindersBlue" data-search-tag="summary">
                    <span style={{ fontSize: '1.5rem' }}>
                      Smarter driving made easy
                    </span>
                    <br />
                    <br />
                    Now is the perfect time to drive smarter with your next
                    hybrid car from Hyundai.
                    <br />
                    <br />
                    Discover the car which fits you and helps reduce your
                    running costs and CO2 emissions.
                    <br />
                    <br />
                    The Hyundai Hybrid Event has simple to understand offers
                    across a full range of hybrid cars, all featuring the latest
                    technology and safety features.
                    <br />
                    <br />
                    Don’t know your mild-hybrids from your plug-ins? Fear not!
                    Our simple guide is the perfect place to start and your
                    local dealer is standing by to help you discover the
                    electrified car that’s right for you.
                    <br />
                    <br />
                    Each and every one comes complete with a five-year unlimited
                    mileage warranty‡.
                    <br />
                    <br />
                    So which one will you choose?
                  </p>

                  <div className="row">
                    <div
                      className="col-md-5 offset-md-1"
                      style={{
                        backgroundColor: 'whitesmoke',
                        fontWeight: 'bold',
                      }}
                    >
                      <p
                        style={{
                          color: '#40aad2',
                          fontWeight: 'bolder',
                          fontSize: '1.2rem',
                          marginTop: '20px',
                        }}
                      >
                        New SANTA FE Plug in
                      </p>
                      <img
                        src="images/santa-fe-phev.png"
                        className="img-fluid"
                      />
                      <p style={{ marginTop: '20px' }}>
                        4.8% APR Representative and £1,000 towards your deposit^
                      </p>
                      <p>
                        Save an additional £3,000 when you order before the end
                        of June*
                      </p>
                      <p
                        style={{
                          fontWeight: 'normal',
                          textDecoration: 'underline',
                        }}
                      >
                        <span
                          onClick={() =>
                            this.setState({ show1: !this.state.show1 })
                          }
                        >
                          {this.state.show1 ? 'Read less' : 'Read more'}
                        </span>
                      </p>
                      {this.state.show1 && (
                        <div style={{ fontWeight: 'normal' }}>
                          SANTA FE’s bold design delivers a powerful first
                          impression. And inside, you’ll discover its gentler
                          side, with premium soft-touch materials throughout the
                          spacious, well-appointed cabin.
                          <br />
                          <br />
                        </div>
                      )}
                      <p>
                        <a href="https://try.hyundai.co.uk/" target="_blank">
                          <button className="btn btn-sm removeRadius btn-primary">
                            Claim offer
                          </button>
                        </a>
                      </p>
                    </div>
                    <div className="col-md-1"></div>
                    <div
                      className="col-md-5"
                      style={{
                        backgroundColor: 'whitesmoke',
                        fontWeight: 'bold',
                      }}
                    >
                      <p
                        style={{
                          color: '#40aad2',
                          fontWeight: 'bolder',
                          fontSize: '1.2rem',
                          marginTop: '20px',
                        }}
                      >
                        New SANTA FE Hybrid
                      </p>
                      <img
                        src="images/santa-fe-hev.png"
                        className="img-fluid"
                      />
                      <p style={{ marginTop: '20px' }}>
                        4.8% APR Representative and £1,000 towards your deposit^
                      </p>
                      <p>
                        Save an additional £3,000 when you order before the end
                        of June*
                      </p>
                      <p
                        style={{
                          fontWeight: 'normal',
                          textDecoration: 'underline',
                        }}
                      >
                        <span
                          onClick={() =>
                            this.setState({ show2: !this.state.show2 })
                          }
                        >
                          {this.state.show2 ? 'Read less' : 'Read more'}
                        </span>
                      </p>
                      {this.state.show2 && (
                        <div style={{ fontWeight: 'normal' }}>
                          SANTA FE’s bold design delivers a powerful first
                          impression. And inside, you’ll discover its gentler
                          side, with premium soft-touch materials throughout the
                          spacious, well-appointed cabin
                          <br />
                          <br />
                        </div>
                      )}
                      <p>
                        <a href="https://try.hyundai.co.uk/" target="_blank">
                          <button className="btn btn-sm removeRadius btn-primary">
                            Claim offer
                          </button>
                        </a>
                      </p>
                    </div>
                  </div>
                  <br />
                  <br />
                  <div className="row">
                    <div
                      className="col-md-5 offset-md-1"
                      style={{
                        backgroundColor: 'whitesmoke',
                        fontWeight: 'bold',
                      }}
                    >
                      <p
                        style={{
                          color: '#40aad2',
                          fontWeight: 'bolder',
                          fontSize: '1.2rem',
                          marginTop: '20px',
                        }}
                      >
                        All-new TUCSON Hybrid
                      </p>
                      <img
                        src="images/all-new-tucson.png"
                        className="img-fluid"
                      />
                      <p style={{ marginTop: '20px' }}>
                        4.8% APR Representative and £2,000 towards your deposit^
                      </p>
                      <p>
                        Save an additional £750 when you order before the end of
                        June*
                      </p>
                      <p
                        style={{
                          fontWeight: 'normal',
                          textDecoration: 'underline',
                        }}
                      >
                        <span
                          onClick={() =>
                            this.setState({ show3: !this.state.show3 })
                          }
                        >
                          {this.state.show3 ? 'Read less' : 'Read more'}
                        </span>
                      </p>
                      {this.state.show3 && (
                        <div style={{ fontWeight: 'normal' }}>
                          The All-new TUCSON is not simply an evolution of its
                          predecessor, but a full-blown design revolution.
                          Leading the way in progressive design, it also offers
                          state-of-the-art tech and the most electrified
                          powertrain line-up in its segment.
                          <br />
                          <br />
                        </div>
                      )}
                      <p>
                        <a href="https://try.hyundai.co.uk/" target="_blank">
                          <button className="btn btn-sm removeRadius btn-primary">
                            Claim offer
                          </button>
                        </a>
                      </p>
                    </div>
                    <div className="col-lg-1"></div>
                    <div
                      className="col-md-5"
                      style={{
                        backgroundColor: 'whitesmoke',
                        fontWeight: 'bold',
                      }}
                    >
                      <p
                        style={{
                          color: '#40aad2',
                          fontWeight: 'bolder',
                          fontSize: '1.2rem',
                          marginTop: '20px',
                        }}
                      >
                        All-new TUCSON Plug In
                      </p>
                      <img
                        src="images/all-new-tucson-phev.png"
                        className="img-fluid"
                      />
                      <p style={{ marginTop: '20px' }}>
                        4.8% APR Representative and £2,000 towards your deposit^
                      </p>
                      <p>
                        Save an additional £750 when you order before the end of
                        June*
                      </p>
                      <p
                        style={{
                          fontWeight: 'normal',
                          textDecoration: 'underline',
                        }}
                      >
                        <span
                          onClick={() =>
                            this.setState({ show4: !this.state.show4 })
                          }
                        >
                          {this.state.show4 ? 'Read less' : 'Read more'}
                        </span>
                      </p>
                      {this.state.show4 && (
                        <div style={{ fontWeight: 'normal' }}>
                          The All-new TUCSON is not simply an evolution of its
                          predecessor, but a full-blown design revolution.
                          Leading the way in progressive design, it also offers
                          state-of-the-art tech and the most electrified
                          powertrain line-up in its segment.
                          <br />
                          <br />
                        </div>
                      )}
                      <p>
                        <a href="https://try.hyundai.co.uk/" target="_blank">
                          <button className="btn btn-sm removeRadius btn-primary">
                            Claim offer
                          </button>
                        </a>
                      </p>
                    </div>
                  </div>
                  <br />
                  <br />
                  <div className="row">
                    <div
                      className="col-md-5 offset-md-1"
                      style={{
                        backgroundColor: 'whitesmoke',
                        fontWeight: 'bold',
                      }}
                    >
                      <p
                        style={{
                          color: '#40aad2',
                          fontWeight: 'bolder',
                          fontSize: '1.2rem',
                          marginTop: '20px',
                        }}
                      >
                        New KONA Hybrid
                      </p>
                      <img
                        src="images/new-kona-hybrid.png"
                        className="img-fluid"
                      />
                      <p style={{ marginTop: '20px' }}>
                        4.8% APR Representative and £1,250 towards your deposit^
                      </p>
                      <p>
                        Save an additional £750 when you order before the end of
                        June*
                      </p>
                      <p
                        style={{
                          fontWeight: 'normal',
                          textDecoration: 'underline',
                        }}
                      >
                        <span
                          onClick={() =>
                            this.setState({ show5: !this.state.show5 })
                          }
                        >
                          {this.state.show5 ? 'Read less' : 'Read more'}
                        </span>
                      </p>
                      {this.state.show5 && (
                        <div style={{ fontWeight: 'normal' }}>
                          Head-turning design, cutting-edge technology and next
                          level efficiency – the New KONA Hybrid builds on the
                          successes of its award-winning processor to make
                          hybrid driving look as good as it feels.
                          <br />
                          <br />
                        </div>
                      )}
                      <p>
                        <a href="https://try.hyundai.co.uk/" target="_blank">
                          <button className="btn btn-sm removeRadius btn-primary">
                            Claim offer
                          </button>
                        </a>
                      </p>
                    </div>
                    <div className="col-lg-1"></div>
                    <div
                      className="col-md-5"
                      style={{
                        backgroundColor: 'whitesmoke',
                        fontWeight: 'bold',
                      }}
                    >
                      <p
                        style={{
                          color: '#40aad2',
                          fontWeight: 'bolder',
                          fontSize: '1.2rem',
                          marginTop: '20px',
                        }}
                      >
                        New KONA
                      </p>
                      <img src="images/new-kona.png" className="img-fluid" />
                      <p style={{ marginTop: '20px' }}>
                        4.8% APR Representative and £1,250 towards your deposit^
                      </p>
                      <p>
                        Save an additional £750 when you order before the end of
                        June*
                      </p>
                      <p
                        style={{
                          fontWeight: 'normal',
                          textDecoration: 'underline',
                        }}
                      >
                        <span
                          onClick={() =>
                            this.setState({ show6: !this.state.show6 })
                          }
                        >
                          {this.state.show6 ? 'Read less' : 'Read more'}
                        </span>
                      </p>
                      {this.state.show6 && (
                        <div style={{ fontWeight: 'normal' }}>
                          The new KONA is taking the award-winning sculpted
                          lines and stylish edge of its predecessor to the next
                          level with new design enhancements and cutting-edge
                          tech, connectivity and safety features.
                          <br />
                          <br />
                        </div>
                      )}
                      <p>
                        <a href="https://try.hyundai.co.uk/" target="_blank">
                          <button className="btn btn-sm removeRadius btn-primary">
                            Claim offer
                          </button>
                        </a>
                      </p>
                    </div>
                  </div>
                  <br />
                  <br />
                  <div className="row">
                    <div
                      className="col-md-5 offset-md-1"
                      style={{
                        backgroundColor: 'whitesmoke',
                        fontWeight: 'bold',
                      }}
                    >
                      <p
                        style={{
                          color: '#40aad2',
                          fontWeight: 'bolder',
                          fontSize: '1.2rem',
                          marginTop: '20px',
                        }}
                      >
                        IONIQ Plug In
                      </p>
                      <img
                        src="images/ioniq-plug-in-hybrid.png"
                        className="img-fluid"
                      />
                      <p style={{ marginTop: '20px' }}>
                        4.8% APR Representative and £1,750 towards your deposit^
                      </p>
                      <p>
                        Save an additional £1,000 when you order before the end
                        of June*
                      </p>
                      <p
                        style={{
                          fontWeight: 'normal',
                          textDecoration: 'underline',
                        }}
                      >
                        <span
                          onClick={() =>
                            this.setState({ show7: !this.state.show7 })
                          }
                        >
                          {this.state.show7 ? 'Read less' : 'Read more'}
                        </span>
                      </p>
                      {this.state.show7 && (
                        <div style={{ fontWeight: 'normal' }}>
                          IONIQ Plug In Hybrid offers the combined output of a
                          1.6 litreengine and electric motor. In pure electric
                          mode, IONIQ Plug-in Hybrid can travel up to 39 miles
                          with a total range of 660 miles with a full tank of
                          petrol.
                          <br />
                          <br />
                        </div>
                      )}
                      <p>
                        <a href="https://try.hyundai.co.uk/" target="_blank">
                          <button className="btn btn-sm removeRadius btn-primary">
                            Claim offer
                          </button>
                        </a>
                      </p>
                    </div>
                    <div className="col-lg-1"></div>
                    <div
                      className="col-md-5"
                      style={{
                        backgroundColor: 'whitesmoke',
                        fontWeight: 'bold',
                      }}
                    >
                      <p
                        style={{
                          color: '#40aad2',
                          fontWeight: 'bolder',
                          fontSize: '1.2rem',
                          marginTop: '20px',
                        }}
                      >
                        IONIQ Hybrid
                      </p>
                      <img
                        src="images/ioniq-hybrid.png"
                        className="img-fluid"
                      />
                      <p style={{ marginTop: '20px' }}>
                        4.8% APR Representative and £1,750 towards your deposit^
                      </p>
                      <p>
                        Save an additional £1,000 when you order before the end
                        of June*
                      </p>
                      <p
                        style={{
                          fontWeight: 'normal',
                          textDecoration: 'underline',
                        }}
                      >
                        <span
                          onClick={() =>
                            this.setState({ show8: !this.state.show8 })
                          }
                        >
                          {this.state.show8 ? 'Read less' : 'Read more'}
                        </span>
                      </p>
                      {this.state.show8 && (
                        <div style={{ fontWeight: 'normal' }}>
                          IONIQ Hybrid delivers everything you expect from a
                          conventional car, but with all the environmental and
                          economic benefits that come with electrified driving.
                          <br />
                          <br />
                        </div>
                      )}
                      <p>
                        <a href="https://try.hyundai.co.uk/" target="_blank">
                          <button className="btn btn-sm removeRadius btn-primary">
                            Claim offer
                          </button>
                        </a>
                      </p>
                    </div>
                  </div>
                  <br />
                  <br />
                  <div className="row">
                    <div
                      className="col-md-5 offset-md-1"
                      style={{
                        backgroundColor: 'whitesmoke',
                        fontWeight: 'bold',
                      }}
                    >
                      <p
                        style={{
                          color: '#40aad2',
                          fontWeight: 'bolder',
                          fontSize: '1.2rem',
                          marginTop: '20px',
                        }}
                      >
                        New i30
                      </p>
                      <img src="images/new-i30.png" className="img-fluid" />
                      <p style={{ marginTop: '20px' }}>
                        4.8% APR Representative and £1,500 towards your deposit^
                      </p>
                      <p>
                        Save an additional £750 when you order before the end of
                        June*
                      </p>
                      <p
                        style={{
                          fontWeight: 'normal',
                          textDecoration: 'underline',
                        }}
                      >
                        <span
                          onClick={() =>
                            this.setState({ show9: !this.state.show9 })
                          }
                        >
                          {this.state.show9 ? 'Read less' : 'Read more'}
                        </span>
                      </p>
                      {this.state.show9 && (
                        <div style={{ fontWeight: 'normal' }}>
                          The New i30 delivers on all fronts: A bold new design,
                          best-in-class safety features, seamless connectivity,
                          and a 48V mild hybrid option.
                          <br />
                          <br />
                        </div>
                      )}
                      <p>
                        <a href="https://try.hyundai.co.uk/" target="_blank">
                          <button className="btn btn-sm removeRadius btn-primary">
                            Claim offer
                          </button>
                        </a>
                      </p>
                    </div>
                    <div className="col-lg-1"></div>
                    <div
                      className="col-md-5"
                      style={{
                        backgroundColor: 'whitesmoke',
                        fontWeight: 'bold',
                      }}
                    >
                      <p
                        style={{
                          color: '#40aad2',
                          fontWeight: 'bolder',
                          fontSize: '1.2rem',
                          marginTop: '20px',
                        }}
                      >
                        All-new i20
                      </p>
                      <img src="images/all-new-i20.png" className="img-fluid" />
                      <p style={{ marginTop: '20px' }}>
                        4.8% APR Representative and £1,500 towards your deposit^
                      </p>
                      <p>
                        Save an additional £750 when you order before the end of
                        June*
                      </p>
                      <p
                        style={{
                          fontWeight: 'normal',
                          textDecoration: 'underline',
                        }}
                      >
                        <span
                          onClick={() =>
                            this.setState({ show10: !this.state.show10 })
                          }
                        >
                          {this.state.show10 ? 'Read less' : 'Read more'}
                        </span>
                      </p>
                      {this.state.show10 && (
                        <div style={{ fontWeight: 'normal' }}>
                          Dynamic and distinctive, the Hyundai All-new i20 is
                          setting new standards in the small car segment with
                          its smart tech, connectivity and best-in-class safety.
                          <br />
                          <br />
                        </div>
                      )}
                      <p>
                        <a href="https://try.hyundai.co.uk/" target="_blank">
                          <button className="btn btn-sm removeRadius btn-primary">
                            Claim offer
                          </button>
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <FooterNavbar />
        </div>

        <Footer />
      </div>
    );
  }
}
