import React, { Component } from "react";

export default class RallyeSanremo extends Component {
  render() {
    return (
      <div className="container">
        <div className="fadeInDownBigFull">
          <div className="card-body newsPadding">
            <div className="card-deck " id="RallyeSanremo">
              <div className="card">
                <div className="row">
                  <div className="col-md">
                    <img
                      id="RallyeSanremo-gallery-1"
                      className="card-img-top img-fluid news-gallary-image active"
                      src="images/rallyeSanremo.jpg"
                      alt="Card image cap"
                    />
                  </div>
                </div>

                <div className="card-block">
                  <div className="card-content">
                    <div className="col">
                      <div className="row whiteBG">
                        <div className="col-lg-4">
                          <div className="salesText mt-3">
                            <p className="theme-news-snippet">
                              “The event will form part of Hayden’s preparations
                              for the Tour de Corse, as he continues to improve
                              his consistency on tarmac”
                            </p>
                          </div>
                          <hr />
                        </div>

                        <div className="col-lg-8 border-lg-left">
                          <div className="row pt-3">
                            <div className="col-md-10 offset-md-1">
                              <p className="salesText" data-search-tag="title">
                                HAYDEN PADDON TO RUN RALLYE SANREMO WITH NEW
                                GENERATION i20 R5
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <div className="theme-news-body-text">
                                <ul>
                                  <li>
                                    Hyundai Motorsport World Rally Championship
                                    (WRC) driver Hayden Paddon will contest
                                    Rallye Sanremo in a New Generation i20 R5
                                  </li>
                                  <li>
                                    The event will form part of Hayden’s
                                    preparations for the Tour de Corse, as he
                                    continues to improve his consistency on
                                    tarmac
                                  </li>
                                  <li>
                                    He will be joined by co-driver Seb Marshall
                                    who will be his full time co-driver after
                                    Rally Finland.
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div className="row pt-3">
                            <div className="col-md-10 offset-md-1">
                              <p
                                className="theme-news-body-text"
                                data-search-tag="summary"
                              >
                                <strong>March 15, 2017 –</strong> Hayden Paddon
                                will drive a New Generation i20 R5 on the Rallye
                                Sanremo later this month (March 29-April 1).
                                This will be his first taste at the wheel of
                                Hyundai Motorsport’s latest R5 design.
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <div className="theme-news-body-text">
                                <p>
                                  The event, the second round of the premier
                                  Italian national championship, will form part
                                  of Paddon’s preparations for the Tour de
                                  Corse, the first pure tarmac rally of this
                                  year’s WRC season. Prior to that, the Kiwi
                                  will complete his pre-event testing in the
                                  Hyundai i20 Coupe WRC.
                                </p>
                                <p>
                                  The event will offer Paddon the opportunity
                                  for more time behind the wheel on tarmac,
                                  furthering his continuous preparation and
                                  improvement plan on the surface. Much of the
                                  177km of special stages will take place on a
                                  similar type of road to those which make up
                                  the WRC stages on the French island.
                                </p>
                                <p>
                                  He will be joined on the event by co-driver
                                  Seb Marshall, who has more experience with the
                                  New Generation i20 R5, having worked with the
                                  Customer Racing department throughout the
                                  development phase of the project last year. It
                                  was announced recently that Hayden and Seb
                                  will work together in WRC after Rally Finland
                                  and Rallye Sanremo will be a good way to train
                                  the crew in real rally conditions.
                                </p>
                                <p>
                                  As well as the WRC pair, the crew of Chewon
                                  Lim and Martijn Wydaeghe will also enter the
                                  event. The rally will be their third event in
                                  less than a month at the start of a busy year
                                  for the pairing as part of the Hyundai
                                  Motorsport Driver Development Program.
                                </p>
                                <p>
                                  Paddon said: “It’ll be my first time at Rallye
                                  Sanremo and I’m really looking forward to it.
                                  Competing the week before the Tour de Corse
                                  should be the perfect warmup for that event.
                                  It will be a good opportunity to get some more
                                  mileage on tarmac on very twisty and demanding
                                  roads that are similar to Corsica. The Italian
                                  drivers who know the stages will be very fast,
                                  while it’ll be our first time there so we’re
                                  not focusing on results, but instead
                                  concentrating on development and improvement
                                  for the future. The whole purpose of the rally
                                  is just to get more time behind the wheel and
                                  to get more comfortable on tarmac. The speed
                                  will be a little bit different but I’m excited
                                  be able to drive the New Generation i20 R5 for
                                  the first time. With Seb sitting in the car
                                  with me, it’s also a chance for us develop our
                                  partnership with an eye on the future.”
                                </p>
                                <p>
                                  Hyundai Motorsport Customer Racing department
                                  manager Andrea Adamo said: “Competing in the
                                  Rallye Sanremo with Hayden is a great
                                  opportunity for the Customer Racing department
                                  to add to some interesting mileage on tarmac,
                                  as we continue to make advancements with the
                                  project in order to support our customers. We
                                  will have a number of teams running with the
                                  New Generation i20 R5 at the event, so it will
                                  be a busy weekend, one we hope will be
                                  successful for our customers, as well as
                                  Hayden and Chewon. For Hayden, it will be also
                                  a great opportunity to prepare for the Tour de
                                  Corse. As testing is limited in WRC, he will
                                  be able to get some more time behind the wheel
                                  of another type of i20, and under the
                                  conditions of another tough event.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
