import React, { Component } from 'react';
import { Redirect } from 'react-router';
import FooterNavbar from '../components/footerNavbar';
import Topnavbar from '../components/topnavbar';
import Bottomnavbar from '../components/bottomnavbar';
import Footer from '../components/footer';
import Store from 'store';
import './competition.css';
import InputField from '../components/inputField';
import CheckField from '../components/checkField';

export default class CompetitionRedeem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redeemed: false,
      isEligable: true,
      disableSubmit: true,
      answer1: '',
      answer2: [],
      answer3: '',
      answer4: [],
      textarea1: true,
      textarea2: true,
      textarea1Value: '',
      textarea2Value: '',
      successMessage: '',
      failMessage: '',
    };
  }

  componentDidMount = () => {
    fetch(
      process.env.REACT_APP_API_URL +
        '/HyundaiUk/Competition/HasWonCompetition?key=' +
        Store.get('loginToken').replace(/['"']+/g, '') +
        '&competitionId=67',
      {
        method: 'get',
      },
    )
      .then((result) => {
        if (result.ok) {
          return result.text();
        }
      })
      .then((status) => {
        if (status ==='false') {
          this.setState({
            isEligable: false,
          });
        }
      });
  };

  handleAnswer1Input = (event) => {
    this.setState({ answer1: event.target.value }, () => this.checkRequired());
  };
  handleAnswer2Input = (event) => {
    let answer2 = [...this.state.answer2];
    if (event.target.checked) {
      answer2.push(event.target.id);
    } else {
      const index = answer2.indexOf(event.target.id);
      if (index > -1) {
        answer2.splice(index, 1);
      }
    }
    this.setState({
      answer2,
    });
  };
  turnTextOn2 = (event) => {
    if (event.target.checked) {
      this.setState({
        textarea1: false,
      });
    } else {
      this.setState({
        textarea1: true,
      });
    }
  };
  handleAnswer3Input = (event) => {
    this.setState({ answer3: event.target.value }, () => this.checkRequired());
  };

  handleAnswer4Input = (event) => {
    let answer4 = [...this.state.answer4];
    if (event.target.checked) {
      answer4.push(event.target.id);
    } else {
      const index = answer4.indexOf(event.target.id);
      if (index > -1) {
        answer4.splice(index, 1);
      }
    }
    this.setState({
      answer4,
    });
  };

  checkRequired = () => {
    if (this.state.answer1 && this.state.answer3) {
      this.setState({
        disableSubmit: false,
      });
    } else {
      this.setState({
        disableSubmit: true,
      });
    }
  };

  turnTextOn4 = (event) => {
    if (event.target.checked) {
      this.setState({
        textarea2: false,
      });
    } else {
      this.setState({
        textarea2: true,
      });
    }
  };

  handleTextarea1Value = (event) => {
    this.setState({
      textarea1Value: event.target.value,
    });
  };

  handleTextarea2Value = (event) => {
    this.setState({
      textarea2Value: event.target.value,
    });
  };

  handleSubmitClick = () => {
    this.setState({
      disableSubmit: true,
    });
    const responses = [];
    responses.push({
      competitionId: 67,
      questionId: 1,
      response: this.state.answer1,
    });
    responses.push({
      competitionId: 67,
      questionId: 3,
      response: this.state.answer3,
    });
    this.state.answer2.forEach((answer) => {
      responses.push({
        competitionId: 67,
        questionId: 2,
        response: answer,
      });
    });
    if (this.state.textarea1Value) {
      responses.push({
        competitionId: 67,
        questionId: 2,
        response: this.state.textarea1Value,
      });
    }
    this.state.answer4.forEach((answer) => {
      responses.push({
        competitionId: 67,
        questionId: 4,
        response: answer,
      });
    });
    if (this.state.textarea2Value) {
      responses.push({
        competitionId: 67,
        questionId: 4,
        response: this.state.textarea2Value,
      });
    }
    fetch(
      process.env.REACT_APP_API_URL +
        '/HyundaiUk/Competition/SubmitPrizeWinnersResponse?key=' +
        Store.get('loginToken').replace(/['"']+/g, ''),
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(responses),
      },
    ).then((response) => {
      if (response.ok) {
        this.setState({
          successMessage: 'Thank you for completing the form',
        });
        setTimeout(() => {
          this.setState({
            isEligable: false,
          });
        }, 3000);
      } else {
        this.setState({
          failMessage: 'Submit failed',
          disableSubmit: false,
        });
      }
    });
  };

  render() {
    return (
      <div>
        {!this.state.isEligable && <Redirect to="/" />}
        <Topnavbar />
        <Bottomnavbar />
        <div className="container">
          <section
            className="section1 wow fadeIn"
            data-wow-duration="2s"
            data-wow-delay="0.2s"
          >
            <div className="content p-5 col-md-10 offset-md-1 border-right border-bottom">
              <form method="post" className="competition-form">
                <p>Fields marked with a * are mandatory</p>
                <div className="form-group">
                  <label htmlFor="answer1">
                    <h5 className="font-weight-bold">
                      MyHyundai Customer Name*
                    </h5>
                  </label>
                  <InputField
                    id="answer1"
                    value={this.state.forenames}
                    placeholder="e.g. John Smith"
                    onChange={this.handleAnswer1Input}
                  />
                </div>
                <div className="row">
                  <div className="col-4">
                    <div className="form-check">
                      <CheckField
                        id="shellfishallergy1"
                        checked={this.state.terms}
                        onChange={this.handleAnswer2Input}
                      />
                      <label htmlFor="shellfishallergy1">
                        Shellfish allergy
                      </label>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-check">
                      <CheckField
                        id="dairyallergy1"
                        checked={this.state.terms}
                        onChange={this.handleAnswer2Input}
                      />
                      <label htmlFor="dairyallergy1">Dairy allergy</label>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-check">
                      <CheckField
                        id="vegan1"
                        checked={this.state.terms}
                        onChange={this.handleAnswer2Input}
                      />
                      <label htmlFor="vegan1">Vegan</label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <div className="form-check">
                      <CheckField
                        id="glutenfree1"
                        checked={this.state.terms}
                        onChange={this.handleAnswer2Input}
                      />
                      <label htmlFor="glutenfree1">Gluten free</label>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-check">
                      <CheckField
                        id="nutsallergy1"
                        checked={this.state.terms}
                        onChange={this.handleAnswer2Input}
                      />
                      <label htmlFor="nutsallergy1">Nuts allergy</label>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-check">
                      <CheckField
                        id="vegetarian1"
                        checked={this.state.terms}
                        onChange={this.handleAnswer2Input}
                      />
                      <label htmlFor="vegetarian1">Vegetarian</label>
                    </div>
                  </div>
                </div>
                <div className="form-check">
                  <CheckField id="others1" onChange={this.turnTextOn2} />
                  <label htmlFor="others1">Others</label>
                </div>
                <div className="form-group">
                  <textarea
                    className="form-control"
                    id="otherstextarea1"
                    rows="2"
                    disabled={this.state.textarea1}
                    onChange={this.handleTextarea1Value}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="customer">
                    <h5>Guest Name*</h5>
                  </label>
                  <InputField
                    name="customer"
                    value={this.state.forenames}
                    placeholder="e.g. John Smith"
                    onChange={this.handleAnswer3Input}
                  />
                </div>
                <div className="row">
                  <div className="col-4">
                    <div className="form-check">
                      <CheckField
                        id="shellfishallergy2"
                        checked={this.state.terms}
                        onChange={this.handleAnswer4Input}
                      />
                      <label htmlFor="shellfishallergy2">
                        Shellfish allergy
                      </label>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-check">
                      <CheckField
                        id="dairyallergy2"
                        checked={this.state.terms}
                        onChange={this.handleAnswer4Input}
                      />
                      <label htmlFor="dairyallergy2">Dairy allergy</label>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-check">
                      <CheckField
                        id="vegan2"
                        checked={this.state.terms}
                        onChange={this.handleAnswer4Input}
                      />
                      <label htmlFor="vegan2">Vegan</label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <div className="form-check">
                      <CheckField
                        id="glutenfree2"
                        checked={this.state.terms}
                        onChange={this.handleAnswer4Input}
                      />
                      <label htmlFor="glutenfree2">Gluten free</label>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-check">
                      <CheckField
                        id="nutsallergy2"
                        checked={this.state.terms}
                        onChange={this.handleAnswer4Input}
                      />
                      <label htmlFor="nutsallergy2">Nuts allergy</label>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-check">
                      <CheckField
                        id="vegetarian2"
                        checked={this.state.terms}
                        onChange={this.handleAnswer4Input}
                      />
                      <label htmlFor="vegetarian2">Vegetarian</label>
                    </div>
                  </div>
                </div>
                <div className="form-check">
                  <CheckField id="others2" onChange={this.turnTextOn4} />
                  <label htmlFor="others2">Others</label>
                </div>
                <div className="form-group">
                  <textarea
                    className="form-control"
                    id="otherstextarea2"
                    rows="2"
                    disabled={this.state.textarea2}
                    onChange={this.handleTextarea2Value}
                  />
                </div>
                <div className="row">
                  <div className="col-md-4 pt-3 text-center">
                    <button
                      type="button"
                      className="btn btn-primary btn-block removeRadius competition-submit"
                      disabled={this.state.disableSubmit}
                      onClick={this.handleSubmitClick}
                    >
                      <span>Submit</span>
                    </button>
                  </div>
                  {this.state.successMessage && (
                    <div className="col-md-4 pt-3 text-success">
                      {this.state.successMessage}
                    </div>
                  )}
                  {this.state.failMessage && (
                    <div className="col-md-4 pt-3 text-danger">
                      {this.state.failMessage}
                    </div>
                  )}
                </div>
              </form>
            </div>
          </section>
        </div>

        <FooterNavbar />

        <Footer />
      </div>
    );
  }
}
