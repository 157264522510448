import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';
import Store from 'store';
import ReactGA from 'react-ga';
export default class JanuaryAwards extends Component {
  componentDidMount = () => {};

  clickHandler = () => {};

  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="januaryAwards" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="januaryAwards-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/jan1.jpg") ',
              }}
            />
            {/* <div
              id="Bayon-gallery-2"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/hyundaielectric2.jpg")',
              }}
            /> */}
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                {/* <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#Bayon-gallery-2"
                    style={{
                      backgroundImage: 'url("images/hyundaielectric2.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#Bayon-gallery-1"
                    style={{
                      backgroundImage: 'url("images/hyundaielectric1.jpg")',
                    }}
                  />
                </div> */}
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “‘Hyundai Scoops Multiple Awards in January”
                </p>
              </div>
              <hr />

              <NewsMedia articleId="awards" articleText="awards" />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    HYUNDAI SCOOPS MULTIPLE AWARDS IN JANUARY
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    {' '}
                    <ul>
                      <li>
                        The new year is off to a flying start for Hyundai with a
                        number of award wins already in 2021
                      </li>
                      <li>
                        From design to electrification, the awards showcase the
                        strength and depth of the full Hyundai range from i10
                        city car to fully electric Kona and what to look forward
                        to
                      </li>
                      <li>
                        Awards came from What Car?, carwow, Company Car Today,
                        First Car, Good Design and WhichEV
                      </li>
                    </ul>
                  </p>
                  {displayShowMore && (
                    <p>
                      <a
                        data-toggle="collapse"
                        href="#JanuaryAwards"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </div>

              <div className={moreSectionClassName} id="JanuaryAwards">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      29 January 2021 -{' '}
                      <strong>
                        Just four weeks into 2021, Hyundai Motor UK has already
                        won awards from six different media outlets and
                        establishments.
                      </strong>
                      <br />
                      <br />
                      The diverse selection of wins, from used cars and company
                      cars to design awards, reveals the strength in the modern
                      Hyundai line-up, which now showcases world-leading
                      electrified technologies.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      Early in January, the{' '}
                      <a
                        href="https://www.hyundai.news/uk/brand/hyundai-i10-is-what-cars-best-small-car-for-the-city-2021/"
                        target="_blank"
                      >
                        all-new i10 was named What Car?’s Best Small Car for the
                        City,
                      </a>{' '}
                      then the previous generation{' '}
                      <a
                        href="https://www.hyundai.news/uk/model-news/hyundai-scores-double-win-at-what-car-used-car-awards/"
                        target="_blank"
                      >
                        i20 was named as First Car’s Used Car of the Year
                      </a>{' '}
                      as a ‘safe, reliable and easy to drive’ car for new
                      drivers. Continuing Hyundai’s small car wins, carwow named
                      the i10 as its Best Small Car, saying: “it’s a doddle to
                      drive but still roomy enough for four adults and their
                      stuff. It’s big on technology, too.”
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      <img src="images/jan3.jpg" className="img-fluid" />
                      <br />
                      <br />
                      As one of the leaders in electrified vehicles, Hyundai
                      also won two awards for its plug-in hybrid and full
                      electric vehicles this January. The{' '}
                      <a
                        href="https://www.hyundai.news/uk/brand/hyundai-ioniq-retains-company-car-today-phev-of-the-year-title/"
                        target="_blank"
                      >
                        IONIQ Plug-in Hybrid was named Company Car Today’s PHEV
                        of the Year
                      </a>{' '}
                      for the third year running for its refined driving
                      experience and fleet-friendly running costs. WhichEV also
                      picked the Kona Electric as its Best Hatchback EV for
                      2021.
                      <br />
                      <br />
                      <img src="images/jan2.jpg" className="img-fluid" />
                      <br />
                      <br />
                      The range of Hyundai electrified vehicles is only set to
                      grow in 2021, and two future-gazing Hyundai concept cars
                      have picked up Good Design Awards: the 45 and Prophecy
                      concepts. The 45 concept previewed the IONIQ 5 production
                      car – based on the all-new e-GMP platform – which will be
                      unveiled in February.
                      <br />
                      <br />
                      <img src="images/jan5.jpg" className="img-fluid" />
                      <br />
                      <br />
                      It will set a new benchmark for quality, efficiency and
                      innovation in the Hyundai fully electric range. Meanwhile,
                      the Prophecy concept previews the IONIQ 6, due to be
                      revealed in 2022.
                      <br />
                      <br />
                      <img src="images/jan4.jpeg" className="img-fluid" />
                      <br />
                      <br />
                      <p
                        style={{
                          color: '#ac6b4f',
                          fontSize: '1rem',
                          fontStyle: 'italic',
                        }}
                      >
                        "There was a point in time not too long ago when Hyundai
                        was known simply as a no-frills small car specialist.
                        But as all of these awards reveal, our brand is now one
                        of the most pioneering automotive manufacturers in the
                        world; we still do the small cars – and as the wins
                        attest, we do them very well – but we’re also leading
                        the way in all types of electrified powertrains, too.
                        Our pioneering design department is attracting attention
                        for its clean, minimalist vision of the future and we
                        hope these Good Design Awards will be just the first of
                        many as these vehicles turn from concept into production
                        over the coming months."
                      </p>
                      <p className="theme-news-body-text">
                        <span style={{ fontSize: '1rem' }}>
                          <strong>Ashley Andrew</strong>
                        </span>{' '}
                        <br />
                        <span style={{ color: 'dimgray' }}>
                          MD of Hyundai Motor UK
                        </span>
                      </p>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
