import React, { Component } from "react";

export default class GroundbreakingIoniq extends Component {
  render() {
    return (
      <div className="container">
        <div className="fadeInDownBigFull">
          <div className="card-body newsPadding">
            <div className="card-deck " id="GroundbreakingIoniq">
              <div className="card">
                <div className="row">
                  <div className="col-md">
                    <img
                      id="GroundbreakingIoniq-gallery-1"
                      className="card-img-top img-fluid news-gallary-image active"
                      src="images/groundBreakingIoniq.jpg"
                      alt="Card image cap"
                    />
                  </div>
                </div>

                <div className="card-block">
                  <div className="card-content">
                    <div className="col">
                      <div className="row whiteBG">
                        <div className="col-lg-4">
                          <div className="salesText mt-3">
                            <p className="theme-news-snippet">
                              “We’re in the ring to help beat cancer with
                              Hyundai Motor UK and Stand Up To Cancer”
                            </p>
                          </div>
                          <hr />
                        </div>

                        <div className="col-lg-8 border-lg-left">
                          <div className="row pt-3">
                            <div className="col-md-10 offset-md-1">
                              <p className="salesText" data-search-tag="title">
                                ANOTHER WORLD FIRST FOR GROUNBREAKING IONIQ
                              </p>
                            </div>
                          </div>

                          <div className="row pt-3">
                            <div className="col-md-10 offset-md-1">
                              <p
                                className="theme-news-body-text"
                                data-search-tag="summary"
                              >
                                {" "}
                                <strong>June 2017 –</strong> TAP TO GIVE! That’s
                                the message from double-Olympic boxing champ
                                Nicola Adams and TV presenter Jake Humphrey, who
                                have unveiled the world’s first Contactless Car
                                at London’s King’s Cross station.
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                This bright orange IONIQ Electric car has been
                                specially designed as part of Hyundai’s
                                #DriveTheRebellion fundraising campaign for
                                Stand Up To Cancer – a joint initiative between
                                Cancer Research UK and Channel 4.
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                “We’re in the ring to help beat cancer with
                                Hyundai Motor UK and Stand Up To Cancer,” said
                                Olympic boxer and Hyundai ambassador, Nicola
                                Adams at the King’s Cross event. “It’s hugely
                                important that we keep raising awareness of
                                cancer; one in two people born after 1960 in the
                                UK will be diagnosed with the disease in their
                                lifetime, which means it affects all of us.”{" "}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                Fitted with five contactless payment points
                                where people can TAP TO GIVE™ £5 or £10, the car
                                is the first on the planet to take contactless
                                donations from members of the public in a quest
                                to raise vital funds for life-saving cancer
                                research.{" "}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                A donation of £10 unlocks the car, allowing
                                people to climb in with their friends for a
                                photo, which then prints off in the car’s boot
                                and can be stuck on the car. Gradually, the
                                IONIQ will be covered in a mosaic of faces of
                                those who’ve joined the rebellion against
                                cancer.{" "}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                “What Hyundai has produced for Stand Up To
                                Cancer is quite incredible,” said Jake at the
                                car’s launch. “There’s no easier way to play a
                                part than simply tapping your card or
                                contactless device to donate. You can add your
                                picture to the sea of faces that will soon cover
                                the car and each person is also emailed a
                                social-media-ready version of their image so the
                                Stand Up To Cancer message can be spread far and
                                wide.”{" "}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                Following its launch at King’s Cross, the
                                Contactless Car will go on a UK road trip,
                                appearing at a number of events until November.{" "}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                “Joining the rebellion against cancer and
                                helping to save lives just got a whole lot
                                easier with the launch of Hyundai’s Contactless
                                Car,” said Rachel Carr, head of Stand Up To
                                Cancer. “Finding fun and easy ways to bring
                                people together and encourage them to get behind
                                our research is really important and Hyundai,
                                with help from Jake and Nicola, is certainly
                                doing that.”{" "}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                As an added incentive to give, everyone who
                                donates to the Contactless Car will be entered
                                into a competition to win a brand new Hyundai
                                IONIQ Electric.{" "}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                For more visit:{" "}
                                <a  
                                  href="http://standuptocancer.hyundai.co.uk/"
                                  target="_blank" rel="noopener noreferrer"
                                >
                                  www.hyundai.co.uk/drivetherebellion
                                </a>{" "}
                                and{" "}
                                <a  
                                  href="https://www.standuptocancer.org.uk/"
                                  target="_blank" rel="noopener noreferrer"
                                >
                                  www.standuptocancer.org.uk
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
