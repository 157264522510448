import React, { Component } from 'react';

export default class IoniqBest extends Component {
  render() {
    return (
      <div className="row mx-0">
        <div className="fadeInDownBigFull">
          <div className="card-body newsPadding">
            <div className="card-deck " id="IoniqBest">
              <div className="card">
                <div className="row">
                  <div className="col-md">
                    <img
                      id="IoniqBest-gallery-1"
                      className="card-img-top img-fluid news-gallary-image active"
                      src="images/ioniqBest.jpg"
                      alt="ioniq Best"
                    />
                  </div>
                </div>

                <div className="card-block">
                  <div className="card-content">
                    <div className="col">
                      <div className="row whiteBG">
                        <div className="col-lg-4">
                          <div className="salesText mt-3">
                            <p className="theme-news-snippet">
                              “In the market for affordable hybrids there’s now
                              a top-notch alternative in the shape of Hyundai’s
                              IONIQ”
                            </p>
                          </div>
                          <hr />
                          <div
                            className="d-flex justify-content-around mb-2 largerSI social-share-buttons"
                            data-article-id="IoniqBest"
                            data-article-text="Ioniq Best"
                          />
                        </div>

                        <div className="col-lg-8 border-lg-left">
                          <div className="row pt-3">
                            <div className="col-md-10 offset-md-1">
                              <p className="salesText" data-search-tag="title">
                                IT’S OFFICIAL! IONIQ IS THE UK’S BEST HYBRID
                              </p>
                            </div>
                          </div>

                          <div className="row pt-3">
                            <div className="col-md-10 offset-md-1">
                              <div className="theme-news-body-text">
                                {' '}
                                <p data-search-tag="summary">
                                  <strong>January 2018 - </strong>IONIQ has been
                                  named ‘Best Hybrid’ at the prestigious 2018
                                  What Car? Awards. The rigorous judging panel,
                                  made up of members of the What Car? road test
                                  and editorial teams, praised the IONIQ Hybrid
                                  for its cost, practicality, equipment levels
                                  and driveability at the glitzy ceremony in
                                  London on 23 January.
                                </p>
                                <p>
                                  “More than any other car, the IONIQ shows that
                                  you can now drive a hybrid without having to
                                  accept any compromises,” said the magazine’s
                                  editor, Steve Huntingford. “Not only is it
                                  clean, temptingly priced and cheap to run, but
                                  it's more practical, better equipped and
                                  better to drive than its rivals."{' '}
                                </p>
                                <p>
                                  What Car? wrote of the winner: “In the market
                                  for affordable hybrids there’s now a top-notch
                                  alternative in the shape of Hyundai’s IONIQ.
                                  The IONIQ is an ideal choice for hybrid car
                                  buyers, combining low running costs and a
                                  relatively modest price tag with a
                                  reassuringly normal driving experience that’s
                                  not unlike that of its conventionally powered
                                  hatchback competitors.{' '}
                                </p>
                                <p>
                                  “When you consider that it’s also more
                                  practical and smarter inside than its main
                                  rival, the Toyota Prius, we think this very
                                  well-rounded car is a worthy victor. Not only
                                  is it quicker than the Prius, but it’s also a
                                  bit cheaper to buy, better equipped and more
                                  practical. At a time when diesel pollution is
                                  under increasing scrutiny, the IONIQ is the
                                  alternative that buyers have been calling out
                                  for.”{' '}
                                </p>
                                <p>
                                  The magazine went on: “The IONIQ Hybrid
                                  handles as well as many conventionally powered
                                  hatchbacks, with body control that’s on a par
                                  with that of more expensive machinery, while a
                                  slick dual-clutch automatic gearbox ensures
                                  that the transition between electric and
                                  petrol power is far better synchronised than
                                  in the Prius, whose days as the ubiquitous
                                  hybrid hatch could be over.”
                                </p>
                                <p>
                                  Meanwhile, the high-performance i30 N, which
                                  only launched in dealerships earlier this
                                  month, was commended at the Awards as the
                                  ‘Best Hot Hatch for £20,000-£30,000’, with the
                                  What Car? team praising its cornering,
                                  handling, and accessible price.{' '}
                                </p>
                                <p>
                                  “If you had told us a year ago that Hyundai
                                  would soon have one of the world’s best hot
                                  hatches in its ranks, we’d have been dubious
                                  to say the least,” Steve Huntingford went on
                                  to say. “But Hyundai really has gone from zero
                                  to hero, bettering established rivals with its
                                  first serious attempt at building a car in
                                  this class.”
                                </p>
                                <p>
                                  Hyundai Motor UK’s President and CEO, Tony
                                  Whitehorn said at the Awards; “We are hugely
                                  proud to see the IONIQ take top honours as the
                                  UK’s Best Hybrid. Meanwhile, the i30 N is our
                                  first hot hatch and its all-round package of
                                  style, equipment, price and state-of-the-art
                                  high performance technologies make it a fast
                                  and fun alternative to the more established
                                  names.”
                                </p>
                                <p>
                                  For more on IONIQ, which is available in
                                  Hybrid, Plug-In and Electric versions, visit{' '}
                                  <a
                                    href="http://www.hyundai.co.uk/new-cars/ioniq"
                                    target="_blank"
                                  >
                                    www.hyundai.co.uk/new-cars/ioniq
                                  </a>
                                </p>
                                <p>
                                  For more on i30 N go to{' '}
                                  <a
                                    href="http://www.hyundai.co.uk/i30n"
                                    target="_blank"
                                  >
                                    www.hyundai.co.uk/i30n
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>{' '}
          </div>
        </div>
      </div>
    );
  }
}
