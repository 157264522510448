import React, { Component } from 'react';

export default class SelectField extends Component {
  render() {
    var className =
      this.props.className == null
        ? 'form-control form-control-sm flatInput'
        : this.props.className;
    className =
      !this.props.validation ||
      this.props.validation == null ||
      this.props.validation.isValid
        ? className
        : className + ' is-invalid';

    const type = this.props.type == null ? 'text' : this.props.type;

    return (
      <select
        id={this.props.id}
        className={className}
        name={this.props.name}
        value={this.props.value}
        onChange={this.props.onChange}
      >
        {this.props.children}
      </select>
    );
  }
}
