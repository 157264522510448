import React, { Component } from "react";

export default class NewGenSantaFe extends Component {
  render() {
    return (
      <div className="container">
        <div className="fadeInDownBigFull">
          <div className="card-body newsPadding">
            <div className="card-deck " id="NewGenSantaFe">
              <div className="card">
                <div className="row">
                  <div className="col-md">
                    <img
                      id="NewGenSantaFe-gallery-1"
                      className="card-img-top img-fluid news-gallary-image active"
                      src="images/ngSantaFe.jpg"
                      alt="Card image cap"
                    />
                  </div>
                </div>

                <div className="card-block">
                  <div className="card-content">
                    <div className="col">
                      <div className="row whiteBG">
                        <div className="col-lg-4">
                          <div className="salesText mt-3">
                            <p className="theme-news-snippet">
                              “The fourth generation Santa Fe, Hyundai Motor’s
                              first SUV represents the company’s strong SUV
                              heritage”
                            </p>
                          </div>
                          <hr />
                        </div>

                        <div className="col-lg-8 border-lg-left">
                          <div className="row pt-3">
                            <div className="col-md-10 offset-md-1">
                              <p className="salesText" data-search-tag="title">
                                HYUNDAI MOTOR RELEASES FIRST TEASER IMAGE OF THE
                                FOURTH GENERATION SANTA FE
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <div className="theme-news-body-text">
                                <ul>
                                  <li>
                                    The fourth generation Santa Fe, Hyundai
                                    Motor’s first SUV represents the company’s
                                    strong SUV heritage.
                                  </li>
                                  <li>
                                    The new generation Santa Fe offers
                                    innovative and industry-first active safety
                                    features.
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div className="row pt-3">
                            <div className="col-md-10 offset-md-1">
                              <div className="theme-news-body-text">
                                <p data-search-tag="summary">
                                  <strong>January 25, 2018 - </strong> Hyundai
                                  Motor has unveiled its first facts about the
                                  New Generation Santa Fe, which will celebrate
                                  its world premiere in February before making
                                  its debut at the Geneva Motor Show in early
                                  March. The fourth generation Santa Fe
                                  continues the model’s global success story.
                                  The first Santa Fe was launched in 2001 and is
                                  the foundation of Hyundai Motor’s SUV
                                  credibility.
                                </p>
                                <p>
                                  The new generation Santa Fe offers a
                                  comprehensive package of Hyundai’s latest
                                  active safety features under the Smart Sense
                                  technology umbrella. The innovative and
                                  industry-first Rear Occupant Alert monitors
                                  the rear seats to detect passengers and alerts
                                  the driver when leaving the car. The Rear
                                  Cross-Traffic Collision Warning is now
                                  enhanced by an automated braking function. To
                                  avoid the risk of collision when reversing out
                                  of narrow areas with limited visibility, the
                                  system warns the driver and applies the brakes
                                  automatically.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
