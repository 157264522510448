import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';
import Store from 'store';
import ReactGA from 'react-ga';
export default class LargestMarketShare extends Component {
  componentDidMount = () => {};

  clickHandler = () => {};

  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="execChair" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="oneMillion-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/largestmarketshare.png") ',
              }}
            />
            {/* <div
              id="Bayon-gallery-2"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/hyundaielectric2.jpg")',
              }}
            /> */}
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                {/* <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#Bayon-gallery-2"
                    style={{
                      backgroundImage: 'url("images/hyundaielectric2.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#Bayon-gallery-1"
                    style={{
                      backgroundImage: 'url("images/hyundaielectric1.jpg")',
                    }}
                  />
                </div> */}
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “Hyundai Records Largest Ever Market Share in UK 2022”
                </p>
              </div>
              <hr />

              <NewsMedia articleId="awards" articleText="awards" />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    HYUNDAI RECORDS LARGEST EVER MARKET SHARE IN UK 2022
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    {' '}
                    <ul>
                      <li>
                        Hyundai claims 5.0% car market share in 2022 – its
                        largest ever in the UK
                      </li>
                      <li>
                        Tucson is Britain’s Favourite Mid-Size SUV by retail
                        sales
                      </li>
                      <li>
                        Award-winning line-up of models deliver year-on-year
                        sales rise of 15.4%
                      </li>
                      <li>
                        Hyundai also records 7.7% share of the market for
                        alternatively fuelled vehicles (AFVs)
                      </li>
                      <li>
                        Range set to be transformed for 2023 with an array of
                        all-new and refreshed models including IONIQ 6 and the
                        All-New Kona
                      </li>
                      {/* <li>
                        Hyundai is investing £1 million in a programme of school
                        trips in order to prevent children missing out on such
                        experiences
                      </li>
                      <li>
                        Bookings are now open for the initiative, which has
                        launched with Hyundai’s Educator in Residence, Tim
                        Campbell MBE
                      </li> */}
                    </ul>
                  </p>
                  {displayShowMore && (
                    <p>
                      <a
                        data-toggle="collapse"
                        href="#marketShare"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </div>

              <div className={moreSectionClassName} id="marketShare">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      <strong>
                        After strong growth in 2021, Hyundai UK’s momentum
                        continued throughout 2022 with another record-breaking
                        year, according to data released by the Society of Motor
                        Manufacturers and Traders (SMMT).
                      </strong>
                      <br />
                      <br />
                      Hyundai claimed its largest ever market share of 5.0%,
                      with 80,419 new cars registered during the past 12 months.
                      This impressive growth constitutes a rise of 15.4% over
                      the 69,680 units recorded in 2021, which represented the
                      previous record of 4.3% market share.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      Retail sales to private buyers accounted for a record 4.3%
                      market share, up 0.4% on 2021 and True Fleet sales saw a
                      rise of 37% YoY with more than 18,000 cars sold showcasing
                      the increasing appeal of Hyundai cars in the fleet sector.
                      <br />
                      <br />
                      Hyundai’s most popular model is the acclaimed TUCSON,
                      which with 27,839 registered, accounted for 35% of the
                      final registrations of 80,419, and proved to be the most
                      popular mid-size SUV for retail buyers and seventh
                      best-selling car overall in the UK. It claimed 5.3% share
                      of the retail market in its sector, but also is well
                      established with fleet customers, who appreciated its
                      generous array of equipment, stylish design and
                      low-emission powertrains.
                      {/* <img src="images/expo20302.jpeg" className="img-fluid" /> */}
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      Hyundai’s strategic positioning as a leader in electrified
                      cars was also reflected in the market for alternatively
                      fuelled vehicles (AFVs), which rose 22% in 2022. With a
                      7.7% share, Hyundai was the third most popular
                      manufacturer, testament to the wide range of options
                      available to customers, from hybrid through plug-in hybrid
                      to fully electric. More than half of all Hyundai cars sold
                      in the UK during 2022 were AFVs.
                      <br />
                      <br />
                      Of particular note was the sales performance of Hyundai’s
                      all-electric vehicles, which made up more than a third of
                      the AFV figure. With the award-winning IONIQ 5 – crowned
                      2022 World Car of the Year, World Electric Vehicle of the
                      Year and World Car Design of the Year – and KONA Electric
                      - soon to be available in an all-new model - as well as
                      the eagerly awaited IONIQ 6, customers will shortly have
                      more choice than ever.
                      <br />
                      <br />
                      <strong>
                        {' '}
                        "The success of Hyundai in the British market over the
                        past 12 months is a reflection of both our advanced
                        range of vehicles and the excellent work of our Retailer
                        Network. Our model range holds tremendous appeal to
                        private buyers and fleets alike thanks to its innovative
                        technology, award-winning design and the sheer diversity
                        of the electrified options that are available. It has
                        been particularly gratifying to see buyers embrace our
                        electrified cars in record numbers – and it fills me
                        with excitement about the potential of the fantastic new
                        IONIQ 6, with deliveries due to start shortly. 2022 was
                        a superb year, and there’s lots more to come in 2023."
                      </strong>{' '}
                      - Ashley Andrew, Managing Director Hyundai Motor UK
                      <br />
                      <br />
                      Orders for the new IONIQ 6 began back in November last
                      year with deliveries set to begin shortly. Hyundai also
                      recently announced the All-New Kona including a new
                      all-electric model which will be joining the range later
                      this year. But it doesn’t stop there with an array of new
                      models to come this year and beyond.
                      <br />
                      <br />
                      {/* It also highlighted the Group’s pioneering role in smart
                      city planning and a myriad of mobility solutions,
                      including robots and Advanced Air Mobility (AAM).
                      <br />
                      <br /> Commenting on the depth and reach of Chung’s
                      vision, MotorTrend added: “In that sense, he does his
                      father and grandfather proud, pushing the entire Hyundai
                      Motor Group to new levels. The man and his vision, and the
                      juggernaut that his company is becoming, loom loud and
                      large. For his vision and leadership, then, Euisun Chung
                      is our 2023 MotorTrend Person of the Year.”
                      <img src="images/execchair2.jpeg" className="img-fluid" /> */}
                      {/* <a
                        href="https://motorstudio.hyundai.com/busan/ln/main.do?strgC=04&lang=en"
                        target="_blank"
                      >
                        Hyundai Motorstudio Busan
                      </a>{' '}
                      , the ‘Reflections in Motion’ exhibition invites visitors
                      to explore and reflect on the interactive relationships
                      between time and design as well as technology and styling,
                      with various featured installations underscoring Hyundai
                      Motor’s vision centered on enabling ‘Progress for
                      Humanity’. */}
                      {/* <br />
                      <br />
                      Also ranked on MotorTrend’s Power List are Luc
                      Donckerwolke, President and Chief Creative Officer of
                      Hyundai Motor Group (3); Jose Muñoz, President and Global
                      Chief Operating Officer of Hyundai Motor Company (10);
                      SangYup Lee, Executive Vice President and Head of Hyundai
                      Global Design Center (28); and Karim Habib (44), Executive
                      Vice President and Head of Kia Global Design Center.
                      <br />
                      <br />
                      Previous recognition for Chung’s pioneering thinking
                      includes the Executive Chair being named Newsweek’s
                      ‘Visionary of the Year’ at its inaugural World’s Greatest
                      Auto Disrupters event in 2022 and being honored with the
                      coveted Issigonis Trophy at the 2021 Autocar Awards. */}
                      {/* <strong>
                        "School trips are an integral part of a child’s
                        educations, and I remember my own outings with such fond
                        memories, even all these years later! What’s more, being
                        able to turn these trips into further learning within
                        the classroom is such an important skill and something
                        that should be available for everyone. This programme is
                        extremely timely and will be crucial in putting school
                        trips firmly back on the agenda"
                      </strong>{' '}
                      - Tim Campbell Hyundai's Educator in Residence;
                      <br /> */}
                      {/* <br />
                      <br />
                      Speaking earlier in January, at a New Year town hall
                      meeting at the Group’s Namyang R&D Center, Chung
                      underlined his continued commitment to the success of the
                      Group. He urged everyone within the organisation to spare
                      no effort to ensure the delivery of the best possible
                      products and services for its customers.
                      <br />
                      <br />
                      <strong>
                        "We must take on challenges based on trust, and the
                        positive results must lead to even stronger trust. No
                        great product or technology is complete without the
                        trust of our customers. Quality is a priority for all of
                        us. From product planning to design, production, sales,
                        and after-sales maintenance, all teams and employees
                        must place quality and the customer as the top priority.
                        We must all go above and beyond to earn the respect and
                        loyalty of our customers."
                      </strong>{' '}
                      - Euisun Chung, The Groups Executive Chair
                      <br />
                      <br /> */}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
