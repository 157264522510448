import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';
import Store from 'store';
import ReactGA from 'react-ga';
export default class I20n extends Component {
  componentDidMount = () => {};

  clickHandler = () => {};

  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="i20n-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/i20nheader.png") ',
              }}
            />
            <div
              id="i20n-gallery-2"
              className="theme-news-header-image "
              style={{
                backgroundImage: 'url("images/i20n1.png") ',
              }}
            />
            <div
              id="i20n-gallery-3"
              className="theme-news-header-image "
              style={{
                backgroundImage: 'url("images/i20n2.png") ',
              }}
            />
            <div
              id="i20n-gallery-4"
              className="theme-news-header-image "
              style={{
                backgroundImage: 'url("images/i20n3.png") ',
              }}
            />
            <div
              id="i20n-gallery-5"
              className="theme-news-header-image "
              style={{
                backgroundImage: 'url("images/i20n4.png") ',
              }}
            />
            <div
              id="i20n-gallery-6"
              className="theme-news-header-image "
              style={{
                backgroundImage: 'url("images/i20n5.png") ',
              }}
            />
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#i20n-gallery-2"
                    style={{
                      backgroundImage: 'url("images/i20n1.png") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#i20n-gallery-3"
                    style={{
                      backgroundImage: 'url("images/i20n2.png") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#i20n-gallery-4"
                    style={{
                      backgroundImage: 'url("images/i20n3.png") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#i20n-gallery-5"
                    style={{
                      backgroundImage: 'url("images/i20n4.png") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#i20n-gallery-6"
                    style={{
                      backgroundImage: 'url("images/i20n5.png") ',
                    }}
                  />
                </div>
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “Hyundai i20 N crowned Top Gear Speed Week Champion”
                </p>
              </div>
              <hr />

              <NewsMedia articleId="awards" articleText="awards" />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    HYUNDAI I20 N CROWNED TOP GEAR SPEED WEEK CHAMPION
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    {' '}
                    <ul>
                      <li>
                        The Hyundai i20 N has been named overall champion at Top
                        Gear’s Speed Week, outperforming sports cars from the
                        world’s most evocative brands
                      </li>
                      <li>
                        Speed Week 2021 was a gathering of 26 of the fastest
                        cars on the planet
                      </li>
                      <li>
                        Despite being the most affordable car in attendance, the
                        Top Gear team were impressed by i20 N’s sharp handling
                        and everyday usability
                      </li>
                      <li>
                        The Hyundai i20 N is on sale now, available to order
                        from £24,995 OTR
                      </li>
                    </ul>
                  </p>
                  {displayShowMore && (
                    <p>
                      <a
                        data-toggle="collapse"
                        href="#i20n"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </div>

              <div className={moreSectionClassName} id="i20n">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      13 October 2021 -{' '}
                      <strong>
                        The Hyundai i20 N has reigned supreme at Top Gear’s
                        Speed Week, defeating some of the most exciting
                        performance cars in the world to be named overall winner
                        for 2021.
                      </strong>
                      <br />
                      <br />
                      Despite being the most affordable car in attendance, the
                      i20 N triumphed from a group of 26 contenders during a
                      series of thorough tests that took place over multiple
                      days at Top Gear’s Dunsfold test track. The top five most
                      fun cars to drive advanced to a shootout on the roads of
                      Exmoor where the judging panel – comprised of Top Gear’s
                      expert editorial team alongside Chris Harris and The STIG
                      – crowned the i20 N the overall winner.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      <img src="images/i20n1.png" className="img-fluid" />
                      <br />
                      <br />
                      The competition was made up of models from some of the
                      most evocative performance car brands in the world,
                      spanning lightweight two-seater sports cars, 1,000hp
                      hybrid hypercars, track-focused specials and fully
                      electric sports coupés. The combination of i20 N’s
                      thrilling handling and rounded abilities ultimately won
                      the team over.
                      <br />
                      <br />{' '}
                      <strong>
                        Jack Rix, Editor of Top Gear Magazine, said:
                      </strong>{' '}
                      “Every year we assemble the most ridiculous collection of
                      fast cars launched in the past 12 months. We call it Speed
                      Week to do justice to the sprawling, multi-day itinerary
                      and logistical minefield of our own making. Speed Week is
                      always full of surprises... what we weren't expecting was
                      the most affordable car here to mount a Raducanu-esque
                      surge through the field for a thoroughly deserved victory.
                      But there's a keenness to the i20 N, backed up by huge
                      ability and real dynamic polish that makes it something of
                      a stand out, not just among its peers, but among the whole
                      spectrum of Speed Week.”
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      <img src="images/i20n2.png" className="img-fluid" />
                      <br />
                      <br />
                      The all new i20 N builds on the strengths of Hyundai's
                      renowned, performance focused N brand and the success of
                      the recently launched all new i20. Hyundai's latest
                      sporting icon also takes inspiration and engineering
                      values from the multiple-rally-winning i20 Coupe WRC car.
                      It’s equally at home delivering its unique brand of
                      exhilarating performance both on the road and racetrack.
                      <br />
                      <br />
                      <img src="images/i20n3.png" className="img-fluid" />
                      <br />
                      <br />
                      The i20 N blends prodigious power, pin-sharp handling and
                      an immersive driving experience with motorsport-inspired
                      styling, cutting edge connectivity and infotainment
                      technology and advanced safety features into a practical,
                      five-door hatchback format perfect for every day driving.
                      <br />
                      <br />
                      <img src="images/i20n4.png" className="img-fluid" />
                      <br />
                      <br />
                      <strong>
                        Ashley Andrew, Managing Director, Hyundai Motor UK,
                        said:
                      </strong>
                      “The i20 N was engineered to deliver a pure,
                      uncompromising driving experience majoring on fun, while
                      also ensuring it excels in everyday practicality and
                      usability. The challenge was to create the ultimate
                      affordable performance car. To see it not only lining up
                      against genuine icons of the supercar world but even
                      toppling all of them and coming out on top in one of the
                      industry’s most comprehensive performance group tests is
                      the ultimate seal of approval.”
                      <br />
                      <br />
                      <img src="images/i20n5.png" className="img-fluid" />
                      <br />
                      <br />
                      The Hyundai i20 N is available to order now, delivering
                      World Rally Championship-inspired styling and performance
                      from just £24,995 On the Road (OTR).
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
