import React, { Component } from 'react';

export default class Interest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState(
      (prevState) => ({
        data: {
          ...prevState.data,
          isSelected: (!JSON.parse(prevState.data.isSelected)).toString(),
        },
      }),
      this.raiseOnChange,
    );
  }

  raiseOnChange() {
    if (this.props.onChange != null) {
      this.props.onChange(this.state.data.label, this.state.data.isSelected);
    }
  }

  render() {
    const selected = JSON.parse(this.state.data.isSelected);
    const name = this.state.data.label;
    const imageSuffix = selected ? 'ON' : 'OFF';
    const imageUrl =
      './images/interests/' +
      this.state.data.imageId +
      '_' +
      imageSuffix +
      '.jpg';

    if (
      imageUrl == './images/interests/_OFF.jpg' ||
      imageUrl == './images/interests/_ON.jpg'
    ) {
      return (
        <div className="card interest ">
          <a onClick={this.handleClick}>
            <img className="card-img-top" src="" alt={name} />
          </a>
          <div className="card-body">
            <h5 className="card-title">{name}</h5>
          </div>
        </div>
      );
    } else {
      return (
        <div className="card interest ">
          <a onClick={this.handleClick}>
            <img className="card-img-top" src={imageUrl} alt={name} />
          </a>
          <div className="card-body">
            <h5 className="card-title">{name}</h5>
          </div>
        </div>
      );
    }
  }
}
