import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';

export default class I30FastBack2 extends Component {
  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="I30FastBack2" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="fastback-lineup-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/i30fastrack3s.jpg") ',
              }}
            />
            <div
              id="fastback-lineup-gallery-2"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/i30fastrack1s.jpg")',
              }}
            />
            <div
              id="fastback-lineup-gallery-3"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/i30fastrack2s.jpg")',
              }}
            />
          </div>
        </div>

        <div className="col  bg-white  border-right border-bottom">
          <div className="row py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#fastback-lineup-gallery-1"
                    style={{
                      backgroundImage: 'url("images/i30fastrack3s.jpg") ',
                    }}
                  />
                </div>

                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#fastback-lineup-gallery-2"
                    style={{
                      backgroundImage: 'url("images/i30fastrack1s.jpg")',
                    }}
                  />
                </div>

                <div className="theme-news-thumbnail-container  img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#fastback-lineup-gallery-3"
                    style={{
                      backgroundImage: 'url("images/i30fastrack2s.jpg")',
                    }}
                  />
                </div>
              </div>
              <div className="mt-3">
                <p className="theme-news-snippet">
                  “The i30 Fastback is a game changer. We have been able to
                  combine the sports car spirit with the comfort of a sedan”
                </p>
              </div>
              <hr />
              <NewsMedia
                articleId="I30FastBack2"
                articleText="Sophisticated i30 Fastback completes the line-up"
              />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    'SOPHISTICATED' i30 FASTBACK COMPLETES THE LINE-UP
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-md-10 offset-md-1">
                  <div className="theme-news-body-text" />
                </div>
              </div>

              <div className="row pt-3 ">
                <div className="col-md-10 offset-md-1 ">
                  <div className="theme-news-body-text">
                    <p
                      className="theme-news-body-text"
                      data-search-tag="summary"
                    >
                      First came the New Generation i30 last March, followed by
                      the i30 Tourer in the Summer. The i30 N roared into
                      showrooms in January and now there’s the latest addition
                      to the family – the i30 Fastback.
                    </p>
                    {displayShowMore && (
                      <p>
                        <a
                          data-toggle="collapse"
                          href="#collapsei30NFast"
                          role="button"
                          aria-expanded="false"
                        >
                          More{' '}
                          <span>
                            <i className="fas fa-long-arrow-alt-down" />
                          </span>
                        </a>
                      </p>
                    )}
                    <div className={moreSectionClassName} id="collapsei30NFast">
                      <p>
                        “The sophisticated design of the i30 Fastback
                        incorporates the essence of our philosophy, making
                        premium design accessible for everyone,” says Thomas A.
                        Schmid, Chief Operating Officer at Hyundai Motor Europe.
                        “It is the first elegant five-door coupé to enter the
                        compact segment, underlining our commitment to
                        innovation and customer choice.”
                      </p>
                      <p>
                        The i30 Fastback’s sloping roofline, long bonnet and
                        muscular body, along with its slim cabin and wide wheel
                        arches give the car a strong stance. That strength,
                        however, is far from being merely skin deep.
                      </p>
                      <p>
                        The chassis has been lowered by 5mm and the stiffness of
                        the suspension increased by 15 per cent over the regular
                        i30 to give a more dynamic and agile drive. Meanwhile,
                        the roof has been lowered by 25mm to improve
                        aerodynamics.The car was then rigorously tested and
                        refined at Hyundai Motor’s European Testing Centre at
                        the infamous Nürburgring circuit in Germany.{' '}
                      </p>
                      <p>
                        “The i30 Fastback is a real game changer,” explained
                        Thomas Bürkle, Chief Designer at Hyundai Design Centre
                        Europe. “When designing the i30 Fastback we played with
                        the proportions of the i30 range by reducing the height
                        of the Cascading Grille and lowering the roof line. The
                        silhouette is further emphasised by the arched rear
                        spoiler. We have been able to achieve a pure design,
                        which combines the sports car spirit with the comfort of
                        a luxurious saloon.”
                      </p>
                      <p>
                        For more on the i30 Fastback visit{' '}
                        <a
                          href="http://www.hyundai.co.uk/new-cars/i30-fastback"
                          target="_blank"
                        >
                          {' '}
                          www.hyundai.co.uk/new-cars/i30-fastback
                        </a>{' '}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
