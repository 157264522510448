import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';
import Store from 'store';
import ReactGA from 'react-ga';
export default class Pony extends Component {
  componentDidMount = () => {};

  clickHandler = () => {};

  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="pony" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="pony-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/ponyexib1.jpg") ',
              }}
            />
            {/* <div
              id="Bayon-gallery-2"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/hyundaielectric2.jpg")',
              }}
            /> */}
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                {/* <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#Bayon-gallery-2"
                    style={{
                      backgroundImage: 'url("images/hyundaielectric2.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#Bayon-gallery-1"
                    style={{
                      backgroundImage: 'url("images/hyundaielectric1.jpg")',
                    }}
                  />
                </div> */}
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “Hyundai Showcases Heritage Series PONY as Icon of Design
                  Innovation”
                </p>
              </div>
              <hr />

              <NewsMedia articleId="awards" articleText="awards" />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    HYUNDAI SHOWCASES HERITAGE SERIES PONY AS ICON OF DESIGN
                    INNOVATION
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    {' '}
                    <ul>
                      <li>
                        Heritage Series PONY illuminates company’s ceaseless
                        design innovations, reinterpreting retro design with
                        modern cool trappings and technologies
                      </li>
                      <li>
                        Key features are camera-based, fender-mounted exterior
                        mirrors, pixelated headlights and taillights, and
                        speed-gauging instrument panel made of lighted vacuum
                        tubes
                      </li>
                      <li>
                        Heritage Series PONY is part of ‘Reflections in Motion’
                        exhibition at Hyundai Motorstudio Busan
                      </li>
                    </ul>
                  </p>
                  {displayShowMore && (
                    <p>
                      <a
                        data-toggle="collapse"
                        href="#pony"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </div>

              <div className={moreSectionClassName} id="pony">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      16 April 2021 -{' '}
                      <strong>
                        Hyundai has revealed the Heritage Series PONY, a
                        retro-modern reconstruction of the original
                        mass-produced PONY model that heralded the dawn of
                        Korea’s automotive industry more than 45 years ago.
                      </strong>
                      <br />
                      <br />
                      Featured at the recently opened Hyundai Motorstudio Busan
                      in South Korea as part of its ‘Reflections in Motion’
                      exhibition, from April 8 through June 27, the Heritage
                      Series PONY combines distinctive legacy design features
                      with modern touches to offer a glimpse of the future.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      <img src="images/ponyexib2.jpg" className="img-fluid" />
                      <br />
                      <br />
                      Legendary automobile designer Giorgetto Giugiaro conceived
                      the first generation of PONY. The PONY was an icon of
                      personal mobility created by Hyundai during its mass
                      production from 1975-1990. The hatchback car was the
                      nation’s first mass-produced and exported vehicle. More
                      than 45 years later, the timeless value of the original
                      PONY is visible in the new Heritage Series PONY, shown as
                      part of the ‘Heritage Series – PONY’ section of the
                      ‘Reflections in Motion’ exhibition.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      The Heritage Series PONY’s exterior is marked by a matte,
                      shimmering surface of silver throughout front to rear part
                      of the vehicle, hatchback silhouette. Notable features
                      include camera-based, fender-mounted exterior mirrors to
                      the fore of the windshield, pixelated round headlights and
                      U-shaped taillights made of LED lights. The pixelated
                      round headlights and U-shaped taillights embody the
                      company’s ‘Pixel Road Trip’ design approach that
                      incorporates pixels and eight-bit graphics also utilized
                      in the IONIQ 5 and 45 concept car.
                      <br />
                      <br />
                      <img src="images/ponyexib3.jpg" className="img-fluid" />
                      <br />
                      <br />
                      The interior boasts a string of chic and modern
                      accoutrements, including doors made of premium materials
                      that exude a classic feel, a modern lighting system, a
                      speed-gauging instrument panel made of lighted vacuum
                      tubes as well as other stylishly retrofitted design
                      elements.
                      <br />
                      <br /> It also contains future-oriented elements, not only
                      retro style. The digital touch transmission, cradle space
                      for mobile phone and voice-activated steering wheel
                      conveys a modern sensibility that will catch people by
                      surprise.
                      <br />
                      <br /> In the trunk space, a last-mile mobility device,
                      comprising a stainless steel case, is installed,
                      representing Hyundai Motor’s human-centered design
                      commitment.
                      <br />
                      <br /> Meanwhile at{' '}
                      <a
                        href="https://motorstudio.hyundai.com/busan/ln/main.do?strgC=04&lang=en"
                        target="_blank"
                      >
                        Hyundai Motorstudio Busan
                      </a>{' '}
                      , the ‘Reflections in Motion’ exhibition invites visitors
                      to explore and reflect on the interactive relationships
                      between time and design as well as technology and styling,
                      with various featured installations underscoring Hyundai
                      Motor’s vision centered on enabling ‘Progress for
                      Humanity’.
                      <br />
                      <br />
                      Exterior
                      <br />
                      <br />
                      <img src="images/ponyext1.jpg" className="img-fluid" />
                      <br />
                      <br />
                      <img src="images/ponyext2.jpg" className="img-fluid" />
                      <br />
                      <br />
                      <img src="images/ponyext3.jpg" className="img-fluid" />
                      <br />
                      <br />
                      <img src="images/ponyext4.jpg" className="img-fluid" />
                      <br />
                      <br />
                      <img src="images/ponyext5.jpg" className="img-fluid" />
                      <br />
                      <br />
                      <img src="images/ponyext6.jpg" className="img-fluid" />
                      <br />
                      <br />
                      <img src="images/ponyext7.jpg" className="img-fluid" />
                      <br />
                      <br />
                      <img src="images/ponyext8.jpg" className="img-fluid" />
                      <br />
                      <br />
                      <img src="images/ponyext9.jpg" className="img-fluid" />
                      <br />
                      <br />
                      <img src="images/ponyext10.jpg" className="img-fluid" />
                      <br />
                      <br />
                      <img src="images/ponyext11.jpg" className="img-fluid" />
                      <br />
                      <br />
                      <img src="images/ponyext12.jpg" className="img-fluid" />
                      <br />
                      <br />
                      Interior
                      <br />
                      <br />
                      <img src="images/ponyint0.jpg" className="img-fluid" />
                      <br />
                      <br />
                      <img src="images/ponyint1.jpg" className="img-fluid" />
                      <br />
                      <br />
                      <img src="images/ponyint2.jpg" className="img-fluid" />
                      <br />
                      <br />
                      <img src="images/ponyint3.jpg" className="img-fluid" />
                      <br />
                      <br />
                      <img src="images/ponyint4.jpg" className="img-fluid" />
                      <br />
                      <br />
                      <img src="images/ponyint5.jpg" className="img-fluid" />
                      <br />
                      <br />
                      <img src="images/ponyint6.jpg" className="img-fluid" />
                      <br />
                      <br />
                      <img src="images/ponyint7.jpg" className="img-fluid" />
                      <br />
                      <br />
                      <img src="images/ponyint8.jpg" className="img-fluid" />
                      <br />
                      <br />
                      <img src="images/ponyint9.jpg" className="img-fluid" />
                      <br />
                      <br />
                      <img src="images/ponyint10.jpg" className="img-fluid" />
                      <br />
                      <br />
                      <img src="images/ponyint11.jpg" className="img-fluid" />
                      <br />
                      <br />
                      <img src="images/ponyint12.jpg" className="img-fluid" />
                      <br />
                      <br />
                      <img src="images/ponyint13.jpg" className="img-fluid" />
                      <br />
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
