import React, { Component } from 'react';
import imageExists from 'image-exists';

export default class VehicleText extends Component {
  render() {
    var pathParts = this.props.imagePath.split('/');
    var vehicle = pathParts[pathParts.length - 1].match(/.*?(?=-\w{2}.png)/);
    if (vehicle == null) return null;

    var vehicleName = '';
    var latest = '';

    switch (vehicle[0].toUpperCase()) {
      case 'I10-GO': {
        vehicleName = 'i10 GO Edition';
        latest = 'latest';
        break;
      }
      case 'I10-PRM': {
        vehicleName = 'i10 Premium';
        latest = 'latest';
        break;
      }
      case 'I10-PSE': {
        vehicleName = 'i10 Premium SE';
        latest = 'latest';
        break;
      }
      case 'I10-S': {
        vehicleName = 'i10 S';
        latest = 'latest';
        break;
      }
      case 'I10-SE': {
        vehicleName = 'i10 SE';
        latest = 'latest';
        break;
      }
      case 'I10-SEB': {
        vehicleName = 'i10 SE Bluedrive';
        latest = 'latest';
        break;
      }
      case 'I20-5D-GO': {
        vehicleName = 'i20 GO Edition';
        latest = 'latest';
        break;
      }
      case 'I20-5D-PRN': {
        vehicleName = 'i20 Premium NAV';
        latest = 'latest';
        break;
      }
      case 'I20-5D-PSN': {
        vehicleName = 'i20 Premium SE Nav';
        latest = 'latest';
        break;
      }
      case 'I20-5D-S': {
        vehicleName = 'i20 S';
        latest = 'latest';
        break;
      }
      case 'I20-5D-SA': {
        vehicleName = 'i20 S Air';
        latest = 'latest';
        break;
      }
      case 'I20-5D-SE': {
        vehicleName = 'i20 SE';
        latest = 'latest';
        break;
      }
      case 'I20-3D-ACT': {
        vehicleName = 'i20 Active';
        latest = 'latest';
        break;
      }
      case 'I20-3D-SE': {
        vehicleName = 'i20 SE';
        latest = 'latest';
        break;
      }
      case 'I30-5D-PRM': {
        vehicleName = 'i30 Premium';
        latest = 'latest';
        break;
      }
      case 'I30-5D-SE': {
        vehicleName = 'i30 SE';
        latest = 'latest';
        break;
      }
      case 'I30-5D-SEN': {
        vehicleName = 'i30 SE NAV';
        latest = 'latest';
        break;
      }
      case 'I30N': {
        vehicleName = 'i30 N';
        latest = 'latest';
        break;
      }
      case 'I30NEW-FPM': {
        vehicleName = 'i30 New Fast Back Premium';
        break;
      }
      case 'I30NEW-FPS': {
        vehicleName = 'i30 New Fast Back Premium SE';
        break;
      }
      case 'I30NEW-FSN': {
        vehicleName = 'i30 New Fast Back Premium SE NAV';
        break;
      }
      case 'I30TOU-SE': {
        vehicleName = 'i30 Tourer SE';
        latest = 'latest';
        break;
      }
      case 'I30TOU-SEN': {
        vehicleName = 'i30 Tourer SE NAV';
        latest = 'latest';
        break;
      }
      case 'I40SAL-PRM': {
        vehicleName = 'i40 Saloon Premium';
        latest = 'latest';
        break;
      }
      case 'I40TOU-PRM': {
        vehicleName = ' i40 Tourer Premium';
        latest = 'latest';
        break;
      }
      case 'ION-ELE-PRM': {
        vehicleName = 'IONIQ Electric Premium';
        break;
      }
      case 'ION-ELE-PSE': {
        vehicleName = 'IONIQ Electric Premium SE';
        break;
      }
      case 'ION-HYB-PRM': {
        vehicleName = 'IONIQ Hybrid Premium';
        break;
      }
      case 'ION-HYB-PSE': {
        vehicleName = 'IONIQ Hybrid Premium SE';
        break;
      }
      case 'ION-PLU-PRM': {
        vehicleName = 'IONIQ Plug in Hybrid Premium';
        break;
      }
      case 'ION-PLU-PSE': {
        vehicleName = 'IONIQ Plug in Hybrid Premium SE';
        break;
      }
      case 'IX20-PRN': {
        vehicleName = 'ix20 Premium NAV';
        latest = 'latest';
        break;
      }
      case 'IX20-SE': {
        vehicleName = 'ix20 SE';
        latest = 'latest';
        break;
      }
      case 'IX20-SEN': {
        vehicleName = 'ix20 SE NAV';
        latest = 'latest';
        break;
      }
      case 'KONA-PRM': {
        vehicleName = 'KONA Premium';
        break;
      }
      case 'KONA-PGT': {
        vehicleName = 'KONA Premium GT';
        break;
      }
      case 'KONA-PSE': {
        vehicleName = 'KONA Premium SE';
        break;
      }
      case 'KONA-S': {
        vehicleName = 'KONA S';
        break;
      }
      case 'KONA-SE': {
        vehicleName = 'KONA SE';
        break;
      }
      case 'SANTAFE-PSE': {
        vehicleName = 'Santa Fe Premium SE';
        latest = 'latest';
        break;
      }
      case 'TUCSON-GO': {
        vehicleName = 'Tucson GO Edition';
        latest = 'latest';
        break;
      }
      case 'TUCSON-PRM': {
        vehicleName = 'Tucson Premium';
        latest = 'latest';
        break;
      }
      case 'TUCSON-PSE': {
        vehicleName = 'Tucson Premium SE';
        latest = 'latest';
        break;
      }
      case 'TUCSON-S': {
        vehicleName = 'Tucson S';
        latest = 'latest';
        break;
      }
      case 'TUCSON-SE': {
        vehicleName = 'Tucson SE';
        latest = 'latest';
        break;
      }
      case 'TUCSON-SEN': {
        vehicleName = 'Tucson SE NAV';
        latest = 'latest';
        break;
      }
      case 'TUCSON-SPT': {
        vehicleName = 'Tucson Sport';
        latest = 'latest';
        break;
      }
      default: {
        return null;
      }
    }
    return (
      <p className="theme-disclaimer-car-text">
        Model shown: {latest} Hyundai {vehicleName}
      </p>
    );
  }
}
