import React, { Component } from "react";

export default class Kona extends Component {
  render() {
    return (
      <div className="container">
        <div className="fadeInDownBigFull">
          <div className="card-body newsPadding">
            <div className="card-deck " id="Kona">
              <div className="card">
                <div className="row">
                  <div className="col-md">
                    <img
                      id="Kona-gallery-1"
                      className="card-img-top img-fluid news-gallary-image active"
                      src="images/kona.jpg"
                      alt="Card image cap"
                    />
                  </div>
                </div>

                <div className="card-block">
                  <div className="card-content">
                    <div className="col">
                      <div className="row whiteBG">
                        <div className="col-lg-4">
                          <div className="salesText mt-3">
                            <p className="theme-news-snippet">
                              “It is an important milestone of Hyundai Motor’s
                              journey to become the leading Asian automotive
                              brand in Europe by 2021”
                            </p>
                          </div>
                          <hr />
                        </div>

                        <div className="col-lg-8 border-lg-left">
                          <div className="row pt-3">
                            <div className="col-md-10 offset-md-1">
                              <p className="salesText" data-search-tag="title">
                                HYUNDAI MOTOR EXPANDS ITS SUV FAMILY WITH THE
                                ALL-NEW HYUNDAI KONA
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <div className="theme-news-body-text">
                                <ul>
                                  <li>
                                    The All-New Kona expands Hyundai Motor’s SUV
                                    family with a unique sub–compact SUV.
                                  </li>
                                  <li>
                                    It is an important milestone of Hyundai
                                    Motor’s journey to become the leading Asian
                                    automotive brand in Europe by 2021
                                  </li>
                                  <li>
                                    The name Kona is derived from the Kona
                                    district on the Big Island of Hawaii,
                                    reflecting the island’s energetic image and
                                    unique lifestyle.
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div className="row pt-3">
                            <div className="col-md-10 offset-md-1">
                              <p
                                className="theme-news-body-text"
                                data-search-tag="summary"
                              >
                                <strong>High Wycombe, 03 April 2017 –</strong>{" "}
                                Hyundai Motor announces the name of its new
                                sub-compact SUV: The All-New Hyundai Kona. The
                                all-new B-SUV will be the fourth member of the
                                brand’s SUV family in Europe consisting of Grand
                                Santa Fe, Santa Fe and Tucson. It features a
                                progressive style that reflects the lifestyle of
                                modern customers.
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <div className="theme-news-body-text">
                                <p>
                                  The All-New Hyundai Kona is an important
                                  milestone of Hyundai Motor’s journey to become
                                  the leading Asian automotive brand in Europe
                                  by 2021 launching 30 new models and
                                  derivatives. Building on Hyundai Motor’s SUV
                                  credibility with more than 1.4 million sales
                                  since the introduction of the first generation
                                  Santa Fe in 2001, the All-New Hyundai Kona is
                                  a true SUV.
                                </p>
                                <p>
                                  The name Kona continues Hyundai Motor’s SUV
                                  naming strategy and is derived from the Kona
                                  district on the Big Island of Hawaii. The
                                  island’s energetic image and unique lifestyle
                                  matches the All-New Hyundai Kona’s dynamic
                                  character.
                                </p>
                                <p>
                                  Its true SUV genes, progressive design and
                                  premium features enhance the brand’s appeal to
                                  attract new customers.
                                </p>
                                <p>
                                  Further news about the All-New Hyundai Kona
                                  will be released in the near future.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
