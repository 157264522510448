import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';

export default class Fountain extends Component {
  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="fountain" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="fountain-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/fountainimage1_920x322.png") ',
              }}
            />
            <div
              id="fountain-gallery-2"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/fountainimage2_920x322.png") ',
              }}
            />
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">

              <div className="d-flex flex-row justify-content-between flex-wrap">
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#fountain-gallery-2"
                    style={{
                      backgroundImage:
                        'url("images/fountainimage2_920x322.png") ',
                    }}
                  />
                </div>
                 <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#fountain-gallery-1"
                    style={{
                      backgroundImage:
                        'url("images/fountainimage1_920x322.png") ',
                    }}
                  />
                </div>

</div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “Hyundai Motor, Tate Unveil Kara Walker’s Monumental Fountain for the Hyundai Commission.”
                </p>
              </div>
              <hr />

              <NewsMedia
                articleId="fountain"
                articleText="Hyundai Motor, Tate Announce the Opening of the Hyundai Commission by Kara Walker"
              />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">                    
                    HYUNDAI MOTOR, TATE ANNOUNCE THE OPENING OF THE HYUNDAI COMISSION BY KARA WALKER                   
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <ul
                    className="theme-news-body-text"
                    data-search-tag="summary"
                  >
                    <li>
                      <strong>
                        "Hyundai Commission: Kara Walker: Fons Americanus” opens from Oct. 2 through Apr. 5, 2020 in Tate Modern’s Turbine Hall
                      </strong>
                    </li>
                    <li>
                      <strong>
                        The soaring fountain explores the transatlantic slave trade and the interconnected histories of Africa, America, and Europe
                      </strong>
                    </li>
                    <li>
                      <strong>
                        Walker, an artist acclaimed for her provocative and candid investigations of race, sexuality and violence, creates one of the most ambitious Hyundai Commission works to date
                      </strong>
                    </li>
                  </ul>

                  {displayShowMore && (
                    <p>
                      <a
                        data-toggle="collapse"
                        href="#fountain"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                  {/* <p className="theme-news-body-text">The i30 Fastback’s sloping roofline, long bonnet and muscular body, along with its slim cabin and wide wheel arches give the car a strong stance. That strength, however, is far from being merely skin deep.</p> */}
                </div>
              </div>

              <div className={moreSectionClassName} id="fountain">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      <strong>SEOUL, Sept. 30, 2019</strong> — Hyundai Motor and Tate Modern today announced the opening of the fifth Hyundai Commission as Tate Modern unveiled a monumental 13-meter-high fountain, created by Kara Walker. Best known for her provocative and candid investigations of race, sexuality and violence through the history of slavery, Walker has now created one of the most ambitious Hyundai Commissions to date. Fons Americanus presents an origin story of the African diaspora and poses timely questions about what is remembered and what is forgotten in public monuments. It is the fifth annual Hyundai Commission, a series of site-specific works created for the Turbine Hall by renowned international artists, as part of a unique long-term partnership between Tate and Hyundai Motor.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      Standing at the far end of the Turbine Hall, Fons Americanus is a soaring fountain that rises from two oval basins filled with water. Sculptural figures occupy these basins and all four sides of the multi-tiered central pedestal, with water spouting from the top figure. Its form is inspired by the Victoria Memorial in front of Buckingham Palace, designed in 1901 and unveiled in 1911 to honour the achievements of Queen Victoria. Rather than a celebration of the British Empire, Walker’s work offers a powerful critique, inverting the usual function of memorials and questioning the narratives of power they present. The fountain is an allegory of the Black Atlantic, exploring the interconnected histories of Africa, America and Europe through fact, fiction and fantasy. Using water as a key theme, the artist references the transatlantic slave trade and the ambitions, fates and tragedies of people from these three continents.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      The sculptural figures covering the monument are derived from an array of art historical, literary and cultural sources. These include J.M.W. Turner’s Slave Ship 1840, Winslow Homer’s Gulf Stream 1899 and Damien Hirst’s formaldehyde shark The Physical Impossibility of Death in the Mind of Someone Living 1991. The 19th century slave propaganda image “The Voyage of the Sable Venus from Angola to the West Indies” is a particular source of inspiration, alongside other images of the Roman goddess Venus standing on a seashell. The figure of Venus stands proudly at the top of Walker’s fountain, recast as a priestess from Afro-Brazilian and Caribbean religions. Her scalloped shell becomes a smaller second sculpture located in front of the Turbine Hall bridge, in which the face of a crying boy emerges from a pool of water, filling the shell with his tears.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      The full title of the work is painted on the Turbine Hall wall, written in Walker’s own words. Encouraging visitors to confront a history often overlooked in Britain, she presents the artwork as a “gift … to the heart of an Empire that redirected the fates of the world”. She has signed the work ‘Kara Walker, NTY’ (Not Titled Yet), in a play on British honours awards such as ‘OBE’ (Order of the British Empire).
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      Hyundai Commission: Kara Walker: Fons Americanus is curated by Clara Kim, The Daskalopoulos Senior Curator, International Art (Africa, Asia & Middle East), Tate Modern with Priyesh Mistry, Assistant Curator, International Art, Tate Modern and Petra Schmidt, Production Manager, Tate Modern. In partnership with Hyundai Motor. Supported by Sikkema Jenkins & Co. with additional support from Tate Americas Foundation. The work has been created with sustainable non-toxic materials, including recyclable cork, wood and metal, coated in a solvent-free acrylic and cement composite.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      Hyundai Commission: Kara Walker © Ben Fisher Photography.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
