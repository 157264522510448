import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';
import 'babel-polyfill';
import 'isomorphic-fetch';
import 'url-search-params-polyfill';
import './App.css';
import Home from './pages/home';
import Login from './pages/login';
import Registration from './pages/registration';
import Activation from './pages/activation';
import NewsPast from './pages/newsPast';
import Personal from './pages/personal';
import MyCarDetails from './pages/myCarDetails';
import MyReminders from './pages/myReminders';
import ServiceHistory from './pages/serviceHistory';
import ContactUs from './pages/contactUs';
import HyundaiNews from './pages/hyundaiNews';
import ProductNews from './pages/productNews';
import AwardsNews from './pages/awardsNews';
import WHNGrid from './pages/whnGrid';
import WHNArticle from './pages/whnArticle';
import ArchivedHyundaiNews from './pages/ArchivedHyundaiNews';
import ArchivedProductNews from './pages/ArchivedProductNews';
import ArchivedAwardsNews from './pages/ArchivedAwardsNews';
import ContactPref from './pages/contactPref';
import Interests from './pages/interests';
import DealerDetails from './pages/dealerDetails';
import CompetitionPage from './pages/CompetitionPage';
import CompsOverview from './pages/compsOverview';
import PrivateRoute from './PrivateRoute';
import PasswordReset from './pages/passwordReset';
import Offers from './pages/offers';
import OffersMerchandise from './pages/offersMerchandise';
import OffersHybrid from './pages/offersHybrid';

import OffersTate from './pages/offersTate';
import OffersChelsea from './pages/offersChelsea';
import OffersFebruary from './pages/offersFebruary';

import offersSummerCheck from './pages/offersSummerCheck';

import ErrorBoundry from './components/errorBoundry';
import Error from './pages/error';
import Tracker from './components/tracker';
import Incentives from './pages/incentives';
import SearchResults from './pages/searchResults';
import MyQuotes from './pages/myQuotes';
import MyTestDrives from './pages/myTestDrives';
import MyBrochures from './pages/myBrochures';
import CarsConfigured from './pages/carsConfigured';
import MyHandbook from './pages/myHandbook';
import FuelCalculator from './pages/fuelCalculator';

import CompetitionRedeem from './pages/CompetitionRedeem';
import ServicePlans from './pages/servicePlans.jsx';
import EssentialPlans from './pages/essentialPlans.jsx';
import PodCharging from './pages/podCharging';
import DealerDetailsPortal from './pages/dealerDetailsPortal.jsx';
import PeaceOfMind from './pages/peaceOfMind';
import Feedback from './pages/feedback';
import Store from 'store';
import ManageMyAccount from './pages/ManageMyAccount';
import CarOffers from './pages/CarOffers';
import PublicConfig from './utils/Config';

import ReactPixel from 'react-facebook-pixel';

class App extends Component {
  state = {
    gaTrackingCode: 'UA-29003542-1',

    isAnyVehicleOverFiveYears:
      (Store.get('vehiclesInfo') &&
        Store.get('vehiclesInfo').isAnyVehicleOverFiveYears) ||
      false,
    isAnyVehicleUnderFiveYears:
      (Store.get('vehiclesInfo') &&
        Store.get('vehiclesInfo').isAnyVehicleUnderFiveYears) ||
      false,
    fromFacebook:
      (Store.get('campaign_name') &&
        Store.get('campaign_name') === 'facebook') ||
      false,
  };

  componentDidMount = () => {
    const options = {
      autoConfig: true, // set pixel's autoConfig
      debug: false, // enable logs
    };
    PublicConfig.load();
    ReactPixel.init('611000135904545', options);
    ReactPixel.pageView();
  };

  setFromFacebook = () => {
    return this.setState({ fromFacebook: true });
  };

  setVehicleInfo = () => {
    return this.setState({
      isAnyVehicleOverFiveYears:
        (Store.get('vehiclesInfo') &&
          Store.get('vehiclesInfo').isAnyVehicleOverFiveYears) ||
        false,
      isAnyVehicleUnderFiveYears:
        (Store.get('vehiclesInfo') &&
          Store.get('vehiclesInfo').isAnyVehicleUnderFiveYears) ||
        false,
    });
  };

  render() {
    if (
      window.location.pathname &&
      (window.location.pathname.toLowerCase() === '/myhyundaiuk-preprod' ||
        window.location.pathname.toLowerCase() === '/myhyundaiuk-staging' ||
        window.location.pathname.toLowerCase() === '/myhyundaiuk' ||
        window.location.pathname.toLowerCase() === '/myhyundaiuk-phase2')
    ) {
      window.location.href = window.location.href + '/';
      return null;
    }

    return (
      <Router basename={process.env.REACT_APP_BASENAME}>
        <Tracker gaTrackingCode={this.state.gaTrackingCode}>
          <Route exact path="/error" component={Error} />
          <ErrorBoundry gaTrackingCode={this.state.gaTrackingCode}>
            <Switch>
              <Route
                exact
                path="/login"
                render={(props) => (
                  <Login
                    {...props}
                    setFromFacebook={() => this.setFromFacebook()}
                    setVehicleInfo={() => this.setVehicleInfo()}
                  />
                )}
              />
              <PrivateRoute exact path="/" component={Home} />
              <Route exact path="/registration" component={Registration} />
              <Route exact path="/activation" component={Activation} />
              <Route exact path="/passwordReset" component={PasswordReset} />
              <PrivateRoute
                exact
                path="/servicePlans"
                component={ServicePlans}
              />
              {/* 
              // Any car greater than 5 years and a day 
              // Or from facebook 
              */}
              {(this.state.isAnyVehicleOverFiveYears ||
                this.state.fromFacebook) && (
                <PrivateRoute
                  exact
                  path="/essentialPlans"
                  component={EssentialPlans}
                />
              )}
              <PrivateRoute exact path="/newsPast" component={NewsPast} />
              <PrivateRoute exact path="/personal" component={Personal} />
              <PrivateRoute
                exact
                path="/manageMyAccount"
                component={ManageMyAccount}
              />
              <PrivateRoute exact path="/myQuotes" component={MyQuotes} />
              <PrivateRoute exact path="/myBrochures" component={MyBrochures} />
              <PrivateRoute
                exact
                path="/carsConfigured"
                component={CarsConfigured}
              />
              <PrivateRoute
                exact
                path="/myTestDrives"
                component={MyTestDrives}
              />
              <PrivateRoute
                exact
                path="/myCarDetails"
                component={MyCarDetails}
              />
              <PrivateRoute
                exact
                path="/myReminders"
                component={MyReminders}
                onEnter={this.handleOnChange}
              />
              <PrivateRoute
                exact
                path="/serviceHistory"
                component={ServiceHistory}
              />
              <PrivateRoute exact path="/offers" component={Offers} />
              <PrivateRoute
                exact
                path="/offersMerchandise"
                component={OffersMerchandise}
              />
              <Route
                exact
                path="/offersSuvSantaFe"
                render={() => <Redirect to="/offers" />}
              />
              <Route
                exact
                path="/offersSuvKona"
                render={() => <Redirect to="/offers" />}
              />
              <Route
                exact
                path="/offersSuvTucson"
                render={() => <Redirect to="/offers" />}
              />
              <PrivateRoute
                exact
                path="/offersHybrid"
                component={OffersHybrid}
              />
              <PrivateRoute exact path="/offersTate" component={OffersTate} />
              <PrivateRoute
                exact
                path="/offersFebruary"
                component={OffersFebruary}
              />
              <PrivateRoute
                exact
                path="/offersSummerCheck"
                component={offersSummerCheck}
              />
              <PrivateRoute
                exact
                path="/offersChelsea"
                component={OffersChelsea}
              />
              <Route exact path="/incentives" component={Incentives} />
              <PrivateRoute exact path="/contactUs" component={ContactUs} />
              <PrivateRoute exact path="/hyundaiNews" component={HyundaiNews} />
              <PrivateRoute exact path="/productNews" component={ProductNews} />
              <PrivateRoute exact path="/awardsNews" component={AwardsNews} />
              <Route
                exact
                path="/newsletter"
                render={(props) => (
                  <WHNGrid
                    {...props}
                    setFromFacebook={() => this.setFromFacebook()}
                    setVehicleInfo={() => this.setVehicleInfo()}
                  />
                )}
              />
              <Route
                exact
                path="/newsletter/:id"
                render={(props) => (
                  <WHNArticle
                    {...props}
                    setFromFacebook={() => this.setFromFacebook()}
                    setVehicleInfo={() => this.setVehicleInfo()}
                  />
                )}
              />
              <PrivateRoute exact path="/peaceOfMind" component={PeaceOfMind} />
              <PrivateRoute exact path="/feedback" component={Feedback} />
              <PrivateRoute exact path="/podCharging" component={PodCharging} />
              <PrivateRoute
                exact
                path="/archivedHyundaiNews"
                component={ArchivedHyundaiNews}
              />
              <PrivateRoute
                exact
                path="/archivedProductNews"
                component={ArchivedProductNews}
              />
              <PrivateRoute
                exact
                path="/archivedAwardsNews"
                component={ArchivedAwardsNews}
              />
              <PrivateRoute exact path="/contactPref" component={ContactPref} />
              <PrivateRoute exact path="/interests" component={Interests} />
              <PrivateRoute
                exact
                path="/searchResults"
                component={SearchResults}
              />
              <PrivateRoute
                exact
                path="/dealerDetails"
                component={DealerDetails}
              />
              <PrivateRoute exact path="/myHandbook" component={MyHandbook} />
              <PrivateRoute
                exact
                path="/fuelCalculator"
                component={FuelCalculator}
              />
              <Route
                exact
                path="/competitionBBQ"
                render={() => <Redirect to="/compsOverview" />}
              />
              <PrivateRoute
                exact
                path="/competition"
                component={CompetitionPage}
              />
              <Route
                exact
                path="/Account/NewMyCompetition"
                render={() => <Redirect to="/compsOverview" />}
              />
              <Route
                exact
                path="/offersService"
                render={() => <Redirect to="/offers" />}
              />
              <PrivateRoute
                exact
                path="/compsOverview"
                component={CompsOverview}
              />
              <PrivateRoute
                exact
                path="/competitionredeem"
                component={CompetitionRedeem}
              />
              <PrivateRoute
                exact
                path="/dealerDetailsPortal"
                component={DealerDetailsPortal}
              />
              <PrivateRoute exact path="/carOffers" component={CarOffers} />
              <Redirect to="/" />
            </Switch>
          </ErrorBoundry>
        </Tracker>
      </Router>
    );
  }
}

export default App;
