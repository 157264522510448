import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';
import Store from 'store';
import ReactGA from 'react-ga';
export default class ElectricSUV extends Component {
  componentDidMount = () => {};

  clickHandler = () => {};

  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="electricSUV-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/suvheader.png") ',
              }}
            />
            <div
              id="electricSUV-gallery-2"
              className="theme-news-header-image "
              style={{
                backgroundImage: 'url("images/suv1.png") ',
              }}
            />
            <div
              id="electricSUV-gallery-3"
              className="theme-news-header-image "
              style={{
                backgroundImage: 'url("images/suv2.png") ',
              }}
            />
            <div
              id="electricSUV-gallery-4"
              className="theme-news-header-image "
              style={{
                backgroundImage: 'url("images/suv3.png") ',
              }}
            />
            <div
              id="electricSUV-gallery-5"
              className="theme-news-header-image "
              style={{
                backgroundImage: 'url("images/suv4.png") ',
              }}
            />
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#electricSUV-gallery-1"
                    style={{
                      backgroundImage: 'url("images/suvheader.png") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#electricSUV-gallery-2"
                    style={{
                      backgroundImage: 'url("images/suv1.png") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#electricSUV-gallery-3"
                    style={{
                      backgroundImage: 'url("images/suv2.png") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#electricSUV-gallery-4"
                    style={{
                      backgroundImage: 'url("images/suv3.png") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#electricSUV-gallery-5"
                    style={{
                      backgroundImage: 'url("images/suv4.png") ',
                    }}
                  />
                </div>
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “Hyundai Motor Teases Sneak Peek of SEVEN, All-Electric SUV
                  Concept ”
                </p>
              </div>
              <hr />

              <NewsMedia articleId="awards" articleText="awards" />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    HYUNDAI MOTOR TEASES SNEAK PEEK OF SEVEN, ALL-ELECTRIC SUV
                    CONCEPT
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    {' '}
                    <ul>
                      <li>
                        SEVEN concept previews design and technology innovations
                        of Hyundai Motor’s upcoming all-electric SUV
                      </li>
                      <li>
                        New SUV typology optimized for dedicated BEVs, combined
                        with new interior architecture evoking a premium lounge
                        experience
                      </li>
                      <li>
                        Hyundai to debut SEVEN concept during AutoMobility LA
                        press conference at 9:55 a.m., Nov. 17 (PT)
                      </li>
                    </ul>
                  </p>
                  {displayShowMore && (
                    <p>
                      <a
                        data-toggle="collapse"
                        href="#electricSUV"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </div>

              <div className={moreSectionClassName} id="electricSUV">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      3rd November 2021 -{' '}
                      <strong>
                        – Hyundai Motor Company today revealed teaser images of
                        the sleek and spacious SEVEN, an all-electric SUV
                        concept to be fully unveiled at AutoMobility LA later
                        this month.
                      </strong>
                      <br />
                      <br />
                      SEVEN captures Hyundai Motor’s future design and
                      technology innovation in the electric mobility era and
                      hints at a new SUV model coming to the IONIQ family, the
                      company’s dedicated battery electric vehicle (BEV) brand.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      <img src="images/suv1.png" className="img-fluid" />
                      <br />
                      <br />
                      The teased images reveal a form following function design
                      that dares to diverge from traditions of the internal
                      combustion engine era. SEVEN’s lighting architecture is
                      defined by Parametric Pixels, IONIQ’s unique design
                      identity connecting analog with digital emotions.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      <img src="images/suv2.png" className="img-fluid" />
                      <br />
                      <br />
                      SEVEN’s interior suggests a premium and personalized
                      lounge ambience, deepening the space innovation that
                      Hyundai Motor has shown with IONIQ 5.
                      <br />
                      <br />
                      <img src="images/suv3.png" className="img-fluid" />
                      <br />
                      <br />
                      Furnishings trimmed in sustainable materials offer a
                      refined, eco-friendly mobility experience that reflects
                      the changing lifestyles of its customers.
                      <br />
                      <br />
                      <img src="images/suv4.png" className="img-fluid" />
                      <br />
                      <br />
                      Hyundai Motor will debut SEVEN during the AutoMobility LA
                      press conference at 9:55 a.m., November 17 (PT).
                      Journalists can attend the conference by visiting
                      Hyundai’s booth at the LA Convention Center or watch it
                      digitally. SEVEN will remain on display from November 19
                      to 28 for the general public to view.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
