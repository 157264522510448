import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

export default class NewsNavbar extends Component {
  render() {
    return (
      <div className="container" data-search-exclude>
        <nav className="navbar navbar-expand-lg navbar-light bg-light p-0 dropShadow secondary-nav">
          <div className="slantedDiv">My Hyundai</div>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto navbarText p-3 p-lg-0">
              {/* <li className="nav-item pr-5">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/productNews"
                >
                  Product News
                </NavLink>
              </li> */}
              <li className="nav-item pr-5">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/hyundaiNews"
                >
                  Hyundai News
                </NavLink>
              </li>

              <li className="nav-item pr-5">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/awardsNews"
                >
                  Awards
                </NavLink>
              </li>

              {/* <li className="nav-item pr-5">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/newsletter"
                >
                  MyHyundai Magazine
                </NavLink>
              </li> */}
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}
