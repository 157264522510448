import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';

export default class UrbanMobility extends Component {
  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="UrbanMobility" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="urbanMobility-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/urbanmobility.jpg") ',
              }}
            />
            <div
              id="urbanMobility-gallery-2"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/urbanMobility1.jpg") ',
              }}
            />
            <div
              id="urbanMobility-gallery-3"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/urbanMobility2.jpg") ',
              }}
            />
            <div
              id="urbanMobility-gallery-4"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/urbanMobility3.jpg") ',
              }}
            />
            <div
              id="urbanMobility-gallery-5"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/urbanMobility4.jpg") ',
              }}
            />
            <div
              id="urbanMobility-gallery-6"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/urbanMobility5.jpg") ',
              }}
            />
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap"></div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “The Future According to Hyundai.”
                </p>
              </div>
              <hr />

              <NewsMedia
                articleId="urbanMobility"
                articleText="Hyundai Motor, Tate Announce the Opening of the Hyundai Commission by Kara Walker"
              />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    THE FUTURE ACCORDING TO HYUNDAI
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text">
                    Hyundai has unveiled an innovative vision for urban mobility
                    that will free future cities and people from constraints of
                    time and space, and allow them to create more value in their
                    lives. The vision to help vitalise human-centred future
                    cities was unveiled at the CES show in Las Vegas in early
                    January along with a pledge from Hyundai to accelerate the
                    implementation of its brand vision ‘Progress for Humanity’
                    and transform itself into a ‘Smart Mobility Solution
                    Provider’.
                  </p>

                  {displayShowMore && (
                    <p>
                      <a  
                        data-toggle="collapse"
                        href="#urbanMobility"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                  {/* <p className="theme-news-body-text">The i30 Fastback’s sloping roofline, long bonnet and muscular body, along with its slim cabin and wide wheel arches give the car a strong stance. That strength, however, is far from being merely skin deep.</p> */}
                </div>
              </div>

              <div className={moreSectionClassName} id="urbanMobility">
                {/* <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      <strong>SEOUL, Sept. 30, 2019</strong> — Hyundai Motor and Tate Modern today announced the opening of the fifth Hyundai Commission as Tate Modern unveiled a monumental 13-meter-high urbanMobility, created by Kara Walker. Best known for her provocative and candid investigations of race, sexuality and violence through the history of slavery, Walker has now created one of the most ambitious Hyundai Commissions to date. Fons Americanus presents an origin story of the African diaspora and poses timely questions about what is remembered and what is forgotten in public monuments. It is the fifth annual Hyundai Commission, a series of site-specific works created for the Turbine Hall by renowned international artists, as part of a unique long-term partnership between Tate and Hyundai Motor.
                    </p>
                  </div>
                </div> */}

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      Hyundai established a Human-Centred City Advisory Group,
                      bringing together top global experts from fields such as
                      psychology, architecture, urban design, transportation,
                      and political science, and conducted research on how
                      future cities should be designed to foster new
                      human-centred values. The advisory group settled on three
                      key values for future cities: vitalise, enable, and care,
                      with a priority placed on the ‘vitalisation’ of cities.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      “Based on advice from the advisory group, we have tried to
                      find mobility solutions that will vitalise communities in
                      a meaningful and imaginative way,” said Youngcho Chi,
                      President and Chief Innovation Officer at Hyundai Motor
                      Company.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      To realise the vision, Hyundai showcased three mobility
                      solutions at CES: Urban Air Mobility (UAM),which combines
                      Personal Air Vehicles (PAV) and urban air mobility
                      services to make airspace available for transportation;
                      Purpose Built Vehicles (PBV), which are ground-based
                      eco-friendly mobility solutions that provide customised
                      spaces and services for passengers in transit; and Hub, a
                      space that connects the two, allowing people to come
                      together and interact.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      <strong>Urban Air Mobility (UAM)</strong>
                      <br />
                      Urban Air Mobility offers ‘liberation from grid-lock’ and
                      ‘democratisation of flight’ thanks to runway-free urban
                      air travel with electric Personal Air Vehicles (PAV)
                      capable of vertical take off and landing.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      Hyundai is the first car company to join the Uber Elevate
                      initiative – which aims to develop Uber Air Taxis for a
                      future aerial ride share network – and has unveiled a
                      full-scale electric PAV concept, the S-A1. Hyundai’s fully
                      electric S-A1 model is designed to take off and land
                      vertically, cruise at speeds of up to 180mph at altitudes
                      between 1,000 and 2,000 feet, and has a range of up to 60
                      miles. The air taxi concept is powered by multiple rotors
                      and propellers to increase safety and reduce noise, has
                      been designed with four passenger seats, and although it
                      is intended to be piloted at first, it will become
                      autonomous over time.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      “Our vision of Urban Air Mobility will transform the
                      concept of urban transportation,” said Jaiwon Shin,
                      Executive Vice President and Head of Hyundai’s UAM
                      Division. "We are looking at the dawn of a completely new
                      era that will open the skies above our cities. Urban Air
                      Mobility will liberate people from grid-lock and reclaim
                      time for people to invest in activities they care about
                      and enjoy.”
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      <strong>Purpose Built Vehicles (PBV)</strong>
                      <br />
                      The fully-electric PBV is a new urban mobility solution
                      that can accommodate a wide variety of future lifestyles
                      with limitless personalisation. It allows passengers to
                      enjoy tailored services while traveling, offering a whole
                      new take on the concept of mobility beyond a means of
                      transportation.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      Hyundai's concept PBV embodies three key messages: city
                      icon, living space on wheels, and clustered mobility, and
                      can function as a restaurant, coffee shop, hotel, or even
                      a clinic and pharmacy, in addition to being an urban
                      shuttle.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      “The design comes alive and makes us feel connected to the
                      way we get around through a progressive reinterpretation
                      of its DNA and incorporation of the urban scenery,” said
                      SangYup Lee, Senior Vice President and Head of Hyundai
                      Global Design.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      The PBV can be adjusted in size from four to six metres
                      thanks to complete disassembly of its upper- and
                      under-bodies, while its interior can be personalised by
                      assembling modular parts, taking the PBV beyond
                      transportation into the realm of living space.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      <strong>Hub</strong>
                      <br />
                      With a skyport on the roof and docking stations on the
                      ground, the Hub is Hyundai’s innovative new community
                      space that connects air-based PAVs and ground-based PBVs.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      The Hub can be transformed into a variety of spaces
                      depending on how the PBVs are connected. It could be
                      turned into a cultural complex by bringing together PBVs
                      functioning as concert halls, movie theatres, and museums,
                      for example, or transformed into a medical complex by
                      connecting medical service PBVs in the form of clinics,
                      doctor’s offices and pharmacies.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      Hyundai plans to place Hubs throughout future cities and
                      build a new mobility ecosystem around the UAM-PBV-Hub
                      network. The three concepts will work closely together to
                      play key roles in vitalising human-centred future cities
                      and enriching people’s lives.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
