import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class CarOfferItem extends Component {
  static propTypes = {
    model: PropTypes.string.isRequired,
    image: PropTypes.string,
    content: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    linkText: PropTypes.string,
  };

  render = () => {
    const { model, image, content, link, linkText } = this.props;
    const contentSplit = content.match(/.{1,46}(\s+|$)/g);

    return (
      <div className="theme-car-offer-item">
        <h4 className="theme-car-offer-item-title text-center pt-4 pb-3 m-0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0  0 370 30"
            height="31"
            width="100%"
          >
            <g>
              <text x="0" y="0" textAnchor="middle">
                <tspan
                  x="185"
                  dy="1em"
                  className="font-weight-bold theme-fill-primary"
                >
                  {model}
                </tspan>
              </text>
            </g>
          </svg>
        </h4>
        <div className="theme-car-offer-item-image-container">
          <img className="theme-car-offer-item-image px-5" src={image} />
        </div>
        <div className="theme-car-offer-item-content text-center ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0  0 400 280"
            preserveAspectRatio="xMidYMid meet"
          >
            <g>
              <text
                textAnchor="middle"
                fill="#6c757d"
                fontSize="14"
                x="0"
                y="0"
                // dy="0.35em"
              >
                {contentSplit.map((content, index) => {
                  return (
                    <tspan x="200px" dy="1.4em" key={index}>
                      {content}
                    </tspan>
                  );
                })}
              </text>
            </g>
          </svg>
        </div>
        <div className="theme-car-offer-item-button-container text-center ">
          <a  
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-primary btn-sm removeRadius w-100"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0  0 152 31"
              preserveAspectRatio="xMidYMid meet"
            >
              <g>
                <text x="0" y="0" fill="white" textAnchor="middle">
                  <tspan x="76" dy="1.4em">
                    {linkText ? linkText : 'Find out more'}
                  </tspan>
                </text>
              </g>
            </svg>
          </a>
        </div>
      </div>
    );
  };
}
