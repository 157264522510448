import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ArchiveNewsNavbar from '../components/ArchiveNewsNavbar';
import FooterNavbar from '../components/footerNavbar';
import Topnavbar from '../components/topnavbar';
import Bottomnavbar from '../components/bottomnavbar';
import Footer from '../components/footer';
import './news.css';

import NewsUtils from '../utils/News';

// Hyundai News -----------------------------------------------------------------------  ?

// Archived Hyundai News --------------------------------------------------------------
import GroundbreakingIoniq from '../components/news/archivedHyundaiNews/groundbreakingIoniq';
import SutCancer from '../components/news/archivedHyundaiNews/sutCancer';
import AutonomousIoniq from '../components/news/archivedHyundaiNews/autonomousIoniq'; // Archived product news also?
import Hy2017GenevaMotorShow from '../components/news/archivedHyundaiNews/hy2017GenevaMotorShow';
import NextGenFuel from '../components/news/archivedHyundaiNews/nextGenFuel'; // Archived product news also?
import WearableRobots from '../components/news/archivedHyundaiNews/wearableRobots';
import MexicanPodium from '../components/news/archivedHyundaiNews/mexicanPodium';
import RallyeSanremo from '../components/news/archivedHyundaiNews/rallyeSanremo';
import Superflex from '../components/news/archivedHyundaiNews/superflex';
import FutureOfMobility from '../components/news/archivedHyundaiNews/futureOfMobility';

// Product News -----------------------------------------------------------------------  ?

// Archived Product News --------------------------------------------------------------
import NewGenSantaFe from '../components/news/archivedProductNews/newGenSantaFe';
import TucsonHybrid from '../components/news/archivedProductNews/tucsonHybrid';
import PlayTime2 from '../components/news/archivedProductNews/playTime2';
import NewGenerationSantaFe from '../components/news/archivedProductNews/newGenerationSantaFe';
import GoEditions from '../components/news/archivedProductNews/goEditions';
// import FuelCell from '../components/news/archivedProductNews/fuelCell';
import I30FastbackLineUp from '../components/news/archivedProductNews/i30FastBack2';
import KonaElectric from '../components/news/archivedProductNews/konaElectric';
import AllNewKona from '../components/news/archivedProductNews/allNewKona';
import Kona from '../components/news/archivedProductNews/kona';
import I3024hrRace from '../components/news/archivedProductNews/i3024hrRace';
import SouthPole from '../components/news/archivedProductNews/southPole';
import NewGeni30Tourer from '../components/news/archivedProductNews/newGeni30Tourer';
import IoniqPlugin from '../components/news/archivedProductNews/ioniqPlugin';
import WinterTestingSweden from '../components/news/archivedProductNews/winterTestingSweden';
import I30WagonMotorShow from '../components/news/archivedProductNews/i30WagonMotorShow';

// Awards -----------------------------------------------------------------------------  ?
import IoniqBest from '../components/news/awards/ioniqBest';

// Archived Awards --------------------------------------------------------------------
import I10HighlyRated from '../components/news/archivedAwards/i10HighlyRated';
import TucsonLongDistance from '../components/news/archivedAwards/tucsonLongDistance';
import AutoExpressAwards from '../components/news/archivedAwards/autoExpressAwards';
import FourthTimei10 from '../components/news/archivedAwards/fourthTimei10';
import EnviroLeadership from '../components/news/archivedAwards/enviroLeadership';
import IoniqBestSmallHatch from '../components/news/archivedAwards/ioniqBestSmallHatch'; // Archived product news also?
import IoniqBestCityCar from '../components/news/archivedAwards/ioniqBestCityCar'; // Archived product news also?
import IoniqGreenCarAwards from '../components/news/archivedAwards/ioniqGreenCarAwards'; // Archived product news also?

export default class NewsPast extends Component {
  constructor(props) {
    super(props);

    this.myRef = React.createRef();

    // const displayCategory = this.urlQuery();

    const displayCategory =
      this.props.location.query != null &&
      this.props.location.query.category != null
        ? this.props.location.query.category
        : 'hyundai';

    const displayItemName =
      this.props.location.query != null &&
      this.props.location.query.displayItem != null
        ? this.props.location.query.displayItem
        : null;

    const hyundai = [
      {
        name: 'GroundbreakingIoniq',
        linkText: 'Another World First for Grounbreaking Ioniq',
        date: 'June 2017',
        component: <GroundbreakingIoniq />,
        image: 'images/groundBreakingIoniq.jpg',
      },
      {
        name: 'SutCancer',
        linkText:
          'Hyundai Motor UK Has Joined the Rebellion Against Cancer by Becoming an Official Partner of Stand up to Cancer 2017',
        date: 'May 2017',
        component: <SutCancer />,
        image: 'images/sutCancer.jpg',
      },
      {
        name: 'AutonomousIoniq',
        linkText:
          'Hyundai Motor Presents Autonomous Ioniq at the 2017 Geneva Motor Show',
        date: 'March 2017',
        component: <AutonomousIoniq />,
        image: 'images/autonomousIoniq.jpg',
      },
      {
        name: 'Hy2017GenevaMotorShow',
        linkText: 'Hyundai Motor at the 2017 Geneva International Motor Show',
        date: 'March 2017',
        component: <Hy2017GenevaMotorShow />,
        image: 'images/hy2017GenMotorShow.jpg',
      },
      {
        name: 'NextGenFuel',
        linkText:
          'Hyundai Motor Reveals Next Generation Fuel Cell Concept at 2017 Geneva Motor Show',
        date: 'March 2017',
        component: <NextGenFuel />,
        image: 'images/nextGenFuel.jpg',
      },
      {
        name: 'WearableRobots',
        linkText:
          'Hyundai Motor Showcases Advanced Wearable Robots at Geneva Motor Show',
        date: 'March 2017',
        component: <WearableRobots />,
        image: 'images/wearableRobots.jpg',
      },
      {
        name: 'MexicanPodium',
        linkText:
          'Hyundai Motorsport Secures Mexican Podium and Power Stage Victory',
        date: 'March 2017',
        component: <MexicanPodium />,
        image: 'images/mexicanPodium.jpg',
      },
      {
        name: 'RallyeSanremo',
        linkText:
          'Hayden Paddon to Run Rallye Sanremo with New Generation i20 R5',
        date: 'March 2017',
        component: <RallyeSanremo />,
        image: 'images/rallyeSanremo.jpg',
      },
      {
        name: 'Superflex',
        linkText:
          'Hyundai Commission Enlists Superflex for 2017 Tate Modern Exhibition',
        date: 'March 2017',
        component: <Superflex />,
        image: 'images/superflex2017.jpg',
      },
      {
        name: 'FutureOfMobility',
        linkText:
          'Hyundai Motor Reveals Vision for ‘Future Mobility’ at the 2017 Consumer Electronics Show',
        date: 'January 2017',
        component: <FutureOfMobility />,
        image: 'images/futureOfMobility2017.jpg',
      },
    ];
    const product = [
      {
        name: 'PlayTime2',
        linkText: 'Play time',
        date: 'May 2018',
        component: <PlayTime2 />,
        image: 'images/i30n_hero.jpg',
      },
      {
        name: 'TucsonHybrid',
        linkText: 'Take a walk on the mild side',
        date: 'May 2018',
        component: <TucsonHybrid />,
        image: '',
      },
      {
        name: 'I30FastbackLineUp',
        linkText: "'Sophisticated' I30 Fastback Completes the Line-up",
        date: 'April 2018',
        component: <I30FastbackLineUp />,
        image: 'images/autonomousIoniq.jpg',
      },
      {
        name: 'NewGenerationSantaFe',
        linkText: 'New Generation Santa Fe Joins Ranks of Fab Four',
        date: 'April 2018',
        component: <NewGenerationSantaFe />,
        image: 'images/SantaFE.jpg',
      },
      // {
      //   name: 'FuelCell',
      //   linkText: 'NEXO: the future of driving',
      //   date: 'April 2018',
      //   component: <FuelCell />,
      //   image: 'images/HYUNDAI Nexo Vorstellung FINAL-1080p 30Mbit.mp4',
      // },
      {
        name: 'GoEditions',
        linkText: 'On your marks, get set, Go',
        date: 'April 2018',
        component: <GoEditions />,
        image: 'images/headerimage.png',
      },
      {
        name: 'KonaElectric',
        linkText: 'A Car of No Compromise: All-New Hyundai Kona Electric',
        date: 'February 2018',
        component: <KonaElectric />,
        image: 'images/konaElectric.jpg',
      },
      {
        name: 'IoniqBest',
        linkText: 'It’s Official! Ioniq Is the UK’s Best Hybrid',
        date: 'January 2018',
        component: <IoniqBest />,
        image: 'images/i10BestCityCar.jpg',
      },
      {
        name: 'NewGenSantaFe',
        linkText:
          'Hyundai Motor Releases First Teaser Image of the Fourth Generation Santa Fe',
        date: 'January 2018',
        component: <NewGenSantaFe />,
        image: 'images/ngSantaFe.jpg',
      },
      {
        name: 'FourthTimei10',
        linkText: 'i10 Wins Award for the Fourth Time Running. Honest!',
        date: 'June 2017',
        component: <FourthTimei10 />,
        image: 'images/i10FourthTime.jpg',
      },
      {
        name: 'AllNewKona',
        linkText: 'It’s Here! All-New Kona Is Unveiled in Milan',
        date: 'June 2017',
        component: <AllNewKona />,
        image: 'images/newKona.jpg',
      },
      {
        name: 'Kona',
        linkText:
          'Hyundai Motor Expands Its Suv Family with the All-New Hyundai Kona',
        date: 'April 2017',
        component: <Kona />,
        image: 'images/kona.jpg',
      },
      {
        name: 'I3024hrRace',
        linkText:
          'Hyundai Motor to Test Close to Production i30 N in Endurance Race in Nürburgring Ahead of 24 Hours Race Participation',
        date: 'April 2017',
        component: <I3024hrRace />,
        image: 'images/24hrRace.jpg',
      },
      {
        name: 'SouthPole',
        linkText:
          'Unleash Your Inner Explorer: Own the Family Car That Drove to the South Pole and Back',
        date: 'April 2017',
        component: <SouthPole />,
        image: 'images/southPole.jpg',
      },
      {
        name: 'AutonomousIoniq',
        linkText:
          'Hyundai Motor Presents Autonomous Ioniq at the 2017 Geneva Motor Show',
        date: 'March 2017',
        component: <AutonomousIoniq />,
        image: 'images/autonomousIoniq.jpg',
      },
      {
        name: 'NewGeni30Tourer',
        linkText:
          'New Generation Hyundai i30 Tourer: Elegance Meets Versatility',
        date: 'March 2017',
        component: <NewGeni30Tourer />,
        image: 'images/ngi30Tourer.jpg',
      },
      {
        name: 'IoniqPlugin',
        linkText:
          'Combining the Best of Both Worlds: Ioniq Plug-In at the 2017 Geneva International Motor Show',
        date: 'March 2017',
        component: <IoniqPlugin />,
        image: 'images/ioniqPlugin.jpg',
      },
      {
        name: 'Hy2017GenevaMotorShow',
        linkText: 'Hyundai Motor at the 2017 Geneva International Motor Show',
        date: 'March 2017',
        component: <Hy2017GenevaMotorShow />,
        image: 'images/hy2017GenMotorShow.jpg',
      },
      {
        name: 'NextGenFuel',
        linkText:
          'Hyundai Motor Reveals Next Generation Fuel Cell Concept at 2017 Geneva Motor Show',
        date: 'March 2017',
        component: <NextGenFuel />,
        image: 'images/nextGenFuel.jpg',
      },
      {
        name: 'WinterTestingSweden',
        linkText:
          'Hyundai Motor’s First High-Performance Model: The Hyundai i30 N – Winter Testing in Sweden with Thierry Neuville',
        date: 'March 2017',
        component: <WinterTestingSweden />,
        image: '',
      },
      {
        name: 'I30WagonMotorShow',
        linkText:
          'Hyundai Motor Shows First Impression of New Generation i30 Wagon',
        date: 'February 2017',
        component: <I30WagonMotorShow />,
        image: 'images/ngi30Wagon.jpg',
      },
      {
        name: 'IoniqBestSmallHatch',
        linkText:
          'Ioniq Continues to Charge Ahead - Awarded Best Small Hatch at the UK Car of the Year Awards 2017',
        date: 'January 2017',
        component: <IoniqBestSmallHatch />,
        image: 'images/ioniqSmallHatchAward.jpg',
      },
      {
        name: 'IoniqBestCityCar',
        linkText:
          'The Best Things Come in Small Packages - New Hyundai i10 Wins Best City Car at 2017 What Car? Awards',
        date: 'January 2017',
        component: <IoniqBestCityCar />,
        image: 'images/i10BestCityCar.jpg',
      },
      {
        name: 'IoniqGreenCarAwards',
        linkText: 'Hyundai Ioniq Electrifies at the Next Green Car Awards',
        date: 'December 2016',
        component: <IoniqGreenCarAwards />,
        image: 'images/ioniqGreenCarAwards.jpg',
      },
    ];
    const awards = [
      {
        name: 'AutoExpressAwards',
        linkText: 'Electric performance lights up awards for Hyundai',
        date: 'June 2018',
        component: <AutoExpressAwards />,
        image: 'images/autoExpress1.jpg',
      },
      {
        name: 'TucsonLongDistance',
        linkText:
          'Hyundai Tucson named best car for long distances by real car owners at Auto Trader New Car Awards',
        date: 'May 2018',
        component: <TucsonLongDistance />,
        image: 'images/Tucson-SPT-AB.png',
      },
      {
        name: 'I10HighlyRated',
        linkText:
          'The multi-award winning Hyundai i10 wins most highly rated car of the year at the 2018 Honest John Awards',
        date: 'May 2018',
        component: <I10HighlyRated />,
        image: 'images/HighlyRatedHyundaii10copy.jpg',
      },
      {
        name: 'IoniqBest',
        linkText: 'It’s Official! Ioniq Is the UK’s Best Hybrid',
        date: 'January 2018',
        component: <IoniqBest />,
        image: 'images/ioniqBest.jpg',
      },
      {
        name: 'FourthTimei10',
        linkText: 'i10 Wins Award for the Fourth Time Running. Honest!',
        date: 'June 2017',
        component: <FourthTimei10 />,
        image: 'images/i10FourthTime.jpg',
      },
      {
        name: 'EnviroLeadership',
        linkText: 'Hyundai Motor Recognised for Environmental Leadership',
        date: 'March 2017',
        component: <EnviroLeadership />,
        image: 'images/envLeadership.jpg',
      },
      {
        name: 'IoniqBestSmallHatch',
        linkText:
          'Ioniq Continues to Charge Ahead - Awarded Best Small Hatch at the UK Car of the Year Awards 2017',
        date: 'January 2017',
        component: <IoniqBestSmallHatch />,
        image: 'images/ioniqSmallHatchAward.jpg',
      },
      {
        name: 'IoniqBestCityCar',
        linkText:
          'The Best Things Come in Small Packages - New Hyundai i10 Wins Best City Car at 2017 What Car? Awards',
        date: 'January 2017',
        component: <IoniqBestCityCar />,
        image: 'images/i10BestCityCar.jpg',
      },
      {
        name: 'IoniqGreenCarAwards',
        linkText: 'Hyundai Ioniq Electrifies at the Next Green Car Awards',
        date: 'December 2016',
        component: <IoniqGreenCarAwards />,
        image: 'images/ioniqGreenCarAwards.jpg',
      },
    ];

    var categories = { hyundai, product, awards };

    var articles = this.articleCategory(categories, displayCategory);

    this.state = {
      hyundai: hyundai,
      product: product,
      awards: awards,
      news: articles,
      displayCategory: displayCategory,
      displayArticle: this.displayArticle(articles, displayItemName),
    };

    this.handleNavClick = this.handleNavClick.bind(this);
  }
  articleCategory(categories, displayCategory) {
    var articles = categories[displayCategory];

    return articles;
  }
  displayArticle(articles, displayItemName) {
    var displayArticle = null;
    if (articles != null) {
      for (var i = 0; i < articles.length; i++) {
        if (articles[i].name === displayItemName) {
          displayArticle = articles[i];
          break;
        }
      }
    }

    return displayArticle;
  }

  urlQuery() {
    const urlCategoryQuery = new URLSearchParams(
      this.props.location.search,
    ).get('category');

    const defaults = urlCategoryQuery ? urlCategoryQuery : 'hyundai';
    return defaults;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    NewsUtils.attachThumbnailClick();
  }

  componentDidUpdate() {
    NewsUtils.attachThumbnailClick();
  }

  getDefaultArticle() {
    switch (this.state.displayCategory) {
      case 'hyundai': {
        return this.state.hyundai.length !== 0 ? this.state.hyundai[0] : null;
      }
      case 'product': {
        return this.state.product.length !== 0 ? this.state.product[0] : null;
      }
      case 'awards': {
        return this.state.awards.length !== 0 ? this.state.awards[0] : null;
      }
      default: {
        return null;
      }
    }
  }

  getTitleArticle() {
    switch (this.state.displayCategory) {
      case 'hyundai': {
        return 'Archived Hyundai News';
      }
      case 'product': {
        return 'Archived Product news';
      }
      case 'awards': {
        return 'Archived Awards';
      }
    }
  }

  handleNavClick(article) {
    this.setState({
      displayArticle: article,
    });

    window.scrollTo({
      top: this.myRef.current.offsetTop + -20,
      behavior: 'smooth',
    });
  }

  backToTop() {
    window.scrollTo(0, 0);
  }

  handleTheClick = (category) => {
    var newCategory = this.articleCategory(this.state, category);
    this.setState({
      news: newCategory,
      displayCategory: category,
    });
  };

  render() {
    const title = this.getTitleArticle();
    const defaultArticle = this.getDefaultArticle();

    const displayArticle =
      this.state.displayArticle != null
        ? this.state.displayArticle
        : defaultArticle;
    const displayCategory = this.state.displayCategory;

    const hyundaiNews = this.state.news.map((article, index) => {
      return (
        <li key={index} className="nav-item mb-4 mb-md-3">
          <a
            className="nav-link"
            data-search-component-link={article.name}
            onClick={() => this.handleNavClick(article)}
          >
            {article.image && (
              <div
                className="theme-news-archive-title-image rounded-top d-md-none  "
                style={{ backgroundImage: 'url(' + article.image + ' )' }}
              />
            )}
            {!article.image && (
              <div className="theme-news-archive-no-image border-top border-right  mt-4 d-md-none " />
            )}
            <div className="theme-news-archive-mobile-link-border">
              <p className="mb-md-0 pr-3 pt-3 pt-md-0">{article.linkText}</p>
            </div>
          </a>
          <div className="news-link-date d-none d-md-block">{article.date}</div>
        </li>
      );
    });

    return (
      <div>
        <Topnavbar />
        <Bottomnavbar />
        <ArchiveNewsNavbar
          iHaveBeenClicked={this.handleTheClick}
          displayCategory={displayCategory}
        />

        <div className="container">
          <div className="py-4  text-center">
            <h4 className="h4ServHistory m-0  d-lg-none ">
              {title != null && <span className="newsPageTitle">{title}</span>}
              {title == null && (
                <span className="newsPageTitle">Past news</span>
              )}
            </h4>
          </div>
        </div>

        <div className="container">
          <div className="row  px-3 px-sm-0">
            <div className="col-12 col-md-4 mb-5 mb-md-0">
              <div
                className="news-nav-container"
                data-search-category-items={displayCategory}
              >
                <ul className="nav flex-column">{hyundaiNews}</ul>
              </div>
            </div>
            <div
              className="col col-md-8 border-md-left border-lg-left"
              ref={this.myRef}
            >
              <div className="row">
                {displayArticle != null && (
                  <div data-search-component={displayArticle.name}>
                    {React.cloneElement(displayArticle.component, {
                      displayShowMore: false,
                    })}
                  </div>
                )}
              </div>
              <button
                type="button"
                className="btn btn-link float-right"
                onClick={this.backToTop}
              >
                Back to Top
              </button>
            </div>
          </div>
        </div>

        <FooterNavbar />

        <Footer />
      </div>
    );
  }
}
