import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';
import Store from 'store';
import ReactGA from 'react-ga';
export default class TucsonNLine extends Component {
  componentDidMount = () => {};

  clickHandler = () => {};

  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="tusconNLine" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="tucsonNLine-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/all-newTUCSONNLine1.jpg") ',
              }}
            />
            {/* <div
              id="Bayon-gallery-2"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/hyundaielectric2.jpg")',
              }}
            /> */}
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                {/* <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#Bayon-gallery-2"
                    style={{
                      backgroundImage: 'url("images/hyundaielectric2.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#Bayon-gallery-1"
                    style={{
                      backgroundImage: 'url("images/hyundaielectric1.jpg")',
                    }}
                  />
                </div> */}
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “First all new TUCSON N Line prices and specifications”
                </p>
              </div>
              <hr />

              <NewsMedia articleId="tucsonNLine" articleText="tucsonNLine" />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    FIRST ALL NEW TUCSON N LINE PRICES AND SPECIFICATIONS
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    {' '}
                    <ul>
                      <li>
                        TUCSON N Line design includes interior and exterior
                        accents inspired by Hyundai N
                      </li>
                      <li>Available in N Line or N Line S trim levels</li>
                    </ul>
                  </p>
                  {displayShowMore && (
                    <p>
                      <a
                        data-toggle="collapse"
                        href="#TucsonNLine"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </div>

              <div className={moreSectionClassName} id="TucsonNLine">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      12 April 2021 -{' '}
                      <strong>
                        Hyundai Motor UK has announced the pricing and
                        specification of the All New TUCSON N Line T-GDi and 48V
                        Mild Hybrid models, starting from just £31,110.
                      </strong>
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      SANTA FE is Hyundai’s longest-running SUV now in its 21st
                      year of sale. Sold in the UK since 2001, the D-segment SUV
                      has been praised for its comfort and roominess,
                      comprehensive standard features and functional cabin
                      design. The latest series of improvements make the New
                      SANTA FE an even more premium choice for its customers.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      TUCSON Hybrid and Plug-in Hybrid N Line pricing and
                      specification will be announced later this year.
                      <br />
                      <br /> The TUCSON N Line offers sporty and distinctive
                      exterior design features and differentiating interior
                      details so customers can enjoy a driving experience with
                      even more attitude. The N Line range starts with the
                      standard N Line 1.6 T-GDi 150ps six-speed manual-equipped
                      model and – until Hybrid and Plug-in Hybrid models receive
                      the N Line treatment – ends with the N Line S 1.6 T-GDi
                      180ps 4WD Mild Hybrid, with seven-speed dual-clutch
                      transmission at £37,065.
                      <br />
                      <br />
                      <img
                        src="images/all-newTUCSONNLine2.jpg"
                        className="img-fluid"
                      />
                      <br />
                      <br />
                      For this latest sporty take on the All New TUCSON, the
                      parametric jewel patterns on the grille pieces have been
                      elaborated with edges, creating additional reflections
                      depending on the angle. The grille also incorporates the N
                      Line badge. Under the grille sits a rectangular shaped
                      bumper and a refined air intake, which is taller and
                      bigger than on the standard TUCSON for a sportier look,
                      while striking black bezels frame the head lamps. At the
                      rear TUCSON N Line is outfitted with a longer aerodynamic
                      spoiler, complete with stabilising fins, a diffusor and
                      aggressive twin-tip muffler exhausts. Standard 19-inch
                      alloy wheels, featuring a geometric pattern mirroring
                      TUCSON’s grille, complete the look.
                      <br />
                      <br />
                      <img
                        src="images/all-newTUCSONNLine3.jpg"
                        className="img-fluid"
                      />
                      <br />
                      <br />
                      Customers can choose from seven exterior colours, with an
                      optional two-tone roof in Phantom Black available on five
                      of the colour trims.
                      <br />
                      <br />
                      Exterior colours:
                      <br />
                      <ul>
                        <li>
                          Shadow Grey [exclusively for N Line] w/ optional
                          Phantom Black roof (£665 + optional £500)
                        </li>
                        <li>
                          Polar White w/ optional Phantom Black roof (£300 +
                          optional £500)
                        </li>
                        <li>
                          Engine Red w/ optional Phantom Black roof (£0 +
                          optional £500)
                        </li>
                        <li>
                          Sunset Red w/ optional Phantom Black roof ((£665 +
                          optional £500)
                        </li>
                        <li>
                          Dark Knight Grey w/ optional Phantom Black roof (£665
                          + optional £500)
                        </li>
                        <li>Shimmering Silver (£665)</li>
                        <li>Phantom Black (£665)</li>
                      </ul>
                      <br />
                      <br />
                      In the cabin, TUCSON N Line features N-branded sporty
                      seats with black suede and leather seat coverings, as well
                      as red stitching. Red stitching is also woven on the door
                      trim and armrest, while the grey cloth crash pad is
                      garnished with red accents. A dedicated N steering wheel
                      arrives complete with an N logo. Other N design elements
                      are to be found on the leather gear shift knob or shift by
                      wire console cover, depending on the chosen transmission.
                      Meanwhile, a black headliner gives the interior an extra
                      sporty touch. Further N Line enhancements include metal
                      pedals, a foot rest and door steps.
                      <br />
                      <br />
                      <img
                        src="images/all-newTUCSONNLine3.jpg"
                        className="img-fluid"
                      />
                      <br />
                      <br />
                      Customers can choose between N Line (from £31,110) and N
                      Line S (from £32,810) trim levels, with the former
                      generously equipped with heated front seats, dual-zone
                      climate control, parking sensors, Smart Key, wireless
                      charging pad and ambient interior lighting. N Line S
                      models additionally receive three-zone climate control, a
                      tilt-and-slide electric panoramic roof, Smart electric
                      tailgate, a heated steering wheel, KRELL Premium Audio
                      with eight speakers and subwoofer and added SmartSense
                      safety including Blind Spot Collision Warning and Rear
                      Cross Traffic Alert.
                      <br />
                      <br />
                      This standard equipment is in addition to TUCSON’s
                      technology across the range, including automatic rear view
                      mirror, lights and wipers, 10.25" touchscreen satellite
                      navigation with Bluelink® connected car services and a
                      10.25" driver’s supervision instrument cluster. The
                      optional Tech Pack is available on N Line S models and
                      includes electronically controlled suspension, blind spot
                      view monitor & around view monitor.
                      <img
                        src="images/all-newTUCSONNLine3.jpg"
                        className="img-fluid"
                      />
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      <p
                        style={{
                          color: '#ac6b4f',
                          fontSize: '1rem',
                          fontStyle: 'italic',
                        }}
                      >
                        "As consistently one of our most popular models, we’re
                        delighted to be able to confirm pricing for the latest
                        addition to the TUCSON line-up. N Line and N Line S
                        models build on the well-equipped, wonderfully designed
                        and hi-tech groundwork of TUCSON with a package of
                        performance-inspired styling editions and an expanded
                        list of luxurious standard equipment – all at a price
                        that, we believe, is better value than rival products."
                      </p>
                    </p>
                    <p className="theme-news-body-text">
                      <span style={{ fontSize: '1rem' }}>
                        <strong>Ashley Andrew</strong>
                      </span>{' '}
                      <br />
                      <span style={{ color: 'dimgray' }}>
                        Managing Director, Hyundai Motor UK
                      </span>
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      All New TUCSON N Line models come with Hyundai’s industry
                      leading 5 Year Unlimited Mileage Warranty, Roadside
                      Assistance package, 5 Year Annual Health Check, and 12
                      Year Anti Corrosion Warranty.
                      <br />
                      <br />
                      <span style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                        {' '}
                        Pricing, specifications & technical data
                      </span>{' '}
                      <br />
                      <br />
                      For the very latest pricing information, please check{' '}
                      <a
                        href="http://www.hyundai.co.uk/new-cars/tucson"
                        target="_blank"
                      >
                        www.hyundai.co.uk/new-cars/tucson
                      </a>
                      <br />
                      <a
                        href="https://www1.hyundai.news/fileadmin/eu/uk/20210412_tucson-n_line_prices_specs/hyundai-tucson-n-line-prices-specs-0421.pdf"
                        target="_blank"
                      >
                        Open pricing, specifications & technical data (PDF)
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
