import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';
import Store from 'store';
import ReactGA from 'react-ga';
export default class NewSantaFe extends Component {
  componentDidMount = () => {};

  clickHandler = () => {};

  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="newSantaFe" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="newSantaFe-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage:
                  'url("images/hyundai-uk-santa-fe-0421-01.jpg") ',
              }}
            />
            {/* <div
              id="Bayon-gallery-2"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/hyundaielectric2.jpg")',
              }}
            /> */}
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                {/* <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#Bayon-gallery-2"
                    style={{
                      backgroundImage: 'url("images/hyundaielectric2.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#Bayon-gallery-1"
                    style={{
                      backgroundImage: 'url("images/hyundaielectric1.jpg")',
                    }}
                  />
                </div> */}
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “Hyundai announces New SANTA FE prices and specifications”
                </p>
              </div>
              <hr />

              <NewsMedia articleId="Bayon" articleText="Bayon" />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    HYUNDAI ANNOUNCES NEW SANTA FE PRICES AND SPECIFICATIONS
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    {' '}
                    <ul>
                      <li>
                        Introduction of new 1.6-litre turbocharged engine with
                        Hybrid or Plug-in Hybrid powertrain choices
                      </li>
                      <li>
                        {' '}
                        New SANTA FE design combines rugged presence and
                        sophistication
                      </li>
                      <li>
                        {' '}
                        Increased interior proportions with class leading
                        passenger legroom
                      </li>
                      <li>Prices from £40,250</li>
                    </ul>
                  </p>
                  {displayShowMore && (
                    <p>
                      <a
                        data-toggle="collapse"
                        href="#santaFe"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </div>

              <div className={moreSectionClassName} id="santaFe">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      2 March 2021 -{' '}
                      <strong>
                        Hyundai Motor UK has announced the pricing and
                        specification of the New SANTA FE, the Company’s
                        flagship SUV.
                      </strong>
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      SANTA FE is Hyundai’s longest-running SUV now in its 21st
                      year of sale. Sold in the UK since 2001, the D-segment SUV
                      has been praised for its comfort and roominess,
                      comprehensive standard features and functional cabin
                      design. The latest series of improvements make the New
                      SANTA FE an even more premium choice for its customers.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      As well as offering the option of a hybrid and plug-in
                      hybrid powertrain for the first time, it also features a
                      bold and distinctive new design as well as a range of new
                      technology and safety features.
                      <br />
                      <br />
                      <img
                        src="images/hyundai-uk-santa-fe-0421-02.jpg"
                        className="img-fluid"
                      />
                      <br />
                      <br />A number of exterior and interior enhancements have
                      been introduced including a redesigned front end with a
                      new cascading grille, T shaped headlamps and a sculpted
                      front bumper. Rear design changes include new connected
                      tail lamps, full length lower reflector and a redesigned
                      bumper. Interior changes include a redesigned dashboard
                      with a standard 10.25” infotainment system and optional
                      12.3” supervision instrument cluster. Cabin space has been
                      increased all around, width by +10mm, length by +15mm and
                      height by +5mm. New SANTA FE boasts an increase of 34mm of
                      2nd row legroom over the previous model, giving New SANTA
                      FE an overall best in class 2nd row legroom of 1060mm. New
                      SANTA FE also continues with a standard 7 seat interior
                      with most D segment competitors now only offering only 5
                      seat configurations.
                      <br />
                      <br />
                      Comprehensive safety equipment comes as standard with New
                      SANTA FE. The list includes Blind Spot Collision Assist
                      (BCA), Downhill Brake Control (DBC), Driver Attention
                      Alert (DAA), eCall, Forward Collision Avoidance Assist
                      with Car, Pedestrian, Cycle and Junction Turning
                      functions, Intelligent Speed Limit Warning (ISLW) forward
                      and reverse Parking Distance Warning (PDW), Rear Occupancy
                      Alert and Lane Departure Warning Assist with Lane Keep
                      Assist (LKAS) .Self-levelling suspension and a trailer
                      wiring package also feature. New SANTA FE is available
                      with Hyundai’s 1.6-litre T-GDi engine combined with either
                      a 230PS Hybrid or a 265PS Plug-in Hybrid option with
                      emissions of just 37g/km CO2. A 6 speed fully automatic
                      gearbox is standard on all models with Hybrid customers
                      able to choose between 2WD or Hyundai’s HTRAC 4WD system
                      featuring intelligent torque distribution and 4 drive
                      modes.
                      <br />
                      <br />
                      <img
                        src="images/hyundai-uk-santa-fe-0421-03.jpg"
                        className="img-fluid"
                      />
                      <br />
                      <br />
                      Two trim levels are available offering customers the
                      choice of high levels of standard equipment often only
                      seen on more premium models in the segment. Priced from
                      £40,205, the SANTA FE Premium 1.6 T-GDi Hybrid 6 speed
                      automatic 2WD offers customers 17” alloy wheels,
                      electrically operated and heated body coloured door
                      mirrors and handles, full LED headlamps, tail lamps and
                      positioning lamps, privacy glass and roof rails. Interior
                      equipment includes electrically adjustable driver and
                      front passenger seats, 2nd row one touch folding seats,
                      luggage compartment heated front and rear seats, dual zone
                      climate control air conditioning, and heated steering
                      wheel. Convenience and connected equipment includes
                      adaptive cruise control with stop/go function, smart key
                      with keyless entry and start/stop button, smart electric
                      tailgate with hands free operation, rear parking system
                      with rear camera and guidance system, KRELL premium sound
                      system with subscription free Apple CarPlay™ and Android
                      Auto™, Bluelink® (Connected Car Services) with 5 year
                      subscription and USB charging ports to the centre console
                      HTRAC 4WD transmissions are available, as well as the
                      Plug-in Hybrid powertrain.
                      <br />
                      <br />
                      The SANTA FE Ultimate 1.6 T-GDi Hybrid 6 speed automatic
                      2WD is available from £43,815 and builds on the already
                      high levels of equipment from the Premium trim with the
                      addition of a surround view monitor parking system with
                      parking collision avoidance assist, remote smart parking
                      assist, 12.3” full TFT drivers instrument cluster, head up
                      display, blind spot view monitor and highway drive assist.
                      Customers also have the choice of specifying the Luxury
                      Pack which includes Nappa leather interior trim, suede
                      headliner, brushed aluminium centre console accents and a
                      faux leather wrapped dashboard. HTRAC 4WD transmissions
                      are available, as well as the Plug-in Hybrid powertrain.
                      <br />
                      <br />
                      <span style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                        {' '}
                        New SANTA FE is available in 9 colour finishes
                      </span>{' '}
                      <br />
                      <br />
                      <table style={{ width: '100%' }}>
                        <tr style={{ backgroundColor: '#cbc7c7' }}>
                          <td
                            style={{
                              border: '1px solid black',
                              padding: '3px',
                            }}
                          >
                            Colour
                          </td>
                          <td
                            style={{
                              border: '1px solid black',
                              padding: '3px',
                            }}
                          >
                            Finish
                          </td>
                        </tr>
                        <th></th>
                        <tr>
                          <td
                            style={{
                              border: '1px solid black',
                              padding: '3px',
                            }}
                          >
                            Typhoon Silver
                          </td>
                          <td
                            style={{
                              border: '1px solid black',
                              padding: '3px',
                            }}
                          >
                            Metallic
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              border: '1px solid black',
                              padding: '3px',
                            }}
                          >
                            White Cream
                          </td>
                          <td
                            style={{
                              border: '1px solid black',
                              padding: '3px',
                            }}
                          >
                            Pearl
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              border: '1px solid black',
                              padding: '3px',
                            }}
                          >
                            Magnetic Force
                          </td>
                          <td
                            style={{
                              border: '1px solid black',
                              padding: '3px',
                            }}
                          >
                            Metallic
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              border: '1px solid black',
                              padding: '3px',
                            }}
                          >
                            Rainforest
                          </td>
                          <td
                            style={{
                              border: '1px solid black',
                              padding: '3px',
                            }}
                          >
                            Metallic
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              border: '1px solid black',
                              padding: '3px',
                            }}
                          >
                            Phantom Black
                          </td>
                          <td
                            style={{
                              border: '1px solid black',
                              padding: '3px',
                            }}
                          >
                            Pearl
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              border: '1px solid black',
                              padding: '3px',
                            }}
                          >
                            Lava Orange
                          </td>
                          <td
                            style={{
                              border: '1px solid black',
                              padding: '3px',
                            }}
                          >
                            Pearl
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              border: '1px solid black',
                              padding: '3px',
                            }}
                          >
                            Glacier White
                          </td>
                          <td
                            style={{
                              border: '1px solid black',
                              padding: '3px',
                            }}
                          >
                            Pearl
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              border: '1px solid black',
                              padding: '3px',
                            }}
                          >
                            Taiga Brown
                          </td>
                          <td
                            style={{
                              border: '1px solid black',
                              padding: '3px',
                            }}
                          >
                            Pearl
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              border: '1px solid black',
                              padding: '3px',
                            }}
                          >
                            Lagoon Blue
                          </td>
                          <td
                            style={{
                              border: '1px solid black',
                              padding: '3px',
                            }}
                          >
                            Pearl
                          </td>
                        </tr>
                      </table>
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      <p
                        style={{
                          color: '#ac6b4f',
                          fontSize: '1rem',
                          fontStyle: 'italic',
                        }}
                      >
                        "The latest enhancements to SANTA FE not only provide an
                        outstanding range of standard equipment but also combine
                        this with a high level of efficiency offered by the
                        Hybrid and Plug-in Hybrid electrified powertrains that
                        continue to reaffirm our commitment to reducing the
                        environmental impact of our vehicles. New SANTA FE will
                        also appeal to the fleet market, particularly from fleet
                        operators and company car drivers who require a multi
                        seat vehicle and a low emission powertrain, a
                        requirement that SANTA FE Plug-in Hybrid is ideally
                        positioned to fill with its low CO2 emissions of just
                        37g/km and standard 7 seat configuration."
                      </p>
                    </p>
                    <p className="theme-news-body-text">
                      <span style={{ fontSize: '1rem' }}>
                        <strong>Ashley Andrew</strong>
                      </span>{' '}
                      <br />
                      <span style={{ color: 'dimgray' }}>
                        Managing Director, Hyundai Motor UK
                      </span>
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      <img
                        src="images/hyundai-uk-santa-fe-0421-04.jpg"
                        className="img-fluid"
                      />
                      <br />
                      <br />
                      <a
                        href="https://www1.hyundai.news/fileadmin/eu/uk/20210302_new_santa_fe_prices_specifications/hyundai_new_santa_fe_pricing_spec_tech.pdf"
                        target="_blank"
                      >
                        Pricing & Specification (PDF)
                      </a>
                      <br />
                      <br />
                      <img
                        src="images/hyundai-uk-santa-fe-0421-05.jpg"
                        className="img-fluid"
                      />
                      <img
                        src="images/hyundai-uk-santa-fe-0421-06.jpg"
                        className="img-fluid"
                      />
                      <img
                        src="images/hyundai-uk-santa-fe-0421-07.jpg"
                        className="img-fluid"
                      />
                      <img
                        src="images/hyundai-uk-santa-fe-0421-08.jpg"
                        className="img-fluid"
                      />
                      <img
                        src="images/hyundai-uk-santa-fe-0421-09.jpg"
                        className="img-fluid"
                      />
                      <img
                        src="images/hyundai-uk-santa-fe-0421-10.jpg"
                        className="img-fluid"
                      />
                      <img
                        src="images/hyundai-uk-santa-fe-0421-11.jpg"
                        className="img-fluid"
                      />
                      <img
                        src="images/hyundai-uk-santa-fe-0421-12.jpg"
                        className="img-fluid"
                      />
                      <img
                        src="images/hyundai-uk-santa-fe-0421-13.jpg"
                        className="img-fluid"
                      />
                      <img
                        src="images/hyundai-uk-santa-fe-0421-14.jpg"
                        className="img-fluid"
                      />
                      <img
                        src="images/hyundai-uk-santa-fe-0421-15.jpg"
                        className="img-fluid"
                      />
                      <img
                        src="images/hyundai-uk-santa-fe-0421-16.jpg"
                        className="img-fluid"
                      />
                      <img
                        src="images/hyundai-uk-santa-fe-0421-17.jpg"
                        className="img-fluid"
                      />
                      <img
                        src="images/hyundai-uk-santa-fe-0421-18.jpg"
                        className="img-fluid"
                      />
                      <img
                        src="images/hyundai-uk-santa-fe-0421-19.jpg"
                        className="img-fluid"
                      />
                      <img
                        src="images/hyundai-uk-santa-fe-0421-20.jpg"
                        className="img-fluid"
                      />
                      <img
                        src="images/hyundai-uk-santa-fe-0421-21.jpg"
                        className="img-fluid"
                      />
                      <img
                        src="images/hyundai-uk-santa-fe-0421-22.jpg"
                        className="img-fluid"
                      />
                      <img
                        src="images/hyundai-uk-santa-fe-0421-23.jpg"
                        className="img-fluid"
                      />
                      <img
                        src="images/hyundai-uk-santa-fe-0421-24.jpg"
                        className="img-fluid"
                      />
                      <img
                        src="images/hyundai-uk-santa-fe-0421-25.jpg"
                        className="img-fluid"
                      />
                      <img
                        src="images/hyundai-uk-santa-fe-0421-26.jpg"
                        className="img-fluid"
                      />
                      <img
                        src="images/hyundai-uk-santa-fe-0421-27.jpg"
                        className="img-fluid"
                      />
                      <img
                        src="images/hyundai-uk-santa-fe-0421-28.jpg"
                        className="img-fluid"
                      />
                      <img
                        src="images/hyundai-uk-santa-fe-0421-29.jpg"
                        className="img-fluid"
                      />
                      <img
                        src="images/hyundai-uk-santa-fe-0421-30.jpg"
                        className="img-fluid"
                      />
                      <img
                        src="images/hyundai-uk-santa-fe-0421-31.jpg"
                        className="img-fluid"
                      />
                      <img
                        src="images/hyundai-uk-santa-fe-0421-32.jpg"
                        className="img-fluid"
                      />
                      <img
                        src="images/hyundai-uk-santa-fe-0421-33.jpg"
                        className="img-fluid"
                      />
                      <img
                        src="images/hyundai-uk-santa-fe-0421-34.jpg"
                        className="img-fluid"
                      />
                      <img
                        src="images/hyundai-uk-santa-fe-0421-35.jpg"
                        className="img-fluid"
                      />
                      <img
                        src="images/hyundai-uk-santa-fe-0421-36.jpg"
                        className="img-fluid"
                      />
                      <img
                        src="images/hyundai-uk-santa-fe-0421-37.jpg"
                        className="img-fluid"
                      />
                      <img
                        src="images/hyundai-uk-santa-fe-0421-38.jpg"
                        className="img-fluid"
                      />
                      <img
                        src="images/hyundai-uk-santa-fe-0421-39.jpg"
                        className="img-fluid"
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
