import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';

export default class KonaDiesel extends Component {
  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="konaDiesel" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="konaDiesel-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/kona_2017.jpg") ',
              }}
            />
            <div
              id="konaDiesel-gallery-2"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/konaDiesel2.jpg")',
              }}
            />
            <div
              id="konaDiesel-gallery-3"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/konaDiesel3.jpg")',
              }}
            />
          </div>
        </div>

        <div className="col bg-white border-right border-bottom">
          <div className="row py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a  
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#konaDiesel-gallery-1"
                    style={{
                      backgroundImage: 'url("images/konaDiesel1_th.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a  
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#konaDiesel-gallery-2"
                    style={{
                      backgroundImage: 'url("images/konaDiesel2_th.jpg")',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container  img-thumbnail">
                  <a  
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#konaDiesel-gallery-3"
                    style={{
                      backgroundImage: 'url("images/konaDiesel3_th.jpg")',
                    }}
                  />
                </div>
              </div>
              <div className="mt-3">
                <p className="theme-news-snippet">
                  “Kona has proved to be an immediate success, and
                  with the introduction of clean diesel as well as
                  electric technology, we expect this demand to
                  strengthen even further ”
                          </p>
              </div>
              <hr />

              <NewsMedia articleId="konaDiesel" articleText="kona Diesel" />
            </div>
            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    KONA GOES DIESEL
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p
                    className="theme-news-body-text"
                    data-search-tag="summary"
                  >
                    Last year, the All-New Kona was launched, giving
                    Hyundai a foothold in the fast-growing sub-compact
                    SUV category for the first time. Just eight months
                    later and the car has proved such a success that
                    two diesel versions have been added to the
                    line-up.
                            </p>
                  {displayShowMore && (
                    <p>
                      <a  
                        data-toggle="collapse"
                        href="#collapsekonaDiesel"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </div>

              <div className={moreSectionClassName} id="collapsekonaDiesel">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      The newly developed 1.6 CRDi U3 engine is the very latest
                      to comply with the stringent new Euro emissions standards,
                      and a state-of-the-art exhaust gas purification system has
                      been added to minimise both CO2 and N2 emissions, and
                      optimise fuel consumption.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      “Kona has proved to be an immediate success, and
                      with the introduction of clean diesel as well as
                      electric technology, we expect this demand to
                      strengthen even further,” says Tony Whitehorn,
                      President and CEO Hyundai Motor UK. “Our
                      customer feedback shows that there is a demand
                      for efficient and clean diesel engines, a demand
                      that Kona diesel will be perfectly placed to
                                satisfy.”{" "}
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      Deciding which Kona to go for is made even more
                      difficult thanks to the choice of two diesel
                      engines; a 115PS 6-speed manual two wheel drive
                      version and a 136PS 7-speed dual clutch
                      transmission two wheel drive engine. Both are
                      fitted into cars that are kitted out with the
                      same high level of standard safety equipment as
                      any other Kona model, with Driver Attention
                      Alert, Lane Keeping Assist, Hill Start Assist
                      Control and Downhill Brake Control featuring on
                      all trim levels, and Autonomous Emergency
                      Braking with Pedestrian Recognition available as
                      an option. High levels of technology and
                      connectivity are also standard, with DAB,
                      Bluetooth, Apple CarPlay™ and Android Auto™ on
                                all models, regardless of trim level.{" "}
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {" "}
                      Prices for the Kona SE 1.6 CRDi 115PS 6-speed
                      manual, which has 17” alloy wheels, body
                      coloured bumpers, door handles and wing mirrors,
                      as well as rear spoiler with integrated LED
                                brake light, start from £19,750.{" "}
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      <strong>Other key features include:</strong>
                    </p>
                    <ul>
                      <li>
                        Cloth seats with driver’s seat height adjustment and
                        60/40 split folding rear seat
                      </li>
                      <li>Manual air conditioning</li>
                      <li>
                        Automatic headlamps with LED positioning lamps and LED
                        daytime running lamps
                      </li>
                      <li>Cruise control with speed limiter</li>
                      <li>Electrically adjustable and heated wing mirrors </li>
                      <li>Electric front and rear windows</li>
                      <li>7” LCD centre console display </li>
                      <li>
                        Steering wheel controls for audio, telephone and cruise
                        control
                      </li>
                      <li>Rear parking sensors and rear camera</li>
                    </ul>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      The Kona Premium 1.6 CRDi 115PS 2WD, meanwhile,
                      starts at £21,050 and includes 18” alloy wheels,
                      a chrome surround to the black front grille,
                      Anthracite finished side body garnish, and
                      silver rear skid plate and side body mouldings.
                              </p>
                    <p>
                      <strong>Other key features include:</strong>
                    </p>

                    <ul>
                      <li>Climate control with automatic defog function</li>
                      <li>Automatically dimming rear view mirror</li>
                      <li>Automatic windscreen wipers </li>
                      <li>Solar glass for front windows and windscreen</li>
                      <li>Privacy glass for rear windows and tailgate</li>
                      <li>
                        Smart Key with keyless entry and engine start/stop
                        button
                      </li>
                      <li>
                        High-end KRELL audio system, including an eight speaker
                        system with external amplifier and subwoofer
                      </li>
                      <li>
                        8” touchscreen centre console display with satellite
                        navigation and LIVE services
                      </li>
                      <li>Wireless phone charging pad </li>
                    </ul>

                    <p>
                      Finally, at the top of the diesel range, the
                      Kona Premium SE 1.6 CRDi 115PS 2WD starts at
                      £23,450. As well as all the kit on the Kona
                      Premium, it comes with leather seat facings and
                      electrically adjustable driver and passenger
                      seats, both of which have heating and
                      ventilation.
                              </p>

                    <p>
                      <strong>Other key features include:</strong>
                    </p>
                    <ul>
                      <li>Heated steering wheel</li>
                      <li>Rear centre arm rest</li>
                      <li>
                        Power folding door mirrors and front parking sensor{' '}
                      </li>
                      <li>
                        Head Up Display (which projects relevant information
                        directly to the drivers line of sight)
                      </li>
                    </ul>

                    <p>
                    Kona diesel is available in a choice of eight
                      colours, with the option of a two-tone roof on
                      Premium and Premium SE models. For more
                      information please click <a href="https://www.hyundai.co.uk/new-cars/kona" target="_blank" rel="noopener noreferrer">here</a>.
                              </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
