import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import './loginPageNavbar.css';

export default class LoginPageNavbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: '',
    };
  }

  UpdateInputValue(inputText) {
    this.setState({
      text: inputText.target.value,
    });
  }

  render() {
    return (
      <div className="container" data-search-exclude>
        {/* <nav className="navbar navbar-expand-lg navbar-light bg-light p-0 dropShadowWHN secondary-nav">
          <div className="slantedDiv" style={{ fontSize: 'small' }}>
            MyHyundai Newsletter
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto navbarText p-3 p-lg-0">
              <li className="nav-item pr-5 pl-5 login-page">
                <NavLink
                  id="inspiration"
                  className="nav-link whn-nav-item"
                  activeClassName=""
                  to={{
                    pathname: '/newsletter',
                    state: {
                      filteredCategory: 'Inspiration',
                    },
                  }}
                >
                  Inspiration
                </NavLink>
              </li>
              <li className="nav-item pr-5 pl-5 login-page">
                <NavLink
                  id="cars"
                  className="nav-link whn-nav-item"
                  activeClassName=""
                  to={{
                    pathname: '/newsletter',
                    state: {
                      filteredCategory: 'Cars',
                    },
                  }}
                >
                  Cars
                </NavLink>
              </li>

              <li className="nav-item pr-5 pl-5 login-page">
                <NavLink
                  id="innovation"
                  className="nav-link whn-nav-item"
                  activeClassName=""
                  to={{
                    pathname: '/newsletter',
                    state: {
                      filteredCategory: 'Innovation',
                    },
                  }}
                >
                  Innovation
                </NavLink>
              </li>

              <li className="nav-item pr-5 pl-5 login-page">
                <NavLink
                  id="culture"
                  className="nav-link whn-nav-item"
                  activeClassName=""
                  to={{
                    pathname: '/newsletter',
                    state: {
                      filteredCategory: 'Culture',
                    },
                  }}
                >
                  Culture
                </NavLink>
              </li>
            </ul>
          </div>
        </nav> */}
      </div>
    );
  }
}
