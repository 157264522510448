import React, { Component } from 'react';
import Auth from '../utils/Auth';
import { Redirect } from 'react-router-dom';

export default class AuthenticatedComponent extends Component {
  constructor(props) {
    super();

    this.state = {
      redirectToLogin: false,
    };
  }

  componentDidMount() {
    this.checkAuthentication();
  }

  componentDidUpdate() {
    if (this.state.redirectToLogin) {
      window.location.href = `${
        process.env.PUBLIC_URL
      }/login?ver=${Date.now()}&return=${encodeURIComponent(
        this.props.location.pathname + this.props.location.search,
      )}`;
    } else {
      this.checkAuthentication();
    }
  }

  checkAuthentication() {
    if (Auth.isAuthenticated()) {
      Auth.refreshToken(this.refreshFailed.bind(this));
    }
  }

  refreshFailed() {
    this.setState({
      redirectToLogin: true,
    });
  }

  render() {
    if (this.state.redirectToLogin) {
      return <div>Please wait while you are redirected.</div>;
    }

    return this.props.children;
  }
}
