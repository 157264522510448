import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class FeedBackRadio extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
  };

  render = () => {
    const { name, value, number, onChange, id } = this.props;

    return (
      <div className="rb-tab">
        <input
          className="custom-control-input rb"
          type="radio"
          name={name}
          id={id}
          value={value}
          onChange={onChange}
        />

        <label htmlFor={id}>
          <div className="rb-spot">
            <span className="rb-txt">{number}</span>
          </div>
        </label>
      </div>
    );
  };
}
