import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';

export default class Chelsea extends Component {
  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="Chelsea" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="chelsea-gallery-1"
              className="theme-news-header-image active"
              style={{ backgroundImage: 'url("images/chelsea1-322-920.png") ' }}
            />
            <div
              id="chelsea-gallery-2"
              className="theme-news-header-image"
              style={{ backgroundImage: 'url("images/chelsea2.jpg")' }}
            />

            <div
              id="chelsea-gallery-3"
              className="theme-news-header-image"
              style={{ backgroundImage: 'url("images/chelsea3.jpg")' }}
            />
          </div>
        </div>

        <div className="col bg-white border-right border-bottom">
          <div className="row py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#chelsea-gallery-1"
                    style={{
                      backgroundImage: 'url("images/chelsea-sqare.jpeg") ',
                    }}
                  />
                </div>

                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#chelsea-gallery-2"
                    style={{ backgroundImage: 'url("images/chelsea2_th.jpg")' }}
                  />
                </div>

                <div className="theme-news-thumbnail-container  img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#chelsea-gallery-3"
                    style={{ backgroundImage: 'url("images/chelsea3_th.jpg")' }}
                  />
                </div>
              </div>
              <div className="mt-3">
                <p className="theme-news-snippet">
                  “At Chelsea we pride ourselves on partnering with some of the
                  world's leading companies and we look forward to working
                  closely with Hyundai as they seek to reward, engage and
                  entertain our global fan base”
                </p>
              </div>
              <hr />

              <NewsMedia articleId="Chelsea" articleText="Chelsea" />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    CHELSEA FOOTBALL CLUB PARTNERSHIP
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    Some of the world’s most popular footballers wear their
                    hearts on their sleeves but from next season the
                    star-studded Chelsea squad will be wearing the Hyundai logo
                    on theirs.
                  </p>
                  {displayShowMore && (
                    <p>
                      <a
                        data-toggle="collapse"
                        href="#collapseChelsea"
                        role="button"
                        aria-expanded="false"
                      >
                        More
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </div>

              <div className={moreSectionClassName} id="collapseChelsea">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      That’s because the five-time Premier League champions have
                      just announced that Hyundai will be an Official Global
                      Partner for the next four seasons, with the Hyundai logo
                      taking pride of place on the sleeve of the men’s and
                      ladies’ team shirts in all domestic competitions from next
                      season until 2022.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      Hyundai has been involved in international football for 20
                      years, having been a FIFA Top Partner since 1999, and
                      involved with UEFA for 18 years up to 2017. The brand is
                      heavily involved with national teams and individual clubs
                      across the world, but the deal with this year’s FA Cup
                      winners is the first time Hyundai has partnered with a UK
                      football club.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      “At Chelsea we pride ourselves on partnering with some of
                      the world's leading companies and we look forward to
                      working closely with Hyundai as they seek to reward,
                      engage and entertain our global fanbase,” said Chelsea
                      Commercial Director Chris Townsend OBE. As part of the
                      deal, Hyundai will work with Chelsea to develop a range of
                      initiatives to make football more accessible to the club’s
                      supporters.”{' '}
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      “We are thrilled to begin a new partnership with Chelsea –
                      a successful and ambitious club that matches the global
                      scale and challenging spirit of Hyundai,” said
                      Andreas-Christoph Hofmann, Vice President Marketing &
                      Product at Hyundai Motor Europe. “We have always
                      positioned fans at the heart of our activities throughout
                      our long-running engagement in football, and we will be
                      taking the same approach with Chelsea fans around the
                      world. Along with our existing partnership with Olympique
                      Lyonnais, this marks the start of a new pan-European
                      sponsorship approach that will include other top clubs in
                      Europe.”
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      The logo on the new jersey will be seen for the first time
                      on 23 July when Chelsea play a friendly fixture against
                      Australian side Perth Glory.{' '}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
