import React, { Component } from 'react';
import ElectricVehiclesNavBar from '../components/ElectricVehicles/ElectricVehiclesNavBar';
import './serviceHistory.css';
import FooterNavbar from '../components/footerNavbar';
import Topnavbar from '../components/topnavbar';
import Bottomnavbar from '../components/bottomnavbar';
import Footer from '../components/footer';
import Store from 'store';

export default class PodCharging extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customer: Store.get('customerInfo'),
      foreName: '',
      hasTestDrive: true,
      hasQuoteRequests: true,
      hasSignedOut: false,
      defaultName: false,
      searchTerm: '',
      redirectToSearch: false,
      competitions: Store.get('competitionInfo'),
      vehicles: Store.get('vehicles'),
      vehicleInterest: Store.get('vehicleInterest'),
      offers: Store.get('offers'),
      handRaisedVehicle: '',
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.state.vehicleInterest.forEach((t) => {
      this.setState({
        handRaisedVehicle: t.vehicleDetails,
      });
    });
  }

  render() {
    return (
      <div>
        <Topnavbar />
        <Bottomnavbar />
        <ElectricVehiclesNavBar />

        <div className="container">
          <section
            className="section1 wow fadeIn"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <div className="content p-3 p-xl-5 col-md-10 offset-md-1 border-right border-bottom">
              <div className="row">
                <div className="col-lg-3">
                  <h4 className="h4ServHistory" data-search-tag="title">
                    Pod point charging points
                  </h4>
                  <p />
                  <p className="personalBlue">
                    Contact number:
                    <br />
                    0207 247 4114
                  </p>
                  <p className="personalBlue">
                    Website:
                    <br />
                    <a  
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://www.pod-point.com"
                      style={{ textDecoration: 'underline' }}
                    >
                      www.pod-point.com
                    </a>
                  </p>
                </div>

                <div className="col-lg-8 offset-lg-1 p-sm-4 border-lg-left">
                  <img className="img-fluid" src="images/chargingPod.png" />
                  <br />
                  <br />

                  <p className="remindersBlue">
                    We are sure that you have thought about charging points when
                    purchasing an electric vehicle and what options are
                    available to you. We know it's not always easy to make a
                    decision so please find some information on this page that
                    may help you decide on the best way forward to suit your
                    individual vehicle charging requirements.
                  </p>
                  <br />

                  <h4 className="page-inner-title">Home charge point:</h4>

                  <p className="remindersBlue">
                    Electric cars can be plugged into your standard 3 pin-plug
                    at home. However, we strongly recommend that you use an
                    approved wallbox and approved supplier. Many electric car
                    drivers opt for a home charge point (also known as a wall
                    box). Both options allow you to charge your car, but a home
                    charge point can mean faster charging, additional safety
                    features, over the air software updates and smart phone app
                    connectivity. You can charge your{' '}
                    <strong>{this.state.handRaisedVehicle}</strong> with a 3.6kW
                    or 7kW charge point at home.
                  </p>
                  <br />
                  <h4 className="page-inner-title">Charging cable:</h4>

                  <ul>
                    <li>
                      Universal Solo Charger – means you will need to use the
                      Type 2 cable supplied with the{' '}
                      <strong>{this.state.handRaisedVehicle}</strong> as
                      standard.
                    </li>
                    <li>
                      Tethered Solo Charger – means the unit includes a Type 2
                      cable which is permanently attached.
                    </li>
                  </ul>

                  <br />
                  <h4 className="page-inner-title">Charge Point Supplier:</h4>

                  <p className="remindersBlue">
                    We fully understand that you may have decided to source a
                    supplier to assist you with your home charge point
                    requirements. However, if prefer some further help and
                    advice, then please feel free to contact our preferred
                    supplier Pod Point on 0207 247 4114 or visit their website
                    <a  
                      href="http://www.pod-point.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: 'underline' }}
                    >
                      {' '}
                      www.pod-point.com
                    </a>
                  </p>
                  <p>
                    As our preferred supplier Pod Point offer Hyundai Electric
                    vehicle customers discounted price on their 7kW Smart Solo
                    Charger: £300 with OLEV grant (price includes VAT and
                    standard installation).
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
        <FooterNavbar />

        <Footer />
      </div>
    );
  }
}
