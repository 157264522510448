import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import './activation.css';
import Auth from '../utils/Auth';
import GetData from '../utils/GetData';
import Validation from '../utils/Validation';
import InputField from '../components/inputField';
import ValidationMessage from '../components/validationMessage';
import ReactPasswordStrength from 'react-password-strength';
import $ from 'jquery';
export default class Activation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      connectionError: false,
      activationCode: '',
      activationCodeExpired: false,
      incentives: false,
      password1: '',
      password2: '',
      email: '',
      postcode: '',
      invalidCode: false,
      alreadyActivated: false,
      loginNameTaken: false,
      logInSuccess: false,
      isLoading: false,
      isFailed: false,
      validation: {
        activationCode: Validation.initRequiredValidationState(),
        password1: Validation.initValidationState(['required', 'password']),
        password2: Validation.initValidationState(['required'], () =>
          Activation.passwordsMatch(this.state),
        ),
        email: Validation.initValidationState(['required', 'email']),
        postcode: Validation.initRequiredValidationState(),
      },
    };
  }

  componentDidMount() {
    if (!navigator.onLine) {
      this.setState({
        connectionError: true,
        error: false,
      });
    } else {
      this.setState({
        connectionError: false,
      });
    }
  }

  static passwordsMatch(state) {
    if (state.password1 !== state.password2) {
      return 'Passwords do not match';
    }

    return null;
  }

  static validationClass(val) {
    var className = '';
    className =
      !val || val == null || val.isValid
        ? className
        : className + ' is-invalid';

    return className;
  }

  activateUser = () => {
    //TODO: Remove this after testing
    if (this.state.activationCode.toLowerCase() === 'qaexbr') {
      $('#activationFailedModal').modal('show');
      return;
    }
    if (!navigator.onLine) {
      this.setState({
        connectionError: true,
        isLoading: false,
        error: false,
      });
      return;
    } else {
      this.setState({
        connectionError: false,
      });
    }

    var newState = Validation.validateAll(this.state);

    if (Validation.anyInvalid(newState)) {
      this.setState(newState);
    } else {
      this.setState({
        isLoading: true,
      });

      fetch(process.env.REACT_APP_API_URL + '/Registration/Activate', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ActivationCode: this.state.activationCode,
          CheckData: this.state.postcode,
          Email: this.state.email,
          Password: this.state.password1,
        }),
      })
        .then((response) => response.json())
        .then((responseData) => {
          if (responseData.status === 1) {
            // this.state.authenticationToken = responseData.authenticationToken;
            Auth.authenticateWithToken(responseData.authenticationToken);
            var me = this;
            GetData.populateModels(() => {
              me.setState(
                {
                  logInSuccess: true,
                  isFailed: false,
                  alreadyActivated: false,
                  loginNameTaken: false,
                  invalidCode: false,
                  isLoading: false,
                },
                () => {
                  this.setState({
                    isLoading: false,
                    isFailed: true,
                  });
                },
              );
            });
            // Invalid Code
          } else if (responseData.status === 2) {
            this.setState({
              logInSuccess: false,
              isFailed: false,
              invalidCode: true,
              incentives: true,
              loginNameTaken: false,
              alreadyActivated: false,
              isLoading: false,
            });
            //Already Activated
          } else if (responseData.status === 3) {
            this.setState({
              logInSuccess: false,
              isFailed: false,
              alreadyActivated: true,
              loginNameTaken: false,
              invalidCode: false,
              isLoading: false,
            });
            //Login Name Exists
          } else if (responseData.status === 4) {
            this.setState({
              logInSuccess: false,
              isFailed: false,
              loginNameTaken: true,
              invalidCode: false,
              alreadyActivated: false,
              isLoading: false,
            });
          } else if (responseData.status === 5) {
            this.setState({
              logInSuccess: false,
              isFailed: false,
              loginNameTaken: false,
              invalidCode: false,
              alreadyActivated: false,
              isLoading: false,
            });
            $('#activationFailedModal').modal('show');
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
            isFailed: true,
          });
        });
    }
  };
  handleInputChangeActivation = (val) => {
    this.setState({ activationCode: val.target.value }, () =>
      this.validateField('activationCode'),
    );
  };

  handleInputChangePass1 = (val) => {
    this.setState({ password1: val.password }, () =>
      this.validateField('password1'),
    );
  };
  handleInputChangePass2 = (val) => {
    this.setState({ password2: val.target.value }, () =>
      this.validateField('password2'),
    );
  };
  handleInputChangeEmail = (val) => {
    this.setState({ email: val.target.value }, () =>
      this.validateField('email'),
    );
  };
  handleInputChangePostcode = (val) => {
    this.setState({ postcode: val.target.value }, () =>
      this.validateField('postcode'),
    );
  };

  validateField(name) {
    this.setState((prevState) => {
      return Validation.validate(prevState, name);
    });
  }

  handleActivateModalClick = () => {
    $('#activationFailedModal').modal('hide');
    this.setState({ activationCodeExpired: true });
  };

  render() {
    if (this.state.activationCodeExpired) {
      return <Redirect to="/registration" />;
    }

    const extraSmall = {
      fontSize: '10px',
    };

    return (
      <div id="activationContainer" className="container">
        <div className="theme-login-container">
          {this.state.logInSuccess && <Redirect to="/incentives" />}
          <div className="d-flex flex-column flex-sm-row justify-content-between text-center  pb-1 pb-sm-4">
            <div>
              <img src="images/hyundai_logoF.png" style={{ maxHeight: 25 }} />
            </div>
            <div className="p-2 p-sm-0">
              <i className="fas fa-phone" />{' '}
              <a href="tel:0800981981" title="Call Us Now">
                0800 981 981
              </a>
            </div>
          </div>

          <div className="theme-activation-bg-image   p-sm-4 pt-sm-5 ">
            <div className="row  mx-0">
              <div className="col-lg-7 ml-auto p-3 bg-white">
                <p className="frmTitleText">Activate your account</p>
                <p className="frmTitleTextSmaller">
                  Nearly there. Just enter your details, then click 'Activate'.
                </p>
                <p className="mandatory">
                  <span className="mandatory">
                    <small style={extraSmall}>
                      Fields marked with a * are mandatory
                    </small>
                  </span>
                </p>

                <form>
                  <div className="row">
                    <div className="col-md-6 pr-3">
                      <div className="form-group">
                        <small>
                          <label htmlFor="activationActivationCodeInput">
                            Activation code *
                          </label>
                        </small>
                        <InputField
                          id="activationActivationCodeInput"
                          value={this.state.activationCode}
                          onChange={this.handleInputChangeActivation}
                          validation={this.state.validation.activationCode}
                        />
                        <ValidationMessage
                          validation={this.state.validation.activationCode}
                        />
                      </div>

                      <div className="form-group">
                        <small>
                          <label htmlFor="activationEmalInput">
                            Email address *
                          </label>
                        </small>
                        <InputField
                          type="email"
                          id="activationEmalInput"
                          value={this.state.email}
                          onChange={this.handleInputChangeEmail}
                          validation={this.state.validation.email}
                          autoComplete="email"
                        />
                        <ValidationMessage
                          validation={this.state.validation.email}
                        />
                      </div>

                      <div className="form-group">
                        <small>
                          <label htmlFor="activationPostcodeInput">
                            Postcode *
                          </label>
                        </small>
                        <InputField
                          id="activationPostcodeInput"
                          value={this.state.postcode}
                          onChange={this.handleInputChangePostcode}
                          validation={this.state.validation.postcode}
                          autoComplete="postal-code"
                        />
                        <ValidationMessage
                          validation={this.state.validation.postcode}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div
                        id="activationPasswordStrengthContainer"
                        className="form-group"
                      >
                        <small>
                          <label htmlFor="activationPasswordInput">
                            Password *
                          </label>
                        </small>

                        <ReactPasswordStrength
                          className={
                            'theme-main-flat-password-strength-container  ' +
                            Activation.validationClass(
                              this.state.validation.password1,
                            )
                          }
                          minLength={8}
                          minScore={2}
                          changeCallback={this.handleInputChangePass1}
                          inputProps={{
                            name: 'password_input',
                            className:
                              'form-control form-control-sm theme-main-flat-password-input',
                            id: 'activationPasswordInput',
                            value: this.state.password1,
                            autoComplete: 'new-password',
                          }}
                        />
                        <div
                          className={
                            'form-control d-none' +
                            Activation.validationClass(
                              this.state.validation.password1,
                            )
                          }
                        />
                        <ValidationMessage
                          validation={this.state.validation.password1}
                        />
                      </div>
                      <div className="form-group">
                        {
                          <small>
                            <label htmlFor="activationConfirmPasswordInput">
                              Confirm password *
                            </label>
                          </small>
                        }
                        <InputField
                          className="form-control form-control-sm flatInput theme-main-flat-confirm-password"
                          type="password"
                          autoComplete="new-password"
                          id="activationConfirmPasswordInput"
                          value={this.state.password2}
                          onChange={this.handleInputChangePass2}
                          validation={this.state.validation.password2}
                        />
                        <ValidationMessage
                          validation={this.state.validation.password2}
                        />
                      </div>
                    </div>
                  </div>

                  <br />
                  <small>
                    <p className="pt-1 ">
                      <a
                        href="https://www.hyundai.com/uk/en/privacy-policy.html"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="asUnderline">Click here</span>
                      </a>{' '}
                      to view the privacy policy.
                    </p>
                  </small>

                  <div className="form-row">
                    <div className="form-group col-md-6 order-sm-2">
                      <button
                        id="activationActivateButton"
                        type="button"
                        className=" btn btn-block btn-primary btn-sm float-right removeRadius mb-1"
                        onClick={this.activateUser}
                      >
                        {this.state.isLoading ? (
                          <div>
                            <i className="fas fa-spinner fa-spin fa-lg text-white" />
                          </div>
                        ) : (
                          <span>Activate</span>
                        )}
                      </button>
                    </div>
                    <div className="form-group col-md-6 order-sm-1">
                      <Link
                        id="activationGoBackButton"
                        role="button"
                        to="/"
                        className="col-md-6 btn btn-block btn-outline-dark btn-sm removeRadius "
                      >
                        Go back
                      </Link>
                    </div>
                  </div>
                </form>
                {this.state.isFailed && (
                  <p className="text-danger">
                    <small>
                      <i className="fas fa-exclamation-triangle" /> Error in
                      saving changes
                    </small>
                  </p>
                )}

                {this.state.loginNameTaken && (
                  <small className="text-danger">
                    <i className="fas fa-exclamation-triangle" /> Email already
                    registered. Please choose a different email address.
                  </small>
                )}

                {this.state.invalidCode && (
                  <div>
                    <small className="text-danger">
                      <i className="fas fa-exclamation-triangle" /> Invalid
                      activation code.
                    </small>
                  </div>
                )}

                {this.state.connectionError === true && (
                  <div className="text-center text-danger">
                    <p />
                    <small>
                      <i className="fas fa-exclamation-triangle" /> No internet
                      connection available
                    </small>
                  </div>
                )}

                {this.state.alreadyActivated && (
                  <small className="text-danger">
                    <i className="fas fa-exclamation-triangle" /> Activation
                    code has already been used.
                  </small>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Activation Expired - Modal   */}

        <div
          className="modal fade"
          id="activationFailedModal"
          tabIndex="-1"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Your activation code has expired
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body  text-center">
                <p className="small">
                  Your activation code has expired, please click on the link
                  below to set up your new MyHyundai account.
                </p>
                <p>
                  <button
                    type="button"
                    className="btn  btn-primary  removeRadius"
                    onClick={this.handleActivateModalClick}
                  >
                    Activate
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
