import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';
import Store from 'store';
import ReactGA from 'react-ga';
export default class NewKona23 extends Component {
  componentDidMount = () => {};

  clickHandler = () => {};

  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="newKona2308" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="oneMillion-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/newKona23Tile.jpg") ',
              }}
            />
            {/* <div
              id="Bayon-gallery-2"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/hyundaielectric2.jpg")',
              }}
            /> */}
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                {/* <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#Bayon-gallery-2"
                    style={{
                      backgroundImage: 'url("images/hyundaielectric2.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#Bayon-gallery-1"
                    style={{
                      backgroundImage: 'url("images/hyundaielectric1.jpg")',
                    }}
                  />
                </div> */}
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “All-New KONA crowned Car of the Year as Hyundai dominates
                  Auto Express New Car Awards”
                </p>
              </div>
              <hr />

              <NewsMedia articleId="awards" articleText="awards" />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    ALL-NEW KONA CROWNED CAR OF THE YEAR AS HYUNDAI DOMINATES
                    AUTO EXPRESS NEW CAR AWARDS
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    {' '}
                    <ul>
                      <li>
                        All-New Hyundai KONA named Car of the Year at Auto
                        Express New Car Awards
                      </li>
                      <li>
                        Also recognised as best Small Company Car and best Small
                        SUV
                      </li>
                      <li>
                        Judges praise “brilliant” KONA for its design,
                        spaciousness and electrified options
                      </li>
                      <li>
                        Hyundai TUCSON is best Mid-size SUV, while New Hyundai
                        i10 is best City Car
                      </li>
                      <li>
                        Prestigious Auto Express awards inform buyers about the
                        finest cars on the market
                      </li>
                      <li>
                        All-new KONA is available to order now, with prices from
                        £25,725
                      </li>
                      {/* <li>
                        Among IONIQ 5 N’s many exclusive performance features
                        are N Battery Preconditioning, N Race, N Pedal, N Brake
                        Regen, N Drift Optimizer, N Torque Distribution, N
                        Launch Control, N Grin Boost, N Active Sound +, N
                        e-shift, N Road Sense and Track SOC
                      </li> */}
                      {/* <li>
                        Hyundai is investing £1 million in a programme of school
                        trips in order to prevent children missing out on such
                        experiences
                      </li>
                      <li>
                        Bookings are now open for the initiative, which has
                        launched with Hyundai’s Educator in Residence, Tim
                        Campbell MBE
                      </li> */}
                    </ul>
                  </p>
                  {displayShowMore && (
                    <p>
                      <a
                        data-toggle="collapse"
                        href="#newKona23"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </div>

              <div className={moreSectionClassName} id="newKona23">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      <strong>
                        Hyundai’s All-New KONA is celebrating significant
                        success already, having been crowned Car of the Year at
                        the Auto Express New Car Awards, as well as Small SUV of
                        the Year and Small Company Car of the Year.
                      </strong>
                      <br />
                      <br />
                      The awards were handed out at a ceremony at London’s
                      Barbican Conservatory on what proved to be a dominant
                      evening for Hyundai, with the All-New KONA’s impressive
                      haul backed up by further recognition for the Hyundai
                      TUCSON as Mid-size SUV of the Year and the New Hyundai i10
                      as City Car of the Year.
                      <br />
                      <br />
                      Auto Express is one of the UK’s leading car news and
                      reviews titles both in print and online, and its New Car
                      Awards are long established, having been launched in 1993.
                      The awards are judged by an experienced team of road
                      testers, chaired by Editor-in-Chief Steve Fowler, and seek
                      to inform buyers of the best cars on the market across a
                      variety of sectors.
                      <br />
                      <br />
                      <img src="images/newKona231.jpg" className="img-fluid" />
                      <br />
                      <br />
                      The All-New KONA was revealed earlier this year and is
                      available to order now, with prices starting from £25,725.
                      First deliveries are scheduled this month.
                      <br />
                      <br />
                      <img src="images/newKona232.jpg" className="img-fluid" />
                      <br />
                      <br />
                      Auto Express’s experts were won over by the All-New KONA’s
                      long list of attributes, including its dynamic design,
                      cutting-edge technology, spacious interior and excellent
                      driving experience, plus the exceptional choice it affords
                      both private buyers and those seeking a company car, with
                      internal combustion engine (ICE), hybrid electric (HEV)
                      and all-electric versions (EV) available.
                      <br />
                      <br />
                      This helped it capture two highly prized category wins, as
                      well as the coveted Car of the Year title – the second
                      time in three years a Hyundai has clinched the top award,
                      following the IONIQ 5’s victory in 2021.
                      <br />
                      <br />
                      The winning theme continued with the Hyundai TUCSON
                      collecting the best Mid-size SUV award for the third year
                      running. As in 2021 and 2022, the TUCSON’s distinctive
                      design, advanced technology and exceptional ownership
                      experience swayed the judges, with its wide choice of
                      efficient powertrains and engaging driving experience also
                      receiving praise. Starting prices for the TUCSON are from
                      £30,200.
                      <br />
                      <br />
                      <img src="images/newKona233.jpg" className="img-fluid" />
                      <br />
                      <br />
                      Joining the All-New KONA and TUCSON on the roll of honour
                      is the New Hyundai i10, also unveiled earlier this year.
                      With a refreshed design, generous specification and an
                      affordable price, it is a more attractive proposition for
                      customers than ever, which was reflected by the Auto
                      Express judges naming it the City Car of the Year. The New
                      i10 is available to order now from £15,420, with first
                      deliveries later this year.
                      <br />
                      <br />
                      <img src="images/newKona234.jpg" className="img-fluid" />
                      <br />
                      <br />
                      "Hyundai continues to do spectacularly well at the Auto
                      Express New Car Awards with another Car of the Year – this
                      time the KONA – plus supporting victories for the TUCSON
                      and the updated i10. We’ve been blown away by the KONA,
                      which is now a much more mature and spacious package in a
                      number of electrified flavours. It’s a great looking car
                      that will be easy to live with and cost-effective to own –
                      a brilliant family SUV. The same can be said of its bigger
                      brother, the TUCSON, which is still impressing with its
                      quality, comfort and tech. And the little i10 proves that
                      affordable motoring is still out there and doesn’t come at
                      the expense of comfort and kit."
                      <br /> Steve Fowler
                      <br /> Auto Express Editor-in-Chief
                      <br />
                      <br />
                      The success in the Auto Express New Car Awards follows a
                      series of high-profile accolades for Hyundai’s dynamic
                      product range.
                      <br />
                      <br />
                      In April, the Hyundai IONIQ 6 picked up a hat-trick of top
                      prizes at the World Car Awards, where it was crowned World
                      Car of the Year, World Electric Vehicle and World Car
                      Design of the Year, matching the performance of the
                      Hyundai IONIQ 5 a year previously.
                      <br />
                      <br />
                      The IONIQ 6 was also named Best Company Car at the 2023
                      Autocar Awards and Saloon of the Year at the 2023 GQ Car
                      Awards. Plus, its excellent safety credentials were
                      endorsed as it achieved a five-star safety rating and Best
                      in Class selection in the Large Family Car category from
                      Euro NCAP.
                      <br />
                      <br />
                      It was also named Best EV Compact Executive Car by Fleet
                      World at the Great British Fleet Awards.
                      <br />
                      <br />
                      "We have been proud to see our innovative model range pick
                      up a number of prestigious awards over the past few years,
                      and I am particularly delighted that the All-New KONA is
                      the latest car to be recognised, with deliveries in
                      Britain starting imminently. The All-New KONA blends
                      boldness with real user-friendliness, and is a wonderful
                      example of how Hyundai can deliver for all types of
                      customer with its broad array of powertrains. We are
                      absolutely thrilled that the experts at Auto Express have
                      given it such a resounding seal of approval, as well as
                      recognising what compelling choices the TUCSON and New i10
                      continue to make in their respective areas of the market."
                      <br />
                      Ashley Andrew
                      <br />
                      President of Hyundai Motor UK
                      <br />
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
