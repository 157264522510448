import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HandbookJS from '../utils/Handbook';
import Store from 'store';

export default class FooterNavbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      competitions: Store.get('competitionInfo'),
      customer: Store.get('customerInfo'),
      offers: Store.get('offers'),
      carOffers: Store.get('carOffers'),
      vehicles: Store.get('vehicles'),
      handbooks: [],
      underFive:
        (Store.get('vehiclesInfo') &&
          Store.get('vehiclesInfo').isAnyVehicleUnderFiveYears) ||
        false,
      config: [],
    };
  }

  componentWillMount() {
    const handbooks = HandbookJS.GetHandbooks(this.state.vehicles);
    this.setState({
      handbooks: handbooks,
    });
  }

  componentDidMount() {
    this.setState({
      config: Store.get('public_config'),
    });
  }

  render() {
    const { customer, vehicles } = this.state;

    return (
      <div
        className="container px-3 py-4 whiteBG"
        data-search-tag="site-menu"
        data-search-exclude
      >
        <div className="row justify-content-lg-around">
          <div className="col-sm-6 col-lg-2 offset-lg-1">
            <ul className="bottomNavList list-unstyled">
              <li>
                <Link to="/personal">
                  <small>
                    <strong>My Profile</strong>
                  </small>
                </Link>
              </li>
              <li>
                <Link to="/personal">
                  <small>Contact Details</small>
                </Link>
              </li>
              <li>
                <Link to="/contactPref">
                  <small>Contact Preferences</small>
                </Link>
              </li>
              {/* <li>
                <Link to="/interests">
                  <small>My Interests</small>
                </Link>
              </li> */}
              <li>
                <Link to="/dealerDetails">
                  <small>Retailer Details</small>
                </Link>
              </li>
              <li>
                <Link to="/ManageMyAccount">
                  <small>Manage My Account</small>
                </Link>
              </li>
              {/* Excluded from Phase 2 */}
              {/* <li>
                <Link to="/myQuotes">
                  <small>Request History</small>
                </Link>
              </li> */}
              {customer.customerType !== 2 && (
                <li>
                  <Link to="/feedback">
                    <small>Feedback</small>
                  </Link>
                </li>
              )}
            </ul>
          </div>
          {customer.customerType === 2 && (
            <div className="col-sm-6 col-lg-2">
              <ul className="bottomNavList list-unstyled">
                <li>
                  <Link to="/peaceOfMind">
                    <small>
                      <strong>Service Products</strong>
                    </small>
                  </Link>
                </li>

                <li>
                  <Link to="/peaceOfMind">
                    <small>Peace Of Mind</small>
                  </Link>
                </li>
              </ul>
            </div>
          )}
          {customer.customerType !== 2 && vehicles.length > 0 && (
            <div className="col-sm-6 col-lg-2">
              <ul className="bottomNavList list-unstyled">
                <li>
                  <Link to="/myCarDetails">
                    <small>
                      <strong>My Car</strong>
                    </small>
                  </Link>
                </li>
                <li>
                  <Link to="/myCarDetails">
                    <small>Vehicle Details</small>
                  </Link>
                </li>
                <li>
                  <Link to="/myReminders">
                    <small>My Reminders</small>
                  </Link>
                </li>
                <li>
                  <Link to="/serviceHistory">
                    <small>Retailer Visits</small>
                  </Link>
                </li>
                {/* <li>
                  <Link to="/fuelCalculator">
                    <small>Fuel Saving Calculator</small>
                  </Link>
                </li> */}
                {this.state.handbooks && this.state.handbooks.length != 0 && (
                  <li>
                    <Link to="/myHandbook">
                      <small>My Handbook</small>
                    </Link>
                  </li>
                )}

                {/* Excluded from Phase 2 */}
                {/* {customer.customerType === 2 && (
                  <li>
                    <Link to="/podCharging">
                      <small>Pod Point Charging Points</small>
                    </Link>
                  </li>
                )} */}

                {this.state.underFive && (
                  <li>
                    <Link to="/servicePlans">
                      <small>Service Products</small>
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          )}
          <div className="col-sm-6 col-lg-2">
            <ul className="bottomNavList list-unstyled">
              <li>
                <Link to="/offers">
                  <small>
                    <strong>Offers</strong>
                  </small>
                </Link>
              </li>

              {this.state.offers.map((offer, index) => {
                return (
                  <li key={index}>
                    <Link to={offer.link} title={offer.title}>
                      <small>{offer.name}</small>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>

          <div className="col-sm-6 col-lg-2">
            <ul className="bottomNavList list-unstyled">
              <li>
                <Link to="/hyundaiNews">
                  <small>
                    <strong>Hyundai News</strong>
                  </small>
                </Link>
              </li>
              {/* <li>
                <Link to="/productNews">
                  <small>Product News</small>
                </Link>
              </li> */}
              <li>
                <Link to="/hyundaiNews">
                  <small>Hyundai News</small>
                </Link>
              </li>
              <li>
                <Link to="/awardsNews">
                  <small>Awards</small>
                </Link>
              </li>
              <li>
                {/* <Link to="/newsletter">
                  <small>MyHyundai Newsletter</small>
                </Link> */}
              </li>
            </ul>
          </div>

          {this.state.competitions &&
            this.state.config &&
            this.state.config.data &&
            this.state.config.data.competitions_active && (
              <div className="col-sm-6 col-md-3">
                <ul className="bottomNavList list-unstyled">
                  <li>
                    <Link to="/compsOverview">
                      <small>
                        <strong>Current Competitions</strong>
                      </small>
                    </Link>
                  </li>
                  {this.state.competitions.map((competition, index) => {
                    if (!competition.directLinkOnly) {
                      return (
                        <li key={index}>
                          <Link
                            to={{
                              pathname: '/competition',
                              search: '?id=' + competition.id,
                            }}
                          >
                            <small>{competition.shortTitle}</small>
                          </Link>
                        </li>
                      );
                    }
                  })}
                </ul>
              </div>
            )}
        </div>
      </div>
    );
  }
}
