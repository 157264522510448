import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';
import Store from 'store';
import ReactGA from 'react-ga';
export default class Commission extends Component {
  componentDidMount = () => {};

  clickHandler = () => {};

  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="commission-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/commissionheader.png") ',
              }}
            />
            <div
              id="commission-gallery-2"
              className="theme-news-header-image "
              style={{
                backgroundImage: 'url("images/commission1.png") ',
              }}
            />
            <div
              id="commission-gallery-3"
              className="theme-news-header-image "
              style={{
                backgroundImage: 'url("images/commission2.png") ',
              }}
            />
            <div
              id="commission-gallery-4"
              className="theme-news-header-image "
              style={{
                backgroundImage: 'url("images/commission3.png") ',
              }}
            />
            <div
              id="commission-gallery-5"
              className="theme-news-header-image "
              style={{
                backgroundImage: 'url("images/commission4.png") ',
              }}
            />
            <div
              id="commission-gallery-6"
              className="theme-news-header-image "
              style={{
                backgroundImage: 'url("images/commission5.png") ',
              }}
            />
            {/* <div
              id="Bayon-gallery-2"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/hyundaielectric2.jpg")',
              }}
            /> */}
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#commission-gallery-2"
                    style={{
                      backgroundImage: 'url("images/commission1.png") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#commission-gallery-3"
                    style={{
                      backgroundImage: 'url("images/commission2.png") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#commission-gallery-4"
                    style={{
                      backgroundImage: 'url("images/commission3.png") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#commission-gallery-5"
                    style={{
                      backgroundImage: 'url("images/commission4.png") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#commission-gallery-6"
                    style={{
                      backgroundImage: 'url("images/commission5.png") ',
                    }}
                  />
                </div>
                {/* <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#Bayon-gallery-1"
                    style={{
                      backgroundImage: 'url("images/hyundaielectric1.jpg")',
                    }}
                  />
                </div> */}
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “Hyundai Motor and Tate Announce the Opening of the Hyundai
                  Commission: Anicka Yi: In Love With The World ”
                </p>
              </div>
              <hr />

              <NewsMedia articleId="awards" articleText="awards" />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    HYUNDAI MOTOR AND TATE ANNOUNCE THE OPENING OF THE HYUNDAI
                    COMMISSION: ANICKA YI: IN LOVE WITH THE WORLD
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    {' '}
                    <ul>
                      <li>
                        Hyundai Commission: Anicka Yi: In Love With The World
                        open from Oct. 12, 2021 through Jan. 16, 2022 in Tate
                        Modern’s Turbine Hall
                      </li>
                      <li>
                        Yi, known for the way she fuses artistic imagination and
                        scientific research, populates the Turbine Hall with
                        floating machines, creating her largest and most
                        ambitious work to date
                      </li>
                      <li>
                        A key part of the unique long-term partnership with
                        Tate, the Hyundai Commission represents Hyundai Motor’s
                        commitment to supporting initiatives that encourage new
                        ways of thinking about art and the values and
                        connections it can create.
                      </li>
                    </ul>
                  </p>
                  {displayShowMore && (
                    <p>
                      <a
                        data-toggle="collapse"
                        href="#commission"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </div>

              <div className={moreSectionClassName} id="commission">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      October 11 2021 -{' '}
                      <strong>
                        Hyundai Motor Company and Tate Modern today announced
                        the opening of Hyundai Commission: Anicka Yi: In Love
                        With The World as Tate Modern unveils a captivating
                        installation by artist Anicka Yi. For this Hyundai
                        Commission, Yi began with the question of what a
                        ‘natural history of machines’ could look and feel like,
                        built on her previous work speculating on the
                        possibilities of machines evolving into independent
                        forms of life. Referencing the Turbine Hall’s original
                        purpose of housing turbines of the former Bankside Power
                        Station before the site was developed into a gallery, Yi
                        populates the hall with machines once again,
                        transforming the space with her vision of a new kind of
                        ecosystem. Moving through the air, her floating machines
                        – called aerobes – prompt viewers to think about new
                        ways that machines might inhabit the world. It is the
                        sixth Hyundai Commission, an annual series of
                        site-specific works created for the Turbine Hall by
                        international artists, as part of a long-term
                        partnership between Tate and Hyundai Motor.
                      </strong>
                      <br />
                      <br />
                      Yi has become known for her experimental work which
                      explores the merging of technology and biology, focusing
                      on breaking down distinctions we hold between plants,
                      animals, micro-organisms and technology. Through this
                      work, she asks viewers to think about how removing these
                      distinctions affects our understanding of ourselves as
                      humans, and the ecosystems we live in.
                      <br />
                      <br /> “The sixth Hyundai Commission with Anicka Yi offers
                      us a unique moment to reflect on our understanding of an
                      interconnected future,” said Thomas Schemera, Global Chief
                      Marketing Officer and Head of Customer Experience Division
                      of Hyundai Motor Company. “As our partnership with Tate
                      deepens, we will continue to build on our shared vision to
                      expand experiences that encourage us all to explore timely
                      questions and discover new perspectives.”
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      <img src="images/commission1.png" className="img-fluid" />
                      <br />
                      <br />
                      (from left to right) Thomas Schemera (Global Chief
                      Marketing Officer and Head of Customer Experience Division
                      of Hyundai Motor Company), Frances Morris (Director of
                      Tate Modern), Anicka Yi (Hyundai Commission artist), Achim
                      Borchardt-Hume (Director of Exhibitions and Programmes of
                      Tate Modern)
                      <br />
                      <br /> Two species of aerobes explore the Turbine Hall,
                      exhibiting individual and group behaviors in response to
                      different elements of their environment. ‘Xenojellies’
                      have semitransparent bodies each with a different colored
                      top and patterned tentacles, while ‘planulae’ are bulbous
                      and covered by short yellow hair. Both species are filled
                      with helium, propelled by rotors and powered by a small
                      battery pack. Their biomorphic forms reference both ocean
                      lifeforms and mushrooms, reflecting the varying roles and
                      levels of complexity amongst organisms in an ecosystem.
                      These aerobes allow Yi to imagine new possibilities for
                      artificial intelligence, inspired by the diverse ways that
                      organisms learn through their bodies and senses.
                      <br />
                      <br />{' '}
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      <img src="images/commission2.png" className="img-fluid" />
                      <br />
                      <br />
                      <strong>
                        Installation view of Hyundai Commission: Anicka Yi: In
                        Love With The World
                      </strong>
                      <br />
                      <br />
                      Integral to Yi’s commission as well as her wider practice
                      are scent and air. She is interested in the politics of
                      air and how this is affected by changing attitudes,
                      inequalities, and ecological awareness. She approaches
                      scent as a medium for subtly shifting perceptions and
                      giving presence to the air that we all share, and on which
                      we depend. For Tate Modern, the artist has created unique
                      scentscapes that transition from one week to the next,
                      evoking odors linked to a specific time in the history of
                      Bankside. These scentscapes create an environment that
                      connects the aerobes with the history of the site and all
                      other organisms that share their habitat. As the odors
                      change between each unique scentscape, the aerobes'
                      behavior and interactions will develop in response.
                      <br />
                      <br />
                      <img src="images/commission3.png" className="img-fluid" />
                      <br />
                      <br />
                      <strong>
                        {' '}
                        Installation view of Hyundai Commission: Anicka Yi: In
                        Love With The World
                      </strong>{' '}
                      <br />
                      <br />
                      Hyundai Motor’s commitment to supporting art and its
                      global communities has led to long-term partnerships with
                      museums and organizations around the world. The aim is to
                      encourage new ways of thinking about art and the values
                      and connections it can create by supporting initiatives
                      where artists, communities, and institutions come together
                      to offer transformative experiences. Hyundai Motor has
                      been supporting art initiatives driven by long-term
                      partnerships with global museums ― Tate, the National
                      Museum of Modern and Contemporary Art, Korea (MMCA), and
                      the Los Angeles County Museum of Art (LACMA) since 2013.
                      In addition to the Hyundai Commission, Hyundai Motor
                      supports the Hyundai Tate Research Centre: Transnational
                      through its partnership with Tate. The Hyundai Tate
                      Research Centre: Transnational, launched in January 2019,
                      continues to help challenge and revise dominant art
                      histories and highlight the global exchanges of artists
                      and ideas.
                      <br />
                      <br />
                      <img src="images/commission4.png" className="img-fluid" />
                      <br />
                      <br />
                      <strong>
                        Installation view of Hyundai Commission: Anicka Yi: In
                        Love With The World
                      </strong>
                      <br />
                      <br />
                      Hyundai Commission: Anicka Yi: In Love With The World is
                      curated by Achim Borchardt-Hume, Director of Exhibitions
                      and Programmes, Tate Modern; Mark Godfrey, former Senior
                      Curator, International Art, Tate Modern; and Carly
                      Whitefield, Assistant Curator, International Art, Tate
                      Modern. Produced by Petra Schmidt, Production Manager,
                      Tate Modern. It will be accompanied by a new book from
                      Tate Publishing.
                      <br />
                      <br /> Hyundai Commission: Anicka Yi: In Love With The
                      World October 12, 2021 – January 16, 2022, Tate Modern In
                      partnership with Hyundai Motor. Supported by the Anicka Yi
                      Supporters, with additional support from the Supporters
                      Circle and Tate Americas Foundation
                      <br />
                      <br />
                      <img src="images/commission5.png" className="img-fluid" />
                      <strong>
                        Installation view of Hyundai Commission: Anicka Yi: In
                        Love With The World
                      </strong>
                      <br />
                      <br />
                      <strong>About Anicka Yi</strong> <br />
                      <br />
                      Anicka Yi was born in Seoul in 1971 and now lives and
                      works in New York City. She has been the subject of solo
                      exhibitions at museums including the Solomon R. Guggenheim
                      Museum in New York, Kunsthalle Basel, and the
                      Fridericianum in Kassel. Her work featured in the Venice
                      Biennale in 2019, the Whitney Biennial in 2017 and the
                      Gwangju Biennale in 2016, as well as in many group
                      exhibitions around the world. She won the Hugo Boss Prize
                      in 2016 and has held residencies and fellowships at the
                      Berggruen Institute in Los Angeles, the Headlands Center
                      for the Arts, and the Center for Art Science and
                      Technology at MIT.
                      <br />
                      <br />
                      <strong>About Tate Modern Turbine Hall</strong>
                      <br />
                      <br /> Since Tate Modern opened in 2000, the Turbine Hall
                      has hosted some of the world’s most memorable and
                      acclaimed works of contemporary art, reaching an audience
                      of millions each year. The way artists have interpreted
                      this vast industrial space has revolutionized public
                      perceptions of contemporary art in the twenty-first
                      century. The annual Hyundai Commission gives artists an
                      opportunity to create new work for this unique context.
                      The commissions are made possible by the long-term
                      partnership between Tate and Hyundai Motor, confirmed
                      until 2026 as part of the longest initial commitment from
                      a corporate partner in Tate’s history.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
