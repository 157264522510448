import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';
import Store from 'store';
import ReactGA from 'react-ga';
export default class Ioniq5 extends Component {
  componentDidMount = () => {};

  clickHandler = () => {};

  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="ioniq5-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/ion3.jpg") ',
              }}
            />
            <div
              id="ioniq5-gallery-2"
              className="theme-news-header-image "
              style={{
                backgroundImage: 'url("images/ion1.jpg") ',
              }}
            />
            <div
              id="ioniq5-gallery-3"
              className="theme-news-header-image "
              style={{
                backgroundImage: 'url("images/ion2.jpg") ',
              }}
            />
            <div
              id="ioniq5-gallery-4"
              className="theme-news-header-image "
              style={{
                backgroundImage: 'url("images/ion3.jpg") ',
              }}
            />
            <div
              id="ioniq5-gallery-5"
              className="theme-news-header-image "
              style={{
                backgroundImage: 'url("images/ion4.jpg") ',
              }}
            />
            <div
              id="ioniq5-gallery-6"
              className="theme-news-header-image "
              style={{
                backgroundImage: 'url("images/ion5.jpg") ',
              }}
            />
            <div
              id="ioniq5-gallery-7"
              className="theme-news-header-image "
              style={{
                backgroundImage: 'url("images/ion6.jpg") ',
              }}
            />
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#ioniq5-gallery-2"
                    style={{
                      backgroundImage: 'url("images/ion1.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#ioniq5-gallery-3"
                    style={{
                      backgroundImage: 'url("images/ion2.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#ioniq5-gallery-4"
                    style={{
                      backgroundImage: 'url("images/ion3.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#ioniq5-gallery-5"
                    style={{
                      backgroundImage: 'url("images/ion4.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#ioniq5-gallery-6"
                    style={{
                      backgroundImage: 'url("images/ion5.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#ioniq5-gallery-7"
                    style={{
                      backgroundImage: 'url("images/ion6.jpg") ',
                    }}
                  />
                </div>
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “Hyundai IONIQ 5 scoops multiple honours at the 2021 News UK
                  Motor Awards”
                </p>
              </div>
              <hr />

              <NewsMedia articleId="awards" articleText="awards" />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    HYUNDAI IONIQ 5 SCOOPS MULTIPLE HONOURS AT THE 2021 NEWS UK
                    MOTOR AWARDS
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    {' '}
                    <ul>
                      <li>
                        Hyundai IONIQ 5 voted ‘Best Designed Car of the Year’ by
                        expert judging panel
                      </li>
                      <li>
                        Hyundai IONIQ 5 voted ‘Best Designed Car of the Year’ by
                        expert judging panel
                      </li>
                      <li>
                        The exterior design is a futuristic interpretation of
                        Hyundai’s first ever production car, the Pony
                      </li>
                      <li>
                        IONIQ 5, Hyundai’s first car built on a new dedicated
                        E-GMP battery electric vehicle platform can charge from
                        10-80% in just 18 minutes and travel up to 298 miles on
                        a single charge
                      </li>
                    </ul>
                  </p>
                  {displayShowMore && (
                    <p>
                      <a
                        data-toggle="collapse"
                        href="#ioniq5"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </div>

              <div className={moreSectionClassName} id="ioniq5">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      10 October 2021 -{' '}
                      <strong>
                        Hyundai IONIQ 5 has once again been recognised by
                        motoring press, winning the 2021 News UK Motor Awards
                        for ‘Best Designed Car of the Year’ and ‘Motoring
                        Innovation of the Year’.
                      </strong>
                      <br />
                      <br />
                      Each category is judged by an expert panel of motoring
                      journalists from Driving.co.uk, The Sunday Times, The Sun
                      and The Times LUXX magazine. In winning the ‘Best Designed
                      Car of the Year’, IONIQ 5 beat competition from the Aston
                      Martin V12 Speedster and Maserati MC20. In the ‘Motoring
                      Innovation of the Year’ category, it was the IONIQ 5’s
                      relaxation seats and Vehicle-to-Load (V2L) functionality
                      that was highlighted by the judges as the key innovations
                      that set the model apart from competitor products and
                      James May’s ‘My Theory Test’ app.
                    </p>
                  </div>
                </div>

                {/* <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      Early in January, the{' '}
                      <a
                        href="https://www.hyundai.news/uk/brand/hyundai-i10-is-what-cars-best-small-car-for-the-city-2021/"
                        target="_blank"
                      >
                        all-new i10 was named What Car?’s Best Small Car for the
                        City,
                      </a>{' '}
                      then the previous generation{' '}
                      <a
                        href="https://www.hyundai.news/uk/model-news/hyundai-scores-double-win-at-what-car-used-car-awards/"
                        target="_blank"
                      >
                        i20 was named as First Car’s Used Car of the Year
                      </a>{' '}
                      as a ‘safe, reliable and easy to drive’ car for new
                      drivers. Continuing Hyundai’s small car wins, carwow named
                      the i10 as its Best Small Car, saying: “it’s a doddle to
                      drive but still roomy enough for four adults and their
                      stuff. It’s big on technology, too.”
                    </p>
                  </div>
                </div> */}

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      <img src="images/ion1.jpg" className="img-fluid" />
                      <br />
                      <br />
                      Commenting on the decision to award IONIQ 5 with both the
                      ‘Best Designed Car of the Year’ and ‘Motoring Innovation
                      of the Year’ accolades,{' '}
                      <strong>Will Dron, Editor of Driving.co.uk, said:</strong>
                      <br />
                      <br />
                      "The IONIQ 5's exterior design is what catches your
                      attention immediately, but it's the innovation inside that
                      really impresses. Many car makers talk about creating
                      'lounge-style cabins' in their new cars but Hyundai is the
                      first to truly explore that concept in a production car,
                      with super-comfortable reclining seats that allow
                      next-level relaxation during breaks from driving. And the
                      Vehicle-to-Load technology turns IONIQ 5 into a powerbank
                      on wheels, which opens up all sorts of outdoor activity
                      possibilities, from charging e-bikes to powering lights
                      and appliances while camping. Both innovations that other
                      car makers are bound to follow in the near future."
                      <br />
                      <br />
                      <img src="images/ion2.jpg" className="img-fluid" />
                      <br />
                      <br />
                      <strong>
                        Ashley Andrew, Managing Director of Hyundai Motor UK
                        said:
                      </strong>
                      “We are delighted to see the Hyundai IONIQ 5 recognised by
                      many of the nation’s leading motoring media. Both the
                      design and technological innovation of INONIQ 5 are key to
                      our mission of leading the march with our electrified
                      mobility solutions. These latest awards add to a rapidly
                      growing set of honours for this ground-breaking model,
                      acclaimed by consumers and media alike.”
                      <br />
                      <br />
                      <img src="images/ion3.jpg" className="img-fluid" />
                      <br />
                      <br />
                      IONIQ 5’s progressive design represents a departure from
                      past norms, exploring a new design freedom offered by a
                      dedicated BEV platform, evoking the daring attitude of
                      Hyundai Pony, the company’s first production car. The
                      front of the car is equipped with Hyundai’s first
                      clamshell hood that minimises panel gaps for optimal
                      aerodynamics and aesthetic appeal.
                      <br />
                      <br /> The front bumper is defined by an eye-catching
                      V-shape incorporating distinctive daytime running lights
                      (DRLs) that provide an unmistakable light signature unique
                      to IONIQ 5. On the sides, flush door handles provide clean
                      surface styling and enhanced aerodynamic efficiency. The
                      front and rear forms of the vehicle merge together at its
                      doors.
                      <br />
                      <br />
                      <img src="images/ion4.jpg" className="img-fluid" />
                      <br />
                      <br />
                      Inside IONIQ 5 is a new ‘Living Space’ cabin that takes
                      advantage of IONIQ 5’s bespoke platform. Axles pushed
                      right to the front and back of the car mean there’s a huge
                      amount of space in the interior, while the flat floor and
                      reclining chairs allow for levels of comfort and
                      flexibility never before seen in this segment.
                      <br />
                      <br />
                      <img src="images/ion5.jpg" className="img-fluid" />
                      <br />
                      <br />
                      Many of the interior touchpoints are eco-friendly: the
                      seats, headliner, door trim, floor and armrest use
                      sustainably sourced materials, such as recycled PET
                      bottles, plant-based (bio PET) yarns and natural wool
                      yarns, eco-processed leather with plant-based extracts,
                      and bio paint with plant extracts.
                      <br />
                      <br />
                      <img src="images/ion6.jpg" className="img-fluid" />
                      <br />
                      <br />
                      IONIQ 5 is able to charge from 10%-80% in just 18 minutes,
                      accelerate from 0-62mph in as little as 5.2 seconds and
                      travel up to 298 miles on a single charge.
                      <br />
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
