import React, { Component } from 'react';
import './servicePlans.css';
import FooterNavbar from '../components/footerNavbar';
import Store from 'store';
import Topnavbar from '../components/topnavbar';
import Bottomnavbar from '../components/bottomnavbar';
import Footer from '../components/footer';
import $ from 'jquery';
import JSEncrypt from 'jsencrypt';
import OffersSideNav from '../components/offersSideNav';
import ServiceProductNavbar from '../components/serviceProductNavbar.jsx';

const ulStyle = {
  padding: '5px',
};

export default class PeaceOfMind extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Topnavbar />
        <Bottomnavbar />

        <div className="container">
          {/* <ProfileNav /> */}
          {<ServiceProductNavbar />}
          <section
            className="section1 wow fadeIn"
            data-wow-duration="2s"
            data-wow-delay="0.2s"
          >
            <div className="content p-3 p-xl-5 col-md-10 offset-md-1 border-right border-bottom">
              <div className="row">
                <div className="col-lg-3 p-3 p-sm-4">
                  {/* <OffersSideNav /> */}
                  <h4 className="h4ServHistory" data-search-tag="title">
                    <strong>Peace Of Mind</strong>
                  </h4>
                  <p className="mb-0" data-search-tag="summary">
                    It's reassuring to know that every Hyundai comes complete
                    with a 5-Year Unlimited Mileage Warranty and 12 month's free
                    Roadside Assistance.
                  </p>
                </div>

                <div className="col-lg-8 offset-lg-1 p-sm-4 border-lg-left">
                  <div className="theme-main-position-relative">
                    <img
                      className="card-img-top img-fluid"
                      src="images/peaceofmindpng.png"
                    />
                  </div>
                  <h4
                    className="h4ServHistory mt-4 mb-4"
                    data-search-tag="title"
                  >
                    <strong>5-Year Unlimited Mileage Warranty</strong>
                  </h4>

                  <p className="remindersBlue" data-search-tag="summary">
                    At Hyundai, we're so confident in the quality of our cars we
                    offer a 5-Year Unlimited Mileage Warranty to all our
                    customers. It offers comprehensive cover for any
                    manufacturing defects, excluding service or wear and tear
                    items. So you can enjoy driving your new Hyundai with
                    complete reassurance. It's also fully transferable to the
                    new owner should you sell the car. The package also includes
                    five free annual vehicle health checks at your local dealer
                    and a 12 Year Anti-Perforation Warranty for the bodywork.
                  </p>

                  <h4
                    className="h4ServHistory mt-4 mb-4 "
                    data-search-tag="title"
                  >
                    <strong>Roadside Assistance</strong>
                  </h4>
                  <p className="remindersBlue">
                    Every new Hyundai also comes complete with 24-Hour Roadside
                    Assistance should you ever need it, anywhere in the UK or
                    the Channel Islands. With Onward Travel cover included to
                    get you home if your car can't be fixed at the roadside.
                  </p>
                  <p className="remindersBlue">
                    For more information, contact your{' '}
                    <u>
                      <a
                        href="https://www.hyundai.co.uk/dealer-locator?gclid=EAIaIQobChMIu4ncyZ_J3AIViLHtCh1yhg_NEAAYASABEgL1sfD_BwE"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="font-weight-bold text-dark"
                      >
                        local dealer
                      </a>
                    </u>{' '}
                    or call Customer Services on{' '}
                    <a href="tel:0800981981"> 0800 981 981.</a>
                  </p>
                </div>
              </div>
            </div>
          </section>
          <FooterNavbar />
        </div>

        <Footer />
      </div>
    );
  }
}
