import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './interests.css';
import FooterNavbar from '../components/footerNavbar';
import Store from 'store';
import Topnavbar from '../components/topnavbar';
import Bottomnavbar from '../components/bottomnavbar';
import ProfileNav from '../components/profileNavbar';
import Interest from '../components/interests/interest';
import SaveData from '../utils/SaveData';
import Footer from '../components/footer';

export default class Interests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      interests: [],
      saveSuccess: false,
      saveFailed: false,
      isLoading: false,
    };

    this.handleInterestChange = this.handleInterestChange.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    const customer = Store.get('customerInfo');
    const interestImages = [
      { label: 'Wining & Dining', imageId: '0' },
      { label: 'Tate Modern and Britain', imageId: '1' },
      //{ label: "NFL", imageId: "2" },
      { label: 'Football', imageId: '3' },
      { label: 'Culture', imageId: '4' },
      { label: 'Family Days', imageId: '6' },
      { label: 'Music', imageId: '7' },
      { label: 'Surprise Me', imageId: '9' },
      { label: 'Hyundai Offers', imageId: '10' },
    ];

    if (customer) {
      let combinedInterests = interestImages
        .map((interestImage) => {
          var matchingInterest = customer.interestList.filter(
            (interest) => interest.label === interestImage.label,
          );
          if (matchingInterest.length === 1) {
            return {
              ...matchingInterest[0],
              imageId: interestImage.imageId,
            };
          }
        })
        .filter((interest) => interest != null);

      const spacer = { label: '', isSelected: false, imageId: '' };
      combinedInterests.push(spacer);

      this.setState({
        interests: combinedInterests,
      });
    }
  }

  handleInterestChange(label, isSelected) {
    this.setState((prevState) => {
      var interests = prevState.interests;
      var index = -1;
      for (var i = 0; i < interests.length; i++) {
        if (interests[i].label === label) {
          index = i;
          break;
        }
      }

      interests[index].isSelected = JSON.parse(isSelected);

      return {
        interests: interests,
      };
    });
  }

  handleSaveClick() {
    this.setState({
      isLoading: true,
    });

    let customer = Store.get('customerInfo');

    customer.interestList = this.state.interests.map((interest) => {
      return {
        label: interest.label,
        isSelected: interest.isSelected,
      };
    });

    var me = this;
    SaveData.saveCustomer(
      JSON.stringify(customer),
      () => {
        Store.set('customerInfo', customer),
          me.setState({
            saveSuccess: true,
            isLoading: false,
          });
      },
      () => {
        me.setState({
          saveFailed: true,
          isLoading: false,
        });
      },
    );
  }

  render() {
    const interests = this.state.interests.map((interest) => (
      <Interest
        key={interest.imageId}
        data={interest}
        onChange={this.handleInterestChange}
      />
    ));

    return (
      <div>
        <Topnavbar />
        <Bottomnavbar />

        <div className="container">
          <ProfileNav />

          <section
            className="section1 wow fadeIn"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <div className="content p-3 p-xl-5 col-md-10 offset-md-1 border-right border-bottom">
              <div className="row">
                <div className="col-md-3 p-3">
                  <h4 className="h4ServHistory" data-search-tag="title">
                    My Interests
                  </h4>
                  <p />
                </div>

                <div className="col-md-8 offset-md-1 px-4 pt-4">
                  <p className="remindersBlue">
                    <span data-search-tag="summary">
                      Welcome to My Interests, making MyHyundai even better for
                      you. Tell us what interests you most from the categories
                      below.
                    </span>{' '}
                    We'll then do our best to bring you competitions and prizes
                    you'll love. Click on as many of the boxes as you want. If
                    you don't select an interest we won't email you, but you
                    will be able to view all competitions through MyHyundai in
                    case you change your mind.
                  </p>
                  <div className="interests justify-content-between">
                    {interests}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col col-md-4 offset-md-8 text-center">
                  <button
                    className="btn btn-primary btn-sm btn-block removeRadius"
                    onClick={this.handleSaveClick}
                  >
                    {this.state.isLoading ? (
                      <div>
                        <i className="fas fa-spinner fa-spin fa-lg text-white" />
                      </div>
                    ) : (
                      <span>Save Details</span>
                    )}
                  </button>

                  {this.state.saveSuccess && (
                    <div className="text-center text-success">
                      <p />
                      <small>
                        <i className="fas fa-check-circle" /> Changes have been
                        saved
                      </small>
                    </div>
                  )}
                  {this.state.saveFailed && (
                    <div className="text-center text-danger">
                      <p />
                      <small>
                        <i className="fas fa-exclamation-triangle" /> Error in
                        saving changes
                      </small>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>

          <FooterNavbar />
        </div>
        <Footer />
      </div>
    );
  }
}
