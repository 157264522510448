import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';
import Store from 'store';
import ReactGA from 'react-ga';
export default class I5n extends Component {
  componentDidMount = () => {};

  clickHandler = () => {};

  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="I5n" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="oneMillion-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/i5n1.jpg") ',
              }}
            />
            {/* <div
              id="Bayon-gallery-2"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/hyundaielectric2.jpg")',
              }}
            /> */}
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                {/* <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#Bayon-gallery-2"
                    style={{
                      backgroundImage: 'url("images/hyundaielectric2.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#Bayon-gallery-1"
                    style={{
                      backgroundImage: 'url("images/hyundaielectric1.jpg")',
                    }}
                  />
                </div> */}
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “Groundbreaking Hyundai IONIQ 5 N named Car of the Year by
                  TopGear.com”
                </p>
              </div>
              <hr />

              <NewsMedia articleId="awards" articleText="awards" />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    GROUNDBREAKING HYUNDAI IONIQ 5 N NAMED CAR OF THE YEAR BY
                    TOPGEAR.COM
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    {' '}
                    <ul>
                      <li>
                        High-performance electric hatch collects TopGear.com’s
                        Car of the Year award
                      </li>
                      <li>
                        IONIQ 5 N is brand’s first electrified N model for
                        driving enthusiasts
                      </li>
                      <li>
                        Game-changer is praised by TopGear judges for “turning a
                        new page” for cars
                      </li>
                      <li>
                        650PS IONIQ 5 N available to order now, with prices from
                        £65,000
                      </li>
                      {/* <li>
                        Significant interior changes to enhance driving
                        experience
                      </li> */}
                      {/* <li>
                        All-new Santa Fe to make world premiere in August via
                        Hyundai’s YouTube channel
                      </li> */}
                      {/* <li>
                        Among IONIQ 5 N’s many exclusive performance features
                        are N Battery Preconditioning, N Race, N Pedal, N Brake
                        Regen, N Drift Optimizer, N Torque Distribution, N
                        Launch Control, N Grin Boost, N Active Sound +, N
                        e-shift, N Road Sense and Track SOC
                      </li> */}
                      {/* <li>
                        Hyundai is investing £1 million in a programme of school
                        trips in order to prevent children missing out on such
                        experiences
                      </li>
                      <li>
                        Bookings are now open for the initiative, which has
                        launched with Hyundai’s Educator in Residence, Tim
                        Campbell MBE
                      </li> */}
                    </ul>
                  </p>
                  {displayShowMore && (
                    <p>
                      <a
                        data-toggle="collapse"
                        href="#Newin5"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </div>

              <div className={moreSectionClassName} id="Newin5">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      <strong>
                        Hyundai’s IONIQ 5 N electric hyper hatch has made a
                        storming start to life by being crowned Car of the Year
                        at the 2023 TopGear.com Awards.
                      </strong>
                      <br />
                      <br />
                      The IONIQ 5 N was revealed earlier this year at July’s
                      Goodwood Festival of Speed, where it received universal
                      acclaim as a thrilling new option for driving enthusiasts
                      looking to electrify their passion on both road and track,
                      and as a pioneering technological blueprint for the
                      Hyundai N division’s EV future.
                      <br />
                      <br />
                      {/* <img src="images/newi20231.jpg" className="img-fluid" />
                      <br />
                      <br /> */}
                      The high-performance model combines the Electrified-Global
                      Modular Platform (E-GMP) of the standard IONIQ 5 – the
                      2022 World Car of the Year – with N’s motorsport-bred
                      technologies and expertise to take driving fun to a new
                      level. And it’s this unswerving focus on delivering
                      maximum enjoyment and excitement behind the wheel that
                      persuaded TopGear’s expert team of judges to name the car
                      the best of the past 12 months.
                      <br />
                      <br />
                      <strong>
                        "Hyundai’s engineers set out to make the IONIQ 5 N feel
                        like a lairy petrol car, but became converts to what
                        electrification could do. They took advantage of an EV’s
                        digital domains to give a vast extra set of adjustable
                        parameters. As a result, on road and track it does what
                        you’d never expect of a big EV… overdelivers to a
                        staggering extent. Forget any ‘for an EV’ qualifiers –
                        it’s huge fun full stop. It turns a page for electric
                        cars, which means a new page for cars."
                      </strong>
                      <br />
                      Paul Horrell, TopGear writer and Car of the Year Juror
                      <br />
                      <br />
                      The IONIQ 5 N’s success marks the second time in three
                      years that a Hyundai N car has taken the Top Gear Car of
                      the Year honour, following the i20 N in 2021.
                      <br />
                      <br />
                      Among the IONIQ 5 N’s array of key technical advances that
                      won over the judges is a powertrain which delivers greater
                      power output and enhanced performance in battery cooling
                      and braking. The IONIQ 5 N’s electric motors spin up to
                      21,000 RPM, providing up to 609 PS in normal conditions,
                      or 650 PS when the car’s N Grin Boost (NGB) feature is
                      engaged, maximising acceleration for up to 10 seconds.
                      0-62mph is achievable in 3.4 seconds, while a top speed of
                      161mph is attainable on tracks.
                      <br />
                      <br />
                      A memorable drive is guaranteed by a host of upgrades,
                      including a strengthened steering column for improved
                      feedback, Electronic Controlled Suspension that adapts
                      suspension stiffness according to the drive mode, and a
                      suite of sophisticated electronic control technology in
                      the E-GMP platform. Highlights include N Pedal, an
                      intelligent software function that provides instant
                      turn-in and enhanced throttle sensitivity for exhilarating
                      cornering, and fully variable front and rear N Torque
                      Distribution.
                      <br />
                      <br />
                      The IONIQ 5 N also sets a new benchmark in terms of
                      emotional engagement for a performance EV, courtesy of N
                      e-shift, which simulates the eight-speed dual-clutch
                      transmission of internal combustion engine N cars, and N
                      Active Sound+, which provides a compelling soundtrack that
                      highlights the EV’s power.
                      <br />
                      <br />
                      <img src="images/i5n2.jpg" className="img-fluid" />
                      <br />
                      <br />
                      <img src="images/i5n3.jpg" className="img-fluid" />
                      <br />
                      <br />
                      <img src="images/i5n4.jpg" className="img-fluid" />
                      <br />
                      <br />
                      <img src="images/i5n5.jpg" className="img-fluid" />
                      <br />
                      <br />
                      Order books are now open, with prices starting at £65,000.
                      <br />
                      <br />
                      TopGear.com’s award is the latest recognition for
                      Hyundai’s impressive product range, which has earned a
                      string of accolades in the past year. In April, the
                      Hyundai IONIQ 6 was crowned World Car of the Year, World
                      Electric Vehicle and World Car Design of the Year,
                      matching the performance of the Hyundai IONIQ 5 in 2022.
                      <br />
                      <br />
                      The IONIQ 6 was also named Saloon of the Year by GQ and
                      Best Company Car by Autocar, while Auto Express crowned
                      the TUCSON Best Mid-size SUV and the New i10 Best City
                      Car. The SANTA FE was awarded Seven-seater of the Year by
                      WhatCar?, while the All-New KONA and the i20 N triumphed
                      in the Carbuyer awards, picking up Best Small Family Car
                      and Best Hot Hatch respectively.
                      <br />
                      <br />
                      <strong>
                        "The IONIQ 5 N is a game changer that really does
                        demonstrate Hyundai’s technological leadership, and it
                        is truly gratifying that its excellence has been
                        recognised by the experts at TopGear. By combining
                        magnificent performance with precision handling and
                        genuine emotional engagement, the IONIQ 5 N takes
                        electric cars into exciting new territory – and yet it
                        works as an efficient family SUV, too."
                      </strong>
                      <br />
                      Ashley Andrew, President of Hyundai & Genesis UK
                      <br />
                      {/* The IONIQ 5 N also sets a new benchmark in terms of
                      emotional engagement for a performance EV, courtesy of N
                      e-shift, which simulates the eight-speed dual-clutch
                      transmission of internal combustion engine N cars, and N
                      Active Sound+, which provides a compelling soundtrack that
                      highlights the EV’s power.
                      <br />
                      <br />
                      <br />
                      The IONIQ 5 N also sets a new benchmark in terms of
                      emotional engagement for a performance EV, courtesy of N
                      e-shift, which simulates the eight-speed dual-clutch
                      transmission of internal combustion engine N cars, and N
                      Active Sound+, which provides a compelling soundtrack that
                      highlights the EV’s power.
                      <br />
                      <br /> */}
                      {/* <ul>
                        <li>
                          N Pedal – an intelligent software function designed to
                          provide instant turn-in behaviour and enhanced
                          throttle sensitivity. Drawing on similar technology
                          used on IONIQ 5’s i-Pedal’s regenerative braking
                          system, N Pedal prioritizes fast and exhilarating
                          cornering over energy efficiency. By utilizing
                          decelerative force, an aggressive weight transfer is
                          created, resulting in sharper corner entry.
                        </li>
                        <li>
                          N Drift Optimizer – this helps to maintain drift
                          angles by balancing multiple vehicle controls
                          responding to real-time inputs. An integrated Torque
                          Kick Drift function allows the driver to simulate the
                          clutch kick action of rear-wheel-driven ICE vehicles
                          for more immediate drift initiation.
                        </li>
                        <li>
                          N Torque Distribution - fully variable front and rear
                          torque distribution that can be adjusted to 11 levels.
                          The e-LSD (electronic limited slip differential) at
                          the rear axle optimizes cornering performance and
                          control. Additional wheel sensors and broader damping
                          capacity with increased damper size widens the
                          performance range of the electronically controlled
                          suspension.{' '}
                        </li>
                      </ul>
                      <br />
                      Many performance enthusiasts have been outspoken about the
                      lack of driver feedback behind the wheel of EVs and to
                      counter this, IONIQ 5 N offers two functions to engage
                      enthusiasts: N e-shift and N Active Sound+. Both modes
                      work collectively to deliver a more engaging physical and
                      aural experience behind the wheel.
                      <br />
                      <br />
                      <ul>
                        <li>
                          N e-shift simulates the eight-speed dual-clutch
                          transmission of internal combustion engine N cars,
                          simulating gearshifts by controlling motor torque
                          output and mimicking the jolt feeling between shifts,
                          providing a precise, interactive driving experience
                          that feels closer to ICEs than previous EVs. N e-shift
                          allows the driver to control the simulated gearshifts
                          with the steering column paddles usually used to
                          control regenerative braking.{' '}
                        </li>
                        <li>
                          N Active Sound+ is paired with N e-shift to enhance
                          the driving experience of EVs by creating a more
                          engaging and exciting sensory experience for the
                          driver. It incorporates a 10-speaker system (eight
                          internal, two external) that offers three distinct
                          sound themes. The ‘Ignition’ theme simulates the sound
                          of the i30 and KONA N’s 2.0T engines and ‘Evolution’
                          provides a signature high-performance sound inspired
                          by the RN22e, the E-GMP based Rolling Lab concept. The
                          ‘Supersonic’ theme is a unique concept inspired by
                          twin-engine fighter jets, featuring variable volume
                          during cornering. The objective of N Active Sound+ is
                          to provide drivers with an acoustic reference to gauge
                          the power being utilized.
                        </li>
                      </ul>
                      <br />
                      <br />
                      <img src="images/newIoniq5n8.jpg" className="img-fluid" />
                      <br />
                      <br />
                      IONIQ 5 N also features several technical enhancements
                      over the standard IONIQ 5, with a powertrain enhanced for
                      performance in both power output, battery cooling and
                      braking. IONIQ 5 N’s electric motors spin up to 21,000 RPM
                      providing up to 609PS in normal conditions, or 650 PS when
                      N Grin Boost (NGB) is engaged, maximising acceleration for
                      up to 10 seconds. Furthering its track capabilities, IONIQ
                      5 N also features N Launch Control, with three different
                      traction levels for the fastest possible start.
                      <br />
                      <br /> */}
                      {/* IONIQ 5 N carefully controls heat induced power
                      degradation with an enhanced battery thermal management
                      system comprising of an increased cooling area from
                      independent radiators for the battery and motor, revised
                      motor oil cooler and battery chiller. The driver can also
                      utilize N Battery Pre-conditioning to optimize the battery
                      cells to the most power-efficient temperature by choosing
                      between ‘Drag’ mode for a short burst of full power or
                      ‘Track’ mode that optimizes the lowest possible battery
                      temperature for more laps. Track SOC (state-of-charge)
                      automatically calculates battery consumption per lap.
                      <br />
                      <br />
                      The N Race feature of IONIQ 5 N further optimizes the EV’s
                      endurance on the circuit, giving drivers more direct
                      control over the car’s energy usage and create an ideal
                      performance window to achieve their driving performance
                      targets. With N Race, drivers are given the strategic
                      choice to prioritise ‘Endurance’ or ‘Sprint’ modes.
                      ‘Endurance’ maximizes range on the racetrack by limiting
                      the peak power, which results in a slower build-up of
                      battery temperature, whilst ‘Sprint’ prioritizes power and
                      provides shorter bursts of full energy.
                      <br />
                      <br />
                      IONIQ 5 N boasts N-tuned brakes, which represent Hyundai’s
                      most powerful braking system yet. It features 400-mm
                      diameter ventilated front discs with four-piston monobloc
                      callipers and 360-mm rear discs. These brakes are
                      constructed with new lightweight materials and optimized
                      airflow for improved cooling efficiency. In addition, the
                      regenerative braking system typically used for enhanced
                      efficiency has been reinterpreted in IONIQ 5 N to enhance
                      braking performance. It serves as the primary source of
                      brake force, while the mechanical brakes supplement
                      additional braking force when required, ensuring enhanced
                      endurance on the track.
                      <br />
                      <br />
                      Designed to deal with the harsh braking demands of track
                      driving, Hyundai N engineers designed N Brake Regen, an
                      N-specific region braking system. It offers up to a
                      maximum of 0.6G decelerative force — an industry leading
                      figure — through N Brake Regen alone and remains engaged
                      under ABS activation with a maximum of 0.2G. The risk of
                      brake fade due to the EV’s relative heaviness is minimized
                      by maximizing regen braking power, which results in lower
                      brake force applied to the mechanical brakes, resulting in
                      lower friction brake temperatures.
                      <br />
                      <br />
                      <img src="images/newIoniq5n5.jpg" className="img-fluid" />
                      <br />
                      <br />
                      As well as extensive mechanical and software developments
                      designed for performance driving, IONIQ 5 N’s exterior
                      adds performance related design elements. The N model
                      further distinguishes itself from the standard IONIQ 5
                      with a change of proportions. It is 20 mm lower overall,
                      50 mm wider at the bottom to accommodate wider tyres and
                      80 mm longer due to the more prominent diffuser.
                      <br />
                      <br />
                      <img src="images/newIoniq5n9.jpg" className="img-fluid" />
                      <br />
                      <br />
                      This impression is further reinforced by the forged
                      21-inch aluminium wheels that are wrapped in high-grip
                      275/35R21 Pirelli P-Zero tyres for improved ride and
                      handling and better grip in track performance.
                      <br />
                      <br />
                      At the front, there is a redesigned graphic fascia with
                      functional mesh along with air curtain and active air
                      flaps for extra cooling. A lip spoiler runs across the
                      lower part of the bumper to emphasize the car’s slow-slung
                      stance and convey its performance intent. The EV
                      N-exclusive ‘Luminous Orange’ at the bottom of the black
                      front bumper cover continues along the side skirts, giving
                      the IONIQ 5 N a racetrack-ready look. At the rear, the
                      prominent, N-only wing-type spoiler, along with
                      orange-accented rear diffuser and air outlet, help control
                      airflow to support optimal aerodynamic performance.
                      Integrated in the spoiler is a triangular N-only brake
                      light. Below the rear hatch is an N-exclusive black bumper
                      cover with checker flag reflector graphics.
                      <br />
                      <br />{' '}
                      <img src="images/newIoniq5n6.jpg" className="img-fluid" />
                      <br />
                      <br />
                      IONIQ 5 N’s interior is optimised for track driving, with
                      enhancements designed to provide both comfort, support and
                      ease of operation. An addition of a centre console fitted
                      with both knee pads and shin support designed to add
                      driver bracing under hard cornering.
                      <br />
                      <br />
                      <img src="images/newIoniq5n4.jpg" className="img-fluid" />
                      <br />
                      <br />
                      Aside from driver support, the console also offers a
                      sliding armrest, C-type USB and wireless charging and
                      cupholders.
                      <br />
                      <br /> */}
                      {/* <img src="images/newIoniq5n2.jpg" className="img-fluid" />
                      <br />
                      <br />
                      New N bucket seats are finished in leather and Alcantara
                      and have reinforced bolsters to provide lateral support
                      during sharp cornering, whilst the seat frames are
                      positioned 20 mm lower compared to the standard
                      specification model.
                      <br />
                      <br />N emblems are arranged front and rear of the seat
                      with welcome lighting at front, with the two-toned design
                      theme of the bucket seats carried over to the rear.
                      Additional new technology has been embedded with the
                      Digital Centre Mirror which allows the driver to switch
                      between a conventional mirror or a digital screen with a
                      feed from a dedicated camera mounted under the rear
                      spoiler for an increased horizontal rear view with a 50o
                      angle vs the 20o of an optical mirror.
                      <br />
                      <br />
                      The newly designed N steering wheel is flanked by N
                      buttons that set the desired drive mode. Driving modes can
                      be customized and matched to each button in various
                      combinations to maximize IONIQ 5 N’s fun driving spirit.
                      The NGB button is positioned at 3 o’clock to allows
                      instant and intuitive access, with steering wheel paddles
                      optimally positioned at driver’s fingertips to control N
                      e-shift and N Pedal features.
                      <br />
                      <br />
                      <img src="images/newIoniq5n3.jpg" className="img-fluid" />
                      <br />
                      <br />
                      IONIQ 5 N is also the latest model to receive the updated
                      CCnC Navigation system, featuring faster processing, new
                      graphical interfaces and an N dedicated screen for N
                      customer mode settings and data logging. IONIQ 5 N is also
                      the first IONIQ model to feature wireless Apple CarPlay™
                      and Android Auto™. Shared with IONIQ 5 Ultimate and Namsan
                      models, IONIQ 5 N is equipped as standard with a Head Up
                      Display (HUD) with Augmented Reality (AR) but with a
                      bespoke N dedicated layout. Also shared with the high end
                      IONIQ 5 models is a high level of standard comfort and
                      safety equipment such as heated and ventilated front
                      seats, heated steering wheel, Highway Driving Assist 2
                      (HDA 2) and the latest suite of ADAS features such as
                      Surround View Monitor (SVM) Blind Spot Monitor (BSM) and
                      Remote Smart Parking Assist 2 (RSPA 2).
                      <br />
                      <br />
                      Like the standard IONIQ 5, the N variant is built on the
                      800V Electric Global Modular Platform, offering ultra-fast
                      charging compatibility and Hyundai’s pioneering
                      Vehicle-to-Load technology (V2L). Similarly, sustainable
                      materials are applied throughout IONIQ 5 N, including
                      recyclable paperette door decorative garnish, BIO PET yarn
                      from sugarcane, recycled PET yarn from recycled PET
                      bottles, BIO TPO from sugarcane and eco processed leather.
                      IONIQ 5 N also adds recycled tire pigment paint and
                      recycled poly Alcantara seat cover cloth.
                      <br />
                      <br />
                      IONIQ 5 N is available in 9 exterior finishes:
                      <br />
                      <br />
                      <img
                        src="images/newIoniq5nTable.jpg"
                        className="img-fluid"
                      />
                      <br />
                      <br />
                      All IONIQ 5 N models come with Hyundai’s industry leading
                      5 Year Unlimited Mileage Warranty, 8 Year/100,000 Mile
                      High Voltage Battery Warranty, 5 Year Annual Health Check,
                      3 year MapCare navigation update program, Roadside
                      Assistance package, and 12 Year Anti Corrosion Warranty.
                      <br />
                      <br />
                      <strong>
                        "IONIQ 5 N is truly the first EV for the driving
                        enthusiast, combining not just high performance and
                        precision handling but also using state of the art
                        technology to provide an emotional connection so far
                        missing in performance EV’s. IONIQ 5 N uses an array of
                        tools to provide a driver centric driving experience
                        whenever desired, as well as the ability of becoming a
                        quiet, comfortable and efficient zero emission family
                        SUV at the flick of a mode button."
                      </strong>
                      <br />
                      <br />
                      Ashley Andrew President, Hyundai Motor UK
                      <br />
                      <br />
                      <br />
                      <a
                        target="_blank"
                        href=" https://www.hyundai.news/newsroom/dam/uk/models/20231026_ioniq_5_n_range_pricing_specs_technical/hyundai-ioniq-5-n-uk-pricing-key-specs-1023.pdf"
                      >
                        <strong>
                          Hyundai IONIQ 5 N | Pricing & key specifications
                        </strong>
                      </a>
                      <br />
                      <br />
                      <a
                        target="_blank"
                        href=" https://www.hyundai.news/newsroom/dam/uk/models/20231026_ioniq_5_n_range_pricing_specs_technical/hyundai-ioniq-5-n-uk-technical-data-1023.pdf"
                      >
                        <strong>Hyundai IONIQ 5 N | Technical data</strong>
                      </a>
                      <br />
                      <br />
                      <br />
                      <br /> */}
                      {/* <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      New i20 also offers a more simplified powertrain line up,
                      with all models available with the turbocharged, 3
                      cylinder, 100PS engine and a choice of 6 speed manual or 7
                      speed dual clutch transmission. <br />
                      <br /> In a range realignment the new i20’s range now
                      starts with the Advance at £20,770 for the Advance 1.0
                      T-GDi 100PS 6MT. The Advance exterior features include
                      redesigned 16” alloy wheels, LED head and tail lamps,
                      whilst interior equipment includes an 8” touchscreen
                      display audio with subscription free Apple CarPlay™ and
                      Android Auto™, 10.25” supervision instrument cluster with
                      customisable functions, front USB-C port for charging
                      compatible devices, manual air conditioning, cruise
                      control with speed limiter function, leather wrapped
                      steering wheel and gear lever, front and rear parking
                      sensors with rear view camera and electrically adjustable
                      and heated wing mirrors. Hyundai’s SmartSense safety
                      package is also standard, offering E-Call, Forward
                      Collision Alert (FCA), Driver Attention Alert (DAA),
                      Intelligent Speed Limit Warning (ISLW), High Beam Assist
                      (HBA), Lane Keep Assist (LKA), Lane Follow Assist (LFA)
                      and Tyre Pressure Monitoring System (TPMS). The Advance
                      1.0 T-GDi with 7 speed DCT powertrain is available from
                      £22,020.
                      <br />
                      <br />
                      <img src="images/newi20234.jpg" className="img-fluid" />
                      <br />
                      <br />
                      <img src="images/newi20235.jpg" className="img-fluid" />
                      <br />
                      <br />
                      Next in new i20’s range is the Premium from £22,570, that
                      builds on the Advance trim, with enhanced exterior trim
                      including 17” alloy wheels, front fog lamps, power folding
                      door mirrors and privacy glass. Additional specification
                      includes automatic wipers, automatically dimming rear view
                      mirror, automatic air conditioning with climate control,
                      multi colour ambient lighting, heated front seats, heated
                      steering wheel and front arm rest. The Premium trim level
                      also includes a 10.25” touchscreen navigation with
                      subscription free Apple CarPlay™ and Android Auto™, as
                      well as Hyundai’s Bluelink® telematics system which
                      carries a free of charge 3-year subscription. Bluelink® is
                      a smartphone app based connected car service that enables
                      drivers to carry out a number of different functions
                      whilst away from their vehicle, including remote central
                      locking activation and vehicle parked location
                      information. In addition to the driver convenience
                      features, Bluelink® serves as a convenient diagnostic tool
                      which will notify the driver of any vehicle maintenance
                      issues that require attention. The Premium 1.0 T-GDi with
                      7 speed DCT powertrain is available from £23,820.
                      <br />
                      <br />
                      <img src="images/newi20236.jpg" className="img-fluid" />
                      <br />
                      <br />
                      The i20 Ultimate 1.0 T-GDi 100PS 6MT is available from
                      £24,070 and adds to the Premium trim level with additional
                      equipment including Smart Key with keyless entry and
                      engine start/stop button, a wireless charging pad for
                      compatible devices, Blind-spot Collision Warning (BCW) and
                      Forward Collision Assist (FCA) with cyclist detection. The
                      new i20 Ultimate also continues with the Bose Premium
                      Sound System seen in the previous model. The Bose Premium
                      Sound System is collaboration between Hyundai and Bose
                      engineers and features eight high performance speakers
                      including a subwoofer. The system is equipped with dynamic
                      speed compensation technology, which monitors vehicle
                      speed and automatically adjusts music levels, ensuring a
                      more consistent listening experience and reducing the need
                      for drivers to make manual adjustments while on the road.
                      The i20 Ultimate 1.0 T-GDi 7 speed DCT powertrain is
                      available from £25,320.
                      <br />
                      <br />
                      <strong>
                        "Since its introduction in 2009, the i20 has
                        consistently delivered for consumers looking for a
                        supermini that combines style, value and specification.
                        The enhancements for new i20 strengthens our proposition
                        even further in this segment as we look to attract more
                        people to our exciting brand."
                      </strong>
                      <br />
                      shley Andrew President,
                      <br />A Hyundai Motor UK
                      <br />
                      <br />
                      All new i20 models come with Hyundai’s industry leading 5
                      Year Unlimited Mileage Warranty, Roadside Assistance
                      package, 5 Year Annual Health Check, and 12 Year Anti
                      Corrosion Warranty.
                      <br />
                      <br />
                      <a
                        target="_blank"
                        href=" https://www.hyundai.news/newsroom/dam/uk/models/20230815_hyundai_announces_new_i20_prices_and_specifications/new-i20-pricing-spec-and-tech.pdf"
                      >
                        <strong>
                          Download new i20 pricing and specifications
                        </strong>
                      </a>{' '}
                      <br />
                      <br />
                      <img src="images/newi20237.jpg" className="img-fluid" />
                      <br />
                      <br />
                      <img src="images/newi20238.jpg" className="img-fluid" />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br /> */}
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
