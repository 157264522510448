export default {
  GetHandbooks(vehicles) {
    let handbooks = [];
    for (let i = 0; i < vehicles.length; i++) {
      const vehicle = vehicles[i];
      if (vehicle.handbook != null) handbooks.push(vehicle.handbook);
    }
    return handbooks;
  },
  GetClassStructure(handbooks) {
    if (handbooks.length > 1) return 'handbook col-lg-6 col-md-6 col-sm-12';
    else return 'col-lg-12';
  },
  GetNameStructure(handbooks) {
    if (handbooks.length > 1) return 'handbook-name-container handbook-multi';
    else return 'handbook-name-container';
  },
  GetFontSize(handbooks) {
    if (handbooks.length > 1) return 'font-medium handbook-font';
    else return 'font-large handbook-font';
  },
};
