import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import './contactPref.css';
import FooterNavbar from '../components/footerNavbar';
import Store from 'store';
import TopNavbar from '../components/topnavbar';
import BottomNavbar from '../components/bottomnavbar';
import Footer from '../components/footer';
import OffersSideNav from '../components/offersSideNav';
import Auth from '../utils/Auth';
import copy from 'copy-to-clipboard';

export default class OffersChelsea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entered: false,
      code: '',
      isEmailSent: '',
      isLoading: false,
      loggedOut: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  handleClick = () => {
    this.setState({ isLoading: true });
    fetch(
      process.env.REACT_APP_API_URL +
        '/HyundaiUk/ChelseaOffer/GetCode?key=' +
        Store.get('loginToken').replace(/['"']+/g, ''),
      {
        method: 'get',
      },
    )
      .then((response) => {
        if (response.status === 200) {
          return response.text();
        } else if (response.status === 401) {
          this.setState({ isLoading: false });
          Auth.signout();
          this.setState({ loggedOut: true });
        } else {
          this.setState({ isEmailSent: 'Try Again' });
          this.setState({ isLoading: false });
        }
      })
      .then((text) => {
        if (text) {
          this.setState({ entered: true, code: text });

          return fetch(
            process.env.REACT_APP_API_URL +
              '/HyundaiUk/ChelseaOffer/SendConfirmationEmail?key=' +
              Store.get('loginToken').replace(/['"']+/g, ''),
            {
              method: 'post',
            },
          ).then((response) => {
            if (response.ok) {
              this.setState({ isEmailSent: 'Email Sent' });
            } else {
              this.setState({ isEmailSent: 'Try Again' });
            }
            this.setState({ isLoading: false });
          });
        }
      })
      .catch((response) => {
        this.setState({ isEmailSent: 'No Internet', isLoading: false });
      });
  };

  copyClick = () => {
    copy(this.state.code);
  };

  render() {
    if (this.state.loggedOut) {
      return (
        <Redirect
          push
          to={{
            pathname: '/ ',
          }}
        />
      );
    }

    return (
      <div>
        <TopNavbar />
        <BottomNavbar />

        <div className="container">
          <section
            className="section1 wow fadeIn"
            data-wow-duration="2s"
            data-wow-delay="0.2s"
          >
            <div className="content pb-5  p-md-5 col-md-10 offset-md-1 border-right border-bottom">
              <div className="row">
                <div className="col-md-3 mt-4 border-md-right">
                  <OffersSideNav />
                </div>

                <div className="col-md-9  p-md-4 contact-perf-content">
                  <h4 className="h4ServHistory" data-search-tag="title">
                    Hyundai Chelsea Offer
                  </h4>
                  <hr />
                  <div>
                    <img
                      className="card-img-top img-fluid"
                      src="images/chelsea_offer_banner.jpg"
                    />
                  </div>
                  <br />
                  <p className="remindersBlue" data-search-tag="summary" />

                  <p>
                    <strong>
                      Missing your Hyundai logo? Get your Chelsea shirt updated
                    </strong>
                  </p>

                  <p>
                    Thanks to our new partnership with Chelsea Football Club,
                    MyHyundai customers can have the Hyundai logo printed on the
                    sleeve of this season's Chelsea football shirt – for free!
                  </p>

                  <p>
                    Click ‘Redeem now’ and we'll email you a personalised
                    e-voucher, then simply take your voucher and 2018/19 shirt
                    along to the Stadium Megastore at Stamford Bridge to make
                    your shirt official.
                  </p>

                  <button
                    className="btn btn-sm btn-block removeRadius btn-primary"
                    type="button"
                    onClick={this.handleClick}
                  >
                    {this.state.isLoading && (
                      <div>
                        <i className="fas fa-spinner fa-spin fa-lg text-white" />
                      </div>
                    )}
                    {!this.state.isLoading && (
                      <span>
                        {!this.state.isLoading && !this.state.isEmailSent
                          ? 'Redeem Now'
                          : this.state.isEmailSent}
                      </span>
                    )}
                  </button>
                  <small>
                    <br />
                    <br />*
                    <strong>
                      Terms and Conditions - Chelsea Football Club FOC Hyundai
                      logo transfer
                    </strong>
                    <br />
                    There can be only one <strong>voucher</strong> used per
                    order. <strong>Vouchers</strong> are non-refundable and
                    cannot be exchanged for cash. The voucher, to have a Hyundai
                    logo printed on the sleeve of your football shirt, can only
                    be used at the ‘Megastore’ located at Stamford Bridge,
                    Chelsea Football Club. Free printing is only available for
                    2018/19 Chelsea football shirts. Person redeeming voucher
                    has to be a Hyundai vehicle owner. ID maybe required. Offer
                    ends 31st May 2019.
                  </small>
                </div>
              </div>
            </div>
          </section>

          <FooterNavbar />
        </div>

        <Footer />
      </div>
    );
  }
}
