import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';
import Store from 'store';
import ReactGA from 'react-ga';
export default class NewSantaFe23 extends Component {
  componentDidMount = () => {};

  clickHandler = () => {};

  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="NewSantaFe" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="oneMillion-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/santafe23Tile.jpg") ',
              }}
            />
            {/* <div
              id="Bayon-gallery-2"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/hyundaielectric2.jpg")',
              }}
            /> */}
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                {/* <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#Bayon-gallery-2"
                    style={{
                      backgroundImage: 'url("images/hyundaielectric2.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#Bayon-gallery-1"
                    style={{
                      backgroundImage: 'url("images/hyundaielectric1.jpg")',
                    }}
                  />
                </div> */}
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “Hyundai’s All-New Santa Fe Boasts Bold New Design Optimized
                  for Effortless Outdoor Lifestyles”
                </p>
              </div>
              <hr />

              <NewsMedia articleId="awards" articleText="awards" />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    HYUNDAI'S ALL-NEW SANTA FE BOASTS BOLD NEW DESIGN OPTIMZED
                    FOR EFFORTLESS OUTDOOR LIFESTYLES
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    {' '}
                    <ul>
                      <li>
                        The all-new midsize SUV’s exterior and interior display
                        a radical transformation made possible by a
                        lifestyle-based design typology that maximizes rear
                        cargo capacity
                      </li>
                      <li>
                        The new concept connects city and nature in a seamless
                        way offering ‘expansion of experiences’ in the everyday
                        life
                      </li>
                      <li>
                        Robust exterior design with fine details makes a
                        powerful statement; H-lights harmonize Santa Fe’s front
                        and rear, creating a differentiated presence on the road
                      </li>
                      <li>
                        Large tailgate and spacious interior offer best-in-class
                        terrace-like space that’s great for urban life and even
                        better for the outdoors
                      </li>
                      <li>
                        Interior and exterior feature unique H-shaped design
                        cues and eco-friendly materials
                      </li>
                      <li>
                        All-new Santa Fe to make world premiere in August via
                        Hyundai’s YouTube channel
                      </li>
                      {/* <li>
                        Among IONIQ 5 N’s many exclusive performance features
                        are N Battery Preconditioning, N Race, N Pedal, N Brake
                        Regen, N Drift Optimizer, N Torque Distribution, N
                        Launch Control, N Grin Boost, N Active Sound +, N
                        e-shift, N Road Sense and Track SOC
                      </li> */}
                      {/* <li>
                        Hyundai is investing £1 million in a programme of school
                        trips in order to prevent children missing out on such
                        experiences
                      </li>
                      <li>
                        Bookings are now open for the initiative, which has
                        launched with Hyundai’s Educator in Residence, Tim
                        Campbell MBE
                      </li> */}
                    </ul>
                  </p>
                  {displayShowMore && (
                    <p>
                      <a
                        data-toggle="collapse"
                        href="#NewSantaFe23"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </div>

              <div className={moreSectionClassName} id="NewSantaFe23">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      <strong>
                        Hyundai Motor Company today revealed its radically
                        transformed all-new Santa Fe. The fifth-generation
                        midsize SUV carries forward its signature
                        nature-meets-city appeal while implementing major
                        updates shaped by big data around the latest outdoor
                        lifestyle trends.
                      </strong>
                      <br />
                      <br />
                      For Santa Fe’s first full model change since 2018, Hyundai
                      Motor took an unconventional approach with a
                      lifestyle-based design typology to maximize rear cargo
                      capacity and better accommodate outdoor and urban
                      activities. As a result, the powerful new model boasts a
                      robust yet delicately detailed exterior design and
                      spacious, versatile interior that flows from the openness
                      of its enlarged tailgate, providing users with a
                      terrace-like space whenever and wherever they need it.
                      <br />
                      <br />
                      <strong>
                        "The all-new Santa Fe is an SUV that finds a perfect
                        balance between city life and the great outdoors,
                        handling everything from busy family itineraries to car
                        camping adventures. With its longer wheelbase, roomy
                        interior and terrace-like tailgate space, the new Santa
                        Fe leans into its SUV strengths to offer more
                        versatility than ever before as well as a premium
                        customer experience.""
                      </strong>
                      <br />
                      SangYup Lee
                      <br />
                      Executive Vice President and Head of Hyundai Global Design
                      Center
                      <br />
                      <br />
                      <img src="images/santafe231.jpg" className="img-fluid" />
                      <br />
                      <br />
                      <h2>
                        Maximizing presence with a strong, unique exterior
                        design
                      </h2>
                      <br />
                      The all-new Santa Fe’s boxy shape and distinctive
                      silhouette are derived from its long wheelbase and wide
                      tailgate area.
                      <br />
                      <br /> The front of the vehicle creates a sense of
                      grandeur with its high hood, H-shaped headlamps and bold,
                      sharp fenders. The H-shaped headlamps harmonize with the
                      H-motif lower front design to enhance visual completeness.
                      The H-shaped design elements reinterpret Hyundai’s ‘H’
                      emblem.
                      <br />
                      <br />
                      Seen from the side, the lengthened wheelbase accommodates
                      a bold roofline, powerful volume around the fenders,
                      sharply defined wheel arches, shortened front overhang and
                      21-inch wheels, all of which gives this SUV a robust,
                      adventurous look.
                      <br />
                      <br />
                      <img src="images/santafe232.jpg" className="img-fluid" />
                      <br />
                      <br />
                      Defined by its larger tailgate, the rear end is simpler,
                      balancing the overall exterior design. The H-shaped
                      taillights are unified with the front H lights to create
                      harmony and provide a distinctive Santa Fe presence on the
                      road, day or night.
                      <br />
                      <br />
                      <img src="images/santafe233.jpg" className="img-fluid" />
                      <br />
                      <br />
                      <h2>
                        The extended tailgate and accommodating cabin provide
                        best-in-class space
                      </h2>
                      <br /> With ample room for adventurers and their gear, the
                      all-new Santa Fe’s interior offers generous living space
                      for an effortless outdoor lifestyle.
                      <br />
                      <br />
                      <img src="images/santafe234.jpg" className="img-fluid" />
                      <br />
                      <br />
                      The more accommodating tailgate creates a spacious
                      interior with a terrace-like feel at the rear when open.
                      The fully foldable second- and third-row seats provide
                      class-leading interior space. All of these attributes make
                      it possible for users to enjoy the outdoors in the easiest
                      way possible.
                      <br />
                      <br />
                      This feature also makes the all-new Santa Fe a scene
                      stealer in urban settings, allowing young families to use
                      the rear area for a wide variety of purposes, such as
                      grocery shopping, home improvement projects, sports and
                      recreation, gardening, family outings and pet
                      transportation.
                      <br />
                      <br />
                      <img src="images/santafe235.jpg" className="img-fluid" />
                      <br />
                      <br />
                      The refined interior offers a pleasant surprise by
                      contrasting with the strong exterior. The interior design
                      emphasizes horizontal and vertical lines, with an H-motif
                      design applied to the dashboard and air vents to enhance
                      the sense of openness and create a unique sensibility.
                      <br />
                      <br />
                      <img src="images/santafe236.jpg" className="img-fluid" />
                      <br />
                      <br />
                      The all-new Santa Fe’s interior refinement is exemplified
                      by a variety of high-tech features, including a Panoramic
                      Curved Display and dual wireless charging. The curved
                      display connects the 12.3-inch digital cluster and
                      infotainment system and enhances driver visibility while
                      delivering a sense of luxury.
                      <br />
                      <br />
                      <img src="images/santafe237.jpg" className="img-fluid" />
                      <br />
                      <br />
                      In addition, the brightly colored seats and headliner
                      create a sense of spaciousness, while the soft-touch
                      wood-patterned garnish and Nappa leather seats with
                      delicate embroidery add sophisticated style.
                      <br />
                      <br /> The new SUV also incorporates a number of
                      sustainable materials. The suede headliner, car mats, and
                      second- and third-row seatbacks are made from recycled
                      plastic, while the crash pad and door trim covers are made
                      from eco-friendly leatherette.
                      <br />
                      <br /> The interior and exterior colors, inspired by
                      natural light, highlight the unique style of the all-new
                      Santa Fe, bringing inspiration and vitality to everyday
                      life and adventure in the city and great outdoors.
                      <br />
                      <br /> The all-new Santa Fe will make its world premiere
                      in August on Hyundai’s YouTube channel.
                      <br />
                      <br /> * Note: Availability of specifications described in
                      the release and images may vary by trim, variant and
                      market.
                      <br />
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
