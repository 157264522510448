import React, { Component } from "react";

export default class SouthPole extends Component {
  render() {
    return (
      <div className="container">
        <div className="fadeInDownBigFull">
          <div className="card-body newsPadding">
            <div className="card-deck " id="SouthPole">
              <div className="card">
                <div className="row">
                  <div className="col-md">
                    <img
                      id="SouthPole-gallery-1"
                      className="card-img-top img-fluid news-gallary-image active"
                      src="images/southPole.jpg"
                      alt="Card image cap"
                    />
                  </div>
                </div>

                <div className="card-block">
                  <div className="card-content">
                    <div className="col">
                      <div className="row whiteBG">
                        <div className="col-lg-4">
                          <div className="salesText mt-3">
                            <p className="theme-news-snippet">
                              “Over 30 days and a remarkable 3,500 miles of
                              frozen terrain and bitter elements”
                            </p>
                          </div>
                          <hr />
                        </div>

                        <div className="col-lg-8 border-lg-left">
                          <div className="row pt-3">
                            <div className="col-md-10 offset-md-1">
                              <p className="salesText" data-search-tag="title">
                                UNLEASH YOUR INNER EXPLORER: OWN THE FAMILY CAR
                                THAT DROVE TO THE SOUTH POLE AND BACK
                              </p>
                            </div>
                          </div>

                          <div className="row pt-3">
                            <div className="col-md-10 offset-md-1">
                              <p
                                className="theme-news-body-text"
                                data-search-tag="summary"
                              >
                                <strong>April 2017 –</strong> Just a few months
                                ago, in December 2016, we challenged our hugely
                                popular Santa Fe with an incredible mission. To
                                celebrate the centenary of legendary explorer
                                Sir Ernest Shackleton’s attempt to traverse
                                Antarctica, we invited his great-grandson,
                                Patrick Bergel, to complete the ambitious
                                journey in our family SUV.
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <div className="theme-news-body-text">
                                <p>
                                  Over 30 days and a remarkable 3,500 miles of
                                  frozen terrain and bitter elements, Bergel
                                  drove a near-standard 2.2-litre Santa Fe right
                                  across Antarctica from Union Glacier to
                                  McMurdo and made it all the way back again.
                                </p>
                                <p>
                                  The climate in Antarctica is notorious for
                                  breaking even tough machinery – so to complete
                                  the expedition in a family car, with just a
                                  few essential modifications, is remarkable.
                                  Big, soft tyres were fitted to handle the snow
                                  and ice, a pre-heater helped against -30 C
                                  temperatures and a special fuel tank held 250
                                  litres of the Jet A-1 fuel required for such
                                  cold conditions; but at its heart, this SUV
                                  was still essentially the same Santa Fe you
                                  can drive yourself.
                                </p>
                                <p>
                                  Now, to celebrate this historic achievement,
                                  Hyundai is releasing just 500 Santa Fe
                                  Endurance Edition models – giving you the
                                  chance to own a vehicle that has been to
                                  places no other wheeled machine has before.
                                </p>
                                <p>
                                  Priced at just £38,995, which represents a
                                  £2,400 saving over the comparable Premium SE
                                  model, the confident Endurance Edition packs
                                  an impressive range of design and technology
                                  features to back up its pioneering pedigree.
                                  Boasting the Premium SE model trim, 19-inch
                                  alloys, Side Bar Sport running steps and the
                                  special Endurance logo, this exclusive
                                  seven-seat model also gives you the best in
                                  intelligent comfort, convenience and safety
                                  features: Autonomous Emergency Braking, Smart
                                  Cruise Control, Around View Monitor, a
                                  panoramic sunroof and a Smart Electric
                                  Tailgate.
                                </p>
                                <p>
                                  The Santa Fe Endurance Edition equally looks
                                  the part at the top of the Hyundai range, with
                                  a choice of exclusive colours: solid creamy
                                  white, pearl white crystal, metallic titanium
                                  silver, pearl phantom black and the same
                                  metallic mineral blue of the car Bergel drove
                                  into the history books.
                                </p>
                                <p>
                                  To discover more about this epic adventure, or
                                  to learn more about the Santa Fe that
                                  completed it, watch our film today at{" "}
                                  <a
                                    href="http://shackletonsreturn.hyundai.com/en/"
                                    target="_blank"
                                  >
                                    www.shackletonsreturn.hyundai.com
                                  </a>{" "}
                                  or{" "}
                                  <a
                                    href="https://youtu.be/J01mqggN0h8"
                                    target="_blank"
                                  >
                                    https://youtu.be/J01mqggN0h8
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
