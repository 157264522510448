import React, { Component } from 'react';
import './competitionForm.css';
import Validation from '../utils/Validation';
import InputField from '../components/inputField';
import ValidationMessage from '../components/validationMessage';
import $ from 'jquery';

export default class Form extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rewardID: '',
      title: 'Title',
      firstName: '',
      surname: '',
      email: '',
      phone: '',
      answer: '',
      emailPreference: false,
      phonePreference: false,
      form: {
        answer: false,
        emailPreference: false,
        phonePreference: false,
      },
      formValues: {
        headerText: '',
        privacyPolicyHtml: '',
        termsAndConditionsHtml: '',
      },
      validation: this.buildValidationObject(),
      validationMessage: '',
      isLoading: false,
      isSentSuccessfully: false,
    };

    this.Submit = this.Submit.bind(this);
    this.HandleEmailInputChange = this.HandleEmailInputChange.bind(this);
    this.HandlePhoneInputChange = this.HandlePhoneInputChange.bind(this);
    this.HandleFirstInputChange = this.HandleFirstInputChange.bind(this);
    this.HandleSurnameInputChange = this.HandleSurnameInputChange.bind(this);
    this.HandleAnswerInputChange = this.HandleAnswerInputChange.bind(this);
    this.HandleTitleInputChange = this.HandleTitleInputChange.bind(this);
  }

  buildValidationObject = () => {
    let validation = {
      phone: Validation.initValidationState(['required', 'phone', 'length100']),
      email: Validation.initValidationState(['required', 'email', 'length100']),
      firstName: Validation.initValidationState(['required', 'length100']),
      surname: Validation.initValidationState(['required', 'length100']),
      title: Validation.initValidationState(['required', 'length100']),
    };
    return validation;
  };

  componentDidMount() {
    const form = this.props.form;
    const formValues = {
      headerText: form.headerText,
      privacyPolicyHtml: form.privacyPolicy,
      termsAndConditionsHtml: form.termsAndConditions,
    };
    const optionalItems = {
      answer: form.includeAnswer,
      emailPreference: form.includeEmailPreference,
      phonePreference: form.includePhonePreference,
    };
    const id = this.props.id;
    this.setState({
      form: optionalItems,
      formValues: formValues,
      rewardID: id,
    });
  }

  HandleFirstInputChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    this.setState(
      {
        firstName: value,
      },
      () => this.validateField(name),
    );
  }

  HandleSurnameInputChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    this.setState(
      {
        surname: value,
      },
      () => this.validateField(name),
    );
  }

  HandleEmailInputChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    this.setState(
      {
        email: value,
      },
      () => this.validateField(name),
    );
  }

  HandlePhoneInputChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    this.setState(
      {
        phone: value,
      },
      () => this.validateField(name),
    );
  }

  HandleAnswerInputChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    this.setState(
      {
        answer: value,
      },
      () => this.validateField(name),
    );
  }

  HandleTitleInputChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    this.setState(
      {
        title: value,
      },
      () => this.validateField(name),
    );
  }

  UpdatePreference(type) {
    if (type == 'phone') {
      this.setState((prevState) => ({
        phonePreference: !prevState.phonePreference,
      }));
    } else if (type == 'email') {
      this.setState((prevState) => ({
        emailPreference: !prevState.emailPreference,
      }));
    }
  }

  ResetState() {
    this.setState({
      title: '',
      firstName: '',
      surname: '',
      email: '',
      phone: '',
      answer: '',
      emailPreference: false,
      phonePreference: false,
    });
  }

  validateField(name) {
    this.setState((prevState) => {
      return Validation.validate(prevState, name);
    });
  }

  ValidationToSelect() {
    const select = document.getElementById('whn-select');
    if (this.state.title == '' || this.state.title == 'Title') {
      select.classList.add('is-invalid');
      return false;
    } else {
      select.classList.remove('is-invalid');
      return true;
    }
  }

  Submit(event) {
    event.preventDefault();

    if (this.state.form.answer) {
      const validation = this.state.validation;
      (validation.answer = Validation.initValidationState(['required'])),
        this.setState({
          validation: validation,
        });
    }

    var newState = Validation.validateAll(this.state);
    if (Validation.anyInvalid(newState)) {
      this.setState(newState);
    } else if (this.ValidationToSelect()) {
      //reset the UI elements
      const inputs = document.forms['whnForm'].getElementsByTagName('input');
      for (let i = 0; i < inputs.length; i++) {
        const element = inputs[i];
        if (
          element.type == 'text' ||
          element.type == 'email' ||
          element.type == 'tel'
        )
          element.value = '';
        else if (element.type == 'checkbox') element.checked = false;
      }

      const select = document.getElementById('whn-select');
      select.value = 'Title';

      //send information off
      const state = this.state;
      let customer;

      if (state.form.emailPreference && state.form.phonePreference) {
        customer = {
          customerReference: state.email,
          title: state.title,
          firstName: state.firstName,
          surname: state.surname,
          email: state.email,
          mobilePhone: state.phone,
          answer: state.answer,
        };
      } else {
        customer = {
          customerReference: state.email,
          title: state.title,
          firstName: state.firstName,
          surname: state.surname,
          email: state.email,
          mobilePhone: state.phone,
          emailPreference: state.emailPreference,
          phonePreference: state.phonePreference,
          answer: state.answer,
        };
      }

      const timestamp = new Date();
      const date =
        timestamp.getFullYear() +
        '-' +
        (timestamp.getMonth() + 1) +
        '-' +
        timestamp.getDate();
      let objectToSend = {};
      objectToSend['customer'] = customer;
      objectToSend['timestamp'] = date;

      this.setState({ validationMessage: '', isLoading: true });

      fetch(
        `${process.env.REACT_APP_REWARDS_API_URL}/Redemption/${this.state.rewardID}/External`,
        {
          method: 'post',
          headers: {
            authKey: '8RFGHJI87654RFGHJI89',
            clientReference: 'HUK',
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(objectToSend),
        },
      )
        .then((response) => {
          if (response.ok) {
            this.ResetState();
            this.setState({
              isSentSuccessfully: true,
            });
            $('#submitModal').modal('show');

            $('#submitModal').on('hidden.bs.modal', function (e) {
              //window.location.reload(false);
              window.location.href = `${process.env.REACT_APP_NEWSLETTER_URL}`;
            });
          } else if (response.status == 400) {
            this.setState({
              isSentSuccessfully: false,
            });
            return response.text();
          } else {
            this.setState({
              validationMessage: 'There was a problem submitting the form',
              isSentSuccessfully: true,
            });
          }
          this.setState({ isLoading: false });
        })
        .then((text) => {
          if (this.state.isSentSuccessfully) {
          } else {
            if (text != undefined) {
              const parsedText = JSON.parse(text);

              if (parsedText == 'AlreadyRedeemed') {
                this.setState({
                  validationMessage:
                    'You have already been entered into the competition',
                });
              }
            } else {
              this.setState({
                validationMessage: 'There was a problem submitting the form',
              });
            }
          }

          this.setState({ isLoading: false });
        });
    }
  }

  render() {
    return (
      <div className="col-lg-8 col-sm-12">
        <form className="pt-3 pb-3" onSubmit={this.Submit} name="whnForm">
          <h3 style={{ marginBottom: '15px' }}>
            <b>{this.state.formValues.headerText}</b>
          </h3>
          <div className="col-lg-2 col-sm-3 competition-select-container">
            <select
              id="whn-select"
              className="form-control competition-form-element competition-select"
              type="text"
              name="title"
              placeholder="Title"
              value={this.state.title}
              onChange={(text) => this.HandleTitleInputChange(text)}
              validation={this.state.validation.title}
            >
              <option disabled selected>
                Title
              </option>
              <option value="Mr.">Mr.</option>
              <option value="Mrs.">Mrs</option>
              <option value="Miss">Miss</option>
            </select>
            <ValidationMessage validation={this.state.validation.title} />
          </div>
          <div className="row">
            <div className="col-lg-6 col-sm-12 left-input">
              <InputField
                id="whn-first-name"
                className="form-control competition-input competition-form-element"
                placeholder="First Name"
                type="text"
                name="firstName"
                value={this.state.firstName}
                onChange={(text) => this.HandleFirstInputChange(text)}
                validation={this.state.validation.firstName}
              />
              <ValidationMessage validation={this.state.validation.firstName} />
            </div>
            <div className="col-lg-6 col-sm-12 right-input">
              <InputField
                id="whn-surname"
                className="form-control competition-input competition-form-element"
                placeholder="Surname"
                type="text"
                name="surname"
                value={this.state.surname}
                onChange={(text) => this.HandleSurnameInputChange(text)}
                validation={this.state.validation.surname}
              />
              <ValidationMessage validation={this.state.validation.surname} />
            </div>
            <div className="col-12 middle-input">
              <InputField
                id="whn-email-address"
                className="form-control competition-input competition-form-element"
                placeholder="Email Address"
                type="email"
                name="email"
                value={this.state.email}
                onChange={(text) => this.HandleEmailInputChange(text)}
                validation={this.state.validation.email}
              />
              <ValidationMessage validation={this.state.validation.email} />
            </div>
            <div className="col-lg-6 col-sm-12 left-input">
              <InputField
                id="whn-phone-number"
                aria-describedby="emailHelp"
                className="form-control competition-input competition-form-element"
                placeholder="Phone Number"
                value={this.state.phone}
                type="tel"
                name="phone"
                onChange={(text) => this.HandlePhoneInputChange(text)}
                validation={this.state.validation.phone}
              />
              <ValidationMessage validation={this.state.validation.phone} />
            </div>
            <div className="col-12">
              {this.state.form.answer && (
                <InputField
                  id="whn-answer"
                  className="form-control competition-input competition-form-element"
                  type="text"
                  placeholder="Answer"
                  name="answer"
                  value={this.state.answer}
                  onChange={(text) => this.HandleAnswerInputChange(text)}
                  validation={this.state.validation.answer}
                />
              )}
              {this.state.form.answer && (
                <ValidationMessage validation={this.state.validation.answer} />
              )}
            </div>
          </div>
          <div
            className="col-12 pt-2 pb-2 pl-1 pr-1"
            dangerouslySetInnerHTML={{
              __html: this.state.formValues.privacyPolicyHtml,
            }}
          ></div>
          <div className="row pl-3 pr-3 pb-1 pt-1">
            <div className="col-lg-9 col-sm-12 pl-3 pr-3 pb-1 pt-1">
              {this.state.form.emailPreference && (
                <InputField
                  type="checkbox"
                  id="email-checkbox"
                  name="email-checkbox"
                  className="m-1"
                  onClick={() => this.UpdatePreference('email')}
                />
              )}

              {this.state.form.emailPreference && (
                <label for="email-checkbox" className="p-1 pb-2 pl-2">
                  Please tick if you'd like us to contact you by Email
                </label>
              )}

              {this.state.form.emailPreference && <br />}
              {this.state.form.phonePreference && (
                <InputField
                  type="checkbox"
                  id="phone-checkbox"
                  name="phone-checkbox"
                  className="m-1"
                  onClick={() => this.UpdatePreference('phone')}
                />
              )}

              {this.state.form.phonePreference && (
                <label for="phone-checkbox" className="p-1 pb-2 pl-2">
                  Please tick if you'd like us to contact you by Phone
                </label>
              )}
              {this.state.form.phonePreference && <br />}
            </div>
          </div>
          <div id="form-button" className="col-lg-3 col-sm-6 pr-3 pl-3 pb-3">
            <button
              className="btn btn-primary removeRadius position-relative"
              type="submit"
              onClick={this.Submit}
            >
              <div
                className={'theme-button-spinner'}
                style={{
                  display: this.state.isLoading ? 'block' : 'none',
                }}
              >
                <i className="fas fa-spinner fa-spin fa-lg text-white" />
              </div>
              <span
                style={{
                  visibility: this.state.isLoading ? 'hidden' : 'visible',
                }}
              >
                Submit
              </span>
            </button>
          </div>
          <div className="row p-3" style={{ width: '100%' }}>
            {this.state.validationMessage && (
              <p className="text-danger py-2">{this.state.validationMessage}</p>
            )}
          </div>
          <div
            id="terms-span"
            className="col-12 pt-2"
            dangerouslySetInnerHTML={{
              __html: this.state.formValues.termsAndConditionsHtml,
            }}
          ></div>
        </form>

        <div
          className="modal fade"
          id="submitModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="submitModal"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Thank you</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body text-center ">
                <p className="small">
                  Thank you, your entry to this competition has been recorded.
                </p>
                <button
                  type="button"
                  className="btn btn-sm btn-outline-primary removeRadius"
                  data-dismiss="modal"
                >
                  Close window
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
