import React, { Component } from "react";

export default class IoniqBestSmallHatch extends Component {
  render() {
    return (
      <div className="container">
        <div className="fadeInDownBigFull">
          <div className="card-body newsPadding">
            <div className="card-deck " id="IoniqBestSmallHatch">
              <div className="card">
                <div className="row">
                  <div className="col-md">
                    <img
                      id="gallery-1"
                      className="card-img-top img-fluid news-gallary-image active"
                      src="images/ioniqSmallHatchAward.jpg"
                      alt="Card image cap"
                    />
                  </div>
                </div>

                <div className="card-block">
                  <div className="card-content">
                    <div className="col">
                      <div className="row whiteBG">
                        <div className="col-lg-4">
                          <div className="salesText mt-3">
                            <p className="theme-news-snippet">
                              “Recognised for being the pioneer of offering
                              three eco drivetrains in one stylish body”
                            </p>
                          </div>
                          <hr />
                          <div
                            className="d-flex justify-content-around mb-2 largerSI social-share-buttons"
                            data-article-id="IoniqBestSmallHatch"
                            data-article-text="Ioniq Best Small Hatch"
                          />
                        </div>

                        <div className="col-lg-8 border-lg-left">
                          <div className="row pt-3">
                            <div className="col-md-10 offset-md-1">
                              <p className="salesText" data-search-tag="title">
                                IONIQ CONTINUES TO CHARGE AHEAD - AWARDED BEST
                                SMALL HATCH AT THE UK CAR OF THE YEAR AWARDS
                                2017
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <div className="theme-news-body-text">
                                <ul>
                                  <li>
                                    Hyundai’s IONIQ, the world’s first car
                                    offering three electrified powertrains, has
                                    been awarded Best Small Hatch at the UK Car
                                    of the Year Awards 2017
                                  </li>
                                  <li>
                                    IONIQ praised for better driving and visual
                                    appeal than both Prius and Leaf
                                  </li>
                                  <li>
                                    Recognised for being the pioneer of offering
                                    three eco drivetrains in one stylish body
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div className="row pt-3">
                            <div className="col-md-10 offset-md-1">
                              <p
                                className="theme-news-body-text"
                                data-search-tag="summary"
                              >
                                <strong>
                                  High Wycombe, 9th January 2017 –
                                </strong>
                                The IONIQ has been awarded Best Small Hatch at
                                the UK Car of the Year Awards 2017, beating
                                competition from the Renault Megane and MINI
                                Clubman.
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                <strong>
                                  The IONIQ received praise from all the COTY
                                  judges for its green powertrains, combined
                                  with a chassis that delivers a good driving
                                  experience and its stylish body design. UK
                                  COTY judges said:
                                </strong>
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                “IONIQ scores for its choice of three good eco
                                drivetrains and is also decent to drive, with a
                                good ride and refinement.” Paul Hudson{" "}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                “Hyundai has produced a solid model to take on
                                the competitive segment.” Olivia Gauch{" "}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                “It’s an intelligent proposition for its
                                flexible electric technology – and the design is
                                edgy enough to reflect this.” Nargess Banks{" "}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                “Technologically pioneering in terms of the
                                range of powertrains, and the car is stylish and
                                without the compromises offered by other
                                alternative-fuelled vehicles.” Paul Barker{" "}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                “Out-Priuses the Prius with a three-car range in
                                a single swoop. A very impressive first effort.”
                                Nat Barnes{" "}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                “The fact it offers three greener powertrains is
                                a masterstroke. Why has nobody done this before?
                                A great reputation for reliability, decent
                                real-world economy from the petrol engine and
                                looks that are sufficient to make a statement
                                without looking polarising.” Keith Jones{" "}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                “With hybrid, plug-in hybrid and full electric
                                options, the IONIQ takes on the Toyota Prius
                                (hybrid & plug-in) and Nissan Leaf (EV only) and
                                arguably offers greater attributes than both –
                                from lower prices to better driving and visual
                                appeal.” Guy Bird{" "}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                Tony Whitehorn, President and CEO, Hyundai Motor
                                UK said “IONIQ has only been available since
                                October yet has already received praise for its
                                eco credentials and also its more mainstream
                                capabilities. The award for Best Small Hatch
                                from the UK COTY judges once again proves that
                                Hyundai is offering exactly what the market
                                wants – eco-friendly vehicles that drive well
                                and look good that are also cost effective to
                                run.”{" "}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                Ioniq – along with 11 other winning cars – will
                                now go forward to a final category to decide the
                                overall UK Car of the Year 2017. The winner will
                                be announced on 24th February 2017.{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
