import React, { Component } from 'react';
import RequestHistoryNavbar from '../components/requestHistoryNavbar';
import './myReminders.css';
import FooterNavbar from '../components/footerNavbar';
import TopNavbar from '../components/topnavbar';
import BottomNavbar from '../components/bottomnavbar';
import Footer from '../components/footer';
import Brochure from '../components/requestHistory/Brochure';
import Store from 'store';
export default class MyBrochures extends Component {
  constructor(props) {
    super(props);

    this.state = {
      vehicleInterest: Store.get('vehicleInterest').filter((vehicleInterest) =>
        ['BrochureRequestPDF', 'BrochureRequest'].includes(
          vehicleInterest.requestType,
        ),
      ),
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { vehicleInterest } = this.state;

    return (
      <div>
        <TopNavbar />
        <BottomNavbar />
        <RequestHistoryNavbar />

        <div className="container">
          <section
            className="section1 wow fadeIn"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <p className="text-center px-3">
              See your Hyundai request history below from the last 6 months
            </p>
            <div className="content p-3 p-xl-5 col-md-10 offset-md-1 border-right border-bottom">
              <div className="row">
                <div className="col-lg-3 p-3 p-sm-4">
                  <h4
                    className="font-weight-bold text-primary theme-main-request-history-sidebar-title mb-0 d-inline-block d-lg-block"
                    data-search-tag="title"
                  >
                    Postal
                    <br />
                    Brochures
                  </h4>

                  <p className="theme-main-request-history-sidebar-number text-info d-inline d-lg-block float-left float-lg-none">
                    {vehicleInterest.length}
                  </p>
                  <div className=" clearfix border-bottom d-lg-none" />
                </div>

                <div className="col-lg-9 p-sm-4 border-lg-left">
                  <div className="row">
                    {vehicleInterest.map((interest, index) => {
                      var date = new Date(
                        interest.requestTimeStamp,
                      ).toLocaleDateString('en-GB', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                      });
                      return (
                        <Brochure
                          key={index}
                          title={interest.vehicleDetails}
                          requestedDate={date}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <FooterNavbar />

        <Footer />
      </div>
    );
  }
}
