import Store from 'store';
import CSV from 'csv-js'

export default {
    list: [],
    rawDealers: [],
    rawDealerExtra: [],
    completeCallback: null,
    load(completeCallback) {
        this.completeCallback = completeCallback;
        var dealers = Store.get('dealers');
        if (dealers) {
            this.list = dealers;
            this.raiseCompleteCallback();
        }
        else {
            fetch('data/dealers.csv', {
                method: 'GET'
            })
                .then((response) => {
                    if (response.ok) {
                        return response.text();
                    }
                })
                .then(data => {
                    this.parseDealerExtra(data);
                });

            fetch(process.env.REACT_APP_API_URL + '/DealerSearch/GetAll', {
                method: 'GET'
            })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    }
                })
                .then(data => {
                    this.rawDealers = (data);
                    this.combineLists();
                });
        }
    },
    parseDealerExtra(data) {
        const valuesArray = CSV.parse(data);
        this.rawDealerExtra = valuesArray
            .filter((dealer) => dealer[0] != 'Name' && dealer[0].length != 0)
            .map((dealer) => {
                return {
                    "name": dealer[0],
                    "salesAddress1": dealer[1],
                    "salesAddress2": dealer[2],
                    "salesAddress3": dealer[3],
                    "salesPostcode": dealer[4],
                    "salesCounty": dealer[5],
                    "salesCountry": dealer[6],
                    "dealerSalesId": dealer[7],
                    "telephone": dealer[8],
                    "salesTelephone": dealer[9],
                    "aftersalesPostcode": dealer[10],
                    "aftersalesLatitude": this.parseLatLong(dealer[11]),
                    "aftersalesLongitude": this.parseLatLong(dealer[12]),
                    "website": dealer[13],
                    "hasSales": dealer[14],
                    "hasAftersales": dealer[15],
                    "mondayOpeningTimes": dealer[16],
                    "saturdayOpeningTimes": dealer[17],
                    "sundayOpeningTimes": dealer[18],
                    "mondayOpeningTimesAftersales": dealer[19],
                    "saturdayOpeningTimesAftersales": dealer[20],
                    "sundayOpeningTimesAftersales": dealer[21],
                    "hasMobility": dealer[22],
                    "hasUsedCars": dealer[23]
                }
            });

        this.combineLists();
    },
    parseLatLong(value) {
        var stringValue = String(value);
        if (stringValue.indexOf("N") >= 0 || stringValue.indexOf("E") >= 0 || stringValue.indexOf("S") >= 0 || stringValue.indexOf("W") >= 0) {
            var numericValue = parseFloat(value.substring(0, value.length - 1));
            if (value.indexOf("S") >= 0 || value.indexOf("W") >= 0) {
                numericValue = numericValue * -1;
            }
            return numericValue;
        }
        return value;
    },
    combineLists() {
        if (this.list.length == 0 && this.rawDealers.length != 0 && this.rawDealerExtra.length != 0) {
            this.list = this.rawDealers.map((dealer) => {
                var extra = this.rawDealerExtra.filter((extra) => extra.dealerSalesId == dealer.code);
                dealer.extra = (extra && extra.length != 0) ? extra[0] : null;
                return dealer;
            })
                .filter((dealer) => dealer.extra != null);

            Store.set('dealers', this.list);

            this.raiseCompleteCallback();
        }
    },
    raiseCompleteCallback() {
        if (this.completeCallback) {
            this.completeCallback();
        }
    }
}