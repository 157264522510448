import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';
import Store from 'store';
import ReactGA from 'react-ga';
export default class Ionic6Award extends Component {
  componentDidMount = () => {};

  clickHandler = () => {};

  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="bestCarAwards-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/ioniq6Main.png") ',
              }}
            />
            {/* <div
              id="bestCarAwards-gallery-2"
              className="theme-news-header-image "
              style={{
                backgroundImage: 'url("images/bca1.jpg") ',
              }}
            />
            <div
              id="bestCarAwards-gallery-3"
              className="theme-news-header-image "
              style={{
                backgroundImage: 'url("images/bca2.jpg") ',
              }}
            />
            <div
              id="bestCarAwards-gallery-4"
              className="theme-news-header-image "
              style={{
                backgroundImage: 'url("images/bca3.jpg") ',
              }}
            />
            <div
              id="bestCarAwards-gallery-5"
              className="theme-news-header-image "
              style={{
                backgroundImage: 'url("images/bca4.jpg") ',
              }}
            />
            <div
              id="bestCarAwards-gallery-6"
              className="theme-news-header-image "
              style={{
                backgroundImage: 'url("images/bca5.jpg") ',
              }}
            /> */}
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                {/* <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#bestCarAwards-gallery-2"
                    style={{
                      backgroundImage: 'url("images/bca1.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#bestCarAwards-gallery-3"
                    style={{
                      backgroundImage: 'url("images/bca2.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#bestCarAwards-gallery-4"
                    style={{
                      backgroundImage: 'url("images/bca3.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#bestCarAwards-gallery-5"
                    style={{
                      backgroundImage: 'url("images/bca4.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#bestCarAwards-gallery-6"
                    style={{
                      backgroundImage: 'url("images/bca5.jpg") ',
                    }}
                  /> */}
                {/* </div> */}
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “Hyundai IONIQ 6 Sweeps World Car of the Year, World Electric
                  Vehicle and World Car Design of the Year”
                </p>
              </div>
              <hr />

              <NewsMedia articleId="awards" articleText="awards" />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    HYUNDAI IONIQ 6 SWEEPS WORLD CAR OF THE YEAR, WORLD ELECTRIC
                    VEHICLE AND WORLD CAR DESIGN OF THE YEAR
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    {' '}
                    <ul>
                      <li>
                        IONIQ 6 honored by World Car Awards at New York
                        International Auto Show
                      </li>
                      <li>
                        The Electrified Streamliner is the second straight
                        Hyundai IONIQ model to sweep World Car Awards following
                        IONIQ 5’s triple wins in 2022
                      </li>
                      <li>
                        As a smart mobility solutions provider, Hyundai Motor
                        plans to introduce 17 battery electric vehicle models by
                        2030
                      </li>
                      {/* <li>
                        SANTA FE named ‘Seven-seater of the Year’ and ‘Best
                        Plug-in Hybrid for Big Families’
                      </li>
                      <li>
                        Hailed for its “practicality, upmarket feel and comfort”
                      </li> */}
                    </ul>
                  </p>
                  {displayShowMore && (
                    <p>
                      <a
                        data-toggle="collapse"
                        href="#ioniq6Award"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </div>

              <div className={moreSectionClassName} id="ioniq6Award">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      <>
                        Hyundai Motor Company’s acclaimed IONIQ 6 Electrified
                        Streamliner has won the prestigious World Car of the
                        Year, World Electric Vehicle and World Car Design of the
                        Year, lauded by an international jury for its unique
                        aerodynamic design and outstanding all-electric range.
                        World Car Awards revealed the results at a ceremony held
                        at the New York International Auto Show (NYIAS) today.
                        <br />
                        <br />A jury of 100 automotive journalists from 32
                        countries chose IONIQ 6 from the top three finalists,
                        all launched in 2022. This is Hyundai’s second straight
                        triple honors at the World Car Awards. Last year, the
                        jury named IONIQ 5 the winner in the same categories.
                      </>
                      <br />
                      <br />
                      <strong>
                        "We are thrilled to receive this prestigious honor two
                        years in a row, which recognizes the tremendous talents
                        and unrelenting efforts of everyone at Hyundai Motor
                        Company to bring EVs like IONIQ 6 to market. This honour
                        reinforces our commitment to be a global leader in
                        vehicle electrification and will serve to embolden our
                        efforts as a smart mobility solutions provider"
                      </strong>{' '}
                      - Jaehoon Chang, President and CEO of Hyundai Motor
                      Company
                      <br />
                      <br />
                      <strong>
                        "We always want to connect with customers on an
                        emotional level and with the design of the IONIQ 6 we
                        have created truly one of a kind. The daring streamliner
                        styling combines strong emotional appeal with
                        aerodynamic efficiency to give exceptional range.
                        Inside, we set-out to create a personal studio which
                        offers the mindful space and calm functionality which,
                        we believe, elevates EV design."
                      </strong>{' '}
                      - SangYup Lee, Executive Vice President and Head of
                      Hyundai Global Design Centre
                      <br />
                      <br />
                      <img src="images/ioniq62.png" className="img-fluid" />
                      <br />
                      <br />
                      IONIQ 6 is the second model in Hyundai’s dedicated
                      all-electric lineup brand, IONIQ. It takes full advantage
                      of a bespoke Electric-Global Modular Platform (E-GMP)
                      architecture to offer 800-V, ultra-fast charging
                      capabilities for a 10 to 80 percent charge in just 18
                      minutes and a spacious interior.
                      <br />
                      <br />
                      With an ultra-low drag coefficient of just 0.21, IONIQ 6
                      is one of the most aerodynamic and energy efficient EVs on
                      the market. The Electrified Streamliner’s WLTP rated range
                      on a single charge is 338 miles.
                      <br />
                      <br />
                      IONIQ 6’s many features include Vehicle-to-Load (V2L)
                      technology, driver assistance systems and advanced
                      connectivity with over-the-air (OTA) software updates that
                      offer the ultimate in-car experience. The spacious and
                      ergonomic interior takes full advantage of the elongated
                      2,950 mm wheelbase to provide an array of empowering
                      features, such as Dual Color Ambient Lighting, Speed Sync
                      Lighting, EV Performance Tune-up and Electric Active Sound
                      Design (e-ASD), to enhance the electric mobility
                      experience.
                      <br />
                      <br />
                      <img src="images/ioniq63.png" className="img-fluid" />
                      <br />
                      <br />
                      <img src="images/ioniq64.png" className="img-fluid" />
                      <br />
                      <br />
                      <img src="images/ioniq65.png" className="img-fluid" />
                      <br />
                      <br />
                      <img src="images/ioniq66.png" className="img-fluid" />
                      <br />
                      <br />
                      <img src="images/ioniq67.png" className="img-fluid" />
                      <br />
                      <br />
                      This year, the World Car Awards jury also named SangYup
                      Lee as the 2023 World Car Person of the Year for his vital
                      contribution to the most stunning and innovative concept
                      and production cars unveiled in 2022, including Hyundai
                      IONIQ 6, the All-new KONA and the N Vision 74 rolling lab.{' '}
                      <br />
                      <br />
                      In addition, IONIQ 6 has won prestigious awards during the
                      past few months as it’s been named ‘Saloon of the Year’ at
                      the 2023 GQ Car Awards and ‘New Car of the Year’ hosted by
                      Le Guide de l’auto. Plus, it achieved a five-star safety
                      rating and ‘Best in Class’ selection in the ‘Large Family
                      Car’ category from Euro NCAP.
                      <br />
                      <br /> The IONIQ brand’s winning this year will help to
                      energize Hyundai Motor’s efforts to accelerate its
                      electrification strategy and become the world’s leading EV
                      maker. Hyundai Motor plans to introduce 17 new BEV models
                      by 2030, including Genesis luxury brand models, and aims
                      to boost annual global BEV sales to 1.87 million units by
                      2030.
                    </p>
                  </div>
                </div>

                {/* <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      Early in January, the{' '}
                      <a
                        href="https://www.hyundai.news/uk/brand/hyundai-i10-is-what-cars-best-small-car-for-the-city-2021/"
                        target="_blank"
                      >
                        all-new i10 was named What Car?’s Best Small Car for the
                        City,
                      </a>{' '}
                      then the previous generation{' '}
                      <a
                        href="https://www.hyundai.news/uk/model-news/hyundai-scores-double-win-at-what-car-used-car-awards/"
                        target="_blank"
                      >
                        i20 was named as First Car’s Used Car of the Year
                      </a>{' '}
                      as a ‘safe, reliable and easy to drive’ car for new
                      drivers. Continuing Hyundai’s small car wins, carwow named
                      the i10 as its Best Small Car, saying: “it’s a doddle to
                      drive but still roomy enough for four adults and their
                      stuff. It’s big on technology, too.”
                    </p>
                  </div>
                </div> */}

                {/* <div className="row"> */}

                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
