import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import './bottomnavbar.css';
import Store from 'store';
import Auth from '../utils/Auth';
import HandbookJS from '../utils/Handbook';

import { ClickOutside } from '../utils/CustomJquery';

export default class BottomNavbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customer: Store.get('customerInfo'),
      foreName: '',
      hasTestDrive: true,
      hasQuoteRequests: true,
      hasSignedOut: false,
      defaultName: false,
      searchTerm: '',
      redirectToSearch: false,
      competitions: Store.get('competitionInfo'),
      vehicles: Store.get('vehicles'),
      vehicleInterest: Store.get('vehicleInterest'),
      offers: Store.get('offers'),
      handbooks: [],
      showElectricLink: false,
      carOffers: Store.get('carOffers'),
      overFive:
        (Store.get('vehiclesInfo') &&
          Store.get('vehiclesInfo').isAnyVehicleOverFiveYears) ||
        false,
      underFive:
        (Store.get('vehiclesInfo') &&
          Store.get('vehiclesInfo').isAnyVehicleUnderFiveYears) ||
        false,
      config: [],
    };
  }

  componentWillMount() {
    const handbooks = HandbookJS.GetHandbooks(this.state.vehicles);

    this.setState({
      handbooks: handbooks,
    });
  }

  componentDidMount() {
    ClickOutside();

    this.setState({
      config: Store.get('public_config'),
    });

    if (this.state.vehicleInterest.length > 0) {
      this.state.vehicleInterest.forEach((t) => {
        if (
          t.vehicleDetails === 'KONA Electric' ||
          t.vehicleDetails === 'IONIQ Electric'
        ) {
          this.setState({
            showElectricLink: true,
          });
        }
      });
    }

    const customer = Store.get('customerInfo');

    if (customer) {
      if (customer.forenames.length < 2) {
        this.setState({
          foreName: 'MyHyundai',
          defaultName: true,
        });
      } else {
        this.setState({
          foreName: customer.forenames,
          defaultName: false,
        });
      }
    }
  }

  signout = () => {
    Auth.signout(function () {
      window.location.href = process.env.PUBLIC_URL + '/login?1';
    });
  };

  search = () => {
    this.setState({
      redirectToSearch: true,
    });
  };

  handleSearchTermChange = (e) => {
    const target = e.target;
    this.setState({
      searchTerm: target.value,
    });
  };

  render() {
    const { customer, carOffers, vehicles } = this.state;

    // Customer Types
    // NotSet = 0,
    // Customer = 1,
    // Prospect = 2,
    // Lapsed = 3,
    // Deceased = 4 - redundant

    const isCarOffersVisible = carOffers && carOffers.showSalesOffer;

    //Redirect to Search Page
    if (this.state.redirectToSearch) {
      return (
        <Redirect
          push
          to={{
            pathname: '/searchResults',
            query: { searchTerm: this.state.searchTerm },
          }}
        />
      );
    }

    return (
      <div className="container" data-search-exclude>
        {this.state.logInSuccess && (
          <div>Please wait while you are redirected.</div>
        )}

        <nav className="navbar navbar-light px-0 bg-white">
          <button
            className="custom-toggler navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          {!this.state.defaultName && (
            <Link to="/personal" className="nav-link usernamebn mr-auto pl-3">
              {this.state.foreName}
              's Hyundai{' '}
              <i
                className="fas fa-fw  fa-user-plus "
                data-toggle="tooltip"
                data-placement="right"
                title="Click here to view or update your profile details."
              />
            </Link>
          )}
          {this.state.defaultName && (
            <Link to="/personal" className="nav-link usernamebn mr-auto pl-3">
              {this.state.foreName}{' '}
              <i
                className="fas fa-fw  fa-user "
                data-toggle="tooltip"
                data-placement="right"
                title="Click here to view or update your profile details."
              />
            </Link>
          )}

          <div className="form-inline d-none d-sm-flex">
            {(this.props.hideSearch == null || !this.props.hideSearch) && (
              <form className="input-group">
                <div className="input-group-prepend ">
                  <span
                    className="input-group-text bottomNavPrepend"
                    id="basic-addon1"
                  >
                    <i className="fas fa-fw  fa-search" />
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control  bottomNavPrependInput"
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="basic-addon1"
                  value={this.state.searchTerm}
                  onChange={this.handleSearchTermChange}
                />
                <button
                  className="btn btn-primary btn-small removeRadius"
                  onClick={this.search}
                >
                  Go
                </button>
              </form>
            )}
            <Link className="nav-link" to="/" onClick={this.signout}>
              <small>Sign out</small> <span className="sr-only">(current)</span>
            </Link>
          </div>
          <div
            className="collapse navbar-collapse theme-bottomnavbar bottom-navbar-dropdown"
            id="navbarNavDropdown"
          >
            <ul className="navbar-nav theme-bottomnavbar-navbar-nav">
              <li>
                <button
                  className="theme-bottomnavbar-toggler "
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarNavDropdown"
                  aria-controls="navbarNavDropdown"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="fas fa-fw  fa-window-close text-blue" />
                </button>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle  align-items-center"
                  href="#"
                  id="navMyProfile"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fas fa-fw  fa-user" />
                  <span className="d-flex align-items-center">
                    My Profile <i className="fas fa-fw  fa-angle-down" />
                    <i className="fas fa-fw  fa-angle-up" />
                  </span>
                </a>
                <div className="dropdown-menu" aria-labelledby="navMyProfile">
                  <Link to="/personal" className="dropdown-item">
                    Contact Details
                  </Link>
                  <Link to="/contactPref" className="dropdown-item">
                    Contact Preferences
                  </Link>
                  {/* <Link to="/interests" className="dropdown-item">
                    My Interests
                  </Link> */}
                  <Link to="/dealerDetails" className="dropdown-item">
                    Retailer Details
                  </Link>
                  <Link to="/ManageMyAccount" className="dropdown-item">
                    Manage My Account
                  </Link>
                </div>
              </li>
              {customer.customerType !== 2 && vehicles.length > 0 && (
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle  align-items-center"
                    href="#"
                    id="navMyCar"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fas fa-fw  fa-car" />
                    <span className="d-flex align-items-center">
                      My Car <i className="fas fa-fw  fa-angle-down" />
                      <i className="fas fa-fw  fa-angle-up" />
                    </span>
                  </a>
                  <div className="dropdown-menu" aria-labelledby="navMyCar">
                    <Link to="/myCarDetails" className="dropdown-item">
                      Vehicle Details
                    </Link>
                    <Link to="/myReminders" className="dropdown-item">
                      My Reminders
                    </Link>
                    <Link to="/serviceHistory" className="dropdown-item">
                      Retailer Visits
                    </Link>
                    {/* <Link to="/fuelCalculator" className="dropdown-item">
                      Fuel Saving Calculator
                    </Link> */}
                    {this.state.handbooks &&
                      this.state.handbooks.length != 0 && (
                        <Link to="/myHandbook" className="dropdown-item">
                          My Handbook
                        </Link>
                      )}
                  </div>
                </li>
              )}
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle align-items-center"
                  to="/offers"
                  id="navOffers"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="far fa-fw  fa-star" />
                  <span className="d-flex align-items-center">
                    Offers <i className="fas fa-fw  fa-angle-down" />
                    <i className="fas fa-fw  fa-angle-up" />
                  </span>
                </Link>
                <div className="dropdown-menu" aria-labelledby="navOffers">
                  <Link to="/offers" className="dropdown-item">
                    All Offers
                  </Link>

                  {this.state.offers.map((offer, index) => {
                    return (
                      <Link
                        to={offer.link}
                        key={index}
                        title={offer.title}
                        className="dropdown-item"
                      >
                        {offer.name}
                      </Link>
                    );
                  })}
                </div>
              </li>

              {/* Car Offers */}
              {isCarOffersVisible && (
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle align-items-center"
                    to="/carOffers"
                    id="navCarOffers"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fas fa-fw  fa-asterisk" />
                    <span className="d-flex align-items-center">
                      Car Offers <i className="fas fa-fw  fa-angle-down" />
                      <i className="fas fa-fw  fa-angle-up" />
                    </span>
                  </Link>
                  <div className="dropdown-menu" aria-labelledby="navCarOffers">
                    <Link to="/carOffers" className="dropdown-item">
                      Car Offers
                    </Link>
                  </div>
                </li>
              )}

              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle align-items-center"
                  to="/news"
                  id="navNews"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="far fa-fw  fa-newspaper" />
                  <span className="d-flex align-items-center">
                    News
                    <i className="fas fa-fw  fa-angle-down" />
                    <i className="fas fa-fw  fa-angle-up" />
                  </span>
                </Link>
                <div className="dropdown-menu" aria-labelledby="navNews">
                  {/* <Link to="/productNews" className="dropdown-item">
                    Product News
                  </Link> */}
                  <Link to="/hyundaiNews" className="dropdown-item">
                    Hyundai News
                  </Link>
                  <Link to="/awardsNews" className="dropdown-item">
                    Awards
                  </Link>
                  {/* <Link to="/newsletter" className="dropdown-item">
                    MyHyundai Newsletter
                  </Link> */}
                </div>
              </li>
              {this.state.competitions &&
                this.state.config &&
                this.state.config.data &&
                this.state.config.data.competitions_active && (
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle align-items-center"
                      to="/compsOverview"
                      id="navCompetitions"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fas fa-fw  fa-trophy" />
                      <span className="d-flex align-items-center">
                        Competitions
                        <i className="fas fa-fw  fa-angle-down" />
                        <i className="fas fa-fw  fa-angle-up" />
                      </span>
                    </Link>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="navCompetitions"
                    >
                      <Link to="/compsOverview" className="dropdown-item">
                        All Competitions
                      </Link>
                      {this.state.competitions.map((competition, index) => {
                        if (!competition.directLinkOnly) {
                          return (
                            <Link
                              to={{
                                pathname: '/competition',
                                search: '?id=' + competition.id,
                              }}
                              className="dropdown-item"
                              key={index}
                            >
                              {competition.shortTitle}
                            </Link>
                          );
                        }
                      })}
                    </div>
                  </li>
                )}
              {/* Excluded from Phase 2 */}
              {/* <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle align-items-center"
                  to="/requestHistory"
                  id="navRequestHistory"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fas fa-fw  fa-calendar" />
                  <span className="d-flex align-items-center">
                    Request History
                    <i className="fas fa-fw  fa-angle-down" />
                    <i className="fas fa-fw  fa-angle-up" />
                  </span>
                </Link>
                <div
                  className="dropdown-menu"
                  aria-labelledby="navRequestHistory"
                >
                  <Link to="/myQuotes" className="dropdown-item">
                    My Quotes
                  </Link>
                  <Link to="/myTestDrives" className="dropdown-item">
                    My Test Drives
                  </Link>
                  <Link to="/myBrochures" className="dropdown-item">
                    My Brochures
                  </Link>
                 //<Link to="/carsConfigured" className="dropdown-item">
                  //  Car Configuration Quotes
                 // </Link>  
                </div>
              </li>  
              {customer.customerType === 2 && this.state.showElectricLink && (
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle align-items-center"
                    to="/podCharging"
                    id="navElectricVehicles"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fas fa-fw  fa-bolt" />
                    <span className="d-flex align-items-center">
                      Electric Vehicles
                      <i className="fas fa-fw  fa-angle-down" />
                      <i className="fas fa-fw  fa-angle-up" />
                    </span>
                  </Link>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navElectricVehicles"
                  >
                    <Link to="/podCharging" className="dropdown-item">
                      POD Point
                    </Link>
                  </div>
                </li>
              )}
*/}
              {/*console.log(customer.customerType)*/}
              {/*console.log(this.state.underFive)*/}
              {/*console.log(this.state.overFive)*/}

              {customer.customerType === 2 ||
              this.state.underFive ||
              this.state.overFive ? (
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle align-items-center"
                    to="/servicePlans"
                    id="navServiceProducts"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fas fa-fw  fa-wrench" />
                    <span className="d-flex align-items-center">
                      Service Products
                      <i className="fas fa-fw  fa-angle-down" />
                      <i className="fas fa-fw  fa-angle-up" />
                    </span>
                  </Link>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navServiceProducts"
                  >
                    {this.state.underFive && (
                      <Link to="/servicePlans" className="dropdown-item">
                        Service Plans
                      </Link>
                    )}

                    {(this.state.overFive ||
                      Store.get('campaign_name') === 'facebook') && (
                      <div>
                        <a
                          href="https://www.hyundai.com/uk/en/owners/servicing/servicing.html"
                          className="dropdown-item"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Servicing
                        </a>
                        <a
                          href="https://www.hyundai.com/uk/en/owners/servicing/fixed-price-servicing.html"
                          className="dropdown-item"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Fixed price servicing
                        </a>
                        <a
                          href="https://www.hyundai.com/uk/en/owners/servicing/service-plans.html"
                          className="dropdown-item"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Service plans
                        </a>
                        <a
                          href="https://www.hyundai.com/uk/en/owners/servicing/hyundai-service-subscription.html"
                          className="dropdown-item"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Hyundai service subscriptions
                        </a>
                      </div>
                    )}

                    {customer.customerType === 2 && (
                      <Link to="/peaceOfMind" className="dropdown-item">
                        Peace Of Mind
                      </Link>
                    )}
                  </div>
                </li>
              ) : null}
              {customer.customerType !== 2 && (
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle align-items-center"
                    to="/feedback"
                    id="navFeedback"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="far fa-fw fa-thumbs-up " />
                    <span className="d-flex align-items-center">
                      Feedback
                      <i className="fas fa-fw  fa-angle-down" />
                      <i className="fas fa-fw  fa-angle-up" />
                    </span>
                  </Link>
                  <div className="dropdown-menu" aria-labelledby="navFeedback">
                    <Link to="/feedback" className="dropdown-item">
                      Feedback
                    </Link>
                  </div>
                </li>
              )}
              <li className="nav-item">
                <Link
                  className="nav-link align-items-center"
                  to="/"
                  onClick={this.signout}
                >
                  <i className="fas fa-fw  fa-sign-out-alt" />
                  <span>Sign out</span>
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}
