import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';
import Store from 'store';
import ReactGA from 'react-ga';
export default class NewI2023 extends Component {
  componentDidMount = () => {};

  clickHandler = () => {};

  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="NewI2023" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="oneMillion-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/newi2023Tile.jpg") ',
              }}
            />
            {/* <div
              id="Bayon-gallery-2"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/hyundaielectric2.jpg")',
              }}
            /> */}
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                {/* <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#Bayon-gallery-2"
                    style={{
                      backgroundImage: 'url("images/hyundaielectric2.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#Bayon-gallery-1"
                    style={{
                      backgroundImage: 'url("images/hyundaielectric1.jpg")',
                    }}
                  />
                </div> */}
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “Hyundai announces new i20 prices and specifications”
                </p>
              </div>
              <hr />

              <NewsMedia articleId="awards" articleText="awards" />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    HYUNDAI ANNOUNCES NEW I20 PRICES AND SPECIFICATIONS
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    {' '}
                    <ul>
                      <li>
                        Hyundai Motor has revealed the new i20, which will hit
                        roads with a refreshed, stylish design. The car`s safety
                        and best-in-class connectivity features ensure it is one
                        of the most comprehensive packages in its class.
                      </li>
                      {/* <li>
                        The new concept connects city and nature in a seamless
                        way offering ‘expansion of experiences’ in the everyday
                        life
                      </li>
                      <li>
                        Robust exterior design with fine details makes a
                        powerful statement; H-lights harmonize Santa Fe’s front
                        and rear, creating a differentiated presence on the road
                      </li>
                      <li>
                        Large tailgate and spacious interior offer best-in-class
                        terrace-like space that’s great for urban life and even
                        better for the outdoors
                      </li>
                      <li>
                        Interior and exterior feature unique H-shaped design
                        cues and eco-friendly materials
                      </li>
                      <li>
                        All-new Santa Fe to make world premiere in August via
                        Hyundai’s YouTube channel
                      </li> */}
                      {/* <li>
                        Among IONIQ 5 N’s many exclusive performance features
                        are N Battery Preconditioning, N Race, N Pedal, N Brake
                        Regen, N Drift Optimizer, N Torque Distribution, N
                        Launch Control, N Grin Boost, N Active Sound +, N
                        e-shift, N Road Sense and Track SOC
                      </li> */}
                      {/* <li>
                        Hyundai is investing £1 million in a programme of school
                        trips in order to prevent children missing out on such
                        experiences
                      </li>
                      <li>
                        Bookings are now open for the initiative, which has
                        launched with Hyundai’s Educator in Residence, Tim
                        Campbell MBE
                      </li> */}
                    </ul>
                  </p>
                  {displayShowMore && (
                    <p>
                      <a
                        data-toggle="collapse"
                        href="#NewI2023c"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </div>

              <div className={moreSectionClassName} id="NewI2023c">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      <strong>
                        The new i20 features a refreshed design including a new
                        shape and pattern of the front bumper and radiator
                        grille, which have been enhanced to create an elegant
                        look with sporty elements. The rear features a
                        redesigned bumper and a unique taillight design
                        alongside newly designed 16 and 17 inch wheels have been
                        added. A dynamic light signature that emphasises its
                        overall bold look, is provided by the standard LED front
                        lights, with Z-shaped LED rear lamps.. Even with compact
                        B-segment dimensions, the new i20 offers customers
                        plenty of interior room, as well as 352 litres of boot
                        space with the rear seats up, and 1,165 litres with the
                        rear seats folded down.
                      </strong>
                      <br />
                      <br />
                      As well as refreshed design, new i20 is available with a
                      wider colour pallet, with nine exterior colours and an
                      optional black two-tone roof. Three exterior colours are
                      brand-new: Lucid Lime Metallic, Lumen Grey Pearl, and Meta
                      Blue Pearl. Returning colours include Atlas White, Phantom
                      Black Pearl, Aurora Grey Pearl, Dragon Red Pearl, Vibrant
                      Blue Pearl, and Mangrove Green Pearl. Alongside new
                      exterior colour options, a new Lucid Lime interior colour
                      package is also available with the seats finished in a new
                      pattern with a bold vertical line, embossed horizontal
                      dotted lines and lime-coloured stitching.
                      <br />
                      <br />
                      <img src="images/newi20231.jpg" className="img-fluid" />
                      <br />
                      <br />
                      New i20 comes equipped as standard with the latest
                      connectivity features that make the in-car experience even
                      more convenient for occupants with USB type-C and
                      Over-the-Air (OTA) map updates are introduced alongside
                      popular features from the previous model, such as the
                      10.25” touchscreen navigation (from Premium Trim and
                      above), Apple CarPlay and Android Auto, wireless device
                      charger, and the latest update to Bluelink® telematics.
                      <br />
                      <br />
                      More Hyundai Smart Sense safety features come as standard.
                      Forward Collision-Avoidance Assist (FCA) for ‘City’,
                      ‘Interurban’, ‘Pedestrian’ and ‘Cyclist’. FCA helps detect
                      and avoid potential accidents with obstacles ahead. Lane
                      Following Assist (LFA) ensures that the vehicle stays in
                      the current lane of travel.
                      <br />
                      <br /> For additional peace of mind, optional safety
                      systems are also available. Rear Cross-traffic Collision
                      Assist (RCCA) applies the brakes while backing out of a
                      parking space if a risk of collision with vehicles to the
                      rear and side is detected. Blind-spot Collision-avoidance
                      Assist (BCA) uses visual alerts that appear on the
                      exterior mirrors if another vehicle is detected. When
                      necessary, BCA activates to avoid a collision or reduce
                      the impact of a collision. Navigation-based Smart Cruise
                      Control (NSCC) uses the vehicle’s navigation system to
                      anticipate curves or straights on motorways and adjusts
                      the speed for safer driving.
                      <br />
                      <br />
                      <img src="images/newi20232.jpg" className="img-fluid" />
                      <br />
                      <br />
                      <img src="images/newi20233.jpg" className="img-fluid" />
                      <br />
                      <br />
                      New i20 also offers a more simplified powertrain line up,
                      with all models available with the turbocharged, 3
                      cylinder, 100PS engine and a choice of 6 speed manual or 7
                      speed dual clutch transmission. <br />
                      <br /> In a range realignment the new i20’s range now
                      starts with the Advance at £20,770 for the Advance 1.0
                      T-GDi 100PS 6MT. The Advance exterior features include
                      redesigned 16” alloy wheels, LED head and tail lamps,
                      whilst interior equipment includes an 8” touchscreen
                      display audio with subscription free Apple CarPlay™ and
                      Android Auto™, 10.25” supervision instrument cluster with
                      customisable functions, front USB-C port for charging
                      compatible devices, manual air conditioning, cruise
                      control with speed limiter function, leather wrapped
                      steering wheel and gear lever, front and rear parking
                      sensors with rear view camera and electrically adjustable
                      and heated wing mirrors. Hyundai’s SmartSense safety
                      package is also standard, offering E-Call, Forward
                      Collision Alert (FCA), Driver Attention Alert (DAA),
                      Intelligent Speed Limit Warning (ISLW), High Beam Assist
                      (HBA), Lane Keep Assist (LKA), Lane Follow Assist (LFA)
                      and Tyre Pressure Monitoring System (TPMS). The Advance
                      1.0 T-GDi with 7 speed DCT powertrain is available from
                      £22,020.
                      <br />
                      <br />
                      <img src="images/newi20234.jpg" className="img-fluid" />
                      <br />
                      <br />
                      <img src="images/newi20235.jpg" className="img-fluid" />
                      <br />
                      <br />
                      Next in new i20’s range is the Premium from £22,570, that
                      builds on the Advance trim, with enhanced exterior trim
                      including 17” alloy wheels, front fog lamps, power folding
                      door mirrors and privacy glass. Additional specification
                      includes automatic wipers, automatically dimming rear view
                      mirror, automatic air conditioning with climate control,
                      multi colour ambient lighting, heated front seats, heated
                      steering wheel and front arm rest. The Premium trim level
                      also includes a 10.25” touchscreen navigation with
                      subscription free Apple CarPlay™ and Android Auto™, as
                      well as Hyundai’s Bluelink® telematics system which
                      carries a free of charge 3-year subscription. Bluelink® is
                      a smartphone app based connected car service that enables
                      drivers to carry out a number of different functions
                      whilst away from their vehicle, including remote central
                      locking activation and vehicle parked location
                      information. In addition to the driver convenience
                      features, Bluelink® serves as a convenient diagnostic tool
                      which will notify the driver of any vehicle maintenance
                      issues that require attention. The Premium 1.0 T-GDi with
                      7 speed DCT powertrain is available from £23,820.
                      <br />
                      <br />
                      <img src="images/newi20236.jpg" className="img-fluid" />
                      <br />
                      <br />
                      The i20 Ultimate 1.0 T-GDi 100PS 6MT is available from
                      £24,070 and adds to the Premium trim level with additional
                      equipment including Smart Key with keyless entry and
                      engine start/stop button, a wireless charging pad for
                      compatible devices, Blind-spot Collision Warning (BCW) and
                      Forward Collision Assist (FCA) with cyclist detection. The
                      new i20 Ultimate also continues with the Bose Premium
                      Sound System seen in the previous model. The Bose Premium
                      Sound System is collaboration between Hyundai and Bose
                      engineers and features eight high performance speakers
                      including a subwoofer. The system is equipped with dynamic
                      speed compensation technology, which monitors vehicle
                      speed and automatically adjusts music levels, ensuring a
                      more consistent listening experience and reducing the need
                      for drivers to make manual adjustments while on the road.
                      The i20 Ultimate 1.0 T-GDi 7 speed DCT powertrain is
                      available from £25,320.
                      <br />
                      <br />
                      <strong>
                        "Since its introduction in 2009, the i20 has
                        consistently delivered for consumers looking for a
                        supermini that combines style, value and specification.
                        The enhancements for new i20 strengthens our proposition
                        even further in this segment as we look to attract more
                        people to our exciting brand."
                      </strong>
                      <br />
                      shley Andrew President,
                      <br />A Hyundai Motor UK
                      <br />
                      <br />
                      All new i20 models come with Hyundai’s industry leading 5
                      Year Unlimited Mileage Warranty, Roadside Assistance
                      package, 5 Year Annual Health Check, and 12 Year Anti
                      Corrosion Warranty.
                      <br />
                      <br />
                      <a
                        target="_blank"
                        href=" https://www.hyundai.news/newsroom/dam/uk/models/20230815_hyundai_announces_new_i20_prices_and_specifications/new-i20-pricing-spec-and-tech.pdf"
                      >
                        <strong>
                          Download new i20 pricing and specifications
                        </strong>
                      </a>{' '}
                      <br />
                      <br />
                      <img src="images/newi20237.jpg" className="img-fluid" />
                      <br />
                      <br />
                      <img src="images/newi20238.jpg" className="img-fluid" />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
