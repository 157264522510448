import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';

export default class Future extends Component {
  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="Future" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="future-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/futurenews.jpg") ',
              }}
            />
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              {/* <div className="d-flex flex-row justify-content-between flex-wrap">
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a  
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#future-gallery-1"
                    style={{
                      backgroundImage: 'url("images/futurenews.jpg") '
                    }}
                  />
                </div>
                
              </div> */}
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “The partnership has recently unveiled the world’s first
                  Holographic Augmented Reality Navigation System at the biggest
                  technology extravaganza”
                </p>
              </div>
              <hr />

              <NewsMedia
                articleId="future"
                articleText="The future is 15 metres ahead of you"
              />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    THE FUTURE IS 15 METRES AHEAD OF YOU
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    {' '}
                    Until now, the progression of the automobile has been mostly
                    one of steady evolution rather than revolution. Yet the rise
                    of key topics such as sustainability, technology and
                    connectivity means we’re likely to see more change in the
                    next 10 years than we have seen in the last 100.
                  </p>
                  {displayShowMore && (
                    <p>
                      <a  
                        data-toggle="collapse"
                        href="#collapsefuture"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                  {/* <p className="theme-news-body-text">The i30 Fastback’s sloping roofline, long bonnet and muscular body, along with its slim cabin and wide wheel arches give the car a strong stance. That strength, however, is far from being merely skin deep.</p> */}
                </div>
              </div>

              <div className={moreSectionClassName} id="collapsefuture">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      Take Augmented Reality (AR), for example. Once restricted
                      to the realm of sci-fi films, this cutting-edge technology
                      could soon be in your car thanks to Hyundai’s
                      collaboration with Swiss start-up company WayRay AG. The
                      partnership has recently unveiled the world’s first
                      Holographic Augmented Reality Navigation System at the
                      biggest technology extravaganza; the Consumer Electronics
                      Show 2019 in Las Vegas.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      The AR display, which projects a virtual image through the
                      windscreen 15 metres in front of the driver’s eyes, not
                      only gives key navigational features such as lane
                      guidance, destination points, and current speed, but it
                      also incorporates safety features like lane departure and
                      forward collision warnings.{' '}
                    </p>
                  </div>
                </div>

                {/* <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {" "}
                      The SUV will feature the 2.2 CRDi “R” engine,
                      which has been upgraded to further reduce
                      emissions, and will be available with front
                      wheel drive or 4WD, with a 6-speed manual
                      gearbox or an all-new 8-speed automatic
                                  transmission.{" "}
                    </p>
                  </div>
                </div> */}

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      While conventional head-up display units project a
                      reflected image through an LCD screen mounted on the
                      dashboard, the biggest advantage of the holographic AR
                      system is that the information is displayed through the
                      windscreen, allowing the driver to look at the road ahead
                      without being distracted.{' '}
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      “This demo car is an important step towards technology
                      driven innovation,” said Yunseong Hwang, Director of Open
                      Innovation Business Group from Hyundai Motor Group about
                      the Genesis G80 displayed at the CES show. “Future
                      mobility windscreens will be more than just a piece of
                      glass. AR holographic powered glass will serve as a
                      platform to provide new services and open up new
                      in-vehicle experiences.”
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      Hyundai and WayRay AG have plans to expand the holographic
                      AR technology to display people, objects, buses, bicycle
                      lanes, and even pedestrian crossings. What’s more, the
                      incorporation of V2X (Vehicle to Everything) technology
                      and connectivity will allow things like road and weather
                      conditions, traffic lights and surrounding vehicle
                      information to be provided in real time.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      Partnerships such as this are part of Hyundai’s commitment
                      to concentrate its research and development resources on
                      acquiring AR navigation and other related technologies to
                      improve the next generation of displays and connected
                      vehicles, as well as to develop future technologies such
                      as wearable robots and smart buildings.{' '}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
