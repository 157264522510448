import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';

export default class I10HighlyRated extends Component {
  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="I10HighlyRated" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="I10HighlyRated-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/HighlyRatedHyundaii10copy.jpg") ',
              }}
            />
            <div
              id="I10HighlyRated-gallery-2"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/HJ2018HatLogo.png")',
              }}
            />
          </div>
        </div>

        <div className="card-block">
          <div className="card-content">
            <div className="col">
              <div className="row whiteBG">
                <div className="col-lg-4">
                  <div className="salesText mt-3">
                    <p className="theme-news-snippet">
                      “Praised by i10 owners and users of HonestJohn.co.uk for
                      its comfort, reliability and fuel economy”
                    </p>
                  </div>
                  <hr />

                  <NewsMedia
                    articleId="I10HighlyRated"
                    articleText="I10 Highly Rated"
                  />
                  <div
                    className="d-flex justify-content-around mb-2 largerSI social-share-buttons"
                    data-article-id="I10HighlyRated"
                    data-article-text="I10 Highly Rated"
                  />
                </div>

                <div className="col-lg-8 border-lg-left">
                  <div className="row pt-3">
                    <div className="col-md-10 offset-md-1">
                      <p className="salesText" data-search-tag="title">
                        THE MULTI-AWARD WINNING HYUNDAI i10 WINS MOST HIGHLY
                        RATED CAR OF THE YEAR AT THE 2018 HONEST JOHN AWARDS
                      </p>

                      <ul
                        className="theme-news-body-text"
                        data-search-tag="summary"
                      >
                        <li>
                          Hyundai Motor wins Most Highly Rated Car of the Year
                          for the i10 at 2018 Honest John Awards
                        </li>
                        <li>
                          Praised by i10 owners and users of HonestJohn.co.uk
                          for its comfort, reliability and fuel economy
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row pt-3">
                    <div className="col-md-10 offset-md-1">
                      <p className="theme-news-body-text">
                        High Wycombe, 04 May 2018 – Hyundai Motor UK claims The
                        Most Highly Rated Car of the Year at last night’s Honest
                        John Awards as voted for by HonestJohn.co.uk users. On
                        the i10’s success, Dan Powell, Managing Editor,
                        HonestJohn.co.uk, commented: “The Most Highly Rated Car
                        of the Year is one of the most-coveted of all of the
                        Honest John Awards, because it is based purely on
                        owners’ reviews. Over the past 12 months the Hyundai i10
                        has consistently received five star feedback from our
                        readers, winning praise for comfort, reliability and
                        fuel economy. Riding an avalanche of positive reviews,
                        the Hyundai i10 is miles ahead of the competition and
                        our Most Highly Rated Car of the Year.”
                      </p>
                      {displayShowMore && (
                        <p>
                          <a  
                            data-toggle="collapse"
                            href="#collapsei10HighlyRated"
                            role="button"
                            aria-expanded="false"
                          >
                            More{' '}
                            <span>
                              <i className="fas fa-long-arrow-alt-down" />
                            </span>
                          </a>
                        </p>
                      )}
                    </div>
                  </div>

                  <div
                    className={moreSectionClassName}
                    id="collapsei10HighlyRated"
                  >
                    <div className="row">
                      <div className="col-md-10 offset-md-1">
                        <p className="theme-news-body-text">
                          Tony Whitehorn, President and CEO, Hyundai Motor UK,
                          said: “The impressive i10 is already the most awarded
                          car in our range, winning several awards from
                          HonestJohn.co.uk for the Most Popular City Car over
                          the last few years. Winning this award though is
                          particularly special as it the result of the reviews
                          from our customers who live with the car every day. It
                          is truly a testament to the style, refinement and
                          practicality of this perfectly-formed city car.” The
                          i10 starts from £9,695 (S trim 1.0-litre petrol
                          manual), up to £13,915 (Premium SE 1.2-litre petrol
                          automatic). For more information, visit:{' '}
                          <a  
                            href="http://www.hyundai.co.uk/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            www.hyundai.co.uk
                          </a>
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-10 offset-md-1">
                        <p className="theme-news-body-text">
                          Now in its eighth consecutive year, the Honest John
                          Awards don’t follow the traditional awards format,
                          instead the winners are chosen by the best experts of
                          all: the website users. The awards recognise and
                          reward the cars that HonestJohn.co.uk users search for
                          most on the site. The reviews which create the most
                          interest among HonestJohn.co.uk users are the winners.{' '}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
