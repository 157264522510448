import React, { Component } from 'react';
import Dates from '../../utils/DateFormat';
import Store from 'store';
import SaveData from '../../utils/SaveData';
import Validation from '../../utils/Validation';
import InputField from '../inputField';

import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

export default class CarDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      vehicle: props.vehicle,
      vehicleIndex: props.vehicleIndex,
      lastServiceDate: CarDetails.getLastServiceDate(props.vehicle),
      mileage: CarDetails.getMileage(props.vehicle),
      saveSuccess: false,
      saveFailed: false,
      validationService: null,
      validationMileage: null,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.vehicle == null) {
      return null;
    }

    if (
      prevState.vehicle != null &&
      prevState.vehicle.id == nextProps.vehicle.id
    ) {
      return null;
    }

    const lastServiceDate = CarDetails.getLastServiceDate(nextProps.vehicle);

    return {
      ...prevState,
      vehicle: nextProps.vehicle,
      vehicleIndex: nextProps.vehicleIndex,
      lastServiceDate: lastServiceDate,
      mileage: CarDetails.getMileage(nextProps.vehicle),
      validationMileage: null,
      validationService: null,
    };
  }

  static getLastServiceDate(vehicle) {
    var defaultDate = new Date('1970,01,01');
    var lastServiceDateConverted = new Date(vehicle.lastServiceDate);

    if (vehicle == null) return '';
    var lastServiceDate = '';
    if (
      vehicle.lastServiceDate == null ||
      +lastServiceDateConverted == +defaultDate
    ) {
      lastServiceDate = null;
    } else {
      lastServiceDate = moment(new Date(vehicle.lastServiceDate));
    }

    return lastServiceDate;
  }

  static getMileage(vehicle) {
    if (vehicle == null) return '';
    return vehicle.mileage;
  }

  saveVehicleDetails = () => {
    this.setState({
      saveFailed: false,
      saveSuccess: false,
    });

    const newState = Validation.validateAll(this.state);

    if (
      Validation.anyInvalid(newState) ||
      this.state.validationService ||
      this.state.validationMileage
    ) {
      this.setState(newState);
    } else {
      this.setState({
        isLoading: true,
      });

      const newVehicles = Store.get('vehicles');

      newVehicles[this.state.vehicleIndex].mileage = parseInt(
        this.state.mileage,
        10,
      );

      //convert moment date to javascript date object
      var dateConverted = new Date(this.state.lastServiceDate);

      newVehicles[
        this.state.vehicleIndex
      ].lastServiceDate = Dates.formateDateForApi(dateConverted);

      var me = this;
      SaveData.saveVehicle(
        newVehicles,
        function() {
          me.setState(
            {
              saveFailed: false,
              saveSuccess: true,
              isLoading: false,
            },
            () => {
              Store.set('vehicles', newVehicles);
            },
          );
        },
        function() {
          me.setState({
            saveSuccess: false,
            saveFailed: true,
            isLoading: false,
          });
        },
      );
    }
  };

  validateDate = (value) => {
    const valueDate =
      value != null && typeof value != 'string'
        ? Dates.formatDate(value.toDate())
        : value;

    var isValid = /^((([012]?\d|30)\/(0?[469]|11)|([012]?\d|3[01])\/(0?[13578]|1[02])|([01]?\d|2[0-8])\/0?2)\/(20|19)\d{2})?$/.test(
      valueDate,
    );

    if (!isValid) {
      return 'Invalid date';
    }

    var date = Dates.parseDate(valueDate);
    isValid = date <= Dates.getToday();

    if (!isValid) {
      return 'Date cannot be in the future';
    }
    return null;
  };

  validateMileage = (value) => {
    var isValid = /^(\d+)?$/.test(value);
    if (!isValid) {
      return 'Invalid number';
    }
    isValid = value.length != 0;

    if (!isValid) {
      return 'Required';
    }
    return null;
  };

  handleDateChange = (value) => {
    const valid = this.validateDate(value);
    this.setState({
      validationService: valid,
      lastServiceDate: value,
    });
  };

  handleDateChangeRaw = (event) => {
    const target = event.target;
    const value = target.value;
    const valid = this.validateDate(value);
    this.setState({
      validationService: valid,
    });
  };

  handleMileageChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const validateMileage = this.validateMileage(value);
    this.setState({
      [name]: value,
      validationMileage: validateMileage,
    });
  };

  validateField(name) {
    this.setState((prevState) => {
      return Validation.validate(prevState, name);
    });
  }

  render() {
    const vehicle = this.state.vehicle;

    return (
      <div className="col ml-lg-4 p-sm-4 border-lg-left">
        <h4 className="page-inner-title">Your Hyundai {vehicle.model}</h4>
        <form>
          <div className="form-row">
            <div className="form-group col-md-6  pr-md-3">
              <small>
                <label htmlFor="inpRegistration">Registration</label>
              </small>
              <input
                type="text"
                className="form-control form-control-sm removeRadius flatInput"
                id="inpRegistration"
                readOnly
                value={vehicle.registration}
              />
            </div>
            <div className="form-group col-md-6">
              <small>
                <label htmlFor="inpMake">Make</label>
              </small>
              <input
                type="text"
                className="form-control form-control-sm removeRadius flatInput"
                id="inpMake"
                readOnly
                value={vehicle.make}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-6  pr-md-3">
              <small>
                <label htmlFor="inpTransmission">Transmission</label>
              </small>
              <input
                type="text"
                className="form-control form-control-sm removeRadius flatInput"
                id="inpTransmission"
                readOnly
                value={vehicle.transmission}
              />
            </div>
            <div className="form-group col-md-6">
              <small>
                <label htmlFor="inpModel">Model</label>
              </small>
              <input
                type="text"
                className="form-control form-control-sm removeRadius flatInput"
                id="inpModel"
                readOnly
                value={vehicle.model}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-6  pr-md-3">
              <small>
                <label htmlFor="inpBodyType">Body type</label>
              </small>
              <input
                type="text"
                className="form-control form-control-sm removeRadius flatInput"
                id="inpBodyType"
                readOnly
                value={vehicle.bodyType}
              />
            </div>
            <div className="form-group col-md-6">
              <small>
                <label htmlFor="inpVin">VIN</label>
              </small>
              <input
                type="text"
                className="form-control form-control-sm removeRadius flatInput"
                id="inpVin"
                readOnly
                value={vehicle.vin}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-6  pr-md-3">
              <small>
                <label htmlFor="inpFuelType">Fuel type</label>
              </small>
              <input
                type="text"
                className="form-control form-control-sm removeRadius flatInput"
                id="inpFuelType"
                readOnly
                value={vehicle.fuelType}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col  pr-md-3">
              <small>
                <label htmlFor="inpFuelType" className="mt-3">
                  If this information is incorrect, let us know at:{' '}
                  <a href="mailto:myhyundaienquiries@hyundai.co.uk">
                    myhyundaienquiries@hyundai.co.uk
                  </a>
                </label>
              </small>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group mt-3 col pr-md-3">
              <small>
                <label htmlFor="inpFuelType">
                  <strong>Please update the following:</strong>
                </label>
              </small>
            </div>
          </div>

          <div className="form-row">
            {/* ======================================== Mileage ======================================== */}
            <div className="form-group col-md-6  pr-md-3">
              <small>
                <label htmlFor="inpCurrentMileage">
                  My current mileage is *
                </label>
              </small>
              <InputField
                name="mileage"
                type="number"
                id="inpCurrentMileage"
                value={this.state.mileage}
                onChange={this.handleMileageChange}
              />

              <div
                className={
                  'form-control d-none ' +
                  (this.state.validationMileage ? 'is-invalid' : '')
                }
              />
              <div className="invalid-feedback">
                <i className="fas fa-exclamation-triangle" />{' '}
                {this.state.validationMileage}
              </div>
            </div>

            {/* ========================================== Date ========================================= */}
            <div className="form-group col-md-6">
              <small>
                <label htmlFor="inpLastServiceDate">
                  My last service was on
                </label>
              </small>
              <div className={'theme-style-date-form-control '}>
                <DatePicker
                  className={
                    'form-control form-control-sm flatInput ' +
                    (this.state.validationService ? 'is-invalid' : '')
                  }
                  placeholderText="dd/mm/yyyy"
                  dateFormat="DD/MM/YYYY"
                  selected={this.state.lastServiceDate}
                  onChange={this.handleDateChange}
                  onChangeRaw={this.handleDateChangeRaw}
                  readOnly={true}
                />
                <div
                  className={
                    'form-control d-none ' +
                    (this.state.validationService ? 'is-invalid' : '')
                  }
                />
                <div className="invalid-feedback">
                  <i className="fas fa-exclamation-triangle" />{' '}
                  {this.state.validationService}
                </div>
              </div>
            </div>
          </div>

          <div className="form-group" />

          <div className="form-row">
            <div className="form-group col-md-6  pr-md-3" />
            <div className="form-group col-md-6">
              <button
                type="button"
                className=" btn btn-block btn-primary btn-sm removeRadius"
                onClick={this.saveVehicleDetails}
              >
                {this.state.isLoading ? (
                  <div>
                    <i className="fas fa-spinner fa-spin fa-lg text-white" />
                  </div>
                ) : (
                  <span>Save details</span>
                )}
              </button>

              {this.state.saveSuccess && (
                <div className="text-center text-success">
                  <p />
                  <small>
                    <i className="fas fa-check-circle" /> Changes have been
                    saved
                  </small>
                </div>
              )}
              {this.state.saveFailed && (
                <div className="text-center text-danger">
                  <p />
                  <small>
                    <i className="fas fa-exclamation-triangle" /> Error in
                    saving changes
                  </small>
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
    );
  }
}
