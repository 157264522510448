import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radio from './FeedBackRadio';

export default class FeedBackRadioGroup extends Component {
  static propTypes = {
    //groups in group Radio
    radioGroupName: PropTypes.string,
    radioOptions: PropTypes.array.isRequired,
    radioGroupTitle: PropTypes.string,
    //
    radioTitle: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  };

  render() {
    const {
      radioGroupName,
      radioOptions,
      radioGroupTitle,
      radioTitle,
      onChange,
    } = this.props;

    //Splits on uppercase and whitespace
    const nameSplit = (radioGroupTitle || radioTitle)
      .split(/(?=[A-Z])+|[ ]/)
      .join('_')
      .toLowerCase();

    return (
      <div className="mb-2" name={radioGroupName}>
        {radioOptions.map((option, i) => {
          return (
            <Radio
              key={i}
              id={i + '_' + nameSplit}
              name={nameSplit}
              value={option}
              number={(i + 1).toString()}
              onChange={onChange}
            />
          );
        })}
        {radioGroupTitle}
      </div>
    );
  }
}
