import React, { Component } from "react";

export default class NewGeni30Tourer extends Component {
  render() {
    return (
      <div className="container">
        <div className="fadeInDownBigFull">
          <div className="card-body newsPadding">
            <div className="card-deck " id="NewGeni30Tourer">
              <div className="card">
                <div className="row">
                  <div className="col-md">
                    <img
                      id="NewGeni30Tourer-gallery-1"
                      className="card-img-top img-fluid news-gallary-image active"
                      src="images/ngi30Tourer.jpg"
                      alt="Card image cap"
                    />
                  </div>
                </div>

                <div className="card-block">
                  <div className="card-content">
                    <div className="col">
                      <div className="row whiteBG">
                        <div className="col-lg-4">
                          <div className="d-flex flex-row justify-content-between flex-wrap" />
                          <div className="salesText mt-3">
                            <p className="theme-news-snippet">
                              “Timeless design of i30 is enhanced by versatile
                              and elegant form of wagon”
                            </p>
                          </div>
                          <hr />
                          <div
                            className="d-flex justify-content-around mb-2 largerSI social-share-buttons"
                            data-article-id="NewGeni30Tourer"
                            data-article-text="New Gen i30 Tourer"
                          />
                        </div>

                        <div className="col-lg-8 border-lg-left">
                          <div className="row pt-3">
                            <div className="col-md-10 offset-md-1">
                              <p className="salesText" data-search-tag="title">
                                NEW GENERATION HYUNDAI i30 TOURER: ELEGANCE
                                MEETS VERSATILITY
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <div className="theme-news-body-text">
                                <ul>
                                  <li>
                                    New Tourer body style will be the next
                                    member in the i30 family, following launch
                                    of five-door
                                  </li>
                                  <li>
                                    Timeless design of i30 is enhanced by
                                    versatile and elegant form of wagon
                                  </li>
                                  <li>
                                    The New Generation i30 Tourer will celebrate
                                    its world premiere at Geneva International
                                    Motor Show 2017 during the Hyundai press
                                    conference (8:15 am CET, 7 March 2017)
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div className="row pt-3">
                            <div className="col-md-10 offset-md-1">
                              <p
                                className="theme-news-body-text"
                                data-search-tag="summary"
                              >
                                <strong>High Wycombe, 7 March 2017</strong> –
                                This year’s Geneva International Motor Show is
                                the stage for the unveiling of the New
                                Generation Hyundai i30 Tourer. The new member of
                                the i30 family maintains the timeless design of
                                its five-door sibling, adding elegance and
                                versatility in its body style.
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                “Designed, developed, tested and manufactured in
                                Europe, the New Generation Hyundai i30 is
                                Hyundai Motor’s DNA car in Europe,” says Thomas
                                A. Schmid, Chief Operating Officer at Hyundai
                                Motor Europe. “The i30 is more than just a model
                                – it is a family of cars, consisting of four
                                body types with unique characters united by a
                                coherent, timeless design. With the new i30
                                Tourer we are now introducing the next body
                                type, offering elegance and versatility for
                                everyone.”
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                “The New Generation i30 Tourer speaks the
                                language of the i30 family in terms of design,
                                and it carries the new Hyundai family identity –
                                the Cascading Grille,” explains Thomas Bürkle,
                                Chief Designer at Hyundai Design Centre Europe.
                                “The tapering roofline and the dynamic
                                proportions give the Tourer an almost coupé-like
                                silhouette. The sleek and dynamic profile is
                                further emphasised by a rich chrome frame
                                surrounding the side windows.”{" "}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                In combination with the full-LED headlamps and
                                the vertical LED daytime running lights, the New
                                Generation i30 Tourer has a strong visual
                                presence.{" "}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                High versatility with one of the biggest boots
                                in the segment{" "}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                The i30 Tourer’s sleek design does not
                                compromise boot space: with 602 litres of space
                                (VDA 211) and 1,650 litres (VDA 214) with the
                                seats folded, it has one of the biggest boots in
                                the segment. Versatility is further enhanced by
                                the additional storage space beneath the boot
                                floor with several compartments.{" "}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                The overall length of the Tourer is 4,585 mm
                                (5-door version: 4,340 mm), the height is 1,465
                                mm (1,475 mm with roof rack). The overall width
                                of 1,795 mm and wheelbase of 2,650 mm are the
                                same as the five-door version.{" "}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <p className="theme-news-body-text">
                                Best-in-class standard safety package{" "}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-10 offset-md-1">
                              <div className="theme-news-body-text">
                                <p>
                                  Hyundai Motor’s latest technology allows the
                                  cars to navigate the most challenging
                                  situations safely, including high levels of
                                  pedestrian traffic, stop lights and signs,
                                  road construction and road blocks, speed
                                  bumps, dogs, small children and even
                                  intersections without traffic signals. Through
                                  extensive testing in urban environments
                                  Hyundai Motor is continuing to develop and
                                  refine its self-driving technologies with the
                                  goal of using less computing power, resulting
                                  in a low-cost platform that the typical
                                  consumer can afford.{" "}
                                </p>
                                <p>
                                  Hyundai Motor democratises technology by
                                  offering latest active safety and connectivity
                                  features for the New Generation i30 Tourer.
                                  The i30 offers best-in-class standard active
                                  safety with Autonomous Emergency Braking,
                                  Driver Attention Alert, High Beam Assist and
                                  Lane Keeping Assist System always on board.
                                </p>
                                <p>
                                  Using camera sensors, the Autonomous Emergency
                                  Braking (AEB) fitted in the i30 operates in
                                  three stages. Initially warning the driver
                                  visually and acoustically, it controls the
                                  brake according to the collision danger stage,
                                  and applies maximum braking force to avoid a
                                  collision or minimise damage when a collision
                                  is unavoidable. The optional pedestrian
                                  recognition uses a front radar and avoids or
                                  mitigates the consequence of an impact with a
                                  pedestrian.
                                </p>
                                <p>
                                  Driver Attention Alert (DAA) is a driver
                                  protection feature which helps monitor driving
                                  patterns in order to detect fatigued driving
                                  and prevent potential accidents.
                                </p>
                                <p>
                                  Advanced Smart Cruise Control (ASCC) keeps a
                                  constant speed and distance from the vehicle
                                  ahead by automatically accelerating and
                                  braking up to 180 km/h. If the traffic comes
                                  to a halt, the system applies the brake until
                                  the car comes to a standstill and accelerates
                                  to the desired speed as soon as the road is
                                  clear.
                                </p>
                                <p>
                                  The Blind Spot Detector (BSD) with Lane Change
                                  Assist monitors the rear corners and, if
                                  another vehicle is detected, a visual alert
                                  appears on the exterior mirrors.
                                </p>
                                <p>
                                  The Rear-Cross Traffic Alert (RCTA) system
                                  reduces the risk of collision with approaching
                                  traffic when reversing out of narrow areas
                                  with low visibility by alerting the driver
                                  visually and acoustically.
                                </p>
                                <p>
                                  The Lane Keeping Assist System (LKAS) alerts
                                  the driver of unsafe movements at over 60 km/h
                                  by sensing the car’s position. It warns the
                                  driver before inducing corrective steering to
                                  guide the driver back to a safe position.
                                </p>
                                <p>
                                  The Speed Limit Information Function (SLIF)
                                  identifies road speed signs and displays the
                                  speed limit in real time. The information is
                                  shown both in the navigation system display as
                                  well as in the TFT cluster.
                                </p>
                                <p>
                                  A High Beam Assist (HBA) detects both oncoming
                                  vehicles and vehicles in the same lane ahead
                                  at night and changes to low-beam as
                                  appropriate, reducing blinding effects on
                                  other drivers. Whenever there are no vehicles
                                  detected HBA automatically re-activates the
                                  high beams, maximising the driver’s range of
                                  vision.
                                </p>
                                <p>Smart and connected</p>
                                <p>
                                  The i30 Tourer offers customers a spacious and
                                  elegant interior with a horizontal layout and
                                  free floating screen for the infotainment
                                  system. The eight-inch touch screen of the new
                                  generation navigation system offers
                                  state-of-the-art connectivity features such as
                                  Apple CarPlay, Android Auto and LIVE Services.
                                  A wireless charging system for smartphones
                                  with the Qi standard is available as well. As
                                  an alternative, customers can choose between
                                  different audio options, including the audio
                                  system with a five-inch capacitive LCD touch
                                  screen with integrated dynamic rear-view
                                  camera, Bluetooth connectivity and My Music
                                  functionality.
                                </p>
                                <p>Dynamic and efficient powertrains</p>
                                <p>
                                  The powertrain line-up of the i30 Tourer
                                  consists of downsized turbocharged engines to
                                  enhance efficiency and responsiveness
                                  (preliminary fuel consumption in l/100 km for
                                  the Hyundai i30 Tourer range: combined 5.8 –
                                  3.6 l/100km, CO2 Emissions 135– 95 g/km*).
                                  Customers can choose from two petrol engines
                                  and one 1.6-litre turbo diesel engine with
                                  110PS. The Tourer is available with Hyundai’s
                                  all-new turbocharged four-cylinder petrol
                                  engine, the 1.4 T-GDI delivers maximum power
                                  of 140 PS and the new three-cylinder 1.0 T-GDI
                                  that delivers maximum power of 120 PS.
                                </p>
                                <p>Driving dynamics</p>
                                <p>
                                  The engineers of Hyundai Motor Europe’s
                                  Technical Centre in Germany enhanced the
                                  driving dynamics of the New Generation i30 in
                                  a rigorous testing programme throughout Europe
                                  and on the famous Nürburgring Nordschleife.
                                  The highly rigid and light body with 53 %
                                  Advanced High Strength Steel provides the
                                  basis for good ride and handling
                                  characteristics, and the fact that it is
                                  combined with 10 % more direct steering and a
                                  newly developed chassis means the driver can
                                  enjoy dynamic driving together with high
                                  levels of comfort.
                                </p>
                                <p>Made in Europe</p>
                                <p>
                                  Designed in Hyundai Motor Europe’s Technical
                                  Centre in Rüsselsheim, produced in Nošovice,
                                  Czech Republic, and tested on the Nürburgring,
                                  the third generation i30 family is built on
                                  the infrastructure that Hyundai Motor has
                                  developed over the last 25 years in Europe. It
                                  aims to continue the success of the two
                                  previous generations of i30, which have sold
                                  more than 800,000 cars in Europe since 2008.
                                </p>
                                <p>
                                  *Official combined cycle test –All values are
                                  tentative and not homologated yet, hence
                                  subject to changes.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
