import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import './personal.css';
import FooterNavbar from '../components/footerNavbar';
import Store from 'store';
import Topnavbar from '../components/topnavbar';
import Bottomnavbar from '../components/bottomnavbar';
import Footer from '../components/footer';
import ProfileNav from '../components/profileNavbar';
import GoogleMap from '../components/dealerDetails/googleMap';
import Dealers from '../utils/Dealers';
import SaveData from '../utils/SaveData';

export default class DealerDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      locationSearch: '',
      dealers: [],
      selectedDealer: null,
      saveSuccess: false,
      saveFailed: false,
      searchDealer: null,
      displayDealer: null,
      mapDealer: null,
      mapLocationSearch: '',
      customer: Store.get('customerInfo'),
    };
  }

  handleLocationInputChange = (val) => {
    this.setState({
      locationSearch: val.target.value,
      searchDealer: null,
    });
  };

  handlePreferedDealerChange = (event) => {
    var dealer = this.findDealer(this.state.dealers, event.target.value);
    this.setState(
      {
        selectedDealer: dealer,
        mapDealer: dealer,
        saveSuccess: false,
        saveFailed: false,
        locationSearch: '',
        mapLocationSearch: '',
        searchDealer: null,
        displayDealer: null,
      },
      this.saveCustomer,
    );
  };

  handleSearchDealerChange = (event) => {
    var dealer = this.findDealer(this.state.dealers, event.target.value);
    this.setState({
      searchDealer: dealer,
      locationSearch: '',
    });
  };

  saveCustomer() {
    const customer = this.state.customer;
    customer.dealer = this.state.selectedDealer;

    var me = this;
    SaveData.saveCustomer(
      JSON.stringify(customer),
      () => {
        Store.set('customerInfo', customer);
        me.setState({ saveSuccess: true });
      },
      () => {
        me.setState({ saveFailed: true });
      },
    );
  }

  handleEnterClick = () => {
    this.setState((prevState) => {
      if (prevState.locationSearch.length !== 0) {
        return {
          mapDealer: null,
          mapLocationSearch: prevState.locationSearch,
        };
      }

      return {
        mapDealer: prevState.searchDealer,
        displayDealer: prevState.searchDealer,
        mapLocationSearch: '',
      };
    });
  };

  handleDealerSearchResultFound = (dealer) => {
    this.setState({
      displayDealer: dealer,
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    Dealers.load(this.handleDealersLoaded);
  }

  handleDealersLoaded = () => {
    var selectedDealer = null;
    const customer = this.state.customer;
    if (customer) {
      if (customer.dealer) {
        selectedDealer = this.findDealer(Dealers.list, customer.dealer.code);
      }
    }

    this.setState({
      dealers: Dealers.list,
      selectedDealer: selectedDealer,
      mapDealer: selectedDealer,
    });
  };

  findDealer(dealers, code) {
    if (dealers != null && dealers.length !== 0) {
      var dealer;
      for (var i = 0; i < dealers.length; i++) {
        if (dealers[i].code === code) {
          dealer = dealers[i];
          break;
        }
      }
      if (dealer && dealer.length !== 0) return dealer;
      else return null;
    } else {
      return null;
    }
  }

  render() {
    const { customer } = this.state;
    const dealerList = this.state.dealers.map((dealer, i) => (
      <option key={i} value={dealer.code}>
        {dealer.name}
      </option>
    ));

    const selectedDealer = this.state.selectedDealer;
    const selectedDealerCode = selectedDealer ? selectedDealer.code : '';

    const searchDealer = this.state.searchDealer;
    const searchDealerCode = searchDealer ? searchDealer.code : '';

    const displayDealer = this.state.displayDealer;

    // if Prospect
    if (customer.customerType === 2) {
      return <Redirect to="/dealerDetailsPortal" />;
    }

    return (
      <div>
        <Topnavbar />
        <Bottomnavbar />

        <div className="container">
          <ProfileNav />

          <section
            className="section1 wow fadeIn"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <div className="content p-3 col-md-10 offset-md-1 border-right border-bottom">
              <div className="row">
                <div className="col-md-4 p-3">
                  <h4 className="h4ServHistory" data-search-tag="title">
                    Retailer details
                  </h4>

                  <div className="form-group">
                    <small>
                      <label htmlFor="inpStillOwned">Preferred retailer</label>
                    </small>
                    <select
                      id="inpStillOwned"
                      className="form-control form-control-sm flatInput"
                      value={selectedDealerCode}
                      onChange={this.handlePreferedDealerChange}
                    >
                      <option value="" selected disabled hidden>
                        {' '}
                        -- Please Select --{' '}
                      </option>
                      {dealerList}
                    </select>
                    {this.state.saveSuccess && (
                      <div className="text-center text-success">
                        <p />
                        <small>
                          <i className="fas fa-check-circle" /> Changes have
                          been saved
                        </small>
                      </div>
                    )}
                    {this.state.saveFailed && (
                      <div className="text-center text-danger">
                        <p />
                        <small>
                          <i className="fas fa-exclamation-triangle" /> Error in
                          saving changes
                        </small>
                      </div>
                    )}
                  </div>
                  {selectedDealer != null && selectedDealer.extra != null && (
                    <div>
                      <p>
                        <small> Opening times:</small>
                      </p>
                      <table className="table table-sm">
                        <tbody>
                          <tr>
                            <td>
                              <small>Monday to Friday</small>
                            </td>
                            <td>
                              <small>
                                {selectedDealer.extra.mondayOpeningTimes}
                              </small>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <small>Saturday</small>
                            </td>
                            <td>
                              <small>
                                {selectedDealer.extra.saturdayOpeningTimes}
                              </small>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <small>Sunday</small>
                            </td>
                            <td>
                              <small>
                                {selectedDealer.extra.sundayOpeningTimes}
                              </small>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <table className="table table-sm">
                        <tbody>
                          <tr>
                            {selectedDealer.extra.hasSales && (
                              <td>
                                <small>
                                  <i className="fas fa-car" /> Sales
                                </small>
                              </td>
                            )}
                            {selectedDealer.extra.hasAftersales && (
                              <td>
                                <small>
                                  <i className="fas fa-cogs" /> Service
                                </small>
                              </td>
                            )}
                          </tr>
                          <tr>
                            {selectedDealer.extra.hasMobility && (
                              <td>
                                <small>
                                  <i className="fas fa-dot-circle" /> Motability
                                </small>
                              </td>
                            )}
                            {selectedDealer.extra.hasUsedCars && (
                              <td>
                                <small>
                                  <i className="fas fa-key" /> Approved used
                                </small>
                              </td>
                            )}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}

                  <div className="form-group">
                    <h4 className="h4ServHistory">Retailer locator</h4>
                    <small>
                      <label htmlFor="inpStillOwn">
                        <strong>Your nearest retailer.</strong>{' '}
                        <span data-search-tag="summary">
                          Enter your city, postcode or retailer name to find the
                          nearest retailer to you.
                        </span>
                      </label>
                    </small>
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="Postcode / Town / City"
                        className="flatInput form-control form-control-sm"
                        id="inpTxtDealerSearch"
                        value={this.state.locationSearch}
                        onChange={this.handleLocationInputChange}
                      />
                    </div>
                    <strong>Or</strong>
                    <select
                      id="inpStillOwn"
                      className="form-control form-control-sm flatInput"
                      value={searchDealerCode}
                      onChange={this.handleSearchDealerChange}
                    >
                      <option value="">-</option>
                      {dealerList}
                    </select>
                  </div>
                  <button
                    type="button"
                    className="btn btn-primary btn-sm removeRadius"
                    onClick={this.handleEnterClick}
                  >
                    Enter
                  </button>
                </div>

                <div
                  className="col-md-8 mb-4"
                  style={{
                    minHeight: '350px',
                    height: '100%',
                    marginTop: '17%',
                  }}
                >
                  {(this.state.mapDealer != null ||
                    this.state.mapLocationSearch !== '') && (
                    <GoogleMap
                      centre={{ lat: 52.83, lng: -1.26 }}
                      dealer={this.state.mapDealer}
                      locationSearch={this.state.mapLocationSearch}
                      dealers={this.state.dealers}
                      onDealerSearchResultFound={
                        this.handleDealerSearchResultFound
                      }
                    />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-4" />
                <div className="col-md-8">
                  {displayDealer != null && displayDealer.extra != null && (
                    <div>
                      <h5>{displayDealer.name}</h5>
                      <p>
                        <small> Opening times:</small>
                      </p>
                      <table className="table table-sm">
                        <tbody>
                          <tr>
                            <td>
                              <small>Monday to Friday</small>
                            </td>
                            <td>
                              <small>
                                {displayDealer.extra.mondayOpeningTimes}
                              </small>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <small>Saturday</small>
                            </td>
                            <td>
                              <small>
                                {displayDealer.extra.saturdayOpeningTimes}
                              </small>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <small>Sunday</small>
                            </td>
                            <td>
                              <small>
                                {displayDealer.extra.sundayOpeningTimes}
                              </small>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <table className="table table-sm">
                        <tbody>
                          <tr>
                            {displayDealer.extra.hasSales && (
                              <td>
                                <small>
                                  <i className="fas fa-car" /> Sales
                                </small>
                              </td>
                            )}
                            {displayDealer.extra.hasAftersales && (
                              <td>
                                <small>
                                  <i className="fas fa-cogs" /> Service
                                </small>
                              </td>
                            )}
                          </tr>
                          <tr>
                            {displayDealer.extra.hasMobility && (
                              <td>
                                <small>
                                  <i className="fas fa-dot-circle" /> Motability
                                </small>
                              </td>
                            )}
                            {displayDealer.extra.hasUsedCars && (
                              <td>
                                <small>
                                  <i className="fas fa-key" /> Approved used
                                </small>
                              </td>
                            )}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>

          <FooterNavbar />
        </div>

        <Footer />
      </div>
    );
  }
}
