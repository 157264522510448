import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';

export default class WhatCar extends Component {
  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="WhatCar" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="whatCar-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/whatcar1.jpg") ',
              }}
            />
            <div
              id="whatCar-gallery-2"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/whatcar2.jpg")',
              }}
            />
            <div
              id="whatCar-gallery-3"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/whatcar3.jpg")',
              }}
            />
            <div
              id="whatCar-gallery-4"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/whatcar4.jpg")',
              }}
            />
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#whatCar-gallery-1"
                    style={{
                      backgroundImage: 'url("images/whatcar1.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#whatCar-gallery-2"
                    style={{
                      backgroundImage: 'url("images/whatcar2.jpg")',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#whatCar-gallery-3"
                    style={{
                      backgroundImage: 'url("images/whatcar3.jpg")',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail mt-3 ">
                  <a
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#whatCar-gallery-4"
                    style={{
                      backgroundImage: 'url("images/whatcar4.jpg")',
                    }}
                  />
                </div>
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “What could possibly be better than scooping three awards at
                  last night’s prestigious What Car? ”
                </p>
              </div>
              <hr />

              <NewsMedia
                articleId="whatCar"
                articleText="A four to be reckoned with at What Car? Awards"
              />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    A FOUR TO BE RECKONED WITH AT WHAT CAR? AWARDS
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    {' '}
                    What could possibly be better than scooping three awards at
                    last night’s prestigious What Car? Car of the Year Awards?
                    How about winning four awards!
                  </p>
                  {displayShowMore && (
                    <p>
                      <a
                        data-toggle="collapse"
                        href="#whatCar1"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                  {/* <p className="theme-news-body-text">The i30 Fastback’s sloping roofline, long bonnet and muscular body, along with its slim cabin and wide wheel arches give the car a strong stance. That strength, however, is far from being merely skin deep.</p> */}
                </div>
              </div>

              <div className={moreSectionClassName} id="whatCar1">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      The famous magazine’s annual awards ceremony, which this
                      year was held in central London on Tuesday 22 January, is
                      one of the highlights of the year for the car world, and
                      the winner’s spotlight was trained on Hyundai’s logo more
                      than most during the evening.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      Hyundai picked up four awards at the event, including
                      three for its innovation in alternative-fuelled vehicles.
                      The What Car? experts handed the coveted Technology Award
                      to Hyundai for its pioneering work in battery electric and
                      hydrogen fuel cell vehicles. Meanwhile, IONIQ was named
                      Hybrid Car of the Year, IONIQ Plug-in Hybrid Premium won
                      Best Plug-in Hybrid for less than £30,000, and i30 N won
                      the Best Hot Hatch for £22,000-£27,000 title.{' '}
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      “In a very short space of time, Hyundai has carved itself
                      a reputation for class-leading hybrid, electric and even
                      hydrogen fuel cell vehicles, making it a worthy winner of
                      both our Technology Award and our Hybrid Car of the Year
                      title,” said What Car? Editor Steve Huntingford. “But its
                      work at the opposite end of the spectrum can’t be ignored;
                      the i30 N entered the hot hatch market with a bang,
                      bringing with it impeccable driving dynamics and
                      incredible value for money.”{' '}
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      Winning the Technology Award highlights the fact that
                      Hyundai is unique amongst manufacturers in that its model
                      range now includes petrol, diesel, hybrid, plug-in hybrid,
                      fully electric and, with the recent launch of NEXO,
                      hydrogen fuel cell vehicles.{' '}
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      “Hyundai won not only for building the electric vehicle
                      with the greatest real-world range we’ve tested so far in
                      Kona Electric, but also for leading the way in developing
                      the hydrogen fuel cell technology that could one day offer
                      car buyers greater electric range without the hassle of
                      recharging a battery,” said the What Car? team.{' '}
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      The magazine’s panel of experts also awarded IONIQ Hybrid
                      the Hybrid Car of the Year title for the second year
                      running, because it “presented the most polished all-round
                      package”, while IONIQ Plug-in Hybrid was named Best
                      Plug-in Hybrid for less than £30,000. “We think the
                      regular IONIQ is the best hybrid car you can buy, but it’s
                      also among the very best plug-in hybrids at any price,”
                      said the judges.{' '}
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      Hyundai’s fourth award of the evening went to the i30 N,
                      which won Best hot hatch for £22,000-£27,000. “Hyundai’s
                      first hot hatch is great fun to drive and comes bristling
                      with so much kit that you don’t need to spend a penny on
                      extras. It’s a hot hatch which pleases both head and
                      heart,” said the panel of experts.{' '}
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      Ashley Andrew, Hyundai Motor UK Managing Director, was
                      more than happy with Hyundai’s haul. “It’s an absolute
                      pleasure to see Hyundai’s innovative work in powertrains
                      rewarded by the most trusted name in automotive consumer
                      publishing. Our electric and hybrid technology is entering
                      the mainstream at an incredible pace, and our commitment
                      to reducing emissions across our range will not stop there
                      as 18 new electrified models are planned by 2025. ”
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
