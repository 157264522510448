import React, { Component } from 'react';
import NewsMedia from '../../newsMedia';

export default class IronMan extends Component {
  render() {
    const displayShowMore = this.props.displayShowMore;
    const moreSectionClassName = displayShowMore ? 'collapse' : '';

    return (
      <div id="IronMan" className="col">
        <div className="row">
          <div className="col-md">
            <div
              id="iron-gallery-1"
              className="theme-news-header-image active"
              style={{
                backgroundImage: 'url("images/iron1.jpg") ',
              }}
            />
            <div
              id="iron-gallery-2"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/iron2.jpg")',
              }}
            />
            <div
              id="iron-gallery-3"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/iron3.jpg")',
              }}
            />
            <div
              id="iron-gallery-4"
              className="theme-news-header-image"
              style={{
                backgroundImage: 'url("images/iron4.jpg")',
              }}
            />
          </div>
        </div>

        <div className="col border-right border-bottom">
          <div className="row bg-white py-3">
            <div className="col-lg-4">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a  
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#iron-gallery-1"
                    style={{
                      backgroundImage: 'url("images/iron1.jpg") ',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a  
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#iron-gallery-2"
                    style={{
                      backgroundImage: 'url("images/iron2.jpg")',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail">
                  <a  
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#iron-gallery-3"
                    style={{
                      backgroundImage: 'url("images/iron3.jpg")',
                    }}
                  />
                </div>
                <div className="theme-news-thumbnail-container img-thumbnail mt-3">
                  <a  
                    className="d-block theme-news-thumbnail"
                    data-hero-image="#iron-gallery-4"
                    style={{
                      backgroundImage: 'url("images/iron4.jpg")',
                    }}
                  />
                </div>
              </div>
              <div className="salesText mt-3">
                <p className="theme-news-snippet">
                  “Hyundai’s limited edition collaboration with Marvel does have
                  exterior and interior design elements that give a nod to the
                  dynamic Iron Man suit”
                </p>
              </div>
              <hr />

              <NewsMedia
                articleId="ironMan"
                articleText="New Kona Iron Man Edition is fit for a superhero"
              />
            </div>

            <div className="col-lg-8 border-lg-left">
              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="salesText" data-search-tag="title">
                    NEW KONA IRON MAN EDITION IS FIT FOR A SUPERHERO
                  </p>
                </div>
              </div>

              <div className="row pt-3">
                <div className="col-md-10 offset-md-1">
                  <p className="theme-news-body-text" data-search-tag="summary">
                    {' '}
                    When brainy billionaire Tony Stark wants a break from flying
                    about in his rocket suit, saving the planet from villainy,
                    he’d probably enjoy a nice spin in the Kona Iron Man
                    Edition.
                  </p>
                  {displayShowMore && (
                    <p>
                      <a  
                        data-toggle="collapse"
                        href="#ironMan"
                        role="button"
                        aria-expanded="false"
                      >
                        More{' '}
                        <span>
                          <i className="fas fa-long-arrow-alt-down" />
                        </span>
                      </a>
                    </p>
                  )}
                  {/* <p className="theme-news-body-text">The i30 Fastback’s sloping roofline, long bonnet and muscular body, along with its slim cabin and wide wheel arches give the car a strong stance. That strength, however, is far from being merely skin deep.</p> */}
                </div>
              </div>

              <div className={moreSectionClassName} id="ironMan">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      OK, so it hasn’t got jet packs, but Hyundai’s limited
                      edition collaboration with Marvel does have exterior and
                      interior design elements that give a nod to the dynamic
                      Iron Man suit. The Iron Man mask features on the roof,
                      bonnet, and wheels, while the matte grey and ‘red armour’
                      paintwork, bespoke Iron-grip dual-tone 18” alloy wheels,
                      and specially redesigned bonnet mark this out as a car fit
                      for a superhero.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      Inside, Tony Stark’s signature adorns the instrument panel
                      alongside an Iron Man gear knob and custom-designed
                      leather seats, which are embossed with the STARK
                      Industries logo and Iron Man image on the backrests. Start
                      the car and the KRELL Premium audio system and supervision
                      instrument cluster both display the Iron Man eyes during
                      system check. The instrument cluster and tachometer also
                      feature the STARK Industries Arc Reactor as the background
                      graphics.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      “The Kona range has always had a strong identity with its
                      expressive styling and choice of vibrant exterior
                      colours,” says Ashley Andrew, Managing Director, Hyundai
                      Motor UK. “Kona Iron Man Edition takes this further,
                      standing out even more with its Iron Man inspired looks
                      and equipment. With only 300 cars coming to the UK, this
                      will be a unique ownership experience, combining Kona’s
                      style, technology and performance with a slice of
                      superhero fun!”
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      As well as a boot-load of bespoke equipment, the Kona Iron
                      Man Edition also shares equipment with the rest of the
                      Kona range, including automatic LED head and tail lights,
                      Keyless Entry with Engine Start/Stop Button, 8”
                      Touchscreen Satellite Navigation with standard Android
                      Auto™ and Apple Car Play™, air conditioning with climate
                      control and driver’s side head-up display. Safety
                      equipment includes autonomous emergency braking, blind
                      spot detection, downhill brake control, driver attention
                      alert, lane keep assist and rear cross traffic alert
                      fitted as standard.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      “We hope that everyone has just as much fun jumping into
                      this one-of-a-kind vehicle as they would putting on the
                      actual Iron Man suit of armour!” said Mindy Hamilton,
                      Marvel’s Senior Vice President of Global Partnerships
                      &amp; Marketing. We’re heading straight to Stark
                      Industries to find out what Tony thinks…
                    </p>
                  </div>
                </div>

                {/* <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      KONA Iron Man Edition, which costs £27,995, is available with a 1.6 T-GDi engine and comes in Matte Grey with Red Armour roof and contrasts. For more on this, and the entire KONA range, visit www.hyundai.co.uk/new-cars/kona
                    </p>
                  </div>
                </div> */}

                {/* <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      The magazine’s panel of experts also awarded IONIQ Hybrid the Hybrid Car of the Year title for the second year running, because it “presented the most polished all-round package”, while IONIQ Plug-in Hybrid was named Best Plug-in Hybrid for less than £30,000. “We think the regular IONIQ is the best hybrid car you can buy, but it’s also among the very best plug-in hybrids at any price,” said the judges.{' '}
                    </p>
                  </div>
                </div> */}

                {/* <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      {' '}
                      Hyundai’s fourth award of the evening went to the i30 N, which won Best hot hatch for £22,000-£27,000. “Hyundai’s first hot hatch is great fun to drive and comes bristling with so much kit that you don’t need to spend a penny on extras. It’s a hot hatch which pleases both head and heart,” said the panel of experts.{' '}
                    </p>
                  </div>
                </div> */}

                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <p className="theme-news-body-text">
                      Kona Iron Man Edition, which costs £27,995, is available
                      with a 1.6 T-GDi engine and comes in Matte Grey with Red
                      Armour roof and contrasts. For more on this, and the
                      entire Kona range, visit{' '}
                      <a href="https://www.hyundai.co.uk/new-cars/kona">
                        www.hyundai.co.uk/new-cars/kona
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
