import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import './error.css';
import Auth from '../utils/Auth';

export default class Error extends React.Component {
  componentDidMount() {
    Auth.signout();
  }

  render() {
    var login = process.env.PUBLIC_URL + '/login';

    return (
      <div>
        <div className="container">
          <div className="jumbotron p-10">
            <div className="row pl-3 pr-3">
              <img
                src="images/hyundai_logoF.png"
                className="logoImg"
                height="25"
              />
              <div className="display-4 d4Fix phoneMargin">
                <i className="fas fa-phone"></i>{' '}
                <a href="tel:0800981981" title="Call Us Now">
                  0800 981 981
                </a>
              </div>
            </div>
          </div>
          <hr id="navHr" />
        </div>
        <div className="container">
          <section
            className="section1 wow fadeIn"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <div className="content p-5 col-md-10 offset-md-1 border-right border-bottom error-container">
              <div className="row">
                <div className="col">
                  <h3 className="midnightBlue">Sorry, something went wrong.</h3>

                  <p className="midnightBlue">
                    Please click here to log back into the site{' '}
                    <a
                      href={login}
                      className=" btn btn-primary btn-sm removeRadius"
                    >
                      Login
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="container">
          <footer className="footer" data-wow-delay="0.2s">
            <div className="clrBlack">
              <div className="row">
                <div className="col-md-3  col-lg">
                  <img
                    className="img-fluid"
                    id="footerLogo"
                    src="https://powertrip.meridianenergy.co.nz/themes/meridian/images/Hyundai_Logo_Hor_Reversed.png"
                  />
                </div>
              </div>
            </div>
            <p className="text-center">
              <small>
                &#169; Copyright {new Date().getFullYear()} Hyundai Motor UK
              </small>
              <small>
                <span className="footerLegal">
                  <a
                    href="https://www.hyundai.com/uk/en/legal-disclaimer.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {' '}
                    Legal
                  </a>{' '}
                  |{' '}
                  <a
                    href="https://www.hyundai.com/uk/en/privacy-policy.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {' '}
                    Privacy
                  </a>
                </span>
              </small>
            </p>
          </footer>
        </div>
      </div>
    );
  }
}
