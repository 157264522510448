import React, { Component } from 'react';
import './personal.css';
import FooterNavbar from '../components/footerNavbar';
import Store from 'store';
import Topnavbar from '../components/topnavbar';
import Bottomnavbar from '../components/bottomnavbar';
import Validation from '../utils/Validation';
import ValidationMessage from '../components/validationMessage';
import Footer from '../components/footer';
import ProfileNav from '../components/profileNavbar';
import ReactPasswordStrength from 'react-password-strength';
import SaveData from '../utils/SaveData';
import Auth from '../utils/Auth';
import InputField from '../components/inputField';
import $ from 'jquery';

export default class ManageMyAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authEmail: Store.get('customerInfo').email,
      currentPassword: '',
      isLoading: false,
      closeIsLoading: false,
      incorrectPassword: '',
      password: '',
      repeatPassword: '',
      passwordValidationMessage: '',
      repeatPasswordValidationMessage: '',
      closeMyAccountSuccess: false,
      closeMyAccountFailed: false,
      validation: {
        password: Validation.initValidationState(['required', 'password']),
        currentPassword: Validation.initValidationState(['required']),
        repeatPassword: Validation.initValidationState(['required']),
      },
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handlePasswordChange = (val) => {
    this.setState(
      {
        password: val.password,
        savePasswordSuccess: false,
        incorrectPassword: false,
      },
      () => {
        (() => {
          this.validateField('password');
        })();
        (() => {
          this.validatePassword();
        })();
        (() => {
          this.validateRepeatPassword();
        })();
      },
    );
  };
  validatePassword() {
    this.setState((prevState) => {
      console.warn('validatePassword', prevState.password.length > 0);

      if (prevState.password.length > 0) {
        return {
          passwordValidationMessage: Validation.validatePassword(
            prevState.password,
          ),
        };
      } else {
        return {
          passwordValidationMessage: '',
        };
      }
    });
  }
  validateRepeatPassword() {
    this.setState((prevState) => {
      console.warn('validateRepeatPassword', prevState.password.length > 0);
      if (prevState.password.length > 0) {
        return {
          repeatPasswordValidationMessage: Validation.validateMatch(
            prevState.password,
            prevState.repeatPassword,
          ),
        };
      } else {
        return {
          repeatPasswordValidationMessage: '',
        };
      }
    });
  }
  updateCallBack = (success) => {
    if (success) {
      this.setState({
        savePasswordSuccess: true,
        savePasswordFailed: false,
        isLoading: false,
      });
    } else {
      this.setState({
        savePasswordSuccess: false,
        savePasswordFailed: true,
        isLoading: false,
      });
    }
  };
  closedCallBack = (success) => {
    if (success) {
      this.setState({
        closeMyAccountSuccess: true,
        closeMyAccountFailed: false,
        closeIsLoading: false,
      });

      setTimeout(() => {
        Auth.signout();
      }, 7000);
    } else {
      this.setState({
        closeMyAccountSuccess: false,
        closeMyAccountFailed: true,
        closeIsLoading: false,
      });
    }
  };

  authCallBack = (bool) => {
    if (bool) {
      this.setState({
        incorrectPassword: false,
        isLoading: false,
      });
    } else {
      this.setState({
        incorrectPassword: true,
        currentPassword: '',
        isLoading: false,
      });
    }
  };

  validateField = (name) => {
    this.setState((prevState) => {
      return Validation.validate(prevState, name);
    });
  };

  handleCurrentPasswordInputChange = (event) => {
    const target = event.target;
    const name = target.name;

    this.setState(
      {
        currentPassword: event.target.value,
        savePasswordSuccess: false,
        incorrectPassword: false,
      },
      () => this.validateField(name),
    );
  };

  handleRepeatPasswordInputChange = (event) => {
    const target = event.target;
    const name = target.name;

    this.setState(
      {
        repeatPassword: event.target.value,
        savePasswordSuccess: false,
        incorrectPassword: false,
      },

      () => {
        (() => {
          this.validateField(name);
        })();
        (() => {
          this.validateRepeatPassword();
        })();
      },
    );
  };

  handleSaveDetails = async (e) => {
    e.preventDefault();
    var newState = Validation.validateAll(this.state);

    if (
      Validation.anyInvalid(newState) ||
      this.state.repeatPasswordValidationMessage ||
      !this.state.currentPassword ||
      !this.state.password ||
      !this.state.repeatPassword
    ) {
      this.setState(newState);
      return;
    } else {
      this.setState({
        savePasswordSuccess: false,
        incorrectPassword: false,
      });

      const { currentPassword, password } = this.state;

      this.setState({
        isLoading: true,
      });

      const auth = await Auth.authenticatePassword(
        this.state.authEmail,
        currentPassword,
        this.authCallBack,
      );

      this.setState({
        currentPassword: '',
        password: '',
        repeatPassword: '',
      });

      this.passField.clear();

      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          password: Validation.initValidationState(['required', 'password']),
        },
      }));

      if (auth) {
        if (
          (this.state.passwordValidationMessage == null ||
            this.state.passwordValidationMessage.length === 0) &&
          password != null
        ) {
          SaveData.updatePassword(password, this.updateCallBack);
        }
      } else {
        this.setState({ incorrectPassword: true });
      }
    }
  };

  passwordValidationMessageRender = () => {
    if (this.state.passwordValidationMessage) {
      return (
        <div className="text-danger small">
          <div className="theme-main-invalid-icon">
            <i className="fas fa-exclamation-triangle" />
          </div>
          <span className="theme-main-invalid-message">
            {this.state.passwordValidationMessage}
          </span>
        </div>
      );
    } else {
      return;
    }
  };

  repeatPasswordValidationMessageRender = () => {
    if (this.state.repeatPasswordValidationMessage) {
      return (
        <div className="text-danger small theme-main-request-history-password-error">
          <div className="theme-main-invalid-icon">
            <i className="fas fa-exclamation-triangle" />
          </div>
          <span className="theme-main-invalid-message">
            {this.state.repeatPasswordValidationMessage}
          </span>
        </div>
      );
    } else {
      return;
    }
  };

  handleCloseAccountModal = () => {
    $('#closeAccountModal').modal('show');
  };
  handleCloseAccount = () => {
    this.setState({ closeIsLoading: true });

    SaveData.closeAccount(this.closedCallBack);
  };

  render = () => {
    const passwordClassName =
      this.state.validation.password.message == null
        ? '    '
        : '    is-invalid';

    const { closeMyAccountSuccess, closeMyAccountFailed } = this.state;

    return (
      <div>
        <Topnavbar />
        <Bottomnavbar />
        <div className="container">
          <ProfileNav />

          <div className="section1 wow fadeIn">
            {/* ===================================== Image ====================================== */}
            <section
              className="row theme-main-grey-background pb-5"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >
              <div className="col-md-10 offset-md-1 ">
                <div className="align-items-start">
                  <div
                    className="theme-request-history-header-image"
                    style={{ backgroundImage: 'url("images/chair.png ") ' }}
                  />
                </div>
              </div>
            </section>

            <section
              className="theme-main-grey-background pb-5"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >
              <div className="content p-3 p-xl-5 col-md-10 offset-md-1 border-right border-bottom">
                <div className="row">
                  {/* ==================================== Side Bar ==================================== */}
                  <div className="col-md-3 p-3 p-md-4">
                    <h4 className="h4ServHistory" data-search-tag="title">
                      Changing&nbsp;
                      <br className="d-none d-sm-block" />
                      your&nbsp;
                      <br className="d-none d-sm-block" />
                      password
                    </h4>
                  </div>

                  {/* ============================== Change Password Form ============================== */}
                  <form className="col-md-9  p-3 p-md-4">
                    <p>
                      If you'd like to change your MyHyundai account password,
                      <br />
                      just complete the information below.
                    </p>

                    {/* =========================== Current Password ============================ */}
                    <div className="form-group">
                      <small>
                        <label htmlFor="inpCurrentPassword">
                          Existing Password *
                        </label>
                      </small>
                      <InputField
                        className="form-control form-control-sm flatInput theme-main-obscure-password"
                        name="currentPassword"
                        type="password"
                        id="inpCurrentPassword"
                        value={this.state.currentPassword}
                        onChange={this.handleCurrentPasswordInputChange}
                        validation={this.state.validation.currentPassword}
                      />
                      <ValidationMessage
                        validation={this.state.validation.currentPassword}
                      />
                    </div>
                    {/* ============================ New Password ============================ */}

                    <div id="new-password-container" className="form-group  ">
                      <label htmlFor="inpPassword">
                        <small>New Password *</small>
                      </label>
                      <div className="input-group">
                        <ReactPasswordStrength
                          ref={(c) => {
                            this.passField = c;
                          }}
                          className={
                            'theme-main-flat-password-strength-container w-100' +
                            passwordClassName
                          }
                          minLength={8}
                          minScore={2}
                          changeCallback={this.handlePasswordChange}
                          inputProps={{
                            name: 'password',
                            className:
                              'form-control form-control-sm theme-main-flat-password-input',
                            id: 'inpPassword',
                            autoComplete: 'new-password',
                          }}
                        />{' '}
                        <div
                          className={'form-control d-none' + passwordClassName}
                        />
                        <ValidationMessage
                          validation={this.state.validation.password}
                        />
                      </div>
                    </div>

                    {/* =========================== Repeat Password   ============================ */}
                    <div className="form-group">
                      <small>
                        <label htmlFor="inpNewPassword">
                          Repeat New Password *
                        </label>
                      </small>
                      <InputField
                        name="repeatPassword"
                        type="password"
                        id="inpNewPassword"
                        className="form-control form-control-sm flatInput theme-main-obscure-password"
                        value={this.state.repeatPassword}
                        onChange={this.handleRepeatPasswordInputChange}
                        validation={this.state.validation.repeatPassword}
                      />
                      <ValidationMessage
                        validation={this.state.validation.repeatPassword}
                      />
                    </div>

                    {/* ============================ Blurb ============================ */}
                    <div className="row">
                      <div className="col-12">
                        <p>
                          Your Password is case sensitive and for security
                          should be at least eight characters long and include a
                          capital letter and a number.
                        </p>
                      </div>
                    </div>

                    {/* ============================ Submit Button ============================ */}

                    <div className="row">
                      <div className="col-sm-6  col-lg-8 ">
                        <div className="form-control d-none" />
                      </div>
                      <div className="col-sm-6  col-lg-4  ">
                        <div className="mt-5" />
                        {this.repeatPasswordValidationMessageRender()}

                        <button
                          id="personalSaveBtn"
                          className="btn btn-sm removeRadius btn-primary btn-block "
                          onClick={this.handleSaveDetails}
                        >
                          {' '}
                          <div
                            className={'theme-button-spinner'}
                            style={{
                              display: this.state.isLoading ? 'block' : 'none',
                            }}
                          >
                            <i className="fas fa-spinner fa-spin fa-lg text-white" />
                          </div>
                          <span
                            style={{
                              visibility: this.state.isLoading
                                ? 'hidden'
                                : 'visible',
                            }}
                          >
                            Update password
                          </span>
                        </button>
                        {this.state.savePasswordSuccess && (
                          <div className="text-center text-success">
                            <p />
                            <small>
                              <i className="fas fa-check-circle" /> Changes have
                              been saved
                            </small>
                          </div>
                        )}
                        {this.state.savePasswordFailed && (
                          <div className="text-center text-danger">
                            <p />
                            <small>
                              <i className="fas fa-exclamation-triangle" />{' '}
                              Error in saving changes
                            </small>
                          </div>
                        )}

                        {this.state.incorrectPassword && (
                          <div className="text-center text-danger">
                            <p />
                            <small>
                              <i className="fas fa-exclamation-triangle" />{' '}
                              Incorrect Password
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </section>

            {/* Excluded from Phase 2 */}
            {/* ============================== Closing Your Account ============================== */}
            {/* <section
              className="theme-main-grey-background pb-5"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >
              <div className="content p-3 p-xl-5 col-md-10 offset-md-1 border-right border-bottom">
                <div className="row">
                  <div className="col-md-3 p-3">
                    <h4 className="h4ServHistory" data-search-tag="title">
                      Closing
                      <br />
                      your account
                    </h4>
                  </div>
                  <div className="col-md-9  p-4  ">
                    <p>
                      If you would like to close your MyHyundai account please
                      click on the ‘Close my account’ button below. You can
                      change your mind at any time and open a new account by
                      visiting{' '}
                      <a href="https://www.myhyundaiuk.com/" target="_blank" rel="noopener noreferrer">
                        myhyundai.com
                      </a>
                    </p>
                    <div className="row">
                      <div className="col-sm-6 offset-sm-6 col-md-6 offset-md-6 col-lg-4 offset-lg-8">
                        <button
                          className="btn btn-primary btn-block removeRadius btn-sm"
                          onClick={this.handleCloseAccountModal}
                        >
                          Close my account
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}
          </div>

          <FooterNavbar />
        </div>
        <Footer />

        {/* ============================ Close My Account Modal ============================ */}
        <div
          className="modal fade"
          id="closeAccountModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="closeAccountModal"
          aria-hidden="true"
        >
          {!closeMyAccountSuccess && (
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h6 className="modal-title">Close account</h6>

                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body text-center">
                  <div className="p-4">
                    {!closeMyAccountFailed && (
                      <p>
                        Are you sure you want to close your MyHyundai account?
                      </p>
                    )}
                    {closeMyAccountFailed && (
                      <p>Oops! Something went wrong. Please try again.</p>
                    )}

                    <button
                      onClick={this.handleCloseAccount}
                      className="btn btn-primary btn-sm removeRadius"
                    >
                      <div
                        className={'theme-button-spinner'}
                        style={{
                          display: this.state.closeIsLoading ? 'block' : 'none',
                        }}
                      >
                        <i className="fas fa-spinner fa-spin fa-lg text-white" />
                      </div>

                      <span
                        style={{
                          visibility: this.state.closeIsLoading
                            ? 'hidden'
                            : 'visible',
                        }}
                      >
                        Close my account
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {closeMyAccountSuccess && (
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h6 className="modal-title">Close account</h6>

                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="p-4 text-center">
                    <p>Your MyHyundai account is now closed</p>
                    <p>
                      If you change your mind, you can open a new one any time
                      by visiting{' '}
                      <a href="https://www.myhyundaiuk.com">myhyundai.com</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };
}
