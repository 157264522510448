import React, { Component, Fragment } from 'react';
import './home.css';
import { Link } from 'react-router-dom';
import FooterNavbar from '../components/footerNavbar';
import TopNavbar from '../components/topnavbar';
import BottomNavbar from '../components/bottomnavbar';
import Store from 'store';
import Footer from '../components/footer';
import Grid from '../components/home/grid';
import VehicleImage from '../components/vehicleImage';
import VehicleText from '../components/vehicleText';
import Dates from '../utils/DateFormat';
import SaveData from '../utils/SaveData';
import Validation from '../utils/Validation';

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      numberOfVehicles: 0,
      vehicleList: [],
      selectedIndex: 0,
      mileageValidation: Validation.initValidationState(['required', 'number']),
      currentMileage: '',
      isLoading: false,
      saveSuccess: false,
      saveFailed: false,
      customer: Store.get('customerInfo'),
      currentVehicleImage: null,
      vehicleImageDisplayed: false,
    };
  }

  handleVehicleImageChanged = (defaultImage, imagePath) => {
    this.setState({
      currentVehicleImage: imagePath,
      vehicleImageDisplayed: !defaultImage,
    });
  };

  handleVehicleSelect = (index) => {
    this.setState(
      (prevState) => {
        return {
          selectedIndex: index,
          currentMileage: prevState.vehicleList[index].mileage,
          isLoading: false,
          saveSuccess: false,
          saveFailed: false,
        };
      },
      function () {
        Store.set('selectedVehicle', index);
      },
    );
  };

  handleMileageChange = (val) => {
    var value = val.target.value;

    var newMileageValidation = Validation.updateValidationState(
      value,
      this.state.mileageValidation,
    );

    if (!newMileageValidation.isValid) {
      this.setState({
        mileageValidation: newMileageValidation,
        currentMileage: value,
        isLoading: false,
        saveSuccess: false,
        saveFailed: false,
      });
    } else {
      this.setState((prevState) => {
        var vehicles = prevState.vehicleList;
        vehicles[prevState.selectedIndex].mileage = value;
        return {
          mileageValidation: newMileageValidation,
          vehicleList: vehicles,
          currentMileage: value,
          isLoading: false,
          saveSuccess: false,
          saveFailed: false,
        };
      });
    }
  };

  saveVehicleDetails = () => {
    if (this.state.mileageValidation.isValid) {
      this.setState({
        isLoading: true,
      });

      var me = this;
      SaveData.saveVehicle(
        me.state.vehicleList,

        function () {
          me.setState(
            {
              saveSuccess: true,
              isLoading: false,
            },
            () => Store.set('vehicles', me.state.vehicleList),
          );
        },
        function () {
          me.setState({
            saveFailed: true,
            isLoading: false,
          });
        },
      );
    }
  };

  componentDidMount() {
    const vehicle = Store.get('vehicles');

    var selectedVehicle = Store.get('selectedVehicle');
    if (selectedVehicle == null) {
      selectedVehicle = 0;
    }

    this.setState({
      numberOfVehicles: (vehicle && vehicle.length) || 0,
      vehicleList: vehicle,
      currentMileage: vehicle[selectedVehicle]
        ? vehicle[selectedVehicle].mileage
        : 0,
      selectedIndex: selectedVehicle,
    });

    // if (
    //   this.state.customer.takeover.includes('i10') ||
    //   [2, 3].includes(this.state.customer.customerType)
    // ) {
    //   ReactGA.event({
    //     label: 'New i10',
    //     category: 'Promotion',
    //     action: 'i10 Promo Home Page Shown',
    //     gaOptions: {
    //       userId: this.state.customer && this.state.customer.urn,
    //     },
    //   });
    // }
  }

  accountUnderSixMonths = (date) => {
    var b = new Date(date);
    var d = new Date();
    d.setMonth(d.getMonth() - 6);

    if (b > d) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    const { customer } = this.state;
    const multiVehicleClassName =
      this.state.numberOfVehicles > 1 ? ' slantedDiv2' : '';

    /* This will only appear on customers account for the first six months unless they have set their interests already */
    const showSecondaryJumbotron =
      customer.interestList.every((t) => t.isSelected === false) &&
      //  &&   this.accountUnderSixMonths(customer.activationDate)
      customer.customerType !== 2
        ? true
        : false;

    const tabs = this.state.vehicleList.map((vehicle, index) => {
      const className =
        index === this.state.selectedIndex
          ? 'nav-item nav-link active' + multiVehicleClassName
          : 'nav-item nav-link' + multiVehicleClassName;
      return (
        <a
          key={index}
          className={className}
          id="nav-home-tab"
          data-toggle="tab"
          role="tab"
          aria-controls="nav-home"
          aria-selected="true"
          onClick={() => this.handleVehicleSelect(index)}
        >
          {vehicle.model}
        </a>
      );
    });
    const selectedVehicle = this.state.vehicleList
      ? this.state.vehicleList[this.state.selectedIndex]
      : null;

    return (
      <div>
        <TopNavbar />
        <BottomNavbar />

        {/* Show i10 Campaign if Takeover is i10 or customer type is Prospect or lapsed
        {(customer.takeover.includes('i10') ||
          [2, 3].includes(customer.customerType)) && <ITenPromotionJumboTron />} */}

        <div className="container position-relative">
          {/* ITenPromotion */}
          {/* Show i10 Campaign if Takeover is i10 or customer type is Prospect or lapsed */}
          {/* {(customer.takeover.includes('i10') ||
            [2, 3].includes(customer.customerType)) && (
            <Fragment>
              <ITenPromotionAdText side="left" />
              <ITenPromotionAdText side="right" />
            </Fragment>
          )} */}
          {/* ITenPromotion END */}

          <div className="theme-home-jumbotron-container theme-home-jumbotron-shadow pt-4 pb-3">
            <div className="theme-home-jumbotron bg-white text-center p-3 mx-3 mx-sm-4 mx-lg-5">
              <p className="theme-home-jumbotron-title">Welcome to MyHyundai</p>

              {customer.customerType !== 2 && (
                <p className="text-primary font-weight-light">
                  Welcome to MyHyundai, the exclusive website for Hyundai owners
                  that helps you get the most from owning a Hyundai.
                  <br className="d-none d-md-block" />
                  {/* <span className="d-inline-block d-md-none">&nbsp;</span>
                  Here you'll find exciting competitions, great offers, all our
                  latest news and product updates. */}
                  {/* <br className="d-none d-md-block" /> */}
                  {/* <span className="d-inline-block d-md-none">&nbsp;</span>
                  And get access to all your vehicle ownership and service
                  information. */}
                </p>
              )}

              {customer.customerType === 2 && (
                <p className="text-primary font-weight-light">
                  Welcome to MyHyundai, the exclusive website for exploring the
                  world of Hyundai. Here you can find all our latest news
                  <br className="d-none d-md-block" />
                  <span className="d-inline-block d-md-none">&nbsp;</span>
                  and product updates plus details of any requests for test
                  drives, quotes or brochures you've made.
                </p>
              )}
            </div>
          </div>
          <div className="fadeInDownBig">
            {this.state.vehicleList.length > 0 && (
              <div className="row theme-home-vehicle-container">
                <div
                  id="carHeader"
                  className="col-12 col-lg-6 col-xl-5 offset-md-0 offset-xl-1 px-sm-5 pr-xl-2"
                >
                  <nav className="theme-main-tabs-container">
                    <div
                      className="nav nav-tabs theme-main-tabs d-flex"
                      id="nav-tab"
                      role="tablist"
                    >
                      {tabs}
                    </div>
                  </nav>

                  {selectedVehicle != null && (
                    <div className="tab-content" id="nav-tabContent">
                      <div
                        className="tab-pane fade show active"
                        role="tabpanel"
                        aria-labelledby="nav-home-tab"
                      >
                        <div className="card">
                          <div className="card-body p-0">
                            <div className="row">
                              <div className="col-lg">
                                <div className=" table-responsive theme-home-table">
                                  <table className="table theme-table-main homeTableBody mb-0">
                                    <tbody>
                                      <tr className="theme-lighter">
                                        <th scope="row" className="homeTopText">
                                          Registration
                                        </th>
                                        <td className="homeTopText">
                                          {selectedVehicle.registration}
                                        </td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                      </tr>
                                      <tr className="theme-lighter">
                                        <th scope="row" className="homeTopText">
                                          Reg date
                                        </th>

                                        {/* <td>{vehicle.registrationDate}</td> */}
                                        <td className="homeTopText">
                                          {Dates.formatDate(
                                            new Date(
                                              selectedVehicle.registrationDate,
                                            ),
                                          )}
                                        </td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                      </tr>
                                      <tr className="theme-dark-lighter">
                                        <th scope="row" className="homeTopText">
                                          Next service{' '}
                                        </th>
                                        <td className="homeTopText">
                                          {Dates.formatDate(
                                            new Date(
                                              selectedVehicle.nextServiceDate,
                                            ),
                                          )}
                                        </td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                      </tr>
                                      <tr className="theme-dark-lighter">
                                        <th scope="row" className="homeTopText">
                                          Mileage
                                        </th>
                                        <td
                                          colSpan="2"
                                          className="theme-mileage-input"
                                        >
                                          <div className="input-group input-group-sm theme-home-mileage-container">
                                            <input
                                              type="number"
                                              className="form-control theme-home-mileage removeRadius"
                                              value={this.state.currentMileage}
                                              min="0"
                                              onChange={
                                                this.handleMileageChange
                                              }
                                            />

                                            <div className="input-group-append">
                                              <button
                                                className="btn btn-primary theme-home-mileage-btn removeRadius"
                                                onClick={
                                                  this.saveVehicleDetails
                                                }
                                              >
                                                {this.state.isLoading ? (
                                                  <div>
                                                    <i className="fas fa-spinner fa-spin fa-lg text-white" />
                                                  </div>
                                                ) : (
                                                  <span>Submit</span>
                                                )}
                                              </button>
                                            </div>
                                            {this.state.saveSuccess && (
                                              <span className="text-center text-success theme-styles-check">
                                                <small>
                                                  <i className="fas fa-check-circle" />
                                                </small>
                                              </span>
                                            )}
                                          </div>
                                        </td>
                                        <td>
                                          {this.state.saveFailed && (
                                            <span className="text-center text-danger">
                                              <small>
                                                <i className="fas fa-exclamation-triangle" />
                                              </small>
                                            </span>
                                          )}
                                        </td>
                                      </tr>
                                      {!this.state.mileageValidation
                                        .isValid && (
                                        <tr className="lighter">
                                          <th
                                            scope="row"
                                            className="homeTopText"
                                          >
                                            &nbsp;
                                          </th>
                                          <td colSpan={2}>
                                            <small className="text-danger">
                                              <i className="fas fa-exclamation-triangle" />{' '}
                                              {
                                                this.state.mileageValidation
                                                  .message
                                              }
                                            </small>
                                          </td>
                                          <td>&nbsp;</td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-12 col-lg-6 col-xl-6 p-0 theme-vehicle-img-container pt-lg-5 pr-lg-5 pt-xl-4 pr-xl-2 d-none d-lg-block">
                  <VehicleImage
                    vehicle={selectedVehicle}
                    imgClassName="theme-main-car-img"
                    defaultImgClassName="theme-main-car-img theme-main-car-default-img"
                    defaultImage="images/heart-logo-right.png"
                    overlayModel={false}
                    onChange={this.handleVehicleImageChanged}
                  />

                  {this.state.vehicleImageDisplayed && (
                    <VehicleText imagePath={this.state.currentVehicleImage} />
                  )}
                </div>
              </div>
            )}

            {/* {showSecondaryJumbotron && (
              <div className="theme-home-jumbotron-container pt-4 pb-3">
                <div className="theme-home-jumbotron bg-white text-center p-3 mx-3 mx-sm-4 mx-lg-5">
                  <p className=" text-primary font-weight-light">
                    To help you get the most out of MyHyundai, you can update
                    your interests any time. If you tell us what you're
                    interested in
                    <br className="d-none d-md-block" />
                    <span className="d-inline-block d-md-none">&nbsp;</span>
                    we can bring you more of the competitions you'll love.
                  </p>
                  <Link
                    to="/interests"
                    className="btn btn-sm btn-primary removeRadius"
                  >
                    Update my interests
                  </Link>
                </div>
              </div>
            )} */}

            <Grid
              className="home-promos"
              vehicle={selectedVehicle}
              customer={customer}
            />
            <p />
            <FooterNavbar />
          </div>
        </div>
        <Footer />
        {/* {(customer.takeover.includes('i10') ||
          [2, 3].includes(customer.customerType)) && (
          <ITenPromotionModal {...this.props} />
        )} */}
      </div>
    );
  }
}
