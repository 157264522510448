import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

export default class ArchiveNewsNavbar extends Component {
  render() {
    const links = [
      // {
      //   category: 'product',
      //   title: 'Archive Product News',
      // },
      {
        category: 'hyundai',
        title: 'Archive Hyundai News',
      },
      {
        category: 'awards',
        title: 'Archive Awards',
      },
    ];

    const renderLink = links.map((link, i) => {
      let linkClassName = 'nav-link btn btn-link  ';
      if (link.category === this.props.displayCategory)
        linkClassName = linkClassName + ' active';

      return (
        <li
          className="nav-item pr-5"
          key={i}
          data-search-category={link.category}
        >
          <button
            className={linkClassName}
            onClick={() => this.props.iHaveBeenClicked(link.category)}
          >
            {link.title}
          </button>
        </li>
      );
    });

    return (
      <div className="container" data-search-exclude>
        <nav className="navbar navbar-expand-lg navbar-light bg-light p-0 dropShadow secondary-nav">
          <div className="slantedDiv">My Hyundai</div>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto navbarText p-3 p-lg-0">
              {renderLink}
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}
