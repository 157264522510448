import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import './registration.css';
import Auth from '../utils/Auth';
import GetData from '../utils/GetData';
import Validation from '../utils/Validation';
import SelectField from '../components/selectField';
import InputField from '../components/inputField';
import ValidationMessage from '../components/validationMessage';
import ReactPasswordStrength from 'react-password-strength';
import Dealers from '../utils/Dealers';

export default class Registration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      connectionError: false,
      disableButton: false,
      checkForm: {
        title: '',
        address1: '',
        firstName: '',
        postcode: '',
        surname: '',
        vehicleReg: '',
        validation: {
          title: Validation.initRequiredValidationState(),
          address1: Validation.initRequiredValidationState(),
          firstName: Validation.initRequiredValidationState(),
          postcode: Validation.initRequiredValidationState(),
          surname: Validation.initRequiredValidationState(),
          vehicleReg: Validation.initRequiredValidationState(),
        },
      },
      registerForm: {
        email: '',
        password1: '',
        password2: '',
        selectedDealer: 0,
        validation: {
          email: Validation.initValidationState(['required', 'email']),
          password1: Validation.initValidationState(['required', 'password']),
          password2: Validation.initValidationState(['required'], () =>
            this.passwordsMatch(this.state),
          ),
        },
      },
      result: 0,
      dealers: [],
      loginNameTaken: false,
      isLoading: false,
    };

    this.handleCheckFormInputChange =
      this.handleCheckFormInputChange.bind(this);
    this.handleRegisterInputChange = this.handleRegisterInputChange.bind(this);
    this.registerUser = this.registerUser.bind(this);
    this.checkUser = this.checkUser.bind(this);
    this.handleGoBackToRegisterClick =
      this.handleGoBackToRegisterClick.bind(this);
  }

  passwordsMatch = (state) => {
    if (
      state.registerForm.password2 != null &&
      state.registerForm.password1 !== state.registerForm.password2
    ) {
      return 'Passwords do not match, please retype';
    }
    return null;
  };

  static validationClass(val) {
    var className = '';
    className =
      !val || val == null || val.isValid
        ? className
        : className + ' is-invalid';

    return className;
  }

  handleGoBackToRegisterClick() {
    this.setState({
      result: 0,
    });
  }

  componentDidMount() {
    if (!navigator.onLine) {
      this.setState({
        connectionError: true,
        error: false,
      });
    } else {
      this.setState({
        connectionError: false,
      });
    }
  }

  componentWillMount() {
    Dealers.load(this.handleDealersLoaded.bind(this));
  }

  handleDealersLoaded() {
    this.setState({
      dealers: Dealers.list,
    });
  }

  registerUser = (e) => {
    e.preventDefault();

    var newState = Validation.validateAll(this.state.registerForm);

    if (Validation.anyInvalid(newState)) {
      this.setState({
        registerForm: newState,
      });
    } else {
      this.setState({ isLoading: true });
      fetch(
        process.env.REACT_APP_API_URL + '/HyundaiUk/Registration/CreateRecord',
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            Title: this.state.checkForm.title,
            Forenames: this.state.checkForm.firstName,
            Surname: this.state.checkForm.surname,
            AddressLine1: this.state.checkForm.address1,
            PostCode: this.state.checkForm.postcode,
            Registration: this.state.checkForm.vehicleReg,
            Email: this.state.registerForm.email,
            Password: this.state.registerForm.password1,
            DealerCode: this.state.registerForm.selectedDealer,
          }),
        },
      )
        .then((response) => response.json())
        .then((responseData) => {
          if (responseData.status === 1) {
            Auth.authenticateWithToken(responseData.authenticationToken);
            var me = this;
            GetData.populateModels(() => {
              me.setState(
                {
                  logInSuccess: true,
                  isLoading: false,
                },
                () => {
                  this.setState({
                    logInSuccess: false,
                    isLoading: false,
                  });
                },
              );
            });
          } else if (responseData.status === 2) {
            this.setState({ loginNameTaken: true });
          }
        });
    }
  };

  checkUser = (e) => {
    if (!navigator.onLine) {
      this.setState({
        connectionError: true,
        isLoading: false,
        error: false,
      });
      return;
    } else {
      this.setState({
        connectionError: false,
      });
    }

    e.preventDefault();

    var newState = Validation.validateAll(this.state.checkForm);

    if (Validation.anyInvalid(newState)) {
      this.setState({
        checkForm: newState,
      });
    } else {
      this.setState({ isLoading: true });

      fetch(
        process.env.REACT_APP_API_URL + '/HyundaiUk/Registration/Register',
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            Title: this.state.checkForm.title,
            Forenames: this.state.checkForm.firstName,
            Surname: this.state.checkForm.surname,
            AddressLine1: this.state.checkForm.address1,
            PostCode: this.state.checkForm.postcode,
            Registration: this.state.checkForm.vehicleReg,
          }),
        },
      )
        .then((response) => response.json())
        .then((responseData) => {
          this.setState({
            result: responseData.result,
            isLoading: false,
          });
        });
    }
  };

  handleCheckFormInputChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    this.setState(
      (prevState) => {
        return {
          checkForm: {
            ...prevState.checkForm,
            [name]: value,
          },
        };
      },
      () => this.validateCheckFormField(name),
    );
  }

  validateCheckFormField(name) {
    this.setState((prevState) => {
      return {
        ...prevState,
        checkForm: Validation.validate(prevState.checkForm, name),
      };
    });
  }

  handleInputChangePass1 = (val) => {
    this.state.registerForm.password1 = val.password;
    this.setState(
      (prevState) => {
        return {
          registerForm: {
            ...prevState.registerForm,
            password1: val.password,
          },
        };
      },
      () => this.validateRegisterFormField('password1'),
    );
  };

  handleRegisterInputChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    this.setState(
      (prevState) => {
        return {
          registerForm: {
            ...prevState.registerForm,
            [name]: value,
          },
        };
      },
      () => this.validateRegisterFormField(name),
    );
  }

  validateRegisterFormField(name) {
    this.setState((prevState) => {
      return {
        ...prevState,
        registerForm: Validation.validate(prevState.registerForm, name),
      };
    });
  }

  render() {
    const result = this.state.result;
    const dealerOptions = this.state.dealers.map((dealer) => (
      <option key={dealer.code} value={dealer.code}>
        {dealer.name}
      </option>
    ));

    const extraSmall = {
      fontSize: '10px',
    };

    const titles = [
      'Mr',
      'Mrs',
      'Ms',
      'Miss',
      'Dr',
      'Professor',
      'Reverend',
      'Lord',
      'Lady',
    ].map((title) => (
      <option key={title} value={title}>
        {title}
      </option>
    ));

    return (
      <div id="registrationContainer" className="container">
        <div className="theme-login-container">
          {this.state.logInSuccess && <Redirect to="/" />}

          <div>
            <div className="d-flex flex-column flex-sm-row justify-content-between text-center  pb-1 pb-sm-4">
              <div>
                <img src="images/hyundai_logoF.png" style={{ maxHeight: 25 }} />
              </div>
              <div className="p-2 p-sm-0">
                <i className="fas fa-phone" />{' '}
                <a href="tel:0800981981" title="Call Us Now">
                  0800 981 981
                </a>
              </div>
            </div>

            <div className="theme-registration-bg-image  p-sm-4 pt-sm-5 ">
              <div className="row  mx-0">
                <div className="col-lg-4" id="regleftCol">
                  <small>
                    <p>
                      <strong>Can I register?</strong> <br />
                      As long as you own a Hyundai, you can register now.
                    </p>
                    <p>
                      <strong>What benefits can I expect?</strong> <br />
                      ﻿﻿Get Hyundai's latest news and product updates. Plus,
                      current Hyundai owners can access vehicle ownership and
                      service information.
                    </p>
                    <p />
                    <p>
                      <strong>Next step?</strong>
                      <br />
                      Complete the form opposite to create your new MyHyundai
                      account.
                    </p>
                  </small>
                </div>

                {/* Register Default */}
                {result === 0 && (
                  <div
                    className="col-lg-7 ml-auto p-3 bg-white"
                    id="registrationDefaultFormContainer"
                  >
                    <p className="frmTitleText">Register now</p>

                    <p className="mandatory">
                      <span className="mandatory">
                        <small style={extraSmall}>
                          Fields marked with a * are mandatory
                        </small>
                      </span>
                    </p>

                    <form>
                      <div className="row">
                        <div className="col-md-6 pr-3">
                          <div className="form-group">
                            <small>
                              <label htmlFor="registrationDefaultFormTitleSelect">
                                Title *
                              </label>
                            </small>
                            <SelectField
                              id="registrationDefaultFormTitleSelect"
                              name="title"
                              value={this.state.checkForm.title}
                              onChange={this.handleCheckFormInputChange}
                              validation={this.state.checkForm.validation.title}
                              autoComplete="honorific-prefix"
                            >
                              <option value="">Choose...</option>
                              {titles}
                            </SelectField>
                            <ValidationMessage
                              validation={this.state.checkForm.validation.title}
                            />
                          </div>
                          <div className="form-group">
                            <small>
                              <label htmlFor="registrationDefaultFormFirstNameInput">
                                First Name *
                              </label>
                            </small>
                            <InputField
                              id="registrationDefaultFormFirstNameInput"
                              autoComplete="given-name"
                              name="firstName"
                              value={this.state.checkForm.firstName}
                              onChange={this.handleCheckFormInputChange}
                              validation={
                                this.state.checkForm.validation.firstName
                              }
                            />
                            <ValidationMessage
                              validation={
                                this.state.checkForm.validation.firstName
                              }
                            />
                          </div>
                          <div className="form-group">
                            <small>
                              <label htmlFor="registrationDefaultFormSurNameInput">
                                Surname *
                              </label>
                            </small>
                            <InputField
                              id="registrationDefaultFormSurNameInput"
                              name="surname"
                              value={this.state.checkForm.surname}
                              onChange={this.handleCheckFormInputChange}
                              autoComplete="family-name"
                              validation={
                                this.state.checkForm.validation.surname
                              }
                            />
                            <ValidationMessage
                              validation={
                                this.state.checkForm.validation.surname
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <small>
                              <label htmlFor="registrationDefaultFormAddressInput">
                                First line of address *
                              </label>
                            </small>
                            <InputField
                              id="registrationDefaultFormAddressInput"
                              name="address1"
                              value={this.state.checkForm.address1}
                              onChange={this.handleCheckFormInputChange}
                              autoComplete="street-address"
                              validation={
                                this.state.checkForm.validation.address1
                              }
                            />
                            <ValidationMessage
                              validation={
                                this.state.checkForm.validation.address1
                              }
                            />
                          </div>
                          <div className="form-group">
                            <small>
                              <label htmlFor="registrationDefaultFormPostcodeInput">
                                Postcode *
                              </label>
                            </small>
                            <InputField
                              id="registrationDefaultFormPostcodeInput"
                              name="postcode"
                              autoComplete="postal-code"
                              value={this.state.checkForm.postcode}
                              onChange={this.handleCheckFormInputChange}
                              validation={
                                this.state.checkForm.validation.postcode
                              }
                            />
                            <ValidationMessage
                              validation={
                                this.state.checkForm.validation.postcode
                              }
                            />
                          </div>
                          <div className="form-group">
                            <small>
                              <label htmlFor="registrationDefaultFormVehicleRegInput">
                                Vehicle registration *
                              </label>
                            </small>
                            <InputField
                              id="registrationDefaultFormVehicleRegInput"
                              name="vehicleReg"
                              value={this.state.checkForm.vehicleReg}
                              onChange={this.handleCheckFormInputChange}
                              validation={
                                this.state.checkForm.validation.vehicleReg
                              }
                            />
                            <ValidationMessage
                              validation={
                                this.state.checkForm.validation.vehicleReg
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <br />

                      <small>
                        <p className="pt-1 ">
                          <a
                            href="https://www.hyundai.com/uk/en/privacy-policy.html"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <u>Click here</u>
                          </a>{' '}
                          to view the privacy policy.
                        </p>
                      </small>

                      <div className="form-row">
                        <div className="form-group col-md-6 order-sm-2">
                          <button
                            id="registrationDefaultFormRegisterButton"
                            type="button"
                            className=" btn btn-block btn-primary btn-sm float-right removeRadius mb-1"
                            onClick={this.checkUser}
                          >
                            {this.state.isLoading ? (
                              <div>
                                <i className="fas fa-spinner fa-spin fa-lg text-white" />
                              </div>
                            ) : (
                              <span>Register</span>
                            )}
                          </button>
                        </div>
                        <div className="form-group col-md-6 order-sm-1">
                          <Link
                            id="registrationDefaultFormGoBackButton"
                            role="button"
                            to="/"
                            className="col-md-6 btn btn-block btn-outline-dark btn-sm removeRadius"
                          >
                            Go back
                          </Link>
                        </div>
                      </div>
                      {this.state.connectionError === true && (
                        <div className="text-center text-danger">
                          <p />
                          <small>
                            <i className="fas fa-exclamation-triangle" /> No
                            internet connection available
                          </small>
                        </div>
                      )}
                    </form>
                  </div>
                )}

                {/* Vehicle Not Found */}
                {result === 1 && (
                  <div
                    className="col-lg-7 ml-auto mt-5 mb-5  p-3 bg-white"
                    id="registrationVehicleNotFoundFormContainer"
                  >
                    <p className="frmTitleText">Register now</p>
                    <div className=" text-danger">
                      <p />
                      <i className="fas fa-exclamation-triangle" /> Vehicle not
                      found{' '}
                    </div>
                    <div>
                      <br />
                      <p className="frmTitleTextSmaller">
                        Please call <a href="tel:0800981981"> 0800 981 981</a>{' '}
                        for further assistance.
                      </p>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <a
                          id="registrationVehicleNotFoundGoBackButton"
                          role="button"
                          className="col-md-6 btn btn-block btn-outline-dark btn-sm removeRadius "
                          onClick={this.handleGoBackToRegisterClick}
                        >
                          Go back
                        </a>
                      </div>
                    </div>
                  </div>
                )}

                {/* Dealer */}
                {result === 2 && (
                  <div
                    className="col-lg-7 ml-auto mt-5 mb-5  p-3 bg-white"
                    id="registrationDealerFormContainer"
                  >
                    <p className="frmTitleText">Register now</p>
                    <p className="mandatory">
                      <span className="mandatory">
                        <small style={extraSmall}>
                          Fields marked with a * are mandatory
                        </small>
                      </span>
                    </p>
                    <div>
                      <form>
                        <div className="form-row">
                          <div className="form-group col-md-6 pr-3">
                            <small>
                              <label htmlFor="registrationDealerFormDealerSelect">
                                Select a dealer *
                              </label>
                            </small>
                            <SelectField
                              id="registrationDealerFormDealerSelect"
                              name="selectedDealer"
                              value={this.state.registerForm.selectedDealer}
                              onChange={this.handleRegisterInputChange}
                              validation={
                                this.state.registerForm.validation
                                  .selectedDealer
                              }
                            >
                              {dealerOptions}
                            </SelectField>
                            <ValidationMessage
                              validation={
                                this.state.registerForm.validation
                                  .selectedDealer
                              }
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <small>
                              <label htmlFor="registrationDealerFormEmailInput">
                                Email address *
                              </label>
                            </small>
                            <InputField
                              id="registrationDealerFormEmailInput"
                              type="email"
                              name="email"
                              value={this.state.registerForm.email}
                              onChange={this.handleRegisterInputChange}
                              validation={
                                this.state.registerForm.validation.email
                              }
                            />
                            <ValidationMessage
                              validation={
                                this.state.registerForm.validation.email
                              }
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div
                            id="registrationPasswordStrengthContainer"
                            className="form-group col-md-6 pr-3"
                          >
                            <small>
                              <label htmlFor="registrationDealerFormPasswordInput">
                                Password *
                              </label>
                            </small>

                            <ReactPasswordStrength
                              className={
                                'theme-main-flat-password-strength-container  ' +
                                Registration.validationClass(
                                  this.state.registerForm.validation.password1,
                                )
                              }
                              minLength={8}
                              minScore={2}
                              changeCallback={this.handleInputChangePass1}
                              inputProps={{
                                name: 'password1',
                                className:
                                  'form-control form-control-sm theme-main-flat-password-input',
                                id: 'registrationDealerFormPasswordInput',
                                //
                              }}
                            />
                            <div
                              className={
                                'form-control d-none' +
                                Registration.validationClass(
                                  this.state.registerForm.validation.password1,
                                )
                              }
                            />

                            <ValidationMessage
                              validation={
                                this.state.registerForm.validation.password1
                              }
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <small>
                              <label htmlFor="registrationDealerFormPasswordConfirmInput">
                                Confirm Password *
                              </label>
                            </small>
                            <InputField
                              className="form-control form-control-sm flatInput theme-main-flat-confirm-password"
                              id="registrationDealerFormPasswordConfirmInput"
                              name="password2"
                              type="password"
                              autoComplete="new-password"
                              value={this.state.registerForm.password2}
                              onChange={this.handleRegisterInputChange}
                              validation={
                                this.state.registerForm.validation.password2
                              }
                            />
                            <ValidationMessage
                              validation={
                                this.state.registerForm.validation.password2
                              }
                            />
                          </div>
                        </div>

                        <br />
                        <div className="form-row">
                          <div className="form-group col-md-6 pr-3">
                            {this.state.loginNameTaken && (
                              <div className="text-center text-danger">
                                <p />
                                <small>
                                  <i className="fas fa-exclamation-triangle" />{' '}
                                  Email already registered. Please choose a
                                  different email address.
                                </small>
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <a
                              id="registrationDealerFormGoBackButton"
                              role="button"
                              className="col-md-6 btn btn-block btn-outline-dark btn-sm removeRadius "
                              onClick={this.handleGoBackToRegisterClick}
                            >
                              Go back
                            </a>
                          </div>
                          <div className="form-group col-md-6">
                            <button
                              id="registrationDealerFormRegisterButton"
                              type="button"
                              className=" btn btn-block btn-primary btn-sm float-right removeRadius mb-1"
                              onClick={this.registerUser}
                            >
                              {this.state.isLoading ? (
                                <div>
                                  <i className="fas fa-spinner fa-spin fa-lg text-white" />
                                </div>
                              ) : (
                                <span>Register</span>
                              )}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                )}

                {/* Activate Account */}
                {result === 3 && (
                  <div
                    className="col-lg-7 ml-auto mt-5 mb-5  p-3 bg-white"
                    id="registrationActivateAccountResultContainer"
                  >
                    <p className="frmTitleText">Register now</p>

                    <div>
                      <p className="frmTitleTextSmaller">
                        Please activate your account.
                      </p>
                      <div className="form-group col-md-6 pr-3 pl-0">
                        <Link
                          id="registrationActivateAccountResultLoginButton"
                          role="button"
                          to="/activation"
                          className="col-lg-6 btn btn-primary btn-sm removeRadius buttonResize"
                        >
                          Activate
                        </Link>
                      </div>
                    </div>
                  </div>
                )}

                {/* Already Activated */}
                {result === 4 && (
                  <div
                    className="col-lg-7 ml-auto mt-5 mb-5  p-3 bg-white"
                    id="registrationAlreadyActivatedResultContainer"
                  >
                    <p className="frmTitleText">Register now</p>

                    <div>
                      <p className="frmTitleTextSmaller">
                        It looks like you already have an account, please login.
                      </p>
                      <div className="form-group col-md-6 pr-3 pl-0">
                        <Link
                          id="registrationAlreadyActivatedResultLoginButton"
                          role="button"
                          to="/login"
                          className="col-lg-6 btn btn-primary btn-sm removeRadius buttonResize"
                        >
                          Login
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
                {/* Vehicle Added Activate*/}
                {result === 5 && (
                  <div
                    className="col-lg-7 ml-auto mt-5 mb-5  p-3 bg-white"
                    id="registrationVehicleAddedActivateResultContainer"
                  >
                    <p className="frmTitleText">Register now</p>
                    <div>
                      <p className="frmTitleTextSmaller">
                        The vehicle was successfully added to your account.
                        Please activate your account.
                      </p>
                      <div className="form-group col-md-6 pr-3 pl-0">
                        <Link
                          id="registrationVehicleAddedActivateResultLoginButton"
                          role="button"
                          to="/activation"
                          className="col-lg-6 btn btn-primary btn-sm removeRadius buttonResize "
                        >
                          Activate
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
                {/* Vehicle Added Login*/}
                {result === 6 && (
                  <div
                    className="col-lg-7 ml-auto mt-5 mb-5  p-3 bg-white"
                    id="registrationVehicleAddedLoginResultContainer"
                  >
                    <p className="frmTitleText">Register now</p>
                    <div>
                      <p className="frmTitleTextSmaller">
                        The vehicle was successfully added to your account.
                        Please login your account.
                      </p>
                      <div className="form-group col-md-6 pr-3 pl-0">
                        <Link
                          id="registrationVehicleAddedLoginResultLoginButton"
                          role="button"
                          to="/login"
                          className="col-lg-6 btn btn-primary btn-sm removeRadius buttonResize"
                        >
                          Login
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
